import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import * as PropTypes from "prop-types";
import * as React from "react";

export function OrderTypeSelector({ value, onChange }) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(event, value) => onChange(value)}
      aria-label="text alignment"
    >
      <ToggleButton disableRipple value="Dine In" aria-label="left aligned">
        Dine In
      </ToggleButton>
      <ToggleButton disableRipple value="Take Away" aria-label="right aligned">
        Take Away
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

OrderTypeSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
