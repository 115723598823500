import { useHttpRequest } from "../../../hooks";
import Swal from "sweetalert2";

export const useSupplierOrderApi = () => {
  const { isLoading, sendRequest } = useHttpRequest();

  const getPrerequisites = async () => {
    return sendRequest(`/fnb-suppliers/get-prerequisite`, "GET").catch((e) => {
      Swal.fire(
        "Error during query prerequisites.",
        e?.response?.data?.message || e.message,
        "error",
      );
      throw e;
    });
  };

  const placeOrder = async (orderData) => {
    return sendRequest(`/fnb-suppliers/place-order`, "POST", orderData).catch(
      (e) => {
        Swal.fire(
          "Error during place order.",
          e?.response?.data?.message || e.message,
          "error",
        );
        throw e;
      },
    );
  };

  const updateOrder = async (orderNumber, orderData) => {
    return sendRequest(
      `/fnb-suppliers/update-order/${orderNumber}`,
      "PUT",
      orderData,
    ).catch((e) => {
      Swal.fire(
        "Error during place order.",
        e?.response?.data?.message || e.message,
        "error",
      );
      throw e;
    });
  };

  const sendOrderToSupplier = async (orderNumber) => {
    return sendRequest(`/fnb-suppliers/submit`, "POST", {
      orderNumber,
    }).catch((e) => {
      Swal.fire(
        "Error during submit to supplier. Please try again.",
        e?.response?.data?.message || e.message,
        "error",
      );
      throw e;
    });
  };
  const markAsReceived = async (orderNumber) => {
    return sendRequest(`/fnb-suppliers/received`, "POST", {
      orderNumber,
    }).catch((e) => {
      Swal.fire(
        "Error during mark order as received.",
        e?.response?.data?.message || e.message,
        "error",
      );
      throw e;
    });
  };

  const getOrderByNumber = async (orderNumber) => {
    return sendRequest(`/fnb-suppliers/get-order/${orderNumber}`, "GET").catch(
      (e) => {
        Swal.fire(
          "Error during query prerequisites.",
          e?.response?.data?.message || e.message,
          "error",
        );
        throw e;
      },
    );
  };

  return {
    isLoading,
    getPrerequisites,
    placeOrder,
    getOrderByNumber,
    updateOrder,
    sendOrderToSupplier,
    markAsReceived,
  };
};
