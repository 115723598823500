import React, { useCallback, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputBase, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import ItemPopup from "../ItemPopup/ItemPopup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveIcon from "@mui/icons-material/Remove";
import { convertThisToCurrency } from "../../../../helper/convert-this-to-currency";

export default function OrderItem({ selectedItems, setSelectedItems, selectedItemsArr, setSelectedItemsArr, disableForm }) {
  const [openItemChooser, setOpenItemChooser] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [reviewedItems, setReviewedItems] = useState([])

  const toggleReviewedItem = (itemId) => {
    const newReviewedItems = { ...reviewedItems }
    newReviewedItems[itemId] = !newReviewedItems[itemId];
    setReviewedItems(newReviewedItems);
  };

  const handleClickOpen = () => {
    setOpenItemChooser(true);
  };

  const handleClose = () => {
    setOpenItemChooser(false);
  };

  const handleQtyChange = (id, qty) => {
    const newSelectedItems = { ...selectedItems };
    newSelectedItems[id].requestedQty = Math.max(0, qty);
    setSelectedItems(newSelectedItems);
  }

  const handleDeleteItem = (id) => {
    const newSelectedItems = { ...selectedItems };
    delete newSelectedItems[id];
    setSelectedItems(newSelectedItems);
  }

  useEffect(() => {
    const newSelectedItemsArr = [];
    let totalPrice = 0;

    for (const i in selectedItems) {
      totalPrice += selectedItems[i].price * selectedItems[i].requestedQty;
      newSelectedItemsArr.push(selectedItems[i]);
    }
    setSelectedItemsArr(newSelectedItemsArr)
    setTotalPrice(totalPrice.toFixed(2))
  }, [selectedItems])

  // calculate line amount
  const calculateAmount = useCallback((row) => {
    return convertThisToCurrency((row.price * row.requestedQty).toFixed(2));
  }, [selectedItems]);

  const addItemColumnConfig = [
    {
      type: "input",
      name: "Qty.",
      width: "110px",
      center: true,
      cell: (row, index, column, id) => {
        return <InputBase disabled={!!disableForm}
          type={"number"}
          className={'input-group'}
          value={selectedItems[row._id].requestedQty}
          onChange={(e) => handleQtyChange(row._id, e.target.value)}
          startAdornment={
            !disableForm && <IconButton disabled={!!disableForm}>
              <RemoveIcon onClick={(e) => handleQtyChange(row._id, Math.max(0, selectedItems[row._id].requestedQty - 1))} />
            </IconButton>
          }
          endAdornment={
            !disableForm && <IconButton disabled={!!disableForm}>
              <AddIcon onClick={(e) => handleQtyChange(row._id, selectedItems[row._id].requestedQty + 1)} />
            </IconButton>
          } />
      }
    },
    {
      name: "Code",
      width: "90px",
      selector: row => row.code
    },
    {
      name: "Description",
      minWidth: "300px",
      wrap: true,
      cell: (row, index, column, id) => {
        return row.description + ' ' + row.model
      }
    },
    {
      name: "Category",
      width: "250px",
      wrap: true,
      selector: row => row.category
    },
    {
      name: "UOM",
      width: '70px',
      selector: row => row.unit,
    },
    {
      name: "Unit Price",
      width: '100px',
      right: true,
      cell: (row, index, column, id) => {
        return convertThisToCurrency(row.price.toFixed(2))
      }
    },
    {
      name: "Amount",
      width: '100px',
      right: true,
      cell: (row, index, column, id) => {
        return calculateAmount(row);
      }
    }
  ];

  if (!disableForm) {
    addItemColumnConfig.unshift({
      name: "",
      width: "50px",
      button: true,
      cell: (row, index, column, id) => {
        return !disableForm && <IconButton disabled={!!disableForm} onClick={(e) => { handleDeleteItem(row._id) }}><DeleteOutlineIcon /></IconButton>
      }
    });
  }

  return (
    <>
      <Grid container className={"form"}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"sub-action"}>
            <Typography variant={"h2"}>Materials</Typography>
            {!disableForm && <Button type={"button"} onClick={handleClickOpen}><AddIcon /></Button>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"summary"}>
            <Typography variant={"h3"}>Total Amount: <span>{convertThisToCurrency(totalPrice)}</span></Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={"table"}>
            <DataTable
              columns={addItemColumnConfig}
              data={selectedItemsArr}
              persistTableHead={true}
              onRowClicked={(row, e) => { toggleReviewedItem(row._id) }}
              conditionalRowStyles={[{ when: row => !!reviewedItems[row._id], classNames: ["tick"] }]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        open={openItemChooser}
        maxWidth={false}
        onClose={handleClose}
      >
        <DialogTitle component={"div"}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"sub-action"}>
            <Button onClick={() => handleClose()}><ArrowBackIcon /></Button>
            <Typography variant={"h2"}>Find Material</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ItemPopup items={selectedItems} setItems={setSelectedItems} />
        </DialogContent>
      </Dialog>
    </>
  );
}
