import { DeleteOutline } from "@mui/icons-material";
import { Button, IconButton, InputBase } from "@mui/material";
import * as moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import convertUrlFileName from "../../../helper/convert-url-filename";

export const feedbackTableConfig =
    (hasEditPrivilege, isDisabled, onRemoveRow, onChangeRowValues, onClickAddBtn) => {

        const columns = [
            {
                name: "Description",
                width: "300px",
                cell: (row, index) => {
                    if (hasEditPrivilege && !isDisabled) {
                        return <InputBase
                            type={"text"}
                            variant={"outlined"}
                            value={row.description}
                            onChange={(e) => onChangeRowValues(index, e.target.value, 'description')}
                        />
                    }
                    return <>{row.description}</>
                }
            },
            {
                name: "Name",
                width: "450px",
                selector: row => (<a href={convertUrlFileName(row.url)} target="_blank" title={row.fileName} download>{row.fileName}</a>)
            },
            {
                name: "Upload Date",
                width: "170px",
                wrap: true,
                selector: row => row.uploadedDate ?
                    moment(row.uploadedDate).format('DD/MM/YYYY HH:mm:ss') : '',
                sortable: true.valueOf,
                id: 'uploadedDate'
            }
        ];

        if (hasEditPrivilege && !isDisabled) {
            columns.unshift({
                name: <IconButton type={"button"} onClick={onClickAddBtn}>
                    <AddIcon /></IconButton>,
                width: "50px",
                cell: (row, index) => (<IconButton onClick={() => onRemoveRow(index)}>
                    <DeleteOutline />
                </IconButton>)
            });
        }
        return columns;
    };