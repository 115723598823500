import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  Button,
  Box,
} from "@mui/material";
import Swal from "sweetalert2";
import "./style/index.css";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import _ from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import TextFieldController from "../../components/TextFieldController";

// ************************ **********************

const CarWashSetup = (props) => {
  // *********** VAR *************
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    sendRequest,
    httpRequestError: error,
    responseData,
  } = useHttpRequest();
  const methods = useForm({ mode: "all" });
  const { handleSubmit, control, reset, getValues, setValue, formState } =
    methods;

  // *********** FUNCTION *************

  const handleSubmitForm = async (data) => {
    try {
      await sendRequest(`/v1/car-wash-setup/setup`, "PUT", {
        ...data,
        startOfMonth: Number(data.startOfMonth),
        daysToComplete: Number(data.daysToComplete),
        dayOfMonthlyReport: Number(data.dayOfMonthlyReport),
        daysInterval: Number(data.daysInterval),
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Car Wash Setup has been updated.",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  };

  // *********** HOOK *************

  useEffect(() => {
    document.title = "Car Wash Setup";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.car_wash_setup,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
    sendRequest(`/v1/car-wash-setup/setup`, "GET").then((res) => {
      reset({
        ...res.data,
      });
    });
  }, []);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // *********** Render *************

  const pageTitleRender = (
    <Typography variant={"h1"}>Car Wash Setup</Typography>
  );

  const actionRender = auth.checkModulePrivilege(
    PrivilegeModules.car_wash_setup,
    PrivilegeActions.edit,
  ) && (
    <Button
      className={"primary"}
      type="submit"
      disabled={Object.keys(formState.errors).length !== 0}
    >
      Save
    </Button>
  );

  const generalFormRender = (
    <Box className={"form"}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant={"h2"}>General</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={6}>
              <TextFieldController
                name={`startOfMonth`}
                label={`Start Of Month`}
                type={"number"}
                rules={{
                  max: {
                    value: 31,
                    message: "Start Of Month must be less than 31",
                  },
                  min: {
                    value: 1,
                    message: "Start Of Month must be greater than 1",
                  },
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldController
                name={`daysInterval`}
                label={`Days Interval`}
                type={"number"}
                rules={{
                  max: {
                    value: 31,
                    message: "Days Interval must be less than 31",
                  },
                  min: {
                    value: 1,
                    message: "Days Interval must be greater than 1",
                  },
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldController
                name={`daysToComplete`}
                label={`Days To Complete`}
                type={"number"}
                rules={{
                  max: {
                    value: 31,
                    message: "Days To Complete must be less than 31",
                  },
                  min: {
                    value: 1,
                    message: "Days To Complete must be greater than 1",
                  },
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldController
                name={`dayOfMonthlyReport`}
                label={`Day Of Monthly Report`}
                type={"number"}
                rules={{
                  max: {
                    value: 31,
                    message: "Day Of Monthly Report must be less than 31",
                  },
                  min: {
                    value: 1,
                    message: "Day Of Monthly Report must be greater than 1",
                  },
                }}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const formRender = (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {pageTitleRender}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
            {actionRender}
          </Grid>

          <Grid item xs={12}>
            {generalFormRender}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      {formRender}
    </>
  );
};

export default CarWashSetup;
