import React, { useEffect, useState } from "react";
import {
  Grid, Typography, Button, TextField
} from "@mui/material";
import { withRouter, Link } from "react-router-dom";
import { useHttpRequest } from "../../hooks/useHttpRequest";

// styles
import useStyles from "./styles";

// logo
import logo from "./gain-city-logo.png";

// context
import { useUserDispatch, useUserState, signOut } from "../../context/UserContext";
import Swal from "sweetalert2";

const TwoFAVerification = (props) => {
  const classes = useStyles();

  // global
  const userDispatch = useUserDispatch();
  const { userInfo } = useUserState();

  const [twoFAToken, setTwoFAToken] = useState("");
  const { httpRequestError: error, responseData, sendRequest: loginAPI } = useHttpRequest();

  const processVerify = async () => {
    await loginAPI("/v1/user/verify-two-fa-token", "PATCH", {
      twoFAToken: twoFAToken, userId: userInfo._id
    });
    return false;
  };

  useEffect(() => {
    if (responseData == undefined) {
      return;
    }

    if (responseData?.verificationStatus == true) {
      localStorage.setItem("id_token", responseData?.jwtToken);
      userDispatch({ type: "LOGIN_SUCCESS" });
      props.history.push('/app/account');
    }

    if (error && responseData?.message) {
      Swal.fire({
        icon: 'question',
        title: 'Oops...',
        text: responseData?.message,
      })
    }
  }, [responseData, error]);

  function logOut() {
    loginAPI("/v1/user/signout", "POST", {});
    signOut(userDispatch, props.history);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault();
      processVerify();
    }
  };

  return (<Grid container className={classes.container}>
    <div className={classes.formContainer}>
      <React.Fragment>
        <Typography variant="h1" align={"center"}><img src={logo} alt={"GainCity"} /></Typography>
        <form className={"login"}>
          <TextField
            id="token"
            label={"Enter 2FA code"}
            value={twoFAToken}
            onChange={e => setTwoFAToken(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus={true}
            type="text"
            fullWidth
            variant="outlined"
          />
          <div className="action">
            <Button
              onClick={processVerify}
              hidden={true}
              className={"primary"}
            >
              Verify
            </Button>
            <Link onClick={logOut} to="#">Back</Link>
          </div>
        </form>
      </React.Fragment>
    </div>
  </Grid>);
};

export default withRouter(TwoFAVerification);
