import { Switch } from "@mui/material";
import { convertThisToCurrency } from "../../../../../helper/convert-this-to-currency";

export const FnbFoodPopupTableColumnConfig = (
  handleItemEnable
) => {
  let columns = [];

  columns = [
    ...columns,
    {
      width: "80px",
      center: true,
      selector: (row) => row.selected,
      cell: (row, index) => {
        if (row.selected !== undefined) {
          return (
            <Switch
              checked={row.selected}
              onChange={(e, value) => handleItemEnable(row)}
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        }
        return null;
      },
      sortable: false,
    },
    {
      name: "SKU",
      width: "90px",
      selector: (row) => row.sku,
      cell: (row) => {
        return <>{row.sku}</>;
      },
      sortable: true,
      sortField: "sku",
    },
    {
      name: "Name",
      width: "300px",
      selector: (row) => row.name.toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "name",
    },
    {
      name: "Unit Price",
      width: '100px',
      right: true,
      selector: row => row.price,
      cell: (row, index, column, id) => {
        return convertThisToCurrency(row.price.toFixed(2))
      },
      sortable: true,
      sortField: "price",
    },
    {
      name: "Category",
      minWidth: "250px",
      selector: (row) => row.category.name.toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "category.name",
    },
  ];

  return columns;
};
