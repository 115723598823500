import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    Autocomplete, Backdrop, CircularProgress,
    Grid, TextField, Typography, InputAdornment, IconButton
} from "@mui/material";
import {
    KeyboardArrowDown as ArrowDownIcon,
    PlaylistPlay as FilterIcon, ArrowBack as BackIcon,
    Clear as ClearIcon, Event as CalendarIcon
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { toggleRightbar, useLayoutState, useLayoutDispatch, setRighbarContent } from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import { taskTrackingTableColumnConfig } from "./data-table/task-tracking-table-column-config";
import { getFromDate } from "../../helper/list-filters-helper";
import MobileTaskTrackingFilters from "./components/Mobile/MobileTaskTrackingFilters";
import TaskTimelineDrawer from "./components/Drawer/TaskTimelineDrawer";
import { TIME_SLOT_STATUS } from "../../data/constants";
import moment from 'moment';
import { currentSlotInfo, getTimeline } from "./helper/task-timeline";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DateFilter from "../../components/DateFilter";

const TaskTrackingList = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();
    // redirect to default page if not authorized
    if(auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.task_tracking,PrivilegeActions.view_list)){ 
        props.history.push('/app/dashboard');
    }

    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    const [staffList, setStaffList] = useState([]);
    const [taskTrackingList, setTaskTrackingList] = useState([]);
    const [timelineColumns, setTimelineColumns] = useState([]);

    const [dateFilter, setDateFilter] = useState(getFromDate(new Date()));
    const [staffFilter, setStaffFilter] = useState(null);
    const [sortBy, setSortBy] = useState({ column: undefined, direction: undefined });
    const [refresh, setRefresh] = useState(false); // refresh list

    // pagination states
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collectionSize, setCollectionSize] = useState(10);

    const mobileFilterProps = { staffFilter, setStaffFilter, staffList };
    const smallDeviceWidth = 1200;

    useEffect(() => {
        document.title = "Task Tracking List";
        if (auth.checkModulePrivilege(PrivilegeModules.task_tracking, PrivilegeActions.show_staff_filter)) {
            loadStaffList();
        }        
    }, []);

    // filter data
    useEffect(() => {
        if (refresh) {
            filter();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => { setRefresh(true); }, [dateFilter, staffFilter]);

    useEffect(() => {
        if (width <= smallDeviceWidth) { // set filter right bar
            setRighbarContent(layoutDispatch,
                [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
                <MobileTaskTrackingFilters key={"rb-body"} {...mobileFilterProps} />],
                { size: 'xs', backButton: true }); // filter content
        }
    }, [staffList, staffFilter, width]);

    const columns = taskTrackingTableColumnConfig(
        {
            allowedViewDetail : auth.checkModulePrivilege(PrivilegeModules.task_tracking,PrivilegeActions.view_detail)
        },
        timelineColumns, 
        onClickStaff, onClickTimeSlot);

    function filter() {
        // set list filters
        getPaginatedData(page, pageSize, getFilterValues(), sortBy)
            .then(response => {
                const taskTrackingList = response.data?.taskTrackingList || [];
                setTaskTrackingList(taskTrackingList);
                setCollectionSize(response.data?.total);
                const start = response.data?.taskTrackingStart;
                const end = response.data?.taskTrackingEnd;
                setTimelineColumns(getTimeline(start.hours, end.hours));
                return () => {
                    return taskTrackingList;
                };
            });
    }

    function getFilterValues() {
        return {
            date: dateFilter ? getFromDate(dateFilter) : getFromDate(moment().toDate()),
            staff: staffFilter?._id ?? null 
        };
    }

    function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
        return sendRequest(
            `/v1/task-tracking/filter?filters=${JSON.stringify(filters)}&page=${page}&pageSize=${pageSize}
        ${(sortBy?.column && sortBy?.direction) ?
                ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {});
    }

    // populate staff user list 
    function loadStaffList() {
        sendRequest(`/v1/user/get-by-user-department`, "GET", {})
            .then(response => {
                setStaffList(response.data?.users || []);
            });
    }

    function sortByColumn(columnConfig, sortDirection) {
        if (columnConfig.sortField && sortDirection) {
            setSortBy({
                column: columnConfig.sortField,
                direction: sortDirection
            });
            setRefresh(true);
        }
    }

    function handlePageChanged(page) {
        setPage(page);
        setRefresh(true);
    }

    function handleRowsPerPageChanged(count) {
        setPageSize(count);
        setRefresh(true);
    }

    function loadTimeline(row, highlightedSlot = undefined) {
        let timeline = timelineColumns.map(ts => {
            let lines = [];
            const item = row.timeline.find(t => t.timeSlot === ts.value);
            if (item && item.description) {
                if (item.description.includes('\n')) {
                    lines = item.description?.split('\n');
                } else { lines = [item.description]; }
            }
            return {
                staff: row.staffUser?.username,
                timeSlot: ts.label,
                slot: ts.value,
                lines,
                highlighted: item?.description ? highlightedSlot === ts.value : false,
                slotInfo: currentSlotInfo(ts, row.date, row.cutoffDate, row.timeline)
            };
        });

        if (highlightedSlot) {
            const hasHighlighted = timeline.findIndex(i => i.highlighted) > -1;
            if (!hasHighlighted) {
                timeline = getHighlightedTimeline(timeline, highlightedSlot);
            }
        }

        loadRightSideContent(true, false, [...timeline]);
        if (!layoutState.isRightbarOpened) { toggleRightbar(layoutDispatch); }
    }

    function getHighlightedTimeline(timeline, slot) {
        const index = timeline.findIndex(i => i.slot === slot);
        const item = timeline.slice(0, index).findLast(i => i.lines.length > 0);
        if (item) { item.highlighted = true; }
        return timeline;
    }

    function onClickStaff(row) {
        if (row.timeline && timelineColumns) {
            loadTimeline(row);
        }
    }

    function onClickTimeSlot(row, slotInfo) {
        if (slotInfo?.status === TIME_SLOT_STATUS.Allocated && auth.checkModulePrivilege(PrivilegeModules.task_tracking,PrivilegeActions.view_detail)) {
            if (row.timeline && timelineColumns) {
                loadTimeline(row, slotInfo.slot);
            }
        } else if(auth.checkModulePrivilege(PrivilegeModules.task_tracking,PrivilegeActions.add)) {
            if (layoutState.isRightbarOpened) { toggleRightbar(layoutDispatch); }
            props.history.push(`card/${row.staffUser?._id}/${moment(row.date).format('YYYY-MM-DD')}/${slotInfo?.slot}`);
        }
    }

    function loadRightSideContent(backButton, showBackDropInLargeScreen, timeline) {
        const size = width <= smallDeviceWidth ? 'xs' : 'md';
        setRighbarContent(layoutDispatch,
            [<TaskTimelineDrawer key={'rb-body'} timeline={timeline} />],
            { size, backButton, showBackDropInLargeScreen });
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>Task Tracking List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                          <DateFilter
                            label={"Date"}
                            value={dateFilter}
                            onChange={(newValue) => setDateFilter(newValue)}
                          />
                        </Grid>
                        {
                            width >= smallDeviceWidth ? (<>
                                {auth.checkModulePrivilege(PrivilegeModules.task_tracking,PrivilegeActions.show_staff_filter) && (<Grid item xs={6} sm={6} md={2} lg={2}>
                                    <Autocomplete
                                        id={"staff-filter"}
                                        options={staffList}
                                        getOptionLabel={(option) =>
                                            `${option?.username}-${option?.name}`}
                                        value={staffFilter}
                                        onChange={(e, newValue) => setStaffFilter(newValue)}
                                        renderInput={(params) => <TextField {...params}
                                            label={"Employee"} variant={"outlined"} />}
                                        popupIcon={<ArrowDownIcon />}
                                    />
                                </Grid>)}
                            </>) :
                                auth.checkModulePrivilege(PrivilegeModules.task_tracking, PrivilegeActions.show_staff_filter) &&
                                (<Grid item xs={2}>
                                    <IconButton
                                        onClick={() => toggleRightbar(layoutDispatch)}
                                        className={classes.drawerToggleBtn}>
                                        {layoutState.isRightbarOpened ? (<BackIcon />)
                                            : (<FilterIcon className={classes.toggleRightBarIcon} />)}
                                    </IconButton>
                                </Grid>)
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}
                                data={taskTrackingList}
                                sortServer
                                onSort={sortByColumn}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationPerPage={pageSize}
                                paginationTotalRows={collectionSize}
                                onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
                                onChangePage={(page) => handlePageChanged(page)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default withRouter(TaskTrackingList);    