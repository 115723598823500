import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogActions, TextField, InputAdornment } from '@mui/material';
import { useHttpRequest, useRoleAuthorization } from '../../../hooks';
import { PrivilegeActions, PrivilegeModules } from '../../../data/privileges.enum';
import { useForm } from "react-hook-form";

const COLLECT_MONEY_URL = '/v1/fnb-order/settle-cash-payment';

export default function CollectMoney({ order, onSuccess, onError }) {
  const { sendRequest, isLoading } = useHttpRequest();
  const { auth } = useRoleAuthorization();
  const [openDiaPaidAndChange, setOpenDiaPaidAndChange] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors: formErrors },
  } = useForm({ mode: "all", defaultValues: { paidAmt: order.grandTotal.toFixed(2) } });

  const handleClick = () => {
    // resetAmt();
    setOpenDiaPaidAndChange(true);
  };

  function handleProceed() {
    setOpenDiaPaidAndChange(false);

    sendRequest(
      COLLECT_MONEY_URL,
      'POST',
      {
        'transactionId': order?.paymentTransactionId,
      },
    ).then(response => {
      onSuccess(response?.data);
    }).catch(error => {
      onError(error);
    });
  }

  const isAbleToShow = () => {
    return auth.isPrivilegeDataLoaded() && auth.checkModulePrivilege(PrivilegeModules.fnb_order, PrivilegeActions.fnb_order_settle_cash_payment)
      && order && !order.isPaid && (order?.payment || order?.__payment__)?.paymentMethod?.toUpperCase() === 'CASH';
  };

  function closeDiaPaidAndChange() {
    setOpenDiaPaidAndChange(false);
  }

  const paidAmt = watch('paidAmt', order.grandTotal)
  useEffect(() => {
    setValue('changeAmt', (paidAmt - order.grandTotal).toFixed(2));
  }, [paidAmt])

  const handleFocus = (event) => {
    const input = event.currentTarget;
    setTimeout(() => {
      input.select()
    })
  }

  return (<>
    {isAbleToShow() && <Button className={'primary'} disabled={isLoading} onClick={handleClick}>Collect Money</Button>}

    <Dialog
      fullWidth={false}
      open={openDiaPaidAndChange}
      maxWidth={false}
      onClose={closeDiaPaidAndChange}
      className={"cashier"}
    >
      <form onSubmit={handleSubmit(handleProceed)}>
        <DialogContent>
          <TextField
            id={"paidAmt"}
            className={"cash-collect"}
            label={"Collect"}
            variant={"outlined"}
            autoComplete={"off"}
            {...register("paidAmt", {
              required: {
                value: false,
              },
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Collect amount must contain only numbers (0-9).",
              },
            })}
            onFocus={handleFocus}
            autoFocus={true}
            error={!!formErrors?.paidAmt}
            helperText={formErrors?.paidAmt?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <TextField
            id={"changeAmt"}
            className={"cash-change"}
            label={"Change"}
            type={"number"}
            variant={"outlined"}
            {...register("changeAmt", {
              required: {
                value: false,
              },
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Change amount must contain only numbers (0-9).",
              },
            })}
            error={!!formErrors?.changeAmt}
            helperText={formErrors?.changeAmt?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            disabled={true}
          />
        </DialogContent>
        <DialogActions>
          <Button className={"btn-confirm"} type="submit">Proceed</Button>
          <Button className={"btn-cancel"} onClick={closeDiaPaidAndChange}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  </>);
}
