import { IconButton, Switch } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";

export const fnbStoreTableColumnConfig = ({allowedViewDetail, allowedDelete, allowedEnableDisable, allowedEdit}, handleNameClick, handleDeleteFnbStore, handleEditFnbStore) => {
    let columns = [
        {
            name: "Store Code",
            width: "100px",
            selector: row => row.storeCode,
            cell: (row) => {
                if (allowedViewDetail) {
                    return (<Link to={`/app/fnb-store/card/${row.storeId}`} onClick={(e) => handleNameClick(row.storeId)}>{row.storeCode}</Link>)
                } else {
                    return (<>{row.storeCode}</>)
                }
            },            
            sortable: true,
            wrap: true,
            sortField: 'storeCode'
        },
        {
            name: "Name",
            width: "200px",
            selector: row => row.name,
            cell: (row) => {
                if (allowedViewDetail) {
                    return (<Link to={`/app/fnb-store/card/${row.storeId}`} onClick={(e) => handleNameClick(row.storeId)}>{row.name}</Link>)
                } else {
                    return (<>{row.name}</>)
                }
            },            
            sortable: true,
            wrap: true,
            sortField: 'name'
        },
        {
            name: "Location",
            minWidth: "400px",
            selector: row => row.location,
            sortable: true,
            wrap: true,
            sortField: 'location'
        },
        {
            name: "Default",
            width: '80px',
            center: true,
            selector: row => row.isDefault,
            cell: (row) => {
                return (<Switch
                    checked={row.isDefault}
                    onChange={(e, value) => handleEditFnbStore(row, 'isDefault', true, value)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled= {!allowedEdit}
                />)
            },
            sortable: true,
            sortField:'isDefault'
        },
        {
            name: "Enable",
            width: '80px',
            center: true,
            selector: row => row.status,
            cell: (row) => {
                return (<Switch
                    checked={row.status}
                    onChange={(e, value) => handleEditFnbStore(row, 'status', true, value)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled= {!allowedEdit}
                />)
            },
            sortable: true,
            sortField:'status'
        }
    ];    


    if (allowedDelete) {
        columns = [...columns,
            {
                name: "",
                right: true,
                width: '50px',
                cell: (row) => {
                    return (<>
                        {allowedDelete && (<>
                            <IconButton onClick={(e) => handleDeleteFnbStore(row)}><DeleteOutline /></IconButton></>
                        )}
                    </>)
                }
            }
        ]
    }

    return columns;
}