import React from "react";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { KeyboardArrowDown, PlaylistPlay, ArrowBack } from "@mui/icons-material";

export default function MobileFnbStoreFilters({
    fnbStores, storeFilter, onChangeStoreFilter, fnbCategories, categoryFilter, setCategoryFilter, noStockFilter, setNoStockFilter, yesNoList, promoteFilter, setPromoteFilter, chefRecommendFilter, setChefRecommendFilter, vegetarianFilter, setVegetarianFilter, healthierChoiceFilter, setHealthierChoiceFilter, enableFilter, setEnableFilter, popularFilter, setPopularFilter
}) {
    return (<>
    </>);
};
