import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import {
  Backdrop, Typography, CircularProgress, Grid,
  TextField, Button, InputAdornment, IconButton
} from "@mui/material";
import { Search, KeyboardArrowDown, PlaylistPlay, ArrowBack } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import useStyles from "./styles";
import { useUserState } from "../../context/UserContext";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { MaterialItemColumnConfig } from "./data-table/material-item-column-config";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MobileMaterialFilters from "./components/Mobile/MobileMaterialFilters";
import { setRighbarContent, useLayoutDispatch, useLayoutState, toggleRightbar } from "../../context/LayoutContext";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const ManageMaterial = (props) => {
  const classes = useStyles();

  // layout state
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // page authorization 
  const { auth } = useRoleAuthorization();

  // handle loading & http
  const { isLoading, httpRequestError: error, responseData, sendRequest } = useHttpRequest();

  // list filters
  const [materialList, setListRequest] = useState([]);
  const [refreshMaterialList, setRefreshMaterialList] = useState(true);
  const [textFilter, setTextFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [materialGroupsFilter, setMaterialGroupsFilter] = useState(null);
  const [materialGroups, setMaterialGroups] = useState([])
  const [enableFilter, setEnableFilter] = useState(null);
  const [message, setMessage] = useState("");
  const { width } = useWindowDimensions();

  useEffect(() => { 
    document.title = "Material List"; 
    // redirect to default page if not authorized
    if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.material, PrivilegeActions.view_list)) {
      props.history.push('/app/dashboard');
    }

    sendRequest(`/v1/material-group/filter?filters={"enable":true}`, "GET", {})
      .then(response => {
        setMaterialGroups(response.data?.materialGroupList || []);
      });
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: 'success',
        title: "Success",
        text: message,
        willClose: setRefreshMaterialList(true)
      });
    }
  }, [message]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: responseData?.message
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    // fetch material item list
    if (refreshMaterialList) {
      sendRequest("/v1/material-item", "GET", {})
        .then(materialListResponse => {
          setListRequest(materialListResponse.data?.itemList || []);
          setRefreshMaterialList(false);
          return materialListResponse.data?.itemList;
        });
    }
  }, [refreshMaterialList]);

  // set material item data with filters
  const data = materialList.filter(item => {
    let filterFlag = true;

    // search box
    if (textFilter) {
      const compoundText = item.category.toLowerCase() + item.code.toLowerCase() + item.description.toLowerCase();
      filterFlag &= compoundText.includes(textFilter.toLowerCase());
    }

    // category dropdown
    if (categoryFilter) {
      filterFlag &= (item.category === categoryFilter);
    }

    if(materialGroupsFilter){
      filterFlag &= item?.withdrawalGroups?.some(group => group._id === materialGroupsFilter._id);
    }

    // enable / disable dropdown
    if (enableFilter) {
      filterFlag &= (item.enable === (enableFilter === 'Yes'));
    }
    return filterFlag;
  });

  // set columns in data table
  const columns = MaterialItemColumnConfig({
    allowedViewDetail: auth.checkModulePrivilege(PrivilegeModules.material, PrivilegeActions.view_detail),
    allowedEnableDisable: auth.checkModulePrivilege(PrivilegeModules.material, PrivilegeActions.enable_disable),
    allowedDelete:  auth.checkModulePrivilege(PrivilegeModules.material, PrivilegeActions.delete)
  }, handleEditItem, handleDeleteItem);

  // populate category list from existing item list
  const categoryList = useMemo(() => {
    return [...new Set(materialList.map(item => item.category))].sort();
  }, [materialList]);

  // enable disable list
  const enableDisableList = ['Yes', 'No'];

  // set right bar for small devices
  const smallDeviceWidth = 1200;
  const mobileFilterProps = {
    categoryList,
    enableDisableList,
    categoryFilter,
    setCategoryFilter,
    enableFilter,
    setEnableFilter,
    setMaterialGroupsFilter,
    materialGroups,
    materialGroupsFilter,
  };

  useEffect(() => {
    // set right bar drawer content for small devices
    if (width <= smallDeviceWidth) {
      setRighbarContent(layoutDispatch,
        [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
        <MobileMaterialFilters key={"rb-body"} {...mobileFilterProps} />], { size: 'xs', backButton: true }); // filter content
    }
  }, [categoryList, categoryFilter, enableFilter, width]);

  // handle edit / remove items from the list
  async function handleEditItem(item, toggle = false, toggleValue = false) {
    if (toggle) {
      item.enable = toggleValue; // set new enable value & update
      await sendRequest(`/v1/material-item/${item._id}`, "PUT", item);
    } else { props.history.push(`card/${item._id}`); }
  }

  function handleDeleteItem(item) {
    if (item) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to delete the material ${item.code}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      }).then(async result => {
        if (result.isConfirmed) {
          const result = await sendRequest(`/v1/material-item/${item._id}`, "DELETE");
          if (result?.status === 200 && result?.data?.deleted) {
            setMessage(`Material ${item.code} has been deleted.`);
          }
        }
      });
    }
  }

  return (<>
    {isLoading && <Backdrop style={{ zIndex: 1 }} open={isLoading}><CircularProgress color={"inherit"} /></Backdrop>}
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography variant={"h1"}>Material List</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
        {
          auth.checkModulePrivilege(PrivilegeModules.material, PrivilegeActions.add)
          && 
          <Button className={"primary"} onClick={() => props.history.push('new')}>New</Button>
        }
      </Grid>
      <Grid
        item xs={12} sm={12} md={12} lg={12}>
        <Grid className={"form"}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={10} sm={10} md={10} lg={2}>
              <TextField
                autoComplete={"off"}
                id={"material-item-searchbox"}
                variant={"outlined"}
                value={textFilter}
                onChange={(e) => setTextFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {
              width >= smallDeviceWidth ? (<>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <Autocomplete
                    id={"category-filter"}
                    options={categoryList}
                    getOptionLabel={(option) => option}
                    value={categoryFilter}
                    onChange={(e, newValue) => setCategoryFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Category"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <Autocomplete
                    id={"enable-filter"}
                    options={enableDisableList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={enableFilter}
                    onChange={(e, newValue) => setEnableFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Enable"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <Autocomplete
                    id={"withdrawal-filter"}
                    options={materialGroups}
                    getOptionLabel={(option) => option.name}
                    value={materialGroupsFilter}
                    onChange={(e, newValue) => setMaterialGroupsFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Withdrawal Group"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                  />
                </Grid>
              </>) :
                (<Grid item xs={2}>
                  <IconButton
                    onClick={() => toggleRightbar(layoutDispatch)}
                    className={classes.drawerToggleBtn}    >
                    {layoutState.isRightbarOpened ? (<ArrowBack className={classes.toggleRightBarIcon} />)
                      : (<PlaylistPlay className={classes.toggleRightBarIcon} />)}
                  </IconButton>
                </Grid>)
            }
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                fixedHeader={true}
                persistTableHead={true}
                columns={columns}
                data={data}
                pagination
                defaultSortFieldId={8}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
  );
};

export default withRouter(ManageMaterial);