import React, { useCallback, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputBase, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import './Styles.css';

export default function Floorplans({ auth, salesOrderNo, selectedItems, setSelectedItems, deletedItems, setDeletedItems, privModules, privActions}) { 
  const handleDeleteItem = (rowData) => {
    setSelectedItems(current => current.filter(element => {
      return element.id != rowData.id;
    }));;

    //trigger the removeFloorplan from parent with id.
    if(rowData.cloudUrl != ''){
      var deletedFileName = rowData.cloudUrl.substring(rowData.cloudUrl.lastIndexOf('/') + 1);
      setDeletedItems(current => [...current, deletedFileName]);
    }
  }

  const handleClickOpen = () => {
    document.getElementById("input-floorplan-upload").click();
  };

  function uploadMultipleFiles(e){
    const selectedFiles = e.target.files;
    for (let j = 0; j < selectedFiles.length; j++) {
      const id = Math.floor(Math.random() * 10000);
      setSelectedItems(current => [...current, { id: id, imageName: selectedFiles[j].name, image: selectedFiles[j], cloudUrl: selectedFiles[j].name, uploadedDate: '-', newItem: true}]);
    }
  }

  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // triggers when file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!(!salesOrderNo && auth.checkModulePrivilege(privModules.customer_existing_aircon,privActions.add)) && !(salesOrderNo && auth.checkModulePrivilege(privModules.customer_existing_aircon,privActions.edit))) {
        return;
    }

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const selectedFiles = e.dataTransfer.files;

      for (let j = 0; j < selectedFiles.length; j++) {
        if(!selectedFiles[j].type.includes("image/") && selectedFiles[j].type != 'application/pdf'){
          continue;
        }

        const id = Math.floor(Math.random() * 10000);
        setSelectedItems(current => [...current, { id: id, imageName: selectedFiles[j].name, image: selectedFiles[j], cloudUrl: '-', uploadedDate: '-', newItem: true}]);
      }
    }
  };

  const addItemColumnConfig = [
    {
      name: "Name",
      minWidth: "250px",
      cell: (row, index, column, id) => {
        if(row.newItem){
          return <p>{row.imageName}</p>
        }else{
          return <a href={row.cloudUrl} target = "_blank" download>{row.imageName}</a>
        }
      }
    },
    {
      name: "Uploaded Date",
      width: '170px',
      wrap: true,
      selector: row => row.uploadedDate,
    },
  ];

  if ((!salesOrderNo && auth.checkModulePrivilege(privModules.customer_existing_aircon,privActions.add)) || (salesOrderNo && auth.checkModulePrivilege(privModules.customer_existing_aircon,privActions.edit))) {
    addItemColumnConfig.unshift({
      name: "",
      width: "50px",
      button: true,
      cell: (row, index, column, id) => {
        return <IconButton onClick={(e) => { handleDeleteItem(row) }}><DeleteOutlineIcon /></IconButton>
      }
    });
  }  

  return (
    <>
      <div className="form-group">
          <input type="file" className="form-control" id="input-floorplan-upload" accept="image/*,.pdf" onChange={uploadMultipleFiles} multiple />
      </div>

      <Grid container className={"form"} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"sub-action"}>
            <Typography variant={"h2"}>Floor Plans</Typography>
            {
              ((!salesOrderNo && auth.checkModulePrivilege(privModules.customer_existing_aircon,privActions.add)) || (salesOrderNo && auth.checkModulePrivilege(privModules.customer_existing_aircon,privActions.edit)))
              && 
              <Button type={"button"} onClick={handleClickOpen}><AddIcon /></Button>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={"table"}>
            <DataTable
              columns={addItemColumnConfig}
              data={selectedItems}
              persistTableHead={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
