import axios from "axios";
import {useCallback, useState} from "react"
import Swal from "sweetalert2";

export const useHttpRequest = () => {
  const [statusCode, setStatusCode] = useState()
  const [responseData, setResponseData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [httpRequestError, setHttpRequestError] = useState(null)

  const baseUrl = process.env['REACT_APP_BACKEND_API_ENDPOINT'];

  const clearError = useCallback(() => {
    setHttpRequestError(null);
  })

  const sendRequest = useCallback(
    async (uri, method = "GET", data = null, headers = {}, options = {}) => {
      setIsLoading(true);
      setHttpRequestError(null)

      if (localStorage.getItem("id_token") && !headers['Authorization']) {
        headers['Authorization'] = `Bearer ${localStorage.getItem("id_token")}`;
      }

      try {
        const response = await axios.request({ method, data, url: uri, baseURL: baseUrl, headers, ...options });
        if (response.headers.hasOwnProperty('r-token')) {
          const token = response.headers['r-token'];
          token?.length > 0 && localStorage.setItem("id_token", token);
        }
        setResponseData(response.data)
        setStatusCode(response?.status)
        setIsLoading(false)
        return response;
      } catch (error) {
        if (error?.response?.headers.hasOwnProperty('app_redirect_to') && error?.response?.headers['app_redirect_to']) {
          window.location = error?.response?.headers['app_redirect_to'];
          return error;
        }

        if (!error?.response?.status) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
          })
          setIsLoading(false)
          throw error;
        }

        setResponseData(error?.response?.data)
        setStatusCode(error?.response?.status)
        setIsLoading(false)
        setHttpRequestError(true)

        throw error;
      }
    }, []
  );
  return {statusCode, responseData, isLoading, httpRequestError, sendRequest, clearError}
}
