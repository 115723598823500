import { withRouter } from "react-router-dom";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowDown,
  PlaylistPlay,
  Search as SearchIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useStyles from "./styles";
import { matrixTableColumnConfig } from "./data-table/matrix-table-column-config";
import MatrixFilterMobile from "./components/MatrixFilterMobile";
import Swal from "sweetalert2";

// **************************************************************

const MatrixList = (props) => {
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  const theme = useTheme();
  const matchMediaQuery = useMediaQuery(theme.breakpoints.down("md"));
  const enableOptions = ["Yes", "No"];
  const [enableFilter, setEnableFilter] = useState(null);
  const [matrixList, setMatrixList] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const layoutDispatch = useLayoutDispatch();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  // Matrix table column
  const columns = matrixTableColumnConfig(
    {
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.aircon_subscription_matrix,
        PrivilegeActions.view_detail,
      ),
      allowedEnableDisable: auth.checkModulePrivilege(
        PrivilegeModules.aircon_subscription_matrix,
        PrivilegeActions.enable_disable,
      ),
    },
    handleToggleStatus,
  );

  // ************************** FUNCTION **********************

  function filter() {
    const filters = {
      enabled: enableFilter,
      text: textFilter.trim(),
    };
    sendRequest(
      `/v1/aircon-subscription-matrix/filter?filters=${JSON.stringify(filters)}
      &page=${page}&pageSize=${pageSize}
      ${
        sortBy?.column && sortBy?.direction
          ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
          : ""
      }`,
      "GET",
      {},
    ).then((response) => {
      const matrixs = response.data?.matrixs || [];
      setMatrixList(matrixs);
      setCollectionSize(response.data?.total);
    });
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  }

  async function handleToggleStatus(data, column) {
    data[column] = !data[column];
    const updatedTemp = JSON.parse(JSON.stringify(data));
    await sendRequest(
      `/v1/aircon-subscription-matrix/${data._id}`,
      "PUT",
      updatedTemp,
    );
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  // ************************** HOOK **********************

  useEffect(() => {
    setRefresh(true);
  }, [enableFilter]);

  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (matchMediaQuery) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MatrixFilterMobile
            key={"rb-body"}
            enableOptions={enableOptions}
            enableFilter={enableFilter}
            setEnableFilter={setEnableFilter}
            handleKeyDown={handleKeyDown}
          />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [enableFilter, matchMediaQuery]);

  useEffect(() => {
    document.title = "Aircon Subscription Matrix List";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.aircon_subscription_matrix,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // ************************** RENDER **********************

  const titleRender = (
    <Typography variant={"h1"}>Aircon Subscription Matrix List</Typography>
  );

  const actionRender = auth.checkModulePrivilege(
    PrivilegeModules.aircon_subscription_matrix,
    PrivilegeActions.add,
  ) && (
    <Button className={"primary"} onClick={() => props.history.push("card")}>
      New
    </Button>
  );

  const filterRender = (
    <>
      <Grid item xs={10} sm={10} md={2} lg={2}>
        <TextField
          autoComplete={"off"}
          id={"text-searchbox"}
          variant={"outlined"}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {!matchMediaQuery ? (
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Autocomplete
            id={"enable-filter"}
            options={enableOptions}
            getOptionLabel={(option) => option.toUpperCase()}
            value={enableFilter}
            onChange={(e, newValue) => setEnableFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Enable"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
      ) : (
        <Grid item xs={2}>
          <IconButton
            onClick={() => toggleRightbar(layoutDispatch)}
            className={classes.drawerToggleBtn}
          >
            {layoutState.isRightbarOpened ? (
              <ArrowBack className={classes.toggleRightBarIcon} />
            ) : (
              <PlaylistPlay className={classes.toggleRightBarIcon} />
            )}
          </IconButton>
        </Grid>
      )}
    </>
  );

  const tableRender = (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <DataTable
        fixedHeader={true}
        persistTableHead={true}
        columns={columns}
        data={matrixList}
        sortServer
        onSort={sortByColumn}
        pagination
        paginationServer
        paginationDefaultPage={page}
        paginationPerPage={pageSize}
        paginationTotalRows={collectionSize}
        onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
        onChangePage={(page) => handlePageChanged(page)}
      />
    </Grid>
  );
  // ************************************************
  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {titleRender}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {actionRender}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {filterRender}
              {tableRender}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(MatrixList);
