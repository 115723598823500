import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  FormControlLabel,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useStoreState } from "../../../../context/StoreContext";
import moment from "moment";
import { KeyboardArrowDown } from "@mui/icons-material";
import { validateText } from "../../../../helper/validate-textfield";
import {
  PrivilegeActions,
  PrivilegeModules,
} from "../../../../data/privileges.enum";
import { useRoleAuthorization } from "../../../../hooks/useRoleAuthorization";
import { FormProvider } from "react-hook-form";
import TextFieldController from "../../../../components/TextFieldController";
import AutocompleteController from "../../../../components/AutocompleteController";
import SwitchController from "../../../../components/SwitchController";

export default function OrderPageWithdrawalView({
  currentOrder,
  formValidation,
  disableForm,
  withdrawlOptionList
}) {
  const { storeList } = useStoreState();
  const [selectedStore, setSelectedStore] = useState(null);
  const {
    register,
    formState: { errors: formErrors },
    setValue,
    getValues,
    watch,
    trigger,
    reset,
  } = formValidation;
  const { auth } = useRoleAuthorization();
  
  useEffect(() => {
    setValue("isWithdrawal", currentOrder?.isWithdrawal || false);
    if(withdrawlOptionList.length > 0 && currentOrder.staffId){
      setValue("staff", withdrawlOptionList.find((val) => val.username === currentOrder.staffId) ?? null);
    }
  }, [currentOrder, withdrawlOptionList]);

  useEffect(() => {
    if (storeList && currentOrder?.incrementId) {
      setSelectedStore(
        storeList
          .filter((store) => store.code === currentOrder.storeCode)
          .pop(),
      );
    }
  }, [currentOrder, storeList]);

  useEffect(() => {
    if (getValues("isWithdrawal") === false) {
      reset((val) => ({
        ...val,
        isWithdrawal: false,
        staff: null,
      }));
    }
  }, [getValues("isWithdrawal")]);

  return (
    <>
      {currentOrder?.incrementId &&
        auth.checkModulePrivilege(
          PrivilegeModules.material_request,
          PrivilegeActions.withdrawal,
        ) && (
          <FormProvider {...formValidation}>
            <Grid container className={"form"}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography variant={"h2"}>Staff Withdrawal</Typography>
                      <SwitchController
                        name={"isWithdrawal"}
                        disabled={disableForm || currentOrder?.incrementId}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <AutocompleteController
                          name={'staff'}
                          disabled={!getValues("isWithdrawal") || currentOrder?.incrementId}
                          required={getValues("isWithdrawal")}
                          label={'Staff'}
                          options={withdrawlOptionList}
                          getOptionLabel={(option) =>
                            `${option.name}-${option.username}`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        )}
    </>
  );
}
