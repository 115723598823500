import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    drawerToggleBtn: {
        marginTop: 10
    },
    toggleRightBarIcon: {
        fontSize: 28
    },
    moreDataSwitch: {
        marginTop: 8
    },
    moreDataSwitchMobile: {
        float: "right",
        marginRight: -10
    },
    seriesNoTotal: {
        marginTop: "1px",
        color: "#d32f2f !important"
    }
}));
