import React from "react";
import { Button } from "@mui/material";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { PrivilegeModules,PrivilegeActions } from "../../../../data/privileges.enum";

const ButtonApproveOrder = ({ auth, selectedItemsArr, setSuccessMessage, orderCode, formSubmitCaller, currentOrder, validationWrapper }) => {
  const handleSubmitForm = async (data) => {
    const orderData = {
      ...data,
      staffId: data?.staff?.username,
      staffName: data?.staff?.name,
      staffDepartment: data?.staff?.department,
      remark: data.remark.trim().toUpperCase(),
      items: selectedItemsArr.map(item => { return { itemId: item._id, itemQty: item.requestedQty } }),
    };
    await formSubmitCaller(
      '/v1/material-order/approve/' + orderCode,
      "POST",
      orderData
    ).then(result => {
      if (!result?.data?.error) {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: `Material request ${result.data.order.incrementId} has been approved.`,
          willClose: popup => {
            window.location.href = '/#/app/material-request/list'
          }
        })
      }
    })
  }

  const isAbleToShowButton = () => {
    if (currentOrder?.status !== "PENDING") {
      return false
    }

    return (auth.checkModulePrivilege(PrivilegeModules.material_request, PrivilegeActions.approve));
  }

  return (
    <>
      {isAbleToShowButton() && <Button className={"primary"} type={"button"} onClick={validationWrapper(handleSubmitForm)}>Approve</Button>}
    </>
  );
}

export default withRouter(ButtonApproveOrder);
