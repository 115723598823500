import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
    Backdrop, Button, CircularProgress, Grid,
    InputAdornment, TextField, Typography,
    Autocomplete, IconButton
} from "@mui/material";
import { Search, KeyboardArrowDown, PlaylistPlay, ArrowBack } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import useStyles from "./styles";
import DataTable from "react-data-table-component";
import { customerExistingAirconConfig } from "./data-table/customer-existing-aircon-config";
import { useLayoutDispatch, useLayoutState, toggleRightbar, setRighbarContent } from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MobileCEAFilters from "./components/Mobile/MobileCEAFilters";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const CustomerExistingAircon = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, httpRequestError: error, responseData, sendRequest } = useHttpRequest();

    // page authorization
    const { auth } = useRoleAuthorization();

    const [filteredData, setFilteredData] = useState([]);

    // filters
    const [textFilter, setTextFilter] = useState("");
    const [sortBy, setSortBy] = useState({ column: undefined, direction: undefined });
    const [floorplanFilter, setFloorplanFilter] = useState(null);
    const floorplanFilterOptions = ['Yes', 'No'];
    const [refresh, setRefresh] = useState(false); // refresh list
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    // pagination states
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collectionSize, setCollectionSize] = useState(10);

    // set right bar for small devices
    const smallDeviceWidth = 1200;
    const mobileFilterProps = {
        floorplanFilterOptions,
        floorplanFilter,
        setFloorplanFilter,
    };    

    useEffect(() => {
        // set right bar drawer content for small devices
        if (width <= smallDeviceWidth) {
          setRighbarContent(layoutDispatch,
            [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
            <MobileCEAFilters key={"rb-body"} {...mobileFilterProps} />], { size: 'xs', backButton: true }); // filter content
        }
    }, [floorplanFilter, width]);    

    useEffect(() => { 
        document.title = "Customers Existing Aircon Listing"; 

        //redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && (!auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon,PrivilegeActions.view_list))){
            props.history.push('/app/dashboard');
        }            
        
        setRefresh(true);
    }, []);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    // set columns in data table
    const columns = customerExistingAirconConfig({
        allowedViewDetail: auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon,PrivilegeActions.view_detail)
    }, handleEditCustomerExistingAircon);


    // set filtered data
    useEffect(() => {
        if (refresh) {
            filter();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (!textFilter) {
            setRefresh(true);
        }
    }, [textFilter]);

    useEffect(() => {
        setRefresh(true);
    }, [floorplanFilter]);    

    function filter() {
        // set list filters
        const filters = {
            text: textFilter,
            floorplan: floorplanFilter || undefined
        };
        // toggle more data
        sendRequest(
            `/v1/customer-existing-aircon/filter?filters=${JSON.stringify(filters)}&page=${page}&pageSize=${pageSize}
            ${(sortBy?.column && sortBy?.direction) ?
                ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {})
            .then(response => {
                const customerExistingAircons = response.data?.customerExistingAircons || [];
                setFilteredData(customerExistingAircons);
                setCollectionSize(response.data?.total);
                return () => {
                    return customerExistingAircons;
                };
            }
        );
    }

    async function handlePageChanged(page){
        setPage(page);
        setRefresh(true);
    }

    async function handleRowsPerPageChanged(count){
        setPageSize(count);
        setRefresh(true);
    }

    // edit or update status of a cea
    async function handleEditCustomerExistingAircon(cea, isSwitch = false, switchValue = undefined) {
        if (isSwitch) {
            cea.enabled = switchValue ? 'Yes' : 'No';
            await sendRequest(`/v1/customer-existing-aircon/${cea._id}`, "PUT", cea);
        } else { props.history.push(`card/${cea._id}`); }
    }

    function sortByColumn(columnConfig, sortDirection) {
        if (columnConfig.sortField && sortDirection) {
            setSortBy({
                column: columnConfig.sortField,
                direction: sortDirection
            });
            setRefresh(true);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value) { // perform search when Enter
            e.preventDefault();
            setRefresh(true);
        }
    };

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} />
        </Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>Customers Existing Aircon List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                {(auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon,PrivilegeActions.add)) &&
                    <Button className={"primary"} onClick={() => props.history.push('card')}>
                        New</Button>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                            <TextField
                                autoComplete={"off"}
                                id={"text-search-box"}
                                variant={"outlined"}
                                value={textFilter}
                                onChange={(e) => setTextFilter(e.target.value)}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={"start"}>
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        {
                            width >= smallDeviceWidth ? (
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                    <Autocomplete
                                        id={"floorplan-filter"}
                                        options={floorplanFilterOptions}
                                        getOptionLabel={(option) => option.toUpperCase()}
                                        value={floorplanFilter}
                                        onChange={(e, newValue) => setFloorplanFilter(newValue)}
                                        renderInput={(params) =>
                                            <TextField {...params} label={"Floor Plan"} variant={"outlined"} />}
                                        popupIcon={<KeyboardArrowDown />}
                                    />
                                </Grid>
                            ):
                            (<Grid item xs={2}>
                            <IconButton
                                onClick={() => toggleRightbar(layoutDispatch)}
                                className={classes.drawerToggleBtn}    >
                                {layoutState.isRightbarOpened ? (<ArrowBack className={classes.toggleRightBarIcon} />)
                                : (<PlaylistPlay className={classes.toggleRightBarIcon} />)}
                            </IconButton>
                            </Grid>)
                        }

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}
                                data={filteredData}
                                sortServer
                                onSort={sortByColumn}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationPerPage={pageSize}
                                paginationTotalRows={collectionSize}
                                onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
                                onChangePage={(page) => handlePageChanged(page)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>)
};

export default withRouter(CustomerExistingAircon);