import React from "react";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";

export default function MobileServiceClaimMatrixFilters({
    enableOptions, enableFilter, setEnableFilter
}) {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"enable-filter-mobile"}
                    options={enableOptions}
                    getOptionLabel={(option) => option?.toUpperCase()}
                    value={enableFilter}
                    onChange={(e, newValue) => setEnableFilter(newValue)}
                    renderInput={(params) => <TextField {...params}
                        label={"Enable"} variant={"outlined"} />}
                    popupIcon={<ArrowDownIcon />}
                />
            </Grid>
        </Grid>
    </>);
};
