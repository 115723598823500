import { Add } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import * as _ from "lodash";
import * as moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { setRighbarContent, toggleRightbar, useLayoutDispatch, useLayoutState } from "../../../../context/LayoutContext";
import { SMALL_DEVICE_WIDTH } from "../../../../data/constants";
import { PrivilegeActions } from "../../../../data/privileges.enum";
import { convertThisToCurrency } from "../../../../helper/convert-this-to-currency";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { paymentsTableConfig } from "../../data-table/payments-table-config";
import { getOutstandingBalance } from "../../helper/calculations";
import { PR_PANELS } from "../../helper/panels";
import PaymentReceivedDocumentsDrawer from "../Drawer/PaymentReceivedDocumentsDrawer";

const WorkflowPayments = (props) => {
    const [paymentRecord, setPaymentRecord] = useState({ record: null, index: -1 });
    const [lastRowValid, setLastRowValid] = useState(false);
    const [documents, setDocuments] = useState([]);
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    useEffect(() => {
        const totalPayment = _.sum(props.paymentList?.map(p => p.paymentAmount));
        const totalBillAmount = _.sum(props.paymentList?.map(p => p.billAmount));
        props.setTotalPayment(totalPayment);
        props.setTotalBillAmount(totalBillAmount);
        props.setOutstandingBalance(getOutstandingBalance(props.contractValueA, totalPayment));

        const lastRow = props.paymentList?.at(props.paymentList?.length - 1);
        setLastRowValid(props.paymentList?.length === 0 ||
            (!!lastRow?.paymentAmount && !!lastRow?.billAmount));
    }, [props.paymentList]);

    useEffect(() => {
        if (paymentRecord?.record && paymentRecord?.index > -1) {
            loadDocumentDrawer();
            onChangeRowValues(paymentRecord?.index, documents, 'documents');
        }
    }, [documents]);

    useEffect(() => {
        if (paymentRecord?.record && paymentRecord?.index > -1) {
            loadDocumentDrawer();
        }
    }, [props?.removedPaymentDocuments]);

    const hasPermissionToEdit = () =>
        props.hasPrivilege(PrivilegeActions.edit_payment_received);

    const drawerProps = {
        step: props?.step, status: props?.projectRequest?.status,
        permissionToEdit: hasPermissionToEdit(),
        documents, setDocuments,
        removedDocuments: props?.removedPaymentDocuments,
        setRemovedDocuments: props?.setRemovedPaymentDocuments,
        allowedFileTypes: props?.allowedFileTypes
    };

    const columns = paymentsTableConfig(hasPermissionToEdit(),
        onRemoveRow, onChangeRowValues, openDocumentDrawer);

    function createNewRow() {
        props.setPaymentList([...props.paymentList, {
            paymentAmount: 0,
            billAmount: 0,
            remarks: '',
            createdAt: moment().toDate(),
            updatedAt: null,
            documents: []
        }]);
    }

    function onRemoveRow(i) {
        const rows = [...props.paymentList];
        rows.splice(i, 1);
        props.setPaymentList([...rows]);
    }

    function onChangeRowValues(i, value, attribute) {
        const pList = [...props.paymentList];
        if (attribute === 'remarks') {
            value = value.toUpperCase();
        } else if (attribute === 'documents') {
            value = value ?? [];
        } else { value = +value; }
        pList.at(i)[attribute] = value;
        props.setPaymentList(pList);
    }

    async function openDocumentDrawer(paymentRecord, index) {
        if (paymentRecord) {
            setPaymentRecord({ record: paymentRecord, index });
            setDocuments(paymentRecord.documents ?? []);
            loadDocumentDrawer();
        }
    }

    function loadDocumentDrawer(backButton = true, showBackDropInLargeScreen = false) {
        const size = width <= SMALL_DEVICE_WIDTH ? 'xs' : 'lg';
        setRighbarContent(layoutDispatch,
            [<PaymentReceivedDocumentsDrawer key={'rb-body'}
                {...drawerProps} />],
            { size, backButton, showBackDropInLargeScreen });
        if (!layoutState.isRightbarOpened) { toggleRightbar(layoutDispatch); }
    }

    return (<>
        <Grid container className={"form"} id={PR_PANELS.PAYMENTS}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Typography variant={"h2"}>Payment Received</Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={"summary"}>
                                <Typography variant={"h3"}>
                                    Total Billed: {<span>{convertThisToCurrency(props.totalBillAmount?.toFixed(2) ?? 0)}</span>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={"summary"}>
                                <Typography variant={"h3"}>
                                    Total Received: {<span>{convertThisToCurrency(props.totalPayment?.toFixed(2))}</span>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={"summary"}>
                                <Typography variant={"h3"}>
                                    Contract Balance: {<span>{convertThisToCurrency(props.outstandingBalance?.toFixed(2))}</span>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={"table"}>
                        <DataTable
                            fixedHeader={true}
                            persistTableHead={true}
                            columns={columns}
                            data={props?.paymentList} />
                    </Grid>
                    {(props.hasPrivilege(PrivilegeActions.edit_payment_received)) &&
                        <Grid item xs={12} className={"table-action"}>

                            <IconButton
                                onClick={createNewRow}
                                disabled={!lastRowValid}>
                                <Add />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default WorkflowPayments;   