import { PrivilegeActions, PrivilegeModules } from '../../../../data/privileges.enum';
import { Button } from '@mui/material';
import { useRoleAuthorization } from '../../../../hooks';

export const Actions = ({ history }) => {
  const { auth } = useRoleAuthorization();

  return <>
    {auth.checkModulePrivilege(
      PrivilegeModules.fnb_voucher_setup,
      PrivilegeActions.add,
    ) && (
      <Button
        className={'primary'}
        onClick={() => history.push('fnb-voucher-setup/new')}
      >
        New
      </Button>
    )}
  </>;
};