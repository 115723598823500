import { CircularProgress, IconButton } from "@mui/material";
import { useState, useCallback, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

export function ProjectRequestPdfViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.2);
  const containerRef = useRef();
  const isDraggingRef = useRef(false);
  const startPointRef = useRef({ x: 0, y: 0 });
  const scrollPosRef = useRef({ x: 0, y: 0 });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onWheel = useCallback((event) => {
    if (event.ctrlKey) {
      event.preventDefault();
      const scaleChange = event.deltaY < 0 ? 0.1 : -0.1;
      setScale((prevScale) => {
        let newScale = prevScale + scaleChange;
        return Math.min(Math.max(newScale, 0.2), 5.0);
      });
    }
  }, []);

  useEffect(() => {
    const wheelOptions = { passive: false }; // Indicate that the event listener is not passive
    containerRef.current.addEventListener("wheel", onWheel, wheelOptions);

    return () => {
      containerRef.current.removeEventListener("wheel", onWheel);
    };
  }, [onWheel]);

  const onMouseDown = useCallback((event) => {
    isDraggingRef.current = true;
    startPointRef.current = {
      x: event.clientX,
      y: event.clientY,
    };
    scrollPosRef.current = {
      x: containerRef.current.scrollLeft,
      y: containerRef.current.scrollTop,
    };
    // Prevent text selection while dragging
    event.preventDefault();
  }, []);

  const onMouseMove = useCallback((event) => {
    if (isDraggingRef.current) {
      const dx = event.clientX - startPointRef.current.x;
      const dy = event.clientY - startPointRef.current.y;
      containerRef.current.scrollLeft = scrollPosRef.current.x - dx;
      containerRef.current.scrollTop = scrollPosRef.current.y - dy;
    }
  }, []);

  const onMouseUp = useCallback(() => {
    isDraggingRef.current = false;
  }, []);

  // Setup global event listeners for mousemove and mouseup
  useEffect(() => {
    const handleMouseMove = (event) => onMouseMove(event);
    const handleMouseUp = () => onMouseUp();

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  return (
    <>
      <div class="pdf-view">
        <div class="pdf-header">
          <IconButton aria-label="zoom in" onClick={() => setScale(scale + 0.1)}>
            <ZoomInIcon />
          </IconButton>
          <IconButton
            aria-label="zoom out"
            onClick={() => setScale(scale - 0.1)}
            disabled={scale <= 0.2}
          >
            <ZoomOutIcon />
          </IconButton>
          <span>Zoom: {Math.round(scale * 100)}%</span>
        </div>

        <div
          ref={containerRef}
          class="pdf-content"
          style={{
            cursor: isDraggingRef.current ? "grabbing" : "grab",
          }}
          onWheel={onWheel}
          onMouseDown={onMouseDown}
        >
          <Document
            file={props.pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode={"canvas"}
            loading={<CircularProgress />}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scale}
                renderTextLayer={false}
              />
            ))}
          </Document>
        </div>
      </div>
    </>
  );
}
