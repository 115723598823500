export function getProfit(contractValue, totalCost) {
    return contractValue - totalCost;
}

export function getProfitMargin(profit, contractValue) {
    return profit / contractValue * 100;
}

export function getOutstandingBalance(contractValue, totalPayment) {
    return contractValue - totalPayment;
}

export function getActualRecordOutstanding(cost, paidAmount) {
    return cost - paidAmount;
}