import { useFormContext } from "react-hook-form";
import * as React from "react";
import { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import {
  CheckBoxOutlineBlank as CheckBoxIcon,
  CheckBoxOutlined as CheckBoxCheckedIcon,
} from "@mui/icons-material";

export function MultipleOption({ addOn, onChange }) {
  const fieldName = `addOns[addon_${addOn.entityId}]`;
  const { register, setValue, control, getValues } = useFormContext();
  const [selectedItems, setSelectedItems] = useState(
    getValues(fieldName) || [],
  );

  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    let newList = [];
    if (isPresent !== -1) {
      newList = selectedItems.filter((item) => item !== value);
    } else {
      newList = [...selectedItems, value];
    }
    setSelectedItems(newList);
    onChange(newList);
  };

  useEffect(() => {
    setValue(fieldName, selectedItems);
  }, [fieldName, selectedItems, setValue]);

  return (
    <>
      <FormGroup>
        {addOn.options.map((item, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                value={item.entityId}
                checkedIcon={<CheckBoxCheckedIcon />}
                icon={<CheckBoxIcon />}
                {...register(fieldName, {
                  // onChange: handleChange(i),
                  required: {
                    value: addOn.isMandatory,
                    message: `You must select at least 1 option.`,
                  },
                })}
                onChange={() => handleSelect(item.entityId)}
                checked={selectedItems
                  .map((opId) => +opId)
                  .includes(item.entityId)}
              />
            }
            value={selectedItems}
            label={
              <>
                <div className={"addon-item-name"}>{item.name}</div>
              </>
            }
          />
        ))}
      </FormGroup>
    </>
  );
}
