import React from "react";
import { Grid, Autocomplete, TextField } from "@mui/material";
import {
  Search as SearchIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  KeyboardArrowDown,
} from "@mui/icons-material";

export default function MobileMachineStoreFilters({
  areaFilter,
  setAreaFilter,
  enableFilter,
  setEnableFilter,
  areaList,
  yesNoList,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"area-filter"}
            options={areaList}
            getOptionLabel={(area) => `${area.areaCode.toUpperCase()}`}
            value={areaFilter}
            onChange={(e, newValue) => setAreaFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Area"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"enable-filter"}
            options={yesNoList}
            getOptionLabel={(option) => option.toUpperCase()}
            value={enableFilter}
            onChange={(e, newValue) => setEnableFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Enable"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
      </Grid>
    </>
  );
}
