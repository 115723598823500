import { withRouter } from "react-router-dom";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowDown,
  PlaylistPlay,
  Search as SearchIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useStyles from "./styles";
import { calendarTableColumnConfig } from "./data-table/calendar-table-column-config";
import CalendarFilterMobile from "./components/CalendarFilterMobile";

const CalendarList = (props) => {
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  const theme = useTheme();
  const matchMediaQuery = useMediaQuery(theme.breakpoints.down("md"));
  const enableOptions = ["Yes", "No"];
  const [enableFilter, setEnableFilter] = useState(null);
  const [calendarList, setCalendarList] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const layoutDispatch = useLayoutDispatch();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  // Calendar table column
  const columns = calendarTableColumnConfig(
    {
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.group_buy_calendar,
        PrivilegeActions.view_detail,
      ),
      allowedEnableDisable: auth.checkModulePrivilege(
        PrivilegeModules.group_buy_calendar,
        PrivilegeActions.enable_disable,
      ),
    },
    handleToggleStatus,
  );

  const filter = () => {
    const filters = {
      enabled: enableFilter,
      text: textFilter.trim(),
    };
    sendRequest(
      `/v1/group-buy-calendar/filter?filters=${JSON.stringify(filters)}
      &page=${page}&pageSize=${pageSize}
      ${
        sortBy?.column && sortBy?.direction
          ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
          : ""
      }`,
      "GET",
      {},
    ).then((response) => {
      const calendars = response.data?.calendars || [];
      setCalendarList(calendars);
      setCollectionSize(response.data?.total);
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  useEffect(() => {
    setRefresh(true);
  }, [enableFilter]);

  // set filtered data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  // Responsive filter
  useEffect(() => {
    if (matchMediaQuery) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <CalendarFilterMobile
            key={"rb-body"}
            enableOptions={enableOptions}
            enableFilter={enableFilter}
            setEnableFilter={setEnableFilter}
            handleKeyDown={handleKeyDown}
          />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [enableFilter, matchMediaQuery]);

  // Set page tilte
  // Check Privilege
  useEffect(() => {
    document.title = "Group Buy Calendar List";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.group_buy_calendar,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  async function handleToggleStatus(data, column) {
    data[column] = !data[column];
    const updatedTemp = JSON.parse(JSON.stringify(data));
    await sendRequest(`/v1/group-buy-calendar/${data._id}`, "PUT", updatedTemp);
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Group Buy Calendar List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.group_buy_calendar,
            PrivilegeActions.add,
          ) && (
            <Button
              className={"primary"}
              onClick={() => props.history.push("card")}
            >
              New
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={2} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-searchbox"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {!matchMediaQuery ? (
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <Autocomplete
                    id={"enable-filter"}
                    options={enableOptions}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={enableFilter}
                    onChange={(e, newValue) => setEnableFilter(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Enable"}
                        variant={"outlined"}
                      />
                    )}
                    popupIcon={<KeyboardArrowDown />}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => toggleRightbar(layoutDispatch)}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <ArrowBack className={classes.toggleRightBarIcon} />
                    ) : (
                      <PlaylistPlay className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={calendarList}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(CalendarList);
