import { withRouter } from "react-router-dom";
import {
  Grid,
  InputAdornment,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Search as SearchIcon, KeyboardArrowDown } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { PageWrapper } from "./components/PageWrapper";
import { Actions } from "./components/Listing/Actions";
import { useRoleAuthorization } from "../../hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { columns } from "./listing-columns.config";
import { useVoucherSetupList } from "./api/useVoucherSetupList";
import { useVoucherSetupSave } from "./api/useVoucherSetupSave";
import {
  triggerConfirmationMessage,
  triggerErrorMessage,
  triggerSuccessMessage,
} from "./helper/notification-helper";
import { useVoucherSetupDelete } from "./api/useVoucherSetupDelete";

const FnbVoucherSetupList = (props) => {
  const { auth } = useRoleAuthorization();
  const { getListVoucherRules } = useVoucherSetupList();
  const { updateVoucherRule: updateRowData } = useVoucherSetupSave();
  const { isLoading: voucherDeleting, deleteVoucherRule } =
    useVoucherSetupDelete();
  const [attributeFilters, setAttributeFilters] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [textFilter, setTextFilter] = useState("");
  const refreshPage = useCallback(() => {
    !refresh && setRefresh(true);
  }, [refresh]);
  const enableDisableList = ["YES", "NO"];
  const [tableData, setTableData] = useState({
    data: [],
    collectionSize: 1,
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refreshPage();
    }
  };

  const handleInlineChange = (row, field, value) => {
    updateRowData(row.incrementId, { [field]: value }).then((response) => {
      setRefresh(true);
    });
  };
  const handleDelete = (row) => {
    triggerConfirmationMessage(
      `Are you sure you want to delete the F&B voucher setup ${row.incrementId}?`,
    )
      .then(async (result) => {
        result.isConfirmed &&
          (await deleteVoucherRule(row.incrementId).then((res) => {
            triggerSuccessMessage(
              `F&B voucher setup ${row.incrementId} has been deleted.`,
            );
            setRefresh(true);
            return res;
          }));
      })
      .catch((err) =>
        triggerErrorMessage(err.response?.data?.message || err.message),
      );
  };

  const columnConfig = useMemo(
    () =>
      columns(
        handleInlineChange,
        auth.checkModulePrivilege("", "") && handleDelete,
      ),
    [auth, handleInlineChange],
  );
  const [dataTableConfig, setDataTableConfig] = useState({
    columns: columnConfig,
    sortField: "createdAt",
    sortOrder: "desc",
    page: 1,
    pageSize: 10,
  });

  const sortByColumn = useCallback(
    (columnConfig, sortDirection) => {
      setDataTableConfig({
        ...dataTableConfig,
        sortField: columnConfig.sortField.sortField,
        sortOrder: sortDirection,
      });
      refreshPage();
    },
    [dataTableConfig, refreshPage],
  );

  const handleChangeRowsPerPage = useCallback(
    (pageSize) => {
      setDataTableConfig((prev) => {
        return { ...prev, pageSize };
      });
      refreshPage();
    },
    [refreshPage],
  );

  const handleChangePage = useCallback(
    (page) => {
      setDataTableConfig((prev) => {
        return { ...prev, page };
      });
      refreshPage();
    },
    [refreshPage],
  );

  useEffect(() => {
    document.title = "F&B Voucher Setup List";
  }, []);

  useEffect(() => {
    refresh &&
      getListVoucherRules(
        textFilter,
        attributeFilters,
        { page: dataTableConfig.page, pageSize: dataTableConfig.pageSize },
        {
          sortField: dataTableConfig.sortField,
          direction: dataTableConfig.sortField,
        },
      ).then((data) => {
        setTableData({ data: data.data, collectionSize: data.total });
        setRefresh(false);
      });
  }, [textFilter, attributeFilters, dataTableConfig, refresh]);

  const handleChangeFilter = (field, value, operation) => {
    if (value === undefined) {
      setAttributeFilters((prev) => ({ ...prev, [field]: undefined }));
    } else {
      setAttributeFilters((prev) => ({
        ...prev,
        [field]: { value, operation },
      }));
    }
    refreshPage();
  };

  return (
    <>
      <PageWrapper
        title={"F&B Voucher Setup List"}
        action={<Actions history={props.history} />}
      >
        <Grid className={"form"}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={10} sm={10} md={10} lg={2}>
              <TextField
                autoComplete={"off"}
                id={"text-searchbox"}
                variant={"outlined"}
                value={textFilter}
                onChange={(e) => setTextFilter(e.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={2}>
              <Autocomplete
                id={"enable-filter"}
                options={enableDisableList}
                getOptionLabel={(option) => option.toUpperCase()}
                value={attributeFilters?.isActive?.value}
                onChange={(e, value) =>
                  handleChangeFilter(
                    "isActive",
                    value ? (value === "YES" ? 1 : 0) : undefined,
                    "eq",
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Enable"}
                    variant={"outlined"}
                  />
                )}
                popupIcon={<KeyboardArrowDown />}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                fixedHeader={true}
                persistTableHead={true}
                columns={dataTableConfig.columns}
                data={tableData.data}
                sortServer
                onSort={sortByColumn}
                pagination
                paginationServer
                paginationDefaultPage={dataTableConfig.page}
                paginationPerPage={dataTableConfig.pageSize}
                paginationTotalRows={tableData.collectionSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
              />
            </Grid>
          </Grid>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default withRouter(FnbVoucherSetupList);
