import { useHttpRequest } from '../../../hooks';
import Swal from 'sweetalert2';

export function useVoucherSetupList() {
  const { isLoading, sendRequest, } = useHttpRequest();

  const getListVoucherRules = async (
    searchQuery,
    filters,
    pagination = { page: 1, pageSize: 10 },
    sortOrder = { sortField: 'createdAt', direction: 'desc' },
  ) => {
    try {
      const response = await sendRequest(
        `/v1/fnb-voucher-rule/search`,
        'POST',
        {
          query: searchQuery,
          filters,
          pagination,
          sortOrder,
        },
      );
      return { data: response?.data?.data?.voucherRules, total: response?.data?.data?.total };
    } catch (e) {
      Swal.fire(
        'Error while fetching voucher rule list',
        e?.response?.data?.message || e.message,
        'error',
      );
      throw e;
    }
  };

  return { isLoading, getListVoucherRules };
}