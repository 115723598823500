import Swal from 'sweetalert2';

export function triggerSuccessMessage(message = '', title = 'Success') {
  return Swal.fire({ icon: 'success', title: 'Success', text: message });
}

export function triggerErrorMessage(message = '', title = 'Error') {
  return Swal.fire({ icon: 'error', title: 'Error', text: message });
}

export function triggerConfirmationMessage(message, title = 'Confirmation') {
  return Swal.fire({
    title: title,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
  })
}