import React from 'react';
import { Button } from '@mui/material';
import { useHttpRequest } from '../../../hooks/useHttpRequest';
import { PrivilegeActions, PrivilegeModules } from '../../../data/privileges.enum';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import Swal from "sweetalert2";

const VOID_ORDER_URL = '/v1/fnb-order/void-order';

export default function VoidOrder({ order, onSuccess, onError }) {
  const { sendRequest, isLoading } = useHttpRequest();
  const {auth} = useRoleAuthorization();

  const handleClick = () => {
    Swal.fire({
      icon: "warning",
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Void",
      denyButtonText: "Cancel",
      title: `Confirmation`,
      text: `Do you want to void the order ${order.orderNumber}?`
    }).then(result => {
      if (result.isConfirmed) {
        sendRequest(
          VOID_ORDER_URL,
          'POST',
          {
            'transactionId': order?.paymentTransactionId,
          },
        ).then(response => {
          onSuccess(response?.data);
        }).catch(error => {
          onError(error);
        });
      }
    })
  };

  const isAbleToShow = () => {
    return auth.isPrivilegeDataLoaded() && auth.checkModulePrivilege(PrivilegeModules.fnb_order, PrivilegeActions.fnb_void_order)
      && order && !order.isPaid && (order?.payment || order?.__payment__)?.paymentMethod?.toUpperCase() === 'CASH';
  };

  return (<>
    {isAbleToShow() && <Button className={'primary'} disabled={isLoading} onClick={handleClick}>Void Order</Button>}
  </>);
}
