
import { DeleteOutline } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";

export const resourceTableColumnConfig = (
    onRemoveRow,
    onChangeResourceCode,
    checkForDuplicatedCodes,
    onChangeIsPercentageCheck,
    onChangeClaimValue,
    onKeyDownClaimValue) => [
        {
            name: "",
            width: "50px",
            button: true,
            cell: (row, index) => <IconButton onClick={() => onRemoveRow(index)}><DeleteOutline />
            </IconButton>
        },
        {
            name: "Code",
            width: "110px",
            cell: (row, index) => {
                return <InputBase
                    disabled={!!row._id}
                    variant={"outlined"}
                    value={row.resourceCode}
                    autoFocus={!row._id}
                    onBlur={(e) => checkForDuplicatedCodes(index, e.target.value)}
                    onChange={(e) => onChangeResourceCode(index, e.target.value)}
                />
            }
        },
        {
            name: "%",
            width: "80px",
            center: true,
            selector: row => row.isPercentageInput,
            cell: (row, index) => {
                return <CheckBox
                    color="primary"
                    checked={row.isPercentage}
                    onChange={(e) => onChangeIsPercentageCheck(index, e.target.checked)}
                />
            }
        },
        {
            name: "Claim Value",
            width: "110px",
            right: true,
            cell: (row, index) => {
                return <InputBase
                    type={"number"}
                    variant={"outlined"}
                    className={"dollar"}
                    value={row.claimValue}
                    onChange={(e) => onChangeClaimValue(index, e.target.value)}
                    onKeyDown={(e) => onKeyDownClaimValue(e)}
                />
            }
        },
    ];
