import { useHttpRequest } from '../../../hooks';
import Swal from 'sweetalert2';

export function useVoucherRuleQuery() {
  const { isLoading, sendRequest } = useHttpRequest();
  const queryVoucher = async (voucherId) => {
    if (!voucherId) {
      throw new Error('voucherId is required');
    }
    try {
      const response = await sendRequest(
        `/v1/fnb-voucher-rule/${voucherId}`,
        'GET',
      );
      return response.data;
    } catch (e) {
      Swal.fire(
        'Error during query voucher rule',
        e?.response?.data?.message || e.message,
        'error',
      );
      throw e;
    }
  };

  const queryVoucherRulesPrerequisites = async () => {
    try {
      const response = await sendRequest(
        `/v1/fnb-voucher-rule/get-prerequisites`,
        'GET',
      );
      return response.data;
    } catch (e) {
      Swal.fire(
        'Error during query prerequisites.',
        e?.response?.data?.message || e.message,
        'error',
      );
      throw e;
    }
  };

  return { isLoading, queryVoucher, queryVoucherRulesPrerequisites };
}