import { Clear as ClearIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./styles";

const Tag = ({ data, handleDelete }) => {
    const classes = useStyles();
    return (
        <Box className={classes.tag}>
            <Typography sx={{ margin: "0px" }}>{data}</Typography>
            <ClearIcon className={classes.tagClearIcon} onClick={() => handleDelete(data)} />
        </Box>
    );
};

export default Tag;