import { useCallback, useEffect, useState } from "react";
import { useHttpRequest } from "../../../hooks";

export function useStoreOptionsList() {
  const [storeList, setStoreList] = useState([]);
  const { isLoading, sendRequest, httpRequestError } = useHttpRequest();

  const fetchPresetList = useCallback(() => {
    const url = `/v1/fnb-order/stores`;

    return sendRequest(url, "GET", {})
      .then((responseData) => setStoreList(responseData?.data?.stores || []))
      .catch((e) => {
        setStoreList([]);
        throw e;
      });
  }, [sendRequest]);

  useEffect(() => {
    fetchPresetList();
  }, [fetchPresetList]);

  return {
    isLoading,
    storeList,
    refetch: fetchPresetList,
    error: httpRequestError,
  };
}
