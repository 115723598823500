import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";
import {
  Search as SearchIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  KeyboardArrowDown,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  useLayoutDispatch,
  useLayoutState,
  toggleRightbar,
  setRighbarContent,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import MobileMachineStoreFilters from "./components/Mobile/MobileMachineStoreFilters";
import { machineStoreTableColumnConfig } from "./data-table/machine-store-table-column-config";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import MachineStoreDrawer from "./components/MachineStoreDrawer";

const MachineStoreList = (props) => {
  const classes = useStyles();

  // handle loading & http
  const { isLoading, sendRequest } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  // layout state
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  // filters
  const [textFilter, setTextFilter] = useState("");
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  const [storeList, setStoreList] = useState([]);
  const smallDeviceWidth = 1200;

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  const [message, setMessage] = useState("");
  const yesNoList = ["Yes", "No"];
  const [enableFilter, setEnableFilter] = useState(null);
  const [loadMobileFilters, setLoadMobileFilters] = useState(false);
  const [areaFilter, setAreaFilter] = useState(null);
  const [areaList, setAreaList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyFilter, setCompanyFilter] = useState(null);
  const mobileFilterProps = {
    enableFilter,
    setEnableFilter,
    areaFilter,
    setAreaFilter,
    areaList,
    yesNoList,
    companyList,
    companyFilter,
    setCompanyFilter,
  };

  useEffect(() => {
    document.title = "Machine Store List";

    loadPrerequisites();

    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.machine_store,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: setRefresh(true),
      });
    }
  }, [message]);

  // filter data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    setRefresh(true);
  }, [enableFilter, areaFilter, companyFilter]);

  useEffect(() => {
    if (width <= smallDeviceWidth) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MobileMachineStoreFilters key={"rb-body"} {...mobileFilterProps} />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [storeList, width, loadMobileFilters]);

  async function loadRightSideContent(
    store,
    backButton,
    showBackDropInLargeScreen,
  ) {
    setLoadMobileFilters(false);

    //load Service Job
    let serviceJobResponse = await sendRequest(
      `/v1/machine-store/get-service-jobs-by-store-id/${store.storeId}`,
      "GET",
    );

    let serviceJob = serviceJobResponse.data.serviceJobs;

    serviceJob.forEach((j) => {
      let chkListUrl = "";
      let servJobUrl = "";

      if (j.serviceJobDoc.length > 1) {
        chkListUrl =
          j.serviceJobDoc[
            j.serviceJobDoc.findIndex((r) => r.docType == "check-list")
          ].imageUrl;
        servJobUrl =
          j.serviceJobDoc[
            j.serviceJobDoc.findIndex((r) => r.docType == "service-job")
          ].imageUrl;
      }

      j.checkListUrl = chkListUrl;
      j.serviceJobUrl = servJobUrl;
    });

    //preparing closedJobCnt for all the machine
    const closedJobCount = new Map();

    serviceJob.forEach((svj) => {
      if (svj.status == "CLOSED") {
        const machineId = svj.machineId;
        closedJobCount.set(machineId, (closedJobCount.get(machineId) || 0) + 1);
      }
    });

    console.log("closedJobCount", closedJobCount);

    store.storeMachine.forEach((sm) => {
      sm.closedJobCnt = closedJobCount.get(sm.machineId) || "";
    });
    //

    const size = width <= smallDeviceWidth ? "xs" : "lg";
    setRighbarContent(
      layoutDispatch,
      [
        <MachineStoreDrawer
          key={"rb-body"}
          storeData={store}
          storeMachineData={store.storeMachine}
          serviceJobData={serviceJob}
        />,
      ],
      { size, backButton, showBackDropInLargeScreen },
    );

    if (!layoutState.isRightbarOpened) {
      toggleRightbar(layoutDispatch);
    }
  }

  // set columns in data table
  const columns = machineStoreTableColumnConfig(
    {
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.machine_store,
        PrivilegeActions.view_detail,
      ),
      allowedDelete: auth.checkModulePrivilege(
        PrivilegeModules.machine_store,
        PrivilegeActions.delete,
      ),
      allowedEnableDisable: auth.checkModulePrivilege(
        PrivilegeModules.machine_store,
        PrivilegeActions.edit,
      ),
      allowedEdit: auth.checkModulePrivilege(
        PrivilegeModules.machine_store,
        PrivilegeActions.edit,
      ),
    },
    handleNameClick,
    handleDeleteMachineStore,
    handleEditMachineStore,
    loadRightSideContent,
  );

  const loadPrerequisites = () => {
    sendRequest(`/v1/machine-store/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        setCompanyList(prerequisites.companyList);
        setAreaList(prerequisites.areaList);
      },
    );
  };

  async function handleEditMachineStore(
    store,
    toggle = false,
    toggleValue = false,
  ) {
    if (toggle) {
      store.enable = toggleValue; // set new enable value & update
      let tmpStore = JSON.parse(JSON.stringify(store));
      await sendRequest(`/v1/machine-store/${store._id}`, "PUT", tmpStore);
    } else {
      props.history.push(`card/${store._id}`);
    }
  }

  function handleDeleteMachineStore(store) {
    if (store) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to delete the store ${store.storeId}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await sendRequest(
            `/v1/machine-store/${store._id}`,
            "DELETE",
          );
          if (result?.status === 200 && result?.data?.deleted) {
            setMessage(`Store ${store.storeId} has been deleted.`);
          }
        }
      });
    }
  }

  function filter() {
    // set list filters
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const stores = response.data?.storeList || [];
        setStoreList(stores);
        setCollectionSize(response.data?.total);
        return () => {
          return stores;
        };
      },
    );
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      enable: enableFilter,
      area: areaFilter ? areaFilter.areaCode : undefined,
      company: companyFilter ? companyFilter._id : undefined,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/machine-store/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${
          sortBy?.column && sortBy?.direction
            ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
            : ""
        }`,
      "GET",
      {},
    );
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  // go to edit view
  async function handleNameClick(storeId) {
    if (
      auth.checkModulePrivilege(
        PrivilegeModules.machine_store,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push(`card/${storeId}`);
    }
  }

  // open mobile filters drawer
  function openMobileFilters() {
    setLoadMobileFilters(true);
    toggleRightbar(layoutDispatch);
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Machine Store List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.machine_store,
            PrivilegeActions.add,
          ) && (
            <Button
              className={"primary"}
              onClick={() => props.history.push("card")}
            >
              New
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={10} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-searchbox"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {width >= smallDeviceWidth ? (
                <>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"company-filter"}
                      options={companyList}
                      getOptionLabel={(company) =>
                        `${company.name.toUpperCase()}`
                      }
                      value={companyFilter}
                      onChange={(e, newValue) => setCompanyFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Company"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"area-filter"}
                      options={areaList}
                      getOptionLabel={(area) =>
                        `${area.areaCode.toUpperCase()}`
                      }
                      value={areaFilter}
                      onChange={(e, newValue) => setAreaFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Area"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"enable-filter"}
                      options={yesNoList}
                      getOptionLabel={(option) => option.toUpperCase()}
                      value={enableFilter}
                      onChange={(e, newValue) => setEnableFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Enable"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => openMobileFilters()}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <BackIcon />
                    ) : (
                      <FilterIcon className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={storeList}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(MachineStoreList);
