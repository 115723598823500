import { DeleteOutline as DeleteIcon, AttachFile as FileIcon } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputBase, MenuItem, Select } from "@mui/material";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { getActualRecordOutstanding } from "../helper/calculations";
import { isVarientType } from "../helper/pr-config";

export const costMarginATableConfig = (
    itemList, editAllowed, isDisabled, onRemoveRow,
    onChangeRowValues, openDocumentDrawer) => {

    const onChangePaidAmount = (value, index, row) => {
        onChangeRowValues(index, value, 'paidAmount');
        const outstanding = getActualRecordOutstanding(+row.cost, +value);
        onChangeRowValues(index, outstanding ?? 0, 'outstanding');
    };

    const onChangeCost = (value, index, row) => {
        onChangeRowValues(index, value, 'cost');
        const outstanding = getActualRecordOutstanding(+value, +row.paidAmount);
        onChangeRowValues(index, outstanding ?? 0, 'outstanding');
    };

    const columns = [
        {
            name: "Document",
            width: "70px",
            cell: (row, index) =>
                <>
                    <IconButton
                        onClick={() => openDocumentDrawer(row, index)}>
                        <FileIcon />
                    </IconButton>
                    <small>{row.documents?.length ?? 0}</small>
                </>
        },
        {
            name: "Type",
            width: "50px",
            center: true,
            cell: (row) => <>{row.type}</>
        },
        {
            name: "Item",
            width: "210px",
            cell: (row, index) => {
                if (editAllowed && !isDisabled && isVarientType(row.type)) {
                    return (
                      <FormControl error={!row.description}>
                        <Select
                            key={`select-${index}`}
                            variant={"outlined"}
                            value={row.description}
                            onChange={(e) => onChangeRowValues(index, e.target.value, 'description')}
                            IconComponent={ArrowDownIcon}
                        >
                            {itemList.map((element, i) =>
                                <MenuItem
                                    key={`option-${i}`}
                                    value={element.toUpperCase()}
                                    selected={row.description === element.toUpperCase()}
                                >{element.toUpperCase()}</MenuItem>)}
                        </Select>
                        {!row.description && <FormHelperText>Item can not be blank</FormHelperText>}
                      </FormControl>
                    )
                }
                return <>{row.description}</>
            }
        },
        {
            name: "Cost",
            width: "110px",
            right: true, // (!editAllowed || isDisabled),
            cell: (row, index) => {
                if (editAllowed && !isDisabled) {
                    return <InputBase
                        type={"number"}
                        variant={"outlined"}
                        className={"dollar"}
                        value={row.cost ? row.cost : undefined}
                        inputProps={{
                            sx: { textAlign: 'start !important;' }
                        }}
                        onChange={(e) => onChangeCost(e.target.value, index, row)}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                }
                return <>{convertThisToCurrency(row.cost?.toFixed(2))}</>
            }
        },
        {
            name: "Price",
            width: "110px",
            right: true, // (!editAllowed || isDisabled),
            cell: (row, index) => {
                if (editAllowed && !isDisabled) {
                    return <InputBase
                        type={"number"}
                        variant={"outlined"}
                        className={"dollar"}
                        inputProps={{
                            sx: { textAlign: 'start !important;' }
                        }}
                        value={row.sellingPrice ? row.sellingPrice : undefined}
                        onChange={(e) => onChangeRowValues(index, e.target.value, 'sellingPrice')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                }
                return <>{convertThisToCurrency(row.sellingPrice?.toFixed(2))}</>
            }
        },
        {
            name: "Paid Amount",
            width: "110px",
            cell: (row, index) => {
                if (editAllowed && !isDisabled) {
                    return <InputBase
                        type={"number"}
                        variant={"outlined"}
                        className={"dollar"}
                        inputProps={{
                            sx: { textAlign: 'start !important;' }
                        }}
                        value={row.paidAmount ? row.paidAmount : undefined}
                        onChange={(e) => onChangePaidAmount(e.target.value, index, row)}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                }
                return <>{convertThisToCurrency(row.paidAmount?.toFixed(2))}</>
            }
        },
        {
            name: "Balance",
            width: "110px",
            right: true,
            cell: (row) => <>{convertThisToCurrency(row.outstanding?.toFixed(2))}</>
        },
        {
            name: "Payment Remark",
            width: "300px",
            cell: (row, index) => {
                if (editAllowed && !isDisabled) {
                    return <InputBase
                        variant={"outlined"}
                        value={row.paymentRemark}
                        onChange={(e) => onChangeRowValues(index, e.target.value, 'paymentRemark')}
                    />
                }
                return <>{row.paymentRemark}</>
            }
        },
        {
            name: "Vendor",
            width: "250px",
            cell: (row, index) => {
                if (editAllowed && !isDisabled) {
                    return <InputBase
                        variant={"outlined"}
                        value={row.vendor}
                        onChange={(e) => onChangeRowValues(index, e.target.value, 'vendor')}
                    />
                }
                return <>{row.vendor}</>
            }
        },
        {
            name: "Remark",
            width: "300px",
            cell: (row, index) => {
                if (editAllowed && !isDisabled) {
                    return <InputBase
                        variant={"outlined"}
                        value={row.remarks}
                        onChange={(e) => onChangeRowValues(index, e.target.value, 'remarks')}
                    />
                }
                return <>{row.remarks}</>
            }
        }
    ];

    if (editAllowed && !isDisabled) {
        columns.unshift({
            name: "",
            width: "50px",
            cell: (row, index) => isVarientType(row.type)
                && (<IconButton onClick={() => onRemoveRow(index)}>
                    <DeleteIcon />
                </IconButton>)
        });
    }
    return columns;
};