import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SignatureCanvas from "react-signature-canvas";
import { useEffect, useState } from "react";
import { PR_BUTTONS, rejectButton } from "../../helper/action-buttons";
import { PrivilegeModules } from "../../../../data/privileges.enum";
import {
  PR_ACTIONS,
  PR_DOCUMENT_TYPES,
  PR_STEP_RESULTS,
} from "../../helper/pr-config";
import {
  useHttpRequest,
  useRoleAuthorization,
  useSignature,
} from "../../../../hooks";
import ProjectRequestRejectModal from "../Modals/ProjectRequestRejectModal";
import Swal from "sweetalert2";
import { splitTest } from "../../../../helper";
import moment from "moment";
import {
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../../../context/LayoutContext";
import ProjectCostingSummary from "../../ProjectCostingSummary";
import { ProjectRequestPdfViewer } from "../other/project-request-pdf-viewer";

export default function ProjectRequestDrawer2(props) {
  const {
    getFileFromSign,
    isSignChange,
    isSignEmpty,
    onChangeSign,
    sigCanvas,
    setSignChange,
    setSignEmpty,
    onClear,
    getBase64FromSign,
  } = useSignature();
  const { projectRequestDetail, setRefresh } = props;
  const [docList, setDocList] = useState([]);
  const { auth } = useRoleAuthorization();
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [message, setMessage] = useState("");
  const [openSignaturePad, setOpenSignaturePad] = useState(false);
  const [signPic, setSignPic] = useState("");
  const [refNo, setRefNo] = useState("");
  const [closeDrawer, setCloseDrawer] = useState(false);
  const layoutDispatch = useLayoutDispatch();
  const layoutState = useLayoutState();
  const [showDoc, setShowDoc] = useState(false);
  const [verifyMode, setVerifyMode] = useState(false);
  const [tempSignature, setTempSignature] = useState("");
  const [signed_VERIFY_Date, setSigned_VERIFY_Date] = useState("");
  const [signed_VERIFY_Name, setSigned_VERIFY_Name] = useState("");
  const [ecMode, setEcMode] = useState(false);
  const verifyPenColor = "black";
  const ecSignatureColor = "#FF9D4C";

  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  const rejectModalProps = {
    isLoading,
    open: rejectModalOpen,
    setOpen: setRejectModalOpen,
    rejectReason,
    setRejectReason,
    onSubmitReject,
  };

  const hasPrivilege = (privilege) =>
    auth.checkModulePrivilege(PrivilegeModules.project_request, privilege);

  const actionBtnProps = {
    isLoading,
    onReject,
    hasPrivilege,
    projectRequest: projectRequestDetail,
    step: projectRequestDetail.step,
  };

  useEffect(() => {
    setShowDoc(true);
  }, [docList]);

  useEffect(() => {
    setShowDoc(false);
    setDocList(props.projectRequestDetail.docList);
    setRefNo(props.projectRequestDetail.referenceNo);
  }, [props.projectRequestDetail.docList]);

  // Alert success and reload when successfully
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: onRefreshWhenSuccess,
      });
      setMessage("");
    }
  }, [message, responseData]);

  function onRefreshWhenSuccess() {
    setRefresh(true);

    if (closeDrawer) {
      toggleRightbar(layoutDispatch);
    }
  }
  // Alert warning when failed
  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  async function onSubmit(data, e, action, tempSignature) {
    const projectRequest = { ...data, history: undefined };

    if (isSignChange) {
      if (!signPic || signPic == "") {
        projectRequest.signature = null;
      } else {
        projectRequest.signature = {
          fileName: "signature.png",
          url: signPic,
          uploadedDate: moment().toDate(),
        };
      }
    }

    //remove the singatures from request to avoid unnecessary data submission
    delete projectRequest.signed_FINANCE_Signature;
    delete projectRequest.signed_QS_Signature;
    delete projectRequest.signed_PURCHASING_Signature;
    delete projectRequest.signed_DIRECTOR_Signature;
    delete projectRequest.signed_OPERATION_Signature;
    delete projectRequest.signed_EC_Signature;
    delete projectRequest.signed_VERIFY_Signature;

    const payload = {
      action,
      request: { ...projectRequest },
      tempSignature: tempSignature,
    };

    proceedToNextStep(payload);
  }

  async function onSubmitReject(e) {
    e.preventDefault();
    const projectRequest = projectRequestDetail;
    const payload = {
      action: PR_ACTIONS.REJECT,
      request: { ...projectRequest, reason: rejectReason, history: undefined },
    };
    proceedToNextStep(payload);
  }

  function onReject(e) {
    e.preventDefault();
    setRejectReason("");
    setRejectModalOpen(true);
  }

  async function proceedToNextStep(payload) {
    const response = await nextStep(payload);
    handleStepResponse(response);
  }

  async function handleStepResponse(res) {
    if (res?.status === 200 && res?.data?.request) {
      const savedRequest = res?.data?.request;
      let message = "";
      switch (res?.data?.stepResult) {
        case PR_STEP_RESULTS.UPDATED:
          setSigned_VERIFY_Date(savedRequest.signed_VERIFY_Date);
          setSigned_VERIFY_Name(savedRequest.signed_VERIFY_Name);
          setCloseDrawer(false);
          message = `Project request ${savedRequest.referenceNo} has been updated.`;
          break;
        case PR_STEP_RESULTS.APPROVED:
          setCloseDrawer(true);
          message = `Project request ${savedRequest.referenceNo} has been approved.`;
          break;
        case PR_STEP_RESULTS.REJECTED:
          setCloseDrawer(true);
          message = `Project request ${savedRequest.referenceNo} has been rejected.`;
          setRejectModalOpen(false);
          break;
        default:
          break;
      }
      message && setMessage(message);
    }
  }

  function nextStep(payload) {
    return sendRequest(`/v1/project-request/next-step`, "POST", payload);
  }

  const handleOpenCloseSignaturePad = (
    open,
    fromOkButton = false,
    forVerifyMode = false,
  ) => {
    setOpenSignaturePad(open);

    if (!forVerifyMode && open && signPic != "") {
      setTimeout(() => {
        sigCanvas.current.fromDataURL(signPic);
      });
    } else if (!forVerifyMode && !open && fromOkButton) {
      setSignChange(true);
      setSignPic(getBase64FromSign());
    } else if (forVerifyMode && !open && fromOkButton) {
      setVerifyMode(false);
      setTempSignature(getBase64FromSign());
      onSubmit(
        projectRequestDetail,
        null,
        PR_ACTIONS.VERIFY,
        getBase64FromSign(),
      );
    } else if (!open) {
      setVerifyMode(false);
      setEcMode(false);
    }
  };

  function updateLoadingStatus(url) {
    const dList = [...docList];
    let index = dList.findIndex((doc) => doc.url == url);

    dList[index].loaded = true;
    setDocList(dList);
  }

  function handleVerify() {
    setVerifyMode(true);
    handleOpenCloseSignaturePad(true, false, true);
  }

  const getPenColor = () => {
    let color = "black";

    if (verifyMode) {
      color = verifyPenColor;
    } else if (ecMode) {
      color = ecSignatureColor;
    }

    return color;
  };

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} size={150} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }} className="display">
        <Grid item xs={12} className={"action"}>
          {hasPrivilege("verify_step_3.2") && (
            <Button
              id={PR_BUTTONS.APPROVE}
              className={"primary"}
              type={"button"}
              disabled={!!tempSignature && tempSignature != ""}
              onClick={(e) => handleVerify()}
            >
              Verify
            </Button>
          )}
          {rejectButton(actionBtnProps)}
          {hasPrivilege("approve_step_3.2") && (
            <Button
              id={PR_BUTTONS.APPROVE}
              className={"primary"}
              type={"button"}
              disabled={!signPic || signPic == ""}
              onClick={(e) =>
                onSubmit(projectRequestDetail, e, PR_ACTIONS.APPROVE)
              }
            >
              Approve
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {/* PR INFO */}
            <Grid xs={12} item>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Typography variant={"h2"}>
                    {projectRequestDetail.referenceNo}
                  </Typography>
                </Grid>
                {showDoc &&
                  docList.map((doc) => (
                    <>
                      <Grid item xs={12}>
                        <Typography variant={"h3"}>{`${
                          doc.type
                        } - ${doc.description.toUpperCase()} - ${doc.fileName.toUpperCase()}`}</Typography>
                      </Grid>
                      <Grid item key={doc.url} xs={12}>
                        <ProjectRequestPdfViewer
                          pdfUrl={doc.url}
                          updateLoadingStatus={updateLoadingStatus}
                          className="pnl-document"
                          style={{ display: !doc.loaded ? "none" : "block" }}
                        />
                      </Grid>
                    </>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <ProjectCostingSummary
                isSignable={hasPrivilege("approve_step_3.2")}
                signPic={signPic}
                setSignPic={setSignPic}
                setSignEmpty={setSignEmpty}
                verifySignPic={tempSignature}
                setVerifySignPic={setTempSignature}
                handleOpenCloseSignaturePad={handleOpenCloseSignaturePad}
                projectRequest={projectRequestDetail}
                signed_VERIFY_Date={signed_VERIFY_Date}
                setSigned_VERIFY_Date={setSigned_VERIFY_Date}
                signed_VERIFY_Name={signed_VERIFY_Name}
                setSigned_VERIFY_Name={setSigned_VERIFY_Name}
                setEcMode={setEcMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ProjectRequestRejectModal {...rejectModalProps} />
      <Modal
        open={openSignaturePad}
        onClose={() => handleOpenCloseSignaturePad(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="sign-root">
            <label className="sign-label">Sign Here</label>
            <IconButton
              aria-label="delete"
              className="sign-action-delete"
              onClick={() => onClear()}
            >
              <ClearIcon />
            </IconButton>
            <SignatureCanvas
              canvasProps={{ className: "sign-canvas" }}
              clearOnResize={false}
              onEnd={() => onChangeSign()}
              ref={sigCanvas}
              penColor={getPenColor()}
            />
            <label className="sign-title">
              {verifyMode
                ? "Verifier"
                : "Executive Chairman / Managing Director"}
            </label>
            <Button
              className="sign-action-ok"
              onClick={() =>
                handleOpenCloseSignaturePad(false, true, verifyMode)
              }
            >
              Ok
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
