import {
    Grid, List, ListItem, ListItemText, Table, TableBody,
    TableCell, TableHead, TableRow, Typography
} from "@mui/material";
import * as moment from "moment";
import { convertThisToCurrency } from "../../../../helper/convert-this-to-currency";
import useStyles from "../../styles";

export default function HistoryDetailsDrawer({ history }) {
    const classes = useStyles();

    function showApproval(history) {
        return (<Grid container spacing={{ xs: 2, md: 3 }} className={classes.section}>
            <Grid item xs={12} lg={4}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={`Status`}
                            secondary={history.request?.status}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Rejected Date`}
                            secondary={history.actionDate ?
                                moment(history.actionDate).format('DD/MM/YYYY HH:mm:ss') : ''}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Steps`}
                            secondary={history.request?.step}
                        />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} lg={8}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={`Rejected By`}
                            secondary={`${history.actionBy?.username}-${history.actionBy?.name}`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Comment`}
                            secondary={history.comment}
                        />
                    </ListItem>
                </List>
            </Grid>
        </Grid>);
    }

    function showGeneral(request) {
        return (<Grid container spacing={{ xs: 2, md: 3 }} className={classes.section}>
            <Grid item xs={12}>
                <Typography variant={"h2"}>General</Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={`Customer`}
                            secondary={request.customer}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Validity`}
                            secondary={request.validity}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Contract Start`}
                            secondary={request.contractStart ?
                                moment(request.contractStart).format('DD/MM/YYYY') : ''}
                        />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} lg={4}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={`UEN No.`}
                            secondary={request.uen}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Credit Limit`}
                            secondary={convertThisToCurrency(request.creditLimit)}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Contract Completion`}
                            secondary={request.contractCompletion ?
                                moment(request.contractCompletion).format('DD/MM/YYYY') : ''}
                        />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} lg={4}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={`Location`}
                            secondary={request.location}
                        />
                    </ListItem>
                </List>
            </Grid>
        </Grid>);
    }

    function showCostMarginProjected(request) {
        return (<Grid container spacing={{ xs: 2, md: 3 }} className={classes.section}>
            <Grid item xs={12}>
                <Typography variant={"h2"}>Cost & Margin (Projected)</Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
            </Grid>
            <Grid item xs={12} lg={4}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={`Total Price`}
                            secondary={convertThisToCurrency(request.totalCost?.toFixed(2))}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Total Cost`}
                            secondary={convertThisToCurrency(request.contractValue?.toFixed(2))}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Profit Margin`}
                            secondary={`${convertThisToCurrency(request.profit?.toFixed(2))} (${request.profitMargin?.toFixed(2)}%)`}
                        />
                    </ListItem>
                </List>
            </Grid>

            {
                request.costMarginList?.length > 0 && (<Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.w20}>Item/Description</TableCell>
                                <TableCell align="right" className={classes.w10}>Cost</TableCell>
                                <TableCell align="right" className={classes.w10}>Selling Price</TableCell>
                                <TableCell align="left" className={classes.w20}>Vendor</TableCell>
                                <TableCell align="left">Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {request.costMarginList.map((row, i) => (
                                <TableRow key={`cm-row-${i}`}>
                                    <TableCell align="left" className={classes.bb}>{row.description}</TableCell>
                                    <TableCell align="right" className={classes.bb}>
                                        {row.cost ? convertThisToCurrency(row.cost.toFixed(2)) : '$0.00'}
                                    </TableCell>
                                    <TableCell align="right" className={classes.bb}>
                                        {row.cost ? convertThisToCurrency(row.sellingPrice.toFixed(2)) : '$0.00'}
                                    </TableCell>
                                    <TableCell align="left" className={classes.bb}>{row.vendor}</TableCell>
                                    <TableCell align="left" className={classes.bb}>{row.remarks}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>)
            }
        </Grid>);
    }

    function showDocuments(request) {
        return (<Grid container spacing={{ xs: 2, md: 3 }} className={classes.section}>
            <Grid item xs={12}>
                <Typography variant={"h2"}>Other Documents</Typography>
            </Grid>

            {
                request.documents?.length > 0 && (<Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.w30}>Name</TableCell>
                                <TableCell align="left">Uploaded Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {request.documents.map((row, i) => (
                                <TableRow key={`cm-row-${i}`}>
                                    <TableCell align="left" className={classes.bb}>
                                        <a href={row.url} className={classes.link}
                                            target="_blank" download>{row.fileName}</a>
                                    </TableCell>
                                    <TableCell align="left" className={classes.bb}>
                                        {
                                            row.uploadedDate ?
                                                moment(row.uploadedDate).format('DD/MM/YYYY') : ''
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>)
            }
        </Grid>);
    }

    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }} className="display">
            {
                (history && history.request) && (<Grid
                    item xs={12} sm={12} md={12} lg={12}>
                    {showApproval(history)}
                    {showGeneral(history.request)}
                    {showCostMarginProjected(history.request)}
                    {showDocuments(history.request)}
                </Grid>)
            }
        </Grid>
    </>);
}
