import React, {useEffect} from "react";
import { useHttpRequest } from "../hooks/useHttpRequest";
import {useUserState} from "./UserContext";

const StoreStateContext = React.createContext();
const StoreDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "SET_STORE":
      return {...state, store: action.payload};
    case "SET_STORE_LIST": {
      return {...state, storeList: action.payload};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function StoreProvider({children}) {
  const [state, dispatch] = React.useReducer(userReducer, {
    store: {code: "DEFAULT", name: "Default"},
    storeList: []
  });
  const { sendRequest } = useHttpRequest()
  function chooseStoreFromList(storeList) {
    for (const availableStore of storeList) {
      if (availableStore.code === localStorage.getItem('storeCode')) {
        dispatch({type:"SET_STORE", payload: availableStore})
        return;
      }
    }
    dispatch({type: "SET_STORE", payload: storeList.filter(i => i.isDefault).pop()})
  }

  const {userInfo} = useUserState();
  useEffect(() => {
    sendRequest('/v1/stores', 'GET')
      .then(result => {
        if (result?.data?.stores && result?.data?.stores?.length) {
          dispatch({type:"SET_STORE_LIST", payload: result?.data?.stores})
          chooseStoreFromList(result?.data?.stores);
        } else {
          dispatch({type:"SET_STORE_LIST", payload: []})
        }
      })
  }, [sendRequest])

  useEffect(() => {
    if (state.storeList.length && !userInfo?.associatedStoreCodes?.includes(state?.store?.code)) {
      const correctStore = state.storeList.filter(i => userInfo?.associatedStoreCodes?.includes(i.code)).pop();
      if (correctStore) {
        dispatch({type: "SET_STORE", payload: correctStore})
      }
    }
  }, [userInfo, state, state.storeList])

  return (
    <StoreStateContext.Provider value={state}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  );
}

function useStoreState() {
  var context = React.useContext(StoreStateContext);
  if (context === undefined) {
    throw new Error("useStoreState must be used within a StoreProvider");
  }
  return context;
}

function useStoreDispatch() {
  var context = React.useContext(StoreDispatchContext);
  if (context === undefined) {
    throw new Error("useStoreDispatch must be used within a StoreProvider");
  }
  return context;
}

function setStore(dispatch, store) {
  dispatch({type: "SET_STORE", payload: store})
}

export {StoreProvider, useStoreState, useStoreDispatch, setStore};
