import { Link } from "@mui/material";
import * as moment from "moment";
import { PR_ACTIONS } from "../helper/pr-config";

export const historyTableConfig = (openHistoryRecord) => [
    {
        name: "Recorded Date",
        width: "170px",
        wrap: true,
        cell: (row) => row.action === PR_ACTIONS.REJECT.toUpperCase() ? (<Link to={'#'}
            onClick={() => openHistoryRecord(row)}>{
                moment(row.actionDate).format('DD/MM/YYYY HH:mm:ss')
            }</Link>) : moment(row.actionDate).format('DD/MM/YYYY HH:mm:ss'),
        sortable: true,
        selector: row => row.actionDate
    },
    {
        name: "Action",
        width: "140px",
        selector: row => row.action
    },
    {
        name: "Actioned By",
        width: "250px",
        selector: row => row.actionBy ? `${row.actionBy.name}-${row.actionBy.username}` : '',
    },
    {
        name: "Step",
        width: "80px",
        selector: row => row.step,
        right: true
    },
    {
        name: "Comment",
        minWidth: '300px',
        wrap: true,
        selector: row => row.comment
    }
];