import { Event } from "@mui/icons-material";
import {
    Backdrop, Button, CircularProgress, Grid,
    InputAdornment, TextField, Typography
} from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { SERVICE_CLAIMS_REPORT_STATUS } from "../../data/constants";
import DateController from "../../components/DateController";
const ServiceClaimReportCard = (props) => {
    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization
    const { auth } = useRoleAuthorization();

    // handling form when adding / editing
    const { register, handleSubmit, setValue, control,
        formState: { errors: formErrors } } = useForm({ mode: "all" });
    const { batchNo } = useParams();

    const [report, setReport] = useState(null);
    const [paymentDate, setPaymentDate] = useState(null);
    const [message, setMessage] = useState("");
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        if (!isLoading && !responseData && batchNo) {
            sendRequest(`/v1/service-claim-report/get-by-batch-no/${batchNo}`, 'GET');
        }
    }, [batchNo]);

    useEffect(() => {
        if (batchNo && responseData?.report) {
            const reportData = responseData?.report;
            document.title = `${reportData.batchNo} - Service Claim Report Card`;
            setReport(reportData);
            setValue('batchNo', reportData.batchNo || '');
            setValue('team', `${reportData.team}`);
            setValue('totalClaimAmount', (reportData.totalClaimAmount || 0).toFixed(2));
        }
    }, [responseData]);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    // navigate back to claim list
    const navigateToList = () => {
        window.location.href = '#/app/service-claim-report/list';
    };

    const onChangePaymentDate = (date) => {
        setValue('paymentDate', date);
        setPaymentDate(date);
    }

    const handleSubmitForm = async (data, e) => {
        e.preventDefault();
        if (!paymentDate || paymentDate == 'Invalid Date') return;
        if (report) {
            report.paymentDate = moment(paymentDate).set({ h: 0, m: 0, s: 0 }).toDate();
            report.status = SERVICE_CLAIMS_REPORT_STATUS.PAID;
            const response = await sendRequest(`/v1/service-claim-report/update/${report.batchNo}`,
                'PUT', report);
            if (response?.status === 200 && response?.data?.paymentDate) {
                setMessage(response?.data?.message);
            }
        }
    };

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Service Claim Report Card - {batchNo}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/service-claim-report/list')}>Cancel</Button>
                        {
                            (auth.checkModulePrivilege(PrivilegeModules.service_claim_report,
                                PrivilegeActions.edit) && !!paymentDate) &&
                            <Button className={"primary"} type={"button"}
                                onClick={handleSubmit(handleSubmitForm)}>Save</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"batchNo"}
                                                    label={"Report No."}
                                                    variant={"outlined"}
                                                    {...register("batchNo")} disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"team"}
                                                    label={"Team"}
                                                    variant={"outlined"}
                                                    {...register("team")} disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"totalClaimAmount"}
                                                    label={"Total Claim Amt."}
                                                    variant={"outlined"}
                                                    {...register("totalClaimAmount")} disabled
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                              <DateController
                                                control={control}
                                                label={'Payment Date'}
                                                name={'paymentDate'}
                                                required={true}
                                                value={paymentDate}
                                                onChange={onChangePaymentDate}
                                              />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(ServiceClaimReportCard);