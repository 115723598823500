import { getFromDate, getToDate } from "../../../helper/list-filters-helper";

export function createPayload(action, data, tempSignature) {
  return {
    action: action,
    request: {
      _id: data._id ?? undefined,
      status: data.status ?? undefined,
      referenceNo: data.referenceNo ?? undefined,
      createdBy: data.createdBy ?? undefined,
      customer: data.customer,
      location: data.location,
      uen: data.uen,
      documents: data.documents ?? [],
      feedbackDocuments: data.feedbackDocuments ?? [],
      deposit: data.deposit ? +data.deposit : undefined, //
      validity: data.validity,
      creditLimit: +data.creditLimit,
      creditTerm: data.creditTerm ?? "",
      contractStart: getFromDate(data.contractStart) ?? undefined,
      contractCompletion: getToDate(data.contractCompletion) ?? undefined,
      estimatedContractStart:
        getFromDate(data.estimatedContractStart) ?? undefined,
      estimatedContractCompletion:
        getToDate(data.estimatedContractCompletion) ?? undefined,
      step: data.step,
      costMarginList: data.costMarginItems ?? [],
      claimsDeposits: data.firstClaim
        ? {
            firstClaim: +data.firstClaim,
            secondClaim: data.secondClaim ? +data.secondClaim : undefined,
            thirdClaim: data.thirdClaim ? +data.thirdClaim : undefined,
            forthClaim: data.forthClaim ? +data.forthClaim : undefined,
            fifthClaim: data.fifthClaim ? +data.fifthClaim : undefined,
            sixthClaim: data.sixthClaim ? +data.sixthClaim : undefined,
            seventhClaim: data.seventhClaim ? +data.seventhClaim : undefined,
            eighthClaim: data.eighthClaim ? +data.eighthClaim : undefined,
            finalClaim: data.finalClaim ? +data.finalClaim : undefined,
            retention: data.retention ? +data.retention : undefined,
          }
        : undefined,
      claimsDepositsProjected: data.firstClaimP
        ? {
            firstClaim: +data.firstClaimP,
            secondClaim: data.secondClaimP ? +data.secondClaimP : undefined,
            thirdClaim: data.thirdClaimP ? +data.thirdClaimP : undefined,
            forthClaim: data.forthClaimP ? +data.forthClaimP : undefined,
            fifthClaim: data.fifthClaimP ? +data.fifthClaimP : undefined,
            sixthClaim: data.sixthClaimP ? +data.sixthClaimP : undefined,
            seventhClaim: data.seventhClaimP ? +data.seventhClaimP : undefined,
            eighthClaim: data.eighthClaimP ? +data.eighthClaimP : undefined,
            finalClaim: data.finalClaimP ? +data.finalClaimP : undefined,
            retention: data.retentionP ? +data.retentionP : undefined,
          }
        : undefined,
      totalCost: data.totalCost ?? undefined,
      contractValue: data.contractValue ?? undefined,
      profit: data.profit ?? undefined,
      profitMargin: data.profitMargin ?? undefined,
      outstandingBalance: data.outstandingBalance ?? undefined,
      totalPayment: data.totalPayment ?? undefined,
      totalBillAmount: data.totalBillAmount ?? undefined,
      progress: data.progress ?? 0,
      progressList: data.progressList ?? [],
      submittedForApproval: data.submittedForApproval ?? false,
      payments: data.paymentList ?? [],
      awardedDate: data.awardedDate ?? undefined,
      costMarginAList: data.costMarginAList ?? [],
      totalCostA: data.totalCostA ?? 0,
      contractValueA: data.contractValueA ?? 0,
      profitA: data.profitA ?? 0,
      profitMarginA: data.profitMarginA ?? 0,
      history: undefined,
      internalReferenceNo: data.internalReferenceNo,
      projectTitle: data.projectTitle,
      tendencyDueDate: data.tendencyDueDate ?? null,
      company: data.selectedCompany,
    },
    tempSignature: tempSignature,
  };
}
