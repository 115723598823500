import { Grid, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import { PR_PANELS } from "../../helper/panels";
import { includesInApprovalSteps, isRejected } from "../../helper/pr-config";
import { feedbackTableConfig } from "../../data-table/feedback-table-config";

const WorkflowFeedback = (props) => {

    const isDisabled = () => {
        return includesInApprovalSteps(props.projectRequest?.step)
            && !isRejected(props.projectRequest?.status);
    };

    const columns = feedbackTableConfig(props?.hasEditPrivilege,
        isDisabled(), onRemoveRow, onChangeRowValues, onClickAddBtn);

    function onClickAddBtn() {
        document.getElementById('feedback-upload').click();
    }

    function onChangeRowValues(i, value, attribute) {
        const docsList = [...props.feedbackDocuments];
        if (attribute === 'description') {
            value = value.toUpperCase();
        }
        docsList.at(i)[attribute] = value;
        props.setFeedbackDocuments(docsList);
    }

    function onAddDocuments(e) {
        const files = e.target.files;
        let fileList = [...props.feedbackDocuments];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            fileList = [...fileList, {
                file: file,
                fileName: file.name,
                description: '',
                uploadedDate: '',
                new: true
            }];
        }
        props.setFeedbackDocuments(fileList);
    }

    function onRemoveRow(index) {
        const documents = [...props.feedbackDocuments];
        const removedDoc = documents.at(index);
        if (removedDoc && !removedDoc.new) {
            const generatedName = removedDoc.url.split('/').pop();
            props.setRemovedDocuments([...props.removedDocuments, generatedName]);
        }
        documents.splice(index, 1);
        props.setFeedbackDocuments([...documents]);
    }

    return (<>
        <input
            hidden
            type="file"
            id="feedback-upload"
            accept={props.allowedFileTypes ?? '*'}
            onChange={(e) => onAddDocuments(e)}
            multiple
        />
        <Grid container className={"form"} id={PR_PANELS.FEEDBACK}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={"sub-action"}>
                        <Typography variant={"h2"}>Feedback</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={"table"}>
                        <DataTable
                            fixedHeader={true}
                            persistTableHead={true}
                            columns={columns}
                            data={props?.feedbackDocuments}
                            defaultSortAsc={false}
                            defaultSortFieldId={'uploadedDate'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default WorkflowFeedback;   