import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    drawerToggleBtn: {
        marginTop: 10
    },
    toggleRightBarIcon: {
        fontSize: 28
    },
    fileName: {
        marginLeft: 10,
        padding: 5,
        backgroundColor: '#f9f9f9'
    }
}));