import { Switch } from "@mui/material";
import { Link } from "react-router-dom";

export const projectsColumnConfig = (permission, handleEditProject) => [
    {
        name: "Project ID",
        width: "200px",
        selector: row => row._id,
        cell: (row) => {
            if(permission.allowedViewDetail){
                return (<Link to={"/app/project/card/" + row.projectId}>{row.projectId}</Link>)
            }else{
                return (<>{row.projectId}</>)
            }
            
        },
        sortable: true
    },
    {
        name: "Project Name",
        minWidth: "300px",
        wrap: true,
        selector: row => row.projectName,
        sortable: true
    },
    {
        name: "Project Group",
        width: "150px",
        selector: row => row.projectGroup,
        sortable: true
    },
    {
        name: "Company",
        width: "90px",
        selector: row => row?.company?.code,
        sortable: true
    },
    {
        name: "Enable",
        width: '90px',
        center: true,
        selector: row => row.enabled,
        cell: (row) => {
            return (<Switch
                checked={row.enabled === 'Yes'}
                disabled={!(permission.allowedEnableDisable)}
                onChange={(e, value) => handleEditProject(row, true, value)}
                inputProps={{ 'aria-label': 'controlled' }}
            />)
        },
        sortable: true
    }
];
