import { withRouter } from "react-router-dom";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, InputAdornment, Autocomplete, IconButton
} from "@mui/material";
import { Search, KeyboardArrowDown, PlaylistPlay, ArrowBack } from "@mui/icons-material";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useEffect, useMemo, useState } from "react";
import { userTableColumnConfig } from "./data-table/user-table-column-config";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { setRighbarContent, toggleRightbar, useLayoutDispatch, useLayoutState } from "../../context/LayoutContext";
import MobileUserListFilters from "./components/Mobile/MobileUserListFilters";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import { getRoleCodesFromNames, getRoleNamesFromCodes } from "../../helper/role-code-name-converter";

const ManageUsers = (props) => {
    const classes = useStyles();
    // handle loading & http
    const { isLoading, httpRequestError: error, responseData, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    // user states
    const [nameFilter, setNameFilter] = useState("");
    const [userRoleFilter, setUserRoleFilter] = useState(null);
    const [enableFilter, setEnableFilter] = useState(null);

    const [userList, setUserList] = useState([]);
    const [refreshUserList, setRefreshUserList] = useState(false);
    const [message, setMessage] = useState("");
    const [roleList, setRoleList] = useState([]);

    const enableDisableList = ['Yes', 'No'];

    useEffect(() => { 
        document.title = "User List"; 

        // fetch user role list
        if (!auth.isPrivilegeDataLoaded()){
            return;
        }

        // redirect to default page if not authorized
        if (!auth.checkModulePrivilege(PrivilegeModules.user, PrivilegeActions.view_list)) {
            props.history.push('/app/dashboard');
        }        

        sendRequest("/v1/role/get-role-list", "GET", {})
            .then(response => {
                setRoleList(response.data?.roles);
                setRefreshUserList(true);
            });        
    }, []);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: setRefreshUserList(true)
            });
        }
    }, [message]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        let abortController = new AbortController();
        // fetch user list
        if (refreshUserList) {
            sendRequest("/v1/user/get-list", "GET", {})
                .then(response => {
                    response.data?.users.forEach(user => {
                        user.roles =  getRoleNamesFromCodes(user.roles, roleList);
                    });

                    setUserList(response.data?.users || []);
                    setRefreshUserList(false);
                    return () => {
                        abortController.abort();
                        return response.data?.users;
                    };
                });
        }
    }, [refreshUserList]);

    // set columns in data table
    const columns = userTableColumnConfig(
        { 
            allowedViewDetail: auth.checkModulePrivilege(PrivilegeModules.user, PrivilegeActions.view_detail),
            allowedEnableDisable: auth.checkModulePrivilege(PrivilegeModules.user, PrivilegeActions.enable_disable),
            allowedDelete: auth.checkModulePrivilege(PrivilegeModules.user, PrivilegeActions.delete),
        }, handleEditUser, handleDeleteUser);

    // set user data with filters
    const filteredData = userList.filter(user => {
        let filterFlag = true;

        // search box
        if (nameFilter) {
            const compoundText = user.name.toLowerCase() + user.username.toLowerCase() + user.mobileNo?.toLowerCase() + user.email?.toLowerCase()
            + user.departments?.filter(d => !!d)?.toString().toLowerCase();
            filterFlag &= compoundText.includes(nameFilter.toLowerCase());
        }

        // user role dropdown
        if (userRoleFilter) {
            filterFlag &= (user.roles.includes(userRoleFilter));
        }

        // enable / disable dropdown
        if (enableFilter) {
            filterFlag &= (user.status === (enableFilter === 'Yes'));
        }
        return filterFlag;
    });

    // populate user role list from existing users
    const userRoleList = useMemo(() => {
        return [...new Set(userList.map(user => user.roles).flat(1))].sort();
    }, [userList]);

    // set right bar for small devices
    const smallDeviceWidth = 1200;
    const mobileFilterProps = {
        userRoleList,
        enableDisableList,
        userRoleFilter,
        enableFilter,
        setUserRoleFilter,
        setEnableFilter
    };

    useEffect(() => {
        if (width <= smallDeviceWidth) { // set filter right bar
            setRighbarContent(layoutDispatch,
                [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
                <MobileUserListFilters key={"rb-body"} {...mobileFilterProps} />], { size: 'xs', backButton: true }); // filter content
        }
    }, [userRoleList, userRoleFilter, enableFilter, width]);

    // handle edit users 
    async function handleEditUser(user, toggle = false, toggleValue = false) {
        if (toggle) {
            user.status = toggleValue; // set new enable value & update
            let tmpUser = JSON.parse(JSON.stringify(user));
            tmpUser.roles = getRoleCodesFromNames(tmpUser.roles, roleList);
            await sendRequest(`/v1/user/${user._id}`, "PUT", tmpUser);
        } else { 
            props.history.push(`card/${user._id}`); 
        }
    }

    // handle delete users
    function handleDeleteUser(user) {
        if (user) {
            Swal.fire({
                title: `Confirmation`,
                text: `Are you sure you want to delete the user ${user.username}-${user.name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await sendRequest(`/v1/user/${user._id}`, "DELETE");
                    if (result?.status === 200 && result?.data?.deleted) {
                        setMessage(`User ${user.username}-${user.name} has been deleted.`);
                    }
                }
            });
        }
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 1 }} open={isLoading}><CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>User List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                {auth.checkModulePrivilege(PrivilegeModules.user, PrivilegeActions.add)
                    && <Button className={"primary"}
                        onClick={() => props.history.push('card')}>New</Button>}
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                            <TextField
                                autoComplete={"off"}
                                id={"user-name-searchbox"}
                                variant={"outlined"}
                                value={nameFilter}
                                onChange={(e) => setNameFilter(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={"start"}>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        {
                            width >= smallDeviceWidth ? (<>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                    <Autocomplete
                                        id={"user-role-filter"}
                                        options={userRoleList}
                                        getOptionLabel={(option) => option}
                                        value={userRoleFilter}
                                        onChange={(e, newValue) => setUserRoleFilter(newValue)}
                                        renderInput={(params) => <TextField {...params} label={"Role"} variant={"outlined"} />}
                                        popupIcon={<KeyboardArrowDown />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                    <Autocomplete
                                        id={"enable-filter"}
                                        options={enableDisableList}
                                        getOptionLabel={(option) => option.toUpperCase()}
                                        value={enableFilter}
                                        onChange={(e, newValue) => setEnableFilter(newValue)}
                                        renderInput={(params) => <TextField {...params} label={"Enable"} variant={"outlined"} />}
                                        popupIcon={<KeyboardArrowDown />}
                                    />
                                </Grid>
                            </>) :
                                (<Grid item xs={2}>
                                    <IconButton
                                        onClick={() => toggleRightbar(layoutDispatch)}
                                        className={classes.drawerToggleBtn}    >
                                        {layoutState.isRightbarOpened ? (<ArrowBack className={classes.toggleRightBarIcon} />)
                                            : (<PlaylistPlay className={classes.toggleRightBarIcon} />)}
                                    </IconButton>
                                </Grid>)
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}
                                data={filteredData}
                                pagination
                                defaultSortFieldId={8}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
    );
};
export default withRouter(ManageUsers);