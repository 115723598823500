
import { DeleteOutline as DeleteIcon, Add as AddIcon, Remove as RemoveIcon, KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { IconButton, InputBase, Select, MenuItem, Button } from "@mui/material";
import './../Styles.css';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

export const resourceTableColumnConfig = (getFileExtension, onRemoveRow, checkForDuplicatedDish, onSelectImage, onChangeChef, onChangeDish, imagePreview, onRemoveImage, sortMenuItem, chefList, disabled) => [
        {
            name: "",
            width: "50px",
            button: true,
            cell: (row, index) => <IconButton onClick={() => onRemoveRow(index)} disabled= {disabled}><DeleteIcon />
            </IconButton>
        },
        {
            name: "Group",
            width: "150px",
            cell: (row, index) => {
                return <InputBase
                    disabled= {disabled}
                    variant={"outlined"}
                    value={row.groupName}
                    onChange={(e) => onChangeDish('groupName', index, e.target.value)}
                />
            }
        },
        {
            name: "Seq. No.",
            width: "70px",
            cell: (row, index) => {
                return <InputBase
                    disabled= {disabled}
                    variant={"outlined"}
                    value={row.seqNo}
                    onBlur={(e) => sortMenuItem(index, Number(e.target.value))}
                    onChange={(e) => onChangeDish('seqNo', index, e.target.value)}
                />
            }
        },
        {
            name: "Dish",
            width: "450px",
            cell: (row, index) => {
                return <InputBase
                    disabled= {disabled}
                    variant={"outlined"}
                    value={row.dishName}
                    onBlur={(e) => checkForDuplicatedDish('dishName', index, e.target.value)}
                    onChange={(e) => onChangeDish('dishName', index, e.target.value)}
                />
            }
        },
        {
            name: "盘子",
            width: "450px",
            cell: (row, index) => {
                return <InputBase
                    disabled= {disabled}
                    variant={"outlined"}
                    value={row.dishNameInChinese}
                    onBlur={(e) => checkForDuplicatedDish('dishNameInChinese', index, e.target.value)}
                    onChange={(e) => onChangeDish('dishNameInChinese', index, e.target.value)}
                />
            }
        },
        {
            name: "Chef",
            width: "250px",
            cell: (row, index) => {
                return <Select
                    key={`select-chef-${index}`}
                    variant={"outlined"}
                    value={row.chef}
                    onChange={(e) => onChangeChef(index, e.target.value)}
                    disabled= {disabled}
                    IconComponent={ArrowDownIcon}
                >
                    {chefList.map((element, i) => <MenuItem
                        key={`chef-option-${i}`}
                        value={element}
                        selected={row.chef.chefId == element.chefId}>
                        {`${element.chefName}-${element.chefId}`}
                    </MenuItem>
                    )}
                </Select>
            }
        },
        {
            name: "Picture (500x500)",
            width: "150px",
            cell: (row, index) => {
                return <>
                    <IconButton
                        className={row.imageUrl != "" ? 'hide' : ''}
                        onClick={() => onSelectImage(index)} disabled={disabled}><AddIcon />
                    </IconButton>
                    <IconButton
                        className={row.imageUrl == "" ? 'hide' : 'show'}
                        onClick={() => onRemoveImage(index)} disabled={disabled}><RemoveIcon />
                    </IconButton>
                    <img
                        className={row.imageUrl == "" || getFileExtension(row.imageUrl) == 'mp4' ? 'hide' : 'show'}
                        src={row.isNewImage ? row.imageUrl_Local : row.imageUrl}
                        onClick={(e) => imagePreview(row)}
                    />
                    <IconButton
                      className={row.imageUrl == "" || getFileExtension(row.imageUrl) != 'mp4' ? 'hide' : 'show'}
                      onClick={(e) => imagePreview(row)}
                    >
                      <OndemandVideoIcon />
                    </IconButton>
                </>
            }
        },
    ];
