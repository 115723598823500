import { Alert, Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { SingleOption } from "./AddOn/SingleOption";
import { MultipleOption } from "./AddOn/MultipleOption";

export default function AddOn({ addOn, onChange }) {
  const handleOnChangeOption = (selectedOptions) =>
    onChange(addOn, selectedOptions);

  const {
    formState: { errors },
  } = useFormContext();

  const getErrorMessage = (errors, name) => {
    return errors[name]?.message;
  };

  return (
    <>
      <Box className={`add-on product-addon-${addOn.entityId}`}>
        <Typography variant="h4">
          {addOn.name}{" "}
          {addOn?.selectType === "single" && <small>(pick one)</small>}
        </Typography>
        {errors.addOns &&
          getErrorMessage(errors.addOns, `addon_${addOn.entityId}`) && (
            <Typography className={"has-error"}>
              {getErrorMessage(errors.addOns, `addon_${addOn.entityId}`)}
            </Typography>
          )}
        {addOn.selectType === "single" && (
          <SingleOption addOn={addOn} onChange={handleOnChangeOption} />
        )}
        {addOn.selectType !== "single" && (
          <MultipleOption addOn={addOn} onChange={handleOnChangeOption} />
        )}
      </Box>
    </>
  );
}
