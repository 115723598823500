import { useHttpRequest } from "../../../hooks";
import Swal from "sweetalert2";

export const useSupplierListApi = () => {
  const { isLoading, sendRequest } = useHttpRequest();

  const getSupplierList = async (
    searchQuery,
    filters,
    pagination = { page: 1, pageSize: 10 },
    sortOrder = { sortField: "createdAt", direction: "desc" },
  ) => {
    return sendRequest(`/fnb-suppliers-management/search/`, "POST", {
      query: searchQuery,
      filters,
      pagination,
      sortOrder,
    })
      .then((response) => response?.data?.data)
      .catch((e) => {
        Swal.fire(
          "Error during query prerequisites.",
          e?.response?.data?.message || e.message,
          "error",
        );
        throw e;
      });
  };

  const getPrerequisites = async () => {
    return sendRequest(
      `/fnb-suppliers-management/get-prerequisite`,
      "GET",
    ).catch((e) => {
      Swal.fire(
        "Error during query prerequisites.",
        e?.response?.data?.message || e.message,
        "error",
      );
      throw e;
    });
  };

  const getByVendorCode = async (vendorCode) => {
    return sendRequest(`/fnb-suppliers-management/${vendorCode}`, "GET").catch(
      (e) => {
        Swal.fire(
          "Error during query prerequisites.",
          e?.response?.data?.message || e.message,
          "error",
        );
        throw e;
      },
    );
  };

  const createSupplier = async (supplierData) => {
    return sendRequest(`/fnb-suppliers-management`, "POST", supplierData).catch(
      (e) => {
        Swal.fire(
          "Error during create supplier.",
          e?.response?.data?.message || e.message,
          "error",
        );
        throw e;
      },
    );
  };

  const updateSupplier = async (vendorCode, supplierData) => {
    if (!vendorCode) {
      throw new Error("vendorCode is required");
    }

    return sendRequest(
      `/fnb-suppliers-management/${vendorCode}`,
      "PUT",
      supplierData,
    ).catch((e) => {
      Swal.fire(
        "Error during updating supplier.",
        e?.response?.data?.message || e.message,
        "error",
      );
      throw e;
    });
  };

  return {
    isLoading,
    getSupplierList,
    getPrerequisites,
    getByVendorCode,
    createSupplier,
    updateSupplier,
  };
};
