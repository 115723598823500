import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import { validateText } from "../../../../helper/validate-textfield";
import { PR_ACTIONS } from "../../helper/pr-config";

const ProjectRequestApproveModal = (props) => {
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;

    props.setOpen(false);
  };

  const handleApproveMessage = (event) => {
    validateText(event);
    props?.setApproveMessage(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
      open={props.open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      maxWidth={"sm"}
    >
      <DialogTitle component={"div"}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={"sub-action"}
        >
          <Typography variant={"h2"}>Approve</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          id={"approve-message"}
          label={"Comment"}
          variant={"outlined"}
          autoFocus
          fullWidth
          defaultValue={props.approveMessage}
          onChange={handleApproveMessage}
          autoComplete={"off"}
        />
      </DialogContent>
      <DialogActions className={"action"}>
        <Button
          className={"primary"}
          disabled={!props.approveMessage}
          onClick={props.handleSubmit((data, e) => {
            if (props.validateForApproveSignature()) {
              props.setOpenSignaturePad(true);
            } else {
              props.onSubmit(data, e, PR_ACTIONS.APPROVE);
            }
            props.setOpen(false);
          })}
        >
          Proceed
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectRequestApproveModal;
