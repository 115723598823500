import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  KeyboardArrowDown,
  Clear,
} from "@mui/icons-material";
import {
  IconButton,
  Switch,
  InputBase,
  TextField,
  Autocomplete,
  Grid,
  FormControlLabel,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  LocalizationProvider,
  SingleInputDateRangeField,
  SingleInputTimeRangeField,
} from "@mui/x-date-pickers-pro";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
const dayOfWeekOption = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const slotTableColumnConfig = ({
  onRemoveRow,
  onCopyRow,
  onChangeSlotItem,
  checkInvalidSlotRow,
  onChangeSlotGroupItem,
  groupBuyEvents = [],
}) => [
    {
      name: "",
      width: "50px",
      cell: (row, index) => (
        <IconButton onClick={() => onRemoveRow(index)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row, index) => (
        <IconButton
          onClick={() => onCopyRow(index)}
          disabled={checkInvalidSlotRow(row)}
        >
          <ContentCopyIcon />
        </IconButton>
      ),
    },
    {
      name: "Start Date - End Date",
      width: "200px",
      cell: (row, index) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              value={row.date}
              onChange={(newValue) => onChangeSlotItem("date", index, newValue)}
              slotProps={{
                textField: {
                  ...(dayjs(row.date[0]).isAfter(row.date[1], "days") && {
                    helperText: "Invalid date range.",
                    error: true,
                  }),
                },
              }}
              format="DD/MM/YYYY"
              localeText={{
                start: "",
                end: "",
              }}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      name: "Start Time - End Time",
      width: "200px",
      cell: (row, index) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputTimeRangeField
              className="input-date-time"
              hiddenLabel
              value={row.time}
              onChange={(newValue) => onChangeSlotItem("time", index, newValue)}
              format="HH:mm"
              slotProps={{
                textField: {
                  ...((dayjs(row.time[0]).isAfter(row.time[1], "hour") ||
                    dayjs(row.time[0]).isAfter(row.time[1], "minute")) && {
                    helperText: "Invalid time range.",
                    error: true,
                  }),
                },
              }}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      name: "Day of Week",
      minWidth: "300px",
      cell: (row, index) => {
        return (
          <Autocomplete
            options={
              !row.dayOfWeek
                ? dayOfWeekOption
                : dayOfWeekOption.filter(
                  (chef) => !row.dayOfWeek.some((val) => chef === val),
                )
            }
            getOptionLabel={(option) => option}
            value={row.dayOfWeek}
            onChange={(e, newValue) => {
              onChangeSlotItem("dayOfWeek", index, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={!row.dayOfWeek.length}
                helperText={
                  !row.dayOfWeek.length && "Day of Week can not be blank"
                }
              />
            )}
            multiple
            popupIcon={<KeyboardArrowDown />}
            ChipProps={{ deleteIcon: <Clear /> }}
          />
        );
      },
    },
    {
      name: "Capacity",
      width: "100px",
      center: true,
      cell: (row, index) => {
        return (
          <TextField
            variant={"outlined"}
            type="number"
            value={row.capacity}
            onChange={(e) =>
              onChangeSlotItem("capacity", index, parseInt(e.target.value))
            }
            error={!row.capacity}
            helperText={!row.capacity && "Capacity can not be blank"}
          />
        );
      },
    },
    ...groupBuyEvents.map((item, index) => ({
      name: `Slot (${item.id})`,
      width: "80px",
      center: true,
      cell: (row, index) => {
        return (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={row?.slotGroup?.[item.id] ?? false}
                  onChange={(e) =>
                    onChangeSlotGroupItem(item.id, index, e.target.checked)
                  }
                />
              }
            />
          </>
        );
      },
    })),
  ];
