import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import {
  Search as SearchIcon,
  KeyboardArrowDown as ArrowDownIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  MoreVertOutlined as ExpandMoreIcon,
} from "@mui/icons-material";
import React, { useEffect, useState, useMemo } from "react";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useUserState } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import { claimsTableColumnConfig } from "./data-table/claims-table-column-config";
import DataTable from "react-data-table-component";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useStyles from "./styles";
import moment, { months } from "moment";
import MobileClaimsFilters from "./components/Mobile/MobileClaimsFilters";
import Swal from "sweetalert2";
import ClaimInvoiceInfoDrawer from "./components/Drawer/ClaimInvoiceInfoDrawer";
import downloadCSV from "../../helper/download-csv";
import { SERVICE_CLAIMS_STATUS, ENABLE_OPTIONS } from "../../data/constants";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import * as _ from "lodash";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DateFilter from "../../components/DateFilter";
import { compareDates } from "../../helper";

const ManageServiceClaims = (props) => {
  const classes = useStyles();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  const [claimList, setClaimList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false);

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  // filter states
  const [textFilter, setTextFilter] = useState("");
  const [teamFilter, setTeamFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [invoicePostedFilter, setInvoicePostedFilter] = useState(null);
  const [downloadedFilter, setDownloadedFilter] = useState(null);
  const [claimDateFrom, setClaimDateFrom] = useState(null);
  const [claimDateTo, setClaimDateTo] = useState(null);
  const [calculatedFilter, setCalculatedFilter] = useState(null);

  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [claimsToBeApproved, setClaimsToBeApproved] = useState([]); // claims remaining for approval
  const [lastApproval, setLastApproval] = useState(null); // claims remaining for approval
  const [loadMobileFilters, setLoadMobileFilters] = useState(false);
  const { userInfo } = useUserState();
  const [popoverButtonOpen, setPopoverButtonOpen] = useState(null);
  const postedList = ENABLE_OPTIONS;
  const statusList = Object.values(SERVICE_CLAIMS_STATUS);

  // set right bar for small devices
  const smallDeviceWidth = 1200;
  const mobileFilterProps = {
    teamList,
    statusList,
    postedList,
    teamFilter,
    statusFilter,
    invoicePostedFilter,
    claimDateFrom,
    claimDateTo,
    setTeamFilter,
    setStatusFilter,
    setInvoicePostedFilter,
    setClaimDateFrom,
    setClaimDateTo,
    downloadedFilter,
    setDownloadedFilter,
    calculatedFilter,
    setCalculatedFilter,
    allowedSeeMoreColumns: auth.checkModulePrivilege(
      PrivilegeModules.service_claim,
      PrivilegeActions.see_more_columns,
    ),
  };

  useEffect(() => {
    document.title = "Service Claim List";
    loadSubCons();
  }, []);

  useMemo(() => {
    if (auth.isPrivilegeDataLoaded()) {
      if (
        !auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.view_list,
        )
      ) {
        props.history.push("/app/dashboard");
      }

      if (
        auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.filter_status_pending,
          false,
        )
      ) {
        setStatusFilter(SERVICE_CLAIMS_STATUS.PENDING);
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.filter_status_approved,
          false,
        )
      ) {
        setStatusFilter(SERVICE_CLAIMS_STATUS.APPROVED);
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.filter_status_cancelled,
          false,
        )
      ) {
        setStatusFilter(SERVICE_CLAIMS_STATUS.CANCELLED);
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.filter_status_paid,
          false,
        )
      ) {
        setStatusFilter(SERVICE_CLAIMS_STATUS.PAID);
      }

      if (
        auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.filter_posted_yes,
          false,
        )
      ) {
        setInvoicePostedFilter("Yes");
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.filter_posted_no,
          false,
        )
      ) {
        setInvoicePostedFilter("No");
      }

      if (
        auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.filter_downloaded_yes,
          false,
        )
      ) {
        setDownloadedFilter("Yes");
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.service_claim,
          PrivilegeActions.filter_downloaded_no,
          false,
        )
      ) {
        setDownloadedFilter("No");
      }
    }
  }, [userInfo]);

  // filter
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    setRefresh(true);
  }, [
    teamFilter,
    statusFilter,
    invoicePostedFilter,
    claimDateFrom,
    claimDateTo,
    downloadedFilter,
    calculatedFilter,
  ]);

  useEffect(() => {
    if (width <= smallDeviceWidth && loadMobileFilters) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MobileClaimsFilters key={"rb-body"} {...mobileFilterProps} />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [
    teamFilter,
    statusFilter,
    invoicePostedFilter,
    claimDateFrom,
    claimDateTo,
    claimList,
    downloadedFilter,
    calculatedFilter,
    width,
    loadMobileFilters,
  ]);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // set claims remaining for approval
  useEffect(() => {
    const list = claimList.filter(
      (c) =>
        c.status === SERVICE_CLAIMS_STATUS.PENDING && c.invoicePosted === "Yes",
    );
    setClaimsToBeApproved(list);
  }, [claimList]);

  // show message after approval and load next claim to approve
  useEffect(() => {
    if (lastApproval) {
      Swal.fire({
        title: `Success`,
        text: `Service claim for invoice ${lastApproval.invoiceNo} has been approved.`,
        icon: "success",
        showCancelButton: claimsToBeApproved.length > 0,
        confirmButtonText: "Ok",
        cancelButtonText:
          claimsToBeApproved.length > 0 ? "Next Service Claim" : undefined,
      }).then((approvalSuccessResult) => {
        if (approvalSuccessResult.isConfirmed) {
          toggleRightbar(layoutDispatch);
        } else {
          const nextClaim = claimsToBeApproved[0]; // next one in pending list
          if (nextClaim) {
            loadRightSideContent(nextClaim, true, true);
          }
        }
      });
    }
  }, [lastApproval]);

  function loadRightSideContent(claim, backButton, showBackDropInLargeScreen) {
    const size = width <= smallDeviceWidth ? "xs" : "lg";
    setRighbarContent(
      layoutDispatch,
      [
        <ClaimInvoiceInfoDrawer
          key={"rb-body"}
          claim={claim}
          invoice={claim.invoice}
          onApproveSingle={onApproveSingle}
          calculate={calculate}
          checkPosted={checkPosted}
          revertClaim={revertClaim}
          hasPermissionToApprove={auth.checkModulePrivilege(
            PrivilegeModules.service_claim,
            PrivilegeActions.approve,
          )}
          deleteClaim={deleteClaim}
          hasPermissionToDelete={auth.checkModulePrivilege(
            PrivilegeModules.service_claim,
            PrivilegeActions.delete,
          )}
          hasPermissionToRevert={auth.checkModulePrivilege(
            PrivilegeModules.service_claim,
            PrivilegeActions.revert_pks_claim,
          )}
        />,
      ],
      { size, backButton, showBackDropInLargeScreen },
    );
  }

  // set columns in data table
  const columns = claimsTableColumnConfig(
    {
      allowedSeeMoreColumns: auth.checkModulePrivilege(
        PrivilegeModules.service_claim,
        PrivilegeActions.see_more_columns,
      ),
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.service_claim,
        PrivilegeActions.view_detail,
      ),
      allowEdit: auth.checkModulePrivilege(
        PrivilegeModules.service_claim,
        PrivilegeActions.edit,
      ),
    },
    handleInvoiceNoClick,
    handleEditRow,
  );

  function filter() {
    let abortController = new AbortController();

    // set list filters
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const claims = response.data?.claimList || [];
        setClaimList(claims);
        setCollectionSize(response.data?.total);
        return () => {
          abortController.abort();
          return claims;
        };
      },
    );
  }

  async function calculate(claim) {
    const response = await sendRequest(
      `/v1/service-claim/calculate/${claim._id.toString()}`,
      "PUT",
      {},
    );
    if (response?.status === 200 && response?.data?.claim) {
      setMessage(`Service claim amount for invoice 
            ${response?.data?.claim?.invoiceNo} has been calculated.`);
      setRefresh(true);
      const updatedClaim = response?.data?.claim;
      updatedClaim.matrix = claim.matrix;
      loadRightSideContent(updatedClaim, true, false); // load new claim after re-calculation
    }
  }

  async function checkPosted(claim) {
    if (claim) {
      const response = await sendRequest(
        `/v1/service-claim/check-posted`,
        "POST",
        { invoiceNo: claim.invoiceNo, team: claim.team },
      );
      if (response?.status === 200 && response?.data) {
        if (response?.data.posted) {
          claim.invoicePosted = "Yes";
          setMessage(
            `Posted status for invoice ${claim.invoiceNo} has been updated.`,
          );
        } else {
          setWarningMessage(`${claim.invoiceNo} is not posted in Navision.`);
        }
        setRefresh(true);
        loadRightSideContent(claim, true, false);
      }
    }
  }

  async function revertClaim(claim) {
    if (claim) {
      const result = await Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to revert the service claim for invoice ${claim.invoiceNo}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      });
      if (result?.isConfirmed) {
        const response = await sendRequest(
          `/v1/service-claim/revert-pks-claim/${claim.invoiceNo}`,
          "PUT",
        );
        if (response?.status === 200 && response?.data) {
          claim.status = response?.data?.serviceClaim?.status;
          setMessage(
            `Service claim for invoice ${claim.invoiceNo} has been reverted.`,
          );
        }
        setRefresh(true);
        loadRightSideContent(claim, true, false);
      }
    }
  }

  function deleteClaim(claim) {
    if (claim) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to delete the service claim for invoice ${claim.invoiceNo}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await sendRequest(
            `/v1/service-claim/${claim._id}`,
            "DELETE",
          );
          if (response?.status === 200 && response?.data?.deleted) {
            setMessage(
              `Service claim for invoice ${claim.invoiceNo} has been deleted.`,
            );
            setRefresh(true);
            toggleRightbar(layoutDispatch);
          }
        }
      });
    }
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      team: teamFilter ? teamFilter.username : undefined,
      status: statusFilter ?? undefined,
      from: claimDateFrom ? getFromDate(claimDateFrom) : undefined,
      to: claimDateTo ? getToDate(claimDateTo) : undefined,
      posted: invoicePostedFilter ?? undefined,
      downloaded: downloadedFilter ?? undefined,
      calculated: calculatedFilter ?? undefined,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/service-claim/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${
          sortBy?.column && sortBy?.direction
            ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
            : ""
        }`,
      "GET",
      {},
    );
  }

  // populate sub-con list
  function loadSubCons() {
    let abortController = new AbortController();

    sendRequest(`/v1/user/get-list-by-role/option_sub_con`, "GET", {}).then(
      (response) => {
        const list = response.data?.users || [];
        setTeamList(list.filter((i) => i.status));

        return () => {
          abortController.abort();
        };
      },
    );
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function handleEditRow(claim) {
    props.history.push(`edit/${claim.invoiceNo}`);
  }

  //  when click on invoice no in the list
  function handleInvoiceNoClick(claim) {
    // if claim approval role, right drawer will open
    if (
      auth.checkModulePrivilege(
        PrivilegeModules.service_claim,
        PrivilegeActions.view_detail,
      )
    ) {
      setLoadMobileFilters(false);
      loadRightSideContent(claim, true, false);
      if (!layoutState.isRightbarOpened) {
        toggleRightbar(layoutDispatch);
      }
    }
  }

  // remove last approved claim
  function removeRecentlyApprovedClaim(invoiceNo) {
    const list = [...claimsToBeApproved];
    const index = list.findIndex((c) => c.invoiceNo === invoiceNo);
    if (index !== -1) {
      list.splice(index, 1);
    }
    setClaimsToBeApproved([...list]);
  }

  // when approve all button clicked
  async function onApproveAll() {
    if (claimList && claimList.length > 0) {
      const response = await getPaginatedData(1, 0, getFilterValues(), sortBy);
      const claims = response?.data?.claimList || [];
      const pendingList = claims.filter(
        (c) =>
          c.status === SERVICE_CLAIMS_STATUS.PENDING &&
          c.invoicePosted === "Yes" &&
          c.calculated === "Yes" &&
          c.orderBalance === 0,
      );
      if (pendingList.length > 0) {
        Swal.fire({
          title: `Confirmation`,
          text: `Proceeding will approve all the pending service claim(s) with posted invoice. Are you sure you want to proceed?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const result = await sendRequest(
              `/v1/service-claim/approve`,
              "POST",
              { invoiceNos: pendingList.map((c) => c.invoiceNo) },
            );

            if (result?.status === 200 && result?.data?.updated) {
              setMessage(
                `All the pending service claim(s) with posted invoice has been approved.`,
              );
              setRefresh(true);
            }
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No pending service claims with posted invoice found.",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No pending service claims with posted invoice found.",
      });
    }
  }

  function isEligibleToDownloadReport() {
    return (
      teamFilter?.username &&
      claimDateFrom &&
      claimDateTo &&
      statusFilter === SERVICE_CLAIMS_STATUS.APPROVED &&
      downloadedFilter === "No" &&
      claimList.length > 0
    );
  }

  // when approve single claim from the right drawer
  function onApproveSingle(claim) {
    if (
      claim &&
      claim.status === SERVICE_CLAIMS_STATUS.PENDING &&
      claim.invoicePosted === "Yes" &&
      claim.calculated === "Yes" &&
      claim.orderBalance === 0
    ) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to approve the service claim for invoice ${claim.invoiceNo}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await sendRequest(
            `/v1/service-claim/approve`,
            "POST",
            { invoiceNos: [claim.invoiceNo] },
          );

          if (result?.status === 200 && result?.data?.updated) {
            claim.status = SERVICE_CLAIMS_STATUS.APPROVED;
            setMessage(
              `Service claim for invoice ${claim.invoiceNo} has been approved.`,
            );
            setRefresh(true);
            removeRecentlyApprovedClaim(claim.invoiceNo); // remove & update pending list
            setLastApproval(claim); // show message & set load next claim for approval
            loadRightSideContent(claim, true, false);
          }
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Unable to approve the service claim for invoice ${claim.invoiceNo}`,
      });
    }
  }

  // generated and download csv report
  async function downloadReport(approved_only) {
    handleClosePopoverButton();

    const csvData = [];
    // only APPROVED and not downloaded records will be in the report
    const response = await getPaginatedData(1, 0, getFilterValues(), sortBy);
    let claims = response?.data?.claimList || [];

    if (approved_only) {
      claims = claims.filter(
        (c) =>
          c.downloaded === "No" && c.status === SERVICE_CLAIMS_STATUS.APPROVED,
      );
    }

    if (claims.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "No data to export.",
      });
      return;
    }

    const row = {
      "Claim No.": "",
      Installer: "",
      "Total Claim Amount": "",
      "Claim Date": "",
      "Approved Date": "",
      "Order Balance": "",
      "Customer Balance": "",
      "Customer No.": "",
      "Customer Name": "",
      "Invoice No.": "",
      "Invoice Date": "",
      "Sales Order No.": "",
      "Sales Order Date": "",
      "Pick Order No.": "",
      "Delivery Date": "",
      "Sales Person": "",
      "Team No.": "",
      "Team Name": "",
      "Contract No": "",
      "Item No.": "",
      "Description Model": "",
      "Qty.": "",
      "Unit Price": "",
      Discount: "",
      Total: "",
      "Claim Amount": "",
    };

    for (const claim of claims) {
      // claim record
      csvData.push({
        "Claim No.": claim.invoiceNo,
        Installer: claim.installer?.username,
        "Total Claim Amount": claim.totalClaimAmount
          ? claim.totalClaimAmount.toFixed(2)
          : "0.00",
        "Claim Date": claim.claimDate
          ? moment(claim.claimDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        "Approved Date": claim.approvedDate
          ? moment(claim.approvedDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        "Order Balance": claim.orderBalance
          ? claim.orderBalance.toFixed(2)
          : "0.00",
        "Customer Balance": claim.customerBalance
          ? claim.customerBalance.toFixed(2)
          : "0.00",
        "Customer No.": claim.invoice?.customerNo,
        "Customer Name": claim.invoice?.billToName,
        "Invoice No.": claim.invoiceNo,
        "Invoice Date": claim.invoice?.invoiceDate
          ? moment(claim.invoice?.invoiceDate).format("DD/MM/YYYY")
          : "",
        "Sales Order No.": claim.invoice?.orderNo,
        "Sales Order Date": claim?.claimDate
          ? moment(claim?.claimDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        "Pick Order No.": claim.invoice?.pickOrderNo,
        "Delivery Date": claim.invoice?.deliveryDate
          ? moment(claim.invoice?.deliveryDate).format("DD/MM/YYYY")
          : "",
        "Sales Person": claim.invoice?.salesPerson,
        "Team No.": claim.team,
        "Team Name": claim.teamName,
        "Contract No.": claim.invoice?.contractNo,
        "Item No.": "",
        "Description/Model": "",
        "Qty.": "",
        "Unit Price": "",
        "Discount Amount": "",
        Total: "",
        "Claim Amount": "",
      });
      // invoice lines
      if (claim.invoice?.invoiceLines) {
        for (const item of claim.invoice.invoiceLines) {
          csvData.push({
            ...row,
            ...{
              "Item No.": item.no,
              "Description Model": item.description,
              "Qty.": item.quantity,
              "Unit Price": item.unitPrice ? item.unitPrice.toFixed(2) : "0.00",
              Discount: item.lineDiscountAmount
                ? item.lineDiscountAmount.toFixed(2)
                : "0.00",
              Total: item.lineTotalAmount
                ? item.lineTotalAmount.toFixed(2)
                : "0.00",
              "Claim Amount": item.claimAmount
                ? item.claimAmount.toFixed(2)
                : "0.00",
            },
          });
        }
      }
      csvData.push({});
    }

    csvData.push({
      ...row,
      ...{
        Total: "Total Claim Amount",
        "Claim Amount": _.sum(claims.map((c) => c.totalClaimAmount)),
      },
    });

    const today = moment().format("YYYYMMDD_HHmmss");
    const filename = `SERVICE_CLAIM-REPORT-${today}.csv`;
    const downloaded = downloadCSV(csvData, filename);

    if (downloaded && approved_only) {
      // update the list as downloaded
      sendRequest(
        `/v1/service-claim/downloaded-status`,
        "POST",
        claims.map((claim) => claim._id),
      ).then((result) => {
        if (result.data?.updated) {
          setRefresh(true);
        }
      });
    }
  }

  async function downloadReportMonthlyPaidByTeam() {
    handleClosePopoverButton();

    // 1. Filter claims and handle empty data
    const paidClaims = await getFilteredPaidClaims();
    if (paidClaims.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "No data to export.",
      });
      return;
    }

    // 2. Group claims by month and team
    const { groupedClaims, monthArr, teamArr } =
      groupClaimsByMonthAndTeam(paidClaims);

    // // 3. Prepare CSV data
    const csvData = buildCsvData(groupedClaims, monthArr, teamArr);

    // // 4. Download CSV
    const filename = generateCsvFilename();
    downloadCSV(csvData, filename, [], { isColumnnDef: false });
  }

  // Helper functions
  async function getFilteredPaidClaims() {
    const filterValues = getFilterValues();
    const response = await getPaginatedData(
      1,
      0,
      { ...filterValues, status: "PAID" },
      sortBy,
    );
    let claims = response?.data?.claimList || [];
    return claims;
  }

  function groupClaimsByMonthAndTeam(claims) {
    const monthArr = [];
    const teamArr = [];

    const groupedClaims = _(claims)
      .groupBy((claim) => {
        const formattedDate = moment(claim.claimDate).format("MMM-YYYY");
        return formattedDate;
      })
      .map((claimsByMonth, month) => {
        monthArr.push(month);
        const teams = _(claimsByMonth)
          .groupBy((claim) => claim.team)
          .map((claimsByTeam, team) => {
            teamArr.push({ name: claimsByTeam[0].teamName, id: team });
            return {
              team,
              teamName: claimsByTeam[0].teamName,
              total: claimsByTeam.reduce(
                (acc, claim) => acc + Number(claim.totalClaimAmount),
                0,
              ),
            };
          })
          .value();
        return {
          month,
          teams,
          total: _.sumBy(teams, "total"),
        };
      })
      .value()
      .sort((a, b) => compareDates(a.month, b.month));
    return {
      groupedClaims,
      monthArr,
      teamArr,
    };
  }

  function buildCsvData(groupedClaims, monthArr, teamArr) {
    const csvData = [];
    monthArr.sort(compareDates);

    csvData.push(["Team", "Name", ...monthArr]);

    teamArr.forEach((team) => {
      const monthTotalsByTeam = monthArr.map((month) => {
        const claimBymonth = groupedClaims.find((item) => item.month === month);
        return (
          claimBymonth?.teams.find((item) => item.team === team.id)?.total ??
          "0"
        );
      });
      csvData.push([team.id, team.name, ...monthTotalsByTeam]);
    });

    const monthTotals = monthArr.map((month) => {
      return groupedClaims.find((item) => item.month === month)?.total ?? "0";
    });

    csvData.push(["", "Total:", ...monthTotals]);

    return csvData;
  }

  function generateCsvFilename() {
    const today = moment().format("YYYYMMDD_HHmmss");
    return `SERVICE_CLAIM_MONTHLY_PAID_TEAM-REPORT-${today}.csv`;
  }

  // open mobile filters drawer
  function openMobileFilters() {
    setLoadMobileFilters(true);
    toggleRightbar(layoutDispatch);
  }

  async function refreshClaimData() {
    const response = await sendRequest(
      `/v1/service-claim/refresh-data`,
      "POST",
    );
    if (response?.status === 200 && response?.data) {
      if (response?.data.completed) {
        setMessage(`All pending service claim(s) has been refreshed.`);
      }
      setRefresh(true);
    }
  }

  function generateReport(filters) {
    return sendRequest(
      `/v1/service-claim-report/generate-report`,
      "POST",
      filters,
    );
  }

  async function downloadApprovedReport() {
    if (claimList.length > 0) {
      const filters = {
        ...getFilterValues(),
        teamName: teamFilter ? teamFilter.name : undefined,
      };
      const response = await generateReport(filters);
      if (response?.status === 200 && response?.data?.url) {
        filter();

        const link = document.createElement("a");
        link.setAttribute("href", response?.data?.url);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", response?.data?.fileName);
        link.click();
      }
    }
  }

  function handleClosePopoverButton() {
    setPopoverButtonOpen(null);
  }

  function handleOpenPopoverButton(event) {
    setPopoverButtonOpen(event.currentTarget);
  }

  const downloadButtonPopover = (
    <Popover
      className="mega-menu"
      open={!!popoverButtonOpen}
      anchorEl={popoverButtonOpen}
      onClose={handleClosePopoverButton}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List>
        <ListItem>
          <ListItemButton onClick={() => downloadReport(false)}>
            <ListItemText primary={`Service Claim`} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemButton onClick={downloadReportMonthlyPaidByTeam}>
            <ListItemText primary={`Monthly Payment by Team`} />
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Service Claim List</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.service_claim,
            PrivilegeActions.add,
          ) && (
            <Button
              className={"primary"}
              onClick={() => props.history.push("card")}
            >
              New
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.service_claim,
            PrivilegeActions.approve,
          ) && (
            <Button className={"primary"} onClick={() => onApproveAll()}>
              Approve
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.service_claim,
            PrivilegeActions.refresh,
          ) && (
            <Button className={"primary"} onClick={() => refreshClaimData()}>
              Refresh
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.service_claim,
            PrivilegeActions.download_report,
          ) && (
            <Button className={"primary"} onClick={handleOpenPopoverButton}>
              Download Report <ExpandMoreIcon />
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.service_claim_report,
            PrivilegeActions.download_report_approved_only,
          ) && (
            <Button
              className={"primary"}
              onClick={() => downloadApprovedReport(true)}
              disabled={!isEligibleToDownloadReport()}
            >
              Download Report
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container className={"form"}>
            <Grid item xs={12}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={10} lg={2}>
                  <TextField
                    autoComplete={"off"}
                    id={"text-searchbox"}
                    variant={"outlined"}
                    value={textFilter}
                    onChange={(e) => setTextFilter(e.target.value)}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {width >= smallDeviceWidth ? (
                  <>
                    {auth.checkModulePrivilege(
                      PrivilegeModules.service_claim,
                      PrivilegeActions.show_team_filter,
                    ) && (
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Autocomplete
                          id={"team-filter"}
                          options={teamList}
                          getOptionLabel={(option) =>
                            `${option.username}-${option.name}`
                          }
                          value={teamFilter}
                          onChange={(e, newValue) => setTeamFilter(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Team"}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<ArrowDownIcon />}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <Autocomplete
                        id={"status-filter"}
                        options={statusList}
                        getOptionLabel={(option) => option}
                        value={statusFilter}
                        onChange={(e, newValue) => setStatusFilter(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Status"}
                            variant={"outlined"}
                          />
                        )}
                        popupIcon={<ArrowDownIcon />}
                      />
                    </Grid>
                    {auth.checkModulePrivilege(
                      PrivilegeModules.service_claim,
                      PrivilegeActions.see_more_columns,
                    ) && (
                      <>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          <Autocomplete
                            id={"posted-filter"}
                            options={postedList}
                            getOptionLabel={(option) => option?.toUpperCase()}
                            value={invoicePostedFilter}
                            onChange={(e, newValue) =>
                              setInvoicePostedFilter(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Posted"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          <Autocomplete
                            id={"downloaded-filter"}
                            options={postedList}
                            getOptionLabel={(option) => option?.toUpperCase()}
                            value={downloadedFilter}
                            onChange={(e, newValue) =>
                              setDownloadedFilter(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Downloaded"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          {/* <Autocomplete
                                                id={"calculated-filter"}
                                                options={postedList}
                                                getOptionLabel={(option) => option?.toUpperCase()}
                                                value={calculatedFilter}
                                                onChange={(e, newValue) => setCalculatedFilter(newValue)}
                                                renderInput={(params) =>
                                                    <TextField {...params} label={"Calculated"} variant={"outlined"} />}
                                                popupIcon={<ArrowDownIcon />}
                                            /> */}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <DateFilter
                        label={"Claim Date - From"}
                        value={claimDateFrom}
                        maxDate={claimDateTo}
                        onChange={(newValue) => setClaimDateFrom(newValue)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <DateFilter
                        label={"Claim Date - To"}
                        value={claimDateTo}
                        minDate={claimDateFrom}
                        onChange={(newValue) => setClaimDateTo(newValue)}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => openMobileFilters()}
                      className={classes.drawerToggleBtn}
                    >
                      {layoutState.isRightbarOpened ? (
                        <BackIcon />
                      ) : (
                        <FilterIcon className={classes.toggleRightBarIcon} />
                      )}
                    </IconButton>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <DataTable
                    fixedHeader={true}
                    persistTableHead={true}
                    columns={columns}
                    data={claimList}
                    sortServer
                    onSort={sortByColumn}
                    pagination
                    paginationServer
                    paginationDefaultPage={page}
                    paginationPerPage={pageSize}
                    paginationTotalRows={collectionSize}
                    onChangeRowsPerPage={(count) =>
                      handleRowsPerPageChanged(count)
                    }
                    onChangePage={(page) => handlePageChanged(page)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {downloadButtonPopover}
    </>
  );
};

export default withRouter(ManageServiceClaims);
