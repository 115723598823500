import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  IconButton,
  Box,
} from "@mui/material";
import {
  Add,
  Clear,
  KeyboardArrowDown as ArrowDownIcon,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import DataTable from "react-data-table-component";
import { resourceTableColumnConfig } from "./data-table/resource-table-column-config";
import useStyles from "./styles";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";

const InstallationClaimMatrixCard = (props) => {
  const classes = useStyles();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const { matrixId } = useParams(); // url param => matrixId when editing

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
  } = useForm({ mode: "all" });

  // un-assigned sub-con list
  const [subConList, setSubConList] = useState([]);
  const [subCons, setSubCons] = useState([]); // selected sub-cons

  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [enableSwitchValue, setEnableSwitchValue] = useState(true);
  const [currentMatrix, setCurrentMatrix] = useState(null); // when edit
  const [resourceList, setResourceList] = useState([]);
  const [rowsInvalid, setRowsInvalid] = useState(false);
  const [genericItemsList, setGenericItemsList] = useState([]);

  useEffect(() => {
    document.title = "New - Installation Claim Matrix Card";
    loadGenericItems();
    loadSubConUsers();

    // redirect to default page if not authorized
    if (
      matrixId &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.manage_installation_claim_matrix,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  // get current matrix in edit view
  useEffect(() => {
    if (!isLoading && !responseData && matrixId) {
      sendRequest(`/v1/installation-claim-matrix/get-by-id/` + matrixId, "GET");
    }
  }, [matrixId]);

  // set form values when edit view
  useEffect(() => {
    if (matrixId && responseData?.matrix) {
      const matrix = responseData?.matrix;
      document.title = `${matrix.name} - Installation Claim Matrix Card`;
      loadSubConUsers();
      setCurrentMatrix(matrix);
      setValue("name", matrix.name || "");
      setValue("description", matrix.description || "");
      setValue("subCons", matrix.subCons || []);
      setValue("enable", matrix.enable || false);

      setEnableSwitchValue(matrix.enable);
      setSubCons(matrix.subCons);
      // set resource list comes from API
      setResourceList([...matrix.rows]);
    }
  }, [responseData]);

  useEffect(() => {
    const invalid =
      resourceList.findIndex((r) => !r.resourceCode || !r.claimValue) > -1;
    setRowsInvalid(invalid);
  }, [resourceList]);

  function loadGenericItems() {
    sendRequest(
      `/v1/installation-claim-matrix/get-generic-items`,
      "GET",
      {},
    ).then((response) => {
      setGenericItemsList(response.data?.genericItems || []);
    });
  }

  // navigate back to installation claim matrix list
  const navigateToList = () => {
    if (!matrixId)
      window.location.href = "#/app/installation-claim-matrix/list";
  };

  // populate un-assigned sub-con user list
  function loadSubConUsers() {
    sendRequest(
      `/v1/installation-claim-matrix/get-matrix-subcon-users`,
      "GET",
      {},
    ).then((response) => {
      setSubConList(response.data?.users || []);
    });
  }

  // when submit the installation claim matrix form
  const handleSubmitForm = async (data, e, duplicate = false) => {
    e.preventDefault();
    if (resourceList.length === 0) {
      return false;
    }

    const matrix = {
      name: data.name,
      description: data.description,
      subCons: subCons,
      rows: resourceList.filter((r) => !!r.resourceCode && !!r.claimValue),
      enable: data.enable,
    };
    let savedMatrix = undefined,
      message = "";
    if (currentMatrix && matrixId) {
      // when edit
      matrix._id = currentMatrix._id;
      const result = await sendRequest(
        `/v1/installation-claim-matrix/${matrixId}`,
        "PUT",
        matrix,
      );
      if (result?.status === 200 && result?.data?.matrix) {
        message = `Installation claim matrix ${result.data.matrix.name} has been updated.`;
        savedMatrix = result?.data?.matrix;
      }
    } else {
      const result = await sendRequest(
        `/v1/installation-claim-matrix`,
        "POST",
        matrix,
      );
      if (result?.status === 201 && result?.data?.matrix) {
        message = `New installation claim matrix ${result.data.matrix.name} has been created.`;
        savedMatrix = result?.data?.matrix;
      }
    }
    if (duplicate && savedMatrix) {
      duplicateMatrix(savedMatrix);
    } else {
      setMessage(message);
    }
  };

  // reset prev matrix data & copy resources to new matrix
  function duplicateMatrix(matrix) {
    props.history.push("/app/installation-claim-matrix/card");
    setCurrentMatrix(null);
    setValue("name", "");
    setValue("description", "");
    setValue("subCons", []);
    setValue("enable", true);
    setEnableSwitchValue(true);
    setSubCons([]);
    setResourceList([...matrix.rows]);
  }

  // validate sub-cons multi select when touched
  const onBlurSubCons = () => {
    if (!getValues("subCons"))
      setError("subCons", { message: "Sub-Con cannot be blank." });
  };

  // set resource table columns
  const columns = resourceTableColumnConfig(
    onRemoveRow,
    onChangeResourceCode,
    checkForDuplicatedCodes,
    onChangeIsPercentageCheck,
    onChangeClaimValue,
    onKeyDownClaimValue,
    onChangeGenericItem,
    onChangeIsGenericCheck,
    genericItemsList,
  );

  function clearSelectedGenericItem(index) {
    const rList = [...resourceList];
    rList[index].resourceCode = "";
    rList[index].isGeneric = false;
    setResourceList(rList);
  }

  function onChangeGenericItem(i, newValue) {
    if (newValue == null) {
      clearSelectedGenericItem(i);
      return;
    }

    if (checkIfDuplicatedGenericItem(i, newValue.name)) {
      setWarningMessage(
        `Duplicate resource ${newValue.name.toUpperCase()} found in the list.`,
      );
      clearSelectedGenericItem(i);
      return;
    }

    const rList = [...resourceList];
    rList[i].resourceCode = newValue == undefined ? "" : newValue.name;
    rList[i].isGeneric = newValue == undefined ? false : true;
    setResourceList(rList);
  }

  function checkIfDuplicatedGenericItem(index, code) {
    let lFound = false;

    for (let i = 0; i < resourceList.length; i++) {
      if (i != index && resourceList[i].resourceCode == code.toUpperCase()) {
        lFound = true;
        break;
      }
    }

    return lFound;
  }

  // remove resource from list
  function onRemoveRow(i) {
    const rows = [...resourceList];
    rows.splice(i, 1);
    setResourceList([...rows]);
  }

  function onChangeResourceCode(i, value) {
    const rList = [...resourceList];
    rList[i].resourceCode = value.trim().toUpperCase();
    setResourceList(rList);
  }

  function onChangeIsPercentageCheck(i, value) {
    const rList = [...resourceList];
    rList[i].isPercentage = value;
    setResourceList(rList);
  }

  function onChangeIsGenericCheck(i, value) {
    const rList = [...resourceList];
    rList[i].isGeneric = value;
    setResourceList(rList);
  }

  function onChangeClaimValue(i, value) {
    const rList = [...resourceList];
    rList[i].claimValue = +value;
    setResourceList(rList);
  }

  function createNewRow() {
    setResourceList([
      ...resourceList,
      {
        resourceCode: "",
        isPercentage: false,
        claimValue: 0,
        type: "",
      },
    ]);
  }

  function onKeyDownClaimValue(e) {
    if (e.key === "Enter" && e.target.value && !rowsInvalid) {
      e.preventDefault();
      createNewRow();
    }
  }

  // check duplicated resource code entered
  const isDuplicating = (code) =>
    resourceList.filter((r) => r.resourceCode === code.trim().toUpperCase())
      .length > 1;

  function checkForDuplicatedCodes(i, code) {
    let foundDuplicate = false;

    if (resourceList.length > 1 && code.length > 0 && isDuplicating(code)) {
      setWarningMessage(
        `Duplicate resource ${code.toUpperCase()} found in the list.`,
      );
      const rList = [...resourceList];
      rList[i].resourceCode = "";
      rList[i].isGeneric = false;
      foundDuplicate = true;
    }

    return foundDuplicate;
  }

  return (
    <>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                Installation Claim Matrix Card
                {currentMatrix ? ` - ${currentMatrix.name}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button
                onClick={() =>
                  props.history.push("/app/installation-claim-matrix/list")
                }
              >
                Cancel
              </Button>
              {((!currentMatrix &&
                !matrixId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.manage_installation_claim_matrix,
                  PrivilegeActions.add,
                )) ||
                (currentMatrix &&
                  matrixId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.manage_installation_claim_matrix,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  Save
                </Button>
              )}
              {((!currentMatrix &&
                !matrixId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.manage_installation_claim_matrix,
                  PrivilegeActions.add,
                )) ||
                (currentMatrix &&
                  matrixId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.manage_installation_claim_matrix,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit((data, e) =>
                    handleSubmitForm(data, e, true),
                  )}
                >
                  Save/Duplicate
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={12}>
                              <TextField
                                id={"name"}
                                label={"Name"}
                                variant={"outlined"}
                                {...register("name", {
                                  required: {
                                    value: true,
                                    message: "Name cannot be blank.",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message:
                                      "Maximum 50 characters are allowed.",
                                  },
                                })}
                                autoComplete={"off"}
                                error={!!formErrors?.name}
                                helperText={formErrors?.name?.message}
                                onChange={(event) => validateText(event)}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id={"description"}
                                label={"Description"}
                                variant={"outlined"}
                                {...register("description")}
                                autoComplete={"off"}
                                error={!!formErrors?.description}
                                helperText={formErrors?.description?.message}
                                onChange={(event) => validateText(event)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={8} xl={9}>
                          <Controller
                            name={`subCons`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Sub-Con cannot be blank.",
                              },
                            }}
                            render={(props) => (
                              <Autocomplete
                                id={"subCons"}
                                multiple
                                options={subConList}
                                getOptionLabel={(option) =>
                                  `${option.username}-${option.name}`
                                }
                                onBlur={(event) => onBlurSubCons()}
                                value={subCons}
                                // renderOption={(props, option) => (
                                //     <Box component="li" {...props}>{option.username}-{option.name}</Box>
                                // )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Sub-Con"}
                                    variant={"outlined"}
                                    error={!!props?.formState?.errors?.subCons}
                                    helperText={
                                      props?.formState?.errors?.subCons?.message
                                    }
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setSubCons(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<ArrowDownIcon />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Resources</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columns}
                      data={resourceList}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton onClick={createNewRow} disabled={rowsInvalid}>
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
};

export default withRouter(InstallationClaimMatrixCard);
