import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Event,
  KeyboardArrowDown as ArrowDownIcon,
  DeleteOutline as DeleteIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import {
  InputAdornment,
  IconButton,
  InputBase,
  Select,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";

export const MachineTableConfig = (
  disabled,
  onChangeMachine,
  onBlurSeqOfMonth,
  serviceIntervalOptions,
  serviceReminderOptions,
  onRemoveMachineRow,
  checkForDuplicatedSerialNo,
) => {
  const {
    formState: { errors: formErrors },
  } = useForm({ mode: "all" });

  return [
    {
      name: "",
      width: "50px",
      button: true,
      cell: (row, index) => (
        <IconButton
          onClick={() => onRemoveMachineRow(index)}
          disabled={disabled || row.hasServiceJob}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      name: "Brand",
      width: "150px",
      cell: (row, index) => {
        return (
          <InputBase
            disabled={disabled || row.hasServiceJob}
            variant={"outlined"}
            value={row.brand}
            onChange={(e) => onChangeMachine("brand", index, e.target.value)}
          />
        );
      },
    },
    {
      name: "Model",
      width: "200px",
      cell: (row, index) => {
        return (
          <InputBase
            disabled={disabled || row.hasServiceJob}
            variant={"outlined"}
            value={row.model}
            onChange={(e) => onChangeMachine("model", index, e.target.value)}
          />
        );
      },
    },
    {
      name: "Serial No.",
      width: "200px",
      cell: (row, index) => {
        return (
          <InputBase
            disabled={disabled || row.hasServiceJob}
            variant={"outlined"}
            value={row.serialNo}
            onBlur={(e) =>
              checkForDuplicatedSerialNo("serialNo", index, e.target.value)
            }
            onChange={(e) => onChangeMachine("serialNo", index, e.target.value)}
          />
        );
      },
    },
    {
      name: "Next Service Month",
      width: "150px",
      cell: (row, index) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDateTimePicker
              variant={"inline"}
              inputVariant={"outlined"}
              closeOnSelect={true}
              hideTabs={true}
              showToolbar={false}
              value={
                row.nextServiceMonth ? new Date(row.nextServiceMonth) : null
              }
              views={["year", "month"]}
              inputFormat={"MM/yyyy"}
              format={"MM/yyyy"}
              mask="__/____"
              onChange={(newValue) =>
                onChangeMachine("nextServiceMonth", index, newValue)
              }
              InputAdornmentProps={{ position: "start" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Event />
                  </InputAdornment>
                ),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete={"off"}
                  error={!!formErrors?.nextServiceMonth || !!params.error}
                  helperText={
                    formErrors?.nextServiceMonth?.message ||
                    (!!params.error && "Next Service Month is invalid.")
                  }
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "",
                  }}
                />
              )}
              ampm={false}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      name: "Sequence",
      width: "100px",
      right: true,
      cell: (row, index) => {
        return (
          <InputBase
            type="number"
            disabled={disabled}
            variant={"outlined"}
            className={"dollar"}
            value={row.seqOfMonth}
            onChange={(e) => {
              onChangeMachine("seqOfMonth", index, e.target.value);
            }}
            onBlur={(e) => {
              onBlurSeqOfMonth(index, e.target.value);
              //console.log(`Input at index ${index} lost focus`);
            }}
          />
        );
      },
    },
    {
      name: "Interval",
      width: "120px",
      cell: (row, index) => {
        return (
          <Select
            key={`select-serv-interval-${index}`}
            variant={"outlined"}
            value={row.serviceInterval}
            onChange={(e) =>
              onChangeMachine("serviceInterval", index, e.target.value)
            }
            disabled={disabled}
            IconComponent={ArrowDownIcon}
          >
            {serviceIntervalOptions.map((element, i) => (
              <MenuItem
                key={`serv-interval-option-${i}`}
                value={element.value}
                selected={row.serviceInterval == element.value}
              >
                {`${element.description}`}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      name: "Reminder (Month)",
      width: "120px",
      right: true,
      cell: (row, index) => {
        return (
          <InputBase
            type="number"
            disabled={disabled}
            variant={"outlined"}
            className={"dollar"}
            value={row.serviceReminder}
            onChange={(e) =>
              onChangeMachine("serviceReminder", index, e.target.value)
            }
          />
        );
      },
    },
    {
      name: "Total Services",
      width: "100px",
      right: true,
      cell: (row, index) => {
        return (
          <InputBase
            type="number"
            disabled={disabled}
            variant={"outlined"}
            className={"dollar"}
            value={row.currentSequence}
            onChange={(e) =>
              onChangeMachine("currentSequence", index, e.target.value)
            }
          />
        );
      },
    },
    {
      name: "Enable",
      width: "80px",
      center: true,
      selector: (row) => row.enable,
      cell: (row, index) => {
        return (
          <>
            <Switch
              checked={row.enable}
              onChange={(e, value) => onChangeMachine("enable", index, value)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
      },
      sortable: true,
      sortField: "enable",
    },
  ];
};
