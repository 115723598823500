import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { showSymbol } from "../../../helper/show-symbol";
import moment from "moment";
import {
  DeleteOutline as DeleteIcon,
  AttachFileOutlined as BillIcon,
  ChatBubbleOutlineOutlined as FeedbackIcon,
} from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";

export const fnbEventBookingTableColumnConfig = (
  {
    allowedViewDetail,
    allowedDelete,
    allowedEnableDisable,
    allowedCheckFeedback,
    allowedCheckBill,
  },
  handleNameClick,
  handleDeleteFnbEventBooking,
  handleEditFnbEventBooking,
  showFeedback,
  copySurveyUrl,
) => {
  let columns = [
    {
      name: "No.",
      width: "90px",
      selector: (row) => row.eventBookingNo,
      cell: (row) => {
        if (allowedViewDetail) {
          return (
            <Link
              to={`/app/fnb-event-booking/card/${row._id}`}
              onClick={(e) => handleNameClick(row._id)}
            >
              {row.eventBookingNo}
            </Link>
          );
        } else {
          return <>{row.eventBookingNo}</>;
        }
      },
      sortable: true,
      sortField: "eventBookingNo",
    },
  ];

  if (allowedDelete) {
    columns = [
      ...columns,
      {
        name: "",
        right: true,
        width: "50px",
        cell: (row) => {
          return (
            <>
              {!row.approvedBy && (
                <IconButton onClick={(e) => handleDeleteFnbEventBooking(row)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          );
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      name: "Self-Collection",
      width: "120px",
      selector: (row) => (row.selfCollection ? "YES" : "NO"),
      center: true,
      sortable: true,
      sortField: "selfCollection",
    },
    {
      name: "Event",
      width: "250px",
      selector: (row) => row.eventName,
      sortable: true,
      wrap: true,
      sortField: "eventName",
    },
    {
      name: "Menu",
      width: "250px",
      selector: (row) => row.menuData?.map((s) => `${s.menuName}`).toString(),
      sortable: true,
      wrap: true,
      sortField: "eventMenuNo",
    },
    {
      name: "Outlet",
      width: "250px",
      selector: (row) =>
        row.fnbOutlet
          ?.map((o) => `${o.name}`)
          .toString()
          .toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "storeCode",
    },
    {
      name: "Event Date",
      width: "240px",
      selector: (row) => row.date,
      cell: (row, index, column, id) => {
        return `${moment(row.date).format("DD/MM/YYYY")} ${row.startTime} - ${
          row.endTime
        }`;
      },
      sortable: true,
      sortField: "date",
    },
    {
      name: "No. of Pax",
      width: "100px",
      right: true,
      selector: (row) => row.noOfPax,
      sortable: true,
      wrap: true,
      sortField: "noOfPax",
    },
    {
      name: "Requested By",
      minWidth: "200px",
      selector: (row) =>
        row.requestedByData?.map((s) => `${s.name}-${s.username}`).toString(),
      sortable: true,
      wrap: true,
      sortField: "requestedBy",
    },
    {
      name: "Created By",
      width: "200px",
      selector: (row) =>
        row.createdByData?.map((s) => `${s.name}-${s.username}`).toString(),
      sortable: true,
      wrap: true,
      sortField: "createdBy",
    },
    {
      name: "Created At",
      width: "170px",
      wrap: true,
      selector: (row) => row.createdAt,
      cell: (row, index, column, id) => {
        return moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss");
      },
      sortable: true,
      sortField: "createdAt",
    },
  ];

  columns = [
    ...columns,
    {
      name: "Survey",
      width: "80px",
      center: true,
      cell: (row) => {
        return (
          <>
            {row.menuData?.map((s) => `${s.menuName}`).toString() != "" && (
              <>
                <IconButton onClick={(e) => copySurveyUrl(row._id)}>
                  <LinkIcon />
                </IconButton>
              </>
            )}
          </>
        );
      },
    },
  ];

  if (allowedCheckBill) {
    columns = [
      ...columns,
      {
        name: "Bill",
        width: "50px",
        center: true,
        cell: (row) => {
          return (
            <>
              {!!row.approvedBy && (
                <>
                  <a href={row.billUrl} target="_blank" download>
                    <BillIcon />
                  </a>
                </>
              )}
            </>
          );
        },
      },
    ];
  }

  if (allowedCheckFeedback) {
    columns = [
      ...columns,
      {
        name: "Feedback",
        width: "80px",
        center: true,
        cell: (row) => {
          return (
            <>
              {!!row.hasFeedback && (
                <>
                  <IconButton onClick={(e) => showFeedback(row.eventBookingNo)}>
                    <FeedbackIcon />
                  </IconButton>
                </>
              )}
            </>
          );
        },
      },
    ];
  }

  if (allowedEnableDisable) {
    columns = [
      ...columns,
      {
        name: "Enable",
        width: "80px",
        center: true,
        selector: (row) => row.enabled,
        cell: (row) => {
          return (
            <>
              {!row.approvedBy && (
                <Switch
                  checked={row.enabled}
                  onChange={(e, value) =>
                    handleEditFnbEventBooking(row, true, value)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
            </>
          );
        },
        sortable: true,
        sortField: "enabled",
      },
    ];
  }

  return columns;
};
