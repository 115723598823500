import { useUserState } from "../context/UserContext";

export const useRoleAuthorization = () => {
    const { userInfo } = useUserState();
    return {
        auth: {
            checkModulePrivilege: (_module, _action, override_with_admin_access = true) => userInfo.privileges && ((override_with_admin_access && userInfo?.roles?.includes('admin')) || userInfo?.privileges.filter(p => {return p.module == _module && p.action == _action}).length > 0),
            isPrivilegeDataLoaded: () => !(userInfo?.privileges == undefined),
            checkGroupPrivilege: (_group, _action) => userInfo.privileges && (userInfo?.roles?.includes('admin') || userInfo?.privileges.filter(p => {return p.group == _group && p.action == _action}).length > 0),
        }
    };
};
