import { Box, Typography } from '@mui/material';
import DataTable from 'react-data-table-component';
import { DataGrid } from '@mui/x-data-grid';
import { convertThisToCurrency } from '../../helper/convert-this-to-currency';


// const tableConfig = [
//   {
//     name: 'Option SKU',
//     selector: row => row.sku,
//   }, {
//     name: 'Option Name',
//     selector: row => row.name,
//   }, {
//     name: 'Option Price',
//     selector: row => convertThisToCurrency(row.price),
//   },
// ];
const cols = [
  { field: 'sku', headerName: 'Option SKU', width: 90, sortable: false },
  { 
    field: 'name', 
    headerName: 'Option Name', 
    width: 300, 
    sortable: false,
    valueGetter: (params) => params.row.name.toUpperCase(),
  },
  {
    field: 'price',
    headerName: 'Option Price',
    width: 100,
    sortable: false,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => convertThisToCurrency(params.row.price),
  },
];
export default function ItemRowExpandable({ data: item }) {
  const selectedAddOnCount = item?.productAddons?.length || 0;
  return <>
    {/* {selectedAddOnCount === 0 && <Typography>Sorry! There are no selected add-on for this item.</Typography>} */}
    {selectedAddOnCount > 0 && (
      // <DataTable columns={tableConfig} data={item?.productAddons?.flatMap(selectedOption => selectedOption.options)}/>
      <DataGrid
        rows={item?.productAddons?.flatMap(selectedOption => selectedOption.options)}
        columns={cols}
        rowSelection={false}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableColumnMenu={true}
        disableVirtualization={true}
        hideFooter={true}
        getRowId={row => row.entityId}
      />
    )}
  </>;
}
