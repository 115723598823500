import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Clear as ClearIcon, Event as CalendarIcon } from "@mui/icons-material";
import React from "react";

export default function DateFilter({
  label,
  value,
  onChange = (newValue) => {},
  minDate = undefined,
  maxDate = undefined,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        value={value}
        format={"dd/MM/yyyy"}
        minDate={minDate ?? null}
        maxDate={maxDate ?? null}
        onChange={onChange}
        slotProps={{
          textField: {
            placeholder: "",
            InputProps: {
              endAdornment: value && (
                <InputAdornment position="end">
                  <IconButton onClick={() => onChange(null)}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          },
          inputAdornment: {
            position: "start",
          },
        }}
      />
    </LocalizationProvider>
  );
}
