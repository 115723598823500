import React, { useEffect, useState } from "react";
import CopyIcon from '@mui/icons-material/ContentCopy';

import {
  Grid, Typography, Button
} from "@mui/material";
import { useLocation, withRouter, Link } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo
import logo from "./gain-city-logo.png";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import Swal from "sweetalert2";
import { useUserState, signOut, useUserDispatch } from "../../context/UserContext";

const TwoFASetup = (props) => {
  const userDispatch = useUserDispatch();
  const { userInfo } = useUserState();
  const { httpRequestError: error, responseData, sendRequest: loginAPI } = useHttpRequest();
  const classes = useStyles();
  const [qrcodeData, setQRcodeData] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const processContinue = async () => {
    props.history.push({ pathname: "/two-fa-verification" });
  };

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      loadSecretKeyAndQRCode();
    }
  }, []);

  useEffect(() => {
    if (responseData?.secretKey) {
      setQRcodeData(responseData.qrcodeData);
      setSecretKey(responseData.secretKey);
    }

    if (error && responseData?.message) {
      Swal.fire({
        icon: 'question',
        title: 'Oops...',
        text: responseData?.message,
      })
    }
  }, [responseData, error]);

  const loadSecretKeyAndQRCode = () => {
    loginAPI("/v1/user/get-two-fa-secretkey-and-qrcode", "PATCH", { 'userId': userInfo._id });
  }

  function logOut() {
    loginAPI("/v1/user/signout", "POST", {});
    signOut(userDispatch, props.history);
  }

  return (<Grid container className={classes.container}>
    <div className={classes.formContainer}>
      <React.Fragment>
        <Typography variant="h1" align={"center"}><img src={logo} alt={"GainCity"} /></Typography>
        <form onSubmit={processContinue} className={"login setup"}>
          <section>
            <Typography variant={"h2"}>Option 1: Scan with Your Mobile Device</Typography>
            <p>Download the Google/Microsoft Authenticator Apps and scan the below image with your Authenticator Apps.</p>
            <img src={qrcodeData} onClick={() => { navigator.clipboard.writeText(secretKey) }} />
          </section>

          <section>
            <Typography variant={"h2"}>Option 2: Setup on Same Device</Typography>
            <p>Download the Google/Microsoft Authenticator Apps and enter the below code on your Authenticator Apps.</p>
            <Button type={"button"} onClick={() => { navigator.clipboard.writeText(secretKey) }}>{secretKey} <CopyIcon /></Button>
          </section>

          <div className="action">
            <Button
              onClick={processContinue}
              hidden={true}
              onSubmit={processContinue}
              className={"primary"}
              type={"submit"}
            >
              Continue
            </Button>
            <Link onClick={logOut} to="#">Back</Link>
          </div>
        </form>
      </React.Fragment>
    </div>
  </Grid>);
};

export default withRouter(TwoFASetup);
