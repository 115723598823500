import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { Edit, DeleteOutline } from "@mui/icons-material";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";

export const MaterialItemColumnConfig = (permission, handleEditItem, handleDeleteItem) => [
    {
        name: "Code",
        width: "90px",
        selector: row => row.code,
        cell: (row, index, column, id) => {
            if (permission.allowedViewDetail) {
                return (<Link to={"/app/material/card/" + row._id}>{row.code}</Link>)
            } else {
                return (<>{row.code}</>)
            }
        },
        sortable: true,
    },
    {
        name: "Description",
        minWidth: "450px",
        wrap: true,
        cell: (row, index, column, id) => {
            return row.description + ' ' + row.model
        },
        selector: row => row.description,
        sortable: true,
    },
    {
        name: "Category",
        width: "200px",
        wrap: true,
        selector: row => row.category,
        sortable: true,
    },
    {
        name: "Withdrawal Group",
        width: "450px",
        wrap: true,
        selector: row => row?.withdrawalGroups?.map((item) => item.name).sort().toString(),
    },
    {
        name: "UOM",
        width: '70px',
        selector: row => row.unit,
        sortable: true,
    },
    {
        name: "Unit Price",
        width: '100px',
        right: true,
        selector: row => row.price,
        cell: (row, index, column, id) => {
            return convertThisToCurrency(row.price.toFixed(2))
        },
        sortable: true,
    },
    {
        name: "Enable",
        width: '80px',
        center: true,
        selector: row => row.enable,
        cell: (row, index, column, id) => {
            return (<Switch
                checked={row.enable}
                disabled={!permission.allowedEnableDisable}
                onChange={(e, value) => handleEditItem(row, true, value)}
                inputProps={{ 'aria-label': 'controlled' }}
            />)
        },
        sortable: true,
    },
    {
        name: "",
        width: '50px',
        right: true,
        cell: (row) => {
            return (<>
                {/* {permission.buyerAdminOnly && <IconButton onClick={(e) => handleEditItem(row)}><Edit /></IconButton>} */}
                {permission.allowedDelete && <IconButton onClick={(e) => handleDeleteItem(row)}><DeleteOutline /></IconButton>}
            </>)
        },
    }
];
