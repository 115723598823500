import React from "react";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";

const MobileTaskTrackingFilters = ({ staffFilter, setStaffFilter, staffList }) => {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"staff-filter"}
                    options={staffList}
                    getOptionLabel={(option) =>
                        `${option?.username}-${option?.name}`}
                    value={staffFilter}
                    onChange={(e, newValue) => setStaffFilter(newValue)}
                    renderInput={(params) => <TextField {...params}
                        label={"Staff"} variant={"outlined"} />}
                    popupIcon={<ArrowDownIcon />}
                />
            </Grid>
        </Grid>
    </>);
};

export default MobileTaskTrackingFilters;