import React from "react";
import { Button } from "@mui/material";
import { withRouter } from "react-router-dom";
import { PrivilegeModules,PrivilegeActions } from "../../../../data/privileges.enum";

const ButtonControlAdmin = ({ auth, selectedItemsArr, setSuccessMessage, orderCode, formSubmitCaller, currentOrder, validationWrapper }) => {
  const handleSubmitForm = async (data) => {
    const orderData = {
      ...data,
      staffId: data?.staff?.username,
      staffName: data?.staff?.name,
      staffDepartment: data?.staff?.department,
      remark: data.remark.trim().toUpperCase(),
      items: selectedItemsArr.map(item => { return { itemId: item._id, itemQty: item.requestedQty } }),
    };
    await formSubmitCaller(
      '/v1/material-order/fulfill/' + orderCode,
      "POST",
      orderData
    ).then(result => {
      if (!result?.data?.error) {
        setSuccessMessage(`Material request ${result.data.order.incrementId} has been fulfilled.`);
      }
    })
  }

  const isAbleToShowButton = () => {
    if (currentOrder?.status !== "APPROVED") {
      return false
    }

    return (auth.checkModulePrivilege(PrivilegeModules.material_request, PrivilegeActions.fulfil));
  }

  return (
    <>
      {isAbleToShowButton() && <Button className={"primary"} type={"button"} onClick={validationWrapper(handleSubmitForm)}>Fulfill</Button>}
    </>
  );
}

export default withRouter(ButtonControlAdmin);
