import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SignatureCanvas from "react-signature-canvas";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { PR_BUTTONS, rejectButton } from "../../helper/action-buttons";
import { PrivilegeModules } from "../../../../data/privileges.enum";
import {
  PR_ACTIONS,
  PR_DOCUMENT_TYPES,
  PR_STEP_RESULTS,
} from "../../helper/pr-config";
import {
  useHttpRequest,
  useRoleAuthorization,
  useSignature,
} from "../../../../hooks";
import ProjectRequestRejectModal from "../Modals/ProjectRequestRejectModal";
import Swal from "sweetalert2";
import {
  splitTest,
  toDataURL,
  convertThisToCurrency,
  FormatDate,
} from "../../../../helper";
import moment from "moment";
import {
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../../../context/LayoutContext";

export default function ProjectRequestDrawer(props) {
  const {
    getFileFromSign,
    isSignChange,
    isSignEmpty,
    onChangeSign,
    sigCanvas,
    setSignChange,
    setSignEmpty,
    onClear,
  } = useSignature();
  const { projectRequestDetail, setRefresh } = props;
  const { auth } = useRoleAuthorization();
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [message, setMessage] = useState("");
  const layoutDispatch = useLayoutDispatch();
  const layoutState = useLayoutState();
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  const rejectModalProps = {
    isLoading,
    open: rejectModalOpen,
    setOpen: setRejectModalOpen,
    rejectReason,
    setRejectReason,
    onSubmitReject,
  };

  const hasPrivilege = (privilege) =>
    auth.checkModulePrivilege(PrivilegeModules.project_request, privilege);

  const actionBtnProps = {
    isLoading,
    onReject,
    hasPrivilege,
    projectRequest: projectRequestDetail,
    step: projectRequestDetail.step,
  };

  // Alert success and reload when successfully
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: onRefreshWhenSuccess,
      });
      setMessage("");
    }
  }, [message, responseData]);

  function onRefreshWhenSuccess() {
    setRefresh(true);
    toggleRightbar(layoutDispatch);
  }
  // Alert warning when failed
  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // Upload signature into e-sign box when existed
  useEffect(() => {
    if (projectRequestDetail.signature) {
      async function fetchSignatureFromPR() {
        toDataURL(projectRequestDetail.signature.url, function (dataUrl) {
          if (dataUrl) {
            sigCanvas.current.fromDataURL(dataUrl);
            setSignChange(false);
            setSignEmpty(false);
          }
        });
      }
      fetchSignatureFromPR();
    }
    return () => {
      onClear();
    };
  }, [projectRequestDetail, layoutState]);

  async function onSubmit(data, e, action) {
    const projectRequest = { ...data, history: undefined };
    if (isSignChange) {
      const formData = new FormData();
      formData.append("referenceNo", data.referenceNo);
      formData.append("type", PR_DOCUMENT_TYPES.SIGNATURE);
      // Delete
      if (projectRequestDetail.signature) {
        formData.append(
          "filesToBeDeleted",
          JSON.stringify([splitTest(projectRequestDetail.signature.url)]),
        );
      }

      if (isSignEmpty) {
        if (projectRequestDetail.signature) {
          await sendRequest(
            `/v1/project-request/upload-project-docs`,
            "POST",
            formData,
          );
        }
        projectRequest.signature = null;
      } else {
        const file = getFileFromSign();
        formData.append("file", file, "signature.png");
        const uploadedResponse = await sendRequest(
          `/v1/project-request/upload-project-docs`,
          "POST",
          formData,
        );

        const uploadedFile = uploadedResponse.data.files[0];
        projectRequest.signature = {
          fileName: uploadedFile.fileName,
          url: uploadedFile.url,
          uploadedDate: moment().toDate(),
        };
      }
    }

    const payload = {
      action,
      request: { ...projectRequest },
    };

    proceedToNextStep(payload);
  }

  async function onSubmitReject(e) {
    e.preventDefault();
    const projectRequest = projectRequestDetail;
    const payload = {
      action: PR_ACTIONS.REJECT,
      request: { ...projectRequest, reason: rejectReason, history: undefined },
    };
    proceedToNextStep(payload);
  }

  function onReject(e) {
    e.preventDefault();
    setRejectReason("");
    setRejectModalOpen(true);
  }

  async function proceedToNextStep(payload) {
    const response = await nextStep(payload);
    handleStepResponse(response);
  }

  async function handleStepResponse(res) {
    if (res?.status === 200 && res?.data?.request) {
      const savedRequest = res?.data?.request;
      let message = "";
      switch (res?.data?.stepResult) {
        case PR_STEP_RESULTS.UPDATED:
          message = `Project request ${savedRequest.referenceNo} has been updated.`;
          break;
        case PR_STEP_RESULTS.APPROVED:
          message = `Project request ${savedRequest.referenceNo} has been approved.`;
          break;
        case PR_STEP_RESULTS.REJECTED:
          message = `Project request ${savedRequest.referenceNo} has been rejected.`;
          setRejectModalOpen(false);
          break;
        default:
          break;
      }
      message && setMessage(message);
    }
  }

  function nextStep(payload) {
    return sendRequest(`/v1/project-request/next-step`, "POST", payload);
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} size={150} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }} className="display">
        {/*Button Action */}
        <Grid item xs={12} className={"action"}>
          <Button
            id={PR_BUTTONS.APPROVE}
            className={"primary"}
            type={"button"}
            onClick={(e) => onSubmit(projectRequestDetail, e, PR_ACTIONS.SAVE)}
          >
            Save
          </Button>
          {rejectButton(actionBtnProps)}
          <Button
            id={PR_BUTTONS.APPROVE}
            className={"primary"}
            type={"button"}
            disabled={isSignEmpty}
            onClick={(e) =>
              onSubmit(projectRequestDetail, e, PR_ACTIONS.APPROVE)
            }
          >
            Approve
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {/* History table*/}
            <Grid item xs={12}>
              <Typography variant={"h2"}>HOD Acknowledgement</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Approved By</TableCell>
                    <TableCell align="left">Approved Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectRequestDetail.history.map((row, i) => (
                    <TableRow key={`${row.no}-${i}`}>
                      <TableCell align="left" width="80%">
                        {row.actionBy.name}
                        {row.actionBy.title && ` (${row.actionBy.title})`}
                      </TableCell>
                      <TableCell align="left" width="20%">
                        {FormatDate(row.actionDate)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            {/* e-Signature Box */}
            <Grid item xs={12} md={6}>
              <div className="sign-root">
                <label className="sign-label">Sign Here</label>
                <IconButton
                  aria-label="delete"
                  className="sign-action-delete"
                  onClick={() => onClear()}
                >
                  <ClearIcon />
                </IconButton>
                <SignatureCanvas
                  canvasProps={{ className: "sign-canvas" }}
                  clearOnResize={false}
                  onEnd={() => onChangeSign()}
                  ref={sigCanvas}
                />
                <label className="sign-title">
                  Executive Chairman / Managing Director
                </label>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {/* PR INFO */}
            <Grid xs={12} item>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Typography variant={"h2"}>
                    {projectRequestDetail.referenceNo}
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={`Customer`}
                        secondary={projectRequestDetail.customer}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Project Address`}
                        secondary={projectRequestDetail.location}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} lg={2}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={`Estimated Contract Start`}
                        secondary={FormatDate(
                          projectRequestDetail.estimatedContractStart,
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Estimated Contract Completion`}
                        secondary={FormatDate(
                          projectRequestDetail.estimatedContractCompletion,
                        )}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} lg={2}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={`Validity`}
                        secondary={projectRequestDetail.validity}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Credit Term`}
                        secondary={projectRequestDetail.creditTerm}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} lg={2}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={`Contract Limit`}
                        secondary={convertThisToCurrency(
                          projectRequestDetail.creditLimit.toFixed(2),
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Deposit`}
                        secondary={convertThisToCurrency(
                          projectRequestDetail.deposit.toFixed(2),
                        )}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} lg={2}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={`Contract Value`}
                        primaryTypographyProps={{
                          color: "red!important",
                        }}
                        secondaryTypographyProps={{
                          color: "red!important",
                        }}
                        secondary={convertThisToCurrency(
                          projectRequestDetail.contractValue?.toFixed(2),
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Total Cost`}
                        secondary={convertThisToCurrency(
                          projectRequestDetail.totalCost?.toFixed(2),
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Profit Margin`}
                        secondary={`${convertThisToCurrency(
                          projectRequestDetail.profit?.toFixed(2),
                        )} (${projectRequestDetail.profitMargin?.toFixed(2)}%)`}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>

            {/* Cost margin list table*/}
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="5%">
                      No.
                    </TableCell>
                    <TableCell align="left" width="20%">
                      Item/Description
                    </TableCell>
                    <TableCell align="left" width="20%">
                      Vendor
                    </TableCell>
                    <TableCell align="right" width="15%">
                      Cost
                    </TableCell>
                    <TableCell align="right" width="15%">
                      Price
                    </TableCell>
                    <TableCell align="left" width="25%">
                      Remark
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectRequestDetail.costMarginList
                    .filter((cml) => cml.hidden !== true)
                    .map((row, i) => (
                      <TableRow key={i}>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {i + 1}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.description}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.vendor}
                        </TableCell>
                        <TableCell align="right" style={{ borderBottom: "0" }}>
                          {convertThisToCurrency(row.cost?.toFixed(2))}
                        </TableCell>
                        <TableCell align="right" style={{ borderBottom: "0" }}>
                          {convertThisToCurrency(row.sellingPrice?.toFixed(2))}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.remarks}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ProjectRequestRejectModal {...rejectModalProps} />
    </>
  );
}
