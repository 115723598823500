import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    drawerToggleBtn: {
        marginTop: 10
    },
    toggleRightBarIcon: {
        fontSize: 28
    },
    timeSlot: {
        height: '8px !important',
        width: '8px !important'
    },
    textarea: {
        border: '1px solid rgba(0, 0, 0, .15) !important',
        borderRadius: 0
    },
    pulse: {
        boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
        animation: "$pulse 2s 1",
        background: '#bfbfbf17 !important'
    },
    "@keyframes pulse": {
        "0%": {
            boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)"
        },
        "70%": {
            boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)"
        },
        "100%": {
            boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)"
        }
    }
}));
