import * as moment from "moment";
import { TIME_SLOT_STATUS } from "../../../data/constants";

export const allocated = (slots, currentSlot) =>
    slots.findIndex(s => s.timeSlot === currentSlot.value) > -1;

export const currentSlotInfo = (slot, date, cutoffDate, timeline) => {
    const slotAllocated = allocated(timeline, slot);
    const cutoff = moment(cutoffDate);
    const cutoffExceeded = !slotAllocated &&
        (moment(date).toDate() <= cutoff.clone().startOf('day').toDate())
        && (cutoff.clone().toDate() <= moment().toDate());
    return cutoffExceeded ? { status: TIME_SLOT_STATUS.Closed, fill: 'red' } :
        slotAllocated ? { status: TIME_SLOT_STATUS.Allocated, fill: '#00dd00' } :
            { status: TIME_SLOT_STATUS.Available, fill: '#bfbfbf' };
};

export const getTimeline = (start, end) => {
    const timeline = [];
    for (let i = start; i <= end; i++) {
        const time = moment().set('h', i).set('m', 0).set('s', 0);
        timeline.push({
            label: time.format('ha'),
            value: time.format('HH:mm')
        });
    }
    return timeline;
};

