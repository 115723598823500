import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  PrivilegeActions,
  PrivilegeModules,
} from "../../../../data/privileges.enum";
import { useRoleAuthorization } from "../../../../hooks";
import { useVoucherSetupSave } from "../../api/useVoucherSetupSave";
import DateFilter from "../../../fnb-order/component/filters/DateFilter";
import moment from "moment";

export default function GenerateVoucher({ voucherData, onSuccess, onError }) {
  const { auth } = useRoleAuthorization();
  const [open, setOpen] = useState(false);
  const { isCodeGenerating, generateVoucherCodes } = useVoucherSetupSave();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors: formErrors },
  } = useForm({ mode: "all", defaultValues: { qty: 1, expiration: null } });

  const handleClick = () => {
    setOpen(true);
  };

  const generateCodesAction = useCallback(
    ({ qty, expiration }) => {
      return generateVoucherCodes(voucherData.incrementId, +qty, expiration)
        .then((res) => {
          closeDialog();
          return res;
        })
        .then(onSuccess)
        .catch(onError);
    },
    [generateVoucherCodes, onError, onSuccess, voucherData.incrementId],
  );

  const isAbleToShow = () => {
    return (
      auth.isPrivilegeDataLoaded() &&
      auth.checkModulePrivilege(
        PrivilegeModules.fnb_voucher_setup,
        PrivilegeActions.add,
      ) &&
      voucherData &&
      voucherData.incrementId
    );
  };

  const expirationDate = watch("expiration", null);

  function closeDialog() {
    setOpen(false);
    reset();
  }

  return (
    <>
      {isAbleToShow() && (
        <Button
          className={"primary"}
          disabled={isCodeGenerating}
          onClick={handleClick}
        >
          Generate
        </Button>
      )}

      <Dialog
        fullWidth={false}
        open={open}
        maxWidth={false}
        onClose={closeDialog}
      >
        <form onSubmit={handleSubmit(generateCodesAction)}>
          <DialogTitle title={"Voucher Codes"} variant="h2">
            Generate Voucher Code
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={6}>
                <TextField
                  id={"qty"}
                  label={"Quantity"}
                  type={"number"}
                  variant={"outlined"}
                  {...register("qty", {
                    required: {
                      value: false,
                    },
                    min: 1,
                    max: 1000,
                  })}
                  error={!!formErrors?.qty}
                  helperText={formErrors?.qty?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <DateFilter
                  onChange={(newValue) => {
                    if (newValue) {
                      const endOfDay = moment(newValue).endOf("day");
                      newValue = endOfDay.toDate();
                    }
                    setValue("expiration", newValue);
                  }}
                  minDate={new Date()}
                  label={"Expiry Date"}
                  value={expirationDate}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button className={"btn-confirm"} type="submit">
              Proceed
            </Button>
            <Button className={"btn-cancel"} onClick={closeDialog}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
