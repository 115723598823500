import { Switch } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

const carWashRequestTableColumnConfig = (permission) => [
  {
    name: "Request No.",
    selector: (row) => row.carWashRequestNo,
    cell: (row, index, column, id) => {
      if (permission.allowedViewDetail) {
        return (
          <Link to={"/app/car-wash-request/card/" + row._id}>
            {row.carWashRequestNo}
          </Link>
        );
      } else {
        return <>{row.carWashRequestNo}</>;
      }
    },
    sortable: true,
    sortField: "carWashRequestNo",
  },
  {
    name: "Request Date",
    width: "150px",
    wrap: true,
    selector: (row) => moment(row.requestedAt).format("DD/MM/YYYY"),
    sortable: true,
    sortField: "requestedAt",
  },
  {
    name: "Last Day",
    width: "150px",
    wrap: true,
    selector: (row) => row.lastAt && moment(row.lastAt).format("DD/MM/YYYY"),
    sortable: true,
    sortField: "lastAt",
  },
  {
    name: "Car Plate No.",
    width: "250px",
    wrap: true,
    selector: (row) => row.carNo,
    sortable: true,
    sortField: "carNo",
  },
  {
    name: "Team",
    width: "250px",
    wrap: true,
    selector: (row) => row.team,
    sortable: true,
    sortField: "team",
  },
  {
    name: "Status",
    width: "250px",
    wrap: true,
    selector: (row) => row.status,
    sortable: true,
    sortField: "status",
  },
  {
    name: "Washed By",
    width: "250px",
    wrap: true,
    selector: (row) => row?.washedBy,
    sortable: true,
    sortField: "washedBy",
  },
  {
    name: "Washed Date",
    width: "250px",
    wrap: true,
    selector: (row) =>
      row.washedAt && moment(row.washedAt).format("DD/MM/YYYY"),
    sortable: true,
    sortField: "washedAt",
  },
  {
    name: "Checked By",
    width: "250px",
    wrap: true,
    selector: (row) => row?.checkedBy,
    sortable: true,
    sortField: "checkedBy",
  },
  {
    name: "Checked Date",
    width: "250px",
    wrap: true,
    selector: (row) =>
      row.checkedAt && moment(row.washedAt).format("DD/MM/YYYY"),
    sortable: true,
    sortField: "checkedAt",
  },
];

export default carWashRequestTableColumnConfig;
