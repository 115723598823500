import React from "react";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { PrivilegeModules,PrivilegeActions } from "../../../../data/privileges.enum";

const ButtonPushToNavision = ({
    auth,
    orderCode,
    formSubmitCaller,
    currentOrder,
    validationWrapper }) => {

    const handleSubmitForm = async () => {
        await formSubmitCaller(
            '/v1/material-order/sync-to-navision/' + orderCode,
            "PUT").then(result => {
                if (result?.data?.order) {
                    Swal.fire({
                        icon: 'success',
                        title: "Success",
                        text: `Material request ${result.data.order.incrementId} has been pushed to Navision.`,
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Error",
                        text: result?.data?.message
                    });
                }
            });
    };

    const isAbleToShowButton = () => {
        return (auth.checkModulePrivilege(PrivilegeModules.material_request, PrivilegeActions.push_to_nav) && currentOrder?.status === "CLOSED" && !currentOrder?.isSynced);
    }

    return (
        <>
            {isAbleToShowButton() &&
                <Button
                    className={"primary"}
                    type={"button"}
                    onClick={validationWrapper(handleSubmitForm)}>
                    Manual Push</Button>}
        </>
    );
}

export default ButtonPushToNavision;
