import { useState, useEffect, useMemo } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Backdrop, Button, CircularProgress, FormControlLabel,
  Grid, Switch, TextField, Typography,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useRoleAuthorization } from '../../hooks/useRoleAuthorization';
import { useHttpRequest } from '../../hooks/useHttpRequest';
import useStyles from './styles';
import { validateText } from '../../helper/validate-textfield';
import { PrivilegeActions, PrivilegeModules } from '../../data/privileges.enum';
import { KeyboardArrowDown as ArrowDownIcon } from '@mui/icons-material';
import { CompanyDropdown } from './components/CompanyDropdown';

const ProjectCard = (props) => {
  const classes = useStyles();

  // handle loading & http
  const { isLoading, httpRequestError: error, responseData, sendRequest } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  // get project id from url when editing
  const { projectId } = useParams();

  const [message, setMessage] = useState('');
  const [currentProject, setCurrentProject] = useState({});
  const [enableSwitchValue, setEnableSwitchValue] = useState(true);
  const [projectRequest, setProjectRequest] = useState(null);
  const [projectRequestList, setProjectRequestList] = useState([]);
  const [isReferenceRequired, setIsReferenceRequired] = useState(true);
  const [projectAddress, setProjectAddress] = useState('');

  useEffect(() => {
    document.title = 'New - Project Card';
    // redirect to default page if not authorized
    if (projectId && auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.project, PrivilegeActions.view_detail)) {
      props.history.push('/app/dashboard');
    }
    loadProjectRequests();
  }, []);

  // get current project when editing
  useEffect(() => {
    if (projectId) {
      sendRequest(`/v1/projects/get-by-project-id/` + projectId, 'GET').then(response => {
        const project = response.data.project;
        if (!project) {
          throw new Error('There is no such project.')
        }
        document.title = `${project.projectId} - Project Card`;
        setCurrentProject(project);
        setProjectAddress(project.projectRequest?.location);
        setIsReferenceRequired(false);
        setEnableSwitchValue(project.enabled === 'Yes');
        setProjectRequest({
          referenceNo: project.referenceNo || '',
          customer: project.projectRequest?.customer,
        });
      })
    }
  }, [projectId]);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: message,
        willClose: navigateToList,
      });
    }
  }, [message, responseData]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // handling form when adding / editing
  const formMethods = useForm({
    mode: 'all', defaultValues: currentProject,
    values: currentProject
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    setValue,
  } = formMethods;

  // when submit the project form
  const handleSubmitForm = async (data) => {
    const project = {
      projectId: data.projectId.toUpperCase(),
      projectName: data.projectName.toUpperCase(),
      projectGroup: data.projectGroup.toUpperCase(),
      // company: data.company,
      enabled: data.enabled ? 'Yes' : 'No',
    };
    let result = null;
    if (projectId && currentProject && currentProject._id) {
      project._id = currentProject._id;
      result = await sendRequest(`/v1/projects/${currentProject._id}`, 'PUT', project);
      if (result?.status === 200 && result?.data?.project) {
        setMessage(`Project ${project.projectId} has been updated.`); // call show feedback message
      }
    } else {
      project.referenceNo = projectRequest.referenceNo;
      project.company = projectRequest.company;
      result = await sendRequest('/v1/projects', 'POST', project);
      if (result?.status === 201 && result?.data?.project) {
        setMessage(`New project ${project.projectId} has been created.`); // call show feedback message
      }
    }
  };

  // navigate back to project list
  function navigateToList() {
    if (!projectId)
      window.location.href = '#/app/project/list';
  }

  async function loadProjectRequests() {
    const response = await sendRequest(`/v1/project-request/list/unassigned`, 'GET');
    setProjectRequestList(response?.data?.requestList || []);
  }

  return (<>
    {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
      <CircularProgress color={'inherit'} />
    </Backdrop>}
    {(!projectId || (projectId && currentProject)) &&
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <FormProvider {...formMethods}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={'h1'}>Project Card {currentProject?.projectId ? ` - ${currentProject.projectId}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={'action'}>
              <Button onClick={() => props.history.push('/app/project/list')}>Cancel</Button>

              {
                ((!projectId && auth.checkModulePrivilege(PrivilegeModules.project, PrivilegeActions.add)) || (projectId && auth.checkModulePrivilege(PrivilegeModules.project, PrivilegeActions.edit)))
                &&
                <Button className={'primary'} type={'submit'}>Save</Button>
              }
            </Grid>
            <Grid item xs={12}>
              <Grid container className={'form'}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={'h2'}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={'project-id'}
                            label={'Project ID'}
                            variant={'outlined'}
                            {...register('projectId', {
                              required: { value: true, message: 'Project ID cannot be blank.' },
                            })}
                            autoComplete={'off'}
                            error={!!formErrors?.projectId}
                            helperText={formErrors?.projectId?.message}
                            disabled={!!projectId && !!currentProject}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={'project-name'}
                            label={'Project Name'}
                            variant={'outlined'}
                            {...register('projectName', {
                              required: { value: true, message: 'Project Name cannot be blank.' },
                            })}
                            autoComplete={'off'}
                            error={!!formErrors?.projectName}
                            helperText={formErrors?.projectName?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={'project-group'}
                            label={'Project Group'}
                            variant={'outlined'}
                            {...register('projectGroup', {
                              required: { value: true, message: 'Project Group cannot be blank.' },
                            })}
                            autoComplete={'off'}
                            error={!!formErrors?.projectGroup}
                            helperText={formErrors?.projectGroup?.message}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            className={classes.moreDataSwitch}
                            labelPlacement='start'
                            control={<Switch
                              {...register('enabled')}
                              checked={enableSwitchValue}
                              onChange={(e) => setEnableSwitchValue(e.target.checked)} />}
                            label='Enable' />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Controller
                            name={`referenceNo`}
                            control={control}
                            rules={{
                              required: {
                                value: isReferenceRequired,
                                message: 'Project Reference No. cannot be blank.',
                              },
                            }}
                            render={(props) => (
                              <Autocomplete
                                id={'project-reference-no'}
                                options={projectRequestList}
                                getOptionLabel={(option) => `${option?.referenceNo}${option?.customer ? '-' + option?.customer : ''}`}
                                value={projectRequest}
                                disabled={!!projectId && !!currentProject}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    label={'Project Reference No.'}
                                    variant={'outlined'}
                                    error={!!formErrors?.referenceNo}
                                    helperText={formErrors?.referenceNo?.message}
                                    autoComplete='off' />}
                                onChange={(event, data) => {
                                  setProjectRequest(data);
                                  props?.field.onChange(data);
                                  setProjectAddress(data.location);
                                }}
                                popupIcon={<ArrowDownIcon />} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={'project-address'}
                            label={'Project Address'}
                            variant={'outlined'}
                            disabled={true}
                            value={projectAddress}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <CompanyDropdown disabled={!!projectId && !!currentProject} />
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </form>}
  </>);
};

export default withRouter(ProjectCard);