import { IconButton, Switch } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment";

export const fnbStoreEventTableColumnConfig = ({allowedViewDetail, allowedDelete, allowedEnableDisable, allowedEdit}, handleNameClick, handleDeleteFnbStoreEvent, handleEditFnbStoreEvent) => {
    let columns = [
        {
            name: "Store",
            width: "150px",
            selector: (row) => row.store.name,
            sortable: true,
            wrap: true,
            sortField: "store.name",
        },        
        {
            name: "Event",
            minWidth: "350px",
            selector: row => row.name,
            cell: (row) => {
                if (allowedViewDetail) {
                    return (<Link to={`/app/fnb-store-event/card/${row.entityId}`} onClick={(e) => handleNameClick(row.entityId)}>{row.name}</Link>)
                } else {
                    return (<>{row.name}</>)
                }
            },            
            sortable: true,
            wrap: true,
            sortField: 'name'
        },
        {
            name: "Active From",
            width: "170px",
            wrap: true,
            selector: row => row.activeFrom,
            cell: (row, index, column, id) => {
                if(!!row.activeFrom){
                    return moment(row.activeFrom).format('DD/MM/YYYY HH:mm:ss')
                }
            },
            sortable: true,
            wrap: true,
            sortField: 'activeFrom'
        },
        {
            name: "Active To",
            width: "170px",
            wrap: true,
            selector: row => row.activeTo,
            cell: (row, index, column, id) => {
                if(!!row.activeTo){
                    return moment(row.activeTo).format('DD/MM/YYYY HH:mm:ss')
                }
            },
            sortable: true,
            wrap: true,
            sortField: 'activeTo'
        },
        {
            name: "Enable",
            width: '80px',
            center: true,
            selector: row => row.status,
            cell: (row) => {
                return (<Switch
                    checked={row.status}
                    onChange={(e, value) => handleEditFnbStoreEvent(row, 'status', true, value)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled= {!allowedEdit}
                />)
            },
            sortable: true,
            sortField:'status'
        }
    ];    


    if (allowedDelete) {
        columns = [...columns,
            {
                name: "",
                right: true,
                width: '50px',
                cell: (row) => {
                    return (<>
                        {allowedDelete && (<>
                            <IconButton onClick={(e) => handleDeleteFnbStoreEvent(row)}><DeleteOutline /></IconButton></>
                        )}
                    </>)
                }
            }
        ]
    }

    return columns;
}