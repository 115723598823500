import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, InputAdornment, Autocomplete, IconButton
} from "@mui/material";
import {
    Search as SearchIcon, KeyboardArrowDown as ArrowDownIcon,
    PlaylistPlay as FilterIcon, ArrowBack as BackIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { ENABLE_OPTIONS } from "../../data/constants";
import { useLayoutDispatch, useLayoutState, toggleRightbar, setRighbarContent } from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import MobileInstallationClaimMatrixFilters from "./components/Mobile/MobileInstallationClaimMatrixFilters";
import { installationClaimMatrixTableColumnConfig } from "./data-table/installation-claim-matrix-table-column-config";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const InstallationClaimMatrixList = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    // layout state
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    // filters
    const [textFilter, setTextFilter] = useState("");
    const [enableFilter, setEnableFilter] = useState(null);
    const [sortBy, setSortBy] = useState({ column: undefined, direction: undefined });
    const [refresh, setRefresh] = useState(false); // refresh list

    const [matrixList, setMatrixList] = useState([]); // matrix list
    const enableOptions = ENABLE_OPTIONS;
    const smallDeviceWidth = 1200;

    // pagination states
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collectionSize, setCollectionSize] = useState(10);

    const mobileFilterProps = { enableOptions, enableFilter, setEnableFilter };

    useEffect(() => { 
        document.title = "Installation Claim Matrix List";

        // redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.manage_installation_claim_matrix, PrivilegeActions.view_list)) {
            props.history.push('/app/dashboard');
        }
    }, []);

    // filter data
    useEffect(() => { if (refresh) { filter(); setRefresh(false); } }, [refresh]);

    useEffect(() => { if (!textFilter) { setRefresh(true); } }, [textFilter]);

    useEffect(() => { setRefresh(true); }, [enableFilter]);

    useEffect(() => {
        if (width <= smallDeviceWidth) { // set filter right bar
            setRighbarContent(layoutDispatch,
                [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
                <MobileInstallationClaimMatrixFilters key={"rb-body"} {...mobileFilterProps} />],
                { size: 'xs', backButton: true }); // filter content
        }
    }, [matrixList, enableFilter, width]);

    // set columns in data table
    const columns = installationClaimMatrixTableColumnConfig(
        {allowedViewDetail:auth.checkModulePrivilege(PrivilegeModules.manage_installation_claim_matrix, PrivilegeActions.view_detail)},
        handleMatrixNameClick, 
        handleEditBatch
    );
    
    function filter() {
        // set list filters
        getPaginatedData(page, pageSize, getFilterValues(), sortBy)
            .then(response => {
                const matrixList = response.data?.matrixList || [];
                setMatrixList(matrixList);
                setCollectionSize(response.data?.total);
                return () => {
                    return matrixList;
                };
            });
    }

    function getFilterValues() {
        return {
            text: textFilter.trim(),
            enable: enableFilter
        };
    }

    function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
        return sendRequest(
            `/v1/installation-claim-matrix/filter?filters=${JSON.stringify(filters)}&page=${page}&pageSize=${pageSize}
        ${(sortBy?.column && sortBy?.direction) ?
                ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {});
    }

    function sortByColumn(columnConfig, sortDirection) {
        if (columnConfig.sortField && sortDirection) {
            setSortBy({
                column: columnConfig.sortField,
                direction: sortDirection
            });
            setRefresh(true);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value) { // perform search when Enter
            e.preventDefault();
            setRefresh(true);
        }
    };

    function handlePageChanged(page) {
        setPage(page);
        setRefresh(true);
    }

    function handleRowsPerPageChanged(count) {
        setPageSize(count);
        setRefresh(true);
    }

    // enable/disable matrixes
    async function handleEditBatch(current, toggleValue) {
        current.enable = toggleValue; // set new enable value & update
        await sendRequest(`/v1/installation-claim-matrix/${current._id}`, "PUT", current);
    }

    // go to edit view
    async function handleMatrixNameClick(matrixId) {
        if (auth.checkModulePrivilege(PrivilegeModules.manage_installation_claim_matrix, PrivilegeActions.view_detail)) {
            props.history.push(`card/${matrixId}`);
        }
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>Installation Claim Matrix List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                {(auth.checkModulePrivilege(PrivilegeModules.manage_installation_claim_matrix, PrivilegeActions.add)) && <Button className={"primary"}
                    onClick={() => props.history.push('card')}>New</Button>}
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                            <TextField
                                autoComplete={"off"}
                                id={"text-searchbox"}
                                variant={"outlined"}
                                value={textFilter}
                                onChange={(e) => setTextFilter(e.target.value)}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={"start"}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }} />
                        </Grid>
                        {/* {
                            width >= smallDeviceWidth ? (<>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                    <Autocomplete
                                        id={"enable-filter"}
                                        options={enableOptions}
                                        getOptionLabel={(option) => option?.toUpperCase()}
                                        value={enableFilter}
                                        onChange={(e, newValue) => setEnableFilter(newValue)}
                                        renderInput={(params) => <TextField {...params}
                                            label={"Enable"} variant={"outlined"} />}
                                        popupIcon={<ArrowDownIcon />}
                                    />
                                </Grid>
                            </>) :
                                (<Grid item xs={2}>
                                    <IconButton
                                        onClick={() => toggleRightbar(layoutDispatch)}
                                        className={classes.drawerToggleBtn}>
                                        {layoutState.isRightbarOpened ? (<BackIcon />)
                                            : (<FilterIcon className={classes.toggleRightBarIcon} />)}
                                    </IconButton>
                                </Grid>)
                        } */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}
                                data={matrixList}
                                sortServer
                                onSort={sortByColumn}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationPerPage={pageSize}
                                paginationTotalRows={collectionSize}
                                onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
                                onChangePage={(page) => handlePageChanged(page)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default withRouter(InstallationClaimMatrixList);