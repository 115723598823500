export const INVOICE_NO_PREFIX = "PKS-";

export const SERVICE_CLAIMS_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
  PAID: "PAID",
};

export const INSTALLATION_CLAIMS_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
  PAID: "PAID",
};

export const LIST_LIMIT_OPTIONS = {
  OLD: "old",
  RECENT: "recent",
};

export const FNB_EVENT_BOOKING_PAX = {
  2: "2 ADULTS",
  3: "3 ADULTS",
  4: "4 ADULTS",
  5: "5 ADULTS",
  6: "6 ADULTS",
  7: "7 ADULTS",
  8: "8 ADULTS",
  9: "9 ADULTS",
  10: "10 ADULTS",
  11: "11 ADULTS",
  12: "12 ADULTS",
  13: "13 ADULTS",
  14: "14 ADULTS",
};

export const PERSONAL_CALENDAR_TYPE = {
  GOOGLE: "GOOGLE",
  OUTLOOK: "OUTLOOK",
};

export const DAY_OF_THE_WEEK = {
  0: "SUNDAY",
  1: "MONDAY",
  2: "TUESDAY",
  3: "WEDNESDAY",
  4: "THURSDAY",
  5: "FRIDAY",
  6: "SATURDAY",
};

export const CHINESE_CHAR_SET_REGEX =
  /[\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF\u00A1-\u02FF\u0370-\u03FF\u0400-\u04FF\u2013-\u204A\u274C]+/g;

export const BRACKET_REQUEST_ITEM_STATUS = {
  NEW: "NEW",
  ISSUED: "ISSUED",
  TO_BE_RETURNED: "TO_BE_RETURNED",
  RETURNED: "RETURNED",
};

export const BRACKET_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const MATERIAL_REQUEST_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "CLOSED",
};

export const SERIAL_NO_PREFIXES = ["304-", "201-", "202-"];

export const ENABLE_OPTIONS = ["Yes", "No"];

export const TIME_SLOT_STATUS = {
  Closed: "closed",
  Allocated: "allocated",
  Available: "available",
};

export const SMALL_DEVICE_WIDTH = 1200;

export const SERVICE_CLAIMS_REPORT_STATUS = {
  PENDING: "PENDING",
  VOIDED: "VOIDED",
  PAID: "PAID",
};

export const INSTALLATION_CLAIMS_REPORT_STATUS = {
  PENDING: "PENDING",
  VOIDED: "VOIDED",
  PAID: "PAID",
};
