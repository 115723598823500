import Joi from 'joi';

const partialStoreSchema = Joi.object({
  storeId: Joi.number().required(),
  name: Joi.string().optional(),
  storeCode: Joi.string().optional(),
});

export const createVoucherSchema = Joi.object({
  entityId: Joi.optional(),
  incrementId: Joi.optional(),
  validityPeriod: Joi.number().positive().required(),
  voucherPrefix: Joi.string().min(0).max(10).optional(),
  voucherSuffix: Joi.string().min(0).max(10).optional(),
  voucherLength: Joi.number().positive().min(5).max(100).required(),
  amount: Joi.number().positive().max(10000).required(),
  minTotalCartAmount: Joi.number().min(0).max(10000).optional(),
  isActive: Joi.boolean().default(true).optional(),
  itemDescription: Joi.optional(),
  itemNo: Joi.string().required().max(20),
  locationCode: Joi.string().required().max(10),
  voucherPrintTemplate: Joi.string().required().not(''),
  stores: Joi.array().items(partialStoreSchema).optional(),

  promotionRule: Joi.object().optional().empty(),
  createdAt: Joi.date().optional(),
  updatedAt: Joi.date().optional(),
});