import { Settings as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { INSTALLATION_CLAIMS_STATUS } from "../../../data/constants";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";

export const claimsTableColumnConfig = ({ allowedSeeMoreColumns, allowedViewDetail, allowEdit }, 
    handleInvoiceNoClick, handleEditRow) => {
    
    const isPending = (row) => row.status === INSTALLATION_CLAIMS_STATUS.PENDING;

    let columns = [
        {
            name: "Invoice No.",
            width: "120px",
            selector: row => row.invoiceNo,
            cell: (row) => {
                if (allowedViewDetail) {
                    return (<Link to={'#'} onClick={(e) => handleInvoiceNoClick(row)}>{row.invoiceNo}</Link>)
                } else {
                    return (<>{row.invoiceNo}</>)
                }

            },
            sortable: true,
            sortField: 'invoiceNo'
        },
        {
            name: "",
            width: allowEdit ? "50px" : "0px",
            right: true,
            cell: (row) => (isPending(row) && allowEdit) &&
                <IconButton onClick={(e) => handleEditRow(row)}>
                    <EditIcon />
                </IconButton>
        },
        {
            name: "Total Claim Amount",
            width: "150px",
            right: true,
            selector: row => row.totalClaimAmount,
            cell: (row) => {
                return convertThisToCurrency(row.totalClaimAmount?.toFixed(2))
            },
            sortable: true,
            sortField: 'totalClaimAmount'
        },
        {
            name: "Team",
            width: "80px",
            selector: row => row.team,
            sortable: true,
            sortField: 'team'
        },
        {
            name: "Claim Date",
            width: "110px",
            selector: row => row.claimDate,
            cell: (row) => {
                return moment(row.claimDate).format('DD/MM/YYYY')
            },
            sortable: true,
            sortField: 'claimDate'
        },
        {
            name: "Approved Date",
            width: "170px",
            selector: row => row?.approvedDate ? row?.approvedDate : '',
            cell: (row) => {
                return row?.approvedDate ? moment(row?.approvedDate).format('DD/MM/YYYY HH:mm:ss') : ''
            },
            sortable: true,
            sortField: 'approvedDate'
        },
        {
            name: "Status",
            width: "90px",
            selector: row => row.status,
            sortable: true,
            sortField: 'status'
        },
        {
            name: "Payment Date",
            width: "110px",
            selector: row => row?.paymentDate ? row?.paymentDate : '',
            cell: (row) => {
                return row?.paymentDate ? moment(row?.paymentDate).format('DD/MM/YYYY') : ''
            },
            sortable: true,
            sortField: 'paymentDate'
        },
        {
            name: "Order Balance",
            width: "120px",
            right: true,
            selector: row => row.orderBalance !== undefined ? convertThisToCurrency(row.orderBalance?.toFixed(2)) : '-',
            sortable: true,
            sortField: 'orderBalance'
        },
        {
            name: "Customer Balance",
            width: "140px",
            right: true,
            selector: row => row.customerBalance !== undefined ? convertThisToCurrency(row.customerBalance?.toFixed(2)) : '-',
            sortable: true,
            sortField: 'customerBalance'
        }
    ];

    // only below roles can see two columns
    if (allowedSeeMoreColumns) {
        columns = [...columns, {
            name: "Posted",
            width: "80px",
            center: true,
            selector: row => row.invoicePosted.toUpperCase(),
            sortable: true,
            sortField: 'posted'
        },
        // {
        //     name: "Calculated",
        //     width: "120px",
        //     center: true,
        //     selector: row => row.calculated ? row.calculated.toUpperCase() : '',
        //     sortable: true,
        //     sortField: 'calculated'
        // },
        {
            name: "Downloaded",
            width: "110px",
            center: true,
            selector: row => row.downloaded ? row.downloaded.toUpperCase() : '',
            sortable: true,
            sortField: 'downloaded'
        },
        ];
    }
    return columns;
};
