import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { DeleteOutline as DeleteIcon } from "@mui/icons-material";
import { Settings as EditIcon } from "@mui/icons-material";

export const machineStoreTableColumnConfig = (
  { allowedViewDetail, allowedDelete, allowedEnableDisable, allowedEdit },
  handleNameClick,
  handleDeleteMachineStore,
  handleEditMachineStore,
  loadRightSideContent,
) => {
  let columns = [
    {
      name: "Store ID",
      width: "100px",
      selector: (row) => row.storeId,
      cell: (row) => {
        if (allowedViewDetail) {
          return (
            <Link
              to={"#"}
              onClick={(e) => loadRightSideContent(row, true, false)}
            >
              {row.storeId}
            </Link>
          );
        } else {
          return <>{row.storeId}</>;
        }
      },
      sortable: true,
      wrap: true,
      sortField: "storeId",
    },
    {
      name: "",
      width: allowedEdit ? "50px" : "0px",
      right: true,
      cell: (row) =>
        allowedViewDetail &&
        allowedEdit && (
          <IconButton onClick={(e) => handleNameClick(row.storeId)}>
            <EditIcon />
          </IconButton>
        ),
    },
    {
      name: "Company",
      width: "200px",
      selector: (row) => row.company.name,
      sortable: true,
      wrap: true,
      sortField: "company",
    },
    {
      name: "Area",
      width: "70px",
      selector: (row) => row.area,
      wrap: true,
      sortable: true,
      sortField: "area",
    },
    {
      name: "Location",
      minWidth: "350px",
      selector: (row) => row.location,
      wrap: true,
      sortable: true,
      sortField: "location",
    },
  ];

  columns = [
    ...columns,
    {
      name: "Enable",
      width: "80px",
      center: true,
      selector: (row) => row.enable,
      cell: (row) => {
        return (
          <>
            {!row.approvedBy && (
              <Switch
                disabled={!allowedEnableDisable}
                checked={row.enable}
                onChange={(e, value) =>
                  handleEditMachineStore(row, true, value)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            )}
          </>
        );
      },
      sortable: true,
      sortField: "enable",
    },
  ];

  return columns;
};
