import { withRouter } from "react-router-dom";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack, PlaylistPlay } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useStyles from "./styles";
import { bookingTableColumnConfig } from "./data-table/booking-table-column-config";
import BookingFilterMobile from "./components/BookingFilterMobile";
import { Search as SearchIcon, KeyboardArrowDown } from "@mui/icons-material";
import moment from "moment";
import Swal from "sweetalert2";
import downloadCSV from "../../helper/download-csv";
import DateFilter from "../../components/DateFilter";
import * as Papa from "papaparse";

const BookingList = (props) => {
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  const theme = useTheme();
  const matchMediaQuery = useMediaQuery(theme.breakpoints.down("md"));
  const [bookingList, setBookingList] = useState([]);
  const layoutDispatch = useLayoutDispatch();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);
  const [textFilter, setTextFilter] = useState("");
  const [utmSourceFilter, setUtmSourceFilter] = useState("");
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [keyCollectionPeriodList, setKeyCollectionPeriodList] = useState([]);
  const [paxNumberList, setPaxNumberList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [refresh, setRefresh] = useState(false); // refresh list
  const [stores, setStores] = useState([]);
  const boolOptions = ["Yes", "No"];
  const [inputfilter, setInputFilter] = useState();
  const [pdpaFilter, setPdpaFilter] = useState();
  const [waitingListFilter, setWaitingListFilter] = useState();
  const [propertyTypeFilter, setPropertyTypeFilter] = useState(null);
  const [keyCollectionPeriodFilter, setKeyCollectionPeriodFilter] =
    useState(null);
  const [paxNumberFilter, setPaxNumberFilter] = useState(null);
  const [productTypeFilter, setProductTypeFilter] = useState(null);
  const [storeFilter, setStoreFilter] = useState(null);
  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const [groupBuyEvents, setGroupBuyEvents] = useState([]);

  const columns = bookingTableColumnConfig(
    {
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.group_buy_calendar,
        PrivilegeActions.view_detail,
      ),
      allowEdit: auth.checkModulePrivilege(
        PrivilegeModules.group_buy_calendar,
        PrivilegeActions.edit,
      ),
    },
    propertyTypeList,
    keyCollectionPeriodList,
    productTypeList,
    paxNumberList,
    groupBuyEvents,
  );

  const filter = () => {
    const filters = {
      propertyTypeId: propertyTypeFilter?.id,
      keyCollectionPeriodId: keyCollectionPeriodFilter?.id,
      productTypeId: productTypeFilter?.id,
      paxNumberId: paxNumberFilter?.id,
      storeId: storeFilter?._id,
      text: textFilter.trim(),
      utmSource: utmSourceFilter.trim(),
      eventFilter: inputfilter,
      pdpa: pdpaFilter,
      isWaitingList: waitingListFilter,
    };

    sendRequest(
      `/v1/group-buy-booking/filter?filters=${JSON.stringify(filters)}
      &page=${page}&pageSize=${pageSize}
      ${sortBy?.column && sortBy?.direction
        ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
        : ""
      }`,
      "GET",
      {},
    ).then((response) => {
      const bookings = response.data?.bookings || [];
      setBookingList(bookings);
      setCollectionSize(response.data?.total);
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  const downloadReport = async () => {
    const csvData = [];

    const filters = {
      propertyTypeId: propertyTypeFilter?.id,
      keyCollectionPeriodId: keyCollectionPeriodFilter?.id,
      productTypeId: productTypeFilter?.id,
      paxNumberId: paxNumberFilter?.id,
      storeId: storeFilter?._id,
      text: textFilter.trim(),
      utmSource: utmSourceFilter.trim(),
      eventFilter: inputfilter,
      pdpa: pdpaFilter,
      isWaitingList: waitingListFilter,
    };

    const propertyTypeListMap = new Map(
      propertyTypeList.map((item) => [item.id, item.option]),
    );
    const keyCollectionPeriodListMap = new Map(
      keyCollectionPeriodList.map((item) => [item.id, item.option]),
    );
    const paxNumberListMap = new Map(
      paxNumberList.map((item) => [item.id, item.option]),
    );
    const productTypeListMap = new Map(
      productTypeList.map((item) => [item.id, item.option]),
    );

    try {
      const { data } = await sendRequest(
        `/v1/group-buy-booking/filter?filters=${JSON.stringify(
          filters,
        )}&page=${page}&pageSize=0${sortBy?.column && sortBy?.direction
          ? `&orderBy=${sortBy.column}&direction=${sortBy.direction}`
          : ""
        }`,
        "GET",
        {},
      );

      // Transform booking data for CSV
      for (const booking of data.bookings) {
        const transformedData = {
          // Core booking data
          "Booking No.": booking.bookingNo,
          "Preferred Outlet": booking.calendar?.store?.name?.toUpperCase() || "-",
          "Booking Date": moment(booking.createdAt).format(
            "DD/MM/YYYY HH:mm:ss",
          ),
          "Customer Name": booking.customerName?.toUpperCase(),
          Email: booking.customerEmail?.toLowerCase(),
          "Mobile No.": booking.customerMobile?.toString() || "-",
          "Property Type":
            propertyTypeListMap.get(booking.propertyTypeId)?.toUpperCase() ||
            "-",
          "Estate Name": booking.estateName?.toUpperCase() || "-",
          "Key Collection Period":
            keyCollectionPeriodListMap
              .get(booking.keyCollectionPeriodId)
              ?.toUpperCase() || "-",
          "No. of PAX":
            paxNumberListMap.get(booking.paxNumberId)?.toUpperCase() || "-",
          "Product Types":
            booking.productTypeIds
              ?.map((item) => productTypeListMap.get(item)?.toUpperCase())
              ?.join() || "-",
          "PDPA Consent": booking?.pdpa ? "YES" : "NO",
          "Waiting List": booking?.isWaitingList ? "YES" : "NO",
        };

        groupBuyEvents.forEach((item) => {
          transformedData[`Apppointment Date (${item.id})`] = booking?.event?.[
            item.id
          ]?.appointmentDate
            ? moment(booking?.event?.[item.id]?.appointmentDate).format(
              "DD/MM/YYYY",
            )
            : "-";
          transformedData[`Apppointment Start Time (${item.id})`] =
            booking?.event?.[item.id]?.appointmentStartTime || "-";
          transformedData[`Apppointment End Time (${item.id})`] =
            booking?.event?.[item.id]?.appointmentEndTime || "-";
          transformedData[`Visited (${item.id})`] =
            typeof booking?.event?.[item.id]?.visited !== "undefined"
              ? booking?.event?.[item.id]?.visited
                ? "YES"
                : "NO"
              : "-";
        });
        transformedData["Utm Source"] =
          booking?.utmSource?.toUpperCase() || "-";
        transformedData["Utm Campaign"] =
          booking?.utmCampaign?.toUpperCase() || "-";
        transformedData["Utm Content"] =
          booking?.utmContent?.toUpperCase() || "-";
        transformedData["Utm Medium"] =
          booking?.utmMedium?.toUpperCase() || "-";
        transformedData["Utm Term"] = booking?.utmTerm?.toUpperCase() || "-";

        csvData.push(transformedData);
      }
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }

    // Generate filename and download CSV
    const today = moment().format("YYYYMMDD_HHmmss");
    const filename = `BOOKING-REPORT-${today}.csv`;
    downloadCSV(csvData, filename);
  };

  const fetchAttribute = async () => {
    try {
      const { data } = await sendRequest(
        `/v1/group-buy-attribute/attribute`,
        "GET",
      );
      setPropertyTypeList(data.propertyType);
      setKeyCollectionPeriodList(data.keyCollectionPeriod);
      setProductTypeList(data.productType);
      setPaxNumberList(data.paxNumber);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
      return;
    }
  };

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  const fetchStore = async () => {
    try {
      const { data } = await sendRequest(`/v1/group-buy-store`, "GET");
      setStores(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
      return;
    }
  };

  function extractPayload(dataArray) {
    const headerRow = dataArray.at(0) || [];
    if (headerRow?.length > 0) {
      const bookingNoIndex = headerRow.findIndex((r) => r === "Booking No.");
      const eventIndex = headerRow.findIndex((r) => r === "Event");
      if (bookingNoIndex > -1 && eventIndex > -1) {
        const dataRows = dataArray
          .slice(1)
          .filter((row) => !!row.at(bookingNoIndex));

        return dataRows.map((row) => ({
          bookingNo: row.at(bookingNoIndex),
          eventId: row.at(eventIndex),
        }));
      }
    }
    return [];
  }

  const importCSVFile = (e) => {
    const selectedFiles = e.target.files;
    const file = selectedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const results = Papa.parse(reader.result);
        const payload = extractPayload(results?.data);
        Swal.fire({
          title: "Confirmation",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await handleUpdateVisited(payload);
          }
        });
      };
      reader.readAsText(file);
    }
  };

  const handleUpdateVisited = async (payload) => {
    setUpdateLoading(true);
    try {
      const { data } = await sendRequest(
        `/v1/group-buy-booking/visited`,
        "PUT",
        payload,
      );
      setRefresh(true);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Updated Visited successfully",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
    }
    setUpdateLoading(false);
  };

  const fetchEvents = async () => {
    const { data } = await sendRequest(`/v1/group-buy-event`, "GET");
    setGroupBuyEvents(data);
  };

  useEffect(() => {
    setRefresh(true);
  }, [
    keyCollectionPeriodFilter,
    paxNumberFilter,
    productTypeFilter,
    propertyTypeFilter,
    storeFilter,
    inputfilter,
    pdpaFilter,
    waitingListFilter,
  ]);

  // set filtered data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  // Responsive filter
  useEffect(() => {
    if (matchMediaQuery) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <BookingFilterMobile
            key={"rb-body"}
            keyCollectionPeriodFilter={keyCollectionPeriodFilter}
            keyCollectionPeriodList={keyCollectionPeriodList}
            setKeyCollectionPeriodFilter={setKeyCollectionPeriodFilter}
            propertyTypeFilter={propertyTypeFilter}
            propertyTypeList={propertyTypeList}
            setPropertyTypeFilter={setPropertyTypeFilter}
            productTypeFilter={productTypeFilter}
            productTypeList={productTypeList}
            setProductTypeFilter={setProductTypeFilter}
            paxNumberFilter={paxNumberFilter}
            paxNumberList={paxNumberList}
            setPaxNumberFilter={setPaxNumberFilter}
            stores={stores}
            setStoreFilter={setStoreFilter}
            storeFilter={storeFilter}
            boolOptions={boolOptions}
            inputfilter={inputfilter}
            setInputFilter={setInputFilter}
            groupBuyEvents={groupBuyEvents}
            pdpaFilter={pdpaFilter}
            setPdpaFilter={setPdpaFilter}
            waitingListFilte={waitingListFilter}
            setWaitingListFilter={setWaitingListFilter}
          />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [
    matchMediaQuery,
    stores,
    propertyTypeList,
    keyCollectionPeriodList,
    paxNumberList,
    productTypeList,
    groupBuyEvents,
  ]);

  // Set page tilte
  // Check Privilege
  useEffect(() => {
    document.title = "Group Buy Booking List";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.group_buy_calendar,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    fetchAttribute();
    fetchStore();
    fetchEvents();
  }, []);

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Group Buy Booking List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.group_buy_booking,
            PrivilegeActions.add,
          ) && (
              <Button
                className={"primary"}
                onClick={() => props.history.push("card")}
              >
                New
              </Button>
            )}
          {auth.checkModulePrivilege(
            PrivilegeModules.group_buy_booking,
            PrivilegeActions.download_report,
          ) && (
              <Button className={"primary"} onClick={() => downloadReport()}>
                Download Report
              </Button>
            )}

          {auth.checkModulePrivilege(
            PrivilegeModules.group_buy_booking,
            PrivilegeActions.edit,
          ) && (
              <>
                <input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{
                    display: "none",
                  }}
                  name="csv"
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => importCSVFile(e)}
                />
                <Button
                  className={"primary"}
                  onClick={() =>
                    document.getElementById("contained-button-file").click()
                  }
                >
                  {isUpdateLoading ? "Loading..." : "Import CSV"}
                </Button>
              </>
            )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={6} md={3} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-search"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={10} sm={6} md={3} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"utm-source-search"}
                  variant={"outlined"}
                  label={"UTM-Source"}
                  value={utmSourceFilter}
                  onChange={(e) => setUtmSourceFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </Grid>
              {!matchMediaQuery ? (
                <>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Autocomplete
                      id={"property-type-filter"}
                      options={propertyTypeList}
                      getOptionLabel={(item) => item.option.toUpperCase()}
                      value={propertyTypeFilter}
                      onChange={(e, newValue) =>
                        setPropertyTypeFilter(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Property Type"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Autocomplete
                      id={"key-collection-period-filter"}
                      options={keyCollectionPeriodList}
                      getOptionLabel={(item) => item.option.toUpperCase()}
                      value={keyCollectionPeriodFilter}
                      onChange={(e, newValue) =>
                        setKeyCollectionPeriodFilter(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Key Collection Period"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Autocomplete
                      id={"pax-number-filter"}
                      options={paxNumberList}
                      getOptionLabel={(item) => item.option.toUpperCase()}
                      value={paxNumberFilter}
                      onChange={(e, newValue) => setPaxNumberFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"No. of PAX"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Autocomplete
                      id={"product-type-filter"}
                      options={productTypeList}
                      getOptionLabel={(item) => item.option.toUpperCase()}
                      value={productTypeFilter}
                      onChange={(e, newValue) => setProductTypeFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Product Type"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  {groupBuyEvents.map((item) => (
                    <>
                      <Grid item xs={6} sm={6} md={3} lg={2}>
                        <DateFilter
                          label={`Appointment Date - From (${item.id})`}
                          value={
                            inputfilter?.[item.id]?.["appointmentStartDate"]
                          }
                          maxDate={
                            inputfilter?.[item.id]?.["appointmentEndDate"]
                          }
                          onChange={(newValue) =>
                            setInputFilter((prev) => ({
                              ...prev,
                              [item.id]: {
                                ...prev?.[item.id],
                                appointmentStartDate: newValue,
                              },
                            }))
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={2}>
                        <DateFilter
                          label={`Appointment Date - To (${item.id})`}
                          value={inputfilter?.[item.id]?.["appointmentEndDate"]}
                          minDate={
                            inputfilter?.[item.id]?.["appointmentStartDate"]
                          }
                          onChange={(newValue) =>
                            setInputFilter((prev) => ({
                              ...prev,
                              [item.id]: {
                                ...prev?.[item.id],
                                appointmentEndDate: newValue,
                              },
                            }))
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={2}>
                        <Autocomplete
                          id={"visited-filter"}
                          options={boolOptions}
                          getOptionLabel={(option) => option.toUpperCase()}
                          value={inputfilter?.[item.id]?.["visited"]}
                          onChange={(e, newValue) =>
                            setInputFilter((prev) => ({
                              ...prev,
                              [item.id]: {
                                ...prev?.[item.id],
                                visited: newValue,
                              },
                            }))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Visited (${item.id})`}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<KeyboardArrowDown />}
                        />
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Autocomplete
                      id={"store-filter"}
                      options={stores}
                      getOptionLabel={(item) => item.name.toUpperCase()}
                      value={storeFilter}
                      onChange={(e, newValue) => setStoreFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Preferred Outlet"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Autocomplete
                      id={"pdpa-filter"}
                      options={boolOptions}
                      getOptionLabel={(item) => item.toUpperCase()}
                      value={pdpaFilter}
                      onChange={(e, newValue) => setPdpaFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"PDPA Consent"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Autocomplete
                      id={"waiting-list-filter"}
                      options={boolOptions}
                      getOptionLabel={(item) => item.toUpperCase()}
                      value={waitingListFilter}
                      onChange={(e, newValue) => setWaitingListFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Waiting List"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => toggleRightbar(layoutDispatch)}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <ArrowBack className={classes.toggleRightBarIcon} />
                    ) : (
                      <PlaylistPlay className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={bookingList}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(BookingList);
