import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  IconButton,
} from "@mui/material";
import {
  Search as SearchIcon,
  KeyboardArrowDown as ArrowDownIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  SettingsPowerRounded,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../../../hooks/useHttpRequest";
import useStyles from "./styles";
import { FnbFoodPopupTableColumnConfig } from "./data-table/fnb-food-popup-table-column-config";
import {
  PrivilegeModules,
  PrivilegeActions,
} from "../../../../data/privileges.enum";
import {
  KeyboardArrowDown,
  PlaylistPlay,
  ArrowBack,
} from "@mui/icons-material";

const FnbFoodPopup = (props) => {
  const classes = useStyles();

  // handle loading & http
  const { isLoading, sendRequest } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  // filters
  const [textFilter, setTextFilter] = useState("");
  const [fnbStores, setFnbStores] = useState([]);
  const [storeFilter, setStoreFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [fnbCategories, setFnbCategories] = useState([]);
  const [enableFilter, setEnableFilter] = useState(null);

  const [promoteFilter, setPromoteFilter] = useState(null);
  const [chefRecommendFilter, setChefRecommendFilter] = useState(null);
  const [vegetarianFilter, setVegetarianFilter] = useState(null);
  const [healthierChoiceFilter, setHealthierChoiceFilter] = useState(null);
  const [noStockFilter, setNoStockFilter] = useState(null);
  const [popularFilter, setPopularFilter] = useState(null);
  const [selectedFnbStoreCodes, setSelectedFnbStoreCodes] = useState(null);
  const [prerequisiteChecked, setPrerequisiteChecked] = useState(false);

  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  const [fnbFoodList, setFnbFoodList] = useState([]);
  const smallDeviceWidth = 1200;

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    loadPrerequisites();

    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    if (fnbStores.length > 0 && !!selectedFnbStoreCodes) {
      onChangeStoreFilter(fnbStores[0]);
    }
  }, [fnbStores, selectedFnbStoreCodes]);

  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: setRefresh(true),
      });
    }
  }, [message]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  // filter data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter && prerequisiteChecked) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    if (prerequisiteChecked) {
      setRefresh(true);
    }
  }, [
    storeFilter,
    categoryFilter,
    enableFilter,
    promoteFilter,
    chefRecommendFilter,
    vegetarianFilter,
    healthierChoiceFilter,
    noStockFilter,
    popularFilter,
  ]);

  const loadPrerequisites = () => {
    sendRequest(`/v1/fnb-food/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        let selectedFnbStoreCodes = prerequisites.selectedFnbStoreCodes;
        setPrerequisiteChecked(true);
        setFnbStores(
          prerequisites.fnbStores.filter((s) => {
            return s.storeCode == props.selectedStore.storeCode;
          }),
        );
        setSelectedFnbStoreCodes([props.selectedStore.storeCode]);
        setEnableFilter("YES");
      },
    );
  };

  function filter() {
    // set list filters
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const foods = response.data?.foods || [];

        foods.forEach((food) => {
          const isSelected = props.selectedItemList.some(
            (selectedFood) => selectedFood.entityId === food.entityId,
          );
          if (isSelected) {
            food.selected = true;
          } else {
            food.selected = false;
          }
        });

        setFnbFoodList(foods);
        setCollectionSize(response.data?.total);
        return () => {
          return foods;
        };
      },
    );
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      storeId: storeFilter ? storeFilter.storeId : undefined,
      categoryId: categoryFilter ? categoryFilter.entityId : undefined,
      enable: enableFilter,
      promote: promoteFilter,
      chefRecommend: chefRecommendFilter,
      vegetarian: vegetarianFilter,
      healthierChoice: healthierChoiceFilter,
      noStock: noStockFilter,
      popular: popularFilter,
      selectedFnbStoreCodes: selectedFnbStoreCodes,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/fnb-food/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${sortBy?.column && sortBy?.direction
        ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
        : ""
      }`,
      "GET",
      {},
    );
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function onChangeStoreFilter(newValue) {
    setStoreFilter(newValue);
    setCategoryFilter(null);
    setFnbCategories(
      newValue
        ? fnbStores.filter((c) => {
          return c.storeId == newValue.storeId;
        })[0].categories
        : [],
    );
  }

  const handleItemEnable = (rowItem) => {
    rowItem.selected = !rowItem.selected;

    if (rowItem.selected) {
      props.setSelectedItemList([...props.selectedItemList, rowItem]);
    } else {
      const indexOfItem = props.selectedItemList.findIndex(
        (item) => item.entityId === rowItem.entityId,
      );
      const selectedItems = [...props.selectedItemList];
      selectedItems.splice(indexOfItem, 1);
      props.setSelectedItemList(selectedItems);
    }
  };

  // set columns in data table
  const columns = FnbFoodPopupTableColumnConfig(handleItemEnable);

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <TextField
            autoComplete={"off"}
            id={"text-searchbox"}
            variant={"outlined"}
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Autocomplete
            id={"category-filter"}
            options={fnbCategories}
            getOptionLabel={(category) =>
              !storeFilter
                ? `${category.store.storeCode
                } - ${category.name.toUpperCase()}`
                : `${category.name.toUpperCase()}`
            }
            value={categoryFilter}
            onChange={(e, newValue) => setCategoryFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Category"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DataTable
            fixedHeader={true}
            persistTableHead={true}
            columns={columns}
            data={fnbFoodList}
            sortServer
            onSort={sortByColumn}
            pagination
            paginationServer
            paginationDefaultPage={page}
            paginationPerPage={pageSize}
            paginationTotalRows={collectionSize}
            onRowClicked={(row, e, index) => { handleItemEnable(row) }}
            onChangeRowsPerPage={(count) =>
              handleRowsPerPageChanged(count)
            }
            onChangePage={(page) => handlePageChanged(page)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(FnbFoodPopup);
