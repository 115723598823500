import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, Switch, FormControlLabel, Autocomplete, Checkbox, ListItemSecondaryAction
} from "@mui/material";
import Swal from "sweetalert2";

import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useUserState } from "../../context/UserContext";
import { validateText } from "../../helper/validate-textfield"

import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
// import TreeView from "@material-ui/lab/TreeView";
// import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions, UserRoles } from "../../data/privileges.enum";

const RoleCard = (props) => {
    const _module = "role";

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest: httpRequest } = useHttpRequest();

    // user info & permissions
    const { auth } = useRoleAuthorization();

    const [selectedRole, setSelectedRole] = useState();
    const [message, setMessage] = useState(""); // handle swal message
    const { roleId } = useParams();
    const [privileges, setPrivileges] = useState([]);
    const [selected, setSelected] = useState([]);
    const [moduleList, setModuleList] = useState([]);

    // handling form when adding / editing
    const { register, handleSubmit, control, formState: { errors: formErrors },
        setError, getValues, setValue, watch }
        = useForm({ mode: "all", defaultValues: selectedRole });

    // set title when adding role
    useEffect(() => {
        // redirect to default page if not authorized
        if (roleId && auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.role, PrivilegeActions.view_detail)) {
            props.history.push('/app/dashboard');
        }

        if (!roleId) {
            document.title = "New - Role Card";
            httpRequest(`/v1/role/module-action-list`, "GET");
        }
    }, []);

    // get current role when editing 
    useEffect(() => {
        if (!isLoading && !responseData && roleId) {
            httpRequest(`/v1/role/get-by-id/` + roleId, 'GET');
        }
    }, [roleId]);

    // set title & form values with current role when editing
    useEffect(() => {
        if (roleId && responseData?.role) {
            const role = responseData?.role;
            document.title = `${role.name} - Role Card`;
            setSelectedRole(role);
            setValue('code', role.code.toUpperCase() || '');
            setValue('name', role.name || '');
            setValue('remark', role.remark || '');
            preparSelectedActions(responseData.rolePrivileges);
            setPrivileges(responseData.rolePrivileges);
            setModuleList(responseData.rolePrivileges.map(item => item.module));
        } else if (!roleId && responseData?.module_action_list) {
            setPrivileges(responseData.module_action_list);
            setModuleList(responseData.module_action_list.map(item => item.module));
        }
    }, [responseData]);

    // show feedback message after add / edit
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    // show error when error occured
    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error]);

    function preparSelectedActions(privileges) {
        privileges.forEach(m => {
            m.actions.forEach(a => {
                if (a.value == 1) {
                    setSelected(current => [...current, `${m.module}-${a.action}`]);
                }
            })
        })
    }

    // form submit
    const handleSubmitForm = async (data) => {
        const role = {
            roleCode: data.code.trim().toLowerCase(),
            roleName: data.name.trim().toUpperCase(),
            remark: data.remark.trim().toUpperCase(),
            privileges: privileges
        };

        let result = null;
        // check whether add or edit attempt  
        if (selectedRole && selectedRole._id) {
            role._id = selectedRole._id; // set _id before edit
            result = await httpRequest(`/v1/role/${role._id}`, "PUT", role);
            if (result?.status === 200 && result?.data?.role) {
                setMessage(`Role ${role.roleName} has been updated.`); // call show feedback message
            }
        } else {
            result = await httpRequest('/v1/role', "POST", role);
            if (result?.status === 201 && result?.data?.role) {
                setMessage(`Role ${role.roleName} has been created.`); // call show feedback message
            }
        }
    };

    // navigate back to Role list
    const navigateToList = () => {
        if (!roleId) {
            window.location.href = '#/app/role/list';
        }
    }

    function updateAction(checked, nodeKey) {
        //check if Set Default and clear other module from Default first
        if (checked && nodeKey.includes("-default")) {
            setSelected(current => current.filter(element => {
                return !element.includes("-default");
            }));

            privileges.forEach(m => {
                m.actions.filter(a => a.action == 'default')[0].value = 0;
            })
        }

        privileges.filter(m => { return m.module == nodeKey.split("-")[0] })[0].actions.filter(ma => { return ma.action == nodeKey.split("-")[1] })[0].value = checked;

        if (checked) {
            setSelected(current => [...current, nodeKey]);
        } else {
            setSelected(current => current.filter(element => {
                return element != nodeKey;
            }));
        }
    }

    function getOnChange(checked, nodeKey) {
        updateAction(checked, nodeKey);
    }

    const renderTreeItem = (nodes, type, module) => {
        const nodeKey = (type == 'module' ? nodes.module : (`${module}-${nodes.action}`));
        const label = type == "action" ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    id={`checkbox_${nodeKey}`}
                    checked={selected.some(item => item === nodeKey)}
                    onChange={event =>
                        getOnChange(event.currentTarget.checked, nodeKey)
                    }
                    onClick={e => e.stopPropagation()}
                />
                <Typography variant="caption">{nodes.name}</Typography>
            </div>
        ) : (
            nodes.name
        );

        return (
            <TreeItem key={nodeKey} nodeId={nodeKey} label={label}>
                {
                    Array.isArray(nodes.actions)
                        ? nodes.actions.map((node) => renderTreeItem(node, 'action', nodes.module))
                        : null
                }
            </TreeItem>
        );
    };

    const renderRolePrivilegesTreeData = () => {
        let treeData = [];

        privileges.forEach(rp => {
            treeData.push(renderTreeItem(rp, 'module'));
        })

        return treeData;
    }

    const handleSelect = (event, nodeKey) => {
        if (!nodeKey.includes("-")) {
            if (moduleList.indexOf(nodeKey) > -1) {
                setModuleList(current => current.filter(element => {
                    return element != nodeKey;
                }));
            } else {
                setModuleList(current => [...current, nodeKey]);
            }

            return;
        }

        let checked = !privileges.filter(m => { return m.module == nodeKey.split("-")[0] })[0].actions.filter(ma => { return ma.action == nodeKey.split("-")[1] })[0].value;
        updateAction(checked, nodeKey);
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 1 }} open={isLoading}><CircularProgress color={"inherit"} /></Backdrop>}
        {(!roleId || (roleId && selectedRole)) && <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant={"h1"}>Role Card {selectedRole ? ` - ${selectedRole.name}` : ` - New`}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                    <Button onClick={() => props.history.push('/app/role/list')}>Cancel</Button>

                    {
                        ((!roleId && auth.checkModulePrivilege(PrivilegeModules.role, PrivilegeActions.add)) || (roleId && auth.checkModulePrivilege(PrivilegeModules.role, PrivilegeActions.edit)))
                        &&
                        <Button className={"primary"} type={"submit"}>Save</Button>
                    }
                </Grid>
                <Grid
                    item xs={12}>
                    <Grid container className={"form"}>
                        <Grid item xs={12}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12}>
                                    <Typography variant={"h2"}>General</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={{ xs: 2, md: 3 }}>
                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                            <TextField id={"code"} label={"Code"} variant={"outlined"}
                                                {...register("code", {
                                                    required: { value: true, message: "Code cannot be blank." }
                                                })}
                                                autoComplete={"off"}
                                                error={!!formErrors?.code}
                                                helperText={formErrors?.code?.message}
                                                onChange={(event) => validateText(event)}
                                                disabled={!!roleId && !!selectedRole}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                            <TextField id={"name"} label={"Name"} variant={"outlined"}
                                                {...register("name", {
                                                    required: { value: true, message: "Name cannot be blank." }
                                                })}
                                                autoComplete={"off"}
                                                error={!!formErrors?.name}
                                                helperText={formErrors?.name?.message}
                                                onChange={(event) => validateText(event)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                            <TextField
                                                id={"remark"}
                                                label={"Remark"}
                                                autoComplete={"off"}
                                                variant={"outlined"}
                                                {...register("remark")}
                                                error={!!formErrors?.remark}
                                                helperText={formErrors?.remark?.message}
                                                onChange={(event) => validateText(event)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!(selectedRole?.code === UserRoles.admin) &&
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} className={"sub-action"}>
                                    <Typography variant={"h2"}>Privilege</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TreeView
                                        aria-label="rich object"
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        expanded={moduleList}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                        onNodeSelect={handleSelect}
                                        sx={{ height: '100%', flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}>
                                        {renderRolePrivilegesTreeData()}
                                    </TreeView>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </form>
        }
    </>
    );
};

export default RoleCard;