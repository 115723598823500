import moment from "moment";
import { Link } from "react-router-dom";
import { convertThisToCurrency } from '../../../helper';

export const getOrderManagementTableConfig = () => [
  {
    name: "Order No.",
    selector: (row) => (
      <Link to={"/app/fnb-order/card/" + row.orderNumber}>
        #{row.orderNumber}
      </Link>
    ),
    sortable: true,
    sortField: "orderNumber",
    width: "110px",
  },
  {
    name: "Queue No.",
    width: "90px",
    sortable: true,
    sortField: "queueNumber",
    selector: (row) => row.queueNumber,
  },
  {
    name: "Total Amount",
    width: "110px",
    right: true,
    sortable: true,
    sortField: "grandTotal",
    selector: (row) => convertThisToCurrency(row.grandTotal.toFixed(2)),
  },
  {
    name: "Order Date",
    width: "150px",
    wrap: true,
    sortable: true,
    sortField: "orderTime",
    selector: (row) =>
      row.orderTime
        ? moment(row.orderTime).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
  },
  {
    name: "Order Status",
    width: "140px",
    sortable: true,
    sortField: "status",
    selector: (row) => row.status.toUpperCase(),
  },
  {
    name: "Order Type",
    width: "120px",
    sortable: true,
    sortField: "orderType",
    selector: (row) => row.orderType.toUpperCase(),
  },
  {
    name: "Payment Method",
    width: "150px",
    selector: (row) => row?.__payment__?.paymentMethod?.toUpperCase(),
  },
  {
    name: "Reference No.",
    width: "120px",
    sortable: true,
    sortField: "navNumber",
    selector: (row) => row.navNumber,
  },
  {
    name: "Tax Amount",
    width: "100px",
    right: true,
    sortable: true,
    sortField: "taxAmount",
    selector: (row) => convertThisToCurrency(row.taxAmount.toFixed(2)),
  },
  {
    name: "Redeemed Code",
    width: "150px",
    sortable: true,
    sortField: "redeemedCode",
    selector: (row) => row.redeemedCode,
  },
  {
    name: "Store",
    selector: (row) => row?.store?.name?.toUpperCase(),
  },
];
