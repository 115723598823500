import { Switch } from "@mui/material";
import { Link } from "react-router-dom";

export const matrixTableColumnConfig = (permission, handleToggleStatus) => [
  {
    name: "Brand",
    selector: (row) => row.matrixNo,
    cell: (row, index, column, id) => {
      if (permission.allowedViewDetail) {
        return (
          <Link to={"/app/aircon-subscription-matrix/card/" + row._id}>
            {row.brandName}
          </Link>
        );
      } else {
        return <>{row.brandName}</>;
      }
    },
    sortable: true,
    sortField: "brandName",
  },
  {
    name: "Enable",
    width: "80px",
    center: true,
    selector: (row) => row.enabled,
    cell: (row) => {
      return (
        <Switch
          checked={row.enabled}
          disabled={!permission.allowedEnableDisable}
          onChange={(e, value) => handleToggleStatus(row, "enabled")}
          inputProps={{ "aria-label": "controlled" }}
        />
      );
    },
    sortable: true,
    sortField: "enabled",
  },
];
