import axios from "axios";
import JSZip from "jszip";
import { useState } from "react";
import Swal from "sweetalert2";

// Handle download a file
const exportFile = (file, fileName) => {
  const a = document.createElement("a");
  const url = URL.createObjectURL(file);
  a.download = fileName;
  a.href = url;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
};
// Check Exist File in Zip
const checkFileInZipExist = (zip, data = []) => {
  for (const ele of data) {
    if (zip.files[`${ele.path}/`] !== undefined) {
      return true;
    }
    continue;
  }
  return false;
};

const promiseFiles = async (documents = [], zip, folderName) => {
  const blobs = documents.map(async ({ url, fileName }) => {
    try {
      const res = await axios.get(url, {
        responseType: "blob",
      });

      const safeFileName = fileName.replace(/\//g, "_");

      return {
        blob: res.data,
        fileName: safeFileName,
      };
    } catch (error) {
      return null;
    }
  });
  const promiseBlobs = await Promise.all(blobs);
  promiseBlobs.forEach((promiseBlob) => {
    if (promiseBlob) {
      zip.folder(folderName).file(promiseBlob.fileName, promiseBlob.blob);
    }
  });
  return zip;
};

export default function useDownload() {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async (
    zipName,
    data = [],
    msg = { noDocumentMsg: "", errMsg: "" },
  ) => {
    let zip = new JSZip();
    setIsDownloading(true);
    try {
      // Check data empty
      if (data.length < 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: msg.noDocumentMsg || "No documents found",
        });
        return;
      }

      await Promise.all(
        data.map(async ({ path, documents }) => {
          if (documents) {
            zip = await promiseFiles(documents, zip, path);
          }
        }),
      );
      // Check File Exist
      if (checkFileInZipExist(zip, data)) {
        // Generate zip file
        const zipFile = await zip.generateAsync({ type: "blob" });
        // Download zip file
        exportFile(zipFile, zipName);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: msg.noDocumentMsg || "No documents found",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: msg.errMsg || error.message || "Something Error",
      });
    }
    setIsDownloading(false);
  };

  return {
    handleDownload,
    isDownloading,
  };
}
