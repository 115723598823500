export const unpaidListColumnConfig = [
    {
        name: "Invoice No.",
        width: '110px',
        selector: row => row.pksNo,
    },
    {
        name: "Payment Date",
        width: '120px',
        selector: row => row.paymentDate,
    },
    {
        name: "Imported",
        width: '80px',
        center: true,
        selector: row => row.imported,
    },
    {
        name: "Error Message",
        selector: row => row.message,
    }
];
