import { Button, Modal, Box, IconButton } from "@mui/material";
import { useSignature } from "../../../../hooks";
import ClearIcon from "@mui/icons-material/Clear";
import SignatureCanvas from "react-signature-canvas";
import { PR_ACTIONS } from "../../helper/pr-config";
import { isStep_1_5 } from "../../helper/pr-config";

const ProjectRequestSignaturePadModal = (props) => {
  const {
    getFileFromSign,
    isSignChange,
    isSignEmpty,
    onChangeSign,
    sigCanvas,
    setSignChange,
    setSignEmpty,
    onClear,
    getBase64FromSign,
  } = useSignature();

  const handleCloseSignaturePad = (data, e) => {
    const signPic = getBase64FromSign();
    if (signPic) {
      props.onSubmit(data, e, PR_ACTIONS.APPROVE, signPic);
      props.setOpenSignaturePad(false);
    }
  };

  return (
    <Modal
      open={props.openSignaturePad}
      onClose={() => props.setOpenSignaturePad(false)}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className="sign-root">
          <label className="sign-label">Sign Here</label>
          <IconButton
            aria-label="delete"
            className="sign-action-delete"
            onClick={() => onClear()}
          >
            <ClearIcon />
          </IconButton>
          <SignatureCanvas
            canvasProps={{ className: "sign-canvas" }}
            clearOnResize={false}
            onEnd={() => onChangeSign()}
            ref={sigCanvas}
            // penColor={verifyMode ? "gold" : "black"}
          />
          <label className="sign-title">Sign for approval</label>
          <Button
            className="sign-action-ok"
            onClick={props.handleSubmit((data, e) => {
              handleCloseSignaturePad(data, e);
            })}
          >
            Ok
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ProjectRequestSignaturePadModal;
