import {
    Backdrop, Button, CircularProgress, Grid,
    IconButton, InputAdornment, TextField, Typography
} from "@mui/material";
import * as moment from "moment";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { SERVICE_CLAIMS_STATUS } from "../../data/constants";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import DateController from "../../components/DateController";

const ServiceClaimEdit = (props) => {
    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization
    const { auth } = useRoleAuthorization();

    // handling form when adding / editing
    const { register, handleSubmit, setValue, control,
        formState: { errors: formErrors } } = useForm({ mode: "all" });

    const { invoiceNo } = useParams();
    const [claim, setClaim] = useState(null);
    const [claimDate, setClaimDate] = useState();
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (!isLoading && !responseData && invoiceNo) {
            sendRequest(`/v1/service-claim/get-by-invoice-no/${invoiceNo}`, 'GET');
        }
    }, [invoiceNo]);

    useEffect(() => {
        if (invoiceNo && responseData?.serviceClaim) {
            const claim = responseData?.serviceClaim;
            document.title = `${claim.invoiceNo} - Service Claim Card`;
            setClaim(claim);
            setClaimDate(claim.claimDate);
            setValue('claimDate', claim.claimDate || '');
            setValue('invoiceNo', claim.invoiceNo || '');
            setValue('team', `${claim.team}-${claim.teamName}`);
            setValue('totalClaimAmount', (claim.totalClaimAmount || 0).toFixed(2));
        }
    }, [responseData]);

    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    const handleSubmitForm = async (data, e) => {
        e.preventDefault();  
        if (!claimDate || claimDate == 'Invalid Date') return; 
        
        if (claim) {
            claim.claimDate = moment(claimDate).set({ h: 0, m: 0, s: 0 }).toDate();
            const response = await sendRequest(`/v1/service-claim/${claim._id}`,
                'PUT', claim);
            if (response?.status === 200 && response?.data?.serviceClaim) {
                setMessage(`Service claim for invoice ${claim.invoiceNo} has been updated.`);
            }
        }
    };

    const onApprove = async () => {
        if (claim && claim.status === SERVICE_CLAIMS_STATUS.PENDING
            && claim.invoicePosted === 'Yes'
            && claim.calculated === 'Yes'
            && claim.orderBalance === 0) {
            const result = await Swal.fire({
                title: `Confirmation`,
                text: `Are you sure you want to approve the service claim for invoice ${claim.invoiceNo}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            });
            if (result?.isConfirmed) {
                const result = await sendRequest(`/v1/service-claim/approve`, "POST",
                    { invoiceNos: [claim.invoiceNo] });
                if (result?.status === 200 && result?.data?.updated) {
                    claim.status = SERVICE_CLAIMS_STATUS.APPROVED;
                    setMessage(`Service claim for invoice ${claim.invoiceNo} has been approved.`);
                }
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: "Oops...",
                text: `Unable to approve the service claim for invoice ${claim.invoiceNo}`
            });
        }
    }

    const navigateToList = () => {
        if (claim.status === SERVICE_CLAIMS_STATUS.APPROVED) {
            window.location.href = '#/app/service-claim/list';
        }
    };

    const onChangeClaimDate = (date) => {
        setValue('claimDate', date);
        setClaimDate(date);
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Service Claim Card - {invoiceNo}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/service-claim/list')}>Cancel</Button>
                        {
                            (auth.checkModulePrivilege(PrivilegeModules.service_claim, PrivilegeActions.edit) &&
                                claim?.status === SERVICE_CLAIMS_STATUS.PENDING) &&
                            <Button className={"primary"} type={"button"}
                                onClick={handleSubmit(handleSubmitForm)}>Save</Button>
                        }
                        {
                            (auth.checkModulePrivilege(PrivilegeModules.service_claim, PrivilegeActions.approve) &&
                                claim?.status === SERVICE_CLAIMS_STATUS.PENDING && claim?.calculated === 'Yes' && claim?.invoicePosted === 'Yes' && claim?.orderBalance === 0) &&
                            <Button className={"primary"} type={"button"}
                                onClick={onApprove}>Approve</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                              <DateController
                                                control={control}
                                                label={'Claim Date'}
                                                name={'claimDate'}
                                                required={true}
                                                value={claimDate}
                                                onChange={onChangeClaimDate}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"invoiceNo"}
                                                    label={"Invoice No."}
                                                    variant={"outlined"}
                                                    {...register("invoiceNo")} disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"team"}
                                                    label={"Team"}
                                                    variant={"outlined"}
                                                    {...register("team")} disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"totalClaimAmount"}
                                                    label={"Total Claim Amount"}
                                                    variant={"outlined"}
                                                    {...register("totalClaimAmount")} disabled
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(ServiceClaimEdit);