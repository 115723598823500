import { Add } from "@mui/icons-material";
import {
    Backdrop, Button, CircularProgress, Grid,
    IconButton, InputAdornment, TextField, Typography
} from "@mui/material";
import * as moment from "moment";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { INSTALLATION_CLAIMS_STATUS } from "../../data/constants";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import DataTable from "react-data-table-component";
import { resourceTableColumnConfig } from "./data-table/resource-table-column-config";
import DateController from "../../components/DateController";

const InstallationClaimEdit = (props) => {
    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization
    const { auth } = useRoleAuthorization();

    // handling form when adding / editing
    const { register, handleSubmit, setValue, control,
        formState: { errors: formErrors } } = useForm({ mode: "all" });

    const { invoiceNo } = useParams();
    const { team } = useParams();
    const [claim, setClaim] = useState(null);
    const [claimDate, setClaimDate] = useState();
    const [message, setMessage] = useState("");
    const [claimedOtherResourceList, setClaimedOtherResourceList] = useState([]);
    const [rowsInvalid, setRowsInvalid] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [otherResourceItemsList, setOtherResourceItemsList] = useState([]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    useEffect(() => {
        if (!isLoading && !responseData && invoiceNo) {
            loadGenericItems(team);
            sendRequest(`/v1/installation-claim/get-by-invoice-no/${invoiceNo}`, 'GET');
        }
    }, [invoiceNo]);

    useEffect(() => {
        if (invoiceNo && responseData?.installationClaim) {
            const claim = responseData?.installationClaim;
            document.title = `${claim.invoiceNo} - Installation Claim Card`;
            setClaim(claim);
            setClaimDate(claim.claimDate);
            setValue('claimDate', claim.claimDate || '');
            setValue('invoiceNo', claim.invoiceNo || '');
            setValue('team', `${claim.team}-${claim.teamName}`);
            setValue('totalClaimAmount', (claim.totalClaimAmount || 0).toFixed(2));

            if (claim.otherResource) {
                setClaimedOtherResourceList(claim.otherResource);
            }
        }
    }, [responseData]);

    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    function loadGenericItems(team) {
        sendRequest(`/v1/installation-claim/get-other-resource-items/${team}`, "GET", {})
            .then(response => {
                setOtherResourceItemsList(response.data?.otherResouceItems || []);
            });
    }

    const handleSubmitForm = async (data, e) => {
        e.preventDefault();
        if (!claimDate || claimDate == 'Invalid Date') return;

        claim.otherResource = claimedOtherResourceList.filter((item) => {return item.code != '' && item.description != ''});

        //Remove generic items from the invoice lines
        let newInvoiceLines = claim.invoice.invoiceLines.filter((item) => {
            return (item.isGeneric != true || item.isGeneric == undefined);
        });

        claim.invoice.invoiceLines = newInvoiceLines;
        //

        //Generate additional invoice lines for GENERIC items
        if (claim.otherResource.length > 0) {
            for (let i = 0; i < claim.otherResource.length; i++) {
                claim.invoice.invoiceLines.push({
                    claimAmount: (claim.otherResource[i].qty * claim.otherResource[i].price),
                    description: claim.otherResource[i].code,
                    lineDiscountAmount: 0,
                    lineNo: ('0000' + i.toString()).padStart(4, '0'),
                    lineTotalAmount: (claim.otherResource[i].qty * claim.otherResource[i].price),
                    locationCode: '',
                    model: '',
                    no: claim.otherResource[i].code,
                    quantity: claim.otherResource[i].qty,
                    serviceItemNo: '',
                    type: 0,
                    unitOfMeasure: '',
                    unitPrice: claim.otherResource[i].price,
                    isGeneric: true
                })
            }
        }
        if (claim) {
            claim.claimDate = moment(claimDate).set({ h: 0, m: 0, s: 0 }).toDate();
            claim.totalClaimAmount = data.totalClaimAmount;
            const response = await sendRequest(`/v1/installation-claim/${claim._id}`,
                'PUT', claim);
            if (response?.status === 200 && response?.data?.installationClaim) {
                setMessage(`Installation claim for invoice ${claim.invoiceNo} has been updated.`);
            }
        }
    };

    const onApprove = async () => {
        if (claim && claim.status === INSTALLATION_CLAIMS_STATUS.PENDING
            && claim.invoicePosted === 'Yes'
            && claim.calculated === 'Yes'
            && claim.orderBalance === 0) {
            const result = await Swal.fire({
                title: `Confirmation`,
                text: `Are you sure you want to approve the installation claim for invoice ${claim.invoiceNo}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            });
            if (result?.isConfirmed) {
                const result = await sendRequest(`/v1/installation-claim/approve`, "POST",
                    { invoiceNos: [claim.invoiceNo] });
                if (result?.status === 200 && result?.data?.updated) {
                    claim.status = INSTALLATION_CLAIMS_STATUS.APPROVED;
                    setMessage(`Installation claim for invoice ${claim.invoiceNo} has been approved.`);
                }
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: "Oops...",
                text: `Unable to approve the installation claim for invoice ${claim.invoiceNo}`
            });
        }
    }

    const navigateToList = () => {
        if (claim.status === INSTALLATION_CLAIMS_STATUS.APPROVED) {
            window.location.href = '#/app/installation-claim/list';
        }
    };

    const onChangeClaimDate = (date) => {
        setValue('claimDate', date);
        setClaimDate(date);
    }

    // set resource table columns
    const columns = resourceTableColumnConfig(otherResourceItemsList, onRemoveRow, onChangeResourceCode,
        onChangeIsPercentageCheck, handleQtyChange,
        onKeyDownClaimValue,
        !(auth.checkModulePrivilege(PrivilegeModules.installation_claim, PrivilegeActions.edit_other_resource) && auth.checkModulePrivilege(PrivilegeModules.installation_claim, PrivilegeActions.edit) && claim?.status === INSTALLATION_CLAIMS_STATUS.PENDING)
    );

    // remove resource from list
    function onRemoveRow(i) {
        const rows = [...claimedOtherResourceList];
        rows.splice(i, 1);
        setClaimedOtherResourceList([...rows]);
    }

    function checkIfDuplicateGenericType(index, newValue) {
        let lFound = false;

        for (let i = 0; i < claimedOtherResourceList.length; i++) {
            if (i != index && claimedOtherResourceList[i].type == newValue.type) {
                lFound = true;
                break;
            }
        }

        return lFound;
    }

    function clearSelectedOtherResourceCode(index) {
        const rList = [...claimedOtherResourceList];
        rList[index].code = '';
        rList[index].description = '';
        setClaimedOtherResourceList(rList);
    }

    function onChangeResourceCode(i, newValue) {
        //Clear selected item
        if (newValue == null) {
            clearSelectedOtherResourceCode(i);
            return;
        }

        // //check for duplicate type
        // if (checkIfDuplicateGenericType(i, newValue)) {
        //     setWarningMessage(`Type ${newValue.type} has been selected.`);
        //     onRemoveRow(i);
        //     return;
        // }

        //check duplicate item code
        if (checkIfDuplicatedCodes(i, newValue)) {
            setWarningMessage(`Duplicate resource ${newValue.resourceCode.toUpperCase()} found in the list.`);
            clearSelectedOtherResourceCode(i);
            return;
        }

        const rList = [...claimedOtherResourceList];
        rList[i].code = newValue.resourceCode.trim().toUpperCase();
        rList[i].description = newValue.resourceCode.trim().toUpperCase();
        rList[i].price = newValue.claimValue;
        rList[i].type = newValue.type;
        setClaimedOtherResourceList(rList);
    }

    function onChangeIsPercentageCheck(i, value) {
        const rList = [...claimedOtherResourceList];
        rList[i].isPercentage = value;
        setClaimedOtherResourceList(rList);
    }

    function handleQtyChange(i, value) {
        const rList = [...claimedOtherResourceList];
        rList[i].qty = value;
        setClaimedOtherResourceList(rList);
    }

    function createNewRow() {
        setClaimedOtherResourceList([...claimedOtherResourceList, {
            code: '',
            description: '',
            price: 0,
            qty: 1,
            type: ''
        }]);
    }

    function onKeyDownClaimValue(e) {
        if (e.key === 'Enter' && e.target.value && !rowsInvalid) {
            e.preventDefault();
            createNewRow();
        }
    }

    function checkIfDuplicatedCodes(index, newValue) {
        let lFound = false;

        for (let i = 0; i < claimedOtherResourceList.length; i++) {
            if (i != index && claimedOtherResourceList[i].code == newValue.resourceCode) {
                lFound = true;
                break;
            }
        }

        return lFound;
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Installation Claim Card - {invoiceNo}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/installation-claim/list')}>Cancel</Button>
                        {
                            (auth.checkModulePrivilege(PrivilegeModules.installation_claim, PrivilegeActions.edit) &&
                                claim?.status === INSTALLATION_CLAIMS_STATUS.PENDING) &&
                            <Button className={"primary"} type={"button"}
                                onClick={handleSubmit(handleSubmitForm)}>Save</Button>
                        }
                        {
                            (auth.checkModulePrivilege(PrivilegeModules.installation_claim, PrivilegeActions.approve) &&
                                claim?.status === INSTALLATION_CLAIMS_STATUS.PENDING && claim?.calculated === 'Yes' && claim?.invoicePosted === 'Yes' && claim?.orderBalance === 0) &&
                            <Button className={"primary"} type={"button"}
                                onClick={onApprove}>Approve</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                              <DateController
                                                control={control}
                                                label={'Claim Date'}
                                                name={'claimDate'}
                                                required={true}
                                                value={claimDate}
                                                onChange={onChangeClaimDate}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"invoiceNo"}
                                                    label={"Invoice No."}
                                                    variant={"outlined"}
                                                    {...register("invoiceNo")} disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"team"}
                                                    label={"Team"}
                                                    variant={"outlined"}
                                                    {...register("team")} disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"totalClaimAmount"}
                                                    label={"Total Claim Amount"}
                                                    type={'number'}
                                                    variant={"outlined"}
                                                    {...register("totalClaimAmount",{
                                                        required: { value: true, message: "Total Claim Amt cannot be blank." },
                                                        pattern: { value: /^\d*\.?\d*$/, message: "Total Claim Amt may contain only numbers (0-9)." }
                                                    })}
                                                    error={!!formErrors?.totalClaimAmount}
                                                    helperText={formErrors?.totalClaimAmount?.message}                                                    
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                    disabled={!(auth.checkModulePrivilege(PrivilegeModules.installation_claim, PrivilegeActions.edit_claim_amount))}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} className={"sub-action"}>
                                    <Typography variant={"h2"}>Other Resource</Typography>
                                </Grid>
                                <Grid item xs={12} className={"table"}>
                                    <DataTable
                                        fixedHeader={true}
                                        persistTableHead={true}
                                        columns={columns}
                                        data={claimedOtherResourceList}
                                    />
                                </Grid>
                                <Grid item xs={12} className={"table-action"}>
                                    <IconButton
                                        onClick={createNewRow}
                                        disabled={(rowsInvalid || !auth.checkModulePrivilege(PrivilegeModules.installation_claim, PrivilegeActions.edit_other_resource))}>
                                        <Add /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(InstallationClaimEdit);