import { Controller, useFormContext } from "react-hook-form";
import * as React from "react";
import { useEffect, useMemo } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  RadioButtonCheckedOutlined as CheckBoxCheckedIcon,
  RadioButtonUncheckedOutlined as CheckBoxIcon,
} from "@mui/icons-material";

export function SingleOption({ addOn, onChange }) {
  const { register, control, getValues, setValue, watch } = useFormContext();
  const optionKey = `items[0].addon.${addOn.entityId}.selectedOptions`;
  setValue(`items[0].addon.${addOn.entityId}.addonId`, addOn.entityId);

  const selectedValue = watch(optionKey);

  const handleClick = (valueKey) => (event) => {
    // @ts-ignore
    if (event.target?.value === getValues(valueKey)) setValue(valueKey, "");
  };

  const valueOptions = useMemo(
    () => (addOn.options || []).map((option) => option.entityId),
    [addOn],
  );

  useEffect(() => {}, [selectedValue]);

  return (
    <>
      <FormControl>
        <Controller
          control={control}
          name={optionKey}
          render={({ field: { value }, fieldState: { error }, formState }) => (
            <>
              {error !== undefined ? (
                <Typography className={"has-error"}>
                  {error.message}
                </Typography>
              ) : (
                <></>
              )}
              <RadioGroup
                value={selectedValue}
                defaultValue={valueOptions[0]}
                onClick={handleClick(optionKey)}
                unselectable={"on"}
              >
                {addOn.options.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    unselectable={"on"}
                    {...register(optionKey, {
                      required: {
                        value: addOn.isMandatory,
                        message: `You must select at least 1 option.`,
                      },
                      valueAsNumber: true,
                    })}
                    control={
                      <Radio
                        value={item.entityId}
                        checkedIcon={<CheckBoxCheckedIcon />}
                        icon={<CheckBoxIcon />}
                        unselectable={"on"}
                        checked={selectedValue == item.entityId}
                      />
                    }
                    label={<div className={"addon-item-name"}>{item.name}</div>}
                  ></FormControlLabel>
                ))}
              </RadioGroup>
            </>
          )}
        />
      </FormControl>
    </>
  );
}
