import { Switch } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";
import { subscriptionPeriodLabel } from "../data";

export const airconTableColumnConfig = ({
  allowedViewDetail,
  preferredOutletsMap,
  systemsMap,
}) => [
  {
    name: "Sub. No.",
    selector: (row) => row.airconSubscriptionNo,
    width: "120px",
    cell: (row, index, column, id) => {
      // if (allowedViewDetail) {
      //   return (
      //     <Link to={"/app/aircon-subscription/card/" + row._id}>
      //       {row.airconSubscriptionNo}
      //     </Link>
      //   );
      // } else {
      return <>{row.airconSubscriptionNo}</>;
      // }
    },
    sortable: true,
    sortField: "airconSubscriptionNo",
  },
  {
    name: "Sub. Date",
    wrap: true,
    width: "150px",
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    sortable: true,
    sortField: "createdAt",
  },
  {
    name: "Customer Name",
    wrap: true,
    width: "250px",
    selector: (row) => row.customerName,
    sortable: true,
    sortField: "customerName",
  },
  {
    name: "Email",
    width: "230px",
    wrap: true,
    selector: (row) => row.customerEmail,
    sortable: true,
    sortField: "customerEmail",
  },
  {
    name: "Mobile No.",
    width: "90px",
    selector: (row) => row.customerMobile,
    sortable: true,
    sortField: "customerMobile",
  },
  {
    name: "Sub. Period",
    wrap: true,
    width: "100px",
    selector: (row) => subscriptionPeriodLabel[row.subscriptionPeriod],
    sortable: true,
    sortField: "subscriptionPeriod",
  },
  {
    name: "Monthly Sub.",
    wrap: true,
    width: "100px",
    right: true,
    selector: (row) => {
      return convertThisToCurrency(row.monthlySubscription.toFixed(2));
    },
    sortable: true,
    sortField: "monthlySubscription",
  },
  {
    name: "Brand",
    width: "150px",
    wrap: true,
    selector: (row) => row.brandName,
    sortable: true,
    sortField: "brandName",
  },
  {
    name: "System",
    width: "150px",
    wrap: true,
    selector: (row) => systemsMap?.get(row.systemId),
    sortable: true,
    sortField: "systemId",
  },
  {
    name: "Description",
    width: "300px",
    wrap: true,
    selector: (row) => row.description,
    sortable: true,
    sortField: "description",
  },
  {
    name: "Combination",
    width: "300px",
    wrap: true,
    selector: (row) => row.combination,
    sortable: true,
    sortField: "combination",
  },
  {
    name: "Preferred Outlet",
    wrap: true,
    width: "300px",
    selector: (row) => preferredOutletsMap?.get(row.preferredOutletId),
    sortable: true,
    sortField: "preferredOutletId",
  },
  {
    name: "PDPA Consent",
    wrap: true,
    center: true,
    width: "110px",
    selector: (row) => (row.isPrivacyClause ? "YES" : "NO"),
    sortable: true,
    sortField: "isPrivacyClause",
  },
];
