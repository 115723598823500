import { withRouter } from "react-router-dom";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, InputAdornment, Autocomplete, IconButton
} from "@mui/material";
import { Search, KeyboardArrowDown, PlaylistPlay, ArrowBack } from "@mui/icons-material";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useEffect, useMemo, useState } from "react";
import { roleTableColumnConfig } from "./data-table/role-config";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import useStyles from "./styles";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const RoleList = (props) => {
    const classes = useStyles();
    // handle loading & http
    const { isLoading, httpRequestError: error, responseData, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    // role states
    const [filter, setFilter] = useState("");

    const [roleList, setRoleList] = useState([]);
    const [refreshRoleList, setRefreshRoleList] = useState(true);
    const [message, setMessage] = useState("");

    useEffect(() => { 
        document.title = "Role List"; 

        // redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.role, PrivilegeActions.view_list)) {
            props.history.push('/app/dashboard');
        }
    }, []);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: setRefreshRoleList(true)
            });
        }
    }, [message]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        let abortController = new AbortController();
        // fetch role list
        if (refreshRoleList) {
            sendRequest("/v1/role", "GET", {})
                .then(response => {
                    setRoleList(response.data?.roles || []);
                    setRefreshRoleList(false);
                    return () => {
                        abortController.abort();
                        return response.data?.roles;
                    };
                });
        }
    }, [refreshRoleList]);

    // set columns in data table
    const columns = roleTableColumnConfig(
        { 
            allowedViewDetail: auth.checkModulePrivilege(PrivilegeModules.role, PrivilegeActions.view_detail),
            allowedDelete: auth.checkModulePrivilege(PrivilegeModules.role, PrivilegeActions.delete)
        }, handleEditRole, handleDeleteRole
    );

    // set role data with filters
    const filteredData = roleList.filter(role => {
        let filterFlag = true;

        // search box
        if (filter) {
            const compoundText = role.code.toLowerCase() + role.name.toLowerCase() + (role.remark == null || role.remark.toLowerCase());
            filterFlag &= compoundText.includes(filter.toLowerCase());
        }

        return filterFlag;
    });

    // handle edit roles
    async function handleEditRole(role, toggle = false, toggleValue = false) {
        if (toggle) {
            role.status = toggleValue; // set new enable value & update
            await sendRequest(`/v1/role/${role._id}`, "PUT", RoleList);
        } else { props.history.push(`card/${role._id}`); }
    }

    // handle delete roles
    function handleDeleteRole(role) {
        if (role) {
            Swal.fire({
                title: `Confirmation`,
                text: `Are you sure you want to delete the role ${role.name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await sendRequest(`/v1/role/${role._id}`, "DELETE");
                    if (result?.status === 200 && result?.data?.deleted) {
                        setMessage(`Role ${role.name} has been deleted.`);
                    }
                }
            });
        }
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 1 }} open={isLoading}><CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>Role List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                {auth.checkModulePrivilege(PrivilegeModules.role, PrivilegeActions.add)
                    && <Button className={"primary"}
                        onClick={() => props.history.push('card')}>New</Button>}
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                            <TextField
                                autoComplete={"off"}
                                id={"role-searchbox"}
                                variant={"outlined"}
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={"start"}>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}
                                data={filteredData}
                                pagination
                                defaultSortFieldId={8}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
    );
};
export default withRouter(RoleList);