import React from "react";
import {
    Button, Grid, List, Box, Typography,
    ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow
} from "@mui/material";
import moment from "moment";
import './Styles.css';

export default function EventFeedbackDrawer({ feedback_summary, submissions, submission_details }) {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }} className="display">
            {feedback_summary && (<Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="star">5 Stars</TableCell>
                                    <TableCell className="star">4 Stars</TableCell>
                                    <TableCell className="star">3 Stars</TableCell>
                                    <TableCell className="star">2 Stars</TableCell>
                                    <TableCell className="star">1 Star</TableCell>
                                    <TableCell className="dish-name">Dish Name / Chef</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feedback_summary.map((row, i) => (
                                    <TableRow
                                        key={`${row.no}-${i}`}
                                        className="row_summary"
                                    >
                                        <TableCell className="star">{(row.star_5 != 0 ? row.star_5 : '-')} <span className="star-percent">{(row.star_5 != 0 ? `${row.star_5_ratio}%` : '')}</span></TableCell>
                                        <TableCell className="star">{(row.star_4 != 0 ? row.star_4 : '-')} <span className="star-percent">{(row.star_4 != 0 ? `${row.star_4_ratio}%` : '')}</span></TableCell>
                                        <TableCell className="star">{(row.star_3 != 0 ? row.star_3 : '-')} <span className="star-percent">{(row.star_3 != 0 ? `${row.star_3_ratio}%` : '')}</span></TableCell>
                                        <TableCell className="star">{(row.star_2 != 0 ? row.star_2 : '-')} <span className="star-percent">{(row.star_2 != 0 ? `${row.star_2_ratio}%` : '')}</span></TableCell>
                                        <TableCell className="star">{(row.star_1 != 0 ? row.star_1 : '-')} <span className="star-percent">{(row.star_1 != 0 ? `${row.star_1_ratio}%` : '')}</span></TableCell>
                                        <TableCell className="dish-name">{row.dishName} <span className="chef-name">{row.chefName} ({row.chefCode})</span></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>)
            }

            {submissions && (<Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    {submissions.map((row, i) => (
                        <>
                            <Grid item xs={12}>
                                <Typography variant={"h2"}>{moment(row.reported_at).format('DD/MM/YYYY HH:mm:ss')} - {row.guest.toUpperCase()}</Typography>
                                <Typography className="dish-comment">{(row.remark != 'N.A' ? row.remark : 'No comments')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="star">Star(s)</TableCell>
                                            <TableCell className="dish-name">Dish Name / Chef</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {submission_details.filter((s) => { return s.submission_id == row.submission_id }).map((rDetail, i) => (
                                            <TableRow key={`${rDetail.no}-${i}`}>
                                                <TableCell className="star">{rDetail.star}</TableCell>
                                                <TableCell className="dish-name">{rDetail.dishName} <span className="chef-name">{rDetail.chefName} ({rDetail.chefCode})</span></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Grid>)
            }
        </Grid ></>);
};
