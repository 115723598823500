import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, InputAdornment, IconButton
} from "@mui/material";
import {
    Search as SearchIcon,
    PlaylistPlay as FilterIcon, ArrowBack as BackIcon, Event as CalendarIcon, Clear as ClearIcon
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useLayoutDispatch, useLayoutState, toggleRightbar, setRighbarContent } from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import MobileFnbEventBookingFilters from "./components/Mobile/MobileFnbEventBookingFilters";
import { fnbEventBookingTableColumnConfig } from "./data-table/fnb-event-booking-table-column-config";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import EventFeedbackDrawer from "./components/EventFeedbackDrawer";
import DateFilter from "../../components/DateFilter";

const FnbEventBookingList = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    // layout state
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    // filters
    const [textFilter, setTextFilter] = useState("");
    const [sortBy, setSortBy] = useState({ column: undefined, direction: undefined });
    const [refresh, setRefresh] = useState(false); // refresh list

    const [eventBookingList, setEventBookingList] = useState([]);
    const smallDeviceWidth = 1200;

    // pagination states
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collectionSize, setCollectionSize] = useState(10);

    const [message, setMessage] = useState("");
    const [dateFilterFrom, setDateFilterFrom] = useState(new Date());
    const [dateFilterTo, setDateFilterTo] = useState(null);
    const mobileFilterProps = { dateFilterFrom, setDateFilterFrom, dateFilterTo, setDateFilterTo };
    const [loadMobileFilters, setLoadMobileFilters] = useState(false);
    const [fnbEventMenuSurveyBaseUrl, setFnbEventMenuSurveyBaseUrl] = useState(null);

    useEffect(() => {
        document.title = "F&B Event Booking List";
        loadPrerequisites();

        // redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.view_list)) {
            props.history.push('/app/dashboard');
        }
    }, []);

    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: setRefresh(true)
            });
        }
    }, [message]);

    // filter data
    useEffect(() => {
        if (refresh) {
            filter();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if (!textFilter) { 
        //     setRefresh(true); 
        // } 
        setRefresh(true);
    }, [textFilter, dateFilterFrom, dateFilterTo]);

    useEffect(() => {
        if (width <= smallDeviceWidth) { // set filter right bar
            setRighbarContent(layoutDispatch,
                [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
                <MobileFnbEventBookingFilters key={"rb-body"} {...mobileFilterProps} />],
                { size: 'xs', backButton: true }); // filter content
        }
    }, [eventBookingList, dateFilterFrom, dateFilterTo, width, loadMobileFilters]);

    // set columns in data table
    const columns = fnbEventBookingTableColumnConfig(
        {
            allowedViewDetail: auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.view_detail),
            allowedDelete: auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.delete),
            allowedEnableDisable: auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.enable_disable),
            allowedCheckFeedback: auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.check_feedback),
            allowedCheckBill: auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.check_bill),
        },
        handleNameClick,
        handleDeleteFnbEventBooking,
        handleEditFnbEventBooking,
        showFeedback,
        copySurveyUrl
    );

    const loadPrerequisites = () => {
        sendRequest(`/v1/fnb-event-booking/get-prerequisites`, "GET", {})
            .then(response => {
                let prerequisites = response.data.prerequisites;
                setFnbEventMenuSurveyBaseUrl(prerequisites.fnbEventMenuSurveyBaseUrl);
            });
    }    

    async function copySurveyUrl(eventBookingId) {
        const url = `${fnbEventMenuSurveyBaseUrl}/${eventBookingId}`;

        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard:', url);
        })
        .catch((error) => {
            console.error('Failed to copy URL to clipboard:', error);
        });
    }

    async function showFeedback(eventBookingNo) {
        setLoadMobileFilters(false);

        let feedbacks;
        await sendRequest(`/v1/fnb-event-booking/get-feedback/${eventBookingNo}`, "GET", {})
            .then(response => {
                feedbacks = response.data;
            });

        const size = width <= smallDeviceWidth ? 'xs' : 'lg';
        setRighbarContent(layoutDispatch,
            [<EventFeedbackDrawer key={'rb-body'}
                feedback_summary={feedbacks.feedback_summary}
                submissions={feedbacks.submissions}
                submission_details={feedbacks.submission_details}
            />],
            { size, backButton: true, showBackDropInLargeScreen: false });

        if (!layoutState.isRightbarOpened) {
            toggleRightbar(layoutDispatch);
        }
    }

    async function handleEditFnbEventBooking(booking, toggle = false, toggleValue = false) {
        if (toggle) {
            booking.enabled = toggleValue; // set new enable value & update
            let tmpBooking = JSON.parse(JSON.stringify(booking));
            await sendRequest(`/v1/fnb-event-booking/${booking._id}`, "PUT", tmpBooking);
        } else {
            props.history.push(`card/${booking._id}`);
        }
    }

    function handleDeleteFnbEventBooking(eventBooking) {
        if (eventBooking) {
            Swal.fire({
                title: `Confirmation`,
                text: `Are you sure you want to delete the F&B event booking ${eventBooking.eventName}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await sendRequest(`/v1/fnb-event-booking/${eventBooking._id}`, "DELETE");
                    if (result?.status === 200 && result?.data?.deleted) {
                        setMessage(`F&B event booking ${eventBooking.eventName} has been deleted.`);
                    }
                }
            });
        }
    }

    function filter() {
        // set list filters
        getPaginatedData(page, pageSize, getFilterValues(), sortBy)
            .then(response => {
                const eventBookings = response.data?.eventBookings || [];
                setEventBookingList(eventBookings);
                setCollectionSize(response.data?.total);
                return () => {
                    return eventBookings;
                };
            });
    }

    function getFilterValues() {
        return {
            text: textFilter.trim(),
            fromDate: dateFilterFrom ? getFromDate(dateFilterFrom) : undefined,
            toDate: dateFilterTo ? getToDate(dateFilterTo) : undefined,
        };
    }

    function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
        return sendRequest(
            `/v1/fnb-event-booking/filter?filters=${JSON.stringify(filters)}&page=${page}&pageSize=${pageSize}
        ${(sortBy?.column && sortBy?.direction) ?
                ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {});
    }

    function sortByColumn(columnConfig, sortDirection) {
        if (columnConfig.sortField && sortDirection) {
            setSortBy({
                column: columnConfig.sortField,
                direction: sortDirection
            });
            setRefresh(true);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value) { // perform search when Enter
            e.preventDefault();
            setRefresh(true);
        }
    };

    function handlePageChanged(page) {
        setPage(page);
        setRefresh(true);
    }

    function handleRowsPerPageChanged(count) {
        setPageSize(count);
        setRefresh(true);
    }

    // go to edit view
    async function handleNameClick(eventBookingId) {
        if (auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.view_detail)) {
            props.history.push(`card/${eventBookingId}`);
        }
    }

    // open mobile filters drawer
    function openMobileFilters() {
        setLoadMobileFilters(true);
        toggleRightbar(layoutDispatch);
    }

    function openFnbCalendear() {
        window.open('#/fnb-event-booking-calendar', 'F&B Calendar');
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>F&B Event Booking List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                {(auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.add)) && <Button className={"primary"}
                    onClick={() => props.history.push('card')}>New</Button>}

                {(auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.fnb_google_calendar)) && <Button className={"primary"}
                    onClick={() => openFnbCalendear()}>Calendar</Button>}
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                            <TextField
                                autoComplete={"off"}
                                id={"text-searchbox"}
                                variant={"outlined"}
                                value={textFilter}
                                onChange={(e) => setTextFilter(e.target.value)}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={"start"}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }} />
                        </Grid>
                        {
                            width >= smallDeviceWidth ? (<>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                  <DateFilter
                                    label={"From"}
                                    value={dateFilterFrom}
                                    maxDate={dateFilterTo}
                                    onChange={(newValue) => setDateFilterFrom(newValue)}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                  <DateFilter
                                    label={"To"}
                                    value={dateFilterTo}
                                    minDate={dateFilterFrom}
                                    onChange={(newValue) => setDateFilterTo(newValue)}
                                  />
                                </Grid>

                            </>) :
                                (<Grid item xs={2}>
                                    <IconButton
                                        onClick={() => openMobileFilters()}
                                        className={classes.drawerToggleBtn}    >
                                        {layoutState.isRightbarOpened ? (<BackIcon />)
                                            : (<FilterIcon className={classes.toggleRightBarIcon} />)}
                                    </IconButton>
                                </Grid>)
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}
                                data={eventBookingList}
                                sortServer
                                onSort={sortByColumn}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationPerPage={pageSize}
                                paginationTotalRows={collectionSize}
                                onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
                                onChangePage={(page) => handlePageChanged(page)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default withRouter(FnbEventBookingList);
