import moment from "moment";
import { Typography } from "@mui/material";
import { useState } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import TouchAppIcon from "@mui/icons-material/TouchApp";

const ShowAndHideText = ({ value }) => {
  const [showCode, setShowCode] = useState(false);
  return (
    <Typography
      onClick={() => setShowCode(!showCode)}
      sx={{ cursor: "pointer", WebkitTextSecurity: showCode ? "none" : "disc" }}
    >
      {value}
    </Typography>
  );
};

export const columns = () => [
  {
    name: "Code",
    sortable: true,
    selector: (row) => <ShowAndHideText value={row.code} />,
    sortField: "code",
    width: "100px",
  },
  {
    name: "Times Used",
    selector: (row) => row.timesUsed,
    sortField: "timesUsed",
    sortable: true,
    right: true,
    width: "90px",
  },
  {
    name: "Usage Limit",
    selector: (row) => row.usageLimit,
    sortField: "usageLimit",
    sortable: true,
    right: true,
    width: "90px",
  },
  {
    name: "Creation Date",
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    sortField: "createdAt",
    sortable: true,
    width: "140px",
  },
  {
    name: "Expiry Date",
    selector: (row) =>
      !row.expirationDate
        ? "Non-Expire"
        : moment(row.expirationDate).format("DD/MM/YYYY"),
    sortable: true,
    sortField: "expirationDate",
    width: "100px",
  },
  {
    name: "Manual Generated",
    selector: (row) => (row.isManualGenerated ? "YES" : "NO"),
    sortField: "isManualGenerated",
    sortable: true,
    center: true,
    width: "120px",
  },
  {
    name: "Enable",
    selector: (row) => (row.status ? "YES" : "NO"),
    sortField: "status",
    sortable: true,
    center: true,
    width: "80px",
  },
  {
    name: "Locked",
    selector: (row) =>
      row.lockUntil === null || moment(row.lockUntil) < moment() ? "NO" : "YES",
    sortField: "lockUntil",
    sortable: true,
    center: true,
    width: "80px",
  },
];
