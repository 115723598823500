import React, { useState } from "react";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Clear, Event, KeyboardArrowDown } from '@mui/icons-material';
import DateFilter from "../../../../components/DateFilter";

export default function MobileClaimsFilters({
    teamList,
    statusList,
    postedList,
    teamFilter,
    statusFilter,
    invoicePostedFilter,
    claimDateFrom,
    claimDateTo,
    setTeamFilter,
    setStatusFilter,
    setInvoicePostedFilter,
    setClaimDateFrom,
    setClaimDateTo,
    downloadedFilter,
    setDownloadedFilter,
    calculatedFilter,
    setCalculatedFilter,
    allowedSeeMoreColumns
}) {
    const [fromOpen, setFromOpen] = useState(false);
    const [toOpen, setToOpen] = useState(false);

    const toggleFromOpen = () => setFromOpen(!fromOpen);
    const toggleToOpen = () => setToOpen(!toOpen);

    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"team-filter-mobile"}
                    options={teamList}
                    getOptionLabel={(option) => `${option.username}-${option.name}`}
                    value={teamFilter}
                    onChange={(e, newValue) => setTeamFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Team"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"status-filter-mobile"}
                    options={statusList}
                    getOptionLabel={(option) => option}
                    value={statusFilter}
                    onChange={(e, newValue) => setStatusFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Status"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            {allowedSeeMoreColumns && (<><Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"posted-filter-mobile"}
                    options={postedList}
                    getOptionLabel={(option) => option?.toUpperCase()}
                    value={invoicePostedFilter}
                    onChange={(e, newValue) => setInvoicePostedFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Posted"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                        id={"downloaded-filter-mobile"}
                        options={postedList}
                        getOptionLabel={(option) => option?.toUpperCase()}
                        value={downloadedFilter}
                        onChange={(e, newValue) => setDownloadedFilter(newValue)}
                        renderInput={(params) => <TextField {...params} label={"Downloaded"} variant={"outlined"} />}
                        popupIcon={<KeyboardArrowDown />}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                        id={"calculated-filter-mobile"}
                        options={postedList}
                        getOptionLabel={(option) => option?.toUpperCase()}
                        value={calculatedFilter}
                        onChange={(e, newValue) => setCalculatedFilter(newValue)}
                        renderInput={(params) =>
                            <TextField {...params} label={"Calculated"} variant={"outlined"} />}
                        popupIcon={<KeyboardArrowDown />}
                    />
                </Grid> */}
            </>)}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateFilter
                label="Claim Date - From"
                value={claimDateFrom}
                onChange={(newValue) => setClaimDateFrom(newValue)}
                maxDate={claimDateTo}
                onClose={toggleFromOpen}
                onOpen={toggleFromOpen}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateFilter
                label="Claim Date - To"
                value={claimDateTo}
                onChange={(newValue) => setClaimDateTo(newValue)}
                minDate={claimDateFrom}
                onClose={toggleToOpen}
                onOpen={toggleToOpen}
              />
            </Grid>
        </Grid>
    </>);
};
