import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  InputAdornment,
  Autocomplete,
  IconButton,
} from "@mui/material";
import {
  Search as SearchIcon,
  KeyboardArrowDown as ArrowDownIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import useStyles from "./styles";
import { activityLogTableColumnConfig } from "./data-table/activity-log-table-column-config";
import MobileActivityLogFilters from "./components/Mobile/MobileActivityLogFilters";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DateFilter from "../../components/DateFilter";
import Swal from "sweetalert2";
import ActivityLogDrawer from "./components/Drawer/ActivityLogDrawer";

// -------------------------------------------

const ActivityLogList = (props) => {
  const classes = useStyles();

  const {
    isLoading,
    sendRequest,
    httpRequestError: error,
    responseData,
  } = useHttpRequest();
  const { auth } = useRoleAuthorization();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  const [textFilter, setTextFilter] = useState("");
  const [fromFilter, setFromFilter] = useState(new Date());
  const [toFilter, setToFilter] = useState(null);
  const [moduleFilter, setModuleFilter] = useState(null);
  const [systemModules, setSystemModules] = useState([]);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });

  const [refresh, setRefresh] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  const mobileFilterProps = {
    fromFilter,
    setFromFilter,
    toFilter,
    setToFilter,
    systemModules,
    moduleFilter,
    setModuleFilter,
  };
  const smallDeviceWidth = 1200;

  const columns = activityLogTableColumnConfig({ openActivityLogDrawer });

  // -------------------------------------------

  function filter() {
    const filters = {
      text: textFilter.trim(),
      from: fromFilter ? getFromDate(fromFilter) : undefined,
      to: toFilter ? getToDate(toFilter) : undefined,
      module: moduleFilter,
    };
    sendRequest(
      `/v1/activity-log/filter?filters=${JSON.stringify(filters)}
          &page=${page}&pageSize=${pageSize}
          ${
            sortBy?.column && sortBy?.direction
              ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
              : ""
          }`,
      "GET",
      {},
    ).then((response) => {
      const logs = response.data?.logs || [];
      setFilteredData(logs);
      setCollectionSize(response.data?.total);
      return () => {
        return logs;
      };
    });
  }

  function openActivityLogDrawer(activityLog) {
    loadActivityContentDrawer(activityLog, true, false);
    if (!layoutState.isRightbarOpened) {
      toggleRightbar(layoutDispatch);
    }
  }

  function loadActivityContentDrawer(
    activityLog,
    backButton,
    showBackDropInLargeScreen,
  ) {
    const size = width <= smallDeviceWidth ? "xs" : "lg";
    setRighbarContent(
      layoutDispatch,
      [<ActivityLogDrawer key={"rb-body"} activityLog={activityLog} />],
      { size, backButton, showBackDropInLargeScreen },
    );
  }

  function openFilterDrawer() {
    loadFilterContentDrawer();
    if (!layoutState.isRightbarOpened) {
      toggleRightbar(layoutDispatch);
    }
  }

  function loadFilterContentDrawer() {
    setRighbarContent(
      layoutDispatch,
      [
        <Typography variant={"h2"} key={"rb-header"}>
          Filter
        </Typography>,
        <MobileActivityLogFilters key={"rb-body"} {...mobileFilterProps} />,
      ],
      { size: "xs", backButton: true },
    );
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" && e.target.value) {
      e.preventDefault();
      setRefresh(true);
    }
  }

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function initLoadData() {
    sendRequest(`/v1/activity-log/system-module/list`, "GET", {}).then(
      (response) => {
        const modules = response.data?.modules || [];
        setSystemModules(modules.sort());
      },
    );
  }

  // -------------------------------------------

  useEffect(() => {
    document.title = "Activity Log List";

    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.activity_log,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }

    initLoadData();
  }, []);

  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setRefresh(true);
  }, [fromFilter, toFilter, moduleFilter]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    if (width <= smallDeviceWidth) {
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>,
          <MobileActivityLogFilters key={"rb-body"} {...mobileFilterProps} />,
        ],
        { size: "xs", backButton: true },
      );
    }
  }, [fromFilter, toFilter, moduleFilter, systemModules, width]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // -------------------------------------------

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Activity Log List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={10} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-searchbox"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {width >= smallDeviceWidth ? (
                <>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <DateFilter
                      label={"From"}
                      value={fromFilter}
                      maxDate={toFilter}
                      onChange={(newValue) => setFromFilter(newValue)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <DateFilter
                      label={"To"}
                      value={toFilter}
                      minDate={fromFilter}
                      onChange={(newValue) => setToFilter(newValue)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"module-filter"}
                      options={systemModules}
                      value={moduleFilter}
                      onChange={(e, newValue) => setModuleFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Module"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<ArrowDownIcon />}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={openFilterDrawer}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <BackIcon />
                    ) : (
                      <FilterIcon className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={filteredData}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// -------------------------------------------

export default withRouter(ActivityLogList);
