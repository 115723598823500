import { convertThisToCurrency } from "../../helper";
import { Link } from "react-router-dom";
import moment from "moment";

export const columns = [
  {
    name: "Order No.",
    selector: (row) => (
      <Link
        to={`/app/fnb-supplier-order/${row.orderNumber}`}
        title={row.orderNumber}
      >
        {row.orderNumber}
      </Link>
    ),
    sortable: true,
    sortField: "orderNumber",
  },
  {
    name: "Store",
    selector: (row) => `${row.deliveryTo.storeCode}`,
    width: "80px",
    wrap: true,
    sortable: true,
    sortField: "deliveryTo.storeCode",
  },
  {
    name: "Supplier",
    selector: (row) => `${row.supplier.name}-${row.supplier.vendorCode}`,
    width: "350px",
    wrap: true,
    sortable: true,
    sortField: "supplier.name",
  },
  {
    name: "Requestor Contact Name",
    selector: (row) => row.contactName,
    width: "250px",
    wrap: true,
    sortable: true,
    sortField: "contactName",
  },
  {
    name: "Requestor Contact No.",
    selector: (row) => row.contactNumber,
    width: "150px",
    sortable: true,
    sortField: "contactNumber",
  },
  {
    name: "Status",
    selector: (row) => {
      switch (row.status) {
        case "SENT_TO_SUPPLIER":
          return "SUBMITTED";
        case "RECEIVED_GOODS":
          return "RECEIVED";
        default:
          return "PENDING";
      }
    },
    width: "100px",
    sortable: true,
    sortField: "status",
  },
  {
    name: "Estimated Total Amount",
    selector: (row) => convertThisToCurrency(+row.grandTotal),
    width: "150px",
    right: true,
    sortable: true,
    sortField: "grandTotal",
  },
  {
    name: "Expected Delivery Date",
    selector: (row) => moment(row.deliveryDate).format("DD/MM/YYYY"),
    width: "150px",
    sortable: true,
    sortField: "deliveryDate",
  },
  {
    name: "Created By",
    selector: (row) => `${row?.createdBy?.name}-${row?.createdBy?.username}`,
    width: "350px",
    wrap: true,
  },
];
