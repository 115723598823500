import { useRef, useState } from "react";
import { dataURItoBlob } from "../helper";

export default function useSignature() {
  const sigCanvas = useRef();
  const [isSignChange, setSignChange] = useState(false);
  const [isSignEmpty, setSignEmpty] = useState(true);

  function onChangeSign() {
    setSignEmpty(sigCanvas.current.isEmpty());
    setSignChange(true);
  }

  function getFileFromSign(fileName = "signature.png") {
    const URL = sigCanvas.current.getCanvas().toDataURL("image/png");
    const blob = dataURItoBlob(URL);
    const file = new File([blob], fileName, { type: "image/png" });
    return file;
  }

  function getBase64FromSign() {
    return isSignEmpty
      ? ""
      : sigCanvas.current.getCanvas().toDataURL("image/png");
  }

  function onClear() {
    sigCanvas.current.clear();
    setSignEmpty(true);
    setSignChange(true);
  }
  return {
    sigCanvas,
    isSignChange,
    isSignEmpty,
    setSignChange,
    setSignEmpty,
    onChangeSign,
    getFileFromSign,
    onClear,
    getBase64FromSign,
  };
}
