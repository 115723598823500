import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  Button,
  IconButton,
  TextField,
  Stack,
  Divider,
} from "@mui/material";
import Swal from "sweetalert2";
import "./style/index.css";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DataTable from "react-data-table-component";
import { Add } from "@mui/icons-material";
import { attributeOptionTableColumnConfig } from "./data-table/attribute-option-table-column-config";
import _ from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { EditorController } from "../../components/EditorController";
import TextFieldController from "../../components/TextFieldController";
import { emailContentList, messageList } from "./data";

const Attribute = (props) => {
  // user info & permissions
  const { auth } = useRoleAuthorization();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  const [currentAttributeItem, setCurrentAttributeItem] = useState(); // current attribute object for edit
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [keyCollectionPeriodList, setKeyCollectionPeriodList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [paxNumberList, setPaxNumberList] = useState([]);
  const methods = useForm({ mode: "all", defaultValues: currentAttributeItem });
  const [groupBuyEvents, setGroupBuyEvents] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setValue,
    control,
    reset,
  } = methods;
  // form submit
  const handleSubmitForm = async (data) => {
    try {
      if (
        checkInvalidList(propertyTypeList) ||
        checkInvalidList(keyCollectionPeriodList) ||
        checkInvalidList(paxNumberList)
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Property Type List missing data",
        });
        return;
      }

      const payload = {
        messageGroup: groupBuyEvents.map((event) => ({
          id: event.id,
          messageContents: messageList.map((message) => ({
            id: message.id,
            title: data[event.id]["messageContents"][message.id].title,
            message: data[event.id]["messageContents"][message.id].message,
          })),
          emailContents: emailContentList.map((item) => ({
            id: item.id,
            content: data[event.id]["emailContents"][item.id].content,
            subject: data[event.id]["emailContents"][item.id].subject,
          })),
        })),
        propertyType: propertyTypeList.map((item) => ({
          ...item,
          id: _.kebabCase(item.option),
        })),
        keyCollectionPeriod: keyCollectionPeriodList.map((item) => ({
          ...item,
          id: _.kebabCase(item.option),
        })),
        paxNumber: paxNumberList.map((item) => ({
          ...item,
          id: _.kebabCase(item.option),
        })),
        productType: productTypeList.map((item) => ({
          ...item,
          id: _.kebabCase(item.option),
        })),
        tncClause: data.tncClause,
        pdpaClause: data.pdpaClause,
      };

      await sendRequest(`/v1/group-buy-attribute/attribute`, "PUT", payload);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Group buy attributes has been updated.",
      });

      return;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There're something error",
      });
      return;
    }
  };

  const onRemoveRow = (list, setList, i) => {
    const rows = [...list];
    rows.splice(i, 1);
    setList([...rows]);
  };

  const onChangeItem = (list, fieldName, i, value, setList) => {
    const copyList = [...list];
    copyList[i][fieldName] = value;
    setList(copyList);
  };

  const createNewRow = (list, setList) => {
    setList([
      ...list,
      {
        option: "",
        enabled: true,
      },
    ]);
  };

  const checkInvalidRow = (row) => {
    if (!row.option) {
      return true;
    }
    return false;
  };

  const checkInvalidList = (list = []) => {
    // Check propertyType data
    const invalidList = list.find((propertyType) =>
      checkInvalidRow(propertyType),
    );
    if (invalidList) {
      return true;
    }
  };

  const checkInvalidLastRow = (list) => {
    const lastList = [...list].pop();
    if (list.length && checkInvalidRow(lastList)) {
      return true;
    }
    return false;
  };

  const propertyTypeColumns = attributeOptionTableColumnConfig({
    onRemoveRow,
    onChangeItem,
    list: propertyTypeList,
    setList: setPropertyTypeList,
  });

  const keyCollectionPeriodColumns = attributeOptionTableColumnConfig({
    onRemoveRow,
    onChangeItem,
    list: keyCollectionPeriodList,
    setList: setKeyCollectionPeriodList,
  });

  const productTypeColumns = attributeOptionTableColumnConfig({
    onRemoveRow,
    onChangeItem,
    list: productTypeList,
    setList: setProductTypeList,
  });

  const paxNumberColumns = attributeOptionTableColumnConfig({
    onRemoveRow,
    onChangeItem,
    list: paxNumberList,
    setList: setPaxNumberList,
  });

  const fetchAttribute = async (id) => {
    try {
      const { data } = await sendRequest(
        `/v1/group-buy-attribute/attribute`,
        "GET",
      );
      setCurrentAttributeItem(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There're something error",
      });
      return;
    }
  };

  const fetchEvents = async () => {
    const { data } = await sendRequest(`/v1/group-buy-event`, "GET");
    setGroupBuyEvents(data);
  };

  // set title when adding attribute
  useEffect(() => {
    document.title = "Group Buy Attribute";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.group_buy_attributes,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    if (currentAttributeItem) {
      setPropertyTypeList(currentAttributeItem.propertyType);
      setKeyCollectionPeriodList(currentAttributeItem.keyCollectionPeriod);
      setProductTypeList(currentAttributeItem.productType);
      setPaxNumberList(currentAttributeItem.paxNumber);
      reset({
        ...currentAttributeItem?.messageGroup,
        tncClause: currentAttributeItem?.tncClause,
        pdpaClause: currentAttributeItem?.pdpaClause,
      });
    }
  }, [currentAttributeItem]);

  useEffect(() => {
    fetchEvents();
    fetchAttribute();
  }, []);

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {/* Title */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>Group Buy Attributes</Typography>
            </Grid>

            {/* Action */}
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              {auth.checkModulePrivilege(
                PrivilegeModules.group_buy_attributes,
                PrivilegeActions.edit,
              ) && (
                  <Button
                    className={"primary"}
                    type="submit"
                    disabled={
                      checkInvalidList(propertyTypeList) ||
                      checkInvalidList(keyCollectionPeriodList) ||
                      checkInvalidList(paxNumberList)
                    }
                  >
                    Save
                  </Button>
                )}
            </Grid>

            {/* General */}
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12}>
                          <Typography variant={"h2"}>General</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={12} sm={6}>
                              <TextFieldController
                                name={`tncClause`}
                                label={`TNC Clause`}
                                multiline
                                minRows={3}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextFieldController
                                name={`pdpaClause`}
                                label={`PDPA Clause`}
                                multiline
                                minRows={3}
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Message */}
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    {groupBuyEvents.map((event) => (
                      <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            <Typography
                              variant={"h2"}
                            >{`Message (${event.id})`}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                              {messageList.map((message, index) => (
                                <Grid item xs={12} sm={6} lg={3}>
                                  <Stack gap={{ xs: 2, md: 3 }}>
                                    <TextFieldController
                                      name={`${event.id}.messageContents.${message.id}.title`}
                                      label={`${message.label.title}`}
                                      required
                                    />
                                    <TextFieldController
                                      name={`${event.id}.messageContents.${message.id}.message`}
                                      label={`${message.label.message}`}
                                      multiline
                                      minRows={4}
                                      required
                                    />
                                  </Stack>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Email content */}
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    {groupBuyEvents.map((event, index) => (
                      <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            <Typography
                              variant={"h2"}
                            >{`Email (${event.id})`}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                              {emailContentList.map((item) => (
                                <Grid item xs={12} md={6}>
                                  <Stack gap={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12}>
                                      <TextFieldController
                                        name={`${event.id}.emailContents.${item.id}.subject`}
                                        label={`${item.label} Subject`}
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <EditorController
                                        control={control}
                                        label={item.label}
                                        required={true}
                                        name={`${event.id}.emailContents.${item.id}.content`}
                                      />
                                    </Grid>
                                  </Stack>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Property type */}
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} className={"sub-action"}>
                      <Typography variant={"h2"}>Property Type</Typography>
                    </Grid>
                    <Grid item xs={12} className={"table"}>
                      <DataTable
                        fixedHeader={true}
                        persistTableHead={true}
                        columns={propertyTypeColumns}
                        data={propertyTypeList}
                      />
                    </Grid>
                    <Grid item xs={12} className={"table-action"}>
                      <IconButton
                        onClick={(e) =>
                          createNewRow(propertyTypeList, setPropertyTypeList)
                        }
                        disabled={checkInvalidLastRow(propertyTypeList)}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Key period */}
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} className={"sub-action"}>
                      <Typography variant={"h2"}>
                        Key Collection Period
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={"table"}>
                      <DataTable
                        fixedHeader={true}
                        persistTableHead={true}
                        columns={keyCollectionPeriodColumns}
                        data={keyCollectionPeriodList}
                      />
                    </Grid>
                    <Grid item xs={12} className={"table-action"}>
                      <IconButton
                        onClick={(e) =>
                          createNewRow(
                            keyCollectionPeriodList,
                            setKeyCollectionPeriodList,
                          )
                        }
                        disabled={checkInvalidLastRow(keyCollectionPeriodList)}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* PAX Number */}
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} className={"sub-action"}>
                      <Typography variant={"h2"}>No. of PAX</Typography>
                    </Grid>
                    <Grid item xs={12} className={"table"}>
                      <DataTable
                        fixedHeader={true}
                        persistTableHead={true}
                        columns={paxNumberColumns}
                        data={paxNumberList}
                      />
                    </Grid>
                    <Grid item xs={12} className={"table-action"}>
                      <IconButton
                        onClick={(e) =>
                          createNewRow(paxNumberList, setPaxNumberList)
                        }
                        disabled={checkInvalidLastRow(paxNumberList)}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Product Type */}
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} className={"sub-action"}>
                      <Typography variant={"h2"}>Product Type</Typography>
                    </Grid>
                    <Grid item xs={12} className={"table"}>
                      <DataTable
                        fixedHeader={true}
                        persistTableHead={true}
                        columns={productTypeColumns}
                        data={productTypeList}
                      />
                    </Grid>
                    <Grid item xs={12} className={"table-action"}>
                      <IconButton
                        onClick={(e) =>
                          createNewRow(productTypeList, setProductTypeList)
                        }
                        disabled={checkInvalidLastRow(productTypeList)}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default Attribute;
