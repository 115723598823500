import React from "react";
import {
  Grid,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Search as SearchIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  KeyboardArrowDown,
  Event as CalendarIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import DateFilter from "../../../../components/DateFilter";
import { useState } from "react";

export default function MobileMachineServiceJobFilters({
  companyFilter,
  setCompanyFilter,
  companyList,
  storeList,
  storeFilter,
  setStoreFilter,
  areaList,
  areaFilter,
  setAreaFilter,
  statusList,
  statusFilter,
  setStatusFilter,
  serviceDateFrom,
  setServiceDateFrom,
  serviceDateTo,
  setServiceDateTo,
}) {
  const [fromOpen, setFromOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);

  const toggleFromOpen = () => setFromOpen(!fromOpen);
  const toggleToOpen = () => setToOpen(!toOpen);

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Autocomplete
            id={"company-filter"}
            options={companyList}
            getOptionLabel={(company) => `${company.name.toUpperCase()}`}
            value={companyFilter}
            onChange={(e, newValue) => setCompanyFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Company"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id={"store-filter"}
            options={storeList}
            getOptionLabel={(store) => `${store.storeId}`}
            value={storeFilter}
            onChange={(e, newValue) => setStoreFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Store ID"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id={"area-filter"}
            options={areaList}
            getOptionLabel={(area) => `${area.areaCode.toUpperCase()}`}
            value={areaFilter}
            onChange={(e, newValue) => setAreaFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Area"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"status-filter"}
            options={statusList}
            getOptionLabel={(status) => `${status.description}`}
            value={statusFilter}
            onChange={(e, newValue) => setStatusFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Status"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            label="Service Date - From"
            value={serviceDateFrom}
            onChange={(newValue) => setServiceDateFrom(newValue)}
            maxDate={serviceDateTo}
            onOpen={toggleFromOpen}
            onClose={toggleFromOpen}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            label="Service Date - To"
            value={serviceDateTo}
            onChange={(newValue) => setServiceDateTo(newValue)}
            minDate={serviceDateFrom}
            onOpen={toggleToOpen}
            onClose={toggleToOpen}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"status-filter"}
            options={statusList}
            getOptionLabel={(status) => `${status.description}`}
            value={statusFilter}
            onChange={(e, newValue) => setStatusFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Status"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
      </Grid>
    </>
  );
}
