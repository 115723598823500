import React, { useState } from "react";
import { FormControlLabel, Grid, IconButton, InputAdornment, Switch, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Clear, Event, KeyboardArrowDown } from '@mui/icons-material';
import useStyles from "../../styles";
import DateFilter from "../../../../components/DateFilter";

export default function MobileRequestFilters({
  installerList,
  staffList,
  statusList,
  installerFilter,
  staffFilter,
  setInstallerFilter,
  setStaffFilter,
  statusFilter,
  setStatusFilter,
  projectFilter,
  setProjectFilter,
  projectFilterOptions,
  fromRequestDate,
  toRequestDate,
  setFromRequestDate,
  setToRequestDate,
  companyList,
  companyFilter,
  setCompanyFilter,
}) {
  const classes = useStyles();

  const [fromOpen, setFromOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);

  const toggleFromOpen = () => setFromOpen(!fromOpen);
  const toggleToOpen = () => setToOpen(!toOpen);

  return (<>
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          id={"installer-filter-mobile"}
          options={installerList}
          getOptionLabel={(option) => `${option.username}-${option.name}`}
          value={installerFilter}
          onChange={(e, newValue) => setInstallerFilter(newValue)}
          renderInput={(params) => <TextField {...params} label={"Installer"} variant={"outlined"} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          id={"staff-filter-mobile"}
          options={staffList}
          getOptionLabel={(option) => `${option.username}-${option.name}`}
          value={staffFilter}
          onChange={(e, newValue) => setStaffFilter(newValue)}
          renderInput={(params) => <TextField {...params} label={"Staff"} variant={"outlined"} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          id={"company-filter-mobile"}
          options={companyList}
          getOptionLabel={(option) => `${option.username}-${option.name}`}
          value={companyFilter}
          onChange={(e, newValue) => setCompanyFilter(newValue)}
          renderInput={(params) => <TextField {...params} label={"Company"} variant={"outlined"} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          id={"status-filter-mobile"}
          value={statusFilter}
          onChange={(e, newValue) => setStatusFilter(newValue)}
          options={statusList}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} label={"Status"} variant={"outlined"} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          id={"project-filter-mobile"}
          value={projectFilter}
          onChange={(e, newValue) => setProjectFilter(newValue)}
          options={projectFilterOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} label={"Status"} variant={"outlined"} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DateFilter
          label="From"
          value={fromRequestDate}
          onChange={(newValue) => setFromRequestDate(newValue)}
          maxDate={toRequestDate}
          onOpen={toggleFromOpen}
          onClose={toggleFromOpen}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DateFilter
          label="To"
          value={toRequestDate}
          onChange={(newValue) => setToRequestDate(newValue)}
          minDate={fromRequestDate}
          onOpen={toggleToOpen}
          onClose={toggleToOpen}
        />
      </Grid>
    </Grid>
  </>);
};
