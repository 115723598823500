
import { DeleteOutline, KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, InputBase, Input, Autocomplete, TextField } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import './../Styles.css';

export const resourceTableColumnConfig = (
    otherResourceItemsList,
    onRemoveRow,
    onChangeResourceCode,
    onChangeIsPercentageCheck,
    handleQtyChange,
    onKeyDownClaimValue,
    disableForm) => [
        {
            name: "",
            width: "50px",
            button: true,
            cell: (row, index) => <IconButton disabled={!!disableForm} onClick={() => onRemoveRow(index, row)}><DeleteOutline />
            </IconButton>
        },
        {
            name: "Item",
            width: "400px",
            cell: (row, index) => {
                return <Autocomplete disabled={!!disableForm}
                    id={"item"}
                    options={otherResourceItemsList}
                    getOptionLabel={(option) => option.hasOwnProperty('resourceCode') ? option.resourceCode : option}
                    value={row.code}
                    onChange={(e, newValue) => onChangeResourceCode(index, newValue)}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    sx={{ width: 400 }}
                    popupIcon={<ArrowDownIcon />}
                />
            }
        },
        // remove the below comment if user will be able to change the qty
        // {
        //     type: "input",
        //     name: "Qty.",
        //     width: "100px",
        //     center: true,
        //     cell: (row, index) => {
        //         return <InputBase disabled={!!disableForm}
        //             type={"number"}
        //             value={row.qty}
        //             onChange={(e) => handleQtyChange(index, e.target.value)}
        //             startAdornment={
        //                 !disableForm && <IconButton disabled={!!disableForm} onClick={(e) => handleQtyChange(index, Math.max(0, row.qty - 1))}>
        //                     <RemoveIcon/>
        //                 </IconButton>
        //             }
        //             endAdornment={
        //                 !disableForm && <IconButton disabled={!!disableForm} onClick={(e) => handleQtyChange(index, row.qty + 1)}>
        //                     <AddIcon/>
        //                 </IconButton>
        //             } />
        //     }
        // },
    ];
