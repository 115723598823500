import { Grid, IconButton, Slider, Typography } from "@mui/material";
import { PrivilegeActions } from "../../../../data/privileges.enum";
import { PR_PANELS } from "../../helper/panels";
import DataTable from "react-data-table-component";
import { progressTableConfig } from "../../data-table/progress-table-config";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { useEffect } from "react";
import { getPercentages, percentageSteps } from "../../helper/pr-config";

const WorkflowProgress = (props) => {
    const [lastRowValid, setLastRowValid] = useState(false);
    const [lastRow, setLastRow] = useState(null);

    const hasPermission = () => props?.hasPrivilege(PrivilegeActions.edit_work_progress);

    const columns = progressTableConfig(hasPermission(),
        (props.progressList?.length - 1), onRemoveRow, onChangeRowValues);

    useEffect(() => {
        const last = props.progressList?.at(props.progressList?.length - 1);
        setLastRow(last);
        setLastRowValid(!props.progressList || props.progressList?.length === 0 ||
            !!last?.percentage && (last?.percentage / percentageSteps) !== percentageSteps);
        if (last?.percentage > 0) {
            props.setProgress(last?.percentage ?? 0);
        }
    }, [props.progressList]);

    function handleProgress(value) {
        props.setProgress(value);
    }

    function createNewRow() {
        const percentages = getPercentages(lastRow ? (lastRow.percentage / percentageSteps) : 0);
        props.setProgressList([...props.progressList, {
            percentageList: percentages,
            percentage: 0,
            comment: ''
        }]);
    }

    function onRemoveRow(row, i) {
        const rows = [...props.progressList];
        rows.splice(i, 1);
        props.setProgressList([...rows]);
    }

    function onChangeRowValues(i, value, attribute) {
        const proList = [...props.progressList];
        if (attribute === 'percentage') {
            value = +value;
        } else { value = value.toUpperCase(); }
        proList.at(i)[attribute] = value;
        props.setProgressList(proList);
    }

    return (<>
        <Grid container className={"form"} id={PR_PANELS.PROGRESS}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Typography variant={"h2"}>Work Progress</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Slider
                            value={props.progress}
                            size="small"
                            color={"primary"}
                            step={percentageSteps}
                            marks={getPercentages(0)}
                            valueLabelDisplay="off"
                            onChange={(e) => handleProgress(e.target.value)}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} className={"table"}>
                        <DataTable
                            fixedHeader={true}
                            persistTableHead={true}
                            columns={columns}
                            data={props.progressList}
                        />
                    </Grid>
                    {(hasPermission()) &&
                        <Grid item xs={12} className={"table-action"}>
                            <IconButton
                                onClick={createNewRow}
                                disabled={!lastRowValid}>
                                <Add />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default WorkflowProgress;   