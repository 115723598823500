
import { DeleteOutline,KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { IconButton, InputBase, Autocomplete, TextField } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import './../Styles.css';

export const resourceTableColumnConfig = (
    onRemoveRow,
    onChangeResourceCode,
    checkForDuplicatedCodes,
    onChangeIsPercentageCheck,
    onChangeClaimValue,
    onKeyDownClaimValue,onChangeGenericItem,onChangeIsGenericCheck,genericItemsList) => [
        {
            name: "",
            width: "50px",
            button: true,
            cell: (row, index) => <IconButton onClick={() => onRemoveRow(index)}><DeleteOutline />
            </IconButton>
        },
        {
            name: "Code",
            width: "110px",
            cell: (row, index) => {
                return <InputBase
                    disabled={!!row._id}
                    className={ row.isGeneric == true ? 'hide_this':''}
                    variant={"outlined"}
                    value={row.resourceCode}
                    autoFocus={!row._id}
                    onBlur={(e) => checkForDuplicatedCodes(index, e.target.value)}
                    onChange={(e) => onChangeResourceCode(index, e.target.value)}
                />
            }
        },
        // {
        //     name: "Generic?",
        //     width: "80px",
        //     center: true,
        //     selector: row => row.isGeneric,
        //     cell: (row, index) => {
        //         return <CheckBox
        //             color="primary"
        //             checked={row.isGeneric}
        //             onChange={(e) => onChangeIsGenericCheck(index, e.target.checked)}
        //         />
        //     }
        // },   
        
        {
            name: "Generic",
            width: "350px",
            cell: (row, index) => {
                return <Autocomplete
                    id={"atcGenericItem"}
                    disabled={!!row._id}
                    options={genericItemsList}
                    className={row.isGeneric == false || row.isGeneric == undefined ? 'colResType hide_text':'colResType'}
                    getOptionLabel={(option) => option.hasOwnProperty('name') ? option.name : option}
                    value={row.resourceCode}
                    onChange={(e, newValue) => onChangeGenericItem(index, newValue)}
                    renderInput={(params) => <TextField {...params} variant="outlined"/>}
                    sx={{ width: 350 }}
                    popupIcon={<ArrowDownIcon />}
                />
            }
        },        
        
        {
            name: "%",
            width: "80px",
            center: true,
            selector: row => row.isPercentageInput,
            cell: (row, index) => {
                return <CheckBox
                    color="primary"
                    checked={row.isPercentage}
                    onChange={(e) => onChangeIsPercentageCheck(index, e.target.checked)}
                />
            }
        },
        {
            name: "Claim Value",
            width: "110px",
            right: true,
            cell: (row, index) => {
                return <InputBase
                    type={"number"}
                    variant={"outlined"}
                    className={"dollar"}
                    value={row.claimValue}
                    onChange={(e) => onChangeClaimValue(index, e.target.value)}
                    onKeyDown={(e) => onKeyDownClaimValue(e)}
                />
            }
        },
    ];
