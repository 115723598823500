export const messageList = [
  {
    id: "success_a",
    label: {
      title: "Success Title-A",
      message: "Success Message-A",
    },
  },
  {
    id: "success_b",
    label: {
      title: "Success Title-B",
      message: "Success Message-B",
    },
  },
  {
    id: "success_c",
    label: {
      title: "Success Title-C",
      message: "Success Message-C",
    },
  },
  {
    id: "success_d",
    label: {
      title: "Success Title-D",
      message: "Success Message-D",
    },
  },
  {
    id: "error_a",
    label: {
      title: "Error Title-A",
      message: "Error Message-A",
    },
  },
];

export const emailContentList = [
  {
    id: "email_content_a",
    label: "Email Content-A",
  },
  {
    id: "email_content_b",
    label: "Email Content-B",
  },
];
