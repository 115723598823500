import React from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyboardArrowDown as ArrowDownIcon } from '@mui/icons-material';

const MobileInstallationClaimReportFilters = ({
    teamList, statusOptions,
    teamFilter, statusFilter,
    setTeamFilter, setStatusFilter
}) => {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"team-filter"}
                    options={teamList}
                    getOptionLabel={(option) => `${option.username}-${option.name}`}
                    value={teamFilter}
                    onChange={(e, newValue) => setTeamFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Team"} variant={"outlined"} />}
                    popupIcon={<ArrowDownIcon />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <Autocomplete
              id={"status-filter"}
              options={statusOptions}
              getOptionLabel={(option) => option}
              value={statusFilter}
              onChange={(e, newValue) => setStatusFilter(newValue)}
              renderInput={(params) => <TextField {...params}
                  label={"Status"} variant={"outlined"} />}
              popupIcon={<ArrowDownIcon />}
            />
            </Grid>
        </Grid>
    </>);
};

export default MobileInstallationClaimReportFilters;