import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, InputAdornment, Autocomplete, IconButton
} from "@mui/material";
import {
    Search as SearchIcon, KeyboardArrowDown as ArrowDownIcon,
    PlaylistPlay as FilterIcon, ArrowBack as BackIcon, SettingsPowerRounded,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { ENABLE_OPTIONS } from "../../data/constants";
import { useLayoutDispatch, useLayoutState, toggleRightbar, setRighbarContent } from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import MobileFnbStoreFilters from "./components/Mobile/MobileFnbStoreFilters";
import { fnbStoreTableColumnConfig } from "./data-table/fnb-store-table-column-config";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import { KeyboardArrowDown, PlaylistPlay, ArrowBack } from "@mui/icons-material";

const FnbStoreList = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    // layout state
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    // filters
    const [textFilter, setTextFilter] = useState("");
    const [fnbStores, setFnbStores] = useState([]);
    const [storeFilter, setStoreFilter] = useState(null);
    const [categoryFilter, setCategoryFilter] = useState(null);   
    const [fnbCategories, setFnbCategories] = useState([]);
    const yesNoList = ['Yes', 'No'];
    const [enableFilter, setEnableFilter] = useState(null);

    const [promoteFilter, setPromoteFilter] = useState(null);
    const [chefRecommendFilter, setChefRecommendFilter] = useState(null);
    const [vegetarianFilter, setVegetarianFilter] = useState(null);
    const [healthierChoiceFilter, setHealthierChoiceFilter] = useState(null);
    const [noStockFilter, setNoStockFilter] = useState(null);
    const [popularFilter, setPopularFilter] = useState(null);

    const [sortBy, setSortBy] = useState({ column: undefined, direction: undefined });
    const [refresh, setRefresh] = useState(false); // refresh list

    const [fnbStoreList, setFnbStoreList] = useState([]);
    const smallDeviceWidth = 1200;

    // pagination states
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collectionSize, setCollectionSize] = useState(10);

    const mobileFilterProps = { fnbStores, storeFilter, onChangeStoreFilter, fnbCategories, categoryFilter, setCategoryFilter, noStockFilter, setNoStockFilter, yesNoList, promoteFilter, setPromoteFilter, chefRecommendFilter, setChefRecommendFilter, vegetarianFilter, setVegetarianFilter, healthierChoiceFilter, setHealthierChoiceFilter, enableFilter, setEnableFilter, popularFilter, setPopularFilter };
    const [message, setMessage] = useState("");
    const [errMessage, setErrMessage] = useState("");

    useEffect(() => {
        document.title = "F&B Store List";

        if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.fnb_store, PrivilegeActions.view_list)) {
            props.history.push('/app/dashboard');
        }
    }, []);

    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: setRefresh(true)
            });
        }
    }, [message]);

    useEffect(() => {
        if (errMessage) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
    
          setErrMessage("");
        }
      }, [errMessage]);

    // filter data
    useEffect(() => { if (refresh) { filter(); setRefresh(false); } }, [refresh]);

    useEffect(() => { if (!textFilter) { setRefresh(true); } }, [textFilter]);

    useEffect(() => { 
        setRefresh(true); 
    }, [storeFilter, categoryFilter, enableFilter, promoteFilter, chefRecommendFilter, vegetarianFilter, healthierChoiceFilter, noStockFilter, popularFilter]);

    useEffect(() => {
        if (width <= smallDeviceWidth) { // set filter right bar
            setRighbarContent(layoutDispatch,
                [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
                <MobileFnbStoreFilters key={"rb-body"} {...mobileFilterProps} />],
                { size: 'xs', backButton: true }); // filter content
        }
    }, [fnbStoreList, enableFilter, width]);

    // set columns in data table
    const columns = fnbStoreTableColumnConfig(
        {
            allowedViewDetail: auth.checkModulePrivilege(PrivilegeModules.fnb_store, PrivilegeActions.view_detail),
            allowedDelete: auth.checkModulePrivilege(PrivilegeModules.fnb_store, PrivilegeActions.delete),
            allowedEnableDisable: auth.checkModulePrivilege(PrivilegeModules.fnb_store, PrivilegeActions.enable_disable),
            allowedEdit: auth.checkModulePrivilege(PrivilegeModules.fnb_store, PrivilegeActions.edit),
        },
        handleNameClick,
        handleDeleteFnbStore,
        handleEditFnbStore
    );

    async function handleEditFnbStore(store, type, toggle = false, toggleValue = false) {
        if (toggle) {
            store[type] = toggleValue;
            let tmpStore = JSON.parse(JSON.stringify(store));
            await sendRequest(`/v1/fnb-store/${store.storeId}`, "PUT", tmpStore);
        } else {
            props.history.push(`card/${store.storeId}`);
        }
    }

    function handleDeleteFnbStore(store) {
        if (store) {
            Swal.fire({
                title: `Confirmation`,
                text: `Are you sure you want to delete the F&B store ${store.name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await sendRequest(`/v1/fnb-store/${store.storeId}`, "DELETE");
                    if (result?.status === 200 && result?.data?.deleted) {
                        setMessage(`F&B store ${store.name} has been deleted.`);
                    }
                }
            }).catch((err) => {
                setErrMessage(err.message);
            });
        }
    }

    function filter() {
        // set list filters
        getPaginatedData(page, pageSize, getFilterValues(), sortBy)
            .then(response => {
                const stores = response.data?.stores || [];
                setFnbStoreList(stores);
                setCollectionSize(response.data?.total);
                return () => {
                    return stores;
                };
            });
    }

    function getFilterValues() {
        return {
            text: textFilter.trim(),
        };
    }

    function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
        return sendRequest(
            `/v1/fnb-store/filter?filters=${JSON.stringify(filters)}&page=${page}&pageSize=${pageSize}
        ${(sortBy?.column && sortBy?.direction) ?
                ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {});
    }

    function sortByColumn(columnConfig, sortDirection) {
        if (columnConfig.sortField && sortDirection) {
            setSortBy({
                column: columnConfig.sortField,
                direction: sortDirection
            });
            setRefresh(true);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value) { // perform search when Enter
            e.preventDefault();
            setRefresh(true);
        }
    };

    function handlePageChanged(page) {
        setPage(page);
        setRefresh(true);
    }

    function handleRowsPerPageChanged(count) {
        setPageSize(count);
        setRefresh(true);
    }

    // go to edit view
    async function handleNameClick(entityId) {
        if (auth.checkModulePrivilege(PrivilegeModules.fnb_store, PrivilegeActions.view_detail)) {
            props.history.push(`card/${entityId}`);
        }
    }

    function onChangeStoreFilter(newValue){
        setStoreFilter(newValue);
        setCategoryFilter(null);
        setFnbCategories(
            newValue ? fnbStores.filter((c) => {
              return c.storeId == newValue.storeId;
            })[0].categories: []
        )            
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>F&B Store List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                {(auth.checkModulePrivilege(PrivilegeModules.fnb_store, PrivilegeActions.add)) && <Button className={"primary"}
                    onClick={() => props.history.push('card')}>New</Button>}
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                            <TextField
                                autoComplete={"off"}
                                id={"text-searchbox"}
                                variant={"outlined"}
                                value={textFilter}
                                onChange={(e) => setTextFilter(e.target.value)}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={"start"}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }} />
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}
                                data={fnbStoreList}
                                sortServer
                                onSort={sortByColumn}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationPerPage={pageSize}
                                paginationTotalRows={collectionSize}
                                onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
                                onChangePage={(page) => handlePageChanged(page)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default withRouter(FnbStoreList);