import React, { useState } from "react";
import {
  PrivilegeActions,
  PrivilegeModules,
} from "../../../data/privileges.enum";
import { useRoleAuthorization } from "../../../hooks/useRoleAuthorization";
import { LoadingButton } from "@mui/lab";
import moment from "moment/moment";
import downloadCSV from "../../../helper/download-csv";

const CSV_HEADERS = [
  "TransactionNumber",
  "store",
  "orderType",
  "orderTime",
  "pickupTime",
  "discountAmount",
  "discountCode",
  "subtotal",
  "taxAmount",
  "totalExclTax",
  "grandTotal",
  "paymentMethod",
  "isPaid",
];

const transformOrderList = (orders = []) => {
  return orders.map((order) => {
    return {
      TransactionNumber: order.orderNumber || "",
      store: order.__store__ ? order.__store__.name : "",
      orderType: order.orderType || "",
      orderTime: order.orderTime
        ? moment(order.orderTime).format("DD/MM/YYYY HH:mm:ss")
        : "",
      pickupTime: order.pickupTime
        ? `${moment(order.pickupTime).format("DD/MM/YYYY")} ${
            order.pickupTime.label
          }`
        : "",
      discountAmount: order.discountAmount || "",
      discountCode: order.discountCode || "",
      subtotal: order.subtotal || "",
      taxAmount: order.taxAmount || "",
      totalExclTax: order.totalExclTax || "",
      grandTotal: order.grandTotal || "",
      paymentMethod: order.__payment__ ? order.__payment__.paymentMethod : "",
      isPaid: order.isPaid ? "YES" : "NO",
    };
  });
};

export default function DownloadOrders({ sendRequestStub, onError }) {
  const { auth } = useRoleAuthorization();
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    setIsLoading(true);
    sendRequestStub()
      .then((response) => {
        console.log(response?.data?.orders);
        const today = moment().format("YYYYMMDD_HHmmss");
        const filename = `FNB_ORDER_REPORT_${today}.csv`;
        const csvData = transformOrderList(response?.data?.orders || []);

        const ordersTotal = (response?.data?.orders || []).reduce(
          (accumulateTotal, order) => accumulateTotal + order.grandTotal,
          0,
        );
        const totalOrder = (response?.data?.orders || []).length;
        // Fill empty
        csvData.push(Array(CSV_HEADERS.length).fill(""));
        csvData.push({
          [CSV_HEADERS[0]]: `Total`,
          [CSV_HEADERS[1]]: ordersTotal,
        });
        csvData.push({
          [CSV_HEADERS[0]]: `Order(s)`,
          [CSV_HEADERS[1]]: totalOrder,
        });

        downloadCSV(csvData, filename, CSV_HEADERS);

        setIsLoading(false);
      })
      .catch((error) => {
        onError(error);
        setIsLoading(false);
      });
  };

  const isAbleToShow = () => {
    return (
      auth.isPrivilegeDataLoaded() &&
      auth.checkModulePrivilege(
        PrivilegeModules.fnb_order,
        PrivilegeActions.view_list,
      )
    );
  };

  return (
    <>
      {isAbleToShow() && (
        <LoadingButton
          className={"primary"}
          onClick={handleClick}
          loading={isLoading}
          variant="outlined"
          disabled={isLoading}
        >
          Download Report
        </LoadingButton>
      )}
    </>
  );
}
