import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, Autocomplete
} from "@mui/material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useStoreDispatch, useStoreState, setStore } from "../../context/StoreContext";
import DataTable from "react-data-table-component";
import { bracketRequestCardTableColumnConfig } from "./data-table/bracket-request-card-table-column-config";
import useStyles from "./styles";
import { useUserState } from "../../context/UserContext";
import sortByDate from "../../helper/sort-by-date";
import { BRACKET_REQUEST_ITEM_STATUS, BRACKET_STATUS } from "../../data/constants";
import { validateText } from "../../helper/validate-textfield";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const BracketRequestCard = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // store data
    const storeDispatcher = useStoreDispatch();
    const { store, storeList } = useStoreState();

    const { userInfo } = useUserState();

    // page authorization 
    const { auth } = useRoleAuthorization();

    // handling form when adding / editing
    const { handleSubmit, control, register, formState: { errors: formErrors }, setValue, clearErrors }
        = useForm({ mode: "all" });

    const [currentRequest, setCurrentRequest] = useState(null);
    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [requestItemList, setRequestItemList] = useState([]);
    const [searchSerialNo, setSearchSerialNo] = useState('');
    const [sroNoRequired, setSroNoRequired] = useState(false);
    const [reason, setReason] = useState('');
    const reasonList = ['AMEND QTY', 'CANCEL ORDER', 'EXCHANGE', 'OTHER'];
    const isViewOnly = false;

    // current remaining count
    const [currentCount, setCurrentCount] = useState(0);
    // previously saved (issued) count
    const [lastSavedCount, setLastSavedCount] = useState(0);
    // store the newly added & returned brackets
    const [changedCounts, setChangedCounts] = useState({ newlyAdded: 0, toBeReturned: 0 });

    const { pkrNo } = useParams(); // url param => pkrNo when editing

    useEffect(() => {
        document.title = "New - Bracket Request Card";

        //redirect to default page if not authorized
        if (pkrNo && auth.isPrivilegeDataLoaded() && (!auth.checkModulePrivilege(PrivilegeModules.bracket_request,PrivilegeActions.view_detail))){
            props.history.push('/app/dashboard');
        } 
    }, []);

    // get current bracket when editing 
    useEffect(() => {
        if (!isLoading && !responseData && pkrNo) {
            sendRequest(`/v1/bracket-request/get-by-pkr-no/` + pkrNo, 'GET');
        }
    }, [pkrNo]);

    // set title & form values with current bracket request when editing
    useEffect(() => {
        if (pkrNo && responseData?.request) {
            const request = responseData?.request;
            document.title = `${request.pkrNo} - Bracket Request Card`;
            setCurrentRequest(request);
            setValue('pkrNo', request.pkrNo || '');
            setValue('remark', request.remark || '');
            setValue('storeCode', request.storeCode || '');
            setValue('reason', '');
            setValue('sroNo', '');
            setReason(''); // reset reason field
            // set request item list (sort by date DESC)
            setRequestItemList(sortByDate(request.items, 'modifiedDate', false));
            // already issued bracket count
            setLastSavedCount(request.items
                .filter(i => i.status === BRACKET_REQUEST_ITEM_STATUS.ISSUED).length);
        }
    }, [responseData]);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    // calculate the total when changing request items
    useEffect(() => {
        const toBeReturned = requestItemList
            .filter(i => i.status === BRACKET_REQUEST_ITEM_STATUS.TO_BE_RETURNED).length;
        const newlyAdded = requestItemList
            .filter(i => i.status === BRACKET_REQUEST_ITEM_STATUS.NEW).length;

        const current = lastSavedCount - toBeReturned + newlyAdded;
        setCurrentCount(current);
        setSroNoRequired(current < lastSavedCount);
        setChangedCounts({ newlyAdded: newlyAdded, toBeReturned: toBeReturned });
        // clear SRO No field error when getting disabled
        if (current >= lastSavedCount) {
            clearErrors('sroNo');
        }
    }, [requestItemList]);

    // navigate back to bracket request list
    const navigateToList = () => {
        if (!pkrNo)
            window.location.href = '#/app/bracket-request/list';
    }

    // PKR helper text
    function getPkrHelperText() {
        if (formErrors?.pkrNo?.type === "required") {
            return "Document No. cannot be blank."
        }

        if (formErrors?.pkrNo?.message === "invalid_pkr") {
            return "Document No. is invalid.";
        }

        if (formErrors?.pkrNo?.type === "maxLength") {
            return "Maximum 12 characters are allowed.";
        }
    }

    // handle saving new bracket request
    const handleSaveNewRequest = async (data, e) => {
        if (data && requestItemList.length > 0) {
            const request = {
                pkrNo: data.pkrNo.toUpperCase(),
                remark: data.remark,
                storeCode: store.code,
                status: 'PENDING',
                createdBy: userInfo._id,
                items: requestItemList.map(r => ({
                    _id: r._id,
                    serialNo: r.serialNo,
                    bracketType: r.bracketType,
                    batchNos: r.batchNos,
                    welder: r.welder?.username,
                    status: BRACKET_REQUEST_ITEM_STATUS.NEW // initially -> NEW
                }))
            };
            const result = await sendRequest('/v1/bracket-request', "POST", request);
            if (result?.status === 201 && result?.data?.request) {
                setMessage(`New bracket request for ${request.pkrNo} has been created.`); // call show feedback message
            }
        }
    };

    // hanlde edit bracket request 
    const handleUpdateRequest = async (data) => {
        if (data && requestItemList.length > 0) {
            const request = { ...currentRequest };
            request.remark = data.remark;
            request.items = requestItemList.map(r => ({
                _id: r._id,
                serialNo: r.serialNo,
                bracketType: r.bracketType,
                batchNos: r.batchNos,
                welder: r.issuedDate ? r.welder : r.welder?.username,
                pkrNo: r.pkrNo,
                customerNo: r.customerNo,
                customerName: r.customerName,
                mobileNo: r.mobileNo,
                address: r.address,
                postalCode: r.postalCode,
                issuedDate: r.issuedDate,
                modifiedDate: r.modifiedDate,
                returnedDate: r.returnedDate,
                status: r.status,
                // SRO No only applied for brackets which are to be retuned
                sroNo: (r.status === BRACKET_REQUEST_ITEM_STATUS.TO_BE_RETURNED)
                    ? data.sroNo : r.sroNo,
                // reason only applied for NEW & TO BE RETURNED brackets
                reason: (r.status === BRACKET_REQUEST_ITEM_STATUS.TO_BE_RETURNED ||
                    r.status === BRACKET_REQUEST_ITEM_STATUS.NEW)
                    ? data.reason?.toUpperCase() : r.reason,
                modelNos: r.modelNos
            }));
            const result = await sendRequest(`/v1/bracket-request/${request._id}`, "PUT", request);
            if (result?.status === 200 && result?.data?.request) {
                setMessage(`Bracket request for ${request.pkrNo} has been updated.`); // call show feedback message
            }
        }
    };

    // load bracket request items table columns
    const columns = bracketRequestCardTableColumnConfig(
        !!pkrNo && !!currentRequest,
        isViewOnly,
        removeBracket,
        onChangeReturnCheckbox);

    // when add brackets to request item list
    async function onKeyDownSerialNo(e) {
        if (e.key === 'Enter' && e.target.value) {
            e.preventDefault();
            setMessage(''); // reset feedback message
            const value = e.target.value.trim().toUpperCase();
            if (checkEligibility(value)) {
                // check in DB
                const bracket = await getBracketBySerialNo(value);
                if (bracket) {
                    // only not issued brackets
                    if (!bracket.pkrNo) {
                        // only PENDING & APPROVED allowed
                        if (bracket.status === BRACKET_STATUS.PENDING ||
                            bracket.status === BRACKET_STATUS.APPROVED) {
                            const item = { ...bracket, bracketType: bracket.bracketType?.name };
                            item.status = BRACKET_REQUEST_ITEM_STATUS.NEW; // set status to NEW
                            requestItemList.unshift(item); // get newly added record to top of the list
                            setRequestItemList([...requestItemList]);
                        } else {
                            setWarningMessage(`Bracket ${value} has already been rejected.`);
                        }
                    } else { setWarningMessage(`Bracket ${value} has already been issued.`); }
                } else { setWarningMessage(`Bracket ${value} is not found in the system.`); }
            } else {
                // tick existing issued bracket
                const matchedItem = requestItemList
                    .find(e => e.serialNo === value);
                if (matchedItem && matchedItem.issuedDate) {
                    onChangeReturnCheckbox(matchedItem._id, true);
                }
            }
            setSearchSerialNo(''); // reset serial no field
        }
    }

    // check bracket before adding to request items
    function checkEligibility(serialNo) {
        const matchedItem = requestItemList
            .find(e => e.serialNo === serialNo);

        // serial no cannot be matched with a list item or matched, but should be a RETURNED one, not NEW
        return !matchedItem ||
            (!!matchedItem && matchedItem.status !== BRACKET_REQUEST_ITEM_STATUS.NEW
                && matchedItem.status === BRACKET_REQUEST_ITEM_STATUS.RETURNED);
    };

    // get bracket by serial no
    async function getBracketBySerialNo(serialNo) {
        const response = await sendRequest(
            `/v1/bracket/get-by-serial-no/${serialNo}`, 'GET');
        return response.data?.bracket;
    }

    // handle remove from request item list
    function removeBracket(serialNo) {
        const list = [...requestItemList];
        const index = list.findIndex(r => r.serialNo === serialNo);
        if (index > -1) { list.splice(index, 1); }
        setRequestItemList(list);
    }

    // when click on return checkbox
    function onChangeReturnCheckbox(id, value) {
        const request = requestItemList.find(i => i._id === id);
        // set status to ISSUED or TO_BE_RETURNED
        request.status = value ? BRACKET_REQUEST_ITEM_STATUS.TO_BE_RETURNED :
            BRACKET_REQUEST_ITEM_STATUS.ISSUED;
        setRequestItemList([...requestItemList]);
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {(!pkrNo || (pkrNo && currentRequest)) &&
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Bracket Request Card
                            {currentRequest ? ` - ${currentRequest.pkrNo}` : ` - New`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/bracket-request/list')}>Cancel</Button>
                        {!pkrNo && auth.checkModulePrivilege(PrivilegeModules.bracket_request, PrivilegeActions.add) && (<Button className={"primary"} type={"button"}
                            disabled={requestItemList.length === 0}
                            onClick={handleSubmit(handleSaveNewRequest)}>
                            Save</Button>)}
                        {pkrNo && auth.checkModulePrivilege(PrivilegeModules.bracket_request, PrivilegeActions.edit) && (<Button className={"primary"} type={"button"}
                            disabled={requestItemList.length === 0}
                            onClick={handleSubmit(handleUpdateRequest)}>
                            Save</Button>)}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Autocomplete
                                                    id={"storeCode"}
                                                    options={storeList}
                                                    getOptionLabel={(store) => store.name}
                                                    value={store}
                                                    disabled={(!!pkrNo && !!currentRequest) || !!isViewOnly}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={"Store"}
                                                            variant={"outlined"}
                                                            autoComplete="off" />}
                                                    onChange={(event, data) => setStore(storeDispatcher, data)}
                                                    popupIcon={<ArrowDownIcon />} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"pkrNo"}
                                                    label={"Document No."}
                                                    variant={"outlined"}
                                                    disabled={(!!pkrNo && !!currentRequest) || !!isViewOnly}
                                                    autoFocus={!pkrNo}
                                                    helperText={getPkrHelperText()}
                                                    {...register("pkrNo", {
                                                        required: true,
                                                        maxLength: { value: 12, message: "Maximum 12 characters are allowed." }
                                                    })}
                                                    error={!!formErrors?.pkrNo}
                                                    autoComplete={"off"}
                                                    inputProps={{ maxLength: 12 }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"remark"}
                                                    label={"Remark"}
                                                    variant={"outlined"}
                                                    disabled={!!isViewOnly}
                                                    {...register("remark", { maxLength: 50 })}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.remark}
                                                    helperText={formErrors?.remark?.type === "maxLength"
                                                        && "Maximum 50 characters are allowed."}
                                                    inputProps={{ maxLength: 50 }}
                                                    onChange={(event) => validateText(event)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(pkrNo && currentRequest && !isViewOnly) && (<Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} className={"sub-action"}>
                                    <Typography variant={"h2"}>Return/Exchange</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={{ xs: 2, md: 3 }}>
                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                            <TextField
                                                id={"sroNo"}
                                                label={"SRO No."}
                                                variant={"outlined"}
                                                {...register("sroNo", {
                                                    required: sroNoRequired && {
                                                        value: true,
                                                        message: "SRO No. cannot be blank."
                                                    }
                                                })}
                                                error={!!formErrors?.sroNo}
                                                helperText={formErrors?.sroNo?.message}
                                                autoComplete={"off"}
                                                onChange={(event) => validateText(event)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                            <Controller
                                                name={`reason`}
                                                control={control}
                                                rules={{
                                                    required: (changedCounts?.newlyAdded > 0
                                                        || changedCounts?.toBeReturned > 0) && {
                                                        value: true,
                                                        message: "Reason cannot be blank."
                                                    }
                                                }}
                                                render={(props) => (
                                                    <Autocomplete
                                                        id={"reason"}
                                                        options={reasonList}
                                                        getOptionLabel={(option) => option}
                                                        value={reason}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label={"Reason"}
                                                                variant={"outlined"}
                                                                error={!!formErrors?.reason}
                                                                helperText={formErrors?.reason?.message}
                                                                autoComplete="off" />}
                                                        onChange={(event, data) => {
                                                            setReason(data);
                                                            props?.field.onChange(data);
                                                        }}
                                                        popupIcon={<ArrowDownIcon />} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)}
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} className={"sub-action"}>
                                    <Typography variant={"h2"}>Brackets</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={{ xs: 2, md: 3 }}>
                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                            {(!isViewOnly) && (<TextField
                                                id={"serialNo"}
                                                label={"Serial No."}
                                                variant={"outlined"}
                                                autoFocus={!!pkrNo && !!currentRequest}
                                                onKeyDown={(e) => onKeyDownSerialNo(e)}
                                                onChange={(e) => setSearchSerialNo(e.target.value)}
                                                autoComplete={"off"}
                                                value={searchSerialNo}
                                            />)}
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={8} xl={9} className={"summary"}>
                                            <Typography variant={"h3"} className={classes.totalBracket}>Total Bracket: {(!!pkrNo && !!currentRequest) && (<span>{currentCount}/{lastSavedCount}</span>)}
                                                {(!pkrNo || !currentRequest) && (<span>{requestItemList.length}</span>)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <DataTable
                                                fixedHeader={true}
                                                persistTableHead={true}
                                                columns={columns}
                                                data={requestItemList} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(BracketRequestCard);