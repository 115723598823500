import { withRouter } from "react-router-dom";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useState } from "react";
import DateFilter from "../../../../components/DateFilter";

const MobileProjectRequestFilters = ({
  statusFilter,
  setStatusFilter,
  statusList,
  awardedDateFrom,
  awardedDateTo,
  setAwardedDateFrom,
  setAwardedDateTo,
  projectManagerFilter,
  setProjectManagerFilter,
  projectManagerList,
  stepFilter,
  setStepFilter,
  stepList,
}) => {
  const [fromOpen, setFromOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);

  const toggleFromOpen = () => setFromOpen(!fromOpen);
  const toggleToOpen = () => setToOpen(!toOpen);

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"status-filter-mobile"}
            options={statusList}
            getOptionLabel={(option) => option}
            value={statusFilter}
            onChange={(e, newValue) => setStatusFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Status"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"pm-filter"}
            options={stepList}
            getOptionLabel={(option) => option}
            value={stepFilter}
            onChange={(e, newValue) => setStepFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Step"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"pm-filter-mobile"}
            options={projectManagerList}
            getOptionLabel={(pm) => `${pm.userId}-${pm.userName}`}
            value={projectManagerFilter}
            onChange={(e, newValue) => setProjectManagerFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Project Manager"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            label="Awarded From"
            value={awardedDateFrom}
            onChange={(newValue) => setAwardedDateFrom(newValue)}
            maxDate={awardedDateTo}
            onOpen={toggleFromOpen}
            onClose={toggleFromOpen}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            label="Awarded To"
            value={awardedDateTo}
            onChange={(newValue) => setAwardedDateTo(newValue)}
            minDate={awardedDateFrom}
            onOpen={toggleToOpen}
            onClose={toggleToOpen}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(MobileProjectRequestFilters);
