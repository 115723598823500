import moment from "moment";
import { Link } from "react-router-dom";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";
import { IconButton } from "@mui/material";
import { DeleteOutline as VoidIcon } from "@mui/icons-material";
import { INSTALLATION_CLAIMS_REPORT_STATUS } from "../../../data/constants";

export const claimReportsTableColumnConfig = ({ allowEdit, handleBatchNoClick, hasPermissionToVoid, onVoidClaimReport }) => {

    const showFileName = (url) => url.split('/').slice(-1);
    const voided = (status) => status === INSTALLATION_CLAIMS_REPORT_STATUS.VOIDED;

    let columns = [
        {
            name: "Report No.",
            width: "110px",
            selector: row => row.batchNo,
            cell: (row) => {
                if (allowEdit && !row.paymentDate && !voided(row.status)) {
                    return (<Link to={'#'} onClick={(e) => handleBatchNoClick(row)}>{row.batchNo}</Link>)
                } else {
                    return (<>{row.batchNo}</>)
                }
            },
            sortable: true,
            sortField: 'batchNo'
        },
        {
          name: "",
          width: hasPermissionToVoid ? "50px" : "0px",
          center: true,
          cell: (row) => (hasPermissionToVoid && !row?.paymentDate && !voided(row.status))
              && (<IconButton onClick={() => onVoidClaimReport(row)}><VoidIcon /></IconButton>)
        },
        {
            name: "Team",
            width: "80px",
            selector: row => row.team,
            sortable: true,
            sortField: 'team'
        },
        {
            name: "Claim Date From",
            width: "130px",
            selector: row => row.claimDateFrom,
            cell: (row) => {
                return moment(row.claimDateFrom).format('DD/MM/YYYY')
            },
            sortable: true,
            sortField: 'claimDateFrom'
        },
        {
            name: "Claim Date To",
            width: "120px",
            selector: row => row.claimDateTo,
            cell: (row) => {
                return moment(row.claimDateTo).format('DD/MM/YYYY')
            },
            sortable: true,
            sortField: 'claimDateTo'
        },
        {
          name: "Status",
          width: "80px",
          selector: row => row?.status,
          cell: (row) => {
            return row.status
          },
          sortable: true,
          sortField: 'status'
        },
        {
            name: "Total Claim Amount",
            width: "150px",
            selector: row => row.totalClaimAmount !== undefined ? convertThisToCurrency(row.totalClaimAmount?.toFixed(2)) : '-',
            sortable: true,
            right: true,
            sortField: 'totalClaimAmount'
        },
        {
            name: "Payment Date",
            width: "110px",
            selector: row => row?.paymentDate ? row?.paymentDate : '',
            cell: (row) => {
                return row?.paymentDate ? moment(row?.paymentDate).format('DD/MM/YYYY') : ''
            },
            sortable: true,
            sortField: 'paymentDate'
        },
        {
            name: "Download",
            cell: (row) => (<a href={row.downloadUrl} target="_blank" download>
                {showFileName(row.downloadUrl)}</a>),
            minWidth: "400px",
            sortable: true,
            sortField: 'downloadUrl'
        }
    ];
    return columns;
};
