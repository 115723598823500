import React from "react";
import {
  Button,
  Grid,
  List,
  Box,
  Typography,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import "./../Styles.css";
import { IconButton } from "@mui/material";
import { Settings as EditIcon } from "@mui/icons-material";
import { AttachFileOutlined as DocumentIcon } from "@mui/icons-material";

export default function MachineStoreDrawer({
  storeData,
  storeMachineData,
  serviceJobData,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }} className="display">
        {storeData && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Typography variant={"h2"}>Store</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={`Company`}
                      secondary={storeData.company.name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Area`} secondary={storeData.area} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} lg={6}>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={`Store ID`}
                      secondary={storeData.storeId}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={`Location`}
                      secondary={storeData.location}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        )}

        {storeMachineData && storeMachineData.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Typography variant={"h2"}>Machine</Typography>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Brand</TableCell>
                      <TableCell align="left">Model</TableCell>
                      <TableCell align="left">Serial No.</TableCell>
                      <TableCell align="left">Next Service Month</TableCell>
                      <TableCell align="left">Sequence</TableCell>
                      <TableCell align="left">Interval</TableCell>
                      <TableCell align="right">Reminder (Month)</TableCell>
                      <TableCell align="right">Total Services</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storeMachineData.map((row, i) => (
                      <TableRow key={`${row.no}-${i}`}>
                        <TableCell align="left">{row.brand}</TableCell>
                        <TableCell align="left">{row.model}</TableCell>
                        <TableCell align="left">{row.serialNo}</TableCell>
                        <TableCell align="left">
                          {moment(row.nextServiceDate).format("MM/YYYY")}
                        </TableCell>
                        <TableCell align="left">{row.seqOfMonth}</TableCell>
                        <TableCell align="left">
                          {row.serviceInterval}
                        </TableCell>
                        <TableCell align="right">
                          {row.serviceReminder}
                        </TableCell>
                        <TableCell align="right">{row.closedJobCnt}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        )}

        {serviceJobData && serviceJobData.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Typography variant={"h2"}>Service Job</Typography>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">No.</TableCell>
                      <TableCell align="center">Seq.</TableCell>
                      <TableCell align="left">Planned Service Date</TableCell>
                      <TableCell align="left">Service Job No.</TableCell>
                      <TableCell align="left">Serviced Date</TableCell>
                      <TableCell align="left">Team</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Brand</TableCell>
                      <TableCell align="left">Model</TableCell>
                      <TableCell align="left">Serial No.</TableCell>
                      <TableCell align="left">Documents</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {serviceJobData.map((row, i) => (
                      <TableRow key={`${row.no}-${i}`}>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.no}
                        </TableCell>
                        <TableCell align="center" style={{ borderBottom: "0" }}>
                          {row.seqNo}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {!row.plannedServiceDate
                            ? ""
                            : moment(row.plannedServiceDate).format(
                                "DD/MM/YYYY",
                              )}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.serviceJobNo}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {!row.serviceDate
                            ? ""
                            : moment(row.serviceDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.servicedByUser}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.status}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.brand}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.model}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {row.serialNo}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: "0" }}>
                          {!!row.checkListUrl && (
                            <a href={row.checkListUrl} target="_blank" download>
                              <DocumentIcon />
                            </a>
                          )}
                          {!!row.serviceJobUrl && (
                            <a
                              href={row.serviceJobUrl}
                              target="_blank"
                              download
                            >
                              <DocumentIcon />
                            </a>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
