import { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import {
  Search as SearchIcon,
  KeyboardArrowDown as ArrowDownIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  Clear as ClearIcon,
  Event as CalendarIcon,
} from "@mui/icons-material";
import useStyles from "./styles";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { SMALL_DEVICE_WIDTH } from "../../data/constants";
import DataTable from "react-data-table-component";
import MobileProjectRequestFilters from "./components/Mobile/MobileProjectRequestFilters";
import { projectRequestTableConfig } from "./data-table/project-request-table-config";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import { useUserState } from "../../context/UserContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
  DesktopDateTimePicker,
} from "@mui/x-date-pickers";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import Swal from "sweetalert2";
import downloadCSV from "../../helper/download-csv";
import moment from "moment";
import {
  PR_STATUS,
  isPending,
  isStep_3_2_5,
  PR_STEPS,
} from "./helper/pr-config";
import ProjectRequestDrawer2 from "./components/Drawer/ProjectRequestDrawer2";
import DateFilter from "../../components/DateFilter";
import ProjectRequestDrawer from "./components/Drawer/ProjectRequestDrawer";

const ProjectRequestList = (props) => {
  const classes = useStyles();
  const { isLoading, sendRequest } = useHttpRequest();

  const { auth } = useRoleAuthorization();
  const { userInfo } = useUserState();

  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  const [textFilter, setTextFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false);
  const [projectManagerFilter, setProjectManagerFilter] = useState(null);
  const [projectManagerList, setProjectManagerList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);
  const [projectRequestDetail, setProjectRequestDetail] = useState(null);
  const [requestList, setRequestList] = useState([]);
  const [awardedDateFrom, setAwardedDateFrom] = useState(null);
  const [awardedDateTo, setAwardedDateTo] = useState(null);
  const [openPRDrawer, setOpenPRDrawer] = useState(true);
  const statusList = Object.values(PR_STATUS);
  const [companyList, setCompanyList] = useState([]);
  const [companyFilter, setCompanyFilter] = useState(null);
  const [stepFilter, setStepFilter] = useState(null);
  const stepList = Object.values(PR_STEPS);
  const mobileFilterProps = {
    projectManagerFilter,
    setProjectManagerFilter,
    statusFilter,
    setStatusFilter,
    statusList,
    projectManagerList,
    awardedDateFrom,
    awardedDateTo,
    setAwardedDateFrom,
    setAwardedDateTo,
    companyList,
    companyFilter,
    setCompanyFilter,
    stepFilter,
    setStepFilter,
    stepList,
  };
  const hasPrivilege = (privilege) =>
    auth.checkModulePrivilege(PrivilegeModules.project_request, privilege);
  const smallDeviceWidth = 1200;

  useEffect(() => {
    document.title = "Project Request List";
  });

  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    setRefresh(true);
  }, [
    projectManagerFilter,
    statusFilter,
    awardedDateFrom,
    awardedDateTo,
    companyFilter,
    stepFilter,
  ]);

  useEffect(() => {
    if (width <= SMALL_DEVICE_WIDTH) {
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>,
          <MobileProjectRequestFilters
            key={"rb-body"}
            {...mobileFilterProps}
          />,
        ],
        { size: "xs", backButton: true },
      );
    }
  }, [
    projectManagerList,
    statusFilter,
    awardedDateFrom,
    awardedDateTo,
    width,
    stepFilter,
  ]);
  useEffect(() => {
    sendRequest(
      "/v1/user/get-list-by-role/option_project_manager",
      "GET",
      {},
    ).then((response) => {
      let list = [];
      if (response.data?.users) {
        list = response.data.users.map((u) => ({
          _id: u._id,
          userId: u.username,
          userName: u.name,
          projectGroup: u.projectGroup,
        }));
      }
      setProjectManagerList(list);
      return list;
    });

    loadCompany();
  }, []);
  useMemo(() => {
    if (auth.isPrivilegeDataLoaded()) {
      let defaultStatus = null;
      if (
        auth.checkModulePrivilege(
          PrivilegeModules.project_request,
          PrivilegeActions.filter_status_new,
          false,
        )
      ) {
        defaultStatus = PR_STATUS.NEW;
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.project_request,
          PrivilegeActions.filter_status_pending,
          false,
        )
      ) {
        defaultStatus = PR_STATUS.PENDING;
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.project_request,
          PrivilegeActions.filter_status_approved,
          false,
        )
      ) {
        defaultStatus = PR_STATUS.APPROVED;
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.project_request,
          PrivilegeActions.filter_status_rejected,
          false,
        )
      ) {
        defaultStatus = PR_STATUS.REJECTED;
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.project_request,
          PrivilegeActions.filter_status_awarded,
          false,
        )
      ) {
        defaultStatus = PR_STATUS.AWARDED;
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.project_request,
          PrivilegeActions.filter_status_declined,
          false,
        )
      ) {
        defaultStatus = PR_STATUS.DECLINED;
      }
      setStatusFilter(defaultStatus);
    }
  }, [userInfo]);

  const columns = projectRequestTableConfig(
    onClickReferenceNo,
    hasPrivilege(PrivilegeActions.view_detail),
    onClickProjectId,
    handlePRClick,
    isOpenPRDrawerValid,
  );
  function isOpenPRDrawerValid({ step, status, version }) {
    if (
      (hasPrivilege(PrivilegeActions.is_ec) ||
        (version > 1 && hasPrivilege("verify_step_3.2"))) &&
      isStep_3_2_5(step) &&
      isPending(status)
    ) {
      return true;
    }
    return false;
  }

  const loadCompany = async () => {
    const prerequisites = await sendRequest(
      `/v1/project-request/get/prerequisites`,
      "GET",
    );
    setCompanyList(prerequisites.data.companies);
  };

  function loadRightSideContent(
    setRefresh,
    projectRequestDetail,
    backButton,
    showBackDropInLargeScreen,
  ) {
    const size = width <= smallDeviceWidth ? "xs" : "lg";

    projectRequestDetail.docList = getDocList(projectRequestDetail);

    let RightDrawerComponent;

    if (projectRequestDetail.version > 1) {
      RightDrawerComponent = ProjectRequestDrawer2;
    } else {
      RightDrawerComponent = ProjectRequestDrawer;
    }

    setRighbarContent(
      layoutDispatch,
      [
        <RightDrawerComponent
          key={"rb-body"}
          projectRequestDetail={projectRequestDetail}
          setRefresh={setRefresh}
        />,
      ],
      { size, backButton, showBackDropInLargeScreen },
    );
  }

  function getDocList(projectRequest) {
    let docList = [];

    projectRequest.costMarginList.forEach((cm) => {
      cm.documents.forEach((doc) => {
        docList.push({
          fileName: doc.fileName,
          url: doc.url,
          description: doc.description,
          type: "COST & MARGIN",
          loaded: false,
        });
      });
    });

    projectRequest.documents.forEach((doc) => {
      if (doc.type != "PNL") {
        docList.push({
          fileName: doc.fileName,
          url: doc.url,
          description: doc.description,
          type: doc.type,
          loaded: false,
        });
      }
    });

    return docList;
  }

  //  when click on invoice no in the list
  function handlePRClick(projectRequestDetail) {
    loadRightSideContent(setRefresh, projectRequestDetail, true, false);
    if (!layoutState.isRightbarOpened) {
      toggleRightbar(layoutDispatch);
    }
  }

  function filter() {
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const projectRequests = response.data?.requestList || [];
        setRequestList(projectRequests);
        setCollectionSize(response.data?.total);
        return () => {
          return projectRequests;
        };
      },
    );
  }

  function getFilterValues() {
    return {
      text: !!textFilter.trim()
        ? encodeURIComponent(textFilter.trim())
        : textFilter.trim(),
      status: statusFilter,
      projectManager: projectManagerFilter && projectManagerFilter._id,
      awardedDateFrom: awardedDateFrom
        ? getFromDate(awardedDateFrom)
        : undefined,
      awardedDateTo: awardedDateTo ? getToDate(awardedDateTo) : undefined,
      company: companyFilter && companyFilter.code,
      step: stepFilter,
    };
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/project-request/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${sortBy?.column && sortBy?.direction
        ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
        : ""
      }`,
      "GET",
      {},
    );
  }

  function onClickReferenceNo(id) {
    // props.history.push(`card/${id}`);
  }

  function onClickProjectId(projectId) {
    props.history.push(`/app/project/card/${projectId}`);
  }

  // generate and download csv report
  async function downloadReport() {
    const csvData = [];
    const response = await getPaginatedData(1, 0, getFilterValues(), sortBy);
    let requests = response?.data?.requestList || [];

    if (requests.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "No data to export.",
      });
      return;
    }

    for (const request of requests) {
      // claim record
      csvData.push({
        referenceNo: request.referenceNo,
        customer: request.customer,
        status: request.status,
        step: `'${request.step}'`,
        totalCostP: request.totalCost?.toString(),
        contractValueP: request.contractValue?.toString(),
        profitMarginP: `${request.profitMargin ?? "0"}%`,
        totalCostA: request.totalCostA?.toString(),
        contractValueA: request.contractValueA?.toString(),
        profitMarginA: `${request.profitMarginA ?? "0"}%`,
        totalPayment: request.totalPayment?.toString(),
        outstanding: request.outstandingBalance?.toString(),
        projectManager: request.projectManager
          ? `${request.projectManager?.username}-${request.projectManager?.name}`
          : "-",
        uenNo: request.uen.toString(),
        awardedDate: request.awardedDate
          ? moment(request.awardedDate).format("DD/MM/YYYY")
          : "-",
        progress: request.progress ? `${request.progress}%` : "-",
        project: request.project
          ? `${request.project.projectId}-${request.project.projectName}`
          : "-",
      });
      csvData.push({});
    }

    const today = moment().format("YYYYMMDD_HHmmss");
    const filename = `PROJECT-REQUEST-REPORT-${today}.csv`;
    downloadCSV(csvData, filename);
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}

      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Project Request List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {hasPrivilege(PrivilegeActions.add) && (
            <Button
              className={"primary"}
              onClick={() => props.history.push("card")}
            >
              New
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.project_request,
            PrivilegeActions.download_report,
          ) && (
              <Button className={"primary"} onClick={() => downloadReport()}>
                Download Report
              </Button>
            )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={10} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-search"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {width >= SMALL_DEVICE_WIDTH ? (
                <>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"status-filter"}
                      options={statusList}
                      getOptionLabel={(option) => option}
                      value={statusFilter}
                      onChange={(e, newValue) => setStatusFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Status"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<ArrowDownIcon />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"pm-filter"}
                      options={companyList}
                      getOptionLabel={(comp) => `${comp.name}-${comp.code}`}
                      value={companyFilter}
                      onChange={(e, data) => setCompanyFilter(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Company"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<ArrowDownIcon />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"pm-filter"}
                      options={projectManagerList}
                      getOptionLabel={(pm) => `${pm.userName}-${pm.userId}`}
                      value={projectManagerFilter}
                      onChange={(e, data) => setProjectManagerFilter(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Project Manager"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<ArrowDownIcon />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <DateFilter
                      label={"From"}
                      value={awardedDateFrom}
                      maxDate={awardedDateTo ?? null}
                      onChange={(newValue) => setAwardedDateFrom(newValue)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <DateFilter
                      label={"To"}
                      value={awardedDateTo}
                      minDate={awardedDateFrom ?? null}
                      onChange={(newValue) => setAwardedDateTo(newValue)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"pm-filter"}
                      options={stepList}
                      getOptionLabel={(option) => option}
                      value={stepFilter}
                      onChange={(e, newValue) => setStepFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Step"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<ArrowDownIcon />}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => toggleRightbar(layoutDispatch)}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <BackIcon />
                    ) : (
                      <FilterIcon className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={requestList}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(ProjectRequestList);
