import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { QtyChanger } from "./QtyChanger";
import { OrderTypeSelector } from "./OrderTypeSelector";
import Swal from "sweetalert2";
import { useHttpRequest } from "../../../hooks";
import { FormProvider, useForm } from "react-hook-form";
import AddOn from "./AddOn";
import { isArray } from "lodash/lang";

function initSelectAddOn(addOns) {
  const rs = {};
  for (const addOnKey of Object.keys(addOns)) {
    const addonEntityId = addOnKey.replace("addon_", "");
    // @ts-ignore
    rs[addonEntityId] = addOns[addOnKey];
  }
  return Object.values(rs);
}

export function FoodPresetCard({ product, store }) {
  const { isLoading, sendRequest } = useHttpRequest();
  const [showOrderPanel, setShowOrderPanel] = useState(false);
  const [qty, setQty] = useState(1);
  const [orderType, setOrderType] = useState("Dine In");

  const methods = useForm({
    mode: "all",
    defaultValues: {
      qty,
      orderType,
    },
  });
  const { register, handleSubmit } = methods;
  const [selectedAddOn, setSelectedAddOn] = useState(initSelectAddOn([]));

  const handleToggleOrderPlace = useCallback(
    () => setShowOrderPanel(!showOrderPanel),
    [showOrderPanel],
  );

  const callPlaceOrderApi = useCallback(
    async (order) => {
      try {
        const createdOrder = await sendRequest(
          "/v1/fnb-preset/place-order/" + order.storeCode,
          "POST",
          order,
        ).then((response) => response.data);

        Swal.fire({
          icon: "success",
          title: `Success`,
          text: `Order update successfully. Queue number is: ${createdOrder.order.queueNumber}`,
        });
      } catch (e) {
        Swal.fire({
          icon: "error",
          titleText: "Create order failed",
          text: e.message,
        });
      }
    },
    [sendRequest],
  );

  const handleOrderSubmit = (orderFormData) => {
    let addOn = [];
    if (orderFormData.items?.[0]?.addon) {
      /**

       {"addOn": [{ "addonId": 23, "selectedOptions": { "optionId": 29 } }]}

       */
      addOn = Object.values(orderFormData.items?.[0]?.addon ?? []).map(
        (selectedAddon) => {
          const result = {
            addonId: selectedAddon.addonId,
            selectedOptions: [],
            qty: 1,
          };

          if (!isArray(selectedAddon.selectedOptions)) {
            selectedAddon.selectedOptions = [selectedAddon.selectedOptions];
          }
          result.selectedOptions = selectedAddon.selectedOptions.map(
            (optionId) => ({ optionId }),
          );

          return result;
        },
      );
    }

    const order = {
      storeCode: store.storeCode,
      items: [{ sku: product.sku, qty, addOn }],
      orderType,
    };
    console.log(orderFormData);

    Swal.fire({
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      title: "Confirmation",
      text: `Process to order ${product.name} / Quantity: ${qty} / ${orderType}?`,
    }).then((response) => {
      if (response.isConfirmed) {
        return callPlaceOrderApi(order);
      }
    });
  };

  const handleOnAddOnChange = (addOn, options) => {
    selectedAddOn[addOn.entityId] = options;
    setSelectedAddOn({ ...selectedAddOn });
  };

  const productImgUrl = useMemo(() => {
    if (product?.images?.length) {
      return product.images[0].url;
    }
  }, [product]);

  return (
    <form onSubmit={handleSubmit(handleOrderSubmit)}>
      <Card>
        <CardMedia
          sx={{ height: 250, cursor: "pointer" }}
          image={productImgUrl}
          onClick={handleToggleOrderPlace}
        />

        <CardContent
          onClick={handleToggleOrderPlace}
          sx={{ cursor: "pointer" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>{product.name}</Typography>
            {showOrderPanel ? (
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            ) : (
              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            )}
          </Box>
        </CardContent>
        <Collapse in={showOrderPanel}>
          <CardActions>
            <FormProvider {...methods}>
              <Grid container spacing={{ xs: 2 }}>
                <Grid item xs={6}>
                  <OrderTypeSelector
                    value={orderType}
                    onChange={setOrderType}
                  />
                </Grid>
                <Grid item xs={6}>
                  <QtyChanger
                    onChange={(newQty) => setQty(newQty)}
                    value={qty}
                  />
                </Grid>
                {product?.availableAddOns?.map((addOn, i) => (
                  <Grid item xs={12}>
                    <AddOn
                      key={i}
                      addOn={addOn}
                      onChange={handleOnAddOnChange}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} className={"tile-action"}>
                  <Button
                    sx={{
                      width: "180px",
                    }}
                    disabled={qty <= 0 || isLoading || !orderType}
                    type={"submit"}
                  >
                    Place Order
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </CardActions>
        </Collapse>
        {isLoading ? <LinearProgress color={"success"} /> : <></>}
      </Card>
    </form>
  );
}
