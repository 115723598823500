import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { IconButton, InputAdornment } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import React from "react";
import { Controller } from "react-hook-form";

export default function DateController({
  label,
  value,
  onChange = (newValue) => {},
  minDate = undefined,
  maxDate = undefined,
  name,
  required,
  control,
  disabled = false,
  format = "dd/MM/yyyy",
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required && `${label} is required` }}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            disabled={disabled}
            label={label}
            value={field.value || value ? new Date(value) : null}
            format={format}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(newValue) => {
              onChange(newValue);
              field.onChange(newValue);
            }}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
                autoComplete: "off",
                InputProps: {
                  endAdornment: value && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => onChange(null)}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
              inputAdornment: {
                position: "start",
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
}
