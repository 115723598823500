import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useUserDispatch } from "../context/UserContext";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import FnbEventBookingCalendar from "../pages/fnb-event-booking/FnbEventBookingCalendar";

// context
import { useUserState } from "../context/UserContext";
import TwoFASetup from "../pages/login/TwoFASetup";
import TwoFAVerification from "../pages/login/TwoFAVerification";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  const userDispatch = useUserDispatch();

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/account" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/account" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <TwoFARoute path="/two-fa-setup" component={TwoFASetup} />
        <TwoFARoute path="/two-fa-verification" component={TwoFAVerification} />
        <FnbEventBookingCalendarRoute
          path="/fnb-event-booking-calendar"
          component={FnbEventBookingCalendar}
        />

        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }

  function TwoFARoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function FnbEventBookingCalendarRoute({ component, ...rest }) {
    const location = useLocation();
    const calendarToken = location.pathname.split("/")[2];

    if (!isAuthenticated && calendarToken) {
      localStorage.setItem("id_token", calendarToken);
      userDispatch({ type: "LOGIN_SUCCESS" });
    }

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated || calendarToken ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }
}
