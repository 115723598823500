import { Button } from "@mui/material";
import { PrivilegeActions } from "../../../data/privileges.enum";
import { PR_PANELS } from "./panels";
import CircularProgress from "@mui/material/CircularProgress";

import {
  checkElement,
  isApproved,
  isAwarded,
  isDeclined,
  isNew,
  isPending,
  isRejected,
  isStep_0_5,
  isStep_1_5,
  isStep_2_5,
  isStep_3_1_5,
  isStep_3_2_5,
  isStep_4_5,
  isStep_5_5,
  isSubmittedForApproval,
  PR_ACTIONS,
} from "./pr-config";

export const PR_BUTTONS = {
  SAVE: "SAVE",
  SUBMIT: "SUBMIT",
  CANCEL: "CANCEL",
  REVISE: "REVISE",
  NEED_REVISION: "NEED_REVISION",
  REJECT: "REJECT",
  APPROVE: "APPROVE",
  DECLINE: "DECLINE",
  AWARD: "AWARD",
  PRINT: "PRINT",
  NOT_APPLICABLE: "NOT_APPLICABLE",
  DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
};

const hasEditPrivilege = (hasPrivilege) => hasPrivilege(PrivilegeActions.edit);

const hasEditPaymentsPrivilege = (hasPrivilege) =>
  checkElement(PR_PANELS.PAYMENTS) &&
  hasPrivilege(PrivilegeActions.edit_payment_received);

const hasEditProgressPrivilege = (hasPrivilege) =>
  checkElement(PR_PANELS.PROGRESS) &&
  hasPrivilege(PrivilegeActions.edit_work_progress);

const hasEditPaymentTermsPrivilege = (hasPrivilege) =>
  checkElement(PR_PANELS.CLAIM_DEPOSIT) &&
  hasPrivilege(PrivilegeActions.edit_payment_schedule);

const hasEditCostMarginActualPrivilege = (hasPrivilege) =>
  checkElement(PR_PANELS.COST_MARGIN_ACTUAL) &&
  hasPrivilege(PrivilegeActions.edit_cost_margin_actual);

const canSave = (hasPrivilege) =>
  (checkElement(PR_BUTTONS.NEED_REVISION) ||
    checkElement(PR_BUTTONS.APPROVE) ||
    checkElement(PR_BUTTONS.REJECT) ||
    checkElement(PR_BUTTONS.NOT_APPLICABLE) ||
    checkElement(PR_BUTTONS.AWARD) ||
    checkElement(PR_BUTTONS.DECLINE)) &&
  hasPrivilege(PrivilegeActions.edit_other_documents);

export function cancelButton(props) {
  return (
    <Button onClick={() => props.history.push("/app/project-request/list")}>
      Cancel
    </Button>
  );
}

export function saveButton(
  { projectRequest, handleSubmit, onSubmit, hasPrivilege, step },
  forceShow,
) {
  return (
    (canSave(hasPrivilege) ||
      ((hasEditPrivilege(hasPrivilege) ||
        hasEditPaymentTermsPrivilege(hasPrivilege) ||
        hasEditPaymentsPrivilege(hasPrivilege) ||
        hasEditProgressPrivilege(hasPrivilege) ||
        hasEditCostMarginActualPrivilege(hasPrivilege)) &&
        (isRejected(projectRequest?.status) ||
          isNew(projectRequest?.status) ||
          isStep_0_5(step) ||
          isStep_5_5(step) ||
          (!isSubmittedForApproval(projectRequest) &&
            (isStep_1_5(step) || isStep_2_5(step) || isStep_3_1_5(step))) ||
          forceShow?.includes("save")))) && (
      <Button
        id={PR_BUTTONS.SAVE}
        className={"primary"}
        type={"button"}
        onClick={handleSubmit((data, e) => onSubmit(data, e, PR_ACTIONS.SAVE))}
      >
        Save
      </Button>
    )
  );
}

export function submitButton(
  { projectRequest, handleSubmit, onSubmit, hasPrivilege, step },
  forceShow,
) {
  return (
    hasPrivilege(PrivilegeActions.revise) &&
    (isNew(projectRequest?.status) ||
      isStep_0_5(step) ||
      forceShow?.includes("submit")) && (
      <Button
        id={PR_BUTTONS.SUBMIT}
        className={"primary"}
        type={"button"}
        onClick={handleSubmit((data, e) =>
          onSubmit(data, e, PR_ACTIONS.SUBMIT),
        )}
      >
        Submit
      </Button>
    )
  );
}

export function reviseButton({
  handleSubmit,
  onSubmit,
  hasPrivilege,
  projectRequest,
  step,
}) {
  return (
    hasPrivilege(PrivilegeActions.revise) &&
    !isSubmittedForApproval(projectRequest) &&
    (isStep_1_5(step) ||
      isStep_2_5(step) ||
      isStep_3_1_5(step) ||
      isStep_3_2_5(step)) && (
      <Button
        id={PR_BUTTONS.REVISE}
        className={"primary"}
        onClick={handleSubmit((data, e) =>
          onSubmit(data, e, PR_ACTIONS.REVISE),
        )}
      >
        {isStep_3_2_5(step) && isRejected(projectRequest?.status)
          ? `Submit`
          : `Submit`}
      </Button>
    )
  );
}

export function needRevisionButton(
  {
    onNeedRevision,
    hasPrivilege,
    projectRequest,
    step,
    hasRemainingCMApprovalsForUser,
  },
  forceShow,
) {
  return (
    ((hasPrivilege(PrivilegeActions.revision_step_1) && isStep_1_5(step)) ||
      (hasPrivilege(PrivilegeActions.revision_step_2) && isStep_2_5(step)) ||
      (hasPrivilege(PrivilegeActions["revision_step_3.1"]) &&
        isStep_3_1_5(step)) ||
      (hasPrivilege(PrivilegeActions["revision_step_3.2"]) &&
        isStep_3_2_5(step))) &&
    (isSubmittedForApproval(projectRequest) ||
      forceShow?.includes("needRevision")) &&
    (isStep_1_5(step) ||
      isStep_2_5(step) ||
      (isStep_3_1_5(step) && hasRemainingCMApprovalsForUser)) && (
      <Button
        id={PR_BUTTONS.NEED_REVISION}
        className={"primary"}
        onClick={onNeedRevision}
      >
        Need Revision
      </Button>
    )
  );
}

export function rejectButton({ onReject, hasPrivilege, projectRequest, step }) {
  return (
    ((hasPrivilege(PrivilegeActions.reject_step_1) && isStep_1_5(step)) ||
      (hasPrivilege(PrivilegeActions.reject_step_2) && isStep_2_5(step)) ||
      (hasPrivilege(PrivilegeActions["reject_step_3.1"]) &&
        isStep_3_1_5(step)) ||
      (hasPrivilege(PrivilegeActions["reject_step_3.2"]) &&
        isStep_3_2_5(step))) &&
    isSubmittedForApproval(projectRequest) &&
    isStep_3_2_5(step) && (
      <Button id={PR_BUTTONS.REJECT} className={"primary"} onClick={onReject}>
        Reject
      </Button>
    )
  );
}

export function approveButton(
  {
    handleSubmit,
    onSubmitApprove,
    hasPrivilege,
    projectRequest,
    step,
    hasRemainingCMApprovalsForUser,
  },
  forceShow,
) {
  return (
    ((hasPrivilege(PrivilegeActions.approve_step_1) && isStep_1_5(step)) ||
      (hasPrivilege(PrivilegeActions.approve_step_2) && isStep_2_5(step)) ||
      (hasPrivilege(PrivilegeActions["approve_step_3.1"]) &&
        isStep_3_1_5(step)) ||
      (hasPrivilege(PrivilegeActions["approve_step_3.2"]) &&
        isStep_3_2_5(step))) &&
    (isSubmittedForApproval(projectRequest) ||
      forceShow?.includes("approve")) &&
    (!isStep_3_1_5(step) ||
      (isStep_3_1_5(step) && hasRemainingCMApprovalsForUser)) && (
      <Button
        id={PR_BUTTONS.APPROVE}
        className={"primary"}
        type={"button"}
        onClick={handleSubmit((data, e) => onSubmitApprove(data, e))}
      >
        Approve
      </Button>
    )
  );
}

export function declineButton({
  onDecline,
  hasPrivilege,
  projectRequest,
  step,
}) {
  return (
    hasPrivilege(PrivilegeActions.decline) &&
    (isStep_4_5(step) || isStep_5_5(step)) &&
    !isDeclined(projectRequest?.status) &&
    !isAwarded(projectRequest?.status) && (
      <Button id={PR_BUTTONS.DECLINE} className={"primary"} onClick={onDecline}>
        Decline
      </Button>
    )
  );
}

export function awardButton({
  onSubmit,
  handleSubmit,
  hasPrivilege,
  projectRequest,
  step,
}) {
  return (
    hasPrivilege(PrivilegeActions.award) &&
    isStep_4_5(step) &&
    isApproved(projectRequest?.status) && (
      <Button
        id={PR_BUTTONS.AWARD}
        className={"primary"}
        onClick={handleSubmit((data, e) => onSubmit(data, e, PR_ACTIONS.AWARD))}
      >
        Award
      </Button>
    )
  );
}

export function printButton({ onPrint, hasPrivilege, step }) {
  return (
    hasPrivilege(PrivilegeActions.print) &&
    (isStep_3_2_5(step) || isStep_5_5(step)) && (
      <Button id={PR_BUTTONS.PRINT} className={"primary"} onClick={onPrint}>
        Print
      </Button>
    )
  );
}

export function downloadDocument(
  { onDownloadDocument, hasPrivilege, step },
  isLoading,
) {
  return (
    hasPrivilege(PrivilegeActions.download_documents) &&
    (isStep_3_2_5(step) || isStep_4_5(step) || isStep_5_5(step)) && (
      <Button
        id={PR_BUTTONS.PRINT}
        className={"primary"}
        startIcon={
          isLoading && (
            <CircularProgress style={{ color: "#b1b1b1" }} size={18} />
          )
        }
        disabled={isLoading}
        onClick={onDownloadDocument}
      >
        Download Document
      </Button>
    )
  );
}

export function notApplicableButton({
  onSubmit,
  handleSubmit,
  hasPrivilege,
  projectRequest,
  step,
}) {
  return (
    ((hasPrivilege(PrivilegeActions.not_applicable_step_1) &&
      isStep_1_5(step)) ||
      (hasPrivilege(PrivilegeActions.not_applicable_step_2) &&
        isStep_2_5(step)) ||
      (hasPrivilege(PrivilegeActions["not_applicable_step_3.1"]) &&
        isStep_3_1_5(step)) ||
      (hasPrivilege(PrivilegeActions["not_applicable_step_3.2"]) &&
        isStep_3_2_5(step))) &&
    isStep_2_5(step) &&
    !projectRequest?.revisionRequested &&
    isPending(projectRequest?.status) && (
      <Button
        id={PR_BUTTONS.NOT_APPLICABLE}
        className={"primary"}
        onClick={handleSubmit((data, e) =>
          onSubmit(data, e, PR_ACTIONS.NOT_APPLICABLE),
        )}
      >
        Not Applicable
      </Button>
    )
  );
}
