import { Alert, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";

export const EditorController = ({ control, label, required = false, name }) => {
  return (
    <Box className="editor">
      <Controller
        name={name}
        control={control}
        rules={{
          required: required
            ? {
                value: true,
                message: `${label} cannot be blank.`,
              }
            : false,
        }}
        render={(props) => (
          <>
            {!!props.fieldState.error && (
              <Alert color="error" icon={false}>
                {props.fieldState.error.message}
              </Alert>
            )}

            <Editor
              apiKey="t6arcldtglab0hdbr6zqf1nma0i2f8qy40184k8urt4rxsbs"
              value={props.field.value}
              init={{
                height: 400,
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect" +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help ",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={(text) => {
                props.field.onChange(text);
              }}
            />
          </>
        )}
      />
    </Box>
  );
};
