import React, { useCallback, useEffect, useState } from "react";
import { InputAdornment, Grid, IconButton, InputBase, TextField, FormControlLabel, Switch, Autocomplete } from "@mui/material";
import { validateText } from "../../../../helper/validate-textfield";
import { Add, KeyboardArrowDown} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import './Styles.css';
import { useForm } from "react-hook-form";

export default function FoodAddon({ addOn, addOnIndex, disabled, onChangeAddOn, onDeleteAddOn, onAddNewRowForAddOnOption, onChangeAddOnOption, onDeleteAddOnOption, handleBlurAddOn, handleBlurAddOnOption, itemList }) {

    const { formState: { errors: formErrors } } = useForm({ mode: "all" });

    const addonOptionColumnConfig = [
        {
            name: "SKU",
            width: "300px",
            cell: (row, index) => {
                return <Autocomplete disabled={disabled}
                    options={itemList}
                    getOptionLabel={(option) => option.hasOwnProperty('sku') ? `${option.sku} - ${option.name.toUpperCase()}` : option}
                    value={`${row.sku}`}
                    onBlur={(e) => handleBlurAddOnOption(addOnIndex, index, 'sku', e.target.value)}
                    onChange={(e, newValue) => 
                        {
                          onChangeAddOnOption('sku', addOnIndex, index, newValue)
                        }
                    }
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    sx={{ width: 300 }}
                    popupIcon={<KeyboardArrowDown />}
                />
            }
        },        
        {
            name: "Name",
            width: "300px",
            cell: (row, index) => {
                return <InputBase
                    disabled={disabled}
                    variant={"outlined"}
                    value={row.name}
                    onBlur={(e) => handleBlurAddOnOption(addOnIndex, index, 'name', e.target.value)}
                    onChange={(e) => onChangeAddOnOption('name', addOnIndex, index, e.target.value)}
                />
            }
        },
        // {
        //     name: "姓名",
        //     width: "300px",
        //     cell: (row, index) => {
        //         return <InputBase
        //             disabled={disabled}
        //             variant={"outlined"}
        //             value={row.nameInChinese}
        //             onBlur={(e) => handleBlurAddOnOption(addOnIndex, index, 'nameInChinese', e.target.value)}
        //             onChange={(e) => onChangeAddOnOption('nameInChinese', addOnIndex, index, e.target.value)}
        //         />
        //     }
        // },
        {
            name: "Price",
            width: '180px',
            cell: (row, index) => {
                return <InputBase
                    disabled={disabled}
                    type={"number"}
                    variant={"outlined"}
                    className={"dollar"}
                    value={row.price}
                    onChange={(e) => onChangeAddOnOption('price', addOnIndex, index, e.target.value)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
            }
        },
    ];

    if (!disabled) {
        addonOptionColumnConfig.unshift({
            name: "",
            width: "50px",
            button: true,
            cell: (row, index, column, id) => {
                return <IconButton onClick={(e) => { onDeleteAddOnOption(addOnIndex, index) }}><DeleteOutlineIcon /></IconButton>
            }
        });
    }

    return (
        <>
            <Grid container className={"form group"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                            id="name"
                            label="Name"
                            variant="outlined"
                            value={addOn.name}
                            onBlur={(e) => handleBlurAddOn(addOnIndex, e.target.value)}
                            onChange={(e) => {
                                onChangeAddOn('name', addOnIndex, e.target.value)
                                validateText(e);
                            }}
                            autoComplete="off"
                            error={!!formErrors?.name}
                            helperText={formErrors?.name?.message}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                            id="nameInChinese"
                            label="姓名"
                            variant="outlined"
                            value={addOn.nameInChinese}
                            onBlur={(e) => handleBlurAddOn(addOnIndex, e.target.value)}
                            onChange={(e) => {
                                onChangeAddOn('nameInChinese', addOnIndex, e.target.value)
                                validateText(e);
                            }}
                            autoComplete="off"
                            error={!!formErrors?.nameInChinese}
                            helperText={formErrors?.nameInChinese?.message}
                        />
                    </Grid>                     */}
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                        <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={<Switch
                                checked={(addOn.selectType == 'multiple' ? true : false)}
                                onChange={(e) => {
                                    onChangeAddOn('selectType', addOnIndex, (e.target.checked ? 'multiple' : 'single'))
                                }} />}
                            label="Multiple Choice"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                        <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={<Switch
                                checked={addOn.isMandatory}
                                onChange={(e) => {
                                    onChangeAddOn('isMandatory', addOnIndex, e.target.checked)
                                }} />}
                            label="Mandatory"
                        />
                    </Grid>

                    <Grid item xs={12} className={"table-action"}>
                        <IconButton onClick={(e) => { onDeleteAddOn(addOnIndex) }}><DeleteOutlineIcon /></IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={12} className={"table"}>
                                <DataTable
                                    columns={addonOptionColumnConfig}
                                    data={addOn.options}
                                    persistTableHead={true}
                                />
                            </Grid>
                            <Grid item xs={12} className={"table-action"}>
                                <IconButton
                                    onClick={(e) => { onAddNewRowForAddOnOption(addOnIndex) }}
                                    disabled={disabled}>
                                    <Add /></IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
