import React from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyboardArrowDown, Search } from "@mui/icons-material";
import DateFilter from "../../../components/DateFilter";
import {
  PrivilegeActions,
  PrivilegeModules,
} from "../../../data/privileges.enum";
import { useRoleAuthorization } from "../../../hooks/useRoleAuthorization";

export default function CalendarFilterMobile({
  statusFilter,
  setStatusFilter,
  statusList,
  optionLeaderList,
  optionLeaderFilter,
  setOptionLeaderFilter,
  requestedFromDate,
  setRequestedFromDate,
  requestedToDate,
  setRequestedToDate,
  washedDate,
  setWashedDate,
  checkedDate,
  setCheckedDate,
  lastDayFromRequestDate,
  lastDayToRequestDate,
  setLastDayFromRequestDate,
  setLastDayToRequestDate,
}) {
  const { auth } = useRoleAuthorization();

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Autocomplete
            id={"status-filter"}
            value={statusFilter}
            onChange={(e, newValue) => {
              setStatusFilter(newValue);
            }}
            options={statusList}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label={"Status"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        {auth.checkModulePrivilege(
          PrivilegeModules.car_wash_request,
          PrivilegeActions.option_supervisor,
        ) && (
          <Grid item xs={12}>
            <Autocomplete
              id={"option-leader-filter"}
              options={optionLeaderList}
              getOptionLabel={(option) => `${option.username}-${option.name}`}
              value={optionLeaderFilter}
              onChange={(e, newValue) => setOptionLeaderFilter(newValue)}
              renderInput={(params) => (
                <TextField {...params} label={"Team"} variant={"outlined"} />
              )}
              popupIcon={<KeyboardArrowDown />}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DateFilter
            label={"Last Day - From"}
            value={lastDayFromRequestDate}
            maxDate={lastDayToRequestDate}
            onChange={(newValue) => setLastDayFromRequestDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Last Day - To"}
            value={lastDayToRequestDate}
            minDate={lastDayFromRequestDate}
            onChange={(newValue) => setLastDayToRequestDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Request Date - From"}
            value={requestedFromDate}
            onChange={(newValue) => setRequestedFromDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Request Date - To"}
            value={requestedToDate}
            onChange={(newValue) => setRequestedToDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Washed Date"}
            value={washedDate}
            onChange={(newValue) => setWashedDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Checked Date"}
            value={checkedDate}
            onChange={(newValue) => setCheckedDate(newValue)}
          />
        </Grid>
      </Grid>
    </>
  );
}
