import React from "react";
import { Button } from '@mui/material';
import { useHttpRequest, useRoleAuthorization } from '../../../hooks';
import { PrivilegeActions, PrivilegeModules } from '../../../data/privileges.enum';

const PRINT_RECEIPT_URL = '/v1/fnb-order/print-receipt';

export default function PrintReceipt({ order, onSuccess, onError }) {
  const { sendRequest, isLoading } = useHttpRequest();
  const { auth } = useRoleAuthorization();

  const handleClick = () => sendRequest(
    PRINT_RECEIPT_URL,
    'POST',
    {
      'uuid': order?.uuid,
    },
  ).then(() => onSuccess(order)).catch(error => {
    onError(error);
  });

  const isAbleToShow = () => {
    return auth.isPrivilegeDataLoaded()
      && auth.checkModulePrivilege(PrivilegeModules.fnb_order, PrivilegeActions.view_detail)
      && order && order.isPaid;
  };

  return (<>
    {isAbleToShow() && <Button className={'primary'} disabled={isLoading} onClick={handleClick}>Print</Button>}
  </>);
}
