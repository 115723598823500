import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    drawerToggleBtn: {
        marginTop: 10
    },
    toggleRightBarIcon: {
        fontSize: 28
    },
    moreDataSwitchMobile: {
        float: "right",
        marginRight: -10
    }
}));
