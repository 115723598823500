import React from 'react';
import './FileUploadDownloadComponent.css'; // Import the CSS file
import {
  IconButton,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';

const FileUploadDownloadComponent = ({ fileType, fileLabel, uploadedFileName, onFileUpload, onFileDownload, onFileRemove, disableChanges }) => {

  function onInputClick() {
    if (uploadedFileName) {
      onFileDownload(fileType);
    } else if (!disableChanges) {
      onFileUpload(fileType);
    }
  }

  return (
    <div className="file-upload">
      <div className="file-label">
        {fileLabel}
      </div>
      <input
        id="uploaded-file-name"
        type="text"
        className="file-input"
        value={uploadedFileName}
        onClick={() => onInputClick()}
      />
      <IconButton
        disabled={disableChanges}
        color="primary"
        component="label"
        className={!uploadedFileName || uploadedFileName == '' ? 'btn-upload' : 'file-hide'}
        onClick={() => onFileUpload(fileType)}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        color="primary"
        component="label"
        className={!uploadedFileName || uploadedFileName == '' ? 'file-hide' : 'btn-download'}
        onClick={() => onFileDownload(fileType)}
      >
        <OpenInNewIcon />
      </IconButton>
      <IconButton
        disabled={disableChanges}
        color="primary"
        component="label"
        className={!uploadedFileName || uploadedFileName == '' ? 'file-hide' : 'btn-remove'}
        onClick={() => onFileRemove(fileType)}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
};

export default FileUploadDownloadComponent;