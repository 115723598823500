import {
  Autocomplete,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { KeyboardArrowDown } from "@mui/icons-material";

const SwitchController = ({
  name,
  disabled,
  value,
  label,
  defaultValue,
  ...props
}) => {
  const { control, watch, trigger } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControlLabel
            control={
              <Switch
                checked={field.value}
                onChange={(e) => {
                  watch(name);
                  field.onChange(e.target.checked);
                }}
                defaultValue={defaultValue}
                disabled={disabled}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};

export default SwitchController;
