import React from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyboardArrowDown, Search } from "@mui/icons-material";

export default function CalendarFilterMobile({
  enableOptions,
  enableFilter,
  setEnableFilter,
  handleKeyDown,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"enable-filter-mobile"}
            options={enableOptions}
            getOptionLabel={(option) => option.toUpperCase()}
            value={enableFilter}
            onChange={(e, newValue) => setEnableFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Enable"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
      </Grid>
    </>
  );
}
