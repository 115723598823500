import { Switch } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

export const customerExistingAirconConfig = (permission, handleEditProject) => [
    {
        name: "Order No.",
        width: "130px",
        selector: row => row._id,
        cell: (row) => {
            if(permission.allowedViewDetail){
                return (<Link to={"/app/customer-existing-aircon/card/" + row.salesOrderNo}>{row.salesOrderNo}</Link>)
            }else{
                return (<>{row.salesOrderNo}</>);
            }
        },
        sortable: true,
        sortField: 'salesOrderNo'
    },
    {
        name: "Order Date",
        width: "100px",
        selector: row => row.orderDate,
        cell: (row, index, column, id) => {
            return moment(row.orderDate).format('DD/MM/YYYY')
        },
        sortable: true,
        sortField: 'orderDate'
    }, 
    {
        name: "Installation Date",
        width: "150px",
        selector: row => row.installationDate,
        cell: (row, index, column, id) => {
            return moment(row.installationDate).format('DD/MM/YYYY')
        },
        sortable: true,
        sortField: 'installationDate'
    },  
    {
        name: "Remark",
        width: "250px",
        selector: row => row.remark,
        wrap: true,
        sortable: true,
        sortField: 'remark'
    },
    {
        name: "Ship-to Address",
        width: "250px",
        selector: row => row.shipToAddress,
        wrap: true,
        sortable: true,
        sortField: 'shipToAddress'
    },
    {
        name: "Ship-to Postal",
        width: "120px",
        selector: row => row.shipToPostalCode,
        sortable: true,
        sortField: 'shipToPostalCode'
    },
    {
        name: "Floor Plan",
        width: "100px",
        selector: row => (row.floorplans != '' && row.floorplans != undefined ? 'YES':'NO'),
        center: true,
        sortable: true,
        sortField: 'floorplans'
    },    
    {
        name: "Created Date",
        width: "150px",
        wrap: true,
        selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        sortable: true,
        sortField: 'createdAt'
    }, 
    {
        name: "Created By",
        width: "100px",
        selector: row => row.createdByData[0] == undefined ? '-':row.createdByData[0].username,
        sortable: true,
        sortField: 'createdBy'
    }
];
