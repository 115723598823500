import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Backdrop, CircularProgress
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

// styles
import { useParams } from "react-router-dom";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import ButtonControlAdmin from "./components/Buttons/ButtonControlAdmin";
import ButtonUpdateOrder from "./components/Buttons/ButtonUpdateOrder";
import BackButton from "./components/Buttons/BackButton";
import OrderPageHeaderView from "./components/OrderPage/OrderPageHeaderView";
import OrderItem from "./components/OrderPage/OrderItem";
import Swal from "sweetalert2";
import ButtonApproveOrder from "./components/Buttons/ButtonApproveOrder";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import ButtonPushToNavision from "./components/Buttons/ButtonPushToNavision";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import OrderPageWithdrawalView from "./components/OrderPage/OrderPageWithdrawalView";

export default function OrderView(props) {
  useEffect(() => {
    document.title = `${orderCode} - Material Request Card`
  }, []);

  const [selectedItems, setSelectedItems] = useState({});
  const [selectedItemsArr, setSelectedItemsArr] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const { auth } = useRoleAuthorization();
  const [currentOrder, setCurrentOrder] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const formValidation = useForm({ mode: 'all', defaultValues: currentOrder });
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    statusCode,
    sendRequest,
    clearError
  } = useHttpRequest();

  const { orderCode } = useParams();
  const [withdrawlOptionList, setWithdrawlOptionList] = useState([]);

  // redirect to default page if not authorized
  if (auth.isPrivilegeDataLoaded() && (!auth.checkModulePrivilege(PrivilegeModules.material_request,PrivilegeActions.view_detail))) {
    props.history.push('/app/dashboard');
  }

  useEffect(() => {
    if (!isLoading && !responseData) {
      sendRequest(`/v1/material-order/get-by-code/` + orderCode, 'GET')
      loadWithdrawalOption()
    }
  }, [orderCode])

  // Prepare Data
  useEffect(() => {
    if (responseData?.order) {
      setCurrentOrder(responseData.order)
      setTotalPrice(responseData.order.totalPrice)
      const newItemState = {};
      for (const item of responseData.order.items) {
        newItemState[item._id] = item;
      }
      setSelectedItems(newItemState)
    }
  }, [responseData])

  useEffect(() => {
    const newSelectedItemsArr = [];
    let totalPrice = 0;

    for (const i in selectedItems) {
      totalPrice += selectedItems[i].price * selectedItems[i].requestedQty;
      newSelectedItemsArr.push(selectedItems[i]);
    }

    setSelectedItemsArr(newSelectedItemsArr);
    setTotalPrice(totalPrice.toFixed(2));
  }, [selectedItems])

  function closeErrorMessage() {
    if (statusCode === 404) {
      props.history.push('/app/material')
    }

    clearError();
  }

  function loadWithdrawalOption() {
    sendRequest(`/v1/user/get-list-by-role/option_withdrawal`, "GET", {})
      .then(response => {
        const list = response.data?.users || [];
        setWithdrawlOptionList(list.filter(i => i.status));
      });
  }

  useEffect(() => {
    if (successMessage) {
      Swal.fire({
        icon: 'success',
        title: "Success",
        text: successMessage,
        willClose(popup) {
          setSuccessMessage(null)
        }
      })
    }
  }, [successMessage])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: "Error",
        text: responseData.message,
        willClose: closeErrorMessage
      })
    }
  }, [error, responseData])

  function isOrderFormDisabled() {
    return currentOrder.status === "CLOSED" || (currentOrder.status === "APPROVED" 
    && auth.checkModulePrivilege(PrivilegeModules.material_request,PrivilegeActions.need_approval, false))
  }

  return (<>
    {isLoading && <Backdrop style={{ zIndex: 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>}

    {currentOrder?.incrementId && 
      <FormProvider {...formValidation}>
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>Material Request Card - {orderCode}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <BackButton history={props.history} />
              <ButtonUpdateOrder
                auth={auth}
                currentOrder={currentOrder}
                selectedItemsArr={selectedItemsArr}
                orderCode={orderCode}
                formSubmitCaller={sendRequest}
                setSuccessMessage={setSuccessMessage}
                validationWrapper={formValidation.handleSubmit}
              />
              <ButtonApproveOrder
                auth={auth}
                selectedItemsArr={selectedItemsArr}
                orderCode={orderCode}
                formSubmitCaller={sendRequest}
                currentOrder={currentOrder}
                validationWrapper={formValidation.handleSubmit}
              />
              <ButtonControlAdmin
                auth={auth}
                selectedItemsArr={selectedItemsArr}
                orderCode={orderCode}
                formSubmitCaller={sendRequest}
                setSuccessMessage={setSuccessMessage}
                currentOrder={currentOrder}
                validationWrapper={formValidation.handleSubmit}
              />
              <ButtonPushToNavision
                auth={auth}
                orderCode={orderCode}
                formSubmitCaller={sendRequest}
                currentOrder={currentOrder}
                validationWrapper={formValidation.handleSubmit} />
            </Grid>
            <Grid
              item xs={12} sm={12} md={12} lg={12}>
              <OrderPageHeaderView currentOrder={currentOrder} disableForm={isOrderFormDisabled()}
                formValidation={formValidation} />
            </Grid>

            <Grid
              item xs={12} sm={12} md={12} lg={12}>
              <OrderPageWithdrawalView currentOrder={currentOrder} disableForm={isOrderFormDisabled()}
                formValidation={formValidation} withdrawlOptionList={withdrawlOptionList}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <OrderItem
                selectedItemsArr={selectedItemsArr}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                setSelectedItemsArr={setSelectedItemsArr}
                disableForm={isOrderFormDisabled()}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    }
  </>);
}
