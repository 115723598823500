import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  KeyboardArrowDown as ArrowDownIcon,
} from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";
import "./../Styles.css";

export const fnbFoodImagesTableColumnConfig = (
  onRemoveRow,
  onSelectImage,
  onChangeDefault,
  imagePreview,
  onRemoveImage,
  disabled,
) => [
    {
      name: "",
      width: "50px",
      button: true,
      cell: (row, index) => (
        <IconButton onClick={() => onRemoveRow(index)} disabled={disabled}>
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      name: "Picture (500x500)",
      width: "100px",
      center: true,
      cell: (row, index) => {
        return (
          <div className={"MuiFileForm-root"}>
            <IconButton
              className={row.url != "" ? "hide" : ""}
              onClick={() => onSelectImage(index)}
              disabled={disabled}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              className={row.url == "" ? "hide" : "show"}
              onClick={() => onRemoveImage(index)}
              disabled={disabled}
            >
              <RemoveIcon />
            </IconButton>
            <div className={"MuiFileForm-img"}>
              <img
                className={row.url == "" ? "hide" : "show"}
                src={row.isNewImage ? row.url_Local : row.url}
                onClick={(e) => imagePreview(row)}
              />
            </div>
          </div>
        );
      },
    },
    {
      name: "Default",
      width: "80px",
      center: true,
      selector: (row) => row.default,
      cell: (row, index) => {
        return (
          <Switch
            checked={row.default}
            onChange={(e, value) => onChangeDefault(index, value)}
            inputProps={{ "aria-label": "controlled" }}
            disabled={disabled}
          />
        );
      },
      sortable: true,
      sortField: "default",
    },
  ];
