import { DeleteOutline as DeleteIcon, AttachFile as FileIcon } from "@mui/icons-material";
import { IconButton, InputAdornment, InputBase } from "@mui/material";
import * as moment from "moment";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";

export const paymentsTableConfig =
    (editAllowed, onRemoveRow, onChangeRowValues, openDocumentDrawer) => {
        const columns = [
            {
                name: "Document",
                width: "70px",
                cell: (row, index) =>
                    <>
                        <IconButton
                            onClick={() => openDocumentDrawer(row, index)}>
                            <FileIcon />
                        </IconButton>
                        <small>{row.documents?.length ?? 0}</small>
                    </>
            },
            {
                name: "Billed Amount",
                width: "120px",
                right: true, // !editAllowed,
                cell: (row, index) => {
                    if (editAllowed) {
                        return <InputBase
                            type={"number"}
                            variant={"outlined"}
                            className={"dollar"}
                            inputProps={{
                                sx: { textAlign: 'start !important;' }
                            }}
                            value={row.billAmount ? row.billAmount : undefined}
                            onChange={(e) => onChangeRowValues(index, e.target.value, 'billAmount')}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                    }
                    return <>{convertThisToCurrency(row.billAmount?.toFixed(2))}</>
                }
            },
            {
                name: "Received Amount",
                width: "120px",
                right: true, // !editAllowed,
                cell: (row, index) => {
                    if (editAllowed) {
                        return <InputBase
                            type={"number"}
                            variant={"outlined"}
                            className={"dollar"}
                            inputProps={{
                                sx: { textAlign: 'start !important;' }
                            }}
                            value={row.paymentAmount ? row.paymentAmount : undefined}
                            onChange={(e) => onChangeRowValues(index, e.target.value, 'paymentAmount')}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                    }
                    return <>{convertThisToCurrency(row.paymentAmount?.toFixed(2))}</>
                }
            },
            {
                name: "Remark",
                width: "300px",
                cell: (row, index) => {
                    if (editAllowed) {
                        return <InputBase
                            variant={"outlined"}
                            value={row.remarks}
                            onChange={(e) => onChangeRowValues(index, e.target.value, 'remarks')}
                        />
                    }
                    return <>{row.remarks}</>
                }
            },
            {
                name: "",
                minWidth: "5px"
            },
            {
                name: "Created Date",
                width: "170px",
                wrap: true,
                selector: row => row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''
            },
            {
                name: "Modified Date",
                width: "170px",
                wrap: true,
                selector: row => row.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss') : ''
            }
        ];

        if (editAllowed) {
            columns.unshift({
                name: "",
                width: "50px",
                cell: (row, index) => (<IconButton onClick={() => onRemoveRow(index)}>
                    <DeleteIcon />
                </IconButton>)
            });
        }
        return columns;
    };
