import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  KeyboardArrowDown as ArrowDownIcon,
} from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";
import "./../Styles.css";
import * as moment from "moment";

export const machineServiceJobDocTableColumnConfig = (
  onRemoveRowDoc,
  onSelectDoc,
  onRemoveDoc,
  disabled,
) => [
  {
    name: "",
    width: "50px",
    button: true,
    cell: (row, index) => (
      <IconButton
        onClick={() => onRemoveRowDoc(row.docType, row.docName)}
        disabled={disabled}
      >
        <DeleteIcon />
      </IconButton>
    ),
  },
  {
    name: "Doc (image/pdf)",
    width: "350px",
    cell: (row, index) => {
      return (
        <>
          <IconButton
            className={row.imageUrl != "" ? "hide" : ""}
            onClick={() => onSelectDoc(row.docType, row.docName)}
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            className={row.imageUrl == "" ? "hide" : "show"}
            onClick={() => onRemoveDoc(row.docType, row.docName)}
            disabled={disabled}
          >
            <RemoveIcon />
          </IconButton>
          <p className={row.isNewDoc ? "show" : "hide"}>{row.docName}</p>
          <a
            className={!row.isNewDoc ? "show" : "hide"}
            href={row.imageUrl}
            target="_blank"
            download
          >
            {row.docName}
          </a>
        </>
      );
    },
  },
  {
    name: "Uploaded At",
    width: "200px",
    center: true,
    selector: (row) =>
      !row.uploadedAt
        ? ""
        : moment(row.uploadedAt).format("DD/MM/YYYY hh:mm:ss"),
    sortable: true,
    sortField: "default",
  },
];
