import React, { useEffect, useMemo, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import {
  Backdrop, Button, CircularProgress, Grid, TextField, Typography,
} from '@mui/material';
import { useHttpRequest, useRoleAuthorization } from '../../hooks';
import CollectMoney from './actions/CollectMoney';
import Swal from 'sweetalert2';
import moment from 'moment';
import { getOrderItemList } from './data-table/order-item-list';
import DataTable from 'react-data-table-component';
import ItemRowExpandable from './ItemRowExpandable';
import { PrivilegeActions, PrivilegeModules } from '../../data/privileges.enum';
import {convertThisToCurrency} from "../../helper";
import VoidOrder from './actions/VoidOrder';
import PrintReceipt from './actions/PrintReceipt';
import CheckPayment from './actions/CheckPayment';


const FnbOrderCard = (props) => {
  const {auth} = useRoleAuthorization();

  const { isLoading, sendRequest, httpRequestError } = useHttpRequest();
  const { orderId } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
      sendRequest(`/v1/fnb-order/get-order-by-id/${orderId}`)
        .then(response => setOrder(response.data));
    },
    [orderId, sendRequest],
  );

  useEffect(() => {
    httpRequestError && Swal.fire({icon: 'error', text: 'Error during querying order information.'})
  }, [httpRequestError])

  useEffect(() => {
    order && (document.title = `${order.orderNumber} - F&B Sales Order Card`)
    if (
      auth.isPrivilegeDataLoaded()
      && !auth.checkModulePrivilege(PrivilegeModules.fnb_order, PrivilegeActions.view_detail)
    ) {
      props.history.push("/app/dashboard");
    }
  }, [auth, order, props.history])

  const handleError = (err) => Swal.fire({icon: 'error', text: err.message})
  const handleSuccessAction = (order, goBack = true) => {
    setOrder(order);
    const message = order.queueNumber
      ? `Order update successfully. Queue number is: ${order.queueNumber}`
      : `Order update successfully.`;

    Swal.fire({ icon: 'success', text: message }).then(() => goBack && props.history.goBack());
  }
  const handleSuccessPrint = (order) => {
    Swal.fire({ icon: 'success', text: 'Order receipt printed.' });
  }
  const orderItemDataTableConfig = useMemo(() => getOrderItemList(), [])
  const orderItemList = useMemo(() => order?.items.filter((item) => item.isVisible) || [], [order])

  if (!order) {
    return <></>;
  }

  return (<>
    {isLoading &&
      (<Backdrop style={{ zIndex: 10 }} open={isLoading}><CircularProgress color={'inherit'} /></Backdrop>)
    }
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography variant={'h1'}>F&B Sales Order Card - {order.orderNumber}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} className={'action'}>
        <Button onClick={() => props.history.goBack()}>Back</Button>
        <CheckPayment onSuccess={handleSuccessAction} onError={handleError} order={order} />
        <CollectMoney onSuccess={handleSuccessAction} onError={handleError} order={order} />
        <PrintReceipt onSuccess={handleSuccessPrint} onError={handleError} order={order} />
        <VoidOrder onSuccess={handleSuccessAction} onError={handleError} order={order} />
      </Grid>

      <Grid item xs={12}>
        <Grid container className={"form"}>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography variant={"h2"}>Order Header</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Order No."}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={order.orderNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Reference No."}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={order?.navNumber || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Store"}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={(order.__store__||order.store)?.name.toUpperCase()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Order Time"}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={moment(order.orderTime).format('YYYY/MM/DD HH:mm:ss')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Queue No."}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={order?.queueNumber || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Order Type"}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={order.orderType.toUpperCase()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Pickup Time"}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={order.pickupTime ? moment(order?.pickupTime?.slotDateTime).format('YYYY/MM/DD HH:mm:ss') : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Payment Method"}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={(order.__payment__||order.payment)?.paymentMethod.toUpperCase()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Order Status"}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={order.status.toUpperCase()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      label={"Order Total"}
                      variant={"outlined"}
                      aria-disabled={true}
                      autoComplete={"off"}
                      disabled={true}
                      value={convertThisToCurrency(order.grandTotal)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/*ITEM LIST*/}
      <Grid item xs={12}>
        <Grid container className={"form"}>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography variant={"h2"}>Order Items</Typography>
              </Grid>
              <Grid item xs={12} className={'table'}>
                <DataTable
                  expandableRows
                  expandableRowsComponent={ItemRowExpandable}
                  expandableRowExpanded={ItemRowExpandable}
                  expandableRowsHideExpander={true}
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={orderItemDataTableConfig}
                  data={orderItemList}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>);
};

export default withRouter(FnbOrderCard);
