import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
function JsonView({ label, data }) {
  return (
    <Stack gap={1}>
      <Typography variant="h3">{label}</Typography>
      <ReactJson
        src={data}
        theme={"apathy:inverted"}
        style={{ padding: 18, overflow: "scroll", maxHeight: 300 }}
        collapsed={true}
      />
    </Stack>
  );
}
export default function ActivityLogDrawer({ activityLog }) {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} className="display">
      {activityLog.inputData && (
        <Grid item xs={12}>
          <JsonView label={"Input Data"} data={activityLog.inputData} />
        </Grid>
      )}

      {activityLog.error && (
        <Grid item xs={12}>
          <JsonView label={"Error Data"} data={activityLog.error} />
        </Grid>
      )}

      {activityLog.prevData && (
        <Grid item xs={12}>
          <JsonView label={"Previous Data"} data={activityLog.prevData} />
        </Grid>
      )}

      {activityLog.resData && (
        <Grid item xs={12}>
          <JsonView label={"Response Data"} data={activityLog.resData} />
        </Grid>
      )}
    </Grid>
  );
}
