import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    drawerToggleBtn: {
        marginTop: 10
    },
    toggleRightBarIcon: {
        fontSize: 28
    },
    addResource: {
        borderTop: '1px solid rgba(0, 0, 0, .15) !important'
    }
}));
