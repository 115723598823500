import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    Backdrop, CircularProgress, Grid, Button
} from "@mui/material";
import { useHttpRequest } from "../../hooks/useHttpRequest";

const FnbKitchenDashboardRedirect = (props) => {
    const { isLoading, sendRequest } = useHttpRequest();
    const [fnbKitchenDashboardBaseUrl, setFnbKitchenDashboardBaseUrl] = useState(null);

    useEffect(() => {
        loadPrerequisites();
    }, []);

    const loadPrerequisites = () => {
        sendRequest(`/v1/fnb-kitchen-dashboard/get-prerequisites`, "GET", {})
            .then(response => {
                let prerequisites = response.data.prerequisites;
                setFnbKitchenDashboardBaseUrl(prerequisites.fnbKitchenDashboardBaseUrl);
            });
    }

    const previewMenu = () => {
        window.open(`${fnbKitchenDashboardBaseUrl}/?token=${localStorage.getItem("id_token")}`, "Kitchen Dashboard");
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        {
                            <Button
                                className={"primary"} type={"button"}
                                onClick={previewMenu}>
                                Kitchen Dashboard
                            </Button>
                        }
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(FnbKitchenDashboardRedirect);