import React from "react";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { KeyboardArrowDown, PlaylistPlay, ArrowBack } from "@mui/icons-material";

export default function MobileFnbStoreEventFilters({
    fnbStores, storeFilter, onChangeStoreFilter, yesNoList, enableFilter, setEnableFilter
}) {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"store-filter"}
                    options={fnbStores}
                    getOptionLabel={(store) => `${store.name}`}
                    value={storeFilter}
                    onChange={(e, newValue) => onChangeStoreFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Store"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"enable-filter"}
                    options={yesNoList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={enableFilter}
                    onChange={(e, newValue) => setEnableFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Enable"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
        </Grid>
    </>);
};
