import convertArrayOfObjectsToCSV from "./convert-array-to-csv";

export default function downloadCSV(csvData, filename, columns = [], options = {isColumnnDef : true}) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(csvData, columns, options.isColumnnDef);
  if (csv == null) return false;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }
  const encodedURI = encodeURI(csv);
  const fixedEncodedURI = encodedURI.replaceAll('#', '%23'); // replace #
  link.setAttribute('href', fixedEncodedURI);
  link.setAttribute('download', filename);
  link.click();
  return true;
}

