import { useHttpRequest } from '../../../hooks';

export function useVoucherSetupSave() {
  const { isLoading, sendRequest } = useHttpRequest();

  const createVoucherRule = async (entity) => {
    const response = await sendRequest(
      `/v1/fnb-voucher-rule`,
      'POST',
      entity,
    );
    return response?.data;
  };

  const updateVoucherRule = async (voucherId, entity) => {
    const response = await sendRequest(
      `/v1/fnb-voucher-rule/${voucherId}`,
      'PUT',
      entity,
    );
    return response?.data;
  };

  const generateVoucherCodes = async (voucherId, qty, expiration) => {
    const response = await sendRequest(
      `/v1/fnb-voucher-rule/generate-manual-voucher-codes`,
      'POST',
      { voucherId, qty, expiration },
    );
    return response?.data;
  };


  return { isLoading, createVoucherRule, updateVoucherRule, generateVoucherCodes };
}