import { Grid } from "@mui/material";
import WorkflowClaimDeposit from "../components/Panels/WorkFlowClaimDeposit";
import WorkflowCostMargin from "../components/Panels/WorkFlowCostMargin";
import WorkflowCostMarginActual from "../components/Panels/WorkFlowCostMarginActual";
import WorkflowDocuments from "../components/Panels/WorkFlowDocuments";
import WorkflowFeedback from "../components/Panels/WorkFlowFeedback";
import WorkflowGeneral from "../components/Panels/WorkFlowGeneral";
import WorkflowHistory from "../components/Panels/WorkFlowHistory";
import WorkflowPayments from "../components/Panels/WorkFlowPayments";
import WorkflowProgress from "../components/Panels/WorkFlowProgress";
import { isAwarded, isStep_0_5, isStep_5_5 } from "./pr-config";
import WorkflowClaimDepositProjected from "../components/Panels/WorkFlowClaimDepositProjected";

export const PR_PANELS = {
  GENERAL: "GENERAL",
  HISTORY: "HISTORY",
  COST_MARGIN_PROJECTED: "COST_MARGIN_PROJECTED",
  COST_MARGIN_ACTUAL: "COST_MARGIN_ACTUAL",
  CLAIM_DEPOSIT: "CLAIM_DEPOSIT",
  DOCUMENTS: "DOCUMENTS",
  PROGRESS: "PROGRESS",
  PAYMENTS: "PAYMENTS",
  FEEDBACK: "FEEDBACK",
};

export function generalPanel(props) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <WorkflowGeneral {...props} />
    </Grid>
  );
}

export function historyPanel(props, step) {
  return (
    !isStep_0_5(step) && (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <WorkflowHistory {...props} />
      </Grid>
    )
  );
}

export function costMarginProjectedPanel(props) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <WorkflowCostMargin {...props} />
    </Grid>
  );
}

export function costMarginActualPanel(props, status) {
  return (
    isAwarded(status) && (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <WorkflowCostMarginActual {...props} />
      </Grid>
    )
  );
}

export function claimDepositPanel(props, status) {
  return (
    isAwarded(status) && (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <WorkflowClaimDeposit {...props} />
      </Grid>
    )
  );
}

export function claimDepositProjectedPanel(props) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <WorkflowClaimDepositProjected {...props} />
    </Grid>
  );
}

export function documentsPanel(props) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <WorkflowDocuments {...props} />
    </Grid>
  );
}

export function progressPanel(props, step, status) {
  return (
    isStep_5_5(step) &&
    isAwarded(status) && (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <WorkflowProgress {...props} />
      </Grid>
    )
  );
}

export function paymentPanel(props, step, status) {
  return (
    isStep_5_5(step) &&
    isAwarded(status) && (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <WorkflowPayments {...props} />
      </Grid>
    )
  );
}

export function feedbackPanel(props, status, hasEditPermission) {
  return (
    hasEditPermission &&
    isAwarded(status) && (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <WorkflowFeedback {...props} />
      </Grid>
    )
  );
}
