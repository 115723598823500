import moment from 'moment';
import { Link } from "react-router-dom";
import { convertThisToCurrency } from '../../../helper/convert-this-to-currency';

export const getOrderItemList = () => [
  {
    name: 'SKU',
    width: "90px",
    selector: row => row.sku,
  },{
    name: 'Name',
    width: "300px",
    selector: row => row.name.toUpperCase(),
  },{
    name: 'Price',
    width: "100px",
    selector: row => convertThisToCurrency(row.activePrice),
    right: true,
  },{
    name: 'Qty.',
    width: "50px",
    selector: row => row.qtyOrdered,
    right: true,
  },{
    name: 'Row Total',
    width: "110px",
    selector: row => convertThisToCurrency(row.rowTotal),
    right: true,
  },{
    name: 'Line Status',
    width: "90px",
    selector: row => row.itemLineStatus.toUpperCase(),
  },
];
