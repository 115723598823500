import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    Backdrop, CircularProgress, List, ListItem, ListItemText, IconButton, Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import useStyles from "./styles";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import './Styles.css';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import './Styles.css'
import moment from "moment";
import { OpenInNewOutlined as MenuIcon, Close as CloseIcon } from '@mui/icons-material';

const FnbEventBookingCalendar = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    // handling form when adding / editing
    const { handleSubmit, register, formState: { errors: formErrors },
        control, setError, getValues, setValue } = useForm({ mode: "all" });

    // un-assigned sub-con list
    const [eventList, setEventList] = useState([]);
    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [openEventDetail, setOpenEventDetail] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();
    const [selEventTime, setSelEventTime] = useState('');
    const [fnbEventMenuBaseUrl, setFnbEventMenuBaseUrl] = useState(null);

    const customModalStyles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        outline: 'none',
    };

    useEffect(() => {
        document.title = "F&B Event Booking Calendar";
        loadPrerequisites();
        loadEvents();

        // redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.fnb_google_calendar)) {
            props.history.push('/app/dashboard');
        }
    }, []);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
            });
            setMessage('');
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    function loadEvents() {
        if (!isLoading && !responseData) {
            sendRequest(`/v1/fnb-event-booking/get-booking-event-list`, "GET", {})
                .then(response => {
                    setEventList(response.data.events);
                });
        }
    }

    const loadPrerequisites = () => {
        sendRequest(`/v1/fnb-event-booking/get-prerequisites`, "GET", {})
            .then(response => {
                let prerequisites = response.data.prerequisites;
                setFnbEventMenuBaseUrl(prerequisites.fnbEventMenuBaseUrl);
            });
    }

    function handleEventClick(eventInfo) {
        let selEvent = eventList.filter((e) => { return e.id == eventInfo.event.id })[0];
        setSelEventTime(`${moment(selEvent.start).format('ddd, MMM D, YYYY LT').toUpperCase()} - ${moment(selEvent.end).format('LT')}`);
        setSelectedEvent(selEvent);
        setOpenEventDetail(true);
    }

    function closeEventDetail() {
        setOpenEventDetail(false);
    }

    const openMenu = () => {
        window.open(`${fnbEventMenuBaseUrl}/${selectedEvent.menuId}`, "Menu Preview", "menubar=0,resizable=0,width=605,height=795");
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        <div class="calendar">
            <Typography variant={"h1"}>F&B Event Booking Calendar</Typography>

            <div>
                <FullCalendar
                    initialView='dayGridMonth'
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    weekends={true}
                    events={eventList}
                    eventColor="gold"
                    eventClick={handleEventClick}
                    displayEventTime={true}
                />
            </div>

            {!!selectedEvent &&
                <Modal
                    open={openEventDetail}
                    onClose={closeEventDetail}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box className="customModalBox" sx={{ ...customModalStyles, width: 450 }}>
                        <Button id="btnCloseModal" onClick={closeEventDetail}><CloseIcon /></Button>
                        <div id="child-modal-description" class="display">
                            <Typography variant={"h2"}>{selectedEvent.title}</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={`When`}
                                        secondary={selEventTime}
                                    />
                                </ListItem>
                                <ListItem className={!selectedEvent.location ? 'hide_this' : ''}>
                                    <ListItemText
                                        primary={`Where`}
                                        secondary={selectedEvent.location.toUpperCase()}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={`No. of Pax`}
                                        secondary={selectedEvent.noOfPax}
                                    />
                                </ListItem>
                                <ListItem className={!selectedEvent.specialRequest ? 'hide_this' : ''}>
                                    <ListItemText
                                        primary={`Special Request`}
                                        secondary={selectedEvent.specialRequest}
                                    />
                                </ListItem>
                                <ListItem className={!selectedEvent.requestedBy ? 'hide_this' : ''}>
                                    <ListItemText
                                        primary={`Requested By`}
                                        secondary={selectedEvent.requestedBy}
                                    />
                                </ListItem>
                                <ListItem className={!selectedEvent.remark ? 'hide_this' : ''}>
                                    <ListItemText
                                        primary={`Remark`}
                                        secondary={selectedEvent.remark}
                                    />
                                </ListItem>
                                <ListItem className={selectedEvent.menuName == '' ? 'hide_this' : ''}>
                                    <ListItemText id="lstItemTextMenu"
                                        primary={`Menu`}
                                        secondary={selectedEvent.menuName}
                                        onClick={openMenu}
                                    />
                                    <IconButton onClick={openMenu}><MenuIcon /></IconButton>
                                </ListItem>
                            </List>
                        </div>
                    </Box>
                </Modal>}
        </div>
    </>);
};

export default withRouter(FnbEventBookingCalendar);