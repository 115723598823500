import React, { useEffect, useState } from "react";
import {
  Grid, CircularProgress, Typography, Button, TextField
} from "@mui/material";
import { withRouter, Link } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo
import logo from "./gain-city-logo.png";

// context
import { useUserDispatch } from "../../context/UserContext";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import Swal from "sweetalert2";
import { useQuery } from "../../hooks/useQuery";

export const NO_PASSWORD_MODE = 'NO_PASSWORD';

const Login = (props) => {
  const classes = useStyles();

  // global
  const userDispatch = useUserDispatch();

  // local
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const { isLoading, httpRequestError: error, responseData, sendRequest: loginAPI } = useHttpRequest();

  const processLogin = async () => {
    await loginAPI(
      "/v1/user/signin", "POST", {
      username: loginValue, password: passwordValue,
    },
    );
    return false;
  };

  const query = useQuery()
  const loginMode = query.get('mode');
  const storeCode = query.get('storeCode');

  if (storeCode) {
    localStorage.setItem('storeCode', storeCode);
  }

  useEffect(() => {
    if (responseData?.token) {
      localStorage.setItem("id_token", responseData?.token);
      userDispatch({ type: "LOGIN_SUCCESS" });

      if (responseData.enabledTwoFA === true) {
        if (responseData.requireTwoFASetup) {
          props.history.push({ pathname: "/two-fa-setup" });
        }
        else {
          props.history.push({ pathname: "/two-fa-verification" });
        }
      }
    }

    if (error && responseData?.message) {
      Swal.fire({
        icon: 'question',
        title: 'Oops...',
        text: responseData?.message,
      })
    }
  }, [responseData, error]);

  return (<Grid container className={classes.container}>

    <div className={classes.formContainer}>
      <React.Fragment>
        <Typography variant="h1" align={"center"}><img src={logo} alt={"GainCity"} /></Typography>
        <form onSubmit={processLogin} className={"login"}>
          <Typography variant="h2" align={"center"}>Partner Portal</Typography>
          <TextField
            id="username"
            label={"Staff No"}
            value={loginValue}
            onChange={e => setLoginValue(e.target.value)}
            placeholder=""
            autoFocus={true}
            type="text"
            fullWidth
            variant="outlined"
          />
          {loginMode !== NO_PASSWORD_MODE && <TextField
            id="password"
            label={"Password"}
            value={passwordValue}
            onChange={e => setPasswordValue(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />}
          <div className="action">
            {isLoading ? (<CircularProgress size={26} className={classes.loginLoader} />) : (<Button
              onClick={processLogin}
              hidden={true}
              disabled={loginValue.length === 0}
              onSubmit={processLogin}
              className={"primary"}
              type={"submit"}
            >
              Login
            </Button>)}
            {loginMode === NO_PASSWORD_MODE && <Link
              onClick={() => { props.history.push('/login') }}
              to="#"
            >
              Normal Login
            </Link>}
          </div>
        </form>
      </React.Fragment>
    </div>
  </Grid>);
};

export default withRouter(Login);
