import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import Swal from "sweetalert2";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import * as Papa from "papaparse";
import DataTable from "react-data-table-component";
import { unpaidListColumnConfig } from "./data-table/unpaid-list-column-config";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import useStyles from "./styles";

const PayServiceClaim = () => {
    const classes = useStyles();
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();
    const { auth } = useRoleAuthorization();

    const [message, setMessage] = useState('');
    const [fileError, setFileError] = useState('');
    const [file, setFile] = useState(null);
    const [unpaidList, setUnpaidList] = useState([]);
    const allowedFileTypes = ['text/csv'];

    const hasPrivilege = (privilege) => auth.checkModulePrivilege(
        PrivilegeModules.pay_service_claim, privilege);

    //#region useEffects

    useEffect(() => {
        document.title = "Project Request";
    });

    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message
            });
            setMessage('');
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (fileError) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: fileError
            });
            setFileError('');
        }
    }, [fileError]);

    //#endregion

    const handleClick = () => {
        document.getElementById("input-file-upload").click();
    };

    function uploadFile(e) {
        const selectedFiles = e.target.files;
        if (selectedFiles.length > 0) {
            setUnpaidList([]);
            const file = selectedFiles[0];
            if (allowedFileTypes.includes(file.type)) {
                setFile(file);
            } else {
                setFile(null);
                setFileError('Only CSV files are allowed.');
            }
        }
        e.target.value = null;
    }

    function extractPayload(dataArray) {
        const headerRow = dataArray.at(0) || [];
        if (headerRow?.length > 0) {
            const pksNoIndex = headerRow.findIndex(r => r === 'PKS No.');
            const paymentDateIndex = headerRow.findIndex(r => r === 'Date of Payment');
            if (pksNoIndex > -1 && paymentDateIndex > -1) {
                const dataRows = dataArray.slice(1)
                    .filter(row => !!row.at(pksNoIndex));

                return dataRows.map(row => ({
                    pksNo: row.at(pksNoIndex),
                    paymentDate: row.at(paymentDateIndex)
                }));
            }
        }
        return [];
    }

    function onImport() {
        if (file) {
            const reader = new FileReader();
            reader.onload = async function (e) {
                const results = Papa.parse(reader.result);
                const payload = extractPayload(results?.data);
                if (payload.length > 0) {
                    await payServiceClaims(payload);
                } else {
                    setFileError('Uploaded file is invalid.');
                }
            }
            reader.readAsText(file);
        }
        setFile(null);
    }

    async function payServiceClaims(payload) {
        const result = await sendRequest('/v1/service-claim/pay',
            'POST', payload);
        if (result?.status === 200 && result?.data) {
            setUnpaidList(result.data.unpaidList || []);
            result.data.updated && setMessage(result.data.message);
        }
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} /></Backdrop>}
        <form>
            <div className="form-group">
                <input type="file" className="form-control" id="input-file-upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={uploadFile} />
            </div>
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant={"h1"}>Pay Service Claim</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                    {
                        hasPrivilege(PrivilegeActions.import) &&
                        <Button className={"primary"} disabled={!file}
                            onClick={onImport}>Import</Button>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid className={"form"}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"sub-action"}>
                                <Typography variant={"h2"}>Upload Feed</Typography>
                                {
                                    hasPrivilege(PrivilegeActions.import) &&
                                    <Button type={"button"} onClick={handleClick}><AddIcon /></Button>
                                }
                                {
                                    file && <Typography
                                        variant={"h4"}
                                        className={classes.fileName}>{file?.name}</Typography>
                                }
                            </Grid>
                            {
                                unpaidList.length > 0 &&
                                (<Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={"table"}>
                                    {
                                        hasPrivilege(PrivilegeActions.import) && <DataTable
                                            columns={unpaidListColumnConfig}
                                            data={unpaidList}
                                            persistTableHead={true}
                                        />
                                    }
                                </Grid>)
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </>);
};

export default withRouter(PayServiceClaim);   