import { Autocomplete, TextField } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

export function OptionSelectComponent({
  label,
  value,
  optionList,
  onChange,
  id,
}) {
  return (
    <Autocomplete
      id={id}
      options={optionList}
      getOptionLabel={(option) => option.toUpperCase()}
      value={value}
      onChange={(e, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={"outlined"} />
      )}
      popupIcon={<KeyboardArrowDown />}
    />
  );
}
