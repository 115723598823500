import {
    Button, TextField, DialogTitle,
    DialogContent, Dialog, DialogActions, Grid, Typography
} from "@mui/material";
import { validateText } from "../../../../helper/validate-textfield";

const ProjectRequestReviseModal = (props) => {

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;

        props.setOpen(false);
    };

    const handleReviseMessage = (event) => {
        validateText(event);
        props?.setReviseMessage(event.target.value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            open={props.open}
            onClose={handleClose}
            disableEscapeKeyDown={true}
            maxWidth={"sm"}>
            <DialogTitle component={"div"}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Need Revision</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <TextField
                    id={"revise-message"}
                    label={"Comment"}
                    variant={"outlined"}
                    autoFocus
                    fullWidth
                    defaultValue={props.reviseMessage}
                    onChange={handleReviseMessage}
                    autoComplete={"off"}
                />
            </DialogContent>
            <DialogActions className={"action"} >
                <Button
                    className={"primary"}
                    disabled={!props.reviseMessage}
                    onClick={(e) => props.onSubmitRevise(e)}>Proceed</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProjectRequestReviseModal;   