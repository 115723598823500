import { Grid, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import { setRighbarContent, toggleRightbar, useLayoutDispatch, useLayoutState }
    from "../../../../context/LayoutContext";
import { SMALL_DEVICE_WIDTH } from "../../../../data/constants";
import { useHttpRequest } from "../../../../hooks/useHttpRequest";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { historyTableConfig } from "../../data-table/history-table-config";
import { PR_PANELS } from "../../helper/panels";
import HistoryDetailsDrawer from "../Drawer/HistoryDetailsDrawer";

const WorkflowHistory = (props) => {
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();
    const { sendRequest } = useHttpRequest();
    const columns = historyTableConfig(openHistoryRecord);

    async function openHistoryRecord(history) {
        if (history?._id) {
            const result = await sendRequest(`/v1/project-request-history/${history?._id}`, "GET");
            if (result?.status === 200 && result?.data?.history) {
                loadHistoryDrawer(result?.data?.history);
            }
        }
    }

    function loadHistoryDrawer(history, backButton = true, showBackDropInLargeScreen = false) {
        const size = width <= SMALL_DEVICE_WIDTH ? 'xs' : 'lg';
        setRighbarContent(layoutDispatch,
            [<HistoryDetailsDrawer key={'rb-body'} history={history} />],
            { size, backButton, showBackDropInLargeScreen });
        if (!layoutState.isRightbarOpened) { toggleRightbar(layoutDispatch); }
    }

    return (<>
        <Grid container className={"form"} id={PR_PANELS.HISTORY}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Typography variant={"h2"}>History</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={"table"}>
                        <DataTable
                            fixedHeader={true}
                            persistTableHead={true}
                            columns={columns}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            data={props?.historyRecords} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default WorkflowHistory;   