import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";
import {
  Search as SearchIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  KeyboardArrowDown,
  Event as CalendarIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  useLayoutDispatch,
  useLayoutState,
  toggleRightbar,
  setRighbarContent,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import MobileMachineServiceJobFilters from "./components/Mobile/MobileMachineServiceJobFilters";
import { machineServiceJobTableColumnConfig } from "./data-table/machine-service-job-table-column-config";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import moment from "moment";
import downloadCSV from "../../helper/download-csv";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
  DesktopDateTimePicker,
} from "@mui/x-date-pickers";
import { useUserState } from "../../context/UserContext";
import DateFilter from "../../components/DateFilter";

const MachineServiceJobList = (props) => {
  const { userInfo } = useUserState();
  const classes = useStyles();

  // handle loading & http
  const { isLoading, sendRequest } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  // layout state
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  // filters
  const [textFilter, setTextFilter] = useState("");
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  const [serviceJobList, setServiceJobList] = useState([]);
  const smallDeviceWidth = 1200;

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  const [message, setMessage] = useState("");
  const yesNoList = ["Yes", "No"];

  const [companyList, setCompanyList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [companyFilter, setCompanyFilter] = useState(null);
  const [storeFilter, setStoreFilter] = useState(null);
  const [areaFilter, setAreaFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState({
    value: "PENDING",
    description: "PENDING",
  });
  const [serviceDateFrom, setServiceDateFrom] = useState(null);
  const [serviceDateTo, setServiceDateTo] = useState(null);

  const mobileFilterProps = {
    companyFilter,
    setCompanyFilter,
    companyList,
    storeList,
    storeFilter,
    setStoreFilter,
    areaList,
    areaFilter,
    setAreaFilter,
    statusList,
    statusFilter,
    setStatusFilter,
    serviceDateFrom,
    setServiceDateFrom,
    serviceDateTo,
    setServiceDateTo,
  };

  const [loadMobileFilters, setLoadMobileFilters] = useState(false);

  useEffect(() => {
    document.title = "Machine Service Job List";

    loadPrerequisites();

    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.machine_service_job,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useMemo(() => {
    if (auth.isPrivilegeDataLoaded()) {
      let defaultStatusForFilter = "";

      if (
        auth.checkModulePrivilege(
          PrivilegeModules.machine_service_job,
          PrivilegeActions.filter_status_pending,
          false,
        )
      ) {
        defaultStatusForFilter = { value: "PENDING", description: "PENDING" };
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.machine_service_job,
          PrivilegeActions.filter_status_done,
          false,
        )
      ) {
        defaultStatusForFilter = { value: "DONE", description: "DONE" };
      }

      if (!!defaultStatusForFilter) {
        setStatusFilter(defaultStatusForFilter);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: setRefresh(true),
      });
    }
  }, [message]);

  // filter data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    setRefresh(true);
  }, [
    companyFilter,
    storeFilter,
    areaFilter,
    statusFilter,
    serviceDateFrom,
    serviceDateTo,
  ]);

  useEffect(() => {
    if (width <= smallDeviceWidth) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MobileMachineServiceJobFilters
            key={"rb-body"}
            {...mobileFilterProps}
          />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [serviceJobList, width, loadMobileFilters]);

  // set columns in data table
  const columns = machineServiceJobTableColumnConfig(
    {
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.machine_service_job,
        PrivilegeActions.view_detail,
      ),
    },
    handleOpenServiceJob,
  );

  const loadPrerequisites = () => {
    sendRequest(`/v1/machine-service-job/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        setCompanyList(prerequisites.companyList);
        setStoreList(prerequisites.storeList);
        setAreaList(prerequisites.areaList);
        setStatusList(prerequisites.MachineServiceJobStatus);
      },
    );
  };

  function filter() {
    // set list filters
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const serviceJobs = response.data?.serviceJobList || [];
        setServiceJobList(serviceJobs);
        setCollectionSize(response.data?.total);
        return () => {
          return serviceJobs;
        };
      },
    );
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      company: !companyFilter ? undefined : companyFilter._id,
      store: !storeFilter ? undefined : storeFilter.storeId,
      area: !areaFilter ? undefined : areaFilter.areaCode,
      status: !statusFilter ? undefined : statusFilter.value,
      serviceDateFrom: serviceDateFrom
        ? getFromDate(serviceDateFrom)
        : undefined,
      serviceDateTo: serviceDateTo ? getToDate(serviceDateTo) : undefined,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/machine-service-job/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${
          sortBy?.column && sortBy?.direction
            ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
            : ""
        }`,
      "GET",
      {},
    );
  }

  function isEligibleToDownloadReport() {
    return (
      !!companyFilter &&
      statusFilter?.value === "CLOSED" &&
      serviceJobList?.length > 0 &&
      !!serviceDateFrom &&
      !!serviceDateTo
    );
  }

  // generated and download csv report
  async function downloadReport(uploaded_doc) {
    const csvData = [];
    const response = await getPaginatedData(1, 0, getFilterValues(), sortBy);
    let serviceJobs = response?.data?.serviceJobList || [];

    if (serviceJobs.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "No data to export.",
      });
      return;
    }

    for (const servJob of serviceJobs) {
      csvData.push({
        no: servJob.no,
        serviceJobNo: servJob.serviceJobNo,
        storeId: servJob.storeId,
        company: servJob.company,
        area: servJob.area,
        location: servJob.location,
        brand: servJob.brand,
        model: servJob.model,
        serialNo: servJob.serialNo,
        serviceDate: servJob.serviceDate
          ? moment(servJob.serviceDate).format("DD/MM/YYYY")
          : "",
        team: servJob.servicedByUser,
      });
    }

    const today = moment().format("YYYYMMDD_HHmmss");

    if (uploaded_doc) {
      const filename = `MACHINE-SERVICE-JOB-REPORT-DOC-${today}`;

      let documents = serviceJobs.reduce((accumulator, currentValue) => {
        const serviceJobDocsWithStoreId = currentValue.serviceJobDoc.map(
          (doc) => {
            return {
              ...doc,
              storeId: currentValue.storeId,
              serviceJobNo: currentValue.serviceJobNo,
            };
          },
        );

        return accumulator.concat(serviceJobDocsWithStoreId);
      }, []);

      downloadDocuments(filename, documents);
    } else {
      const filename = `MACHINE-SERVICE-JOB-REPORT-${today}.csv`;
      downloadCSV(csvData, filename);
    }
  }

  const downloadDocuments = async (fileName, documents) => {
    const zip = new JSZip();

    for (const doc of documents) {
      try {
        const response = await axios.get(doc.imageUrl, {
          responseType: "blob",
        });
        const folderName = `#${doc.storeId}`;
        const fileBaseName = `${doc.serviceJobNo}-${
          doc.docType === "service-job" ? "service-report" : "check-list"
        }`;
        const fileExtension = doc.imageUrl.split(".").pop();
        const fileName = `${fileBaseName}.${fileExtension}`;

        let folder = zip.folder(folderName);
        if (!folder) {
          folder = zip.folder(folderName);
        }

        folder.file(fileName, response.data);
      } catch (error) {
        console.error("Error fetching image:", doc.imageUrl, error);
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${fileName}.zip`);
    });
  };

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  // go to edit view
  async function handleOpenServiceJob(companyId) {
    if (
      auth.checkModulePrivilege(
        PrivilegeModules.machine_service_job,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push(`card/${companyId}`);
    }
  }

  // open mobile filters drawer
  function openMobileFilters() {
    setLoadMobileFilters(true);
    toggleRightbar(layoutDispatch);
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Machine Service Job List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.machine_service_job,
            PrivilegeActions.download_report,
          ) && (
            <Button
              className={"primary"}
              onClick={() => downloadReport(false)}
              disabled={!isEligibleToDownloadReport()}
            >
              Download Report
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.machine_service_job,
            PrivilegeActions.download_report,
          ) && (
            <Button
              className={"primary"}
              onClick={() => downloadReport(true)}
              disabled={!isEligibleToDownloadReport()}
            >
              Download Documents
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-searchbox"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {width >= smallDeviceWidth ? (
                <>
                  <Grid item xs={6} md={2}>
                    <Autocomplete
                      id={"company-filter"}
                      options={companyList}
                      getOptionLabel={(company) =>
                        `${company.name.toUpperCase()}`
                      }
                      value={companyFilter}
                      onChange={(e, newValue) => setCompanyFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Company"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Autocomplete
                      id={"store-filter"}
                      options={storeList}
                      getOptionLabel={(store) => `${store.storeId}`}
                      value={storeFilter}
                      onChange={(e, newValue) => setStoreFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Store ID"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Autocomplete
                      id={"area-filter"}
                      options={areaList}
                      getOptionLabel={(area) =>
                        `${area.areaCode.toUpperCase()}`
                      }
                      value={areaFilter}
                      onChange={(e, newValue) => setAreaFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Area"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <DateFilter
                      label={"Service Date - From"}
                      value={serviceDateFrom}
                      maxDate={serviceDateTo ?? null}
                      onChange={(newValue) => setServiceDateFrom(newValue)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <DateFilter
                      label={"Service Date - To"}
                      value={serviceDateTo}
                      minDate={serviceDateFrom ?? null}
                      onChange={(newValue) => setServiceDateTo(newValue)}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Autocomplete
                      id={"status-filter"}
                      options={statusList}
                      getOptionLabel={(status) => `${status.description}`}
                      value={statusFilter}
                      onChange={(e, newValue) => setStatusFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Status"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => openMobileFilters()}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <BackIcon />
                    ) : (
                      <FilterIcon className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={serviceJobList}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(MachineServiceJobList);
