import { Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { showSymbol } from "../../../helper/show-symbol";

export const serviceClaimMatrixTableColumnConfig = ({allowedViewDetail}, handleMatrixNameClick, handleEditBatch) => [
    {
        name: "Name",
        width: "150px",
        selector: row => row.name,
        cell: (row) => {
            if(allowedViewDetail){
                return (<Link to={'#'} onClick={(e) => handleMatrixNameClick(row._id)}>{row.name}</Link>)
            }else{
                return (<>{row.name}</>)
            }
            
        },
        sortable: true,
        sortField: 'name'
    },
    {
        name: "Description",
        width: "250px",
        selector: row => row.description,
        sortable: true,
        wrap: true,
        sortField: 'description'
    },
    {
        name: "Sub-Con",
        minWidth: "300px",
        selector: row => row.subCons?.map(s => s.username).toString(),
        sortable: true,
        wrap: true,
        sortField: 'subCons'
    },
    // {
    //     name: "Resource",
    //     width: "400px",
    //     selector: row => row.rows?.map(s =>
    //         `${s.resourceCode}:${showSymbol(s.claimValue, s.isPercentage)}`).toString(),
    //     sortable: true,
    //     sortField: 'resources'
    // },
    // {
    //     name: "Enable",
    //     width: '80px',
    //     center: true,
    //     selector: row => row.enable,
    //     cell: (row) => {
    //         return (<Switch
    //             checked={row.enable}
    //             onChange={(e, value) => handleEditBatch(row, value)}
    //             inputProps={{ 'aria-label': 'controlled' }}
    //         />)
    //     },
    //     sortable: true,
    //     sortField: 'enable'
    // }
];
