
import { Link } from "react-router-dom";
import moment from "moment";
import { IconButton } from "@mui/material";
import TaskIcon from '@mui/icons-material/ModeCommentOutlined';
import { TIME_SLOT_STATUS } from "../../../data/constants";
import { currentSlotInfo } from "../helper/task-timeline";

export const taskTrackingTableColumnConfig = (permission, timelineColumns, onClickStaff, onClickTimeSlot) => {
    const columns = [
        {
            name: "Employee",
            width: "250px",
            selector: row => row.staffUser?.username,
            cell: (row) => {
                if (permission.allowedViewDetail) {
                    return (<Link to={'#'} onClick={(e) => onClickStaff(row)}>
                        {`${row.staffUser?.username}-${row.staffUser?.name}`}</Link>)
                } else {
                    return (<>{`${row.staffUser?.username}-${row.staffUser?.name}`}</>);
                }
            },
            sortable: true,
            sortField: 'staff'
        },
        {
            name: "Date",
            width: "120px",
            selector: row => row.date ? moment(row.date).format('DD/MM/YYYY') : '',
            sortField: 'date'
        }
    ];
    return [...columns, ...getTimeSlots(timelineColumns, onClickTimeSlot)];
};

const getTimeSlots = (slots, onClickTimeSlot) => {
    return slots.map(ts => ({
        name: ts.label,
        width: "60px",
        cell: (row) => {
            const info = currentSlotInfo(ts, row?.date, row?.cutoffDate, row?.timeline || []);
            return (
                <IconButton
                    disabled={info?.status === TIME_SLOT_STATUS.Closed}
                    onClick={(e) => onClickTimeSlot(row, { ...info, ...{ slot: ts.value } })}  >
                    <TaskIcon sx={{ fill: info?.fill }} />
                </IconButton>
            )
        }
    }));
};



