import dataURItoBlob from "./convert-data-url-to-file";
import splitTest from "./get-filename-from-path";
import toDataURL from "./toDataURL";
import { convertThisToCurrency } from "./convert-this-to-currency";
import FormatDate from "./formatDate";
export {
  dataURItoBlob, splitTest, toDataURL, convertThisToCurrency, FormatDate
}


export function compareDates(date1, date2) {
  const formattedDate1 = new Date(date1).toISOString();
  const formattedDate2 = new Date(date2).toISOString();
  return formattedDate1.localeCompare(formattedDate2);
}