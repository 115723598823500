import * as moment from "moment";

export const stringToBoolean = (stringValue) => {
  switch (stringValue) {
    case "true":
    case "yes":
    case "1":
    case true:
      return true;

    case "false":
    case "no":
    case "0":
    case false:
    case null:
    case undefined:
      return false;

    default:
      return false;
  }
};

export const convertSlotId = (data) => {
  return (
    moment(data.appointmentDate).format("YYYYMMDD") +
    moment(data.appointmentTime.startTime, "HH:mm").format("HHmm") +
    moment(data.appointmentTime.endTime, "HH:mm").format("HHmm")
  );
};
