import React from "react";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { KeyboardArrowDown, PlaylistPlay, ArrowBack } from "@mui/icons-material";

export default function MobileFnbFoodFilters({
    fnbStores, storeFilter, onChangeStoreFilter, fnbCategories, categoryFilter, setCategoryFilter, noStockFilter, setNoStockFilter, yesNoList, promoteFilter, setPromoteFilter, chefRecommendFilter, setChefRecommendFilter, vegetarianFilter, setVegetarianFilter, healthierChoiceFilter, setHealthierChoiceFilter, enableFilter, setEnableFilter, popularFilter, setPopularFilter
}) {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"store-filter"}
                    options={fnbStores}
                    getOptionLabel={(store) => `${store.name.toUpperCase()}`}
                    value={storeFilter}
                    onChange={(e, newValue) => onChangeStoreFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Outlet"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"category-filter"}
                    options={fnbCategories}
                    getOptionLabel={(category) => !storeFilter ? `${category.store.storeCode} - ${category.name.toUpperCase()}`:`${category.name.toUpperCase()}`}
                    value={categoryFilter}
                    onChange={(e, newValue) => setCategoryFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Category"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"promote-filter"}
                    options={yesNoList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={promoteFilter}
                    onChange={(e, newValue) => setPromoteFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Promote"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"popular-filter"}
                    options={yesNoList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={popularFilter}
                    onChange={(e, newValue) => setPopularFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Popular"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"chefRecommend-filter"}
                    options={yesNoList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={chefRecommendFilter}
                    onChange={(e, newValue) => setChefRecommendFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Chef Recommend"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"vegetarian-filter"}
                    options={yesNoList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={vegetarianFilter}
                    onChange={(e, newValue) => setVegetarianFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Vegetarian"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"healthierChoice-filter"}
                    options={yesNoList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={healthierChoiceFilter}
                    onChange={(e, newValue) => setHealthierChoiceFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Healthier Choice"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"noStock-filter"}
                    options={yesNoList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={noStockFilter}
                    onChange={(e, newValue) => setNoStockFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Sold Out"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>            
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"enable-filter"}
                    options={yesNoList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={enableFilter}
                    onChange={(e, newValue) => setEnableFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Enable"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
        </Grid>
    </>);
};
