import { Add } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { PrivilegeActions } from "../../../../data/privileges.enum";
import { costMarginATableConfig } from "../../data-table/cost-margin-a-table-config";
import * as _ from 'lodash';
import { getOutstandingBalance, getProfit, getProfitMargin } from "../../helper/calculations";
import { convertThisToCurrency } from "../../../../helper/convert-this-to-currency";
import { PR_PANELS } from "../../helper/panels";
import { isAwarded, PR_COST_MARGIN_ACTUAL_TYPES } from "../../helper/pr-config";
import CostMarginActualDocumentsDrawer from "../Drawer/CostMarginActualDocumentsDrawer";
import { setRighbarContent, toggleRightbar, useLayoutDispatch, useLayoutState } from "../../../../context/LayoutContext";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { SMALL_DEVICE_WIDTH } from "../../../../data/constants";

const WorkflowCostMarginActual = (props) => {
    const [lastRowValid, setLastRowValid] = useState(false);
    const [costMarginRecord, setCostMarginRecord] = useState({ record: null, index: -1 });
    const [documents, setDocuments] = useState([]);
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    useEffect(() => {
        const totalCostA = _.sum(props.costMarginAList.map(cm => cm.cost));
        const contractValueA = _.sum(props.costMarginAList.map(cm => cm.sellingPrice));
        const profitA = getProfit(contractValueA, totalCostA);
        const profitMarginA = getProfitMargin(profitA, contractValueA);
        props.setTotalCostA(totalCostA);
        props.setContractValueA(contractValueA);
        props.setProfitA(profitA);
        props.setProfitMarginA((isNaN(profitMarginA) || profitMarginA === -Infinity) ? 0 : profitMarginA);

        props.setOutstandingBalance(getOutstandingBalance(contractValueA, props.totalPayment));

        const lastRow = props.costMarginAList?.at(props.costMarginAList?.length - 1);
        setLastRowValid(props.costMarginAList?.length === 0 ||
            (!!lastRow?.description && !!lastRow?.cost));
    }, [props.costMarginAList]);


    useEffect(() => {
        if (costMarginRecord?.record && costMarginRecord?.index > -1) {
            loadDocumentDrawer();
            onChangeRowValues(costMarginRecord?.index, documents, 'documents');
        }
    }, [documents]);

    useEffect(() => {
        if (costMarginRecord?.record && costMarginRecord?.index > -1) {
            loadDocumentDrawer();
        }
    }, [props?.removedDocuments]);


    const hasPermissionToEdit = () =>
        props.hasPrivilege(PrivilegeActions.edit_cost_margin_actual);

    const isDisabled = () => !isAwarded(props.projectRequest?.status);

    const drawerProps = {
        step: props?.step, status: props?.projectRequest?.status,
        permissionToEdit: hasPermissionToEdit(),
        isDisabled: isDisabled(),
        documents, setDocuments,
        removedDocuments: props?.removedDocuments,
        setRemovedDocuments: props?.setRemovedDocuments,
        allowedFileTypes: props?.allowedFileTypes
    };

    const columns = costMarginATableConfig(
        (getUniqueMatrixList(props?.itemMatrix) || []),
        hasPermissionToEdit(), isDisabled(),
        onRemoveRow, onChangeRowValues, openDocumentDrawer);

    function getUniqueMatrixList(list) {
        return list.map(x => x.item).filter((x, i, a) => a.indexOf(x) === i);
    }

    function createNewRow() {
        props.setCostMarginAList([...props.costMarginAList, {
            description: '',
            cost: 0,
            sellingPrice: 0,
            vendor: '',
            remarks: '',
            type: PR_COST_MARGIN_ACTUAL_TYPES.V,
            paidAmount: 0,
            outstanding: 0,
            paymentRemark: '',
            documents: []
        }]);
    }

    function onRemoveRow(i) {
        const rows = [...props.costMarginAList];
        rows.splice(i, 1);
        props.setCostMarginAList([...rows]);
    }

    function onChangeRowValues(i, value, attribute) {
        const cmList = [...props.costMarginAList];
        if (attribute === 'vendor' || attribute === 'remarks'
            || attribute === 'paymentRemark') {
            value = value.toUpperCase();
        } else if (attribute === 'sellingPrice'
            || attribute === 'cost' || attribute === 'paidAmount'
            || attribute === 'outstanding') {
            value = +value;
        }
        cmList.at(i)[attribute] = value;
        props.setCostMarginAList(cmList);
    }

    async function openDocumentDrawer(costMarginRecord, index) {
        if (costMarginRecord) {
            setCostMarginRecord({ record: costMarginRecord, index });
            setDocuments(costMarginRecord.documents ?? []);
            loadDocumentDrawer();
        }
    }

    function loadDocumentDrawer(backButton = true, showBackDropInLargeScreen = false) {
        const size = width <= SMALL_DEVICE_WIDTH ? 'xs' : 'lg';
        setRighbarContent(layoutDispatch,
            [<CostMarginActualDocumentsDrawer key={'rb-body'}
                {...drawerProps} />],
            { size, backButton, showBackDropInLargeScreen });
        if (!layoutState.isRightbarOpened) { toggleRightbar(layoutDispatch); }
    }

    return (<>
        <Grid container className={"form"} id={PR_PANELS.COST_MARGIN_ACTUAL}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Typography variant={"h2"}>Cost & Margin (Actual)</Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={"summary"}>
                                <Typography variant={"h3"}>
                                    Total Cost: {<span>{convertThisToCurrency(props.totalCostA?.toFixed(2))}</span>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={"summary"}>
                                <Typography variant={"h3"}>
                                    Contract Value: {<span>{convertThisToCurrency(props.contractValueA?.toFixed(2))}</span>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={"summary"}>
                                <Typography variant={"h3"}>
                                    Profit Margin: {<span>{convertThisToCurrency(props.profitA?.toFixed(2))} ({props.profitMarginA?.toFixed(2)}%)</span>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={"table"}>
                        <DataTable
                            fixedHeader={true}
                            persistTableHead={true}
                            columns={columns}
                            data={props.costMarginAList}
                            customStyles={{
                              rows: {
                                style: {
                                  alignItems: 'baseline'
                                }
                              }
                            }}
                        />
                    </Grid>
                    {(hasPermissionToEdit() && !isDisabled()) &&
                        <Grid item xs={12} className={"table-action"}>
                            <IconButton
                                onClick={createNewRow}
                                disabled={!lastRowValid}>
                                <Add />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default WorkflowCostMarginActual;   