import { useHttpRequest } from '../../../hooks';
import Swal from 'sweetalert2';

export function useVoucherSetupDelete() {
  const { isLoading, sendRequest } = useHttpRequest();

  const deleteVoucherRule = async (incrementId) => {
    try {
      await sendRequest(
        `/v1/fnb-voucher-rule/${incrementId}`,
        'DELETE',
      );
      return true;
    } catch (e) {
      Swal.fire(
        'Error while deleting voucher rule',
        e?.response?.data?.message || e.message,
        'error',
      );
      throw e;
    }
  };

  return { isLoading, deleteVoucherRule };
}