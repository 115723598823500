import moment from "moment";
import { Link } from "react-router-dom";
import { BRACKET_STATUS } from "../../../data/constants";

export const bracketsTableColumnConfig = ({ seeMore, allowedViewDetail }) => {
    let columns = [
        {
            name: "Serial No.",
            width: "100px",
            selector: row => row.serialNo,
            sortable: true,
            sortField: 'serialNo'
        },
        {
            name: "Bracket Type",
            width: "110px",
            selector: row => row.bracketType?.name,
            left: true,
            sortable: true,
            sortField: 'bracketType'
        },
        {
            name: "Welder",
            width: "80px",
            selector: row => row.welder?.username,
            sortable: true,
            sortField: 'welder'
        },
        {
            name: "Batch No.",
            width: "350px",
            wrap: true,
            sortable: true,
            selector: row => row.batchNos?.sort().toString(),
            sortField: '_batchNos'
        },
        {
            name: "Created Date",
            width: "150px",
            wrap: true,
            sortable: true,
            sortField: 'createdDate',
            selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss')
        },
        {
            name: "Quality Check",
            selector: row => row.status,
            sortable: true,
            width: "120px",
            sortField: 'status'
        },
        {
            name: "Document No.",
            width: "120px",
            selector: row => row.pkrNo,
            sortable: true,
            cell: (row) => {
                if(allowedViewDetail){
                    return (<Link to={`/app/bracket-request/card/${row.pkrNo}`}>{row.pkrNo}</Link>)
                }else{
                    return (<>{row.pkrNo}</>);
                }   
            },
            sortField: 'pkrNo'
        },
    ];

    // only admin and supervisor can see below columns
    if (seeMore) {
        columns = [...columns,
        {
            name: "Team",
            width: "80px",
            selector: row => row.teamNo,
            sortable: true,
            sortField: 'teamNo'
        },
        {
            name: "Ship-to Customer Name",
            width: "250px",
            wrap: true,
            selector: row => row.shipToCustomerName,
            sortable: true,
            sortField: 'shipToCustomerName'
        },
        {
            name: "Ship-to Contact",
            width: "200px",
            wrap: true,
            selector: row => row.shipToContact?.toUpperCase(),
            sortable: true,
            sortField: 'shipToContact'
        },
        {
            name: "Ship-to Address",
            width: "250px",
            wrap: true,
            selector: row => row.address && row.shipToPostCode
                ? `${row.address} ${row.shipToPostCode}` : '',
            sortable: true,
            sortField: 'shipToAddress'
        },
        {
            name: "Customer No.",
            width: "120px",
            selector: row => row.customerNo,
            sortable: true,
            sortField: 'customerNo'
        },
        {
            name: "Bill-to Customer Name",
            width: "250px",
            wrap: true,
            selector: row => row.billToCustomerName,
            sortable: true,
            sortField: 'billToCustomerName'
        },
        {
            name: "Bill-to Contact",
            width: "120px",
            selector: row => row.billToContact ? row.billToContact : row.billToContact2,
            sortable: true,
            sortField: 'billToContact'
        },
        {
            name: "Model No.",
            width: "500px",
            wrap: true,
            selector: row => row.modelNos,
            sortable: true,
            sortField: 'modelNos'
        }
    ]   ;
    }
    return columns;
};
