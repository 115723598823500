import { useParams, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
  Modal,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { validateText } from "../../helper/validate-textfield";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import Swal from "sweetalert2";
import ProjectRequestRejectModal from "./components/Modals/ProjectRequestRejectModal";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import * as moment from "moment";
import ProjectRequestDeclineModal from "./components/Modals/ProjectRequestDeclineModal";
import ProjectRequestReviseModal from "./components/Modals/ProjectRequestReviseModal";
import {
  claimDepositPanel,
  costMarginActualPanel,
  costMarginProjectedPanel,
  documentsPanel,
  feedbackPanel,
  generalPanel,
  historyPanel,
  paymentPanel,
  progressPanel,
  claimDepositProjectedPanel,
} from "./helper/panels";
import {
  approveButton,
  awardButton,
  cancelButton,
  declineButton,
  rejectButton,
  reviseButton,
  needRevisionButton,
  saveButton,
  submitButton,
  printButton,
  notApplicableButton,
  downloadDocument,
} from "./helper/action-buttons";
import {
  isApproved,
  isStep_3_1_5,
  isStep_5_5,
  PR_ACTIONS,
  PR_COST_MARGIN_ACTUAL_TYPES,
  PR_DOCUMENT_TYPES,
  PR_STEPS,
  PR_STEP_RESULTS,
  percentageSteps,
  getPercentages,
  isStep_1_5,
  isStep_2_5,
  isStep_3_2_5,
} from "./helper/pr-config";
import { createPayload } from "./helper/payload-handler";
import { useUserState } from "../../context/UserContext";
import { getOutstandingBalance } from "./helper/calculations";
import _ from "lodash";
import useDownload from "../../hooks/useDownload";
import { PR_OTH_DOC_TYPE } from "./helper/pr-config";
import ProjectRequestApproveModal from "./components/Modals/ProjectRequestApproveModal";
import ProjectRequestSignaturePadModal from "./components/Modals/ProjectRequestSignaturePadModal";

const ProjectRequestCard = (props) => {
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
    statusCode,
  } = useHttpRequest();
  const { auth } = useRoleAuthorization();
  const { userInfo } = useUserState();
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    setValue,
    setError,
  } = useForm({ mode: "all" });
  const { requestId } = useParams();
  const { isDownloading, handleDownload } = useDownload();

  const [projectRequest, setProjectRequest] = useState(null);
  const [step, setStep] = useState(null);
  const [estimatedContractStart, setEstimatedContractStart] = useState(null);
  const [estimatedContractCompletion, setEstimatedContractCompletion] =
    useState(null);
  const [contractStart, setContractStart] = useState(null);
  const [contractCompletion, setContractCompletion] = useState(null);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [reviseModalOpen, setReviseModalOpen] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [reviseMessage, setReviseMessage] = useState("");
  const [declineReason, setDeclineReason] = useState("");

  const [totalCost, setTotalCost] = useState(0);
  const [contractValue, setContractValue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [profitMargin, setProfitMargin] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [outstandingBalance, setOutstandingBalance] = useState(0);
  const [totalBillAmount, setTotalBillAmount] = useState(0);

  const [removedCMDocuments, setCMRemovedDocuments] = useState([]);
  const [removedCMActualDocuments, setCMActualRemovedDocuments] = useState([]);

  const [progress, setProgress] = useState(null);
  const [progressList, setProgressList] = useState([]);
  const [action, setAction] = useState(null);

  const [forceShowBtns, setForceShowBtns] = useState([]);
  const [costMarginList, setCostMarginList] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [removedOtherDocuments, setRemovedOtherDocuments] = useState([]);
  const [message, setMessage] = useState("");

  const [paymentList, setPaymentList] = useState([]);
  const [removedPaymentDocuments, setRemovedPaymentDocuments] = useState([]);

  const [feedbackDocuments, setFeedbackDocuments] = useState([]);
  const [removedFeedbackDocuments, setRemovedFeedbackDocuments] = useState([]);
  // cost & margin actual
  const [costMarginAList, setCostMarginAList] = useState([]);
  const [totalCostA, setTotalCostA] = useState(0);
  const [contractValueA, setContractValueA] = useState(0);
  const [profitA, setProfitA] = useState(0);
  const [profitMarginA, setProfitMarginA] = useState(0);
  const [itemMatrix, setItemMatrix] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [tendencyDueDate, setTendencyDueDate] = useState(null);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [approveMessage, setApproveMessage] = useState("");
  const [allowedFileTypes, setAllowedFileTypes] = useState(null);
  const [prVersion, setPrVersion] = useState(2);

  const [hasRemainingCMApprovalsForUser, setHasRemainingCMApprovalsForUser] =
    useState(false);

  const hasPrivilege = (privilege) =>
    auth.checkModulePrivilege(PrivilegeModules.project_request, privilege);

  const panelProps = { register, formErrors, projectRequest, step };
  const [openSignaturePad, setOpenSignaturePad] = useState(false);

  const actionBtnProps = {
    ...props,
    step,
    hasPrivilege,
    onSubmit,
    handleSubmit,
    onNeedRevision,
    onReject,
    onDecline,
    projectRequest,
    onDownloadDocument,
    onSubmitApprove,
  };

  const rejectModalProps = {
    isLoading,
    open: rejectModalOpen,
    setOpen: setRejectModalOpen,
    rejectReason,
    setRejectReason,
    onSubmitReject,
  };

  const reviseModalProps = {
    open: reviseModalOpen,
    setOpen: setReviseModalOpen,
    reviseMessage,
    setReviseMessage,
    onSubmitRevise,
  };

  const declineModalProps = {
    open: declineModalOpen,
    setOpen: setDeclineModalOpen,
    declineReason,
    setDeclineReason,
    onSubmitDecline,
  };

  const signaturePadModalProps = {
    openSignaturePad,
    setOpenSignaturePad,
    onSubmit,
    proceedApprovalWithRemark,
    handleSubmit,
    step,
  };

  const printBtnProps = { ...props, step, hasPrivilege, onPrint };

  const validateForApproveSignature = () => {
    return (
      (isStep_1_5(step) && hasPrivilege("esign_step_1")) ||
      (isStep_2_5(step) && hasPrivilege("esign_step_2")) ||
      (isStep_3_1_5(step) && hasPrivilege("esign_step_3.1")) ||
      (isStep_3_2_5(step) && hasPrivilege("esign_step_3.2"))
    );
  };

  const approveModalProps = {
    open: approveModalOpen,
    setOpen: setApproveModalOpen,
    approveMessage,
    setApproveMessage,
    onSubmit,
    handleSubmit,
    setOpenSignaturePad,
    validateForApproveSignature,
  };

  //#region useEffects

  useEffect(() => {
    document.title = "Project Request Card";
  }, []);

  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: onCloseAfterSuccess,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
        willClose: () => {
          if (statusCode === 403) goToList();
        },
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (requestId) {
      getProjectRequestData(requestId).then(async (response) => {
        const request = response?.data?.request;

        document.title = `${request.referenceNo} - Project Request Card`;
        setProjectRequest(request);
        setStep(request.step);

        loadGeneralPanelData(request);
        loadCostMarginPanelData(request);
        loadDocumentPanelData(request);

        loadClaimsDepositsPanelData(request);
        loadClaimsDepositsProjectedPanelData(request);
        loadProgressPanelData(request);

        if (isStep_5_5(request.step)) {
          loadCostMarginAPanelData(request);
          loadFeedbackPanelData(request);
          loadPaymentPanelData(request);
        }
      });
    } else {
      document.title = `New - Project Request Card`;
      setStep(PR_STEPS.STEP_0_5);
    }

    loadPrerequisites();
  }, [requestId]);

  useEffect(() => {
    if (projectRequest && companyList) {
      setSelectedCompany(projectRequest.company);
    }
  }, [projectRequest, companyList]);

  useEffect(() => {
    if (!selectedCompany) {
      return;
    }

    getProjectRequestItemMatrixData(selectedCompany.code).then((response) => {
      const orderedList = response.data?.matrix.sort((a, b) =>
        a.item.localeCompare(b.item),
      );

      if (projectRequest) {
        setItemMatrix(orderedList);
        loadRemainingCMApprovalsStatus(response.data?.matrix, projectRequest);
      } else {
        const notHiddenList = orderedList.filter((val) => val.hidden !== true);
        setItemMatrix(notHiddenList);
      }
    });
  }, [selectedCompany]);

  const loadPrerequisites = async () => {
    const prerequisites = await getPrerequisites();
    setAllowedFileTypes(prerequisites.data.allowedFileTypes);
    setCompanyList(prerequisites.data.companies);
  };

  function getPrerequisites() {
    return sendRequest(`/v1/project-request/get/prerequisites`, "GET");
  }

  const getDocTypeList = () => {
    return Object.keys(PR_OTH_DOC_TYPE).map((type_code) => ({
      type_code,
      type_desc: PR_OTH_DOC_TYPE[type_code],
    }));
  };

  //#endregion

  //#region Panels

  const generalPanelProps = {
    ...panelProps,
    setError,
    contractStart,
    setContractStart,
    contractCompletion,
    setContractCompletion,
    setValue,
    estimatedContractStart,
    setEstimatedContractStart,
    estimatedContractCompletion,
    setEstimatedContractCompletion,
    hasEditPrivilege: hasPrivilege(PrivilegeActions.edit),
    tendencyDueDate,
    setTendencyDueDate,
    companyList,
    selectedCompany,
    setSelectedCompany,
    version: prVersion,
  };

  const costAndMarginPanelProps = {
    projectRequest,
    hasPrivilege,
    costMarginList,
    setCostMarginList,
    totalCost,
    setTotalCost,
    contractValue,
    setContractValue,
    profit,
    setProfit,
    profitMargin,
    setProfitMargin,
    itemMatrix,
    allowedFileTypes: allowedFileTypes,
    step,
    removedDocuments: removedCMDocuments,
    setRemovedDocuments: setCMRemovedDocuments,
    version: prVersion,
  };

  const costAndMarginAPanelProps = {
    projectRequest,
    hasPrivilege,
    costMarginAList,
    setCostMarginAList,
    itemMatrix,
    totalCostA,
    setTotalCostA,
    contractValueA,
    setContractValueA,
    totalPayment,
    profitA,
    setProfitA,
    profitMarginA,
    setProfitMarginA,
    setOutstandingBalance,
    step,
    removedDocuments: removedCMActualDocuments,
    setRemovedDocuments: setCMActualRemovedDocuments,
  };

  const historyPanelProps = { historyRecords: projectRequest?.history || [] };

  const documentsPanelProps = {
    projectRequest: projectRequest,
    docTypeList: getDocTypeList(),
    projectRequest,
    allowedFileTypes: allowedFileTypes, // projectRequest?.allowedFileTypes,
    otherDocuments,
    setOtherDocuments,
    removedDocuments: removedOtherDocuments,
    setRemovedDocuments: setRemovedOtherDocuments,
    hasEditPrivilege: hasPrivilege(PrivilegeActions.edit_other_documents),
    version: prVersion,
    currentUserName: userInfo.username,
    step,
    hasRemainingCMApprovalsForUser,
  };

  const feedbackPanelProps = {
    projectRequest: projectRequest,
    allowedFileTypes: allowedFileTypes, // projectRequest?.allowedFileTypes,
    feedbackDocuments,
    setFeedbackDocuments,
    removedDocuments: removedFeedbackDocuments,
    setRemovedDocuments: setRemovedFeedbackDocuments,
    hasEditPrivilege: hasPrivilege(PrivilegeActions.edit_feedback),
  };

  const claimsDepositsPanelProps = {
    ...panelProps,
    hasEditPrivilege: hasPrivilege(PrivilegeActions.edit_payment_schedule),
  };

  const claimsDepositsProjectedPanelProps = {
    ...panelProps,
    hasEditPrivilege: hasPrivilege(PrivilegeActions.edit),
  };

  const paymentsPanelProps = {
    projectRequest,
    hasPrivilege,
    paymentList,
    setPaymentList,
    contractValueA,
    totalPayment,
    setTotalPayment,
    outstandingBalance,
    setOutstandingBalance,
    removedPaymentDocuments: removedPaymentDocuments,
    setRemovedPaymentDocuments: setRemovedPaymentDocuments,
    hasEditPrivilege: hasPrivilege(PrivilegeActions.edit_payment_received),
    totalBillAmount,
    setTotalBillAmount,
  };

  const progressPanelProps = {
    hasPrivilege,
    progress,
    setProgress,
    progressList,
    setProgressList,
  };

  function loadRemainingCMApprovalsStatus(itemMatrix, pr) {
    if (
      itemMatrix?.length > 0 &&
      pr &&
      isStep_3_1_5(pr?.step) &&
      pr?.costMarginList?.length > 0
    ) {
      const realtedItems = itemMatrix.filter(
        (mi) => mi.username === userInfo.username,
      );
      if (realtedItems.length > 0) {
        const notApprovedList = pr.costMarginList.filter(
          (cm) =>
            realtedItems.findIndex(
              (ri) =>
                ri.item.toUpperCase() === cm.description &&
                !cm.revisionRequested &&
                !cm.revisioner &&
                !isApproved(cm.status),
            ) > -1,
        );
        const hasRemaining = notApprovedList.length > 0;
        if (hasRemaining) {
          setForceShowBtns(["needRevision", "approve"]);
        }
        setHasRemainingCMApprovalsForUser(hasRemaining);
      }
    }
  }

  function loadGeneralPanelData(request) {
    setValue("referenceNo", request.referenceNo);
    setValue("customer", request.customer);
    setValue("uen", request.uen);
    setValue("location", request.location);
    setValue("validity", request.validity);
    setValue("creditLimit", request.creditLimit);
    setValue("deposit", request.deposit);
    setValue("creditTerm", request.creditTerm);
    setValue("contractStart", request.contractStart);
    setValue("contractCompletion", request.contractCompletion);
    setValue("estimatedContractStart", request.estimatedContractStart);
    setValue("tendencyDueDate", request.tendencyDueDate);
    setValue(
      "estimatedContractCompletion",
      request.estimatedContractCompletion,
    );
    request?.projectManager &&
      setValue(
        "projectManager",
        `${request.projectManager?.name}-${request.projectManager?.username}`,
      );
    setContractStart(request.contractStart);
    setContractCompletion(request.contractCompletion);
    setEstimatedContractStart(request.estimatedContractStart);
    setEstimatedContractCompletion(request.estimatedContractCompletion);
    setValue("projectTitle", request?.projectTitle?.toUpperCase());
    setValue("internalReferenceNo", request?.internalReferenceNo);
    setValue("approveComment_1_5", request.approveComment_1_5);
    setTendencyDueDate(request.tendencyDueDate);
    setPrVersion(request.version);
  }

  function loadCostMarginPanelData(request) {
    setTotalCost(request?.totalCost || 0);
    setContractValue(request?.sellingPrice || 0);
    setProfit(request?.profit || 0);
    setProfitMargin(request?.profitMargin || 0);
    setCostMarginList(request?.costMarginList || []);
  }

  function loadCostMarginAPanelData(request) {
    setTotalCostA(request?.totalCostA || 0);
    setContractValueA(request?.contractValueA || 0);
    setProfitA(request?.profitA || 0);
    setProfitMarginA(request?.profitMarginA || 0);
    setCostMarginAList(request?.costMarginAList || []);
  }

  function loadDocumentPanelData(request) {
    let otherDocs =
      request?.documents.sort(
        (a, b) => new Date(a.uploadedDate) - new Date(b.uploadedDate),
      ) || [];

    otherDocs.forEach((d) => {
      if (
        request.version == 1 ||
        (request.version > 1 &&
          d.type != "PNL" &&
          d.uploadedBy == userInfo.username)
      ) {
        d.allowedDelete = true;
      } else {
        d.allowedDelete = false;
      }
    });

    if (request.version == 1) {
      setOtherDocuments(otherDocs);
    } else {
      setOtherDocuments([
        ...otherDocs.filter((d) => {
          return d.type == "PNL";
        }),
        ...otherDocs.filter((d) => {
          return d.type != "PNL";
        }),
      ]);
    }
  }

  function loadFeedbackPanelData(request) {
    setFeedbackDocuments(request?.feedbackDocuments || []);
  }

  function loadClaimsDepositsPanelData(request) {
    if (request.claimsDeposits) {
      setValue("firstClaim", request.claimsDeposits.firstClaim);
      setValue("secondClaim", request.claimsDeposits.secondClaim);
      setValue("thirdClaim", request.claimsDeposits.thirdClaim);
      setValue("forthClaim", request.claimsDeposits.forthClaim);
      setValue("fifthClaim", request.claimsDeposits.fifthClaim);
      setValue("sixthClaim", request.claimsDeposits.sixthClaim);
      setValue("seventhClaim", request.claimsDeposits.seventhClaim);
      setValue("eighthClaim", request.claimsDeposits.eighthClaim);
      setValue("finalClaim", request.claimsDeposits.finalClaim);
      setValue("retention", request.claimsDeposits.retention);
    }
  }

  function loadClaimsDepositsProjectedPanelData(request) {
    if (request.claimsDepositsProjected) {
      setValue("firstClaimP", request.claimsDepositsProjected.firstClaim);
      setValue("secondClaimP", request.claimsDepositsProjected.secondClaim);
      setValue("thirdClaimP", request.claimsDepositsProjected.thirdClaim);
      setValue("forthClaimP", request.claimsDepositsProjected.forthClaim);
      setValue("fifthClaimP", request.claimsDepositsProjected.fifthClaim);
      setValue("sixthClaimP", request.claimsDepositsProjected.sixthClaim);
      setValue("seventhClaimP", request.claimsDepositsProjected.seventhClaim);
      setValue("eighthClaimP", request.claimsDepositsProjected.eighthClaim);
      setValue("finalClaimP", request.claimsDepositsProjected.finalClaim);
      setValue("retentionP", request.claimsDepositsProjected.retention);
    }
  }

  function loadPaymentPanelData(request) {
    if (request.payments) {
      setPaymentList(request?.payments || []);
      setTotalPayment(request?.totalPayment || 0);
      setOutstandingBalance(request?.outstandingBalance || 0);
      setTotalBillAmount(request?.totalBillAmount || 0);
    }
  }

  function loadProgressPanelData(request) {
    const progressValue = request?.progress || 0;
    const list = request?.progressList || [];
    setProgress(progressValue);
    for (const progress of list) {
      const step = progress.percentage / percentageSteps;
      progress.percentageList = getPercentages(step - 1);
    }
    setProgressList(list);
  }

  //#endregion

  function onCloseAfterSuccess() {
    if (action != PR_ACTIONS.SAVE) goToList();
  }

  function goToList() {
    window.location.href = "#/app/project-request/list";
  }

  function getProjectRequestData(id) {
    return sendRequest(`/v1/project-request/${id}`, "GET");
  }

  function getProjectRequestItemMatrixData(companyCode) {
    return sendRequest(
      `/v1/project-request-item-matrix/get-item-matrix-list/${companyCode}`,
      "GET",
    );
  }

  function onReject(e) {
    e.preventDefault();
    setRejectReason("");
    setRejectModalOpen(true);
  }

  function onNeedRevision(e) {
    e.preventDefault();

    if (!validationOnSubmit()) {
      return;
    }

    setReviseMessage("");
    setReviseModalOpen(true);
  }

  function onDecline(e) {
    e.preventDefault();
    setDeclineReason("");
    setDeclineModalOpen(true);
  }

  async function onDownloadDocument(e) {
    e.preventDefault();
    const data = [];
    // Feedback document
    data.push({
      path: "Feedback",
      documents: feedbackDocuments,
    });

    // Other documents
    let plDocResponse = await sendRequest(
      `/v1/project-request/generate-report-costing-summary/${requestId}`,
      "GET",
    );
    let index = otherDocuments.findIndex((i) => i.type == "PNL");
    if (index > -1) {
      otherDocuments[index].url = plDocResponse?.data?.url;
    }

    data.push({
      path: "Other Documents",
      documents: otherDocuments,
    });

    // Payment document
    let paymentListDocument = [];
    paymentList.forEach((elm) => {
      paymentListDocument.push(...elm.documents);
    });

    data.push({
      path: "Payment Received",
      documents: paymentListDocument,
    });

    // Cost Margin Actual Document
    let costMarginAListDocument = [];
    costMarginAList.forEach((elm) => {
      costMarginAListDocument.push(...elm.documents);
    });
    data.push({
      path: "Cost Margin (Actual)",
      documents: costMarginAListDocument,
    });

    // Cost Margin List Document
    let costMarginListDocument = [];
    costMarginList.forEach((elm) => {
      costMarginListDocument.push(...elm.documents);
    });

    data.push({
      path: "Cost Margin (Projected)",
      documents: costMarginListDocument,
    });

    const zipName = projectRequest.referenceNo.replaceAll("/", "-");
    // Handle download
    await handleDownload(zipName, data, {
      noDocumentMsg: `No documents found in project request ${projectRequest.referenceNo}`,
      errMsg: "Something error",
    });
  }

  function mapCostMarginProjectedList(list, existingPR = false) {
    return list.map((cm) => ({
      ...cm,
      _id: cm._id,
      description: cm.description,
      cost: +cm.cost,
      sellingPrice: +cm.sellingPrice,
      vendor: cm.vendor,
      remarks: cm.remarks,
      status: cm.status,
      approvedBy: cm.approvedBy,
      approvedDate: cm.approvedDate,
      documents: existingPR
        ? cm.documents.map((d) => ({
            fileName: d.fileName,
            description: d.description,
            url: d.url,
            uploadedDate: d.uploadedDate,
            new: d.new,
          }))
        : [],
    }));
  }

  function mapCostMarginActualList(list) {
    return list.map((cm) => ({
      _id: cm._id,
      description: cm.description,
      cost: +cm.cost,
      sellingPrice: +cm.sellingPrice,
      vendor: cm.vendor,
      remarks: cm.remarks,
      type: cm.type,
      paidAmount: +cm.paidAmount,
      outstanding: +cm.outstanding,
      paymentRemark: cm.paymentRemark,
      documents: cm.documents.map((d) => ({
        fileName: d.fileName,
        description: d.description,
        url: d.url,
        uploadedDate: d.uploadedDate,
        new: d.new,
      })),
    }));
  }

  function mapPaymentList(list) {
    return list.map((p) => ({
      _id: p._id,
      paymentAmount: p.paymentAmount,
      billAmount: p.billAmount,
      remarks: p.remarks,
      createdAt: p.createdAt,
      updatedAt: p.updatedAt,
      documents: p.documents.map((d) => ({
        fileName: d.fileName,
        url: d.url,
        description: d.description,
        uploadedDate: d.uploadedDate,
        new: d.new,
      })),
    }));
  }

  function getNewCostMarginDocumentList(cmList) {
    const files = [];
    for (let cmIndex = 0; cmIndex < cmList.length; cmIndex++) {
      const record = cmList[cmIndex];
      if (record.documents) {
        const list = record.documents
          .filter((d) => d.new)
          .map((d) => ({
            cmIndex,
            new: d.new,
            fileName: d.fileName,
            description: d.description,
            file: d.file,
          }));
        files.push(list);
      } else {
        record.documents = [];
      }
    }
    return files.flat(1);
  }

  function getNewCostMarginActualDocumentList(cmaList) {
    const files = [];
    for (let cmIndex = 0; cmIndex < cmaList.length; cmIndex++) {
      const record = cmaList[cmIndex];
      if (record.documents) {
        const list = record.documents
          .filter((d) => d.new)
          .map((d) => ({
            cmIndex,
            new: d.new,
            fileName: d.fileName,
            description: d.description,
            file: d.file,
          }));
        files.push(list);
      } else {
        record.documents = [];
      }
    }
    return files.flat(1);
  }

  function getNewPaymentDocumentList(paymentList) {
    const files = [];
    for (let pIndex = 0; pIndex < paymentList.length; pIndex++) {
      const record = paymentList[pIndex];
      if (record.documents) {
        const list = record.documents
          .filter((d) => d.new)
          .map((d) => ({
            pIndex,
            new: d.new,
            fileName: d.fileName,
            description: d.description,
            file: d.file,
          }));
        files.push(list);
      } else {
        record.documents = [];
      }
    }
    return files.flat(1);
  }
  const checkCostMarginValid = () => {
    const costMarginListFilter = costMarginList.filter(
      (val) => val.hidden !== true,
    );
    if (costMarginListFilter.length === 0) {
      return false;
    }
    // Filter if description is null
    const data = _.find(costMarginListFilter, (i) => {
      return (
        i.description === "" ||
        (prVersion > 1 && (!i.description2 || i.description2 === ""))
      );
    });
    return _.isUndefined(data);
  };

  const checkCostMarginAValid = () => {
    if (costMarginAList.length === 0) {
      return false;
    }
    // Filter if description is null
    const data = _.find(costMarginAList, (i) => {
      return i.description === "";
    });
    return _.isUndefined(data);
  };

  async function onSubmitApprove(data, e) {
    if (!validationOnSubmit()) {
      return;
    }

    if (isStep_1_5(step)) {
      proceedApprovalWithRemark(e);
    } else if (validateForApproveSignature()) {
      setOpenSignaturePad(true);
    } else {
      onSubmit(data, e, PR_ACTIONS.APPROVE);
    }
  }

  function proceedApprovalWithRemark(e) {
    e.preventDefault();
    setApproveMessage("");
    setApproveModalOpen(true);
  }

  function validationOnSubmit() {
    if (
      prVersion > 1 &&
      otherDocuments.findIndex((r) => r.type == "QUOTATION") == -1
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Missing quotation in other documents section.",
      });
      return false;
    }

    if (prVersion > 1 && otherDocuments.findIndex((r) => r.type == "") > -1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Type cannot be blank in other documents section.",
      });
      return false;
    }

    if (
      (action === PR_ACTIONS.SUBMIT ||
        action === PR_ACTIONS.SAVE ||
        action === PR_ACTIONS.REVISE) &&
      !checkCostMarginValid()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Cost & Margin (Projected) cannot be blank.",
      });
      return false;
    }

    return true;
  }

  async function onSubmit(data, e, action, signPic) {
    e.preventDefault();
    if (data) {
      setAction(action);

      if (!validationOnSubmit()) {
        return;
      }

      let awardedDate = undefined;
      let contractBalance = undefined;
      let otherDocumentsFinal = [];
      let feedbackDocumentsFinal = feedbackDocuments || [];
      let costMarginActualItems = costMarginAList || [];
      let paymentItems = paymentList || [];
      const existingPR = !!requestId && !!projectRequest?._id;

      // collect cost & margin projected data
      let costMarginProjectedItems =
        costMarginList.length > 0
          ? mapCostMarginProjectedList(costMarginList, existingPR)
          : [];

      if (existingPR) {
        // collect cost & margin actual data
        if (action === PR_ACTIONS.AWARD) {
          awardedDate = moment().toDate();
          // mirror projected to actual
          costMarginActualItems = mapCostMarginActualList([
            ...costMarginProjectedItems
              .filter((val) => val.hidden !== true)
              .map((cm) => ({
                ...cm,
                ...{
                  type: PR_COST_MARGIN_ACTUAL_TYPES.O,
                  paidAmount: 0,
                  outstanding: cm.cost, // as paid amount is 0
                  paymentRemark: "",
                  documents: [],
                },
              })),
          ]);
          setCostMarginAList(costMarginActualItems);
          setTotalCostA(totalCost);
          setContractValueA(contractValue);
          setProfitA(profit);
          setProfitMarginA(profitMargin);
          // contractValue is equals to contractValueA here
          contractBalance = getOutstandingBalance(contractValue, totalPayment);
          setOutstandingBalance(contractBalance);
        } else if (
          hasPrivilege(PrivilegeActions.edit_cost_margin_actual) &&
          costMarginAList.length > 0
        ) {
          if (checkCostMarginAValid()) {
            costMarginActualItems = mapCostMarginActualList(costMarginAList);
            setCostMarginAList(costMarginActualItems);
            setTotalCostA(totalCostA);
            setContractValueA(contractValueA);
            setProfitA(profitA);
            setProfitMarginA(profitMarginA);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Cost & Margin (Actual) cannot be blank.",
            });
            return;
          }
        }

        // prepare cost & margin documents
        costMarginProjectedItems = await prepareCostMarginDocumentsBeforeSave(
          projectRequest?.referenceNo,
          costMarginProjectedItems,
        );

        // prepare cost & margin actual documents
        costMarginActualItems =
          await prepareCostMarginActualDocumentsBeforeSave(
            projectRequest?.referenceNo,
            costMarginActualItems,
          );

        // collect other documents
        otherDocumentsFinal = await prepareOtherDocumentsBeforeSave(
          projectRequest?.referenceNo,
          otherDocuments,
        );

        if (hasPrivilege(PrivilegeActions.edit_feedback)) {
          // collect feedback documents
          feedbackDocumentsFinal = await prepareFeedbackDocumentsBeforeSave(
            projectRequest?.referenceNo,
            feedbackDocuments,
          );
        }

        if (hasPrivilege(PrivilegeActions.edit_payment_received)) {
          // collect payment data
          const paymentRecords =
            paymentList.length > 0 ? mapPaymentList(paymentList) : [];
          paymentItems = await preparePaymentDocumentsBeforeSave(
            projectRequest?.referenceNo,
            paymentRecords,
          );
        }
      }

      const payload = createPayload(
        action,
        {
          _id: projectRequest?._id,
          status: projectRequest?.status,
          referenceNo: projectRequest?.referenceNo,
          createdBy: projectRequest?.createdBy,
          submittedForApproval: projectRequest?.submittedForApproval,
          ...data,
          contractStart,
          contractCompletion,
          estimatedContractStart,
          estimatedContractCompletion,
          step,
          costMarginItems: costMarginProjectedItems,
          totalCost,
          contractValue,
          profit,
          profitMargin,
          outstandingBalance: contractBalance ?? outstandingBalance,
          totalPayment,
          progress,
          progressList,
          costMarginAList: costMarginActualItems,
          totalCostA,
          contractValueA,
          profitA,
          profitMarginA,
          totalBillAmount,
          paymentList: paymentItems,
          awardedDate,
          documents: otherDocumentsFinal,
          feedbackDocuments: feedbackDocumentsFinal,
          selectedCompany: selectedCompany,
        },
        signPic,
      );

      if (action == PR_ACTIONS.APPROVE && isStep_1_5(step)) {
        payload.request.reason = approveMessage;
      }

      proceedToNextStep(payload);
    }
  }

  async function onSubmitReject(e) {
    e.preventDefault();
    setAction(PR_ACTIONS.REJECT);
    const payload = {
      action: PR_ACTIONS.REJECT,
      request: { ...projectRequest, reason: rejectReason },
    };
    proceedToNextStep(payload);
  }

  function printReport(id) {
    return sendRequest(`/v1/project-request/generate-report/${id}`, "GET");
  }

  function onPrintSuccess(response) {
    if (response?.status === 200 && response?.data?.url) {
      const link = document.createElement("a");
      link.setAttribute("href", response?.data?.url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", response?.data?.fileName);
      link.click();
    }
  }

  async function onPrint(e) {
    e.preventDefault();
    if (projectRequest?._id) {
      const response = await printReport(projectRequest?._id);
      onPrintSuccess(response);
    }
  }

  async function onSubmitRevise(e) {
    e.preventDefault();
    setAction(PR_ACTIONS.NEED_REVISION);
    const payload = {
      action: PR_ACTIONS.NEED_REVISION,
      request: { ...projectRequest, reason: reviseMessage },
    };
    proceedToNextStep(payload);
  }

  async function onSubmitDecline(e) {
    e.preventDefault();
    setAction(PR_ACTIONS.DECLINE);
    const payload = {
      action: PR_ACTIONS.DECLINE,
      request: { ...projectRequest, reason: declineReason },
    };
    proceedToNextStep(payload);
  }

  function nextStep(payload) {
    return sendRequest(`/v1/project-request/next-step`, "POST", payload);
  }

  async function proceedToNextStep(payload) {
    const response = await nextStep(payload);
    handleStepResponse(response, !!payload.request?._id);
  }

  async function handleStepResponse(res, existing = false) {
    if (res?.status === 200 && res?.data?.request) {
      const savedRequest = res?.data?.request;
      let message = undefined;

      switch (res?.data?.stepResult) {
        case PR_STEP_RESULTS.UPDATED:
          loadDocumentPanelData(savedRequest);
          message = `Project request ${savedRequest.referenceNo} has been updated.`;
          break;
        case PR_STEP_RESULTS.CREATED:
          message = `New project request ${savedRequest.referenceNo} has been created.`;
          const p1 = await updateOtherDocumentsAfterNewSave(savedRequest);
          const p2 = await updateCostMarginDocumentsAfterSave(savedRequest);

          await nextStep(
            createPayload(PR_ACTIONS.SAVE, { ...savedRequest, ...p1, ...p2 }),
          );

          props.history.push(`/app/project-request/card/${savedRequest._id}`);
          break;
        case PR_STEP_RESULTS.SUBMITTED:
          message = `Project request ${savedRequest.referenceNo} has been submitted for approval.`;
          if (!existing) {
            const p1 = await updateOtherDocumentsAfterNewSave(savedRequest);
            const p2 = await updateCostMarginDocumentsAfterSave(savedRequest);

            await nextStep(
              createPayload(PR_ACTIONS.SAVE, { ...savedRequest, ...p1, ...p2 }),
            );
          }
          break;
        case PR_STEP_RESULTS.APPROVED:
          message = `Project request ${savedRequest.referenceNo} has been approved.`;
          break;
        case PR_STEP_RESULTS.REJECTED:
          message = `Project request ${savedRequest.referenceNo} has been rejected.`;
          break;
        case PR_STEP_RESULTS.REVISED:
          message = `Project manager for project request ${savedRequest.referenceNo} has been informed.`;
          break;
        case PR_STEP_RESULTS.DECLINED:
          message = `Project request ${savedRequest.referenceNo} has been declined.`;
          break;
        case PR_STEP_RESULTS.AWARDED:
          message = `Project request ${savedRequest.referenceNo} has been awarded.`;
          break;
        default:
          break;
      }
      message && setMessage(message);
    }
  }

  async function uploadDocuments(
    referenceNo,
    type,
    newDocuments,
    removedDocuments,
  ) {
    let uploadedFiles = [];
    if (newDocuments?.length > 0 || removedDocuments?.length > 0) {
      const response = await upload(
        referenceNo,
        type,
        newDocuments,
        removedDocuments.length > 0 ? removedDocuments : [],
      );
      if (response?.status === 201 && response?.data) {
        uploadedFiles = response?.data?.files || [];
      }
    }
    return uploadedFiles;
  }

  async function prepareCostMarginDocumentsBeforeSave(
    referenceNo,
    costMarginRecords,
  ) {
    const newCmDocuments = getNewCostMarginDocumentList(costMarginList);
    const uploadedFiles = await uploadDocuments(
      referenceNo,
      PR_DOCUMENT_TYPES.COST_MARGIN,
      newCmDocuments,
      removedCMDocuments,
    );

    for (let cmIndex = 0; cmIndex < costMarginList?.length; cmIndex++) {
      const cmRawRecord = costMarginList[cmIndex];
      const cmRecord = costMarginRecords[cmIndex];

      for (let document of cmRawRecord.documents) {
        const uploadedDoc = uploadedFiles.find(
          (uf) => uf.fileName === document.fileName,
        );
        if (uploadedDoc) {
          const doc = cmRecord.documents.find(
            (uf) => uf.fileName === uploadedDoc.fileName,
          );
          if (doc) {
            doc.url = uploadedDoc.url;
            doc.uploadedDate = moment().toDate();
          }
        }
      }
    }
    return costMarginRecords;
  }

  async function prepareCostMarginActualDocumentsBeforeSave(
    referenceNo,
    costMarginActualRecords,
  ) {
    const newCmActualDocuments =
      getNewCostMarginActualDocumentList(costMarginAList);
    const uploadedFiles = await uploadDocuments(
      referenceNo,
      PR_DOCUMENT_TYPES.COST_MARGIN_A,
      newCmActualDocuments,
      removedCMActualDocuments,
    );

    for (let cmIndex = 0; cmIndex < costMarginAList?.length; cmIndex++) {
      const cmRawRecord = costMarginAList[cmIndex];
      const cmRecord = costMarginActualRecords[cmIndex];

      for (let document of cmRawRecord.documents) {
        const uploadedDoc = uploadedFiles.find(
          (uf) => uf.fileName === document.fileName,
        );
        if (uploadedDoc) {
          const doc = cmRecord.documents.find(
            (uf) => uf.fileName === uploadedDoc.fileName,
          );
          if (doc) {
            doc.url = uploadedDoc.url;
            doc.uploadedDate = moment().toDate();
          }
        }
      }
    }
    return costMarginActualRecords;
  }

  async function prepareOtherDocumentsBeforeSave(referenceNo, documents) {
    const newOtherDocuments = documents.filter((d) => d.new);
    const uploadedFiles = await uploadDocuments(
      referenceNo,
      PR_DOCUMENT_TYPES.OTHER_DOCUMENTS,
      newOtherDocuments,
      removedOtherDocuments,
    );

    const uploadedDocuments = [];
    if (uploadedFiles.length > 0) {
      for (let dIndex = 0; dIndex < newOtherDocuments.length; dIndex++) {
        const docRawRecord = newOtherDocuments[dIndex];
        const uploadedDoc = uploadedFiles.find(
          (uf) => uf.fileName === docRawRecord.fileName,
        );
        if (uploadedDoc) {
          uploadedDocuments.push({
            description: docRawRecord.description,
            fileName: docRawRecord.fileName,
            url: uploadedDoc.url,
            uploadedDate: moment().toDate(),
            type: docRawRecord.type,
            uploadedBy:
              docRawRecord.uploadedBy == ""
                ? userInfo.username
                : docRawRecord.uploadedBy,
          });
        }
      }
    }

    return [
      ...documents
        .filter((d) => !d.new)
        .map((d) => ({
          _id: d._id,
          fileName: d.fileName,
          description: d.description,
          url: d.url,
          uploadedDate: d.uploadedDate,
          type: d.type,
          uploadedBy: d.uploadedBy == "" ? userInfo.username : d.uploadedBy,
        })),
      ...uploadedDocuments.map((f) => ({
        fileName: f.fileName,
        url: f.url,
        description: f.description,
        uploadedDate: f.uploadedDate,
        type: f.type,
        uploadedBy: f.uploadedBy == "" ? userInfo.username : f.uploadedBy,
      })),
    ];
  }

  async function prepareFeedbackDocumentsBeforeSave(referenceNo, documents) {
    const newFeedbackDocuments = documents.filter((d) => d.new);
    const uploadedFiles = await uploadDocuments(
      referenceNo,
      PR_DOCUMENT_TYPES.FEEDBACK_DOCUMENTS,
      newFeedbackDocuments,
      removedFeedbackDocuments,
    );

    const uploadedDocuments = [];
    if (uploadedFiles.length > 0) {
      for (let fdIndex = 0; fdIndex < newFeedbackDocuments.length; fdIndex++) {
        const docRawRecord = newFeedbackDocuments[fdIndex];
        const uploadedDoc = uploadedFiles.find(
          (uf) => uf.fileName === docRawRecord.fileName,
        );
        if (uploadedDoc) {
          uploadedDocuments.push({
            description: docRawRecord.description,
            fileName: docRawRecord.fileName,
            url: uploadedDoc.url,
            uploadedDate: moment().toDate(),
          });
        }
      }
    }

    return [
      ...documents
        .filter((d) => !d.new)
        .map((d) => ({
          _id: d._id,
          fileName: d.fileName,
          description: d.description,
          url: d.url,
          uploadedDate: d.uploadedDate,
        })),
      ...uploadedDocuments.map((f) => ({
        fileName: f.fileName,
        url: f.url,
        description: f.description,
        uploadedDate: f.uploadedDate,
      })),
    ];
  }

  async function preparePaymentDocumentsBeforeSave(
    referenceNo,
    paymentRecords,
  ) {
    const newPaymentDocuments = getNewPaymentDocumentList(paymentList);
    const uploadedFiles = await uploadDocuments(
      referenceNo,
      PR_DOCUMENT_TYPES.PAYMENT_RECEIVED,
      newPaymentDocuments,
      removedPaymentDocuments,
    );

    for (let pIndex = 0; pIndex < paymentList?.length; pIndex++) {
      const paymentRawRecord = paymentList[pIndex];
      const paymentRecord = paymentRecords[pIndex];

      for (let document of paymentRawRecord.documents) {
        const uploadedDoc = uploadedFiles.find(
          (uf) => uf.fileName === document.fileName,
        );
        if (uploadedDoc) {
          const doc = paymentRecord.documents.find(
            (uf) => uf.fileName === uploadedDoc.fileName,
          );
          if (doc) {
            doc.url = uploadedDoc.url;
            doc.uploadedDate = moment().toDate();
          }
        }
      }
    }
    return paymentRecords;
  }

  async function updateCostMarginDocumentsAfterSave(savedRequest) {
    const newCmDocuments = getNewCostMarginDocumentList(costMarginList);
    const uploadedFiles = await uploadDocuments(
      savedRequest.referenceNo,
      PR_DOCUMENT_TYPES.COST_MARGIN,
      newCmDocuments,
      [],
    );
    if (uploadedFiles.length > 0) {
      for (let cmIndex = 0; cmIndex < costMarginList?.length; cmIndex++) {
        const cmRawRecord = costMarginList[cmIndex];
        const cmRecord = savedRequest.costMarginList[cmIndex];
        let documents = [];
        for (let document of cmRawRecord.documents) {
          const uploadedDoc = uploadedFiles.find(
            (uf) => uf.fileName === document.fileName,
          );
          if (uploadedDoc) {
            documents.push({
              description: document.description,
              fileName: document.fileName,
              url: uploadedDoc.url,
              uploadedDate: moment().toDate(),
            });
          }
        }
        cmRecord.documents = documents;
      }
    }
    return { costMarginItems: savedRequest.costMarginList };
  }

  async function updateOtherDocumentsAfterNewSave(savedRequest) {
    const newDocuments = otherDocuments.filter((d) => d.new);
    let payload = undefined;
    let documents = [];

    if (prVersion > 1 && documents.findIndex((r) => r.type == "PNL") == -1) {
      documents.push({
        type: "PNL",
        description: "Project P&L",
        fileName: `PL-${savedRequest.referenceNo}.PDF`,
      });
    }

    if (newDocuments.length > 0) {
      const uploadedFiles = await uploadDocuments(
        savedRequest.referenceNo,
        PR_DOCUMENT_TYPES.OTHER_DOCUMENTS,
        newDocuments,
        removedOtherDocuments,
      );

      if (uploadedFiles.length > 0) {
        for (let dIndex = 0; dIndex < newDocuments.length; dIndex++) {
          const docRawRecord = newDocuments[dIndex];

          const uploadedDoc = uploadedFiles.find(
            (uf) => uf.fileName === docRawRecord.fileName,
          );
          if (uploadedDoc) {
            documents.push({
              description: docRawRecord.description,
              fileName: docRawRecord.fileName,
              url: uploadedDoc.url,
              uploadedDate: moment().toDate(),
              type: docRawRecord.type,
              uploadedBy:
                docRawRecord.uploadedBy == ""
                  ? userInfo.username
                  : docRawRecord.uploadedBy,
            });
          }
        }
        payload = { documents: [...documents] };
      }
    }

    return payload;
  }

  function upload(referenceNo, type, newDocuments, docsToBeRemoved) {
    const formData = new FormData();
    for (const doc of newDocuments) {
      if (doc.file && doc.fileName) {
        formData.append("file", doc.file, doc.fileName);
      }
    }
    formData.append("referenceNo", referenceNo);
    formData.append("type", type);
    formData.append(
      "filesToBeDeleted",
      docsToBeRemoved ? JSON.stringify(docsToBeRemoved) : "[]",
    );

    return sendRequest(
      `/v1/project-request/upload-project-docs`,
      "POST",
      formData,
    );
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} size={150} />
        </Backdrop>
      )}
      <form>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant={"h1"}>
              Project Request Card -{" "}
              {!!requestId && !!projectRequest
                ? projectRequest.referenceNo
                : `New`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
            {cancelButton(actionBtnProps)}
            {saveButton(actionBtnProps, forceShowBtns)}
            {submitButton(actionBtnProps, forceShowBtns)}
            {reviseButton(actionBtnProps)}
            {printButton(printBtnProps)}
            {needRevisionButton(
              { ...actionBtnProps, hasRemainingCMApprovalsForUser },
              forceShowBtns,
            )}
            {rejectButton(actionBtnProps)}
            {approveButton(
              { ...actionBtnProps, hasRemainingCMApprovalsForUser },
              forceShowBtns,
            )}
            {declineButton(actionBtnProps)}
            {awardButton(actionBtnProps)}
            {notApplicableButton(actionBtnProps)}
            {downloadDocument(actionBtnProps, isDownloading)}
          </Grid>
          {generalPanel(generalPanelProps)}
          {projectRequest && (
            <>
              {progressPanel(
                progressPanelProps,
                projectRequest.step,
                projectRequest.status,
              )}
              {feedbackPanel(
                feedbackPanelProps,
                projectRequest.status,
                hasPrivilege(PrivilegeActions.edit_feedback),
              )}
              {paymentPanel(
                paymentsPanelProps,
                projectRequest.step,
                projectRequest.status,
              )}
              {claimDepositPanel(
                claimsDepositsPanelProps,
                projectRequest.status,
              )}
            </>
          )}
          {claimDepositProjectedPanel(claimsDepositsProjectedPanelProps)}
          {projectRequest && (
            <>
              {costMarginActualPanel(
                costAndMarginAPanelProps,
                projectRequest.status,
              )}
            </>
          )}
          {costMarginProjectedPanel(costAndMarginPanelProps)}
          {documentsPanel(documentsPanelProps)}
          {projectRequest && (
            <>{historyPanel(historyPanelProps, projectRequest.step)}</>
          )}
        </Grid>
      </form>
      <ProjectRequestRejectModal {...rejectModalProps} />
      <ProjectRequestDeclineModal {...declineModalProps} />
      <ProjectRequestReviseModal {...reviseModalProps} />
      <ProjectRequestApproveModal {...approveModalProps} />
      <ProjectRequestSignaturePadModal {...signaturePadModalProps} />
    </>
  );
};
export default withRouter(ProjectRequestCard);
