import { Switch } from "@mui/material";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";

export const fnbFoodTableChefModeColumnConfig = (
  { allowedEdit },
  handleEditFnbFood,
) => {
  let columns = [
    {
      name: "Store",
      width: "150px",
      selector: (row) => row.store.name.toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "store.name",
    },
    {
      name: "Sold Out",
      width: "90px",
      center: true,
      selector: (row) => row.inStock,
      cell: (row) => {
        return (
          <Switch
            checked={!row.inStock}
            onChange={(e, value) =>
              handleEditFnbFood(row, "inStock", true, !value)
            }
            inputProps={{ "aria-label": "controlled" }}
            disabled={!allowedEdit}
          />
        );
      },
      sortable: true,
      sortField: "inStock",
    },
    {
      name: "Enable",
      width: "80px",
      center: true,
      selector: (row) => row.status,
      cell: (row) => {
        return (
          <Switch
            checked={row.status}
            onChange={(e, value) =>
              handleEditFnbFood(row, "status", true, value)
            }
            inputProps={{ "aria-label": "controlled" }}
            disabled={!allowedEdit}
          />
        );
      },
      sortable: true,
      sortField: "status",
    },
    {
      name: "SKU",
      width: "70px",
      selector: (row) => row.sku,
      sortable: true,
      sortField: "sku",
    },
    {
      name: "Name",
      width: "250px",
      selector: (row) => row.name.toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "name",
    },
    {
      name: "Category",
      width: "200px",
      selector: (row) => row.category.name.toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "category.name",
    },
    {
      name: "Unit Price",
      width: "100px",
      right: true,
      selector: (row) => convertThisToCurrency(row.price),
      sortable: true,
      sortField: "price",
    },
  ];

  return columns;
};
