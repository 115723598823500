import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  Button,
  IconButton,
  TextField,
  Stack,
  Divider,
  Box,
  Alert,
} from "@mui/material";
import Swal from "sweetalert2";
import "./style/index.css";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DataTable from "react-data-table-component";
import { Add } from "@mui/icons-material";
import { optionTableColumnConfig } from "./data-table/option-table-column-config";
import _ from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { EditorController } from "../../components/EditorController";
import TextFieldController from "../../components/TextFieldController";
import { emailContentList, messageList } from "./data";

// ************************ **********************

const AirconSubscriptionAttribute = (props) => {
  // *********** VAR *************
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    sendRequest,
    httpRequestError: error,
    responseData,
  } = useHttpRequest();
  const methods = useForm({ mode: "all" });
  const { handleSubmit, control, reset, getValues, setValue, formState } =
    methods;

  // *********** FUNCTION *************

  const handleSubmitForm = async (data) => {
    try {
      await sendRequest(
        `/v1/aircon-subscription-attribute/attribute`,
        "PUT",
        data,
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Aircon subscription attribute has been updated.",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  };

  const onRemoveRow = (name, i) => {
    const rows = getValues(name);
    rows.splice(i, 1);
    setValue(name, [...rows]);
    methods.trigger(name);
  };

  const createNewRow = async (name) => {
    const rows = getValues(name) ?? [];
    setValue(name, [
      ...rows,
      {
        option: "",
        enabled: true,
      },
    ]);
    await new Promise((resolve) => setTimeout(resolve, 100));
    methods.trigger(name);
  };

  const preferredOutletColumns = optionTableColumnConfig({
    onRemoveRow,
    name: "preferredOutlets",
  });

  const systemColumns = optionTableColumnConfig({
    onRemoveRow,
    name: "systems",
  });

  // *********** HOOK *************

  useEffect(() => {
    document.title = "Aircon Subscription Attribute";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.group_buy_attributes,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
    sendRequest(`/v1/aircon-subscription-attribute/attribute`, "GET").then(
      (res) => {
        reset({
          ...res.data,
          preferredOutlets: res.data.preferredOutlets ?? [],
          systems: res.data.systems ?? [],
        });
      },
    );
  }, []);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // *********** Render *************

  const pageTitleRender = (
    <Typography variant={"h1"}>Aircon Subscription Attribute</Typography>
  );

  const actionRender = auth.checkModulePrivilege(
    PrivilegeModules.group_buy_attributes,
    PrivilegeActions.edit,
  ) && (
    <Button
      className={"primary"}
      type="submit"
      disabled={Object.keys(formState.errors).length !== 0}
    >
      Save
    </Button>
  );

  const generalFormRender = (
    <Box className={"form"}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant={"h2"}>General</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={6}>
              <TextFieldController
                name={`tncClause`}
                label={`TNC Clause`}
                multiline
                minRows={3}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldController
                name={`pdpaClause`}
                label={`PDPA Clause`}
                multiline
                minRows={3}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const messageFormRender = (
    <Box className={"form"}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant={"h2"}>Message</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {messageList.map((message, index) => (
              <Grid item xs={12} sm={6}>
                <Stack gap={{ xs: 2, md: 3 }}>
                  <TextFieldController
                    name={`messageContents.${message.id}.title`}
                    label={`${message.label.title}`}
                    required
                  />
                  <TextFieldController
                    name={`messageContents.${message.id}.message`}
                    label={`${message.label.message}`}
                    multiline
                    minRows={4}
                    required
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const emailFormRender = (
    <Box className={"form"}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant={"h2"}>{`Email`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {emailContentList.map((item) => (
              <Grid item xs={12}>
                <Stack gap={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <TextFieldController
                      name={`emailContents.${item.id}.subject`}
                      label={`${item.label} Subject`}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditorController
                      control={control}
                      label={item.label}
                      required={true}
                      name={`emailContents.${item.id}.content`}
                    />
                  </Grid>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const preferredOutletTableRender = (
    <Box className={"form"}>
      <Controller
        name={"preferredOutlets"}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const list = field.value ?? [];
          return (
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} className={"sub-action"}>
                <Typography variant={"h2"}>Preferred Outlet</Typography>
              </Grid>
              <Grid item xs={12} className={"table"}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={preferredOutletColumns}
                  data={list}
                />
              </Grid>
              <Grid item xs={12} className={"table-action"}>
                <IconButton
                  onClick={(e) => createNewRow("preferredOutlets")}
                  disabled={!!error}
                >
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
          );
        }}
      />
    </Box>
  );

  const systemTableRender = (
    <Box className={"form"}>
      <Controller
        name={"systems"}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const list = field.value ?? [];
          return (
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} className={"sub-action"}>
                <Typography variant={"h2"}>System</Typography>
              </Grid>
              <Grid item xs={12} className={"table"}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={systemColumns}
                  data={list}
                />
              </Grid>
              <Grid item xs={12} className={"table-action"}>
                <IconButton
                  onClick={(e) => createNewRow("systems")}
                  disabled={!!error}
                >
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
          );
        }}
      />
    </Box>
  );

  const formRender = (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {pageTitleRender}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
            {actionRender}
          </Grid>

          <Grid item xs={12}>
            {generalFormRender}
          </Grid>

          <Grid item xs={12}>
            {messageFormRender}
          </Grid>

          <Grid item xs={12}>
            {emailFormRender}
          </Grid>

          <Grid item xs={12}>
            {preferredOutletTableRender}
          </Grid>

          <Grid item xs={12}>
            {systemTableRender}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      {formRender}
    </>
  );
};

export default AirconSubscriptionAttribute;
