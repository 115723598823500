import {
    Backdrop, Button, CircularProgress,
    Grid, TextField, Typography
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { useUserState } from "../../context/UserContext";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import TagInput from "../../components/TagInput/TagInput";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";

const ServiceClaimCard = (props) => {
    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // user info & permissions
    const { userInfo } = useUserState();

    // page authorization
    const { auth } = useRoleAuthorization();

    // handling form when adding / editing
    const { register, handleSubmit, setValue,
        formState: { dirtyFields, isSubmitted } }
        = useForm({ mode: "all" });

    const [invoiceNos, setInvoiceNos] = useState([]);
    const [message, setMessage] = useState("");
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        document.title = "New - Service Claim Card";
        
        if (userInfo) { 
            setValue('team', `${userInfo.username}-${userInfo.name}`); 
        }
    }, []);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    useEffect(() => {
        if (isSubmitted) {
            validateInvoiceNosTagInput();
        }
    }, [isSubmitted]);

    // navigate back to claim list
    const navigateToList = () => {
        window.location.href = '#/app/service-claim/list';
    };

    // tag input validations
    const validateInvoiceNosTagInput = () => {
        if (invoiceNos.length === 0) {
            setValue('invoiceNos', [], { shouldDirty: true });
        }
    };

    // check bracket before add to list
    const checkBeforeAdd = async (list, current) => {
        try {
            current = current.toUpperCase();
            const { valid } = await validateInvoice(current, userInfo.username);

            if (!valid) {
                setWarningMessage(`${current} does not exist in the system.`);
                setInvoiceNos(list); // revert to previous
            }
        } catch (error) {
            setInvoiceNos(list); // revert to previous
        }
    };

    const handleSubmitForm = async (data, e) => {
        e.preventDefault();
        if (invoiceNos.length === 0 || invoiceNos.length === 0) { return; }

        const result = await sendRequest('/v1/service-claim', "POST", {
            team: userInfo.username,
            teamName: userInfo.name,
            invoiceNos
        });
        if (result?.status === 201 && result?.data?.serviceClaims) {
            setMessage(`New service claim(s) has been created.`); // call show feedback message
        }
    };

    // validate invoice and get by no
    async function validateInvoice(invoiceNo, team) {
        const response = await sendRequest(
            '/v1/service-claim/validate-invoice', 'POST',
            { invoiceNos: [invoiceNo], team });
        return response.data;
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Service Claim Card - New</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/service-claim/list')}>Cancel</Button>

                        {
                            (auth.checkModulePrivilege(PrivilegeModules.service_claim, PrivilegeActions.add)) && 
                            <Button className={"primary"} type={"button"} onClick={handleSubmit(handleSubmitForm)}>Save</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField id={"team"} label={"Team"} variant={"outlined"}
                                                    {...register("team")} disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={8} xl={9}>
                                                <TagInput
                                                    name={"invoiceNos"}
                                                    required={true}
                                                    autoFocus={true}
                                                    tags={invoiceNos}
                                                    setTags={setInvoiceNos}
                                                    label={`Invoice No.`}
                                                    error={(dirtyFields.invoiceNos && invoiceNos.length === 0)}
                                                    helperText={(dirtyFields.invoiceNos && invoiceNos.length === 0)
                                                        && "Invoice No. cannot be blank."}
                                                    onChange={(data) => {
                                                        setValue('invoiceNos', data);
                                                        validateInvoiceNosTagInput();
                                                    }}
                                                    regex={/[^-\w\s]/gi}
                                                    onKeyDown={(list, current) => checkBeforeAdd(list, current)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(ServiceClaimCard);