import React, { useEffect } from "react";
import { Backdrop, Typography, CircularProgress, Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { useUserState } from "../../context/UserContext";
import { useHttpRequest } from "../../hooks/useHttpRequest";

const ChangePassword = () => {

    // local
    const { userInfo } = useUserState();

    const { register, handleSubmit, watch, reset, formState: { errors: formErrors } } = useForm();

    const { isLoading, httpRequestError: error,
        responseData, sendRequest: formSubmitCaller } = useHttpRequest();

    useEffect(() => {
        document.title = "Change Password";
    }, []);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message,
            })
        }
    }, [error, responseData]);

    const validateConfirmPassword = (confirmPassword) => {
        if (watch('newPassword') !== confirmPassword) {
            return "password_not_matched";
        }
    };

    const validateNewPassword = (newPassword) => {
        if (watch('currentPassword') === newPassword) {
            return "cannot_repeat_current_password";
        }
    };

    const onValid = async (data) => {
        data.userId = userInfo._id;

        await formSubmitCaller(
            '/v1/user/change-password',
            "POST",
            data
        ).then(result => {
            reset({ currentPassword: '', newPassword: '', confirmPassword: '' });
            if (result?.status === 200 && result?.data?.updated) {
                Swal.fire({
                    icon: 'success',
                    title: "Success",
                    text: result?.data?.message
                });
            }
        });
    };

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 1 }} open={isLoading}><CircularProgress color={"inherit"} /></Backdrop>}
        <form onSubmit={handleSubmit(onValid)}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant={"h1"}> Change Password </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                    <Button className={"primary"} type="submit">Submit</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={"form"}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        id="current-password"
                                        label="Current Password"
                                        variant={"outlined"}
                                        helperText={formErrors?.currentPassword?.type === "required" && "Current Password cannot be blank."}
                                        error={!!formErrors?.currentPassword}
                                        {...register('currentPassword')}
                                        autoComplete={"off"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        id="new-password"
                                        label="New Password"
                                        variant={"outlined"}
                                        helperText={(formErrors?.newPassword?.type === "required" && "New Password cannot be blank.")
                                            || (formErrors?.newPassword?.message === "cannot_repeat_current_password"
                                                && "Cannot use current password again.")}
                                        {...register('newPassword', { required: true, validate: validateNewPassword })}
                                        error={!!formErrors?.newPassword}
                                        autoComplete={"off"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        id="confirm-password"
                                        label="Confirm Password"
                                        variant={"outlined"}
                                        helperText={(formErrors?.confirmPassword?.type === "required" && "Confirm Password cannot be blank.")
                                            || (formErrors?.confirmPassword?.message === "password_not_matched" && "Passwords do not match.")}
                                        error={!!formErrors?.confirmPassword}
                                        {...register('confirmPassword', { required: true, validate: validateConfirmPassword })}
                                        autoComplete={"off"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </>
    );
};

export default ChangePassword;
