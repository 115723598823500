import React from "react";
import { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
    Backdrop, Button, CircularProgress, FormControlLabel,
    Grid, Switch, TextField, Typography, InputAdornment, IconButton
} from "@mui/material";
import Swal from "sweetalert2";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import useStyles from "./styles";
import { validateText } from "../../helper/validate-textfield";
import ImageUploadPreviewComponent from '../../components/ImageUploadPreviewComponent/ImageUploadPreviewComponent';
import Floorplans from "./components/Floorplans/Floorplans";
import moment from "moment";
import { useUserState } from "../../context/UserContext";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const CustomerExistingAirconCard = (props) => {
    const classes = useStyles();
    const { userInfo } = useUserState();
    const [orderDate, setOrderDate] = useState(new Date()); // today by default
    const [installationDate, setInstallationDate] = useState(new Date()); // today by default

    // handle loading & http
    const { isLoading, httpRequestError: error, responseData, sendRequest } = useHttpRequest();

    // handling form when adding / editing
    const { register, handleSubmit, control, formState: { errors: formErrors }, setValue, watch }
        = useForm({ mode: "all" });

    // page authorization
    const { auth } = useRoleAuthorization();

    // get id from url when editing
    const { salesOrderNo } = useParams();

    const [message, setMessage] = useState("");
    const [errMessage, setErrMessage] = useState("");
    const [currentCustomerExistingAircon, setCurrentCustomerExistingAircon] = useState(null);
    const [lstImages, setLstImages] = useState([]);
    const [lstDeletedImages, setLstDeletedImages] = useState([]);
    const [lstFloorplans, setLstFloorplans] = useState([]);
    const [lstDeletedFloorplans, setLstDeletedFloorplans] = useState([]);

    var lstImageUrls = [];
    var lstFloorplanUrls = [];

    useEffect(() => { 
        document.title = "New - Customer Existing Aircon"; 
        
        // redirect to default page if not authorized
        if (salesOrderNo && auth.isPrivilegeDataLoaded() && (!auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon,PrivilegeActions.view_detail))){
            props.history.push('/app/dashboard');
        }
    }, []);

    // get current record when editing 
    useEffect(() => {
        if (!isLoading && !responseData && salesOrderNo) {
            sendRequest(`/v1/customer-existing-aircon/get-by-so-number/` + salesOrderNo, 'GET');
        }
    }, [salesOrderNo]);

    // set title & form values with current record when editing
    useEffect(() => {
        if (salesOrderNo && responseData?.custExistingAircon) {
            const cea = responseData?.custExistingAircon;
            document.title = `${cea.salesOrderNo} - Customer Existing Aircon Card`;
            setCurrentCustomerExistingAircon(cea);
            setValue('salesOrderNo', cea.salesOrderNo || '');
            setValue('orderDate', moment(new Date(cea.orderDate)).format('DD/MM/YYYY') || '');
            setValue('installationDate', moment(new Date(cea.installationDate)).format('DD/MM/YYYY') || '');
            setValue('images', cea.images || '');
            setValue('remark', cea.remark || '');
            setOrderDate(cea.orderDate || '');
            setInstallationDate(cea.installationDate || '');
            setValue('createdBy', cea.createdBy || '');
            setValue('updatedBy', cea.updatedBy || '');
            setValue("shipToAddress", cea.shipToAddress || '');
            setValue("shipToPostalCode", cea.shipToPostalCode || '');

            if (cea.images != undefined && cea.images != null && cea.images != '') {
                let retImaArray = cea.images.split(",")
                const lstImages = [];
                for (var i = 0; i < retImaArray.length; i++) {
                    const id = Math.floor(Math.random() * 10000);
                    lstImages.push({ id: id, imageName: '', image: {}, cloudUrl: retImaArray[i], newItem: false });
                }
                setLstImages(lstImages);
            }

            if (cea.floorplans != undefined && cea.floorplans != null && cea.floorplans != '') {
                let retItemArray = cea.floorplans.split(",");
                const lstItems = [];
                for (var i = 0; i < retItemArray.length; i++) {
                    let retItemData = retItemArray[i].split("*");
                    const id = Math.floor(Math.random() * 10000);

                    lstItems.push({ id: id, imageName: retItemData[0], image: {}, cloudUrl: retItemData[1], uploadedDate: retItemData[2], newItem: false, deleted: false });
                }
                setLstFloorplans(lstItems);
            }
        }
    }, [responseData]);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });

            setMessage("");
        }
    }, [message, responseData]);

    useEffect(() => {
        if (errMessage) {
            Swal.fire({
                icon: 'error',
                title: "Oops...",
                text: errMessage,
            });

            setErrMessage("");
        }
    }, [errMessage]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    const addImage = (id, imageName, image) => {
        setLstImages(current => [...current, { id: id, imageName: imageName, image: image, cloudUrl: '', newItem: true }]);
    }

    const removeImage = (id) => {
        var deletedFile = lstImages.filter(element => {
            return element.id == id;
        })[0];

        if (deletedFile.newItem == false) {
            var deletedFileName = deletedFile.cloudUrl.substring(deletedFile.cloudUrl.lastIndexOf('/') + 1);
            setLstDeletedImages(current => [...current, deletedFileName]);
        }

        setLstImages(current => current.filter(element => {
            return element.id != id;
        }));
    }
    
    // when submit the form
    const handleSubmitForm = async (data, e) => {
        e.preventDefault();

        let salesOrderDate = null;
        let installationDate = null;
        let shipToAddress = null;
        let shipToPostalCode = null;

        //validate the Sale Order Number against with the Navision system and retrieving the dates
        if (salesOrderNo && currentCustomerExistingAircon && currentCustomerExistingAircon._id) {
            salesOrderDate = convertStringToDate(data.orderDate);
            installationDate = convertStringToDate(data.installationDate);
            shipToAddress = data.shipToAddress;
            shipToPostalCode = data.shipToPostalCode;
        }
        else{
            let result2 = null;
            result2 = await sendRequest(`/v1/customer-existing-aircon/validate-sales-order-number/${data.salesOrderNo.toUpperCase()}`, "GET");
    
            if (result2.data.isValid == false) {
                setValue('salesOrderNo', '');
                setErrMessage(`${data.salesOrderNo.toUpperCase()} does not exist in Navision.`);
                return;
            }
            
            salesOrderDate = moment(new Date(result2.data.orderDate)).format('YYYY/MM/DD');
            installationDate = moment(new Date(result2.data.installationDate)).format('YYYY/MM/DD');
            shipToAddress = result2.data.shipToAddress;
            shipToPostalCode = result2.data.shipToPostalCode;
        }
        //end-of-validation

        if (lstImages.length == 0 && lstFloorplans.length == 0) {
            setErrMessage(`Please upload at least one photo or one floor plan.` );
            return;
        }

        //uploading/deleting Aircon images 
        const formData = new FormData();
        for (var i = 0; i < lstImages.length; i++) {
            let file = lstImages[i].image;
            let cloudUrl = lstImages[i].cloudUrl;

            if (lstImages[i].newItem == true) {
                formData.append('file', file);
            }
            else {
                lstImageUrls.push(cloudUrl);
            }
        }

        let type = "AC";
        let result1 = null;
        let deletedImageNames = (lstDeletedImages.toString() == "" ? undefined : lstDeletedImages.toString());
        let _id = (currentCustomerExistingAircon == null ? undefined : currentCustomerExistingAircon._id);
        result1 = await sendRequest(`/v1/customer-existing-aircon/upload-image-onto-s3/${data.salesOrderNo.toUpperCase()}/${_id}/${deletedImageNames}/${type}`, "POST", formData);

        if (result1?.status === 201) {
            let urlArrayInResponse = result1.data.urlArray;

            for (var j = 0; j < urlArrayInResponse.length; j++) {
                lstImageUrls.push(urlArrayInResponse[j].url);

                let newItem = lstImages.find(element => element.imageName == urlArrayInResponse[j].fileName && element.newItem == true);
                newItem.cloudUrl = urlArrayInResponse[j].url;
                newItem.newItem = false;
            }
        }else{
            return;
        }
        //

        //uploading/deleting Floorplans 
        const formData2 = new FormData();
        for (var i = 0; i < lstFloorplans.length; i++) {
            let file = lstFloorplans[i].image;
            let cloudUrl = lstFloorplans[i].imageName + "*" + lstFloorplans[i].cloudUrl + "*" + lstFloorplans[i].uploadedDate;

            if (lstFloorplans[i].newItem == true) {
                formData2.append('file', file);
            }else{
                lstFloorplanUrls.push(cloudUrl);
            }
        }

        let result2 = null;
        let deletedFloorplans = (lstDeletedFloorplans.toString() == "" ? undefined : lstDeletedFloorplans.toString());
        type = "FP";
        result2 = await sendRequest(`/v1/customer-existing-aircon/upload-image-onto-s3/${data.salesOrderNo.toUpperCase()}/${_id}/${deletedFloorplans}/${type}`, "POST", formData2);

        if (result2?.status === 201) {
            let urlArrayInResponse = result2.data.urlArray;

            for (let j = 0; j < urlArrayInResponse.length; j++) {
                let today = new Date();
                let date = today.getDate().toString().padStart(2, '0') + '/' + (today.getMonth()+1).toString().padStart(2, '0') + '/' + today.getFullYear();
                let time = today.getHours().toString().padStart(2, '0') + ":" + today.getMinutes().toString().padStart(2, '0') + ":" + today.getSeconds().toString().padStart(2, '0');
                lstFloorplanUrls.push(urlArrayInResponse[j].fileName + "*" + urlArrayInResponse[j].url + "*" + date + ' ' + time);

                let newItem = lstFloorplans.find(element => element.imageName == urlArrayInResponse[j].fileName && element.newItem == true);
                newItem.cloudUrl = urlArrayInResponse[j].url;
                newItem.uploadedDate = date + ' ' + time;
                newItem.newItem = false;
            }
        }else{
            return;
        }
        //

        const oData = {
            salesOrderNo: data.salesOrderNo.toUpperCase(),
            orderDate: salesOrderDate,
            installationDate: installationDate,
            images: lstImageUrls.toString() || null,
            floorplans: lstFloorplanUrls.toString() || null,
            remark: (data.remark == "" ? undefined : data.remark.toUpperCase()),
            createdBy: (salesOrderNo && currentCustomerExistingAircon && currentCustomerExistingAircon._id ? data.createdBy : userInfo._id) ,
            updatedBy: (salesOrderNo && currentCustomerExistingAircon && currentCustomerExistingAircon._id ? userInfo._id : undefined),
            shipToAddress: shipToAddress,
            shipToPostalCode: shipToPostalCode
        };

        let result = null;
        if (salesOrderNo && currentCustomerExistingAircon && currentCustomerExistingAircon._id) {
            oData._id = currentCustomerExistingAircon._id;
            result = await sendRequest(`/v1/customer-existing-aircon/${currentCustomerExistingAircon._id}`, "PUT", oData);
            if (result?.status === 200 && result?.data?.customerExistingAircon) {
                setMessage(`Entry ${oData.salesOrderNo} has been updated.`); // call show feedback message
            }
        } else {
            result = await sendRequest('/v1/customer-existing-aircon', "POST", oData);
            if (result?.status === 201 && result?.data?.customerExistingAircon) {
                setMessage(`New entry ${oData.salesOrderNo} has been created.`); // call show feedback message
            }
        }
    };

    function convertStringToDate(dateInDdMmYyyy) {
        return new Date(dateInDdMmYyyy.split('/')[2] + "/" + dateInDdMmYyyy.split('/')[1] + '/' + dateInDdMmYyyy.split('/')[0]);
    }

    // navigate back to list
    function navigateToList() {
        if (!salesOrderNo){
            window.location.href = '#/app/customer-existing-aircon/list';
        }
    }

    const handleSalesOrderNumberFocusOut = async (data, e) => {
        e.preventDefault();

        let result = null;
        result = await sendRequest(`/v1/customer-existing-aircon/validate-sales-order-number/${data.salesOrderNo.toUpperCase()}`, "GET");

        if (result.data.isValid == false) {
            setValue('salesOrderNo', '');
            setErrMessage(`${data.salesOrderNo.toUpperCase()} does not exist in Navision.`);
            return;
        }

        setOrderDate(moment(new Date(result.data.orderDate)).format('DD/MM/YYYY'));
        setInstallationDate(moment(new Date(result.data.installationDate)).format('DD/MM/YYYY'));

        setValue('orderDate', moment(new Date(result.data.orderDate)).format('DD/MM/YYYY'));
        setValue('installationDate', moment(new Date(result.data.installationDate)).format('DD/MM/YYYY'));
    }

    const removeSpacesFromSORCS = async(salesOrderNo) => {
        setValue('salesOrderNo', salesOrderNo.toUpperCase().trim());
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} />
        </Backdrop>}
        {(!salesOrderNo || (salesOrderNo && currentCustomerExistingAircon)) &&
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Customer Existing Aircon Card - {currentCustomerExistingAircon ? `${currentCustomerExistingAircon.salesOrderNo}` : `New`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/customer-existing-aircon/list')}>Cancel</Button>
                        {((!salesOrderNo && auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon,PrivilegeActions.add)) || (salesOrderNo && auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon,PrivilegeActions.edit))) &&
                            <Button className={"primary"} type={"button"} onClick={handleSubmit(handleSubmitForm)}>Save</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"sales-order-no"}
                                                    label={"Order No. (SORCS/EORCS)"}
                                                    variant={"outlined"}
                                                    {...register("salesOrderNo", {
                                                        required: { value: true, message: "Order No. cannot be blank." }
                                                    })}
                                                    autoComplete={"off"}
                                                    autoFocus={true}
                                                    error={!!formErrors?.salesOrderNo}
                                                    helperText={formErrors?.salesOrderNo?.message}
                                                    onBlur={(e) => removeSpacesFromSORCS(e.target.value)}
                                                    disabled={!!salesOrderNo && !!currentCustomerExistingAircon}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    disabled={!auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon,PrivilegeActions.edit) && salesOrderNo ? true : false}
                                                    id={"remark"}
                                                    label={"Remark"}
                                                    variant={"outlined"}
                                                    {...register("remark", {
                                                        required: { value: false }
                                                    })}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.remark}
                                                    helperText={formErrors?.remark?.message}
                                                    onChange={(event) => validateText(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"order-date"}
                                                    label={"Order Date"}
                                                    variant={"outlined"}
                                                    {...register("orderDate", {
                                                        required: { value: false, message: "Order Date cannot be blank." }
                                                    })}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.orderDate}
                                                    helperText={formErrors?.orderDate?.message}
                                                    disabled={true}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"installation-date"}
                                                    label={"Installation Date"}
                                                    variant={"outlined"}
                                                    {...register("installationDate", {
                                                        required: { value: false, message: "Installation Date cannot be blank." }
                                                    })}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.installationDate}
                                                    helperText={formErrors?.installationDate?.message}
                                                    disabled={true}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"shipToAddress"}
                                                    label={"Ship-to Address"}
                                                    variant={"outlined"}
                                                    {...register("shipToAddress", {
                                                        required: { value: false }
                                                    })}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.shipToAddress}
                                                    helperText={formErrors?.shipToAddress?.message}
                                                    onChange={(event) => validateText(event)}
                                                    disabled={true}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField
                                                    id={"shipToPostalCode"}
                                                    label={"Ship-to Postal"}
                                                    variant={"outlined"}
                                                    {...register("shipToPostalCode", {
                                                        required: { value: false }
                                                    })}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.shipToPostalCode}
                                                    helperText={formErrors?.shipToPostalCode?.message}
                                                    onChange={(event) => validateText(event)}
                                                    disabled={true}
                                                />
                                            </Grid>                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Floorplans 
                            auth={auth}
                            salesOrderNo={salesOrderNo}
                            selectedItems={lstFloorplans}  
                            setSelectedItems={setLstFloorplans}
                            deletedItems={lstDeletedFloorplans}
                            setDeletedItems={setLstDeletedFloorplans}
                            privModules = {PrivilegeModules}
                            privActions = {PrivilegeActions}
                         />
                    </Grid>                         
                    <Grid item xs={12}>
                        <ImageUploadPreviewComponent 
                            savedImages={lstImages} 
                            onAddImage={addImage} 
                            onRemoveImage={removeImage}
                            readOnly={!((!salesOrderNo && auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon, PrivilegeActions.add)) || (salesOrderNo && auth.checkModulePrivilege(PrivilegeModules.customer_existing_aircon, PrivilegeActions.edit)))}
                            />
                    </Grid>
                </Grid>
            </form>}
    </>)
};

export default withRouter(CustomerExistingAirconCard);