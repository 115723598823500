export default function convertArrayOfObjectsToCSV(array, columns = [], isColumnnDef = true) {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  // column definitions
  if(isColumnnDef){
    result += (columns.length > 0 ? columns : keys).join(columnDelimiter);
    result += lineDelimiter;
  }
  
  array.forEach(item => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key] ? `"${item[key].toString().replace('"', '""')}"` : '';
      // eslint-disable-next-line no-plusplus
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
}