import { useRef } from "react";
import { ChevronRight as BulletIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import Tag from "./Tag";
import useStyles from "./styles";
import { useForm } from "react-hook-form";
import { validateText } from "../../helper/validate-textfield";

const TagInput = (props) => {
    const classes = useStyles();
    const tagRef = useRef();
    const { register } = useForm({ mode: "all" });

    const handleDelete = (value) => {
        if (!props?.disabled) { // can remove tags, if not disabled
            const newTags = props?.tags.filter((val) => val !== value);
            props?.setTags(newTags);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value) { // create new tags when Enter
            e.preventDefault();
            const value = props?.regex ?
                tagRef.current.value.replace(props?.regex, '') : tagRef.current.value;
            if (!checkExisting(value)) {
                props?.setTags([...props?.tags, value]);
                props?.onChange([...props?.tags, value]);
                // trigger on keydown event
                if (props?.onKeyDown) {
                    props?.onKeyDown([...props?.tags], value);
                }
            }
            tagRef.current.value = '';
        }
    };

    const checkExisting = (current) => {
        return props?.tags.indexOf(current) > -1;
    };

    return (<div className={"tag-input"}>
        <TextField
            {...register(props?.name)}
            inputRef={tagRef}
            fullWidth
            variant='outlined'
            placeholder={!props?.disabled ? "Enter here.." : ""}
            onChange={(event) => validateText(event)}
            InputProps={{
                endAdornment: (
                    <>
                        <BulletIcon />
                        {props?.tags.map((data, index) => {
                            return (
                                <Tag data={data}
                                    handleDelete={handleDelete}
                                    key={index} />
                            );
                        })}
                    </>
                )
            }}
            autoFocus={props?.autoFocus}
            label={props?.label}
            disabled={props?.disabled}
            error={props?.required && props?.error}
            helperText={props?.required && props?.helperText}
            autoComplete={"off"}
            onKeyDown={handleKeyDown}
        />
        {props?.body}
    </div>);
};

export default TagInput;