import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const TextFieldController = ({
  type,
  name,
  label,
  defaultValue,
  required,
  rules,
  InputProps,
  disabled,
  onChange,
  hiddenLabel,
  className,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type={type}
          label={hiddenLabel ? undefined : label}
          value={field.value}
          disabled={disabled}
          onChange={onChange || field.onChange}
          error={!!error}
          helperText={error ? error.message : null}
          InputProps={InputProps}
          hiddenLabel={hiddenLabel}
          className={className}
          {...props}
        />
      )}
      rules={{ required: required && `${label} cannot be blank.`, ...rules }}
    />
  );
};

export default TextFieldController;
