
export function getRoleCodesFromNames(roleNames, roleList){
    let codes = [];

    roleNames.forEach(roleName => {
        if(roleList.filter(r => {return r.name == roleName}).length > 0){
            codes.push(roleList.filter(r => {return r.name == roleName})[0].code);
        }
    })

    return codes;
}

export function getRoleNamesFromCodes(roleCodes, roleList){
    let names = [];

    roleCodes.forEach(roleCode => {
        if(roleList.filter(r => {return r.code == roleCode}).length>0){
            names.push(roleList.filter(r => {return r.code == roleCode})[0].name);
        }
    })

    return names;
}