import React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import useStyles from "../../styles";
import DateFilter from "../../../../components/DateFilter";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";

// -------------------------------------------------------

export default function MobileActivityLogFilters({
  fromFilter,
  setFromFilter,
  toFilter,
  setToFilter,
  systemModules,
  moduleFilter,
  setModuleFilter,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            label={"From"}
            value={fromFilter}
            maxDate={toFilter}
            onChange={(newValue) => setFromFilter(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            label={"To"}
            value={toFilter}
            minDate={fromFilter}
            onChange={(newValue) => setToFilter(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"module-filter"}
            options={systemModules}
            value={moduleFilter}
            onChange={(e, newValue) => setModuleFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Module"} variant={"outlined"} />
            )}
            popupIcon={<ArrowDownIcon />}
          />
        </Grid>
      </Grid>
    </>
  );
}
