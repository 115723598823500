import { withRouter } from "react-router-dom";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
    Search as SearchIcon, KeyboardArrowDown as ArrowDownIcon,
    PlaylistPlay as FilterIcon, ArrowBack as BackIcon,
    Clear as ClearIcon, Event as CalendarIcon
} from "@mui/icons-material";
import {
    Autocomplete,
    Backdrop, CircularProgress, Grid, IconButton, InputAdornment,
    TextField, Typography
} from "@mui/material";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { setRighbarContent, toggleRightbar, useLayoutDispatch, useLayoutState } from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect } from "react";
import { ENABLE_OPTIONS, SERVICE_CLAIMS_REPORT_STATUS } from "../../data/constants";
import useStyles from "./styles";
import MobileServiceClaimReportFilters from "./components/Mobile/MobileServiceClaimReportFilters";
import { claimReportsTableColumnConfig } from "./data-table/service-claim-report-column-config";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import Swal from "sweetalert2";

const ServiceClaimReportList = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    const [message, setMessage] = useState("");
    const [reportList, setReportList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [sortBy, setSortBy] = useState({ column: undefined, direction: undefined });
    const [refresh, setRefresh] = useState(false);

    // pagination states
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collectionSize, setCollectionSize] = useState(10);

    // filter states
    const [textFilter, setTextFilter] = useState("");
    const [teamFilter, setTeamFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);

    const statusOptions = Object.values(SERVICE_CLAIMS_REPORT_STATUS)
        .map(o => o.toUpperCase());

    // set right bar for small devices
    const smallDeviceWidth = 1200;
    const mobileFilterProps = {
        teamList, statusOptions,
        teamFilter, statusFilter,
        setTeamFilter, setStatusFilter
    };

    // set columns in data table
    const columns = claimReportsTableColumnConfig(
        {
            allowEdit: auth.checkModulePrivilege(
                PrivilegeModules.service_claim_report, PrivilegeActions.edit),
            handleBatchNoClick,
            hasPermissionToVoid: auth.checkModulePrivilege(
                PrivilegeModules.service_claim_report, PrivilegeActions.void_claim_report),
            onVoidClaimReport
        });

    useEffect(() => {
        document.title = "Service Claim Report List";
        loadSubCons();
    }, []);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    // filter
    useEffect(() => {
        if (refresh) {
            filter();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (!textFilter) {
            setRefresh(true);
        }
    }, [textFilter]);

    useEffect(() => {
        setRefresh(true);
    }, [teamFilter, statusFilter]);

    useEffect(() => {
        if (width <= smallDeviceWidth) { // set filter right bar
            setRighbarContent(layoutDispatch,
                [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
                <MobileServiceClaimReportFilters key={"rb-body"} {...mobileFilterProps} />],
                { size: 'xs', backButton: true }); // filter content
        }
    }, [teamList, reportList, teamFilter, statusFilter, width]);

    // populate sub-con list 
    function loadSubCons() {
        let abortController = new AbortController();
        sendRequest(`/v1/user/get-list-by-role/option_sub_con`, "GET", {})
            .then(response => {
                const list = response.data?.users || [];
                setTeamList(list.filter(i => i.status));
                return () => { abortController.abort(); }
            });
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value) { // perform search when Enter
            e.preventDefault();
            setRefresh(true);
        }
    };

    function handlePageChanged(page) {
        setPage(page);
        setRefresh(true);
    }

    function handleRowsPerPageChanged(count) {
        setPageSize(count);
        setRefresh(true);
    }

    function sortByColumn(columnConfig, sortDirection) {
        if (columnConfig.sortField && sortDirection) {
            setSortBy({
                column: columnConfig.sortField,
                direction: sortDirection
            });
            setRefresh(true);
        }
    }

    // open mobile filters drawer
    function openMobileFilters() {
        toggleRightbar(layoutDispatch);
    }

    function getFilterValues() {
        return {
            text: textFilter.trim(),
            team: teamFilter ? teamFilter.username : undefined,
            status: statusFilter ?? undefined,
        };
    }

    function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
        return sendRequest(
            `/v1/service-claim-report/filter?filters=${JSON.stringify(filters)}&page=${page}&pageSize=${pageSize}
        ${(sortBy?.column && sortBy?.direction) ?
                ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {});
    }

    function filter() {
        let abortController = new AbortController();
        // set list filters
        getPaginatedData(page, pageSize, getFilterValues(), sortBy)
            .then(response => {
                const reports = response.data?.reports || [];
                setReportList(reports);
                setCollectionSize(response.data?.total);
                return () => {
                    abortController.abort();
                    return reports;
                };
            });
    }

    function handleBatchNoClick(row) {
        props.history.push(`card/${row.batchNo}`);
    }

    async function onVoidClaimReport(row) {
        if (!row.paymentDate) {
            const result = await Swal.fire({
                title: `Confirmation`,
                text: `Are you sure you want to void the service claim report ${row.batchNo}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            });
            if (result?.isConfirmed) {
                const response = await voidClaimReport(row.batchNo);
                if (response?.status === 200 && response?.data?.report) {
                    setMessage(response?.data?.message);
                    setRefresh(true);
                }
            }
        }
    }

    function voidClaimReport(batchNo) {
        return sendRequest(`/v1/service-claim-report/void-report/${batchNo}`, "PUT", {});
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>Service Claim Report List</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container className={"form"}>
                    <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={10} lg={2}>
                                <TextField
                                    autoComplete={"off"}
                                    id={"text-searchbox"}
                                    variant={"outlined"}
                                    value={textFilter}
                                    onChange={(e) => setTextFilter(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position={"start"}>
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            {
                                width >= smallDeviceWidth ? (<>
                                    {
                                        <Grid item xs={6} sm={6} md={2} lg={2}>
                                            <Autocomplete
                                                id={"team-filter"}
                                                options={teamList}
                                                getOptionLabel={(option) => `${option.username}-${option.name}`}
                                                value={teamFilter}
                                                onChange={(e, newValue) => setTeamFilter(newValue)}
                                                renderInput={(params) => <TextField {...params} label={"Team"} variant={"outlined"} />}
                                                popupIcon={<ArrowDownIcon />}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={6} sm={6} md={2} lg={2}>
                                        <Autocomplete
                                            id={"status-filter"}
                                            options={statusOptions}
                                            getOptionLabel={(option) => option}
                                            value={statusFilter}
                                            onChange={(e, newValue) => setStatusFilter(newValue)}
                                            renderInput={(params) => <TextField {...params}
                                                label={"Status"} variant={"outlined"} />}
                                            popupIcon={<ArrowDownIcon />}
                                        />
                                    </Grid>
                                </>) :
                                    (<Grid item xs={2}>
                                        <IconButton
                                            onClick={() => openMobileFilters()}
                                            className={classes.drawerToggleBtn}    >
                                            {layoutState.isRightbarOpened ? (<BackIcon />)
                                                : (<FilterIcon className={classes.toggleRightBarIcon} />)}
                                        </IconButton>
                                    </Grid>)
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DataTable
                                    fixedHeader={true}
                                    persistTableHead={true}
                                    columns={columns}
                                    data={reportList}
                                    sortServer
                                    onSort={sortByColumn}
                                    pagination
                                    paginationServer
                                    paginationDefaultPage={page}
                                    paginationPerPage={pageSize}
                                    paginationTotalRows={collectionSize}
                                    onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
                                    onChangePage={(page) => handlePageChanged(page)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default withRouter(ServiceClaimReportList);