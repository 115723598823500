import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@mui/icons-material";
import { UserRoles } from "../../../data/privileges.enum";

export const roleTableColumnConfig = (permission, handleEditRole, handleDeleteRole) => [
    {
        name: "Code",
        width: "250px",
        selector: row => row.code,
        cell: (row, index, column, id) => {
            if(permission.allowedViewDetail){
                return (<Link to={"/app/role/card/" + row._id}>{row.code.toUpperCase()}</Link>)
            }else{
                return (<>{row.code.toUpperCase()}</>)
            }
        },
        sortable: true,
    },
    {
        name: "Name",
        width: "250px",
        selector: row => row.name,
        sortable: true,
    },
    {
        name: "Remark",
        minWidth: "250px",
        selector: row => row.remark,
        sortable: true,
    },
    {
        name: "",
        right: true,
        width: '50px',
        cell: (row) => {
            return (<>
                {!(row.code === UserRoles.admin) && permission.allowedDelete && (<>
                    <IconButton onClick={(e) => handleDeleteRole(row)}><DeleteOutline /></IconButton></>
                )}
            </>)
        }
    }
];
