import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  ArrowBack as BackIcon,
  KeyboardArrowDown,
  PlaylistPlay as FilterIcon,
  Search as SearchIcon,
  Clear
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getOrderManagementTableConfig } from "./data-table/order-management";
import Swal from "sweetalert2";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import useStyles from "../fnb-event-booking/styles";
import QrScanner from "../../components/QrScanner/QrScanner";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import DateFilter from "./component/filters/DateFilter";
import { yesNoOptions } from "./constants/yes-no-options";
import { uniq } from "lodash/array";
import DownloadOrders from "./actions/DownloadOrders";

const UNLIMITED_PAGE_SIZE = 0;

const FnbOrderManagement = (props) => {
  const classes = useStyles();

  const { auth } = useRoleAuthorization();
  const { isLoading, sendRequest: sendOrderRequest } = useHttpRequest();
  const {
    isLoadingStore,
    sendRequest: sendStoreRequest,
    responseData: storeSearchResult,
  } = useHttpRequest();
  useMemo(
    () =>
      sendStoreRequest(
        `/v1/fnb-order/stores`,
        "GET",
        {},
      ),
    [sendStoreRequest],
  );

  // layout state
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();
  const [loadMobileFilters, setLoadMobileFilters] = useState(false);

  const [openScanner, setOpenScanner] = useState(false);

  // filters
  const [textFilter, setTextFilter] = useState("");
  const [textValue, setTextValue] = useState("");
  const [dateFilterFrom, setDateFilterFrom] = useState(new Date());
  const [dateFilterTo, setDateFilterTo] = useState(null);
  const [storeFilter, setStoreFilter] = useState(null);
  const [paymentFilter, setPaymentFilter] = useState(["CASH"]);

  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [tableData, setTableData] = useState([]);
  const [paidFilter, setPaidFilter] = useState({ value: "0", label: "NO" });

  const smallDeviceWidth = 1200;

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  const dataTableConfig = useMemo(getOrderManagementTableConfig, []);

  const storeOptions = useMemo(
    () => storeSearchResult?.stores || [],
    [storeSearchResult],
  );
  const paymentOptions = useMemo(() => {
    return uniq(
      (storeSearchResult?.stores || []).flatMap((store) =>
        (store?.config?.availablePaymentMethods || []).map(
          (method) => method.methodCode,
        ),
      ),
    );
  }, [storeSearchResult]);

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection.toUpperCase(),
      });
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // perform search when Enter
      e.preventDefault();
      setTextFilter(String(e.target.value));
    }
  };

  // open mobile filters drawer
  const openMobileFilters = () => {
    setLoadMobileFilters(true);
    toggleRightbar(layoutDispatch);
  };

  const handleAsyncData = useCallback(
    async (page, pageSize) => {
      const filters = {
        text: String(textFilter).trim(),
        fromDate: dateFilterFrom ? getFromDate(dateFilterFrom) : undefined,
        toDate: dateFilterTo ? getToDate(dateFilterTo) : undefined,
        isPaid: paidFilter ? paidFilter.value : undefined,
        store: storeFilter ? storeFilter.storeCode : undefined,
        paymentMethod: paymentFilter ? paymentFilter : undefined,
      };
      const urlSearchParam = new URLSearchParams();
      urlSearchParam.set("filters", JSON.stringify(filters));
      urlSearchParam.set("page", String(page));
      urlSearchParam.set("pageSize", String(pageSize));

      sortBy?.column && urlSearchParam.set("orderBy", sortBy?.column);
      sortBy?.direction && urlSearchParam.set("direction", sortBy?.direction);

      const requestUrl = "/v1/fnb-order/filter?" + urlSearchParam.toString();
      return sendOrderRequest(requestUrl, "GET", {});
    },
    [
      dateFilterFrom,
      dateFilterTo,
      sendOrderRequest,
      sortBy,
      textFilter,
      paidFilter,
      storeFilter,
      paymentFilter,
    ],
  );

  useEffect(() => {
    handleAsyncData(page, pageSize)
      .then((response) => {
        setTableData(response?.data?.orders || []);
        setCollectionSize(response.data?.total);
      })
      .catch((error) => {
        Swal.fire("Error", error.message);
      });
  }, [handleAsyncData, page, pageSize]);

  useEffect(() => {
    document.title = "F&B Sales Order List";
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_order,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, [auth, props.history]);

  const getExtraFilter = useCallback(() => {
    return (
      <>
        <Grid item xs={12} sm={12} md={10} lg={2}>
          <Autocomplete
            id={"store-filter"}
            options={storeOptions}
            value={storeFilter}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(e, newValue) => setStoreFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Store"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={2}>
          <DateFilter
            onChange={setDateFilterFrom}
            maxDate={dateFilterTo}
            label={"From"}
            value={dateFilterFrom}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={2}>
          {/*{TIME TO FILTER}*/}
          <DateFilter
            onChange={setDateFilterTo}
            minDate={dateFilterFrom}
            value={dateFilterTo}
            label={"To"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={2}>
          <Autocomplete
            id={"paid-filter"}
            options={yesNoOptions}
            getOptionLabel={(option) => `${option.label}`}
            value={paidFilter}
            onChange={(e, newValue) => setPaidFilter(newValue)}
            isOptionEqualToValue={(option, value) =>
              value.value === option.value
            }
            renderInput={(params) => (
              <TextField {...params} label={"Paid"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={4}>
          <Autocomplete
            id={"payment-filter"}
            options={paymentOptions}
            value={paymentFilter}
            onChange={(e, newValue) => setPaymentFilter(newValue)}
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Payment Method"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
            ChipProps={{ deleteIcon: <Clear /> }}
          />
        </Grid>
      </>
    );
  }, [
    dateFilterFrom,
    dateFilterTo,
    paidFilter,
    storeFilter,
    storeOptions,
    paymentOptions,
    paymentFilter,
  ]);

  useEffect(() => {
    if (width <= smallDeviceWidth) {
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>,
          <Grid container key={"rb-body"} spacing={3}>
            {getExtraFilter()}
          </Grid>,
        ],
        { size: "xs", backButton: true },
      );
    }
  }, [getExtraFilter, layoutDispatch, width]);

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>F&B Sales Order List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          <DownloadOrders
            onError={(err) =>
              Swal.fire({ icon: "error", text: "Error during download." })
            }
            sendRequestStub={() => handleAsyncData(0, UNLIMITED_PAGE_SIZE)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={10} lg={2}>
                <TextField
                  value={textValue}
                  onChange={(e) => setTextValue(e.currentTarget.value)}
                  autoComplete={"off"}
                  id={"text-searchbox"}
                  variant={"outlined"}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <IconButton onClick={() => setOpenScanner(true)}>
                          <QrCodeScannerIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {width >= smallDeviceWidth ? (
                getExtraFilter()
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={openMobileFilters}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <BackIcon />
                    ) : (
                      <FilterIcon className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={dataTableConfig}
                  data={tableData}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) => setPageSize(count)}
                  onChangePage={(page) => setPage(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <QrScanner
        isOpen={openScanner}
        onClose={() => setOpenScanner(false)}
        onSuccess={(data) => {
          setTextFilter(data);
          setTextValue(data);
        }}
      />
    </>
  );
};

export default withRouter(FnbOrderManagement);
