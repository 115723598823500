import { Grid } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { TIME_SLOT_STATUS } from "../../../../data/constants";
// import useStyles from "../../styles";

const TaskTimelineDrawer = ({ timeline }) => {
    // const classes = useStyles();

    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }} className="display">
            <Grid item xs={12}>
                <Timeline>
                    {
                        timeline && timeline
                            .filter(item => (item.lines && !!item.lines.at(0)
                                && item.slotInfo?.status === TIME_SLOT_STATUS.Allocated) ||
                                item.slotInfo?.status === TIME_SLOT_STATUS.Closed ||
                                item.slotInfo?.status === TIME_SLOT_STATUS.Available)
                            .map((item, i) =>
                                <TimelineItem
                                    key={`${item.staff}-${item.timeSlot}${i}-item`}
                                    className={item.highlighted ? 'highlight' : null}>
                                    <TimelineOppositeContent>
                                        {item.timeSlot}
                                    </TimelineOppositeContent>
                                    {(item.lines) && (<>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: item.slotInfo?.fill }} />
                                            <TimelineConnector sx={{ bgcolor: item.slotInfo?.fill }} />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            {
                                                item.lines
                                                    .map(value => <>{value}<br /></>)
                                            }
                                        </TimelineContent></>)
                                    }
                                </TimelineItem>)
                    }
                </Timeline>
            </Grid>
        </Grid>
    </>);
};

export default TaskTimelineDrawer;