import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Backdrop, Typography, CircularProgress, Grid, Button, } from "@mui/material";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import TagInput from "../../components/TagInput/TagInput";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { BRACKET_STATUS } from "../../data/constants";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";

const BracketQualityCheck = (props) => {

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();
    
    // handling form when adding / editing
    const { handleSubmit, formState: { errors: dirtyFields }, setValue }
        = useForm({ mode: "all" });

    const [serialNos, setSerialNos] = useState([]);
    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        document.title = "Quality Check - Bracket Card";

        // redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.bracket_quality_check, PrivilegeActions.view_list)) {
            props.history.push('/app/dashboard');
        }
    }, []);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    // navigate back to bracket list
    const navigateToList = () => {
        window.location.href = '#/app/bracket/list';
    }

    // tag input validations
    const validateSerialNosTagInput = async (value) => {
        if (serialNos.length === 0) {
            setValue('serialNos', [], { shouldDirty: true });
        }
    };

    // check bracket before add to list
    async function checkBeforeAdd(list, current) {
        const value = current.trim().toUpperCase();
        const bracket = await getBracketBySerialNo(current.toUpperCase());
        if (bracket) {
            // only not issued brackets
            if (!bracket.pkrNo) {
                // bracket cannot be already accepted or rejected if user is a welder qc
                // admin & welder manager can override the status
                if (!auth.checkModulePrivilege(PrivilegeModules.bracket_quality_check, PrivilegeActions.overwrite_status) &&
                    (bracket.status === BRACKET_STATUS.APPROVED ||
                        bracket.status === BRACKET_STATUS.REJECTED)) {
                    setSerialNos(list); // revert to previous
                    setWarningMessage(`Bracket ${value} has already been 
                    ${bracket.status === BRACKET_STATUS.APPROVED ? 'approved.' : 'rejected.'}`);
                }
            } else {
                setSerialNos(list); // revert to previous
                setWarningMessage(`Bracket ${value} has already been issued.`);
            }
        } else {
            setSerialNos(list);// revert to previous
            setWarningMessage(`Bracket ${value} is not found in the system.`);
        }
    }

    // get bracket by serial no
    async function getBracketBySerialNo(serialNo) {
        const response = await sendRequest(
            `/v1/bracket/get-by-serial-no/${serialNo}`, 'GET');
        return response.data?.bracket;
    }

    // when submit the bracket form
    const handleSubmitForm = async (data, e, action) => {
        e.preventDefault();
        if (dirtyFields.serialNos && serialNos.length === 0) { return; }
        let result = await sendRequest(
            `/v1/bracket/quality-check/${action === 'approve' ?
                BRACKET_STATUS.APPROVED : BRACKET_STATUS.REJECTED}`,
            "PUT", serialNos);
        if (result?.status === 200 && result?.data?.updated) {
            setMessage(`Bracket(s) have been ${action === 'approve' ? 'approved.' : 'rejected.'}`);
            setSerialNos([]); // reset list
        }
    };

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        <form>
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant={"h1"}>Bracket Card - Quality Check</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                    <Button onClick={() => props.history.push('/app/bracket/list')}>Cancel</Button>

                    {
                        auth.checkModulePrivilege(PrivilegeModules.bracket_quality_check, PrivilegeActions.approve) 
                        && 
                        <Button className={"primary"} type={"button"}
                        onClick={handleSubmit((data, e) => handleSubmitForm(data, e, 'approve'))}
                        disabled={serialNos.length === 0}>
                            Approve
                        </Button>
                    }
                    
                    {
                        auth.checkModulePrivilege(PrivilegeModules.bracket_quality_check, PrivilegeActions.reject) 
                        && 
                        <Button className={"primary"} type={"button"}
                        onClick={handleSubmit((data, e) => handleSubmitForm(data, e, 'reject'))}
                        disabled={serialNos.length === 0}>
                            Reject
                        </Button>
                    }
                    
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={"form"}>
                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                    <Typography variant={"h2"}>General</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={{ xs: 2, md: 3 }}>
                                        <Grid item xs={12} sm={6}>
                                            <TagInput
                                                name={"serialNos"}
                                                required={true}
                                                autoFocus={true}
                                                tags={serialNos}
                                                setTags={setSerialNos}
                                                label={`Serial No.`}
                                                error={(dirtyFields.serialNos && serialNos.length === 0)}
                                                helperText={(dirtyFields.serialNos && serialNos.length === 0)
                                                    && "Serial No. cannot be blank."}
                                                onChange={(data) => {
                                                    setValue('serialNos', data);
                                                    validateSerialNosTagInput(data);
                                                }}
                                                onKeyDown={(list, current) => checkBeforeAdd(list, current)}
                                                body={serialNos.length > 0 &&
                                                    (<div className={"total"}>
                                                        {`Total: ${serialNos.length}`}</div>)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </>);
};

export default withRouter(BracketQualityCheck);