import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { DeleteOutline as DeleteIcon } from "@mui/icons-material";

export const machineCompanyTableColumnConfig = (
  { allowedViewDetail, allowedDelete, allowedEnableDisable },
  handleNameClick,
  handleDeleteMachineCompany,
  handleEditMachineCompany,
) => {
  let columns = [
    {
      name: "Name",
      minWidth: "250px",
      selector: (row) => row.name,
      cell: (row) => {
        if (allowedViewDetail) {
          return (
            <Link
              to={`/app/machine-company/card/${row._id}`}
              onClick={(e) => handleNameClick(row._id)}
            >
              {row.name}
            </Link>
          );
        } else {
          return <>{row.name}</>;
        }
      },
      sortable: true,
      wrap: true,
      sortField: "name",
    },
  ];

  columns = [
    ...columns,
    {
      name: "Enable",
      width: "80px",
      center: true,
      selector: (row) => row.enable,
      cell: (row) => {
        return (
          <>
            {!row.approvedBy && (
              <Switch
                disabled={!allowedEnableDisable}
                checked={row.enable}
                onChange={(e, value) =>
                  handleEditMachineCompany(row, true, value)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            )}
          </>
        );
      },
      sortable: true,
      sortField: "enable",
    },
  ];

  return columns;
};
