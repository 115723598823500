import { withRouter } from "react-router-dom";
import { useEffect, useMemo, useState, useRef } from "react";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, InputAdornment, Autocomplete, IconButton, FormControlLabel, Switch
} from "@mui/material";
import {
    Search as SearchIcon, KeyboardArrowDown as ArrowDownIcon,
    PlaylistPlay as FilterIcon, ArrowBack as BackIcon, Clear as ClearIcon,
    Event as CalendarIcon
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
    setRighbarContent, toggleRightbar, useLayoutDispatch,
    useLayoutState
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import moment from "moment";
import useStyles from "./styles";
import { bracketRequestTableColumnConfig } from "./data-table/bracket-request-table-column-config";
import MobileBracketRequestFilters from "./components/Mobile/MobileBracketRequestFilters";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DateFilter from '../../components/DateFilter'

const BracketRequestList = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    // layout state
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    // filters
    const [textFilter, setTextFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState(null);
    const [fromFilter, setFromFilter] = useState(null);
    const [toFilter, setToFilter] = useState(null);
    const [sortBy, setSortBy] = useState({ column: undefined, direction: undefined });

    const [refresh, setRefresh] = useState(false); // refresh list

    const [requestList, setRequestList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    // pagination states
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collectionSize, setCollectionSize] = useState(10);

    const smallDeviceWidth = 1200;

    useEffect(() => {
        document.title = "Bracket Request List";

        //redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && (!auth.checkModulePrivilege(PrivilegeModules.bracket_request,PrivilegeActions.view_list))){
            props.history.push('/app/dashboard');
        }   
    }, []);

    // populate status list from existing bracket requests
    const statusList = useMemo(() => {
        return [...new Set(requestList.map(c => c.status).flat(1))].sort();
    }, [requestList]);

    // set filtered data
    useEffect(() => { if (refresh) { filter(); setRefresh(false); } }, [refresh]);

    useEffect(() => { setRefresh(true); }, [statusFilter, fromFilter, toFilter]);

    useEffect(() => {
        if (!textFilter) {
            setRefresh(true);
        }
    }, [textFilter]);

    // mobile filter view props
    const mobileFilterProps = {
        statusList, statusFilter, setStatusFilter,
        fromFilter, setFromFilter,
        toFilter, setToFilter
    };

    useEffect(() => {
        if (width <= smallDeviceWidth) { // set filter right bar
            setRighbarContent(layoutDispatch,
                [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
                <MobileBracketRequestFilters key={"rb-body"} {...mobileFilterProps} />],
                { size: 'xs', backButton: true }); // filter content
        }
    }, [requestList, statusFilter, fromFilter, toFilter, width]);

    // list filter
    function filter() {
        // set list filters
        const filters = {
            text: textFilter.trim(),
            status: statusFilter ? statusFilter : undefined,
            from: fromFilter ? getFromDate(fromFilter) : undefined,
            to: toFilter ? getToDate(toFilter) : undefined
        };
        // toggle more data
        sendRequest(
            `/v1/bracket-request/filter?filters=${JSON.stringify(filters)}
            &page=${page}&pageSize=${pageSize}
            ${(sortBy?.column && sortBy?.direction) ?
            ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {})
            .then(response => {
                const requests = response.data?.requests || [];
                setRequestList(requests);
                setFilteredData(requests);
                setCollectionSize(response.data?.total);
                return () => {
                    return requests;
                };
            });
    }

    // set columns in data table
    const columns = bracketRequestTableColumnConfig(
        auth.checkModulePrivilege(PrivilegeModules.bracket_request,PrivilegeActions.view_detail),
        handlePkrNoClick);

    // go to edit bracket request
    function handlePkrNoClick(pkrNo) {
        props.history.push(`card/${pkrNo}`);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value) { // perform search when Enter
            e.preventDefault();
            setRefresh(true);
        }
    };

    function handlePageChanged(page){
        setPage(page);
        setRefresh(true);
    }

    function handleRowsPerPageChanged(count){
        setPageSize(count);
        setRefresh(true);
    }    

    function sortByColumn(columnConfig, sortDirection) {
        if (columnConfig.sortField && sortDirection) {
            setSortBy({
                column: columnConfig.sortField,
                direction: sortDirection
            });
            setRefresh(true);
        }
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>Bracket Request List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                {
                    (auth.checkModulePrivilege(PrivilegeModules.bracket_request,PrivilegeActions.add)) && 
                    <Button className={"primary"} onClick={() => props.history.push('card')}>New</Button>
                }
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                            <TextField
                                autoComplete={"off"}
                                id={"text-searchbox"}
                                variant={"outlined"}
                                value={textFilter}
                                onChange={(e) => setTextFilter(e.target.value)}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={"start"}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }} />
                        </Grid>
                        {
                            width >= smallDeviceWidth ? (<>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                  <DateFilter
                                    label={"From"}
                                    value={fromFilter}
                                    maxDate={toFilter}
                                    onChange={(newValue) => setFromFilter(newValue)}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                  <DateFilter
                                    label={"To"}
                                    value={toFilter}
                                    minDate={fromFilter}
                                    onChange={(newValue) => setToFilter(newValue)}
                                  />
                                </Grid>
                            </>) :
                                (<Grid item xs={2}>
                                    <IconButton
                                        onClick={() => toggleRightbar(layoutDispatch)}
                                        className={classes.drawerToggleBtn}>
                                        {layoutState.isRightbarOpened ? (<BackIcon />)
                                            : (<FilterIcon className={classes.toggleRightBarIcon} />)}
                                    </IconButton>
                                </Grid>)
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}                               
                                data={filteredData}
                                sortServer
                                onSort={sortByColumn}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationPerPage={pageSize}
                                paginationTotalRows={collectionSize}
                                onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
                                onChangePage={(page) => handlePageChanged(page)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default withRouter(BracketRequestList);