import { Switch } from "@mui/material";

export const bracketBatchTableColumnConfig = (permission, handleEditBatch) => [
    {
        name: "Batch No.",
        width: "200px",
        selector: row => row.batchNo,
        sortable: true,
        sortField: 'batchNo'
    },
    {
        name: "Remarks",
        minWidth: "250px",
        selector: row => row.remark,
        sortable: true,
        sortField: 'remark'
    },
    {
        name: "Enable",
        width: '80px',
        center: true,
        selector: row => row.enable,
        cell: (row) => {
            return (<Switch
                checked={row.enable === 'Yes'}
                disabled={!permission.allowedEnableDisable}
                onChange={(e, value) => handleEditBatch(row, value)}
                inputProps={{ 'aria-label': 'controlled' }}
            />)
        },
        sortable: true,
        sortField: 'enable'
    },
];
