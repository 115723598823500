import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@mui/icons-material";

export const userTableColumnConfig = (
  permission,
  handleEditUser,
  handleDeleteUser,
) => [
  {
    name: "Username",
    width: "100px",
    selector: (row) => row.username,
    cell: (row, index, column, id) => {
      if (permission.allowedViewDetail) {
        return <Link to={"/app/user/card/" + row._id}>{row.username}</Link>;
      } else {
        return <>{row.username}</>;
      }
    },
    sortable: true,
  },
  {
    name: "Name",
    width: "250px",
    wrap: true,
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Department Code",
    width: "150px",
    selector: (row) => row.department,
    sortable: true,
  },
  {
    name: "Customer No.",
    width: "110px",
    selector: (row) => row?.customerNo || "",
    sortable: true,
  },
  {
    name: "Project Group",
    width: "120px",
    selector: (row) => row?.projectGroup || "",
    sortable: true,
  },
  {
    name: "Stores",
    width: "250px",
    wrap: true,
    selector: (row) => row.associatedStoreCodes?.sort().toString(),
  },
  {
    name: "Companies",
    width: "250px",
    wrap: true,
    selector: (row) => row.companies?.sort().toString(),
  },
  {
    name: "Roles",
    minWidth: "250px",
    wrap: true,
    selector: (row) => row.roles?.sort().toString(),
  },
  {
    name: "Enable",
    width: "80px",
    center: true,
    selector: (row) => row.status,
    cell: (row) => {
      return (
        <Switch
          checked={row.status}
          disabled={!permission.allowedEnableDisable}
          onChange={(e, value) => handleEditUser(row, true, value)}
          inputProps={{ "aria-label": "controlled" }}
        />
      );
    },
    sortable: true,
  },
  {
    name: "",
    right: true,
    width: "50px",
    cell: (row) => {
      return (
        <>
          {permission.allowedDelete && (
            <>
              <IconButton onClick={(e) => handleDeleteUser(row)}>
                <DeleteOutline />
              </IconButton>
            </>
          )}
        </>
      );
    },
  },
];
