import { useCallback, useEffect, useState } from "react";
import { useHttpRequest } from "../../../hooks";

export function useFnbPresetList(storeCode) {
  const [presetList, setPresetList] = useState([]);
  const { isLoading, sendRequest, httpRequestError } = useHttpRequest();

  const fetchPresetList = useCallback(() => {
    if (!storeCode) {
      return setPresetList([]);
    }
    const url = storeCode
      ? `/v1/fnb-preset/list/${storeCode}`
      : `/v1/fnb-preset/list`;
    return sendRequest(url, "GET", {})
      .then((responseData) =>
        setPresetList(responseData?.data?.searchResult || []),
      )
      .catch((e) => {
        setPresetList([]);
        throw e;
      });
  }, [sendRequest, storeCode]);

  useEffect(() => {
    fetchPresetList();
  }, [fetchPresetList]);

  return {
    isLoading,
    presetList,
    refetch: fetchPresetList,
    error: httpRequestError,
  };
}
