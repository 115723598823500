import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  IconButton,
  Box,
  InputAdornment,
} from "@mui/material";
import {
  Add,
  Clear,
  KeyboardArrowDown as ArrowDownIcon,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import DataTable from "react-data-table-component";
import { resourceTableColumnConfig } from "./data-table/resource-table-column-config";
import useStyles from "./styles";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useUserState } from "../../context/UserContext";
import {
  setStore,
  useStoreDispatch,
  useStoreState,
} from "../../context/StoreContext";

const FnbEventMenuCard = (props) => {
  const classes = useStyles();
  const { userInfo } = useUserState();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const { eventMenuId } = useParams(); // url param => eventMenuId when editing

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
  } = useForm({ mode: "all" });

  // un-assigned sub-con list
  const [chefList, setChefList] = useState([]);
  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [currentMenu, setCurrentMenu] = useState(null); // when edit
  const [menuItemList, setMenuItemList] = useState([]); // menu item list
  const [rowsInvalid, setRowsInvalid] = useState(false);
  const [lstDeletedImages, setLstDeletedImages] = useState([]);
  let selectedMenuItem = 0;

  const [fnbOutlets, setFnbOutlets] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [duplicated, setDuplicated] = useState(false);
  const [fnbEventMenuBaseUrl, setFnbEventMenuBaseUrl] = useState(null);
  const [enabledSwitchValue, setEnabledSwitchValue] = useState(true); // enable switch => default true
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    document.title = "New - F&B Event Menu Card";
    loadPrerequisites();
    loadChefUsers();

    // redirect to default page if not authorized
    if (
      eventMenuId &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_event_menu,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  useEffect(() => {
    const invalid =
      menuItemList.findIndex(
        (r) => !r.dishName || !r.imageUrl || !r.chef.chefId,
      ) > -1 || !selectedStore;
    setRowsInvalid(invalid);
  }, [menuItemList, selectedStore]);

  useEffect(() => {
    if (fnbOutlets && currentMenu) {
      setSelectedStore(
        fnbOutlets
          .filter((outlet) => outlet.code === currentMenu.storeCode)
          .pop(),
      );
    } else if (fnbOutlets && !currentMenu) {
      setSelectedStore(
        fnbOutlets.filter((outlet) => outlet.isDefault === true).pop(),
      );
    }
  }, [currentMenu, fnbOutlets]);

  useEffect(() => {
    if (!selectedStore) {
      return;
    }

    setValue("storeCode", selectedStore.code);
  }, [selectedStore]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  // get current matrix in edit view
  useEffect(() => {
    if (!isLoading && !responseData && eventMenuId) {
      sendRequest(`/v1/fnb-event-menu/get-by-menu-id/` + eventMenuId, "GET");
    }
  }, [eventMenuId]);

  // set form values when edit view
  useEffect(() => {
    if (eventMenuId && responseData?.eventMenu) {
      const eventMenu = responseData?.eventMenu;
      document.title = `${eventMenu.menuName} - F&B Event Menu Card`;
      const miniChefList = loadChefUsers();
      setCurrentMenu(eventMenu);
      setValue("eventMenuNo", eventMenu.eventMenuNo || "");
      setValue("menuName", eventMenu.menuName || "");
      setValue("storeCode", eventMenu.storeCode || "");
      setValue("pricePerPax", eventMenu.pricePerPax || "");
      setValue("remark", eventMenu.remark || "");
      setValue("createdBy", eventMenu.createdBy || "");
      setValue("updatedBy", eventMenu.updatedBy || "");
      setValue("enabled", eventMenu.enabled);
      setEnabledSwitchValue(eventMenu.enabled); // enable switch

      if (eventMenu.menuItems) {
        miniChefList.then((listOfChef) => {
          let listMap = [];
          listOfChef.forEach(
            (currentValue) => (listMap[currentValue.chefId] = currentValue),
          );

          for (const menuItem of eventMenu.menuItems) {
            menuItem.chef = listMap[menuItem.chef.chefId] || {};
          }
        });
        setMenuItemList(eventMenu.menuItems);
      }
    }
  }, [responseData]);

  const navigateToList = () => {
    if (!eventMenuId) window.location.href = "#/app/fnb-event-menu/list";
  };

  const loadPrerequisites = () => {
    sendRequest(`/v1/fnb-event-menu/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        setFnbOutlets(prerequisites.fnbOutlets);
        setFnbEventMenuBaseUrl(prerequisites.fnbEventMenuBaseUrl);
      },
    );
  };

  async function loadChefUsers() {
    return sendRequest("/v1/user/get-list-by-role/option_chef", "GET", {}).then(
      (response) => {
        let list = response.data.users.map((u) => ({
          chefId: u.username,
          chefName: u.name,
        }));

        setChefList(list || []);
        return list;
      },
    );
  }

  const handleSubmitForm = async (
    data,
    e,
    preview = false,
    duplicate = false,
  ) => {
    e.preventDefault();

    if (!data.pricePerPax) {
      setErrMessage(`Price Per Pax cannot be empty.`);
      return;
    }

    //duplicating/uploading/deleting Aircon images
    let result1 = null;

    if (duplicated && !currentMenu) {
      let lstDuplicateImageUrls = menuItemList
        .filter((e) => {
          return e.isNewImage == true && e.isDuplicated == true;
        })
        .map((item) => item.imageUrl);
      result1 = await sendRequest(
        `/v1/fnb-event-menu/duplicate-images-in-s3`,
        "POST",
        lstDuplicateImageUrls,
      );

      if (result1?.status === 201) {
        menuItemList
          .filter((mi) => {
            return mi.isNewImage == true && mi.isDuplicated == true;
          })
          .forEach((m, index) => {
            m.imageUrl = result1.data.urlArray[index].url;
          });
      } else {
        return;
      }
    }

    let lstImageUrls = [];
    let formData = new FormData();
    for (var i = 0; i < menuItemList.length; i++) {
      if (
        menuItemList[i].isNewImage == true &&
        menuItemList[i].isDuplicated == false
      ) {
        formData.append("file", menuItemList[i].imageUrl);
      } else {
        lstImageUrls.push(menuItemList[i].imageUrl);
      }
    }

    let deletedImageNames =
      lstDeletedImages.toString() == ""
        ? undefined
        : lstDeletedImages.toString();
    result1 = await sendRequest(
      `/v1/fnb-event-menu/upload-image-onto-s3/${deletedImageNames}`,
      "POST",
      formData,
    );

    if (result1?.status === 201) {
      menuItemList
        .filter((mi) => {
          return mi.isNewImage == true && mi.isDuplicated == false;
        })
        .forEach((m, index) => {
          m.imageUrl = result1.data.urlArray[index].url;
        });
    } else {
      return;
    }
    //

    const menu = {
      eventMenuNo: data.eventMenuNo,
      menuName: data.menuName.toUpperCase(),
      storeCode: data.storeCode,
      pricePerPax: data.pricePerPax,
      remark: data.remark.toUpperCase(),
      enabled: data.enabled,
      menuItems: menuItemList.map((mi) => ({
        groupName: mi.groupName,
        seqNo: Number(mi.seqNo),
        dishName: mi.dishName,
        dishNameInChinese: mi.dishNameInChinese,
        imageUrl: mi.imageUrl,
        chef: mi.chef,
      })),
      createdBy:
        eventMenuId && currentMenu && currentMenu._id
          ? data.createdBy
          : userInfo._id,
      updatedBy:
        eventMenuId && currentMenu && currentMenu._id
          ? userInfo._id
          : undefined,
    };

    let savedMenu = undefined,
      message = "";
    if (currentMenu && eventMenuId) {
      // when edit
      menu._id = currentMenu._id;
      const result = await sendRequest(
        `/v1/fnb-event-menu/${eventMenuId}`,
        "PUT",
        menu,
      );
      if (result?.status === 200 && result?.data?.eventMenu) {
        message = `F&B event menu  ${result.data.eventMenu.menuName} has been updated.`;
        savedMenu = result?.data?.eventMenu;
      }
    } else {
      const result = await sendRequest(`/v1/fnb-event-menu`, "POST", menu);
      if (result?.status === 201 && result?.data?.eventMenu) {
        message = `New F&B event menu ${result.data.eventMenu.menuName} has been created.`;
        savedMenu = result?.data?.eventMenu;
      }
    }

    if (preview && savedMenu) {
      previewMenu(savedMenu._id);
    } else if (duplicate && savedMenu) {
      duplicateMenu();
    } else {
      setMessage(message);
    }
  };

  // set resource table columns
  const columns = resourceTableColumnConfig(
    getFileExtension,
    onRemoveRow,
    checkForDuplicatedDish,
    onSelectImage,
    onChangeChef,
    onChangeDish,
    imagePreview,
    onRemoveImage,
    sortMenuItem,
    chefList,
    currentMenu &&
      eventMenuId &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_event_menu,
        PrivilegeActions.edit,
      ),
  );

  function onSelectImage(i) {
    selectedMenuItem = i;
    document.getElementById("input-file-upload").click();
  }

  function imagePreview(menuItem) {
    window.open(
      menuItem.isNewImage ? menuItem.imageUrl_Local : menuItem.imageUrl,
    );
  }

  function onRemoveImage(i) {
    const rList = [...menuItemList];

    if (!rList[i].isNewImage && !rList[i].isDuplicated) {
      var deletedFileName = rList[i].imageUrl.substring(
        rList[i].imageUrl.lastIndexOf("/") + 1,
      );
      setLstDeletedImages((current) => [...current, deletedFileName]);
    } else {
      rList[i].imageUrl_Local = "";
    }

    rList[i].imageUrl = "";
    setMenuItemList(rList);
  }

  // remove resource from list
  function onRemoveRow(i) {
    onRemoveImage(i);
    const rows = [...menuItemList];
    rows.splice(i, 1);
    setMenuItemList([...rows]);
  }

  function onChangeDish(fieldName, i, value) {
    const rList = [...menuItemList];
    rList[i][fieldName] = value.toUpperCase();
    setMenuItemList(rList);
  }

  // reset prev matrix data & copy resources to new matrix
  function duplicateMenu() {
    props.history.push("/app/fnb-event-menu/card");
    setDuplicated(true);
    setCurrentMenu(null);
    setValue("menuName", "");
    setValue("remark", "");

    const rList = [...menuItemList];
    rList.forEach((e) => {
      e.imageUrl_Local = e.imageUrl;
      e.isNewImage = true;
      e.isDuplicated = true;
    });
    setMenuItemList(rList);
  }

  function onChangeChef(i, newValue) {
    const rList = [...menuItemList];
    if (newValue == null || newValue == undefined) {
      rList[i].chef = { chefId: "", chefName: "" };
    } else {
      rList[i].chef = newValue;
    }

    setMenuItemList(rList);
  }

  function sortMenuItem(i, value){
    const rList = [...menuItemList];
    const dishName = rList[i].dishName;
    rList.sort((a, b) => a.seqNo - b.seqNo);

    //shift the seq numbers if it's trying to take the existing number
    if(rList.filter((mi) => {return mi.dishName != dishName && mi.seqNo == value}).length > 0 ){
        rList.filter((mi) => {return mi.dishName != dishName && mi.seqNo >= value}).forEach((d, index) => {
            d.seqNo = index + value + 1;
        });

        rList.sort((a, b) => a.seqNo - b.seqNo);
    }
    
    //rearrange the seq numbers to skip the jumped ones
    rList.forEach((d, index) => {
        d.seqNo = index + 1;
    });
    rList.sort((a, b) => a.seqNo - b.seqNo);

    setMenuItemList(rList);
  }

  function getNewSeqNo() {
    const max = menuItemList.reduce((maxValue, obj) => {
        return obj.seqNo > maxValue ? obj.seqNo : maxValue;
      }, 0) + 1;
      
      return max;
  }

  function createNewRow() {
    setMenuItemList([
      ...menuItemList,
      {
        groupName: "",
        seqNo: getNewSeqNo(),
        dishName: "",
        dishNameInChinese: "",
        imageUrl: "",
        chef: { chefId: "", chefName: "" },
        isNewImage: true,
        isDuplicated: false,
      },
    ]);
  }

  // check duplicated resource code entered
  const isDuplicating = (fieldName, dishName) =>
    menuItemList.filter(
      (r) => r[fieldName].toUpperCase() === dishName.trim().toUpperCase(),
    ).length > 1;

  function checkForDuplicatedDish(fieldName, i, dishName) {
    let foundDuplicate = false;

    const rList = [...menuItemList];

    if (
      menuItemList.length > 1 &&
      dishName.length > 0 &&
      isDuplicating(fieldName, dishName)
    ) {
      setWarningMessage(
        `Duplicate dish ${dishName.toUpperCase()} found in the list.`,
      );
      rList[i][fieldName] = "";
      foundDuplicate = true;
    } else {
      rList[i][fieldName] = dishName;
    }

    return foundDuplicate;
  }

  function setMenuImage(e) {
    for (var j = 0; j < e.target.files.length; j++) {
      const rList = [...menuItemList];
      rList[selectedMenuItem].imageUrl = e.target.files[j];
      rList[selectedMenuItem].imageUrl_Local = URL.createObjectURL(
        e.target.files[j],
      );
      rList[selectedMenuItem].isNewImage = true;
      rList[selectedMenuItem].isDuplicated = false;
      setMenuItemList(rList);
    }
  }

  const onChangeStore = (newValue) => {
    setValue("storeCode", newValue.code);
    setSelectedStore(newValue);
  };

  const previewMenu = (menuId) => {
    window.open(
      `${fnbEventMenuBaseUrl}/${menuId}`,
      "Menu Preview",
      "menubar=0,resizable=0,width=605,height=795",
    );
  };

  function getFileExtension(url) {
    let lastSegment;
    if(url?.type == undefined){
      lastSegment = url.split(/\#|\?/)[0].split('.').pop();
    }else{
      lastSegment = url.type.split('/').pop();
    }
    
    return lastSegment;
  }  

  return (
    <>
      <div className="form-group">
        <input
          type="file"
          className="form-control"
          id="input-file-upload"
          accept="image/png, image/jpeg, image/gif, video/mp4"
          onChange={setMenuImage}
        />
      </div>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                F&B Event Menu Card
                {currentMenu ? ` - ${currentMenu.menuName}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button
                onClick={() => props.history.push("/app/fnb-event-menu/list")}
              >
                Cancel
              </Button>
              {((!currentMenu &&
                !eventMenuId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.fnb_event_menu,
                  PrivilegeActions.add,
                )) ||
                (currentMenu &&
                  eventMenuId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.fnb_event_menu,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  Save
                </Button>
              )}
              {((!currentMenu &&
                !eventMenuId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.fnb_event_menu,
                  PrivilegeActions.add,
                )) ||
                (currentMenu &&
                  eventMenuId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.fnb_event_menu,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit((data, e) =>
                    handleSubmitForm(data, e, true, false),
                  )}
                >
                  Save & Preview
                </Button>
              )}
              {((!currentMenu &&
                !eventMenuId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.fnb_event_menu,
                  PrivilegeActions.add,
                )) ||
                (currentMenu &&
                  eventMenuId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.fnb_event_menu,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit((data, e) =>
                    handleSubmitForm(data, e, false, true),
                  )}
                >
                  Save/Duplicate
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Autocomplete
                            options={fnbOutlets}
                            getOptionLabel={(store) => `${store.name}`}
                            value={selectedStore}
                            onChange={(e, newValue) => onChangeStore(newValue)}
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Outlet"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                            disabled={
                              currentMenu &&
                              eventMenuId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.fnb_event_menu,
                                PrivilegeActions.edit,
                              )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"menuName"}
                            label={"Menu Name"}
                            variant={"outlined"}
                            {...register("menuName", {
                              required: {
                                value: true,
                                message: "Name cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.menuName}
                            helperText={formErrors?.menuName?.message}
                            onChange={(event) => validateText(event)}
                            disabled={
                              currentMenu &&
                              eventMenuId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.fnb_event_menu,
                                PrivilegeActions.edit,
                              )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"pricePerPax"}
                            label={"Price/Pax"}
                            type={"number"}
                            variant={"outlined"}
                            {...register("pricePerPax", {
                              required: {
                                value: true,
                                message: "Price Per Pax cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.pricePerPax}
                            helperText={formErrors?.pricePerPax?.message}
                            onChange={(event) => validateText(event)}
                            disabled={
                              currentMenu &&
                              eventMenuId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.fnb_event_menu,
                                PrivilegeActions.edit,
                              )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"remark"}
                            label={"Remark"}
                            variant={"outlined"}
                            {...register("remark")}
                            autoComplete={"off"}
                            error={!!formErrors?.remark}
                            helperText={formErrors?.remark?.message}
                            onChange={(event) => validateText(event)}
                            disabled={
                              currentMenu &&
                              eventMenuId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.fnb_event_menu,
                                PrivilegeActions.edit,
                              )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("enabled")}
                                checked={enabledSwitchValue}
                                onChange={(e) =>
                                  setEnabledSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Enable"
                            disabled={
                              currentMenu &&
                              eventMenuId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.fnb_event_menu,
                                PrivilegeActions.edit,
                              )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Menu Items</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columns}
                      data={menuItemList}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={createNewRow}
                      disabled={
                        rowsInvalid ||
                        (currentMenu &&
                          eventMenuId &&
                          !auth.checkModulePrivilege(
                            PrivilegeModules.fnb_event_menu,
                            PrivilegeActions.edit,
                          ))
                      }
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
};

export default withRouter(FnbEventMenuCard);
