import React from "react";
import {
    Button, Grid, List, Box, Typography,
    ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow
} from "@mui/material";
import moment from "moment";
import { INSTALLATION_CLAIMS_STATUS } from "../../../../data/constants";
import useStyles from "../../styles";
import { convertThisToCurrency } from "../../../../helper/convert-this-to-currency";

export default function ClaimInvoiceInfoDrawer({ claim, invoice,
    onApproveSingle, calculate, checkPosted, deleteClaim, revertClaim,
    hasPermissionToApprove, hasPermissionToDelete, hasPermissionToRevert }) {
    const classes = useStyles();
    const isPending = claim && claim.status === INSTALLATION_CLAIMS_STATUS.PENDING;
    const isCancelled = claim && claim.status === INSTALLATION_CLAIMS_STATUS.CANCELLED;
    const isApproved = claim && claim.status === INSTALLATION_CLAIMS_STATUS.APPROVED;
    // show calculate button when claim is PENDING, related matrix is modified, 
    // and claim has not calculated
    const showCalculateButton = isPending && claim.calculated === 'No';

    // show approve button only when claim is pending and invoice not posted
    const showApproveButton = hasPermissionToApprove && isPending
        && claim.calculated === 'Yes' && claim.invoicePosted === 'Yes' && claim?.orderBalance === 0;

    // check posted button
    const showCheckPostedButton = hasPermissionToApprove && isPending && claim.invoicePosted === 'No';

    // delete option
    const showDeleteButton = (isPending || isCancelled) && hasPermissionToDelete;

    // revert option
    const showRevertButton = isApproved && claim.downloaded === 'No' && hasPermissionToRevert;

    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }} className="display">
            <Grid item xs={12} className={"action"}>
                {showDeleteButton &&
                    <Button className={"primary"}
                        onClick={() => deleteClaim(claim)}>Delete</Button>
                }
                {showRevertButton &&
                    <Button className={"primary"}
                        onClick={() => revertClaim(claim)}>Revert</Button>
                }
                {showCalculateButton &&
                    <Button className={"primary"}
                        onClick={() => calculate(claim)}>Calculate</Button>
                }
                {showCheckPostedButton &&
                    <Button className={"primary"}
                        onClick={() => checkPosted(claim)}>Check Posted</Button>
                }
                {(!showCalculateButton && showApproveButton) &&
                    <Button className={"primary"} onClick={() => onApproveSingle(claim)}>Approve</Button>}
            </Grid>
            {claim && (<Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Typography variant={"h2"}>Installation Claim</Typography>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Invoice No.`}
                                    secondary={claim.invoiceNo}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Team`}
                                    secondary={`${claim.team}-${claim.teamName}`}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Claim Date`}
                                    secondary={claim.claimDate ? moment(claim.claimDate).format('DD/MM/YYYY HH:mm:ss') : ''}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Approved Date`}
                                    secondary={claim.approvedDate ? moment(claim.approvedDate).format('DD/MM/YYYY HH:mm:ss') : '-'}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Status`}
                                    secondary={claim.status}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <List>
                            {hasPermissionToApprove && <>
                                <ListItem>
                                    <ListItemText
                                        primary={`Posted`}
                                        secondary={claim.invoicePosted.toUpperCase()}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={`Downloaded`}
                                        secondary={claim.downloaded.toUpperCase()}
                                    />
                                </ListItem>
                            </>
                            }
                        </List>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Total Claim Amount`}
                                    classes={{
                                        primary: classes.highlighted,
                                        secondary: classes.highlighted
                                    }}
                                    secondary={convertThisToCurrency(claim.totalClaimAmount?.toFixed(2))}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Original Total Claim Amount`}
                                    secondary={convertThisToCurrency(claim.orgTotalClaimAmount?.toFixed(2))}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Order Balance`}
                                    secondary={claim.orderBalance !== undefined ? convertThisToCurrency(claim.orderBalance.toFixed(2)) : '-'}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Customer Balance`}
                                    secondary={claim.customerBalance !== undefined ? convertThisToCurrency(claim.customerBalance.toFixed(2)) : '-'}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>)
            }
            {invoice && (<Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Typography variant={"h2"}>Invoice</Typography>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Ship To`}
                                    secondary={
                                        <address>
                                            <span>{invoice.billToName}</span>
                                            <span>{invoice.billToStreetName} {invoice.billToUnitNo}</span>
                                            <span>SINGAPORE {invoice.billToPostCode}</span>
                                            <span>M: {invoice.billToContactNo}</span>
                                        </address>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Deliver To`}
                                    secondary={
                                        <address>
                                            <span>{invoice.shipToName}</span>
                                            <span>{invoice.shipToStreetName} {invoice.shipToUnitNo}</span>
                                            <span>SINGAPORE {invoice.shipToPostCode}</span>
                                            <span>M: {invoice.shipToContactNo}</span>
                                        </address>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Customer No.`}
                                    secondary={invoice.customerNo}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`D.O./Tax Invoice No.`}
                                    secondary={invoice.invoiceNo}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`D.O./Tax Invoice Date`}
                                    secondary={invoice.invoiceDate ?
                                        moment(invoice.invoiceDate).format('DD/MM/YYYY') : ''}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Sales Order No.`}
                                    secondary={invoice.orderNo}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Sales Order Date`}
                                    secondary={invoice.orderDate ?
                                        moment(invoice.orderDate).format('DD/MM/YYYY') : ''}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Pick Order No.`}
                                    secondary={invoice.pickOrderNo}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Delivery Date`}
                                    secondary={invoice.deliveryDate ?
                                        moment(invoice.deliveryDate).format('DD/MM/YYYY') : ''}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Delivery Time`}
                                    secondary={invoice.deliveryTime}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`Sales Person`}
                                    secondary={invoice.salesPerson}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Payment Terms`}
                                    secondary={invoice.paymentTerms}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Contract No.`}
                                    secondary={invoice.contractNo}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">No.</TableCell>
                                    <TableCell align="left">Description/Model</TableCell>
                                    <TableCell align="center">Qty</TableCell>
                                    <TableCell align="center">UOM</TableCell>
                                    <TableCell align="left">LCN</TableCell>
                                    <TableCell align="right">Unit Price</TableCell>
                                    <TableCell align="right">Discount Amount</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                    <TableCell align="right" classes={{ root: classes.highlighted }}>Claim Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoice.invoiceLines.map((row, i) => (
                                    <TableRow
                                        key={`${row.no}-${i}`}
                                    >
                                        <TableCell align="left" style={{ borderBottom: "0" }}>{row.isGeneric != true || row.isGeneric == null ? row.no : ''}</TableCell>
                                        <TableCell align="left" style={{ borderBottom: "0" }}>{row.description}</TableCell>
                                        <TableCell align="center" style={{ borderBottom: "0" }}>{row.type !== 0 || row.isGeneric == true ? row.quantity : ''}</TableCell>
                                        <TableCell align="center" style={{ borderBottom: "0" }}>{row.unitOfMeasure}</TableCell>
                                        <TableCell align="left" style={{ borderBottom: "0" }}>{row.locationCode}</TableCell>
                                        <TableCell align="right" style={{ borderBottom: "0" }}>{row.type === 0 ? '' : (row.unitPrice ? convertThisToCurrency(row.unitPrice.toFixed(2)) : '$0.00')}</TableCell>
                                        <TableCell align="right" style={{ borderBottom: "0" }}>{row.type === 0 ? '' : (row.lineDiscountAmount ? convertThisToCurrency(row.lineDiscountAmount.toFixed(2)) : '$0.00')}</TableCell>
                                        <TableCell align="right" style={{ borderBottom: "0" }}>{row.type === 0 ? '' : (row.lineTotalAmount ? convertThisToCurrency(row.lineTotalAmount.toFixed(2)) : '$0.00')}</TableCell>
                                        <TableCell align="right" style={{ borderBottom: "0" }}
                                            classes={{ root: classes.highlighted }}>{row.type !== 0 || row.isGeneric == true ?
                                                (row.claimAmount ? convertThisToCurrency(row.claimAmount.toFixed(2)) : '$0.00') : ''}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>
            </Grid>)
            }

            {invoice.creditMemos && invoice.creditMemos.length > 0 &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Typography variant={"h2"}>Credit Memo</Typography>
                        </Grid>
                        {invoice.creditMemos.map((crMemo, j) => (
                            <>
                                <Grid item xs={6} lg={8}></Grid>
                                <Grid item xs={6} lg={2}>
                                    <List>
                                        <ListItem>
                                            <ListItemText
                                                primary={`SRO No.`}
                                                secondary={crMemo.returnOrderNo}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <List>
                                        <ListItem>
                                            <ListItemText
                                                primary={`Credit Memo Amount`}
                                                classes={{
                                                    primary: classes.highlighted,
                                                    secondary: classes.highlighted
                                                }}
                                                secondary={convertThisToCurrency(crMemo.creditMemoAmount?.toFixed(2))}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={`Credit Memo No.`}
                                                secondary={crMemo.creditMemoNo}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={`Credit Memo Date`}
                                                secondary={moment(crMemo.creditMemoDate).format('DD/MM/YYYY')}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">No.</TableCell>
                                                <TableCell align="left">Description/Model</TableCell>
                                                <TableCell align="center">Qty</TableCell>
                                                <TableCell align="center">UOM</TableCell>
                                                <TableCell align="left">LCN</TableCell>
                                                <TableCell align="right">Unit Price</TableCell>
                                                <TableCell align="right">Discount Amount</TableCell>
                                                <TableCell align="right">Total</TableCell>
                                                <TableCell align="right" classes={{ root: classes.highlighted }}>Claim Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                crMemo.creditMemoLines.map((row, i) => (
                                                    <TableRow
                                                        key={`${row.no}-${i}`}
                                                    >
                                                        <TableCell align="left" style={{ borderBottom: "0" }}>{row.no}</TableCell>
                                                        <TableCell align="left" style={{ borderBottom: "0" }}>{row.description}</TableCell>
                                                        <TableCell align="center" style={{ borderBottom: "0" }}>{row.type !== 0 ? row.quantity : ''}</TableCell>
                                                        <TableCell align="center" style={{ borderBottom: "0" }}>{row.unitOfMeasure}</TableCell>
                                                        <TableCell align="left" style={{ borderBottom: "0" }}>{row.locationCode}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "0" }}>{row.type === 0 ? '' : (row.unitPrice ? convertThisToCurrency(row.unitPrice.toFixed(2)) : '$0.00')}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "0" }}>{row.type === 0 ? '' : (row.lineDiscountAmount ? convertThisToCurrency(row.lineDiscountAmount.toFixed(2)) : '$0.00')}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "0" }}>{row.type === 0 ? '' : (row.lineTotalAmount ? convertThisToCurrency(row.lineTotalAmount.toFixed(2)) : '$0.00')}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "0" }}
                                                            classes={{ root: classes.highlighted }}>{row.type !== 0 ?
                                                                (row.claimAmount ? convertThisToCurrency(row.claimAmount.toFixed(2)) : '$0.00') : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Grid>
            }
        </Grid ></>);
};
