import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import Swal from "sweetalert2";
import "./style/index.css";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DataTable from "react-data-table-component";
import { Add } from "@mui/icons-material";
import { airconItemTableColumnConfig } from "./data-table/aircon-item-table-column-config";
import _ from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import TextFieldController from "../../components/TextFieldController";
import { useParams } from "react-router-dom";
import SwitchController from "../../components/SwitchController";

// ************************ **********************

const AirconSubscriptionMatrixCard = (props) => {
  // *********** VAR *************
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    sendRequest,
    httpRequestError: error,
    responseData,
  } = useHttpRequest();
  const methods = useForm({ mode: "all" });
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState,
    trigger,
  } = methods;
  const [currentMatrix, setCurrentMatrix] = useState();
  const [airconAttribute, setAirconAttribute] = useState();
  const { id } = useParams();

  // *********** FUNCTION *************
  const navigateToList = () => {
    if (!id) window.location.href = "#/app/aircon-subscription-matrix/list";
  };

  const handleSubmitForm = async (props) => {
    try {
      const payload = {
        ...props,
        items: props.items.map((item) => ({
          ...item,
          price: {
            "36Months": Number(item.price["36Months"]),
            "60Months": Number(item.price["60Months"]),
          },
          systemId: item.system.id,
        })),
      };

      let res;

      if (id && currentMatrix) {
        res = await sendRequest(
          `/v1/aircon-subscription-matrix/${id}`,
          "PUT",
          payload,
        );

        await fetchAirconSubscription(airconAttribute);
      } else {
        res = await sendRequest(
          `/v1/aircon-subscription-matrix`,
          "POST",
          payload,
        );
      }

      Swal.fire({
        icon: "success",
        title: "Success",
        text: res?.data?.message,
        willClose: navigateToList,
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  };

  const onRemoveRow = (name, i) => {
    const rows = getValues(name);
    rows.splice(i, 1);
    setValue(name, [...rows]);
    methods.trigger(name);
  };

  const createNewRow = async (name) => {
    const rows = getValues(name) ?? [];
    setValue(name, [
      ...rows,
      {
        systemId: null,
        price: {
          "36Months": "",
          "60Months": "",
        },
        combination: "",
        system: null,
        description: "",
      },
    ]);

    await new Promise((resolve) => setTimeout(resolve, 100));
    methods.trigger(name);
  };

  const itemColumns = airconItemTableColumnConfig({
    onRemoveRow,
    name: "items",
    systems: airconAttribute?.systems?.filter((i) => i.enabled) ?? [],
  });

  const fetchAirconSubscription = async (attribute) => {
    sendRequest(`/v1/aircon-subscription-matrix/${id}`, "GET").then((res) => {
      document.title = `${res.data.brandName} - Aircon Subscription Matrix Card`;
      setCurrentMatrix(res.data);
      const mapData = {
        ...res.data,
        items:
          res.data.items.map((item) => ({
            ...item,
            system: attribute?.systems?.find((s) => s.id === item.systemId),
          })) ?? [],
      };

      reset(mapData);
    });
  };

  // *********** HOOK *************

  useEffect(() => {
    document.title = "New - Aircom Subscription Matrix Card";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.aircon_subscription_matrix,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
    sendRequest(`/v1/aircon-subscription-attribute/attribute`, "GET").then(
      (res) => {
        const attribute = res.data;
        setAirconAttribute(attribute);
        if (id) {
          fetchAirconSubscription(attribute);
        } else {
          reset({ items: [], enabled: true });
        }

        trigger();
      },
    );
  }, [id]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // *********** Render *************

  const pageTitleRender = (
    <Typography variant={"h1"}>
      {id && currentMatrix
        ? `Aircon Subscription Matrix Card - ${currentMatrix.brandName}`
        : "Aircon Subscription Matrix Card - New"}
    </Typography>
  );

  const actionRender = auth.checkModulePrivilege(
    PrivilegeModules.aircon_subscription_matrix,
    PrivilegeActions.edit,
  ) && (
    <Button
      className={"primary"}
      type="submit"
      disabled={Object.keys(formState.errors).length !== 0}
    >
      Save
    </Button>
  );

  const generalFormRender = (
    <Box className={"form"}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant={"h2"}>General</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <TextFieldController
                name={`brandName`}
                label={`Brand`}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <SwitchController name={`enabled`} label={`Enable`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const itemTableRender = (
    <Box className={"form"}>
      <Controller
        name={"items"}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const list = field.value ?? [];
          return (
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} className={"sub-action"}>
                <Typography variant={"h2"}>Aircon</Typography>
              </Grid>
              <Grid item xs={12} className={"table"}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={itemColumns}
                  data={list}
                />
              </Grid>
              <Grid item xs={12} className={"table-action"}>
                <IconButton
                  onClick={(e) => createNewRow("items")}
                  disabled={!!error}
                >
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
          );
        }}
      />
    </Box>
  );

  const formRender = (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {pageTitleRender}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
            <Button
              onClick={() =>
                props.history.push("/app/aircon-subscription-matrix/list")
              }
            >
              Cancel
            </Button>
            {actionRender}
          </Grid>

          <Grid item xs={12}>
            {generalFormRender}
          </Grid>

          <Grid item xs={12}>
            {itemTableRender}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      {formRender}
    </>
  );
};

export default AirconSubscriptionMatrixCard;
