import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  Typography,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { ArrowBack as BackIcon } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { individualItemSchema } from "../schema/fnb-supplier-schema";
import React from "react";
import { uniqBy } from "lodash/array";

export function SupplierItemEdit({
  defaultValues,
  storeOptions,
  onChange,
  onClose,
}) {
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    register,
  } = useForm({
    values: defaultValues,
    mode: "all",
    resolver: joiResolver(individualItemSchema, { allowUnknown: true }),
  });
  const selectedStores = watch("stores", []);
  const isStoreSelected = (store) =>
    (selectedStores || []).some(
      (tickedS) => tickedS.storeCode === store.storeCode,
    );

  const onChangeStoreSwitch = (store) => (event, checked) => {
    const newStoreList = uniqBy(
      checked
        ? [...selectedStores, store]
        : [...selectedStores].filter((s) => store.storeCode !== s.storeCode),
      (s) => s.storeCode,
    );
    setValue("stores", newStoreList);
  };

  const saveHandler = handleSubmit(onChange);

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <form onSubmit={saveHandler}>
          <DialogTitle>
            <Grid item xs={12} className={"sub-action"}>
              <Button onClick={() => onClose()}>
                <BackIcon />
              </Button>
              <Typography variant={"h2"}>
                {defaultValues.isNew ? "Supplier Item - New" : `Supplier Item - ${defaultValues.sku}`}
              </Typography>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <TextField
                  label={"SKU"}
                  error={!!errors?.sku}
                  {...register("sku")}
                  helperText={errors?.sku && "SKU cannot be blank."}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={"Description"}
                  error={!!errors?.description}
                  {...register("description")}
                  helperText={
                    errors?.description && "Description cannot be blank."
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={"UOM"}
                  error={!!errors?.uom}
                  {...register("uom")}
                  helperText={errors?.uom && "UOM cannot be blank."}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={"Unit Price"}
                  error={!!errors?.price}
                  {...register("price")}
                  helperText={
                    errors?.price &&
                    "Unit Price cannot be blank and must be a valid dollar value."
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  className="form-control"
                  component="fieldset"
                  variant="standard"
                  error={!!errors?.stores}
                >
                  <FormLabel component="legend">Stores</FormLabel>
                  <FormGroup sx={{ flexDirection: "row" }}>
                    {storeOptions.map((store) => (
                      <FormControlLabel
                        key={store.storeCode}
                        control={
                          <Switch
                            checked={isStoreSelected(store)}
                            onChange={onChangeStoreSwitch(store)}
                            name={store.storeCode}
                          />
                        }
                        label={`${store.storeName.toUpperCase()}-${store.storeCode}`}
                      />
                    ))}
                  </FormGroup>
                  {errors?.stores && (
                    <FormHelperText>
                      {errors?.stores &&
                        "Store cannot be blank."}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="action">
            <Button onClick={saveHandler} type={"submit"} className={"primary"}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
