export const PR_STEPS = {
  STEP_0_5: "0/5",
  STEP_1_5: "1/5",
  STEP_2_5: "2/5",
  STEP_3_1_5: "3.1/5",
  STEP_3_2_5: "3.2/5",
  STEP_4_5: "4/5",
  STEP_5_5: "5/5",
};

const PR_APPROVAL_STEPS = [
  PR_STEPS.STEP_1_5,
  PR_STEPS.STEP_2_5,
  PR_STEPS.STEP_3_1_5,
  PR_STEPS.STEP_3_2_5,
];

export const PR_STATUS = {
  NEW: "NEW",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  REVISED: "REVISED",
  DECLINED: "DECLINED",
  AWARDED: "AWARDED",
};

export const PR_ACTIONS = {
  CREATE: "Create",
  SUBMIT: "Submit",
  SAVE: "Save",
  APPROVE: "Approve",
  REJECT: "Reject",
  REVISE: "Revise",
  NEED_REVISION: "Need_Revision",
  AWARD: "Award",
  DECLINE: "Decline",
  NOT_APPLICABLE: "Not_Applicable",
  VERIFY: "Verify",
};

export const PR_STEP_RESULTS = {
  CREATED: "CREATED",
  SUBMITTED: "SUBMITTED",
  UPDATED: "UPDATED",
  REJECTED: "REJECTED",
  REVISED: "REVISED",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  AWARDED: "AWARDED",
};

export const PR_DOCUMENT_TYPES = {
  OTHER_DOCUMENTS: "OTHER_DOCUMENTS",
  FEEDBACK_DOCUMENTS: "FEEDBACK_DOCUMENTS",
  PAYMENT_RECEIVED: "PAYMENT_RECEIVED",
  COST_MARGIN: "COST_MARGIN",
  COST_MARGIN_A: "COST_MARGIN_A",
  SIGNATURE: "SIGNATURE",
};

export const PR_COST_MARGIN_ACTUAL_TYPES = {
  O: "O",
  V: "V",
};

export const PR_OTH_DOC_TYPE = {
  //   CREDIT_AGGREEMENT: "CREDIT AGGREEMENT",
  //   QUOTATION_SUPPLIER_SUBCON: "QUOTATION (SUPPLIER/SUBCON)",
  //   QUOTATION_GAIN_CITY: "QUOTATION (GAIN CITY)",
  QUOTATION: "QUOTATION",
  OTHERS: "OTHERS",
};

export const isStep_0_5 = (step) => step === PR_STEPS.STEP_0_5;
export const isStep_1_5 = (step) => step === PR_STEPS.STEP_1_5;
export const isStep_2_5 = (step) => step === PR_STEPS.STEP_2_5;
export const isStep_3_1_5 = (step) => step === PR_STEPS.STEP_3_1_5;
export const isStep_3_2_5 = (step) => step === PR_STEPS.STEP_3_2_5;
export const isStep_4_5 = (step) => step === PR_STEPS.STEP_4_5;
export const isStep_5_5 = (step) => step === PR_STEPS.STEP_5_5;

export const isNew = (status) => status === PR_STATUS.NEW;
export const isPending = (status) => status === PR_STATUS.PENDING;
export const isApproved = (status) => status === PR_STATUS.APPROVED;
export const isRejected = (status) => status === PR_STATUS.REJECTED;
export const isRevised = (status) => status === PR_STATUS.REVISED;
export const isDeclined = (status) => status === PR_STATUS.DECLINED;
export const isAwarded = (status) => status === PR_STATUS.AWARDED;

export const isSubmittedForApproval = (pr) =>
  isPending(pr?.status) && !!pr?.submittedForApproval;
export const includesInApprovalSteps = (step) =>
  PR_APPROVAL_STEPS.includes(step);

export const isVarientType = (type) => type === PR_COST_MARGIN_ACTUAL_TYPES.V;
export const isOriginalType = (type) => type === PR_COST_MARGIN_ACTUAL_TYPES.O;

export const percentageSteps = 10;

export function getPercentages(start) {
  let percentages = [];
  for (let i = start + 1; i <= percentageSteps; i++) {
    const value = i * percentageSteps;
    percentages.push({
      value,
      label: `${value} %`,
    });
  }
  return percentages;
}

export function checkElement(id) {
  return document.getElementById(id) !== null;
}
