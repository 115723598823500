import moment from "moment";
import { Link } from "react-router-dom";
import { BRACKET_REQUEST_ITEM_STATUS } from "../../../data/constants";

export const bracketRequestTableColumnConfig = (allowedViewDetail,handlePkrNoClick) => [
    {
        name: "Document No.",
        width: "120px",
        selector: row => row.pkrNo,
        cell: (row) => {
            if(allowedViewDetail){
                return (<Link to={'#'} onClick={(e) => handlePkrNoClick(row.pkrNo)}>{row.pkrNo}</Link>)
            }else{
                return (<>{row.pkrNo}</>);
            }            
        },
        sortable: true,
        sortField: 'pkrNo'
    },
    {
        name: "Total Bracket",
        width: "100px",
        selector: row => row.issueQty,
        sortable: true,
        right: true,
        sortField: 'issueQty'
    },
    {
        name: "Store",
        width: "80px",
        selector: row => row.storeCode,
        sortable: true,
        sortField: 'store'
    },
    {
        name: "Request Date",
        width: "150px",
        wrap: true,
        sortable: true,
        selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        sortField: 'requestDate'
    },
    {
        name: "Remark",
        selector: row => row.remark,
        sortable: true,
        sortField: 'remark',
        width: "250px",
        wrap: true,
},
];
