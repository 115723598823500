import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@mui/icons-material";
import { convertThisToCurrency } from "../../../helper";

export const fnbFoodTableColumnConfig = (
  {
    allowedViewDetail,
    allowedDelete,
    allowedEnableDisable,
    allowedEdit,
    chef_mode,
    itemPickerMode,
  },
  handleNameClick,
  handleDeleteFnbFood,
  handleEditFnbFood,
  selectItem,
) => {
  let columns = [];

  if (itemPickerMode) {
    columns = [
      ...columns,
      {
        width: "80px",
        center: true,
        selector: (row) => row.selected,
        cell: (row, index) => {
          if (row.selected !== undefined) {
            return (
              <Switch
                checked={row.selected}
                onChange={(e, value) => selectItem(row, index, value)}
                inputProps={{ "aria-label": "controlled" }}
              />
            );
          }
          return null;
        },
        sortable: false,
      },
    ];
  }

  if (!itemPickerMode) {
    columns = [
      ...columns,
      {
        name: "Store",
        width: "150px",
        selector: (row) => row.store.name.toUpperCase(),
        sortable: true,
        wrap: true,
        sortField: "store.name",
      },
    ];
  }

  columns = [
    ...columns,
    {
      name: "SKU",
      width: "70px",
      selector: (row) => row.sku,
      cell: (row) => {
        if (allowedViewDetail && !chef_mode && !itemPickerMode) {
          return (
            <Link
              to={`/app/fnb-food/card/${row.entityId}`}
              onClick={(e) => handleNameClick(row.entityId)}
            >
              {row.sku}
            </Link>
          );
        } else {
          return <>{row.sku}</>;
        }
      },
      sortable: true,
      sortField: "sku",
    },
    {
      name: "Name",
      width: "300px",
      selector: (row) => row.name.toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "name",
    },
  ];

  columns = [
    ...columns,
    {
      name: "Category",
      width: "200px",
      selector: (row) => row.category.name.toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "category.name",
    },
  ];

  //   if (!chef_mode && !itemPickerMode) {
  //     columns = [
  //       ...columns,
  //       {
  //         name: "Description",
  //         width: "250px",
  //         selector: (row) => row.description,
  //         sortable: true,
  //         wrap: true,
  //         sortField: "description",
  //       },
  //     ];
  //   }

  if (!itemPickerMode) {
    columns = [
      ...columns,
      {
        name: "Unit Price",
        width: "100px",
        right: true,
        selector: (row) => convertThisToCurrency(row.price),
        sortable: true,
        sortField: "price",
      },
    ];
  }

  if (!chef_mode && !itemPickerMode) {
    columns = [
      ...columns,
      {
        name: "Takeaway Fee",
        width: "110px",
        right: true,
        selector: (row) => convertThisToCurrency(row.takeawayFee),
        sortable: true,
        sortField: "takeawayFee",
      },
      {
        name: "Seq. No.",
        width: "90px",
        right: true,
        selector: (row) => row.seqNo,
        sortable: true,
        sortField: "seqNo",
      },
      {
        name: "Promote",
        width: "80px",
        center: true,
        selector: (row) => row.promote,
        cell: (row) => {
          return (
            <Switch
              checked={row.promote}
              onChange={(e, value) =>
                handleEditFnbFood(row, "promote", true, value)
              }
              inputProps={{ "aria-label": "controlled" }}
              disabled={!allowedEdit}
            />
          );
        },
        sortable: true,
        sortField: "promote",
      },
      {
        name: "Popular",
        width: "90px",
        center: true,
        selector: (row) => row.popular,
        cell: (row) => {
          return (
            <Switch
              checked={row.popular}
              onChange={(e, value) =>
                handleEditFnbFood(row, "popular", true, value)
              }
              inputProps={{ "aria-label": "controlled" }}
              disabled={!allowedEdit}
            />
          );
        },
        sortable: true,
        sortField: "popular",
      },
      {
        name: "Chef Recommend",
        width: "130px",
        center: true,
        selector: (row) => row.chefRecommend,
        cell: (row) => {
          return (
            <Switch
              checked={row.chefRecommend}
              onChange={(e, value) =>
                handleEditFnbFood(row, "chefRecommend", true, value)
              }
              inputProps={{ "aria-label": "controlled" }}
              disabled={!allowedEdit}
            />
          );
        },
        sortable: true,
        sortField: "chefRecommend",
      },
      {
        name: "Vegetarian",
        width: "100px",
        center: true,
        selector: (row) => row.vegetarian,
        cell: (row) => {
          return (
            <Switch
              checked={row.vegetarian}
              onChange={(e, value) =>
                handleEditFnbFood(row, "vegetarian", true, value)
              }
              inputProps={{ "aria-label": "controlled" }}
              disabled={!allowedEdit}
            />
          );
        },
        sortable: true,
        sortField: "vegetarian",
      },
      {
        name: "Healthier Choice",
        width: "130px",
        center: true,
        selector: (row) => row.healthierChoice,
        cell: (row) => {
          return (
            <Switch
              checked={row.healthierChoice}
              onChange={(e, value) =>
                handleEditFnbFood(row, "healthierChoice", true, value)
              }
              inputProps={{ "aria-label": "controlled" }}
              disabled={!allowedEdit}
            />
          );
        },
        sortable: true,
        sortField: "healthierChoice",
      },
    ];
  }

  if (!itemPickerMode) {
    columns = [
      ...columns,
      {
        name: "Sold Out",
        width: "90px",
        center: true,
        selector: (row) => row.inStock,
        cell: (row) => {
          return (
            <Switch
              checked={!row.inStock}
              onChange={(e, value) =>
                handleEditFnbFood(row, "inStock", true, !value)
              }
              inputProps={{ "aria-label": "controlled" }}
              disabled={!allowedEdit}
            />
          );
        },
        sortable: true,
        sortField: "inStock",
      },
      {
        name: "Redemption",
        width: "100px",
        center: true,
        selector: (row) => row.isPresetMeal,
        cell: (row) => {
          return (
            <Switch
              checked={row.isPresetMeal}
              onChange={(e, value) =>
                handleEditFnbFood(row, "isPresetMeal", true, value)
              }
              inputProps={{ "aria-label": "controlled" }}
              disabled={!allowedEdit}
            />
          );
        },
        sortable: true,
        sortField: "inStock",
      },
      {
        name: "Enable",
        width: "80px",
        center: true,
        selector: (row) => row.status,
        cell: (row) => {
          return (
            <Switch
              checked={row.status}
              onChange={(e, value) =>
                handleEditFnbFood(row, "status", true, value)
              }
              inputProps={{ "aria-label": "controlled" }}
              disabled={!allowedEdit}
            />
          );
        },
        sortable: true,
        sortField: "status",
      },
    ];
  }

  if (allowedDelete && !itemPickerMode && !chef_mode) {
    columns = [
      ...columns,
      {
        name: "",
        right: true,
        width: "50px",
        cell: (row) => {
          return (
            <>
              {allowedDelete && (
                <>
                  <IconButton onClick={(e) => handleDeleteFnbFood(row)}>
                    <DeleteOutline />
                  </IconButton>
                </>
              )}
            </>
          );
        },
      },
    ];
  }

  return columns;
};
