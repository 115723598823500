import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, Autocomplete
} from "@mui/material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { Clear, KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import TagInput from "../../components/TagInput/TagInput";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { SERIAL_NO_PREFIXES } from "../../data/constants";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const BracketCard = (props) => {

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();
    
    // handling form when adding / editing
    const { handleSubmit, control, getValues, setError,
        formState: { errors: formErrors, isSubmitted, dirtyFields }, setValue }
        = useForm({ mode: "all" });

    const [currentBracket, setCurrentBracket] = useState(null);
    const [welderList, setWelderList] = useState([]);
    const [bracketTypeList, setBracketTypeList] = useState([]);
    const [welder, setWelder] = useState(null);
    const [bracketType, setBracketType] = useState(null);
    const [batchNoList, setBatchNoList] = useState([]);
    const [batchNos, setBatchNos] = useState([]);
    const [serialNos, setSerialNos] = useState([]);
    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    const { serialNo } = useParams(); // url param => serialNo when editing

    useEffect(() => {
        document.title = "New - Bracket Card";

        // redirect to default page if not authorized
        if (serialNo && !auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.view_detail)) {
            props.history.push('/app/dashboard');
        }

        // load welder list
        sendRequest(`/v1/user/get-list-by-role/option_welder`, "GET", {})
            .then(response => {
                const list = response.data?.users || [];
                setWelderList(list.filter(i => i.status));
            });

        // load bracket types
        sendRequest(`/v1/bracket-type`, "GET", {})
            .then(response => {
                setBracketTypeList(response.data?.bracketTypes || []);
            });

        // load enabled batch Nos
        sendRequest(`/v1/bracket-batch/filter?filters={"enable":"Yes"}`, "GET", {})
            .then(response => {
                setBatchNoList(response.data?.batchList || []);
            });
    }, []);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    // get current bracket when editing 
    useEffect(() => {
        if (!isLoading && !responseData && serialNo) {
            sendRequest(`/v1/bracket/get-by-serial-no/` + serialNo, 'GET');
        }
    }, [serialNo]);

    // set title & form values with current bracket when editing
    useEffect(() => {
        if (serialNo && responseData?.bracket) {
            const bracket = responseData?.bracket;
            document.title = `${bracket.serialNo} - Bracket Card`;
            setCurrentBracket(bracket);
            setValue('welder', bracket.welder || '');
            setValue('bracketType', bracket.bracketType || '');
            setValue('batchNos', bracket.batchNos || '');
            setValue('serialNos', [bracket.serialNo] || '');
            setWelder(bracket.welder);
            setBracketType(bracket.bracketType);
            setSerialNos([bracket.serialNo]);
            setBatchNos(bracket.batchNos);
            validateSerialNosTagInput();
        }
    }, [responseData]);

    useEffect(() => {
        if (isSubmitted) {
            validateSerialNosTagInput();
        }
    }, [isSubmitted]);

    // navigate back to bracket list
    const navigateToList = () => {
        if (!serialNo)
            window.location.href = '#/app/bracket/list';
    }

    // tag input validations
    const validateSerialNosTagInput = () => {
        if (serialNos.length === 0) {
            setValue('serialNos', [], { shouldDirty: true });
        }
    };

    // when submit the bracket form
    const handleSubmitForm = async (data, e) => {
        e.preventDefault();
        if (batchNos.length === 0 || serialNos.length === 0) { return; }

        let result = null;
        if (serialNo && currentBracket && currentBracket._id) { // when edit
            const exitingBracket = { ...currentBracket };
            exitingBracket.welder = data.welder._id;
            exitingBracket.bracketType = data.bracketType._id;
            exitingBracket.batchNos = batchNos.map(n => n.batchNo);

            result = await sendRequest(`/v1/bracket/${exitingBracket._id}`,
                "PUT", exitingBracket);
            if (result?.status === 200 && result?.data?.bracket) {
                setMessage(`Bracket ${exitingBracket.serialNo} has been updated.`); // call show feedback message
            }
        } else { // create new
            const newBracket = {
                welder: data.welder._id,
                bracketType: data.bracketType._id,
                batchNos: batchNos.map(n => n.batchNo),
                serialNos: serialNos.map(n => n.trim())
            };
            result = await sendRequest('/v1/bracket', "POST", newBracket);
            if (result?.status === 201 && result?.data?.result) {
                setMessage(`New bracket(s) has been created.`); // call show feedback message
            }
        }
    };

    // get bracket by serial no
    async function getBracketBySerialNo(serialNo) {
        const response = await sendRequest(
            `/v1/bracket/get-by-serial-no/${serialNo}`, 'GET');
        return response.data?.bracket;
    }

    // check bracket before add to list
    async function checkBeforeAdd(list, current) {
        current = current.toUpperCase();
        if (isValidSerialNo(current)) {
            const bracket = await getBracketBySerialNo(current);
            if (bracket) {
                setSerialNos(list); // revert to previous
                setWarningMessage(`Duplicate bracket ${current} found in the system.`);
            }
        } else {
            setSerialNos(list); // revert to previous
            setWarningMessage(`Serial No. is invalid.`);
        }
    }
    
    // check for serial no prefixes
    const isValidSerialNo = (value) => {
        const prefix = value.substring(0, 4);
        return SERIAL_NO_PREFIXES.includes(prefix);
    }

    // validate batch nos multi select when touched
    const onBlurBatchNos = () => {
        if (!getValues('batchNos'))
            setError('batchNos', { message: 'Batch Nos cannot be blank.' });
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {(!serialNo || (serialNo && currentBracket)) &&
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Bracket Card {currentBracket ? ` - #${currentBracket.serialNo}` : ` - New`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/bracket/list')}>Cancel</Button>
                        {
                            ((!serialNo && auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.add)) || (serialNo && auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.edit)))
                            &&
                            <Button className={"primary"} type={"button"} onClick={handleSubmit(handleSubmitForm)}>Save</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Controller
                                                    name={`welder`}
                                                    control={control}
                                                    rules={{ required: { value: true, message: "Welder cannot be blank." } }}
                                                    render={(props) => (
                                                        <Autocomplete
                                                            id={"welder"}
                                                            options={welderList}
                                                            getOptionLabel={(option) =>
                                                                `${option.username}-${option.name}`}
                                                            value={welder}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label={"Welder"}
                                                                    variant={"outlined"}
                                                                    error={!!formErrors?.welder}
                                                                    helperText={formErrors?.welder?.message}
                                                                    autoComplete="off" />}
                                                            onChange={(event, data) => {
                                                                setWelder(data);
                                                                props?.field.onChange(data);
                                                            }}
                                                            popupIcon={<ArrowDownIcon />} />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Controller
                                                    name={`bracketType`}
                                                    control={control}
                                                    rules={{ required: { value: true, message: "Bracket Type cannot be blank." } }}
                                                    render={(props) => (
                                                        <Autocomplete
                                                            id={"bracket-type"}
                                                            options={bracketTypeList}
                                                            getOptionLabel={(option) => option.name}
                                                            value={bracketType}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label={"Bracket Type"}
                                                                    variant={"outlined"}
                                                                    error={!!formErrors?.bracketType}
                                                                    helperText={formErrors?.bracketType?.message}
                                                                    autoComplete="off" />}
                                                            onChange={(event, data) => {
                                                                setBracketType(data);
                                                                props?.field.onChange(data);
                                                            }}
                                                            popupIcon={<ArrowDownIcon />} />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    name={`batchNos`}
                                                    control={control}
                                                    rules={{
                                                        required: { value: true, message: "Batch Nos. cannot be blank." }
                                                    }}
                                                    render={(props) => (
                                                        <Autocomplete
                                                            id={"batch-nos"}
                                                            multiple
                                                            options={batchNoList}
                                                            getOptionLabel={(option) => option.batchNo}
                                                            onBlur={(event) => onBlurBatchNos()}
                                                            value={batchNos}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label={"Batch No."}
                                                                    variant={"outlined"}
                                                                    error={!!props?.formState?.errors?.batchNos}
                                                                    helperText={props?.formState?.errors?.batchNos?.message}
                                                                    autoComplete="off"
                                                                />
                                                            }
                                                            onChange={(event, data) => {
                                                                setBatchNos(data);
                                                                props?.field.onChange(data);
                                                            }}
                                                            popupIcon={<ArrowDownIcon />}
                                                            ChipProps={{ deleteIcon: <Clear /> }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {!serialNo && (<>
                                                <Grid item xs={12} sm={6}>
                                                    <TagInput
                                                        name={"serialNos"}
                                                        required={true}
                                                        tags={serialNos}
                                                        setTags={setSerialNos}
                                                        label={`Serial No.`}
                                                        error={(dirtyFields.serialNos && serialNos.length === 0)}
                                                        helperText={(dirtyFields.serialNos && serialNos.length === 0)
                                                            && "Serial No. cannot be blank."}
                                                        disabled={!!serialNo && !!currentBracket}
                                                        onChange={(data) => {
                                                            setValue('serialNos', data);
                                                            validateSerialNosTagInput();
                                                        }}
                                                        onKeyDown={(list, current) => checkBeforeAdd(list, current)}
                                                        body={serialNos.length > 0 &&
                                                            (<div className={"total"}>
                                                                {`Total: ${serialNos.length}`}</div>)}
                                                    />
                                                </Grid>
                                            </>)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(BracketCard);