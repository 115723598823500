import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { documentsTableConfig } from "../../data-table/documents-table-config";
import { PR_PANELS } from "../../helper/panels";
import { checkElement, isSubmittedForApproval } from "../../helper/pr-config";
import { PR_BUTTONS } from "../../helper/action-buttons";
import ProjectCostingSummary from "../../ProjectCostingSummary.js";
import { useState, useEffect } from "react";
import { useHttpRequest } from "../../../../hooks/useHttpRequest.js";
import { isStep_3_1_5 } from "../../helper/pr-config";

const WorkflowDocuments = (props) => {
  const { sendRequest, isLoading } = useHttpRequest();
  const [signPic, setSignPic] = useState("");
  const [openDiaProjCostingSummary, setOpenDiaProjCostingSummary] =
    useState(false);

  const isEditable = () =>
    props?.hasEditPrivilege &&
    (checkElement(PR_BUTTONS.SAVE) ||
      checkElement(PR_BUTTONS.SUBMIT) ||
      checkElement(PR_BUTTONS.NEED_REVISION) ||
      checkElement(PR_BUTTONS.APPROVE) ||
      checkElement(PR_BUTTONS.REVISE) ||
      checkElement(PR_BUTTONS.AWARD));

  const columns = documentsTableConfig(
    props.docTypeList || [],
    isEditable() ||
      (isStep_3_1_5(props.step) && props.hasRemainingCMApprovalsForUser),
    onRemoveRow,
    onChangeRowValues,
    onClickAddBtn,
    handleClickOnPNL,
    props.version,
  );

  function handleClickOnPNL() {
    downloadProjectCostingSummaryReport();
  }

  function onClickAddBtn() {
    document.getElementById("document-upload").click();
  }

  function onChangeRowValues(i, value, attribute) {
    const docsList = [...props.otherDocuments];
    if (attribute === "description") {
      value = value.toUpperCase();
    }
    docsList.at(i)[attribute] = value;
    props.setOtherDocuments(docsList);
  }

  function onAddDocuments(e) {
    const files = e.target.files;
    let fileList = [...props.otherDocuments];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      fileList = [
        ...fileList,
        {
          file: file,
          fileName: file.name,
          description: "",
          uploadedDate: null,
          new: true,
          type: "",
          uploadedBy: "",
          allowedDelete: true,
        },
      ];
    }
    props.setOtherDocuments(fileList);
  }

  function onRemoveRow(index) {
    const documents = [...props.otherDocuments];
    const removedDoc = documents.at(index);
    if (removedDoc && !removedDoc.new) {
      const generatedName = removedDoc.url.split("/").pop();
      props.setRemovedDocuments([...props.removedDocuments, generatedName]);
    }
    documents.splice(index, 1);
    props.setOtherDocuments([...documents]);
  }

  function closeDiaProjCostingSummary() {
    setOpenDiaProjCostingSummary(false);
  }

  function onPrintSuccess(response) {
    if (response?.status === 200 && response?.data?.url) {
      const link = document.createElement("a");
      link.setAttribute("href", response?.data?.url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", response?.data?.fileName);
      link.click();
    }
  }

  const downloadProjectCostingSummaryReport = async () => {
    onPrintSuccess(
      await sendRequest(
        `/v1/project-request/generate-report-costing-summary/${props.projectRequest._id}`,
        "GET",
      ),
    );
  };

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress
            style={{ zIndex: 9999 }}
            color={"inherit"}
            size={150}
          />
        </Backdrop>
      )}
      <input
        hidden
        type="file"
        id="document-upload"
        accept={props.allowedFileTypes ?? "*"}
        onChange={(e) => onAddDocuments(e)}
        multiple
      />
      <Grid container className={"form"} id={PR_PANELS.DOCUMENTS}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid
              item
              xs={12}
              className={"sub-action"}
            >
              <Typography variant={"h2"}>Other Documents</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={"table"}>
              <DataTable
                fixedHeader={true}
                persistTableHead={true}
                columns={columns}
                defaultSortAsc={false}
                //defaultSortFieldId={4}
                data={props?.otherDocuments}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        open={openDiaProjCostingSummary}
        maxWidth="md"
        onClose={closeDiaProjCostingSummary}
      >
        <DialogContent id="projectCostingSummary">
          <ProjectCostingSummary
            signPic={signPic}
            setSignPic={setSignPic}
            projectRequest={props.projectRequest}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="btnProceed"
            onClick={() => downloadProjectCostingSummaryReport()}
            color="primary"
            disabled={isLoading}
          >
            Print
          </Button>
          <Button
            id="btnCancel"
            onClick={closeDiaProjCostingSummary}
            color="primary"
          >
            X
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WorkflowDocuments;
