import { withRouter } from "react-router-dom";
import { useEffect, useMemo, useState, useRef } from "react";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, InputAdornment, Autocomplete, IconButton
} from "@mui/material";
import {
    Search as SearchIcon, KeyboardArrowDown as ArrowDownIcon,
    PlaylistPlay as FilterIcon, ArrowBack as BackIcon, Clear as ClearIcon,
    Event as CalendarIcon
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import useStyles from "./styles";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
    setRighbarContent, toggleRightbar, useLayoutDispatch,
    useLayoutState
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { bracketsTableColumnConfig } from "./data-table/brackets-table-column-config";
import moment from "moment";
import MobileBracketFilters from "./components/Mobile/MobileBracketFilters";
import downloadCSV from "../../helper/download-csv";
import Swal from "sweetalert2";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import { PrivilegeModules,PrivilegeActions } from "../../data/privileges.enum";
import DateFilter from "../../components/DateFilter";

const BracketList = (props) => {
    const classes = useStyles();

    // handle loading & http
    const { isLoading, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();
    
    // layout state
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { width } = useWindowDimensions();

    // filters
    const [textFilter, setTextFilter] = useState("");
    const [welderFilter, setWelderFilter] = useState(null);
    const [fromFilter, setFromFilter] = useState(null);
    const [toFilter, setToFilter] = useState(null);
    const [issuedFilter, setIssuedFilter] = useState(null);
    const [sortBy, setSortBy] = useState({ column: undefined, direction: undefined });

    const [refresh, setRefresh] = useState(false); // refresh list

    // pagination states
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collectionSize, setCollectionSize] = useState(10);

    const [bracketList, setBracketList] = useState([]);
    const [welderList, setWelderList] = useState([]);

    const smallDeviceWidth = 1200;
    const issuedList = ['Yes', 'No'];

    useEffect(() => {
        document.title = "Bracket List";
        loadWelders();

        // redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.view_list)) {
            props.history.push('/app/dashboard');
        }        
    }, []);

    // set filtered data
    useEffect(() => { if (refresh) { filter(); setRefresh(false); } }, [refresh]);

    useEffect(() => { setRefresh(true); },
        [welderFilter, fromFilter, toFilter, issuedFilter]);

    useEffect(() => {
        if (!textFilter) {
            setRefresh(true);
        }
    }, [textFilter]);

    // populate welder list 
    function loadWelders() {
        // load welder list
        sendRequest(`/v1/user/get-list-by-role/option_welder`, "GET", {})
            .then(response => {
                const list = response.data?.users || [];
                setWelderList(list.filter(i => i.status));
            });
    }

    function filter() {
        // set list filters
        getPaginatedData(page, pageSize, getFilterValues(), sortBy)
            .then(response => {
                const brackets = response.data?.brackets || [];
                setBracketList(brackets);
                setCollectionSize(response.data?.total);
                return () => {
                    return brackets;
                };
            });
    }

    function getFilterValues() {
        return {
            text: textFilter.trim(),
            welder: welderFilter ? welderFilter._id : undefined,
            from: fromFilter ? moment(fromFilter).format('YYYY-MM-DD') : undefined,
            to: toFilter ? moment(toFilter).format('YYYY-MM-DD') : undefined,
            issued: issuedFilter ? (issuedFilter === 'Yes') : undefined
        };
    }

    function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
        return sendRequest(
            `/v1/bracket/filter?filters=${JSON.stringify(filters)}&page=${page}&pageSize=${pageSize}
        ${(sortBy?.column && sortBy?.direction) ?
                ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {});
    }


    function getFilterValues() {
        return {
            text: textFilter,
            welder: welderFilter ? welderFilter._id : undefined,
            from: fromFilter ? getFromDate(fromFilter) : undefined,
            to: toFilter ? getToDate(toFilter) : undefined,
            issued: issuedFilter ? (issuedFilter === 'Yes') : undefined
        };
    }

    function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
        return sendRequest(
            `/v1/bracket/filter?filters=${JSON.stringify(filters)}&page=${page}&pageSize=${pageSize}
        ${(sortBy?.column && sortBy?.direction) ?
                ('&orderBy=' + sortBy?.column + '&direction=' + sortBy?.direction) : ''}`,
            "GET", {});
    }

    // set columns in data table
    const columns = bracketsTableColumnConfig({ 
        seeMore: auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.see_more_columns),
        allowedViewDetail: auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.view_detail)
    });
    const mobileFilterProps = {
        welderList,
        welderFilter, setWelderFilter,
        fromFilter, setFromFilter,
        toFilter, setToFilter,
        issuedList, issuedFilter, setIssuedFilter
    };

    useEffect(() => {
        if (width <= smallDeviceWidth) { // set filter right bar
            setRighbarContent(layoutDispatch,
                [<Typography variant={"h2"} key={"rb-header"}>Filter</Typography>, // header text
                <MobileBracketFilters key={"rb-body"} {...mobileFilterProps} />],
                { size: 'xs', backButton: true }); // filter content
        }
    }, [bracketList, welderFilter, fromFilter, toFilter, issuedFilter, width]);

    function sortByColumn(columnConfig, sortDirection) {
        if (columnConfig.sortField && sortDirection) {
            setSortBy({
                column: columnConfig.sortField,
                direction: sortDirection
            });
            setRefresh(true);
        }
    }

    // generate and download csv report
    async function downloadReport() {
        const csvData = [];
        const response = await getPaginatedData(1, 0,
            getFilterValues(), sortBy);
        // only issued brackets will be in the report
        const data = response.data?.brackets || [];
        const brackets = data;
        if (brackets.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: "Warning",
                text: "No data to export.",
            });
            return;
        }
        for (const bracket of brackets) {
            // bracket record
            csvData.push({
                serialNo: bracket.serialNo,
                bracketType: bracket.bracketType?.name,
                batchNos: bracket.batchNos?.toString(),
                createdDate: bracket.createdAt ?
                    moment(bracket.createdAt).format('DD/MM/YYYY HH:mm:ss') : '',
                welder: bracket.welder ? (`${bracket.welder.username}-${bracket.welder.name}`) : '',
                pkrNo: bracket.pkrNo,
                shipToCustomerName: bracket.shipToCustomerName
                    ? (`${bracket.shipToCustomerName} (${bracket.shipToCustomerName})`) : '',
                shipToContact: bracket.shipToContact,
                address: bracket.address ? bracket.address.trim() : '',
                customerNo: bracket.customerNo,
                billToCustomerName: bracket.billToCustomerName,
                billToContact: bracket.billToContact,
                modelNos: bracket.modelNos?.toString(),
                teamNo: bracket.teamNo
            });
        }
        const today = moment().format('YYYYMMDD_HHmmss')
        const filename = `BRACKET_LIST_REPORT_${today}.csv`;
        // column names
        const columns = [
            "Serial No.",
            "Bracket Type",
            "Batch No.",
            "Create Date",
            "Welder",
            "Document No.",
            "Ship-to Customer Name",
            "Ship-to Contact",
            "Ship-to Address",
            "Customer No.",
            "Bill-to Customer Name",
            "Bill-to Contact",
            "Model No.",
            "Team No."
        ];
        downloadCSV(csvData, filename, columns);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value) { // perform search when Enter
            e.preventDefault();
            setRefresh(true);
        }
    };

    function handlePageChanged(page) {
        setPage(page);
        setRefresh(true);
    }

    function handleRowsPerPageChanged(count) {
        setPageSize(count);
        setRefresh(true);
    }

    return (<>
        {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} /></Backdrop>}
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant={"h1"}>Bracket List</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                {auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.add) && <Button className={"primary"}
                    onClick={() => props.history.push('card')}>New</Button>}
                {auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.quality_check) && <Button className={"primary"}
                    onClick={() => props.history.push('quality-check')}>Quality Check</Button>}
                {auth.checkModulePrivilege(PrivilegeModules.bracket, PrivilegeActions.download_report)
                    && <Button className={"primary"} onClick={() => downloadReport()}>Download Report</Button>}
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12}>
                <Grid className={"form"}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={10} sm={10} md={10} lg={2}>
                            <TextField
                                autoComplete={"off"}
                                id={"text-searchbox"}
                                variant={"outlined"}
                                value={textFilter}
                                onChange={(e) => setTextFilter(e.target.value)}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={"start"}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }} />
                        </Grid>
                        {
                            width >= smallDeviceWidth ? (<>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                    <Autocomplete
                                        id={"welder-filter"}
                                        options={welderList}
                                        getOptionLabel={(option) => `${option.username}-${option.name}`}
                                        value={welderFilter}
                                        onChange={(e, newValue) => setWelderFilter(newValue)}
                                        renderInput={(params) => <TextField {...params}
                                            label={"Welder"} variant={"outlined"} />}
                                        popupIcon={<ArrowDownIcon />} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                    <Autocomplete
                                        id={"issued-filter"}
                                        options={issuedList}
                                        getOptionLabel={(option) => option.toUpperCase()}
                                        value={issuedFilter}
                                        onChange={(e, newValue) => setIssuedFilter(newValue)}
                                        renderInput={(params) => <TextField {...params} label={"Issued"} variant={"outlined"} />}
                                        popupIcon={<ArrowDownIcon />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                  <DateFilter
                                    label={"From"}
                                    value={fromFilter}
                                    maxDate={toFilter}
                                    onChange={(newValue) => setFromFilter(newValue)}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                  <DateFilter
                                    label={"To"}
                                    value={toFilter}
                                    minDate={fromFilter}
                                    onChange={(newValue) => setToFilter(newValue)}
                                  />
                                </Grid>
                            </>) :
                                (<Grid item xs={2}>
                                    <IconButton
                                        onClick={() => toggleRightbar(layoutDispatch)}
                                        className={classes.drawerToggleBtn}>
                                        {layoutState.isRightbarOpened ? (<BackIcon />)
                                            : (<FilterIcon className={classes.toggleRightBarIcon} />)}
                                    </IconButton>
                                </Grid>)
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DataTable
                                fixedHeader={true}
                                persistTableHead={true}
                                columns={columns}
                                data={bracketList}
                                sortServer
                                onSort={sortByColumn}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationPerPage={pageSize}
                                paginationTotalRows={collectionSize}
                                onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
                                onChangePage={(page) => handlePageChanged(page)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default withRouter(BracketList);