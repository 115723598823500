import Joi from "joi";

export const individualItemSchema = Joi.object({
  _id: Joi.string().required().label("Item ID"),
  sku: Joi.string().required().label("SKU"),
  description: Joi.string().required().label("Description"),
  uom: Joi.string().required().label("UOM"),
  price: Joi.number().positive().required().label("Unit Price"),
  stores: Joi.array()
    .items(
      Joi.object({
        _id: Joi.string(),
        storeCode: Joi.string(),
      })
        .or("_id", "storeCode")
        .required(),
    )
    .required()
    .label("F&B Stores"),
  isDeleted: Joi.boolean().optional(),
  isNew: Joi.boolean().optional(),
});

export const fnbSupplierSchema = Joi.object({
  vendorCode: Joi.string().required().label("Vendor Code"),
  name: Joi.string().required().label("Supplier Name"),
  address: Joi.string().optional().label("Supplier Address"),
  contactName: Joi.string().required().min(1).max(200).label("Contact Name"),
  contactNumber: Joi.string()
    .required()
    .pattern(new RegExp(/^(6|8|9)\d{7}$/))
    .label("Mobile No."),
  contactEmails: Joi.array()
    .items(
      Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
    )
    .required().label("Emails"),
  stores: Joi.array()
    .items(
      Joi.object({
        _id: Joi.string(),
        storeCode: Joi.string(),
      })
        .or("_id", "storeCode")
        .required(),
    )
    .required()
    .label("F&B Stores"),

  items: Joi.array()
    .items(individualItemSchema)
    .empty()
    .label("Supplier Items"),

  createdAt: Joi.date().optional(),
  updatedAt: Joi.date().optional(),
});
