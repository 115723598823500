import React, { useState } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyboardArrowDown, Search } from "@mui/icons-material";
import DateFilter from "../../../components/DateFilter";

export default function AirconFilterMobile({
  subscriptionFromDate,
  toRequestDate,
  setFromRequestDate,
  setToRequestDate,
  preferredOutletList,
  preferredOutletFilter,
  setPreferredOutletFilter,
}) {
  const [fromOpen, setFromOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);

  const toggleFromOpen = () => setFromOpen(!fromOpen);
  const toggleToOpen = () => setToOpen(!toOpen);

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            label="Subscription Date From"
            value={subscriptionFromDate}
            onChange={(newValue) => setFromRequestDate(newValue)}
            maxDate={toRequestDate}
            onOpen={toggleFromOpen}
            onClose={toggleFromOpen}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            label="Subscription Date To"
            value={toRequestDate}
            onChange={(newValue) => setToRequestDate(newValue)}
            minDate={subscriptionFromDate}
            onOpen={toggleToOpen}
            onClose={toggleToOpen}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            id={"preferred-outlet-filter-mobile"}
            options={preferredOutletList}
            getOptionLabel={(item) => item.option.toUpperCase()}
            value={preferredOutletFilter}
            onChange={(e, newValue) => setPreferredOutletFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Preferred Outlet"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
      </Grid>
    </>
  );
}
