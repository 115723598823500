import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";

const MachineCompanyCard = (props) => {
  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const { companyId } = useParams(); // url param => companyId when editing

  // un-assigned sub-con list
  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [currentCompany, setCurrentCompany] = useState(null);
  const [rowsInvalid, setRowsInvalid] = useState(false);
  const [enableSwitchValue, setEnableSwitchValue] = useState(true); // enable switch => default true

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
    reset,
  } = useForm({ mode: "all" });

  useEffect(() => {
    document.title = "New - Machine Company Card";

    // redirect to default page if not authorized
    if (
      companyId &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.machine_company,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  // get current matrix in edit view
  useEffect(() => {
    if (!isLoading && !responseData && companyId) {
      sendRequest(`/v1/machine-company/get-company-by-id/` + companyId, "GET");
    }
  }, [companyId]);

  // set form values when edit view
  useEffect(() => {
    if (companyId && responseData?.company) {
      const company = responseData?.company;
      document.title = `${company.name} - Machine Company Card Card`;
      setCurrentCompany(company);
    }
  }, [responseData]);

  useEffect(() => {
    if (currentCompany) {
      reset(currentCompany);
      setEnableSwitchValue(currentCompany.enable);
    }
  }, [currentCompany]);

  const navigateToList = () => {
    if (!companyId) window.location.href = "#/app/machine-company/list";
  };

  const handleSubmitForm = async (data, e) => {
    e.preventDefault();

    const company = {
      name: data.name.toUpperCase(),
      enable: data.enable,
    };

    let savedCompany = undefined,
      message = "";
    if (currentCompany && companyId) {
      // when edit
      company._id = currentCompany._id;
      const result = await sendRequest(
        `/v1/machine-company/${companyId}`,
        "PUT",
        company,
      );
      if (result?.status === 200 && result?.data?.company) {
        message = `Machine company ${result.data.company.name} has been updated.`;
        savedCompany = result?.data?.company;
      }
    } else {
      const result = await sendRequest(`/v1/machine-company`, "POST", company);
      if (result?.status === 201 && result?.data?.company) {
        message = `New machine company ${result.data.company.name} has been created.`;
        savedCompany = result?.data?.company;
      }
    }

    setMessage(message);
  };

  return (
    <>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                Machine Company Card
                {currentCompany ? ` - ${currentCompany.name}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button
                onClick={() => props.history.push("/app/machine-company/list")}
              >
                Cancel
              </Button>
              {((!currentCompany &&
                !companyId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.machine_company,
                  PrivilegeActions.add,
                )) ||
                (currentCompany &&
                  companyId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.machine_company,
                    PrivilegeActions.edit,
                  )) ||
                (currentCompany &&
                  companyId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.machine_company,
                    PrivilegeActions.menu_update,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  Save
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"name"}
                            label={"Name"}
                            variant={"outlined"}
                            {...register("name", {
                              required: {
                                value: true,
                                message: "Name cannot be blank.",
                              },
                            })}
                            disabled={
                              currentCompany &&
                              companyId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.machine_company,
                                PrivilegeActions.edit,
                              )
                            }
                            autoComplete={"off"}
                            error={!!formErrors?.name}
                            helperText={formErrors?.name?.message}
                            onChange={(name) => validateText(name)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            disabled={
                              currentCompany &&
                              companyId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.machine_company,
                                PrivilegeActions.edit,
                              )
                            }
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("enable")}
                                checked={enableSwitchValue}
                                onChange={(e) =>
                                  setEnableSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Enable"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
};

export default withRouter(MachineCompanyCard);
