import React from "react";
import { Grid } from "@mui/material";
import useStyles from "../../styles";
import DateFilter from "../../../../components/DateFilter";

export default function MobileBracketRequestFilters({
    statusList,
    statusFilter, setStatusFilter,
    fromFilter, setFromFilter,
    toFilter, setToFilter
}) {
    const classes = useStyles();
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"status-filter-mobile"}
                    options={statusList}
                    getOptionLabel={(option) => option}
                    value={statusFilter}
                    onChange={(e, newValue) => setStatusFilter(newValue)}
                    renderInput={(params) => <TextField {...params}
                        label={"Status"} variant={"outlined"} />}
                    popupIcon={<ArrowDownIcon />} />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateFilter
                label={"From"}
                value={fromFilter}
                maxDate={toFilter}
                onChange={(newValue) => setFromFilter(newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateFilter
                label={"To"}
                value={toFilter}
                minDate={fromFilter}
                onChange={(newValue) => setToFilter(newValue)}
              />
            </Grid>
        </Grid>
    </>);
};
