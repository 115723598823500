import React from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyboardArrowDown } from '@mui/icons-material';

export default function MobileUserListFilters({
    userRoleList,
    enableDisableList,
    userRoleFilter,
    enableFilter,
    setUserRoleFilter,
    setEnableFilter
}) {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"user-role-filter-mobile"}
                    options={userRoleList}
                    getOptionLabel={(option) => option}
                    value={userRoleFilter}
                    onChange={(e, newValue) => setUserRoleFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"User Role"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"enable-filter-mobile"}
                    options={enableDisableList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={enableFilter}
                    onChange={(e, newValue) => setEnableFilter(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Enable"} variant={"outlined"} />}
                    popupIcon={<KeyboardArrowDown />}
                />
            </Grid>
        </Grid>
    </>);
};
