import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    drawerToggleBtn: {
        marginTop: 10
    },
    toggleRightBarIcon: {
        fontSize: 28
    },
    rejectModalTitle: {
        textAlign: 'center'
    },
    rejectModalFooter: {
        display: 'block !important'
    },
    section: { marginBottom: 30 },
    bb: { borderBottom: 0 },
    w10: { width: '10%' },
    w20: { width: '20%' },
    w30: { width: '30%' },
    link: { color: 'rgba(112, 102, 224, 1) !important' }
}));