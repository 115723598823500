import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";
import {
  Search as SearchIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  KeyboardArrowDown,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  useLayoutDispatch,
  useLayoutState,
  toggleRightbar,
  setRighbarContent,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import MobileMachineCompanyFilters from "./components/Mobile/MobileMachineCompanyFilters";
import { machineCompanyTableColumnConfig } from "./data-table/machine-company-table-column-config";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";

const MachineCompanyList = (props) => {
  const classes = useStyles();

  // handle loading & http
  const { isLoading, sendRequest } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  // layout state
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  // filters
  const [textFilter, setTextFilter] = useState("");
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  const [companyList, setCompanyList] = useState([]);
  const smallDeviceWidth = 1200;

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  const [message, setMessage] = useState("");
  const yesNoList = ["Yes", "No"];
  const [enableFilter, setEnableFilter] = useState(null);
  const mobileFilterProps = {
    enableFilter,
    setEnableFilter,
    yesNoList,
  };
  const [loadMobileFilters, setLoadMobileFilters] = useState(false);

  useEffect(() => {
    document.title = "Machine Company List";

    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.machine_company,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: setRefresh(true),
      });
    }
  }, [message]);

  // filter data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    setRefresh(true);
  }, [enableFilter]);

  useEffect(() => {
    if (width <= smallDeviceWidth) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MobileMachineCompanyFilters
            key={"rb-body"}
            {...mobileFilterProps}
          />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [companyList, width, loadMobileFilters]);

  // set columns in data table
  const columns = machineCompanyTableColumnConfig(
    {
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.machine_company,
        PrivilegeActions.view_detail,
      ),
      allowedDelete: auth.checkModulePrivilege(
        PrivilegeModules.machine_company,
        PrivilegeActions.delete,
      ),
      allowedEnableDisable: auth.checkModulePrivilege(
        PrivilegeModules.machine_company,
        PrivilegeActions.edit,
      ),
    },
    handleNameClick,
    handleDeleteMachineCompany,
    handleEditMachineCompany,
  );

  async function handleEditMachineCompany(
    company,
    toggle = false,
    toggleValue = false,
  ) {
    if (toggle) {
      company.enable = toggleValue; // set new enable value & update
      let tmpCompany = JSON.parse(JSON.stringify(company));
      await sendRequest(
        `/v1/machine-company/${company._id}`,
        "PUT",
        tmpCompany,
      );
    } else {
      props.history.push(`card/${company._id}`);
    }
  }

  function handleDeleteMachineCompany(company) {
    if (company) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to delete the company ${company.name}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await sendRequest(
            `/v1/machine-company/${company._id}`,
            "DELETE",
          );
          if (result?.status === 200 && result?.data?.deleted) {
            setMessage(`Company ${company.name} has been deleted.`);
          }
        }
      });
    }
  }

  function filter() {
    // set list filters
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const companies = response.data?.companyList || [];
        setCompanyList(companies);
        setCollectionSize(response.data?.total);
        return () => {
          return companies;
        };
      },
    );
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      enable: enableFilter,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/machine-company/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${
          sortBy?.column && sortBy?.direction
            ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
            : ""
        }`,
      "GET",
      {},
    );
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  // go to edit view
  async function handleNameClick(companyId) {
    if (
      auth.checkModulePrivilege(
        PrivilegeModules.machine_company,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push(`card/${companyId}`);
    }
  }

  // open mobile filters drawer
  function openMobileFilters() {
    setLoadMobileFilters(true);
    toggleRightbar(layoutDispatch);
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Machine Company List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.machine_company,
            PrivilegeActions.add,
          ) && (
            <Button
              className={"primary"}
              onClick={() => props.history.push("card")}
            >
              New
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={10} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-searchbox"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {width >= smallDeviceWidth ? (
                <>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"enable-filter"}
                      options={yesNoList}
                      getOptionLabel={(option) => option.toUpperCase()}
                      value={enableFilter}
                      onChange={(e, newValue) => setEnableFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Enable"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => openMobileFilters()}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <BackIcon />
                    ) : (
                      <FilterIcon className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={companyList}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(MachineCompanyList);
