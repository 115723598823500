import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop, Typography, CircularProgress, Grid,
  TextField, Button, Switch, InputAdornment, FormControlLabel, Autocomplete
} from "@mui/material";
import Swal from "sweetalert2";
import { Clear, KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";

import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const MaterialCard = (props) => {
  // user info & permissions
  const { auth } = useRoleAuthorization();

  // handle loading & http
  const { isLoading, httpRequestError: error,
    responseData, sendRequest: formSubmitCaller, sendRequest: getItemByIdCaller } = useHttpRequest();
  const [materialGroups, setMaterialGroups] = useState([]) 
  const [currentMaterialItem, setCurrentMaterialItem] = useState(); // current material object for edit
  const [message, setMessage] = useState(""); // handle swal message
  const [enableSwitchValue, setEnableSwitchValue] = useState(true); // enable switch => default true

  const { itemId } = useParams(); // url param => item id when editing

  // handling form when adding / editing
  const form = useForm({ mode: "all", defaultValues: currentMaterialItem });
  const { register, handleSubmit, formState: { errors: formErrors }, setValue, getValues, control, watch, trigger  } = form

  // set title when adding material
  useEffect(() => { 
    document.title = "New - Material Card"; 
    // redirect to default page if not authorized
    if (itemId && auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.material, PrivilegeActions.view_detail)) {
      props.history.push('/app/dashboard');
    }

    formSubmitCaller(`/v1/material-group/filter?filters={"enable":true}`, "GET", {})
    .then(response => {
      setMaterialGroups(response.data?.materialGroupList || []);
    });

    
  }, []);

  // get current material when editing 
  useEffect(() => {
    if (!isLoading && !responseData && itemId) {
      getItemByIdCaller(`/v1/material-item/` + itemId, 'GET');
    }
  }, [itemId]);

  // set title & form values with current material item when editing
  useEffect(() => {
    if (itemId && responseData?.item) {
      const item = responseData?.item;
      document.title = `${item.code} - Material Card`;
      setCurrentMaterialItem(item);
      setValue('code', item.code || '');
      setValue('description', item.description || '');
      setValue('category', item.category || '');
      setValue('price', item.price || '');
      setValue('model', item.model || '');
      setValue('unit', item.unit || '');
      setValue('enable', item.enable);
      setValue('withdrawalGroups', item.withdrawalGroups || []) 
      setEnableSwitchValue(item.enable); // set switch value by force
    }
  }, [responseData])

  // show feedback message after add / edit
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: 'success',
        title: "Success",
        text: message,
        willClose: navigateToList
      });
    }
  }, [message, responseData]);

  // show error when error occured
  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: responseData?.message
      });
    }
  }, [error]);

  // form submit
  const handleSubmitForm = async (data) => {
    const materialItem = {
      code: data.code.trim().toUpperCase(),
      unit: data.unit.trim().toUpperCase(),
      description: data.description.trim().toUpperCase(),
      price: Number(data.price),
      category: data.category.trim().toUpperCase(),
      model: data.model.trim().toUpperCase(),
      enable: data.enable,
      brand: "",
      division: "",
      productGroup: "",
      withdrawalGroups: data.withdrawalGroups
    };
    let result = null;
    // check whether add or edit attempt  
    if (currentMaterialItem && currentMaterialItem._id) {
      materialItem._id = currentMaterialItem._id; // set _id before edit
      result = await formSubmitCaller(`/v1/material-item/${materialItem._id}`, "PUT", materialItem);
      if (result?.status === 200 && result?.data?.item) {
        setMessage(`Material ${materialItem.code} has been updated.`); // call show feedback message
      }
    } else {
      result = await formSubmitCaller('/v1/material-item', "POST", materialItem);
      if (result?.status === 200 && result?.data?.item) {
        setMessage(`New material ${materialItem.code} has been created.`); // call show feedback message
      }
    }
  };

  // navigate back to material list
  const navigateToList = () => {
    if (!itemId)
      window.location.href = '#/app/material/list';
  }

  return (<>
    {isLoading && <Backdrop style={{ zIndex: 1 }} open={isLoading}><CircularProgress color={"inherit"} /></Backdrop>}
    {(!itemId || (itemId && currentMaterialItem)) && <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Material Card {currentMaterialItem ? ` - ${currentMaterialItem.code}` : ` - New`}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          <Button onClick={() => props.history.push('/app/material/list')}>Cancel</Button>
          {
            ((!itemId && auth.checkModulePrivilege(PrivilegeModules.material, PrivilegeActions.add)) || (itemId && auth.checkModulePrivilege(PrivilegeModules.material, PrivilegeActions.edit)))
            &&
            <Button className={"primary"} type={"submit"}>Save</Button>
          }
        </Grid>
        <Grid
          item xs={12} sm={12} md={12} lg={12}>
          <Grid container className={"form"}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Typography variant={"h2"}>General</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <TextField id={"code"} label={"Code"} variant={"outlined"}
                        {...register("code", {
                          required: { value: true, message: "Code cannot be blank." },
                          maxLength: { value: 20, message: "Maximum 20 characters are allowed." },
                          minLength: { value: 3, message: "Minimum 3 characters should be in the code." }
                        })}
                        autoComplete={"off"}
                        error={!!formErrors?.code}
                        helperText={formErrors?.code?.message}
                        onChange={(event) => validateText(event)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <TextField id={"description"} label={"Description"} variant={"outlined"}
                        {...register("description", {
                          required: { value: true, message: "Description cannot be blank." },
                          maxLength: { value: 60, message: "Maximum 60 characters are allowed." }
                        })}
                        autoComplete={"off"}
                        error={!!formErrors?.description}
                        helperText={formErrors?.description?.message}
                        onChange={(event) => validateText(event)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <TextField id={"category"} label={"Category"} variant={"outlined"}
                        {...register("category", {
                          required: { value: true, message: "Category cannot be blank." },
                          maxLength: { value: 30, message: "Maximum 30 characters are allowed." }
                        })}
                        autoComplete={"off"}
                        error={!!formErrors?.category}
                        helperText={formErrors?.category?.message}
                        onChange={(event) => validateText(event)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <TextField id={"model"} label={"Model"} variant={"outlined"}
                        {...register("model", {
                          maxLength: { value: 50, message: "Maximum 50 characters are allowed." }
                        })}
                        autoComplete={"off"}
                        error={!!formErrors?.model}
                        helperText={formErrors?.model?.message}
                        onChange={(event) => validateText(event)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <TextField id={"price"} label={"Unit Price"} variant={"outlined"} type={"double"}
                        {...register("price", {
                          required: { value: true, message: "Unit Price cannot be blank." },
                          pattern: { value: /^\d*\.?\d*$/, message: "Unit Price may contain only numbers (0-9)." }
                        })}
                        autoComplete={"off"}
                        error={!!formErrors?.price}
                        helperText={formErrors?.price?.message}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <TextField id={"unit"} label={"UOM"} variant={"outlined"}
                        {...register("unit", {
                          required: { value: true, message: "UOM cannot be blank." },
                          maxLength: { value: 10, message: "Maximum 10 characters are allowed." }
                        })}
                        autoComplete={"off"}
                        error={!!formErrors?.unit}
                        helperText={formErrors?.unit?.message}
                        onChange={(event) => validateText(event)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                      <Controller
                        name={`withdrawalGroups`}
                        control={control}
                        render={(props) => (
                          <Autocomplete
                            id={"withdrawal-groups"}
                            multiple
                            options={materialGroups}
                            getOptionLabel={(option) => option.name}
                            value={getValues('withdrawalGroups') || []}
                            renderInput={(params) =>
                              <TextField
                                  {...params}
                                  label={"Withdrawal Group"}
                                  variant={"outlined"}
                                  error={!!props?.formState?.errors?.withdrawalGroups}
                                  helperText={props?.formState?.errors?.withdrawalGroups?.message}
                                  autoComplete="off"
                              />
                            }
                            onChange={(event, data) => {
                              props?.field.onChange(data);
                            }}
                            popupIcon={<ArrowDownIcon />}
                            ChipProps={{ deleteIcon: <Clear /> }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Switch
                          {...register("enable")}
                          checked={enableSwitchValue}
                          onChange={(e) => setEnableSwitchValue(e.target.checked)} />}
                        label="Enable" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
    }
  </>
  );
};

export default MaterialCard;