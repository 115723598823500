import React from 'react';
import { Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { KeyboardArrowDown } from '@mui/icons-material';

export default function MobileProjectFilters({
  companyList,
  companyFilterValue,
  setCompanyFilterValue,
  projectGroupList,
  enableDisableList,
  projectGroupFilter,
  setProjectGroupFilter,
  enableFilter,
  setEnableFilter,
}) {
  return (<>
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          id={'project-group-filter-mobile'}
          options={projectGroupList}
          getOptionLabel={(option) => option}
          value={projectGroupFilter}
          onChange={(e, newValue) => setProjectGroupFilter(newValue)}
          renderInput={(params) =>
            <TextField {...params} label={'Project Group'} variant={'outlined'} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          options={companyList}
          getOptionLabel={(option) => option}
          value={companyFilterValue}
          onChange={(e, newValue) => setCompanyFilterValue(newValue)}
          renderInput={(params) =>
            <TextField {...params} label={'Company'} variant={'outlined'} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          id={'enable-filter-mobile'}
          options={enableDisableList}
          getOptionLabel={(option) => option.toUpperCase()}
          value={enableFilter}
          onChange={(e, newValue) => setEnableFilter(newValue)}
          renderInput={(params) =>
            <TextField {...params} label={'Enable'} variant={'outlined'} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>
    </Grid>
  </>);
};
