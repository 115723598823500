import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import { useHttpRequest } from "../hooks/useHttpRequest";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {...state, isAuthenticated: true};
    case "SET_USER_INFO":
      if (JSON.stringify(state.userInfo) === JSON.stringify(action.payload)) {
        return state;
      }
      return {...state, userInfo: action.payload}
    case "SIGN_OUT_SUCCESS":
      return {...state, isAuthenticated: false, userInfo: {}};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({children}) {
  const { sendRequest: getUserInfoAPI } = useHttpRequest();
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
    userInfo: JSON.parse(localStorage.getItem("user_info") ?? '{}')
  });

  useEffect(() => {
    if (state.isAuthenticated) {
      getUserInfoAPI("/v1/user/me").then(responseData => {
        let userData = Object.assign({}, responseData.data.user);
        userData.privileges = responseData.data.userRolePrivileges.privileges;
        userData.defaultUrl = responseData.data.userRolePrivileges.defaultUrl;

        dispatch({ type: "SET_USER_INFO", payload: userData });
        localStorage.setItem('user_info', JSON.stringify(userData));
        return responseData;
      }).catch((e) => {
        Swal.fire({
          icon: 'warning',
          title: e.response.statusText,
          text: e.response.data.message,
          willClose(popup) {
            dispatch({ type: "SIGN_OUT_SUCCESS" });
            // window.location.href = '/';
          }
        })
      })
    }
  }, [getUserInfoAPI, state.isAuthenticated]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {UserProvider, useUserState, useUserDispatch, loginUser, signOut};

// ###########################################################

async function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {

    setTimeout(() => {
    }, 2000);
  } else {
    dispatch({type: "LOGIN_FAILURE"});
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) { 
  localStorage.removeItem("id_token");
  localStorage.removeItem("user_info");
  dispatch({type: "SIGN_OUT_SUCCESS"});
  if(history) {
    history.push("/login");
  } else {
    window.location.href = '/'
  }
}
