export const PrivilegeModules = {
  material_request: "material_request",
  bracket_request: "bracket_request",
  customer_existing_aircon: "customer_existing_aircon",
  material: "material",
  bracket_batch: "bracket_batch",
  bracket: "bracket",
  bracket_quality_check: "bracket_quality_check",
  service_claim: "service_claim",
  manage_service_claim_matrix: "manage_service_claim_matrix",
  service_claim_report: "service_claim_report",
  project: "project",
  user: "user",
  role: "role",
  task_tracking: "task_tracking",
  department: "department",
  project_request: "project_request",
  pay_service_claim: "pay_service_claim",
  installation_claim: "installation_claim",
  manage_installation_claim_matrix: "manage_installation_claim_matrix",
  fnb_event_menu: "fnb_event_menu",
  fnb_event_booking: "fnb_event_booking",
  installation_claim_report: "installation_claim_report",
  fnb_vip_menu_item: "fnb_vip_menu_item",
  fnb_food: "fnb_food",
  fnb_store: "fnb_store",
  fnb_order: "fnb_order",
  fnb_store_event: "fnb_store_event",
  fnb_report: "fnb_report",
  fnb_voucher_setup: "fnb_voucher_setup",
  machine_company: "machine_company",
  machine_store: "machine_store",
  machine_service_job: "machine_service_job",
  group_buy_calendar: "group_buy_calendar",
  group_buy_booking: "group_buy_booking",
  group_buy_attributes: "group_buy_attributes",
  activity_log: "activity_log",
  aircon_subscription_attribute: "aircon_subscription_attribute",
  aircon_subscription_matrix: "aircon_subscription_matrix",
  aircon_subscription: "aircon_subscription",

  fnb_supplier_order: "fnb_supplier_order",
  car_wash_request: "car_wash_request",
  car_wash_setup: "car_wash_setup",
};

export const PrivilegeActions = {
  alert_awarded: "alert_awarded",
  download_documents: "download_documents",
  view_list: "view_list",
  view_detail: "view_detail",
  add: "add",
  edit: "edit",
  delete: "delete",
  default: "default",
  download_report: "download_report",
  push_to_nav: "push_to_nav",
  fulfil: "fulfil",
  approve: "approve",
  quality_check: "quality_check",
  see_more_columns: "see_more_columns",
  revise: "revise",
  enable_disable: "enable_disable",
  refresh: "refresh",
  reset_2fa: "reset_2fa",
  show_team_filter: "show_team_filter",
  role_get_list: "role_get_list",
  view_list_own_entry: "view_list_own_entry",
  view_list_status_pending: "view_list_status_pending",
  view_list_status_approved: "view_list_status_approved",
  view_list_status_closed: "view_list_status_closed",
  view_list_assigned_proj: "view_list_assigned_proj",
  proj_get_list_by_group: "proj_get_list_by_group",
  material_get_list: "material_get_list",
  bracket_get_list_by_srl_no: "bracket_get_list_by_srl_no",
  bracket_batch_get_list: "bracket_batch_get_list",
  filter_status_pending: "filter_status_pending",
  filter_status_approved: "filter_status_approved",
  filter_status_paid: "filter_status_paid",
  filter_status_closed: "filter_status_closed",
  view_list_project: "view_list_project",
  need_approval: "need_approval",
  get_user_from_same_department: "get_user_from_same_department",
  show_staff_filter: "show_staff_filter",
  filter_posted_yes: "filter_posted_yes",
  filter_posted_no: "filter_posted_no",
  filter_downloaded_yes: "filter_downloaded_yes",
  filter_downloaded_no: "filter_downloaded_no",
  download_report_approved_only: "download_report_approved_only",
  void_claim_report: "void_claim_report",
  revert_pks_claim: "revert_pks_claim",
  is_manager: "is_manager",
  is_ec: "is_ec",
  is_observer: "is_observer",
  submit: "submit",
  award: "award",
  decline: "decline",
  edit_payment_schedule: "edit_payment_schedule",
  edit_payment_received: "edit_payment_received",
  edit_cost_margin_actual: "edit_cost_margin_actual",
  edit_work_progress: "edit_work_progress",
  edit_feedback: "edit_feedback",
  option_step_0: "option_step_0",
  option_step_1: "option_step_1",
  option_step_2: "option_step_2",
  "option_step_3.1": "option_step_3.1",
  "option_step_3.2": "option_step_3.2",
  option_step_4: "option_step_4",
  option_step_5: "option_step_5",
  option_staff: "option_staff",
  option_manager: "option_manager",
  overwrite_status: "overwrite_status",
  filter_status_cancelled: "filter_status_cancelled",
  import: "import",
  request_approval_1: "request_approval_1",
  request_approval_2: "request_approval_2",
  "request_approval_3.1": "request_approval_3.1",
  "request_approval_3.2": "request_approval_3.2",
  print: "print",
  edit_cost_margin_projected: "edit_cost_margin_projected",
  edit_other_documents: "edit_other_documents",
  alert_need_revision: "alert_need_revision",
  alert_revised_1: "alert_revised_1",
  alert_revised_2: "alert_revised_2",
  "alert_revised_3.1": "alert_revised_3.1",
  alert_change_status: "alert_change_status",
  view_list_status_new: "view_list_status_new",
  view_list_status_rejected: "view_list_status_rejected",
  view_list_status_awarded: "view_list_status_awarded",
  view_list_status_declined: "view_list_status_declined",
  filter_status_new: "filter_status_new",
  filter_status_rejected: "filter_status_rejected",
  filter_status_awarded: "filter_status_awarded",
  filter_status_declined: "filter_status_declined",
  edit_other_resource: "edit_other_resource",
  menu_update: "menu_update",
  option_chef: "option_chef",
  fnb_google_calendar: "fnb_google_calendar",
  check_feedback: "check_feedback",
  option_event_requestor: "option_event_requestor",
  check_bill: "check_bill",
  edit_claim_amount: "edit_claim_amount",
  approve_step_1: "approve_step_1",
  approve_step_2: "approve_step_2",
  "approve_step_3.1": "approve_step_3.1",
  "approve_step_3.2": "approve_step_3.2",
  revision_step_1: "revision_step_1",
  revision_step_2: "revision_step_2",
  "revision_step_3.1": "revision_step_3.1",
  "revision_step_3.2": "revision_step_3.2",
  reject_step_1: "reject_step_1",
  reject_step_2: "reject_step_2",
  "reject_step_3.1": "reject_step_3.1",
  "reject_step_3.2": "reject_step_3.2",
  not_applicable_step_1: "not_applicable_step_1",
  not_applicable_step_2: "not_applicable_step_2",
  "not_applicable_step_3.1": "not_applicable_step_3.1",
  "not_applicable_step_3.2": "not_applicable_step_3.2",
  option_kitchen_chef: "option_kitchen_chef",
  fnb_order_settle_cash_payment: "fnb_order_settle_cash_payment",
  chef_mode: "chef_mode",
  fnb_void_order: "fnb_void_order",
  done: "done",
  close: "close",
  cancel: "cancel",
  filter_status_done: "filter_status_done",
  "alert_approved_3.2": "alert_approved_3.2",
  "verify_step_3.2": "verify_step_3.2",
  esign_step_1: "esign_step_1",
  esign_step_2: "esign_step_2",
  "esign_step_3.1": "esign_step_3.1",
  is_pa: "is_pa",
  withdrawal: "withdrawal",
  option_withdrawal: "option_withdrawal",
  complete: "complete",
  reject: "reject",
  option_leader: "option_leader",
  option_supervisor: "option_supervisor",
};

export const UserRoles = {
  admin: "admin",
};
