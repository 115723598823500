import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    drawerToggleBtn: {
        marginTop: 10
    },
    toggleRightBarIcon: {
        fontSize: 28
    },
    invoiceInfo: { marginTop: '0px' },
    invoiceInfoListItem: {
        padding: '2px 0px !important',
        borderBottom: 'unset !important',
        minHeight: '22px',
        overflow: 'scroll'
    },
    moreDataSwitch: {
        marginTop: 8
    },
    moreDataSwitchMobile: {
        float: "right",
        marginRight: -10
    },
    highlighted: {
        color: 'red !important' 
    }
}));
