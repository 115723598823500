import { Button } from '@mui/material';
import { useHttpRequest, useRoleAuthorization } from '../../../hooks';
import { PrivilegeActions, PrivilegeModules } from '../../../data/privileges.enum';

const CHECK_PAYMENT_URL = '/v1/fnb-order/check-payment';

export default function CheckPayment({ order, onSuccess, onError }) {
  const { sendRequest, isLoading } = useHttpRequest();
  const { auth } = useRoleAuthorization();

  function handleClick() {

    sendRequest(
      CHECK_PAYMENT_URL,
      'POST',
      {
        'orderNumber': order?.orderNumber,
      },
    ).then(response => {
      console.log(response.data)
      onSuccess(response?.data?.order, false);
    }).catch(error => {
      onError(new Error(error.response?.data?.message || error));
    });
  }

  const isAbleToShow = () => {
    if (!(auth.isPrivilegeDataLoaded()
      && auth.checkModulePrivilege(PrivilegeModules.fnb_order, PrivilegeActions.fnb_order_settle_cash_payment))) {
      return false;
    }

    const payment = (order?.payment || order?.__payment__);
    if (!order.isPaid && payment?.paymentMethod?.toUpperCase() !== 'CASH') {
      return true;
    }

    if (order.isPaid && (!order.queueNumber || !order.navNumber)) {
      return true;
    }
  };

  return (<>
    {isAbleToShow() && <Button className={'primary'} disabled={isLoading} onClick={handleClick}>Check Payment</Button>}
  </>);
}
