import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  KeyboardArrowDown as ArrowDownIcon,
} from "@mui/icons-material";
import { IconButton, InputBase, Autocomplete, TextField } from "@mui/material";
import "./../Styles.css";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
} from "@mui/x-date-pickers";
import moment from "moment";

export const fnbFoodSchedulesTableColumnConfig = (
  dayOfWeekList,
  onRemoveScheduleRow,
  onChangeSchedule,
  disabled,
) => [
  {
    name: "",
    width: "50px",
    button: true,
    cell: (row, index) => (
      <IconButton onClick={() => onRemoveScheduleRow(index)} disabled={disabled}>
        <DeleteIcon />
      </IconButton>
    ),
  },
  {
    name: "Day of Week",
    width: "200px",
    cell: (row, index) => {
        return <Autocomplete disabled={disabled}
            id={"item"}
            options={dayOfWeekList}
            getOptionLabel={(option) => option}
            value={row.dayOfWeekDesc}
            onChange={(e, newValue) => onChangeSchedule("dayOfWeekDesc", index, newValue)}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            sx={{ width: 300 }}
            className={row.invalidData ? 'invalid-schedule-row':''}
            popupIcon={<ArrowDownIcon />}
        />
    }
  },  
  {
    name: "From Time",
    width: "200px",
    cell: (row, index) => {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              slotProps={{
                inputAdornment: {
                  position: "start"
                },
                textField: {
                  variant: "outlined"
                }
              }}
              className="input-date-time"
              value={row.fromTime && moment(row.fromTime, 'HH:mm:ss').toDate()}
              minutesStep={15}
              ampm={false}
              onChange={(newValue) =>
                onChangeSchedule("fromTime", index, moment(newValue).format('HH:mm:ss'))}
            />          
        </LocalizationProvider>        
      );
    },
  },
  {
    name: "To Time",
    width: "180px",
    cell: (row, index) => {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            slotProps={{
              inputAdornment: {
                position: "start"
              },
              textField: {
                variant: "outlined"
              }
            }}
            className="input-date-time"
            value={row.toTime && moment(row.toTime, 'HH:mm:ss').toDate()}
            minutesStep={15}
            ampm={false}
            onChange={(newValue) =>
              onChangeSchedule("toTime", index, moment(newValue).format('HH:mm:ss'))
            }
          />          
        </LocalizationProvider>        
      );
    },
  },
];
