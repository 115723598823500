import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  IconButton,
} from "@mui/material";
import {
  Search as SearchIcon,
  KeyboardArrowDown as ArrowDownIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { ENABLE_OPTIONS } from "../../data/constants";
import {
  useLayoutDispatch,
  useLayoutState,
  toggleRightbar,
  setRighbarContent,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import MobileFnbEventMenuFilters from "./components/Mobile/MobileFnbEventMenuFilters";
import { fnbEventMenuTableColumnConfig } from "./data-table/fnb-event-menu-table-column-config";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const FnbEventMenuList = (props) => {
  const classes = useStyles();

  // handle loading & http
  const { isLoading, sendRequest } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  // layout state
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  // filters
  const [textFilter, setTextFilter] = useState("");
  const [enableFilter, setEnableFilter] = useState(null);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  const [eventMenuList, setEventMenuList] = useState([]);
  const enableOptions = ENABLE_OPTIONS;
  const smallDeviceWidth = 1200;

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  const mobileFilterProps = { enableOptions, enableFilter, setEnableFilter };
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    document.title = "F&B Event Menu List";

    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_event_menu,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: setRefresh(true),
      });
    }
  }, [message]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  // filter data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    setRefresh(true);
  }, [enableFilter]);

  useEffect(() => {
    if (width <= smallDeviceWidth) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MobileFnbEventMenuFilters key={"rb-body"} {...mobileFilterProps} />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [eventMenuList, enableFilter, width]);

  // set columns in data table
  const columns = fnbEventMenuTableColumnConfig(
    {
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.fnb_event_menu,
        PrivilegeActions.view_detail,
      ),
      allowedDelete: auth.checkModulePrivilege(
        PrivilegeModules.fnb_event_menu,
        PrivilegeActions.delete,
      ),
      allowedEnableDisable: auth.checkModulePrivilege(
        PrivilegeModules.fnb_event_menu,
        PrivilegeActions.enable_disable,
      ),
    },
    handleNameClick,
    handleDeleteFnbEventMenu,
    handleEditFnbEventMenu,
  );

  async function handleEditFnbEventMenu(
    menu,
    toggle = false,
    toggleValue = false,
  ) {
    if (toggle) {
      menu.enabled = toggleValue; // set new enable value & update
      let tmpMenu = JSON.parse(JSON.stringify(menu));
      await sendRequest(`/v1/fnb-event-menu/${menu._id}`, "PUT", tmpMenu);
    } else {
      props.history.push(`card/${menu._id}`);
    }
  }

  function handleDeleteFnbEventMenu(menu) {
    if (menu) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to delete the F&B event menu ${menu.menuName}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await sendRequest(
            `/v1/fnb-event-menu/${menu._id}`,
            "DELETE",
          );
          if (result?.status === 200 && result?.data?.deleted) {
            setMessage(`F&B event menu ${menu.menuName} has been deleted.`);
          } else if (result?.status === 200 && result?.data?.deleted == false) {
            setErrMessage(result?.data?.message);
          }
        }
      });
    }
  }

  function filter() {
    // set list filters
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const eventMenus = response.data?.eventMenus || [];
        setEventMenuList(eventMenus);
        setCollectionSize(response.data?.total);
        return () => {
          return eventMenus;
        };
      },
    );
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      enable: enableFilter,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/fnb-event-menu/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${
          sortBy?.column && sortBy?.direction
            ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
            : ""
        }`,
      "GET",
      {},
    );
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  // go to edit view
  async function handleNameClick(eventMenuId) {
    if (
      auth.checkModulePrivilege(
        PrivilegeModules.fnb_event_menu,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push(`card/${eventMenuId}`);
    }
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>F&B Event Menu List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.fnb_event_menu,
            PrivilegeActions.add,
          ) && (
            <Button
              className={"primary"}
              onClick={() => props.history.push("card")}
            >
              New
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={10} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-searchbox"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* {
                            width >= smallDeviceWidth ? (<>
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                    <Autocomplete
                                        id={"enable-filter"}
                                        options={enableOptions}
                                        getOptionLabel={(option) => option?.toUpperCase()}
                                        value={enableFilter}
                                        onChange={(e, newValue) => setEnableFilter(newValue)}
                                        renderInput={(params) => <TextField {...params}
                                            label={"Enable"} variant={"outlined"} />}
                                        popupIcon={<ArrowDownIcon />}
                                    />
                                </Grid>
                            </>) :
                                (<Grid item xs={2}>
                                    <IconButton
                                        onClick={() => toggleRightbar(layoutDispatch)}
                                        className={classes.drawerToggleBtn}>
                                        {layoutState.isRightbarOpened ? (<BackIcon />)
                                            : (<FilterIcon className={classes.toggleRightBarIcon} />)}
                                    </IconButton>
                                </Grid>)
                        } */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={eventMenuList}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(FnbEventMenuList);
