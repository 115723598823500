import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  KeyboardArrowDown as ArrowDownIcon,
} from "@mui/icons-material";
import { IconButton, InputBase, InputAdornment } from "@mui/material";
import "./../Styles.css";
import DateTimeController from "../../../components/DateTimeController";

export const fnbFoodSpecialPriceTableColumnConfig = (
  onRemoveSpecialPriceRow,
  onChangeSpecialPrice,
  getPureDate,
  disabled,
  control
) => [
  {
    name: "",
    width: "50px",
    button: true,
    cell: (row, index) => (
      <IconButton
        onClick={() => onRemoveSpecialPriceRow(index)}
        disabled={disabled}
      >
        <DeleteIcon />
      </IconButton>
    ),
  },
  {
    name: "Price",
    width: "180px",
    cell: (row, index) => {
      return (
        <InputBase
          disabled={disabled}
          type={"number"}
          variant={"outlined"}
          value={row.price}
          onChange={(e) => onChangeSpecialPrice("price", index, e.target.value)}
          className={row.invalidData ? 'dollar invalid-special-price-row':'dollar'}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      );
    },
  },
  {
    name: "Active From",
    width: "200px",
    cell: (row, index) => {
      return (
        <DateTimeController
          control={control}
          name={'activeFrom'}
          format={"dd/MM/yyyy HH:mm"}
          value={row.activeFrom}
          onChange={(newValue) => onChangeSpecialPrice("activeFrom", index, newValue)}
          disabled={disabled}
        />
      );
    },
  },
  {
    name: "Active To",
    width: "200px",
    cell: (row, index) => {
      return (
        <DateTimeController
          control={control}
          name={'activeTo'}
          format={"dd/MM/yyyy HH:mm"}
          value={row.activeTo}
          onChange={(newValue) => onChangeSpecialPrice("activeTo", index, newValue)}
          disabled={disabled}
        />
      );
    },
  },
];
