import { DeleteOutline } from "@mui/icons-material";
import { IconButton, InputBase, MenuItem, Select } from "@mui/material";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";

export const progressTableConfig = (hasPermission, lastIndex, onRemoveRow, onChangeRowValues) => {
    const columns = [
        {
            name: "Progress",
            width: "100px",
            cell: (row, index) => {
                if (hasPermission && index === lastIndex) {
                    return <Select
                        key={`select-${index}`}
                        variant={"outlined"}
                        value={row.percentage}
                        onChange={(e) => onChangeRowValues(index, e.target.value, 'percentage')}
                        IconComponent={ArrowDownIcon}
                    >
                        {row.percentageList?.map((element, i) =>
                            <MenuItem
                                key={`option-${i}`}
                                value={element.value}
                                selected={row.percentage === element.value}
                            >{element.label}</MenuItem>)}
                    </Select>
                }
                return ((row.percentage === 0) ? `` : `${row.percentage} %`)
            }
        },
        {
            name: "Remark",
            width: "300px",
            cell: (row, index) => {
                if (hasPermission && index === lastIndex) {
                    return <InputBase
                        variant={"outlined"}
                        value={row.comment}
                        onChange={(e) => onChangeRowValues(index, e.target.value, 'comment')}
                    />
                }
                return <>{row.comment}</>
            }
        }
    ];

    if (hasPermission) {
        columns.unshift({
            name: "",
            width: "50px",
            cell: (row, index) =>
                index === lastIndex && (<IconButton onClick={() => onRemoveRow(row, index)}>
                    <DeleteOutline />
                </IconButton>)
        });
    }
    return columns;
};