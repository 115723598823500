import {
  DeleteOutline as DeleteIcon,
} from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import "../Styles.css";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";


export const fnbStoreEventItemTableColumnConfig = (
  onRemoveRow,
  disabled,
) => [
  {
    name: "",
    width: "50px",
    button: true,
    cell: (row, index) => (
      <IconButton onClick={() => onRemoveRow(index)} disabled={disabled}>
        <DeleteIcon />
      </IconButton>
    ),
  },
  {
    name: "SKU",
    width: "90px",
    selector: (row) => row.sku,
    cell: (row) => {
      return <>{row.sku}</>;
    },
  },  
  {
    name: "Name",
    width: "300px",
    selector: (row) => row.name.toUpperCase(),
  },
  {
    name: "Unit Price",
    width: '100px',
    right: true,
    selector: row => row.price,
    cell: (row, index, column, id) => {
      return convertThisToCurrency(row.price.toFixed(2))
    },
  },
{
    name: "Category",
    minWidth: "250px",
    selector: (row) => row.category.name.toUpperCase(),
  },
];
