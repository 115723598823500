import { Box, IconButton, TextField } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";

export function QtyChanger({ onChange, value }) {
  const increaseQty = () => {
    onChange(+value + 1);
  };

  const decreaseQty = () => {
    onChange(Math.max(1, value - 1));
  };

  const onChangeQty = (event, newValue) => {
    const nextValue = Math.max(0, +event.currentTarget.value);
    onChange(nextValue);
  };

  return (
    <>
      <Box
        textAlign={"center"}
        id={"qtyChanger"}
        sx={{
          display: "flex",
          width: "120px",
          textAlign: "center",
          m: "0 0 0 auto",
          alignItems: "center",
        }}
      >
        <IconButton aria-label="delete" onClick={decreaseQty}>
          <RemoveIcon />
        </IconButton>

        <TextField
          sx={{ textAlign: "center" }}
          variant={"outlined"}
          type={"number"}
          min={1}
          onClick={(event) => {
            event.target.select();
          }}
          onChange={onChangeQty}
          max={100}
          value={value}
          inputProps={{ style: { textAlign: "center" } }}
        />

        <IconButton aria-label="delete" onClick={increaseQty}>
          <AddIcon />
        </IconButton>
      </Box>
    </>
  );
}
