import React, { Component } from 'react';
import './Styles.css';
import { Button, Grid, IconButton, Typography } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from "@mui/icons-material/Add";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

class ImageUploadPreviewComponent extends Component {
    fileObj = [];
    fileArray = []; //URL only
    maxNoOfImages = 0;
    note = 'Note: Please shoot picture of the Aircon/Bracket Hole/Power Point/Brand/Compressor';

    constructor(props) {
        super(props)
        this.state = {
            file: [null],
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDrop = this.handleDrop.bind(this);

        for (var i = 0; i < props.savedImages.length; i++) {
            this.fileArray.push({ id: props.savedImages[i].id, url: props.savedImages[i].cloudUrl });
        }

        if (props.maxNoOfImages != undefined) {
            this.maxNoOfImages = props.maxNoOfImages;
        }

        if (props.note != undefined) {
            this.note = props.note;
        }
    }

    // handle drag events
    handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
    };

    // triggers when file is dropped
    handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.readOnly) {
            return;
        }

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            var currLenOfFileObj = this.fileObj.length;

            for (var j = 0; j < e.dataTransfer.files.length; j++) {
                if (!e.dataTransfer.files[j].type.includes("image/")) {
                    continue;
                }

                this.fileObj.push(e.dataTransfer.files[j]);
            }

            this.ammendFileArray(currLenOfFileObj);
        }
    };

    ammendFileArray(indStart) {
        for (let i = indStart; i < this.fileObj.length; i++) {
            const id = Math.floor(Math.random() * 10000);
            this.fileArray.push({ id: id, url: URL.createObjectURL(this.fileObj[i]) });

            this.props.onAddImage(id, this.fileObj[i].name, this.fileObj[i]);//trigger parent's addImage function
        }
        this.setState({ file: this.fileArray })
    }

    onButtonClick() {
        document.getElementById("input-file-upload").click();
    };

    uploadMultipleFiles(e) {
        var currLenOfFileObj = this.fileObj.length;

        for (var j = 0; j < e.target.files.length; j++) {
            this.fileObj.push(e.target.files[j]);
        }

        this.ammendFileArray(currLenOfFileObj);
    }

    uploadFiles(e) {
        e.preventDefault()
    }

    removeImageHandler(id) {
        var indexOfImage = this.fileArray.findIndex(element => element.id == id);

        this.fileObj.splice(indexOfImage, 1);
        this.fileArray.splice(indexOfImage, 1);
        this.setState({ file: this.fileArray });//it is need to setState to reflect on the Page

        this.props.onRemoveImage(id);//trigger parent's removeImage function
    }

    previewDocument(imageUrl) {
        window.open(imageUrl);
    }

    render() {
        return (
            <>
                <div className="form-group">
                    <input type="file" className="form-control" id="input-file-upload" accept="image/*" onChange={this.uploadMultipleFiles} multiple={this.maxNoOfImages > 0 ? false : true} />
                </div>

                <Grid container className={"form"} onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} className={"sub-action"} >
                            <Typography variant={"h2"}>Photos</Typography>
                            {
                                (!this.props.readOnly)
                                &&
                                <Button type={"button"}
                                    onClick={this.onButtonClick}
                                    disabled={this.maxNoOfImages > 0 && this.props.savedImages.length == this.maxNoOfImages ? true : false}
                                    className={this.maxNoOfImages > 0 && this.props.savedImages.length == this.maxNoOfImages ? 'disabled_color' : ''}>
                                    <AddIcon />
                                </Button>
                            }
                        </Grid>

                        {
                            (!this.props.readOnly)
                            &&
                            <Grid item xs={12} className={"note"}>
                                {this.note}
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={this.fileArray.length == 0 ? 'hide-this-component' : ''}>
                            <ImageList sx={{ width: 1, height: 1 }} cols={4} gap={8} >
                                {this.fileArray.map((item) => (
                                    <ImageListItem key={item.id}>
                                        <IconButton
                                            className={this.props.readOnly ? 'hide-this-component' : 'btnRemoveImage'}
                                            onClick={() => this.removeImageHandler(item.id)}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                        <img
                                            className='img_preview'
                                            src={item.url}
                                            onClick={() => this.previewDocument(item.url)}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default ImageUploadPreviewComponent;