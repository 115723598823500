import { Link } from "react-router-dom";
import * as moment from "moment";

export const machineServiceJobTableColumnConfig = (
  { allowedViewDetail },
  handleOpenServiceJob,
) => {
  let columns = [
    {
      name: "No.",
      width: "100px",
      selector: (row) => row.no,
      cell: (row) => {
        if (allowedViewDetail) {
          return (
            <Link
              to={`/app/machine-service-job/card/${row._id}`}
              onClick={(e) => handleOpenServiceJob(row._id)}
            >
              {row.no}
            </Link>
          );
        } else {
          return <>{row.name}</>;
        }
      },
      sortable: true,
      wrap: true,
      sortField: "no",
    },
    {
      name: "Planned Service Date",
      width: "140px",
      selector: (row) => moment(row.plannedServiceDate).format("DD/MM/YYYY"),
      wrap: true,
      sortable: true,
      sortField: "plannedServiceDate",
    },
    {
      name: "Service Job No.",
      width: "150px",
      selector: (row) => row.serviceJobNo,
      wrap: true,
      sortable: true,
      sortField: "serviceJobNo",
    },
    {
      name: "Serviced Date",
      width: "100px",
      selector: (row) =>
        !row.serviceDate ? "" : moment(row.serviceDate).format("DD/MM/YYYY"),
      wrap: true,
      sortable: true,
      sortField: "serviceDate",
    },
    {
      name: "Team",
      width: "130px",
      selector: (row) => row.servicedByUser,
      wrap: true,
      sortable: true,
      sortField: "servicedByUser",
    },
    {
      name: "Status",
      width: "100px",
      selector: (row) => row.status,
      wrap: true,
      sortable: true,
      sortField: "status",
    },
    {
      name: "Company",
      width: "200px",
      selector: (row) => row.company,
      wrap: true,
      sortable: true,
      sortField: "company",
    },
    {
      name: "Store ID",
      width: "100px",
      selector: (row) => row.storeId,
      wrap: true,
      sortable: true,
      sortField: "storeId",
    },
    {
      name: "Area",
      width: "70px",
      selector: (row) => row.area,
      wrap: true,
      sortable: true,
      sortField: "area",
    },
    {
      name: "Brand",
      width: "150px",
      selector: (row) => row.brand,
      wrap: true,
      sortable: true,
      sortField: "brand",
    },
    {
      name: "Model",
      width: "200px",
      selector: (row) => row.model,
      wrap: true,
      sortable: true,
      sortField: "model",
    },
    {
      name: "Serial No.",
      width: "200px",
      selector: (row) => row.serialNo,
      wrap: true,
      sortable: true,
      sortField: "serialNo",
    },
  ];

  return columns;
};
