import React, { useState } from "react";
import { Grid } from "@mui/material";
import DateFilter from "../../../../components/DateFilter";

export default function MobileFnbEventBookingFilters({
    dateFilterFrom, setDateFilterFrom, dateFilterTo, setDateFilterTo
}) {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateFilter
                label="From"
                value={dateFilterFrom}
                onChange={(newValue) => setDateFilterFrom(newValue)}
                maxDate={dateFilterTo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateFilter
                label="To"
                value={dateFilterTo}
                onChange={(newValue) => setDateFilterTo(newValue)}
                minDate={dateFilterFrom}
              />
            </Grid>
        </Grid>
    </>);
};
