import SearchIcon from "@mui/icons-material/Search";
import { Chip, IconButton } from "@mui/material";
import moment from "moment";

export const activityLogTableColumnConfig = ({ openActivityLogDrawer }) => [
  {
    name: "",
    width: "40px",
    selector: (row) => (
      <IconButton>
        <SearchIcon
          onClick={() => {
            openActivityLogDrawer(row);
          }}
        />
      </IconButton>
    ),
  },
  {
    name: "Date",
    width: "160px",
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    sortable: true,
    sortField: "createdAt",
  },
  {
    name: "Username",
    width: "100px",
    selector: (row) => row?.username,
    sortable: true,
    sortField: "username",
  },
  {
    name: "Module",
    width: "250px",
    selector: (row) => row.module,
    sortable: true,
    sortField: "module",
    wrap: true,
  },
  {
    name: "Action",
    width: "350px",
    selector: (row) => row.action,
    sortable: true,
    sortField: "action",
    wrap: true,
  },
  {
    name: "Status",
    width: "100px",
    selector: (row) => (
      <Chip
        color={Number(row.status) > 400 ? "error" : "success"}
        label={row.status}
        size="small"
      />
    ),
    sortable: true,
    sortField: "status",
  },
];
