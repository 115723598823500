import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import { IconButton, Switch, TextField } from "@mui/material";
import TextFieldController from "../../../components/TextFieldController";
import SwitchController from "../../../components/SwitchController";
export const optionTableColumnConfig = ({
  onRemoveRow,
  onChangeItem,
  name,
}) => [
  {
    name: "",
    width: "50px",
    cell: (row, index) => (
      <IconButton onClick={() => onRemoveRow(name, index)}>
        <DeleteIcon />
      </IconButton>
    ),
  },
  {
    name: "Option",
    cell: (row, index) => {
      return (
        <TextFieldController
          name={`${name}.${index}.option`}
          required
          variant={"outlined"}
          label={"Option"}
          hiddenLabel={true}
        />
      );
    },
  },
  {
    name: "Enable",
    width: "80px",
    center: true,
    selector: (row) => row.enabled,
    cell: (row, index) => {
      return <SwitchController name={`${name}.${index}.enabled`} />;
    },
    sortable: true,
  },
];
