import { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Autocomplete,
  Backdrop, Button, CircularProgress, Grid,
  IconButton,
  InputAdornment, TextField, Typography,
} from '@mui/material';
import { ArrowBack, KeyboardArrowDown, PlaylistPlay, Search } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useRoleAuthorization } from '../../hooks/useRoleAuthorization';
import { useHttpRequest } from '../../hooks/useHttpRequest';
import useStyles from './styles';
import { setRighbarContent, toggleRightbar, useLayoutDispatch, useLayoutState } from '../../context/LayoutContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import DataTable from 'react-data-table-component';
import { projectsColumnConfig } from './data-table/projects-column-config';
import MobileProjectFilters from './components/Mobile/MobileProjectFilters';
import { PrivilegeModules, PrivilegeActions } from '../../data/privileges.enum';
import downloadCSV from '../../helper/download-csv';
import moment from 'moment';

const ManageProjects = (props) => {
  const classes = useStyles();

  // handle loading & http
  const { isLoading, httpRequestError: error, responseData, sendRequest } = useHttpRequest();
  const { sendRequest: queryCompanyList } = useHttpRequest();

  // layout states
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  // page authorization
  const { auth } = useRoleAuthorization();

  const [projectList, setProjectList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [refreshProjectList, setRefreshProjectList] = useState(true);
  const [textFilter, setTextFilter] = useState('');
  const [projectGroupFilter, setProjectGroupFilter] = useState(null);
  const [enableFilter, setEnableFilter] = useState(null);

  const enableDisableList = ['Yes', 'No'];
  const [companyActiveList, setCompanyActiveList] = useState([]);
  const [companyFilterValue, setCompanyFilterValue] = useState(undefined);

  useEffect(() => {
    queryCompanyList('/v1/company').then((response) => setCompanyActiveList(response.data.map(i => i.name)));
  }, []);

  useEffect(() => {
    document.title = 'Project List';

    // redirect to default page if not authorized
    if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.project, PrivilegeActions.view_list)) {
      props.history.push('/app/dashboard');
    }
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    // fetch project list
    if (refreshProjectList) {
      // toggle more data
      sendRequest(`/v1/projects`, 'GET', {})
        .then(response => {
          const projects = response.data?.projects || [];
          setProjectList(projects);
          setRefreshProjectList(false);
          return () => {
            abortController.abort();
            return projects;
          };
        });
    }
  }, [refreshProjectList]);

  // set filtered data
  useEffect(() => {
      setFilteredData(filterProjects());
    },
    [projectList, textFilter, enableFilter, projectGroupFilter, companyFilterValue]);

  // populate status list from existing claims
  const projectGroupList = useMemo(() => {
    return [...new Set(projectList.map(c => c.projectGroup).flat(1))].sort();
  }, [projectList]);

  // set right bar for small devices
  const smallDeviceWidth = 1200;
  const mobileFilterProps = {
    companyList: companyActiveList,
    companyFilterValue,
    setCompanyFilterValue,
    projectGroupList,
    enableDisableList,
    projectGroupFilter,
    setProjectGroupFilter,
    enableFilter,
    setEnableFilter,
  };

  useEffect(() => {
    if (width <= smallDeviceWidth) { // set filter right bar
      setRighbarContent(layoutDispatch,
        [<Typography variant={'h2'} key={'rb-header'}>Filter</Typography>, // header text
          <MobileProjectFilters key={'rb-body'} {...mobileFilterProps} />],
        { size: 'xs', backButton: true }); // filter content
    }
  }, [projectGroupFilter, enableFilter, width, projectGroupList]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // set columns in data table
  const columns = projectsColumnConfig({
    allowedViewDetail: auth.checkModulePrivilege(PrivilegeModules.project, PrivilegeActions.view_detail),
    allowedEnableDisable: auth.checkModulePrivilege(PrivilegeModules.project, PrivilegeActions.enable_disable),
  }, handleEditProject);

  // list filter
  function filterProjects() {
    return projectList.filter(project => {
      let filterFlag = true;

      // search box
      if (textFilter) {
        const compoundText = project.projectId.toUpperCase() + project.projectName.toUpperCase();
        filterFlag &= compoundText.includes(textFilter.toUpperCase());
      }

      // project group dropdown
      if (projectGroupFilter) {
        filterFlag &= (project.projectGroup.includes(projectGroupFilter));
      }

      // enable dropdown
      if (enableFilter) {
        filterFlag &= (project.enabled.includes(enableFilter));
      }

      if (companyFilterValue) {
        filterFlag &= (project?.company?.name === companyFilterValue);
      }

      return filterFlag;
    });
  }

  // edit or update status of a project
  async function handleEditProject(project, isSwitch = false, switchValue = undefined) {
    if (isSwitch) {
      project.enabled = switchValue ? 'Yes' : 'No';
      await sendRequest(`/v1/projects/${project._id}`, 'PUT', project);
    } else {
      props.history.push(`card/${project._id}`);
    }
  }

  // generate and download csv report
  async function downloadReport() {
    const csvData = [];
    const projects = filterProjects() || [];

    if (projects.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'No data to export.',
      });
      return;
    }

    for (const project of projects) {
      csvData.push({
        projectId: project.projectId,
        projectName: project.projectName,
        projectGroup: project.projectGroup,
        referenceNo: project.referenceNo,
        Company: project?.company?.name || '',
        enabled: project.enabled,
      });
    }

    // column names
    const columns = [
      'Project ID',
      'Project Name',
      'Project Group',
      'Project Reference No.',
      'Company',
      'Enable',
    ];

    const today = moment().format('YYYYMMDD_HHmmss');
    const filename = `PROJECT-REPORT-${today}.csv`;
    downloadCSV(csvData, filename, columns);
  }

  return (<>
    {isLoading && <Backdrop style={{ zIndex: 10 }} open={isLoading}>
      <CircularProgress color={'inherit'} />
    </Backdrop>}
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography variant={'h1'}>Project List</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} className={'action'}>
        {auth.checkModulePrivilege(PrivilegeModules.project, PrivilegeActions.add) &&
          <Button className={'primary'} onClick={() => props.history.push('card')}>New</Button>}
        {(auth.checkModulePrivilege(PrivilegeModules.project, PrivilegeActions.download_report)) &&
          <Button className={'primary'} onClick={() => downloadReport()}>Download Report</Button>}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid className={'form'}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={10} sm={10} md={10} lg={2}>
              <TextField
                autoComplete={'off'}
                id={'text-search-box'}
                variant={'outlined'}
                value={textFilter}
                onChange={(e) => setTextFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {
              width >= smallDeviceWidth ? (<>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={'project-group-filter'}
                      options={projectGroupList}
                      getOptionLabel={(option) => option}
                      value={projectGroupFilter}
                      onChange={(e, newValue) => setProjectGroupFilter(newValue)}
                      renderInput={(params) =>
                        <TextField {...params} label={'Project Group'} variant={'outlined'} />}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={'company-filter'}
                      options={companyActiveList}
                      getOptionLabel={(company) => company}
                      value={companyFilterValue}
                      onChange={(e, newValue) => setCompanyFilterValue(newValue)}
                      renderInput={(params) =>
                        <TextField {...params} label={'Company'} variant={'outlined'} />}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={'enable-filter'}
                      options={enableDisableList}
                      getOptionLabel={(option) => option.toUpperCase()}
                      value={enableFilter}
                      onChange={(e, newValue) => setEnableFilter(newValue)}
                      renderInput={(params) =>
                        <TextField {...params} label={'Enable'} variant={'outlined'} />}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                </>) :
                (<Grid item xs={2}>
                  <IconButton
                    onClick={() => toggleRightbar(layoutDispatch)}
                    className={classes.drawerToggleBtn}>
                    {layoutState.isRightbarOpened ? (<ArrowBack className={classes.toggleRightBarIcon} />)
                      : (<PlaylistPlay className={classes.toggleRightBarIcon} />)}
                  </IconButton>
                </Grid>)
            }
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                fixedHeader={true}
                persistTableHead={true}
                columns={columns}
                data={filteredData}
                pagination
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>);
};

export default withRouter(ManageProjects);