import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyboardArrowDown } from '@mui/icons-material';
import useStyles from "../../styles";

export default function MobileCEAFilters({
  floorplanFilterOptions,
  floorplanFilter,
  setFloorplanFilter,
}) {
  const classes = useStyles();

  return (<>
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
              id={"floorplan-filter"}
              options={floorplanFilterOptions}
              getOptionLabel={(option) => option.toUpperCase()}
              value={floorplanFilter}
              onChange={(e, newValue) => setFloorplanFilter(newValue)}
              renderInput={(params) =>
                  <TextField {...params} label={"Floor Plan"} variant={"outlined"} />}
              popupIcon={<KeyboardArrowDown />}
          />
      </Grid>      
    </Grid>
  </>);
};
