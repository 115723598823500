export const messageList = [
  {
    id: "success",
    label: {
      title: "Success Title",
      message: "Success Message",
    },
  },
  {
    id: "error",
    label: {
      title: "Error Title",
      message: "Error Message",
    },
  },
];

export const emailContentList = [
  {
    id: "email",
    label: "Email Content",
  },
];
