import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { Add, KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { MachineTableConfig } from "./data-table/machine-table-column-config";
import DataTable from "react-data-table-component";

const MachineStoreCard = (props) => {
  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const { storeId } = useParams(); // url param => storeId when editing

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
    reset,
  } = useForm({ mode: "all" });

  // un-assigned sub-con list
  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [currentStore, setCurrentStore] = useState(null);
  const [rowsInvalid, setRowsInvalid] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [machineList, setMachineList] = useState([]);

  const [
    MACHINE_SERVICE_REMINDER_DAY_DEFAULT,
    setMACHINE_SERVICE_REMINDER_DAY_DEFAULT,
  ] = useState(0);
  const [
    MACHINE_SERVICE_INTERVAL_DEFAULT,
    setMACHINE_SERVICE_INTERVAL_DEFAULT,
  ] = useState(0);
  const [machineServiceIntervalOptions, setMachineServiceIntervalOptions] =
    useState([]);
  const [
    machineServiceReminderDayOptions,
    setMachineServiceReminderDayOptions,
  ] = useState([]);

  const [enableSwitchValue, setEnableSwitchValue] = useState(true); // enable switch => default true

  useEffect(() => {
    document.title = "New - Machine Store Card";

    loadPrerequisites();

    // redirect to default page if not authorized
    if (
      storeId &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.machine_store,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  // get current matrix in edit view
  useEffect(() => {
    if (!isLoading && !responseData && storeId) {
      sendRequest(`/v1/machine-store/get-store-by-id/` + storeId, "GET");
    }
  }, [storeId]);

  useEffect(() => {
    if (areaList && currentStore) {
      setSelectedArea(
        areaList.filter((area) => area.areaCode == currentStore.area).pop(),
      );
    }
  }, [currentStore, areaList]);

  // set form values when edit view
  useEffect(() => {
    if (storeId && responseData?.store) {
      const store = responseData?.store;
      document.title = `${store.storeId} - Machine Store Card`;
      setCurrentStore(store);
      setSelectedCompany(responseData?.selectedCompany);
    }
  }, [responseData]);

  useEffect(() => {
    if (currentStore) {
      setEnableSwitchValue(currentStore.enable);
      setMachineList(currentStore.storeMachine);

      reset(currentStore);
    }
  }, [currentStore]);

  useEffect(() => {
    const invalid =
      machineList.findIndex(
        (r) =>
          !r.brand ||
          !r.model ||
          !r.serialNo ||
          !r.nextServiceMonth ||
          !r.seqOfMonth ||
          !r.serviceInterval ||
          !r.serviceReminder,
      ) > -1 || !selectedCompany;
    setRowsInvalid(invalid);
  }, [machineList, selectedCompany, selectedArea]);

  const loadPrerequisites = () => {
    sendRequest(`/v1/machine-store/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        setCompanyList(prerequisites.companyList);
        setAreaList(prerequisites.areaList);
        setMACHINE_SERVICE_REMINDER_DAY_DEFAULT(
          prerequisites.MACHINE_SERVICE_REMINDER_DAY_DEFAULT,
        );
        setMACHINE_SERVICE_INTERVAL_DEFAULT(
          prerequisites.MACHINE_SERVICE_INTERVAL_DEFAULT,
        );
        setMachineServiceIntervalOptions(
          prerequisites.MachineServiceIntervalOptions,
        );
        setMachineServiceReminderDayOptions(
          prerequisites.MachineServiceReminderDayOptions,
        );
      },
    );
  };

  const navigateToList = () => {
    if (!storeId) window.location.href = "#/app/machine-store/list";
  };

  const handleSubmitForm = async (data, e) => {
    e.preventDefault();

    const store = {
      companyId: selectedCompany,
      storeId: data.storeId.toUpperCase(),
      area: data.area,
      location: data.location,
      enable: data.enable,
      storeMachine: machineList.map((m) => ({
        machineId: m?.machineId,
        brand: m.brand,
        model: m.model,
        serialNo: m.serialNo,
        nextServiceDate: m.nextServiceDate,
        nextServiceMonth: m.nextServiceMonth,
        seqOfMonth: m.seqOfMonth,
        serviceInterval: m.serviceInterval,
        serviceReminder: m.serviceReminder,
        currentSequence: m.currentSequence,
        enable: m.enable,
      })),
    };

    let savedStore = undefined,
      message = "";
    if (currentStore && storeId) {
      // when edit
      store._id = currentStore._id;
      const result = await sendRequest(
        `/v1/machine-store/${store._id}`,
        "PUT",
        store,
      );
      if (result?.status === 200 && result?.data?.store) {
        message = `Machine store ${result.data.store.storeId} has been updated.`;
        savedStore = result?.data?.store;
      }
    } else {
      const result = await sendRequest(`/v1/machine-store`, "POST", store);
      if (result?.status === 201 && result?.data?.store) {
        message = `New machine store ${result.data.store.storeId} has been created.`;
        savedStore = result?.data?.store;
      }
    }

    setMessage(message);
  };

  const onChangeCompany = (newValue) => {
    setValue("companyId", newValue._id);
    setSelectedCompany(newValue);
  };

  const onChangeArea = (newValue) => {
    setValue("area", !newValue ? "" : newValue.areaCode);
    setSelectedArea(newValue);
  };

  const machineColumns = MachineTableConfig(
    storeId &&
      !auth.checkModulePrivilege(
        PrivilegeModules.machine_store,
        PrivilegeActions.edit,
      ),
    onChangeMachine,
    onBlurSeqOfMonth,
    machineServiceIntervalOptions,
    machineServiceReminderDayOptions,
    onRemoveMachineRow,
    checkForDuplicatedSerialNo,
  );

  const isDuplicating = (fieldName, value) =>
    machineList.filter(
      (r) => r[fieldName].toUpperCase() === value.trim().toUpperCase(),
    ).length > 1;

  function checkForDuplicatedSerialNo(fieldName, i, value) {
    let foundDuplicate = false;

    const rList = [...machineList];

    if (
      machineList.length > 1 &&
      value.length > 0 &&
      isDuplicating(fieldName, value)
    ) {
      setWarningMessage(`Serial No ${value.toUpperCase()} found in the list.`);
      rList[i][fieldName] = "";
      foundDuplicate = true;
    } else {
      rList[i][fieldName] = value;
    }

    return foundDuplicate;
  }

  const isDuplicateSerialNo = (serialNo, i) => {
    return (
      machineList.filter((m, index) => {
        return m.serialNo == serialNo && index != i;
      }).length > 0
    );
  };

  async function onChangeMachine(fieldName, i, value) {
    if (fieldName == "serialNo") {
      if (isDuplicateSerialNo()) {
        return;
      }
    }

    const rList = [...machineList];

    if (fieldName == "nextServiceMonth") {
      rList[i].seqOfMonth = "";
    }

    rList[i][fieldName] = value;
    setMachineList(rList);
  }

  async function onBlurSeqOfMonth(i, seq) {
    let nextServiceMonth = new Date(machineList[i].nextServiceMonth);

    if (!nextServiceMonth.getFullYear()) {
      return;
    }

    let nextServiceDateNew = new Date(
      await getNextServiceDate(
        nextServiceMonth.getFullYear(),
        nextServiceMonth.getMonth() + 1,
        seq,
      ),
    );

    const rList = [...machineList];
    rList[i].nextServiceDate = nextServiceDateNew;
    setMachineList(rList);
  }

  async function getNextServiceDate(year, month, seq) {
    let getNextServiceDate = await sendRequest(
      `/v1/machine-store/get-next-service-date/${year}/${month}/${seq}`,
      "GET",
    );
    return getNextServiceDate.data.nextServiceDate;
  }

  function createNewRowForMachine() {
    setMachineList([
      ...machineList,
      {
        machineId: "",
        brand: "",
        model: "",
        serialNo: "",
        nextServiceDate: "",
        nextServiceMonth: "",
        seqOfMonth: 1,
        serviceInterval: MACHINE_SERVICE_INTERVAL_DEFAULT,
        serviceReminder: MACHINE_SERVICE_REMINDER_DAY_DEFAULT,
        currentSequence: 0,
        enable: true,
      },
    ]);
  }

  function onRemoveMachineRow(i) {
    const rows = [...machineList];
    rows.splice(i, 1);
    setMachineList([...rows]);
  }

  return (
    <>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                Machine Store Card
                {currentStore ? ` - ${currentStore.storeId}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button
                onClick={() => props.history.push("/app/machine-store/list")}
              >
                Cancel
              </Button>
              {((!currentStore &&
                !storeId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.machine_store,
                  PrivilegeActions.add,
                )) ||
                (currentStore &&
                  storeId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.machine_store,
                    PrivilegeActions.edit,
                  )) ||
                (currentStore &&
                  storeId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.machine_store,
                    PrivilegeActions.menu_update,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  Save
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Autocomplete
                            options={companyList}
                            getOptionLabel={(company) => `${company.name}`}
                            value={selectedCompany}
                            onChange={(e, newValue) =>
                              onChangeCompany(newValue)
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Company"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                            disabled={currentStore ? true : false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"storeId"}
                            label={"Store ID"}
                            variant={"outlined"}
                            {...register("storeId", {
                              required: {
                                value: true,
                                message: "Store ID cannot be blank.",
                              },
                            })}
                            disabled={
                              currentStore ||
                              (storeId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.machine_store,
                                  PrivilegeActions.edit,
                                ))
                            }
                            autoComplete={"off"}
                            error={!!formErrors?.storeId}
                            helperText={formErrors?.storeId?.message}
                            onChange={(storeId) => validateText(storeId)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Autocomplete
                            options={areaList}
                            getOptionLabel={(area) => area.areaCode}
                            value={selectedArea}
                            onChange={(e, newValue) => onChangeArea(newValue)}
                            disableClearable={false}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Area"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"location"}
                            label={"Location"}
                            variant={"outlined"}
                            {...register("location", {
                              required: {
                                value: true,
                                message: "Location cannot be blank.",
                              },
                            })}
                            disabled={
                              currentStore &&
                              storeId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.machine_store,
                                PrivilegeActions.edit,
                              )
                            }
                            autoComplete={"off"}
                            error={!!formErrors?.location}
                            helperText={formErrors?.location?.message}
                            onChange={(location) => validateText(location)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            disabled={
                              currentStore &&
                              storeId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.machine_store,
                                PrivilegeActions.edit,
                              )
                            }
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("enable")}
                                checked={enableSwitchValue}
                                onChange={(e) =>
                                  setEnableSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Enable"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Machines</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={machineColumns}
                      data={machineList}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={createNewRowForMachine}
                      disabled={
                        rowsInvalid ||
                        (currentStore &&
                          storeId &&
                          !auth.checkModulePrivilege(
                            PrivilegeModules.machine_store,
                            PrivilegeActions.edit,
                          ))
                      }
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
};

export default withRouter(MachineStoreCard);
