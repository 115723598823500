import { Add, DeleteOutline } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import * as moment from "moment";
import convertUrlFileName from "../../../helper/convert-url-filename";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";

export const documentsTableConfig = (
  docTypeList,
  isEditable,
  onRemoveRow,
  onChangeRowValues,
  onClickAddBtn,
  handleClickOnPNL,
  version,
) => {
  let columns = [];

  if (version > 1) {
    columns = [
      ...columns,
      {
        name: "Type",
        width: "210px",
        cell: (row, index) => {
          if (row.type != "PNL") {
            return (
              <FormControl error={!row.type}>
                <Select
                  disabled={!isEditable}
                  key={`select-${index}`}
                  variant={"outlined"}
                  value={row.type}
                  onChange={(e) =>
                    onChangeRowValues(index, e.target.value, "type")
                  }
                  IconComponent={ArrowDownIcon}
                >
                  {docTypeList.map((element, i) => (
                    <MenuItem
                      key={element.type_code}
                      value={element.type_code}
                      selected={row.type === element.type_desc.toUpperCase()}
                    >
                      {element.type_desc.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                {!row.type && (
                  <FormHelperText>Type can not be blank</FormHelperText>
                )}
              </FormControl>
            );
          } else {
            return <>{row.type}</>;
          }
        },
      },
    ];
  }

  columns = [
    ...columns,
    ...[
      {
        name: "Description",
        width: "300px",
        wrap: true,
        cell: (row, index) => {
          if (isEditable && row.type != "PNL") {
            return (
              <InputBase
                type={"text"}
                variant={"outlined"}
                value={row.description}
                onChange={(e) =>
                  onChangeRowValues(index, e.target.value, "description")
                }
              />
            );
          }
          return <>{row.description}</>;
        },
      },
      {
        name: "Name",
        width: "450px",
        selector: (row) => {
          if (row.type != "PNL") {
            return (
              <a href={convertUrlFileName(row.url)} target="_blank" title={row.fileName} download>
                {row.fileName}
              </a>
            );
          }

          return <a onClick={() => handleClickOnPNL()} title={row.fileName}>{row.fileName}</a>;
        },
      },
      {
        name: "Upload Date",
        width: "170px",
        selector: (row) =>
          row.uploadedDate
            ? moment(row.uploadedDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
        sortable: false,
      },
    ],
  ];

  if (version > 1) {
    columns = [
      ...columns,
      {
        name: "Uploaded By",
        wrap: true,
        width: "170px",
        selector: (row) => (row.type != "PNL" ? `${row.uploadedBy}` : ""),
        sortable: false,
      },
    ];
  }

  if (isEditable) {
    columns.unshift({
      name: (
        <IconButton onClick={onClickAddBtn}>
          <Add />
        </IconButton>
      ),
      width: "50px",
      cell: (row, index) => {
        if (row.allowedDelete) {
          return (
            <IconButton onClick={() => onRemoveRow(index)}>
              <DeleteOutline />
            </IconButton>
          );
        }
      },
    });
  }
  return columns;
};
