import { Add, DeleteOutline } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import * as moment from "moment";
import { isAwarded } from "../helper/pr-config";
import convertUrlFileName from "../../../helper/convert-url-filename";

export const paymentReceivedDocumentsTableConfig =
    (onChangeRowValues, onRemoveRow, onClickAddBtn,
        permissionToEdit, step, status) => {

        const isEditable = () => permissionToEdit;

        const columns = [
            {
                name: "Description",
                width: "300px",
                cell: (row, index) => {
                    if (isEditable()) {
                        return <InputBase
                            type={"text"}
                            variant={"outlined"}
                            value={row.description}
                            onChange={(e) => onChangeRowValues(index, e.target.value, 'description')}
                        />
                    }
                    return <>{row.description}</>
                }
            },
            {
                name: "Name",
                width: "450px",
                selector: row => (<a href={convertUrlFileName(row.url)} target="_blank" title={row.fileName} download>{row.fileName}</a>)
            },
            {
                name: "Upload Date",
                width: "170px",
                selector: row => row.uploadedDate ?
                    moment(row.uploadedDate).format('DD/MM/YYYY HH:mm:ss') : '',
                sortable: true,
                id: 'uploadedDate'
            }
        ];

        if (isEditable() && isAwarded(status))
            columns.unshift({
                name: (<IconButton
                    onClick={onClickAddBtn}>
                    <Add />
                </IconButton>),
                width: "50px",
                cell: (row, index) =>
                    (isEditable() && isAwarded(status)) &&
                    (<IconButton onClick={() => onRemoveRow(row, index)}>
                        <DeleteOutline />
                    </IconButton>)
            });
        return columns;
    };