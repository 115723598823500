import React from "react";
import { Button } from "@mui/material";
import { withRouter } from "react-router-dom";

const BackButton = ({history}) => {
  return (
    <>
      <Button type={"button"} onClick={() => history.goBack()}>Cancel</Button>
    </>
  );
}

export default withRouter(BackButton);
