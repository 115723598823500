import {
  Grid,
  InputAdornment,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Search as SearchIcon, KeyboardArrowDown } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { columns } from "./voucher-code-columns.config";
import { useVoucherCodeListQuery } from "./api/useVoucherCodeListQuery";

export const FnbVoucherCodeGrid = ({ voucherData, refresh, setRefresh }) => {
  const { auth } = useRoleAuthorization();
  const { isLoading, getListCodeQuery } = useVoucherCodeListQuery();

  const [textFilter, setTextFilter] = useState("");
  const [attributeFilters, setAttributeFilters] = useState({});
  const refreshPage = useCallback(() => {
    !refresh && setRefresh(true);
  }, [refresh]);
  const manualGenerateList = ["YES", "NO"];
  const usedList = ["YES", "NO"];
  const [tableData, setTableData] = useState({
    data: [],
    collectionSize: 1,
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refreshPage();
    }
  };

  const columnConfig = useMemo(() => columns(), []);
  const [dataTableConfig, setDataTableConfig] = useState({
    columns: columnConfig,
    sortField: "createdAt",
    sortOrder: "desc",
    page: 1,
    pageSize: 10,
  });

  const sortByColumn = useCallback(
    (columnConfig, sortDirection) => {
      setDataTableConfig({
        ...dataTableConfig,
        sortField: columnConfig.sortField,
        sortOrder: sortDirection,
      });
      refreshPage();
    },
    [dataTableConfig, refreshPage],
  );

  const handleChangeRowsPerPage = useCallback(
    (pageSize) => {
      setDataTableConfig((prev) => {
        return { ...prev, pageSize };
      });
      refreshPage();
    },
    [refreshPage],
  );

  const handleChangePage = useCallback(
    (page) => {
      setDataTableConfig((prev) => {
        return { ...prev, page };
      });
      refreshPage();
    },
    [refreshPage],
  );

  useEffect(() => {
    refresh &&
      voucherData.incrementId &&
      getListCodeQuery(
        textFilter,
        attributeFilters,
        { page: dataTableConfig.page, pageSize: dataTableConfig.pageSize },
        {
          sortField: dataTableConfig.sortField,
          direction: dataTableConfig.sortOrder,
        },
        voucherData.incrementId,
      ).then((data) => {
        setTableData({ data: data.data, collectionSize: data.total });
        setRefresh(false);
      });
  }, [voucherData.incrementId, dataTableConfig, refresh]);

  const handleChangeFilter = (field, value, operation) => {
    if (value === undefined) {
      setAttributeFilters((prev) => ({ ...prev, [field]: undefined }));
    } else {
      setAttributeFilters((prev) => ({
        ...prev,
        [field]: { value, operation },
      }));
    }
    refreshPage();
  };

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={10} sm={10} md={10} lg={2}>
          <TextField
            autoComplete={"off"}
            id={"text-searchbox"}
            variant={"outlined"}
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={2}>
          <Autocomplete
            id={"manual-generated-filter"}
            options={manualGenerateList}
            getOptionLabel={(option) => option.toUpperCase()}
            value={attributeFilters?.isManualGenerated}
            onChange={(e, value) =>
              handleChangeFilter(
                "isManualGenerated",
                value ? (value === "YES" ? 1 : 0) : undefined,
                "eq",
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Manual Generated"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={2}>
          <Autocomplete
            id={"used-filter"}
            options={usedList}
            getOptionLabel={(option) => option.toUpperCase()}
            value={attributeFilters?.isManualGenerated}
            onChange={(e, value) => {
              value === "YES" && handleChangeFilter("timesUsed", 1, "gteq");
              value === "NO" && handleChangeFilter("timesUsed", 0, "eq");
              value === undefined && handleChangeFilter("timesUsed", undefined);
            }}
            renderInput={(params) => (
              <TextField {...params} label={"Used"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DataTable
            fixedHeader={true}
            persistTableHead={true}
            columns={dataTableConfig.columns}
            data={tableData.data}
            sortServer
            onSort={sortByColumn}
            pagination
            paginationServer
            paginationDefaultPage={dataTableConfig.page}
            paginationPerPage={dataTableConfig.pageSize}
            paginationTotalRows={tableData.collectionSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
    </>
  );
};
