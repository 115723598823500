import { withRouter } from "react-router-dom";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useStoreOptionsList } from "./services/useStoreOptionsList";
import { useFnbPresetList } from "./services/useFnbPresetList";
import { FoodPresetCard } from "./components/FoodPresetCard";
import { StoreSwitcher } from "./components/StoreSwitcher";

export function FnbPresetList() {
  const [selectedStore, setSelectedStore] = useState(null);
  const {
    storeList,
    refetch: refetchStoreList,
    isLoading: isStoreListLoading,
  } = useStoreOptionsList();

  const { presetList, isLoading: isPresetLoading } = useFnbPresetList(
    selectedStore?.storeCode,
  );

  const isLoading = useMemo(
    () => isStoreListLoading && isPresetLoading,
    [isPresetLoading, isStoreListLoading],
  );

  useEffect(() => {
    if (!selectedStore && storeList.length == 1) setSelectedStore(storeList[0]);
  }, [selectedStore, storeList]);

  return (
    <>
      <Suspense
        fallback={
          <Backdrop style={{ zIndex: 10 }} open={isLoading}>
            <CircularProgress color={"inherit"} />
          </Backdrop>
        }
      >
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <Typography variant={"h1"}>F&B Redemption</Typography>
          </Grid>
          <Grid item xs={12} md={6} className={"action"}>
          </Grid>

          <Grid item xs={12}>
            <Grid className={"form"}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} lg={2}>
                  <StoreSwitcher
                    storeList={storeList}
                    value={selectedStore}
                    onChange={setSelectedStore}
                  />
                </Grid>
                <Grid item xs={12} className={"tile"}>
                  {presetList?.length === 0 ? (
                    <Typography className={"no-record"}>No Records Found</Typography>
                  ) : (
                    <Grid container spacing={{ xs: 2, md: 3 }}>

                      {presetList.length > 0 &&
                        presetList.map((product) => (
                          <Grid item xs={12} md={3} key={product.sku}>
                            <FoodPresetCard store={selectedStore} product={product} />
                          </Grid>
                        ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}

export default withRouter(FnbPresetList);
