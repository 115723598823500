import { useParams, withRouter } from "react-router-dom";
import React, { useCallback, useMemo, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSupplierListApi } from "./api/useSupplierListApi";
import { SupplierForm } from "./SupplierForm";

const SupplierListCard = (props) => {
  const params = useParams();
  const vendorCode = useMemo(() => params.vendorCode, [params]);
  const pageTitle = useMemo(
    () =>
      !vendorCode
        ? "F&B Supplier Card - New"
        : `F&B Supplier Card - ${vendorCode}`,
    [vendorCode],
  );
  const windowTitle = useMemo(
    () =>
      !vendorCode
        ? "New - F&B Supplier Card"
        : `${vendorCode} - F&B Supplier Card`,
    [vendorCode],
  );
  // Prerequisite
  const { getPrerequisites, isLoading, getByVendorCode } = useSupplierListApi();
  const [prerequisites, setPrerequisites] = useState(undefined);
  const [supplierData, setSupplierData] = useState();

  const fetchOrderData = useCallback(async () => {
    if (!vendorCode) {
      setSupplierData({
        contactEmails: [],
        items: [],
        stores: [],
      });
      return;
    }
    getByVendorCode(vendorCode)
      .then((res) => res.data)
      .then(setSupplierData);
  }, [vendorCode]);

  const fetchPrerequisite = useCallback(async () => {
    getPrerequisites().then((response) => {
      setPrerequisites(response.data);
      setSupplierData((prev) => {
        if (!prev.contactName) prev.contactName = response?.data?.contact?.name;
        if (!prev.contactNumber)
          prev.contactNumber = response?.data?.contact?.number;
        return { ...prev };
      });
    });
  }, []);

  document.title = windowTitle;
  useMemo(() => {
    fetchOrderData().then(fetchPrerequisite);
  }, []);

  return (
    <>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      {prerequisites && (
        <SupplierForm
          supplierData={supplierData}
          prerequisites={prerequisites}
          pageTitle={pageTitle}
        />
      )}
    </>
  );
};

export default withRouter(SupplierListCard);
