import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Autocomplete,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import {
  Add,
  KeyboardArrowDown,
  Clear as ClearIcon,
} from "@mui/icons-material";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Event } from "@mui/icons-material";
import { machineServiceJobDocTableColumnConfig } from "./data-table/machine-service-job-doc-table-column-config";
import DataTable from "react-data-table-component";
import * as path from "path";
import { useUserState } from "../../context/UserContext";
import FileUploadDownloadComponent from "./components/FileUploadDownloadComponent/FileUploadDownloadComponent";
import DateController from "../../components/DateController";

const MachineServiceJobCard = (props) => {
  const { userInfo } = useUserState();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const { serviceJobId } = useParams(); // url param => serviceJobId when editing

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
    reset,
  } = useForm({ mode: "all" });

  // un-assigned sub-con list
  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [currentServiceJob, setCurrentServiceJob] = useState(null);
  const [rowsInvalid, setRowsInvalid] = useState(false);

  const [companyList, setCompanyList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState(null);
  const [serviceDate, setServiceDate] = useState(null);
  const [plannedServiceDate, setPlannedServiceDate] = useState(null);
  const [serviceJobDocList, setServiceJobDocList] = useState([]);
  const [lstDeletedDoc, setLstDeletedDoc] = useState([]);
  const [closedDoc, setClosedDoc] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [checkListUrl, setCheckListUrl] = useState("");
  const [serviceJobUrl, setServiceJobUrl] = useState("");
  const [serviceJobStatus, setServiceJobStatus] = useState("");

  let selectedDocType = "";
  let selectedDocName = "";

  useEffect(() => {
    document.title = "New - Machine Service Job Card";
    loadPrerequisites();

    // redirect to default page if not authorized
    if (
      serviceJobId &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.machine_service_job,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    const invalid = serviceJobDocList.findIndex((r) => r.docName == "") > -1;
    setRowsInvalid(invalid);

    serviceJobDocList.forEach((doc) => {
      if (doc.docType == "check-list") {
        setCheckListUrl(doc.imageUrl);
        setValue("checkListFileName", doc.docName);
      } else if (doc.docType == "service-job") {
        setServiceJobUrl(doc.imageUrl);
        setValue("serviceJobFileName", doc.docName);
      }
    });
  }, [serviceJobDocList]);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  // get current matrix in edit view
  useEffect(() => {
    if (!isLoading && !responseData && serviceJobId) {
      sendRequest(
        `/v1/machine-service-job/get-service-job-by-id/` + serviceJobId,
        "GET",
      );
    }
  }, [serviceJobId]);

  useEffect(() => {
    if (statusList && currentServiceJob) {
      setStatus(
        statusList
          .filter((status) => status.value === currentServiceJob.status)
          .pop(),
      );
    }
  }, [currentServiceJob, statusList]);

  // set form values when edit view
  useEffect(() => {
    if (serviceJobId && responseData?.serviceJob) {
      const serviceJob = responseData?.serviceJob;
      document.title = `${serviceJob.no} - Machine Service Job Card`;
      setCurrentServiceJob(serviceJob);
    }
  }, [responseData]);

  useEffect(() => {
    if (currentServiceJob) {
      reset(currentServiceJob);
      setPlannedServiceDate(currentServiceJob.plannedServiceDate);
      setServiceDate(currentServiceJob.serviceDate);
      setServiceJobDocList(currentServiceJob.serviceJobDoc || []);
      setClosedDoc(
        currentServiceJob.status == "CLOSED" ||
          currentServiceJob.status == "VOIDED",
      );
      setServiceJobStatus(currentServiceJob.status);
    }
  }, [currentServiceJob]);

  const navigateToList = () => {
    if (!serviceJobId) window.location.href = "#/app/machine-service-job/list";
  };

  const loadPrerequisites = () => {
    sendRequest(`/v1/machine-service-job/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        setCompanyList(prerequisites.companyList);
        setStoreList(prerequisites.storeList);
        setAreaList(prerequisites.areaList);
        setStatusList(prerequisites.MachineServiceJobStatus);
      },
    );
  };

  function handleServiceJobStatusUpdate(data, e, status) {
    let statusDesc;

    if (status == "CLOSED") {
      statusDesc = "close";
    } else if (status == "DONE") {
      statusDesc = "submit";
    } else if (status == "VOIDED") {
      statusDesc = "void";
    }

    Swal.fire({
      title: `Confirmation`,
      text: `Are you sure you want to ${statusDesc} the machine service job ${currentServiceJob.no}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleSubmitForm(data, e, status);
      }
    });
  }

  const handleSubmitForm = async (data, e, status) => {
    e.preventDefault();

    if (
      (status == "DONE" || status == "CLOSED") &&
      (serviceJobDocList.findIndex((r) => r.docType == "check-list") == -1 ||
        serviceJobDocList.findIndex((r) => r.docType == "service-job") == -1)
    ) {
      setErrMessage("Check list and service job document cannot be blank.");
      return;
    }

    let serviceJob;

    if (status == "VOIDED") {
      serviceJob = currentServiceJob;
      serviceJob.status = status;
    } else {
      //uploading/deleting food images
      let result1 = null;
      let folderName = data.no;

      let lstImageUrls = [];
      let formData = new FormData();
      for (var i = 0; i < serviceJobDocList.length; i++) {
        if (serviceJobDocList[i].isNewDoc == true) {
          formData.append("file", serviceJobDocList[i].imageUrl);
        } else {
          lstImageUrls.push(serviceJobDocList[i].imageUrl);
        }
      }

      let deletedImageNames =
        lstDeletedDoc.toString() == "" ? undefined : lstDeletedDoc.toString();
      result1 = await sendRequest(
        `/v1/machine-service-job/upload-image-onto-s3/${folderName}/${deletedImageNames}`,
        "POST",
        formData,
      );

      if (result1?.status === 201) {
        serviceJobDocList
          .filter((mi) => {
            return mi.isNewDoc == true;
          })
          .forEach((m, index) => {
            m.imageUrl = result1.data.urlArray[index].url;
          });
      } else {
        return;
      }
      //

      serviceJob = {
        no: data.no,
        serviceJobNo: data.serviceJobNo.toUpperCase(),
        companyId: data.companyId,
        storeId: data.storeId,
        machineId: data.machineId,
        seqNo: data.seqNo,
        brand: data.brand,
        model: data.model,
        serialNo: data.serialNo,
        status: status,
        serviceDate: data.serviceDate,
        plannedServiceDate: data.plannedServiceDate,
        remindedDate: data.remindedDate,
        serviceJobDoc: serviceJobDocList.map((doc) => ({
          docName: doc.docName,
          imageUrl: doc.imageUrl,
          uploadedAt: doc.uploadedAt,
          docType: doc.docType,
        })),
        servicedBy:
          status == "DONE" ? userInfo._id : currentServiceJob.servicedBy,
      };
    }

    let savedServiceJob = undefined,
      message = "";
    if (currentServiceJob && serviceJobId) {
      // when edit
      serviceJob._id = currentServiceJob._id;
      const result = await sendRequest(
        `/v1/machine-service-job/${serviceJobId}`,
        "PUT",
        serviceJob,
      );
      if (result?.status === 200 && result?.data?.serviceJob) {
        message = `Machine service job ${result.data.serviceJob.no} has been updated.`;
        savedServiceJob = result?.data?.serviceJob;
      }
    } else {
      const result = await sendRequest(
        `/v1/machine-service-job`,
        "POST",
        serviceJob,
      );
      if (result?.status === 201 && result?.data?.serviceJob) {
        message = `New machine service job ${result.data.serviceJob.no} has been created.`;
        savedServiceJob = result?.data?.serviceJob;
      }
    }

    setMessage(message);
  };

  const onChangeDate_ServiceDate = (date) => {
    setValue("serviceDate", date);
    setServiceDate(date);
  };

  const onChangeDate_PlannedServiceDate = (date) => {
    setValue("plannedServiceDate", date);
    setPlannedServiceDate(date);
  };

  function getDocIndex(docType, docName) {
    let i = -1;

    serviceJobDocList.forEach((e, index) => {
      if (e.docType == docType && e.docName == docName) {
        i = index;
        return;
      }
    });

    return i;
  }

  function onRemoveDoc(docType, docName) {
    let i = getDocIndex(docType, docName);

    const rList = [...serviceJobDocList];

    if (!rList[i].isNewDoc) {
      var deletedFileName = path.basename(rList[i].url);
      setLstDeletedDoc((current) => [...current, deletedFileName]);
    } else {
      rList[i].url_Local = "";
    }

    rList[i].docName = "";
    rList[i].imageUrl = "";
    rList[i].uploadedAt = undefined;
    setServiceJobDocList(rList);
  }

  function onRemoveRowDoc(docType, docName) {
    let i = getDocIndex(docType, docName);

    onRemoveDoc(docType, docName);
    const rows = [...serviceJobDocList];
    rows.splice(i, 1);
    setServiceJobDocList([...rows]);
  }

  const columnsForDoc = machineServiceJobDocTableColumnConfig(
    onRemoveRowDoc,
    onSelectDoc,
    onRemoveDoc,
    (currentServiceJob &&
      serviceJobId &&
      !auth.checkModulePrivilege(
        PrivilegeModules.machine_service_job,
        PrivilegeActions.edit,
      )) ||
      closedDoc,
  );

  function createNewRowForDoc(type) {
    //let type = '';
    console.log("type - ", type);
    setServiceJobDocList([
      ...serviceJobDocList,
      {
        docName: "",
        imageUrl: "",
        uploadedAt: undefined,
        docType: type,
        isNewDoc: true,
      },
    ]);
  }

  function onSelectDoc(docType, docName) {
    selectedDocType = docType;
    selectedDocName = docName;
    document.getElementById("input-file-upload").click();
  }

  function setServiceDoc(e) {
    let currentIndex = getDocIndex(selectedDocType, selectedDocName);

    for (var j = 0; j < e.target.files.length; j++) {
      if (currentIndex > -1) {
        const rList = [...serviceJobDocList];
        rList[currentIndex].docName = e.target.files[j].name;
        rList[currentIndex].imageUrl = e.target.files[j];
        rList[currentIndex].url_Local = URL.createObjectURL(e.target.files[j]);
        rList[currentIndex].isNewDoc = true;
        rList[currentIndex].uploadedAt = new Date();
        setServiceJobDocList(rList);
      } else {
        setServiceJobDocList([
          ...serviceJobDocList,
          {
            docName: e.target.files[j].name,
            imageUrl: e.target.files[j],
            url_Local: URL.createObjectURL(e.target.files[j]),
            isNewDoc: true,
            uploadedAt: new Date(),
            docType: selectedDocType,
          },
        ]);
      }

      if (selectedDocType == "check-list") {
        setCheckListUrl(e.target.files[j].name);
        setValue("checkListFileName", e.target.files[j].name);
      } else if (selectedDocType == "service-job") {
        setServiceJobUrl(e.target.files[j].name);
        setValue("serviceJobFileName", e.target.files[j].name);
      }
    }
  }

  function onUploadDocClick(docType) {
    switch (docType) {
      case "check-list":
        if (checkListUrl == "") {
          onSelectDoc(docType, getValues("checkListFileName"));
        } else {
          onRemoveRowDoc(docType, getValues("checkListFileName"));
          setValue("checkListFileName", "");
          setCheckListUrl("");
        }

        break;
      case "service-job":
        if (serviceJobUrl == "") {
          onSelectDoc(docType, getValues("serviceJobFileName"));
        } else {
          onRemoveRowDoc(docType, getValues("serviceJobFileName"));
          setValue("serviceJobFileName", "");
          setServiceJobUrl("");
        }

        break;
    }
  }

  function onDownloadDocClick(docType) {
    let fileUrl = "";

    switch (docType) {
      case "check-list":
        fileUrl = checkListUrl;
        break;

      case "service-job":
        fileUrl = serviceJobUrl;
        break;
    }

    if (fileUrl.toString().includes("/")) {
      window.open(fileUrl, "_blank");
    } else {
      const url = URL.createObjectURL(fileUrl);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank"; // Open in a new tab
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <>
      <div className="form-group">
        <input
          type="file"
          className="form-control"
          id="input-file-upload"
          accept="image/*,.pdf"
          onChange={setServiceDoc}
        />
      </div>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                Machine Service Job Card
                {currentServiceJob ? ` - ${currentServiceJob.no}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button
                onClick={() =>
                  props.history.push("/app/machine-service-job/list")
                }
              >
                Cancel
              </Button>
              {currentServiceJob &&
                serviceJobId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.machine_service_job,
                  PrivilegeActions.done,
                ) &&
                currentServiceJob &&
                serviceJobId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.machine_service_job,
                  PrivilegeActions.edit,
                ) &&
                serviceJobStatus == "PENDING" && (
                  <Button
                    disabled={rowsInvalid || closedDoc}
                    className={"primary"}
                    type={"button"}
                    onClick={handleSubmit((data, e) =>
                      handleServiceJobStatusUpdate(data, e, "DONE"),
                    )}
                  >
                    Submit
                  </Button>
                )}
              {currentServiceJob &&
                serviceJobId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.machine_service_job,
                  PrivilegeActions.close,
                ) &&
                currentServiceJob &&
                serviceJobId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.machine_service_job,
                  PrivilegeActions.edit,
                ) &&
                serviceJobStatus == "DONE" && (
                  <Button
                    disabled={rowsInvalid || closedDoc}
                    className={"primary"}
                    type={"button"}
                    onClick={handleSubmit((data, e) =>
                      handleServiceJobStatusUpdate(data, e, "CLOSED"),
                    )}
                  >
                    Close
                  </Button>
                )}
              {currentServiceJob &&
                serviceJobId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.machine_service_job,
                  PrivilegeActions.cancel,
                ) &&
                currentServiceJob &&
                serviceJobId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.machine_service_job,
                  PrivilegeActions.edit,
                ) &&
                serviceJobStatus == "PENDING" && (
                  <Button
                    disabled={rowsInvalid || closedDoc}
                    className={"primary"}
                    type={"button"}
                    onClick={(e) =>
                      handleServiceJobStatusUpdate(null, e, "VOIDED")
                    }
                  >
                    Void
                  </Button>
                )}
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"company"}
                            label={"Company"}
                            variant={"outlined"}
                            {...register("company", {
                              required: { value: false },
                            })}
                            disabled={currentServiceJob && serviceJobId}
                            autoComplete={"false"}
                            error={!!formErrors?.company}
                            helperText={formErrors?.company?.message}
                            onChange={(company) => validateText(company)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"storeId"}
                            label={"Store ID"}
                            variant={"outlined"}
                            {...register("storeId", {
                              required: { value: false },
                            })}
                            disabled={currentServiceJob && serviceJobId}
                            autoComplete={"false"}
                            error={!!formErrors?.storeId}
                            helperText={formErrors?.storeId?.message}
                            onChange={(storeId) => validateText(storeId)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"area"}
                            label={"Area"}
                            variant={"outlined"}
                            {...register("area", {
                              required: { value: false },
                            })}
                            disabled={currentServiceJob && serviceJobId}
                            autoComplete={"false"}
                            error={!!formErrors?.area}
                            helperText={formErrors?.area?.message}
                            onChange={(area) => validateText(area)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"location"}
                            label={"Location"}
                            variant={"outlined"}
                            {...register("location", {
                              required: { value: false },
                            })}
                            disabled={currentServiceJob && serviceJobId}
                            autoComplete={"false"}
                            error={!!formErrors?.location}
                            helperText={formErrors?.location?.message}
                            onChange={(location) => validateText(location)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"brand"}
                            label={"Brand"}
                            variant={"outlined"}
                            {...register("brand", {
                              required: { value: false },
                            })}
                            disabled={currentServiceJob && serviceJobId}
                            autoComplete={"false"}
                            error={!!formErrors?.brand}
                            helperText={formErrors?.brand?.message}
                            onChange={(brand) => validateText(brand)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"model"}
                            label={"Model"}
                            variant={"outlined"}
                            {...register("model", {
                              required: { value: false },
                            })}
                            disabled={currentServiceJob && serviceJobId}
                            autoComplete={"false"}
                            error={!!formErrors?.model}
                            helperText={formErrors?.model?.message}
                            onChange={(model) => validateText(model)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"serialNo"}
                            label={"Serial No."}
                            variant={"outlined"}
                            {...register("serialNo", {
                              required: { value: false },
                            })}
                            disabled={currentServiceJob && serviceJobId}
                            autoComplete={"false"}
                            error={!!formErrors?.serialNo}
                            helperText={formErrors?.serialNo?.message}
                            onChange={(serialNo) => validateText(serialNo)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <DateController
                            control={control}
                            label={"Planned Service Date"}
                            name={"plannedServiceDate"}
                            required={true}
                            value={
                              plannedServiceDate
                                ? new Date(plannedServiceDate)
                                : null
                            }
                            onChange={(newValue) =>
                              onChangeDate_PlannedServiceDate(newValue)
                            }
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Autocomplete
                            id={"status"}
                            disableClearable={true}
                            disabled={true}
                            options={statusList}
                            getOptionLabel={(status) => `${status.description}`}
                            value={status}
                            onChange={(e, newValue) => setStatus(newValue)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Status"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<KeyboardArrowDown />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Details</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      id={"serviceJobNo"}
                      label={"Service Job No."}
                      variant={"outlined"}
                      {...register("serviceJobNo", {
                        required: {
                          value: true,
                          message: "Service Job No. cannot be blank.",
                        },
                      })}
                      disabled={
                        closedDoc ||
                        (currentServiceJob &&
                        serviceJobId &&
                        !auth.checkModulePrivilege(
                          PrivilegeModules.machine_service_job,
                          PrivilegeActions.edit,
                        )
                          ? true
                          : false)
                      }
                      autoComplete={"off"}
                      error={!!formErrors?.serviceJobNo}
                      helperText={formErrors?.serviceJobNo?.message}
                      onChange={(serviceJobNo) => validateText(serviceJobNo)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <DateController
                      control={control}
                      label={"Serviced Date"}
                      name={"serviceDate"}
                      required={true}
                      value={serviceDate ? new Date(serviceDate) : null}
                      onChange={(newValue) =>
                        onChangeDate_ServiceDate(newValue)
                      }
                      disabled={
                        closedDoc ||
                        (currentServiceJob &&
                        serviceJobId &&
                        !auth.checkModulePrivilege(
                          PrivilegeModules.machine_service_job,
                          PrivilegeActions.edit,
                        )
                          ? true
                          : false)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <FileUploadDownloadComponent
                      fileType={"check-list"}
                      fileLabel={"Check List Document"}
                      uploadedFileName={getValues("checkListFileName")}
                      onFileUpload={onUploadDocClick}
                      onFileDownload={onDownloadDocClick}
                      onFileRemove={onUploadDocClick}
                      disableChanges={closedDoc}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <FileUploadDownloadComponent
                      fileType={"service-job"}
                      fileLabel={"Service Job Document"}
                      uploadedFileName={getValues("serviceJobFileName")}
                      onFileUpload={onUploadDocClick}
                      onFileDownload={onDownloadDocClick}
                      onFileRemove={onUploadDocClick}
                      disableChanges={closedDoc}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className="hide_this">
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant={"h2"}>Check List Document</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columnsForDoc}
                      data={serviceJobDocList.filter((e) => {
                        return e.docType == "check-list";
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={(e) => createNewRowForDoc("check-list")}
                      disabled={
                        rowsInvalid ||
                        (currentServiceJob &&
                          serviceJobId &&
                          !auth.checkModulePrivilege(
                            PrivilegeModules.machine_service_job,
                            PrivilegeActions.edit,
                          )) ||
                        serviceJobDocList.filter((e) => {
                          return e.docType == "check-list";
                        }).length > 0
                      }
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className="hide_this">
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant={"h2"}>Service Job Document</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columnsForDoc}
                      data={serviceJobDocList.filter((e) => {
                        return e.docType == "service-job";
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={(e) => createNewRowForDoc("service-job")}
                      disabled={
                        rowsInvalid ||
                        (currentServiceJob &&
                          serviceJobId &&
                          !auth.checkModulePrivilege(
                            PrivilegeModules.machine_service_job,
                            PrivilegeActions.edit,
                          )) ||
                        serviceJobDocList.filter((e) => {
                          return e.docType == "service-job";
                        }).length > 0
                      }
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
};

export default withRouter(MachineServiceJobCard);
