import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Backdrop, Typography, CircularProgress, Grid, TextField, Button } from "@mui/material";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import { getFromDate } from "../../helper/list-filters-helper";
import * as moment from 'moment';
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";

const TaskTrackingCard = (props) => {

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();

    // redirect to default page if not authorized
    if(auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.task_tracking,PrivilegeActions.add)){ 
        props.history.push('/app/dashboard');
    }


    // handling form when adding / editing
    const { handleSubmit, register, formState: { errors: formErrors }, setValue }
        = useForm({ mode: "all" });

    const { staffId, date, slot } = useParams();

    const [staffList, setStaffList] = useState([]);
    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        document.title = "New - Task Tracking Card";
        loadStaffUsers();
    }, []);

    useEffect(() => {
        if (staffId && date && slot) {
            setValue('date', moment(date).format('DD/MM/YYYY'));
            setValue('timeSlot', moment(slot, 'HH:mm').format('ha'));
        }
    }, [staffId, date, slot]);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
            setMessage('');
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    // navigate back to the list
    const navigateToList = () => {
        window.location.href = '#/app/task-tracking/list';
    }

    // populate staff user list 
    function loadStaffUsers() {
        sendRequest(`/v1/user/get-list-by-role/staff`, "GET", {})
            .then(response => {
                setStaffList(response.data?.users || []);
            });
    }

    // when submit the task tracking form
    const handleSubmitForm = async (data, e) => {
        e.preventDefault();
        const taskTracking = {
            date: getFromDate(data.date),
            staff: staffId,
            timeline: [{
                timeSlot: slot,
                duration: data.duration,
                description: data.description
            }]
        };
        const result = await sendRequest(`/v1/task-tracking`, "POST", taskTracking);
        if (result?.status === 201 && result?.data?.taskTrackingRecord) {
            setMessage(`New Task tracking record has been created.`);
        }
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Task Tracking Card</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/task-tracking/list')}>Cancel</Button>
                        <Button className={"primary"} type={"button"}
                            onClick={handleSubmit(handleSubmitForm)}>Save</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                                                <TextField id={"date"} label={"Date"} variant={"outlined"}
                                                    {...register("date")} disabled={true} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                                                <TextField id={"timeSlot"} label={"Time Slot"} variant={"outlined"}
                                                    {...register("timeSlot")} disabled={true} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                                                <TextField id={"duration"} label={"Duration (Hour)"} variant={"outlined"}
                                                    {...register("duration", {
                                                        min: { value: 1, message: "Minimum duration must be 1." },
                                                        required: { value: true, message: "Duration cannot be blank." }
                                                    })}
                                                    autoComplete={"off"}
                                                    type={"number"}
                                                    defaultValue={1}
                                                    error={!!formErrors?.duration}
                                                    helperText={formErrors?.duration?.message}
                                                    onChange={(event) => validateText(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} xl={9}>
                                                <TextField id={"description"} label={"Description"}
                                                    variant={"outlined"}
                                                    {...register("description", {
                                                        required: { value: true, message: "Description cannot be blank." }
                                                    })}
                                                    error={!!formErrors?.description}
                                                    helperText={formErrors?.description?.message}
                                                    multiline
                                                    rows={2}
                                                    maxRows={10}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(TaskTrackingCard);