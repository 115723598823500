import { Add } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { PrivilegeActions } from "../../../../data/privileges.enum";
import { costMarginTableConfig } from "../../data-table/cost-margin-table-config";
import * as _ from "lodash";
import { getProfit, getProfitMargin } from "../../helper/calculations";
import { convertThisToCurrency } from "../../../../helper/convert-this-to-currency";
import { PR_PANELS } from "../../helper/panels";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../../../context/LayoutContext";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { SMALL_DEVICE_WIDTH } from "../../../../data/constants";
import CostMarginDocumentsDrawer from "../Drawer/CostMarginDocumentsDrawer";
import { isApproved, isAwarded, PR_STATUS } from "../../helper/pr-config";

const WorkflowCostMargin = (props) => {
  const [costMarginRecord, setCostMarginRecord] = useState({
    record: null,
    index: -1,
  });
  const [lastRowValid, setLastRowValid] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [costMarginFilter, setCostMarginFilter] = useState([]);
  const [costMarginHidden, setCostMarginHidden] = useState([]);
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (props.costMarginList) {
      const costMarginListClone = [...props.costMarginList];
      const costMarginFilter = [];
      const costMarginHidden = [];
      costMarginListClone.forEach((costMargin) => {
        if (costMargin.hidden === true) {
          costMarginHidden.push(costMargin);
        } else {
          costMarginFilter.push(costMargin);
        }
      });
      setCostMarginFilter(costMarginFilter);
      setCostMarginHidden(costMarginHidden);
    }
  }, [props.costMarginList]);
  useEffect(() => {
    const totalCost = _.sum(props.costMarginList.map((cm) => cm.cost));
    const contractValue = _.sum(
      props.costMarginList.map((cm) => cm.sellingPrice),
    );
    const profit = getProfit(contractValue, totalCost);
    const profitMargin = getProfitMargin(profit, contractValue);
    props.setTotalCost(totalCost);
    props.setContractValue(contractValue);
    props.setProfit(profit);
    props.setProfitMargin(
      isNaN(profitMargin) || profitMargin === -Infinity ? 0 : profitMargin,
    );
  }, [props.costMarginList]);

  useEffect(() => {
    const lastRow = costMarginFilter?.at(costMarginFilter?.length - 1);
    setLastRowValid(
      costMarginFilter?.length === 0 ||
        (!!lastRow?.description && !!lastRow?.cost),
    );
  }, [costMarginFilter]);

  useEffect(() => {
    if (costMarginRecord?.record && costMarginRecord?.index > -1) {
      loadDocumentDrawer();
      onChangeRowValues(costMarginRecord?.index, documents, "documents");
    }
  }, [documents]);

  useEffect(() => {
    if (costMarginRecord?.record && costMarginRecord?.index > -1) {
      loadDocumentDrawer();
    }
  }, [props?.removedDocuments]);

  const hasPermissionToEdit = () =>
    props.hasPrivilege(PrivilegeActions.edit_cost_margin_projected);

  const drawerProps = {
    step: props?.step,
    status: props?.projectRequest?.status,
    permissionToEdit: hasPermissionToEdit(),
    documents,
    setDocuments,
    costMarginRecord,
    removedDocuments: props?.removedDocuments,
    setRemovedDocuments: props?.setRemovedDocuments,
    allowedFileTypes: props?.allowedFileTypes,
    submittedForApproval: props?.projectRequest?.submittedForApproval,
  };

  const columns = costMarginTableConfig(
    getUniqueMatrixList(props?.itemMatrix) || [],
    hasPermissionToEdit(),
    props?.projectRequest?.submittedForApproval,
    openDocumentDrawer,
    onRemoveRow,
    onChangeRowValues,
    props?.projectRequest?.costMarginApprovers,
    props?.step,
    props?.projectRequest?.status,
    props.version,
  );

  function getUniqueMatrixList(list) {
    return list.map((x) => x.item).filter((x, i, a) => a.indexOf(x) === i);
  }

  function createNewRow() {
    props.setCostMarginList([
      ...props.costMarginList,
      {
        description: "",
        cost: 0,
        sellingPrice: 0,
        vendor: "",
        remarks: "",
        status: PR_STATUS.PENDING,
        approvedDate: undefined,
        approvedBy: undefined,
        documents: [],
        description2: "",
      },
    ]);
  }

  function onRemoveRow(row, i) {
    const rows = [...costMarginFilter];
    rows.splice(i, 1);
    // delete all
    props.setRemovedDocuments([
      ...props?.removedDocuments,
      ...row.documents?.map((d) => d.fileName),
    ]);
    props.setCostMarginList([...rows, ...costMarginHidden]);
  }

  function onChangeRowValues(i, value, attribute) {
    const cmList = [...costMarginFilter];
    if (attribute === "vendor" || attribute === "remarks" || attribute === "description2") {
      value = value.toUpperCase();
    } else if (attribute === "sellingPrice" || attribute === "cost") {
      value = +value;
    } else if (attribute === "documents") {
      value = value ?? [];
    }
    cmList.at(i)[attribute] = value;
    props.setCostMarginList([...cmList, ...costMarginHidden]);
  }

  async function openDocumentDrawer(costMarginRecord, index) {
    if (costMarginRecord) {
      setCostMarginRecord({ record: costMarginRecord, index });
      setDocuments(costMarginRecord.documents ?? []);
      loadDocumentDrawer();
    }
  }

  function loadDocumentDrawer(
    backButton = true,
    showBackDropInLargeScreen = false,
  ) {
    const size = width <= SMALL_DEVICE_WIDTH ? "xs" : "lg";
    setRighbarContent(
      layoutDispatch,
      [<CostMarginDocumentsDrawer key={"rb-body"} {...drawerProps} />],
      { size, backButton, showBackDropInLargeScreen },
    );
    if (!layoutState.isRightbarOpened) {
      toggleRightbar(layoutDispatch);
    }
  }

  return (
    <>
      <Grid container className={"form"} id={PR_PANELS.COST_MARGIN_PROJECTED}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Typography variant={"h2"}>Cost & Margin (Projected)</Typography>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  className={"summary"}
                >
                  <Typography variant={"h3"}>
                    Total Cost:{" "}
                    {
                      <span>
                        {convertThisToCurrency(props.totalCost?.toFixed(2))}
                      </span>
                    }
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  className={"summary"}
                >
                  <Typography variant={"h3"}>
                    Contract Value:{" "}
                    {
                      <span>
                        {convertThisToCurrency(props.contractValue?.toFixed(2))}
                      </span>
                    }
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  className={"summary"}
                >
                  <Typography variant={"h3"}>
                    Profit Margin:{" "}
                    {
                      <span>
                        {convertThisToCurrency(props.profit?.toFixed(2))} (
                        {props.profitMargin?.toFixed(2)}%)
                      </span>
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={"table"}>
              <DataTable
                fixedHeader={true}
                persistTableHead={true}
                columns={columns}
                data={costMarginFilter}
                customStyles={{
                  rows: {
                    style: {
                      alignItems: "baseline",
                    },
                  },
                }}
              />
            </Grid>
            {hasPermissionToEdit() &&
              !props?.projectRequest?.submittedForApproval &&
              !isApproved(props?.projectRequest?.status) &&
              !isAwarded(props?.projectRequest?.status) && (
                <Grid item xs={12} className={"table-action"}>
                  <IconButton onClick={createNewRow} disabled={!lastRowValid}>
                    <Add />
                  </IconButton>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkflowCostMargin;
