import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.css";
import moment from "moment";

const ProjectCostingSummary = (props) => {
  const projectRequest = props.projectRequest;
  const [costMarginItems, setCostMarginItems] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [profitMargin, setProfitMargin] = useState(0);
  const [profitMarginPerc, setProfitMarginPerc] = useState(0);

  useEffect(() => {
    let tempCostMarginItems = [];
    let costTotal = 0,
      priceTotal = 0,
      profitTotal = 0;

    projectRequest.costMarginList.forEach((item, index) => {
      if (!item.hidden) {
        tempCostMarginItems.push({
          srNo: index + 1,
          description: item.description + " - " + item.description2,
          cost: item.cost,
          remarks: item.remarks,
        });

        costTotal += item.cost;
        priceTotal += item.sellingPrice;

        tempCostMarginItems.push(getEmptyCostMarginLine());
      }
    });

    profitTotal = priceTotal - costTotal;
    setTotalCost(costTotal);
    setTotalPrice(priceTotal);
    setProfitMargin(profitTotal);
    setProfitMarginPerc((profitTotal / priceTotal) * 100);

    for (let i = tempCostMarginItems.length; i < 20; i++) {
      tempCostMarginItems.push(getEmptyCostMarginLine());
    }

    setCostMarginItems(tempCostMarginItems);
    loadSignature();
  }, [projectRequest]);

  // Upload signature into e-sign box when existed
  const loadSignature = () => {
    props.setVerifySignPic(
      projectRequest.signed_VERIFY_Signature
        ? projectRequest.signed_VERIFY_Signature
        : "",
    );
    props.setSignPic(
      projectRequest.signature ? projectRequest.signature.url : "",
    );
    props.setSigned_VERIFY_Date(projectRequest.signed_VERIFY_Date);
    props.setSigned_VERIFY_Name(projectRequest.signed_VERIFY_Name);

    if (props.isSignable) {
      props.setSignEmpty(false);
    }
  };

  const getEmptyCostMarginLine = () => {
    return {
      srNo: "",
      description: "",
      cost: "",
      remarks: "",
    };
  };

  const formatNumber = (num) => {
    return parseFloat(num).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <>
      <section class="pnl-form">
        <h1>Project Costing Summary</h1>
        <div class="sc-01">
          <h2>HOD Acknowledgement (Order Processing Stage)</h2>
          <dl>
            <dt>
              <h3>Finance</h3>
              <div data-name="approver">
                {!!projectRequest.signed_FINANCE_Date && (
                  <>
                    <img src={projectRequest.signed_FINANCE_Signature} />
                    <span>{projectRequest.signed_FINANCE_Name}</span>
                    <span>
                      {moment(projectRequest.signed_FINANCE_Date).format(
                        "DD/MM/YYYY HH:mm:ss",
                      )}
                    </span>
                  </>
                )}
                {!projectRequest.signed_FINANCE_Date && <label>N.A</label>}
              </div>
              <label>Signature / Date</label>
            </dt>
            <dt>
              <h3>QS</h3>
              <div data-name="approver">
                {!!projectRequest.signed_QS_Date && (
                  <>
                    <img src={projectRequest.signed_QS_Signature} />
                    <span>{projectRequest.signed_QS_Name}</span>
                    <span>
                      {moment(projectRequest.signed_QS_Date).format(
                        "DD/MM/YYYY HH:mm:ss",
                      )}
                    </span>
                  </>
                )}
                {!projectRequest.signed_QS_Date && <label>N.A</label>}
              </div>
              <label>Signature / Date</label>
            </dt>
            <dt>
              <h3>Purchasing</h3>
              <div data-name="approver">
                {!!projectRequest.signed_PURCHASING_Date && (
                  <>
                    <img src={projectRequest.signed_PURCHASING_Signature} />
                    <span>{projectRequest.signed_PURCHASING_Name}</span>
                    <span>
                      {moment(projectRequest.signed_PURCHASING_Date).format(
                        "DD/MM/YYYY HH:mm:ss",
                      )}
                    </span>
                  </>
                )}
                {!projectRequest.signed_PURCHASING_Date && <label>N.A</label>}
              </div>
              <label>Signature / Date</label>
            </dt>
            <dt>
              <h3>Operations</h3>
              <div data-name="approver">
                {!!projectRequest.signed_OPERATION_Date && (
                  <>
                    <img src={projectRequest.signed_OPERATION_Signature} />
                    <span>{projectRequest.signed_OPERATION_Name}</span>
                    <span>
                      {moment(projectRequest.signed_OPERATION_Date).format(
                        "DD/MM/YYYY HH:mm:ss",
                      )}
                    </span>
                  </>
                )}
                {!projectRequest.signed_OPERATION_Date && <label>N.A</label>}
              </div>
              <label>Signature / Date</label>
            </dt>
            <dt>
              {/* <h3>{`${projectRequest.company.code} Director`}</h3> */}
              <h3>Director</h3>
              <div data-name="approver">
                {!!projectRequest.signed_DIRECTOR_Date && (
                  <>
                    <img src={projectRequest.signed_DIRECTOR_Signature} />
                    <span>{projectRequest.signed_DIRECTOR_Name}</span>
                    <span>
                      {moment(projectRequest.signed_DIRECTOR_Date).format(
                        "DD/MM/YYYY HH:mm:ss",
                      )}
                    </span>
                  </>
                )}
                {!projectRequest.signed_DIRECTOR_Date && <label>N.A</label>}
              </div>
              <label>Signature / Date</label>
            </dt>
          </dl>
          <dl>
            <dt
              onClick={() => {
                if (props.isSignable) {
                  props.setEcMode(true);
                  props.handleOpenCloseSignaturePad(true);
                }
              }}
            >
              {!props.signPic && (
                <>
                  <div class="btn-sign">Click Here to Sign</div>
                </>
              )}
              {!!props.signPic && (
                <>
                  <h3>EC</h3>
                  <div data-name="approver">
                    <img src={props.signPic} />
                    {!!projectRequest.signed_EC_Date && (
                      <>
                        <span>{projectRequest.signed_EC_Name}</span>
                        <span>
                          {moment(projectRequest.signed_EC_Date).format(
                            "DD/MM/YYYY HH:mm:ss",
                          )}
                        </span>
                      </>
                    )}
                    {!projectRequest.signed_EC_Date && <label>N.A</label>}
                  </div>
                  <label>Signature / Date</label>
                </>
              )}
            </dt>
          </dl>
        </div>
        {!!props.verifySignPic && (
          <div class="sc-01 sc-float">
            <dl>
              <dt>
                <div data-name="approver">
                  <img src={props.verifySignPic} />
                  {!!props.signed_VERIFY_Date && (
                    <>
                      <span>{props.signed_VERIFY_Name}</span>
                      <span>
                        {moment(props.signed_VERIFY_Date).format(
                          "DD/MM/YYYY HH:mm:ss",
                        )}
                      </span>
                    </>
                  )}
                </div>
              </dt>
            </dl>
          </div>
        )}
        <div class="sc-02">
          <h2>{projectRequest.company.name}</h2>
          <dl>
            <dt>
              <label>Project Title</label>
              <div>{projectRequest.projectTitle.toUpperCase()}</div>
            </dt>
            <dt>
              <label>Location</label>
              <div>{projectRequest.location.toUpperCase()}</div>
            </dt>
            <dt>
              <label>Customer</label>
              <div>{projectRequest.customer.toUpperCase()}</div>
            </dt>
            <dt>
              <label>Ref. No.</label>
              <div>{projectRequest.internalReferenceNo.toUpperCase()}</div>
            </dt>
          </dl>
          <dl>
            <dt>
              <label>Prepared By</label>
              <div>{projectRequest.projectManager.name}</div>
            </dt>
            <dt>
              <label>Prepared At</label>
              <div>{projectRequest.submitDateTime}</div>
            </dt>
          </dl>
        </div>
        {!!costMarginItems && (
          <>
            <div class="sc-03">
              <table>
                <tr>
                  <th>S/No.</th>
                  <th>Item / Description</th>
                  <th>Cost (S$)</th>
                  <th>Remarks</th>
                </tr>
                {costMarginItems.map((row, index) => (
                  <>
                    <tr key={index}>
                      <td>{row.srNo}</td>
                      <td>{row.description}</td>
                      <td>
                        {row.cost == "" || formatNumber(row.cost.toFixed(2))}
                      </td>
                      <td>{row.remarks}</td>
                    </tr>
                  </>
                ))}
              </table>
            </div>
            <div class="sc-04">
              <dl>
                <dt>
                  <label>Total Cost</label>
                  <div>S$ {formatNumber(totalCost)}</div>
                </dt>
                <dt>
                  <label>Profit Margin</label>
                  <div>S$ {formatNumber(profitMargin)}</div>
                </dt>
                <dt>
                  <label></label>
                  <div>{profitMarginPerc.toFixed(2)}%</div>
                </dt>
                <dt>
                  <label>Selling Price</label>
                  <div>S$ {formatNumber(totalPrice)}</div>
                </dt>
                <dt>
                  <label>Contract Start</label>
                  <div>
                    {moment(projectRequest.estimatedContractStart).format(
                      "DD/MM/YYYY",
                    )}
                  </div>
                </dt>
                <dt>
                  <label>Contract Completion</label>
                  <div>
                    {moment(projectRequest.estimatedContractCompletion).format(
                      "DD/MM/YYYY",
                    )}
                  </div>
                </dt>
                <dt>
                  <label>Validity</label>
                  <div>{projectRequest.validity}</div>
                </dt>
                <dt>
                  <label>Credit Limit</label>
                  <div></div>
                </dt>
              </dl>
            </div>
            <div class="sc-05">
              <table>
                <tr>
                  <th>Finance Department</th>
                  <td>1 Time</td>
                </tr>
                <tr>
                  <th>Deposit</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.deposit > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.deposit,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>1st Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.firstClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.firstClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>2nd Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.secondClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.secondClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>3rd Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.thirdClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.thirdClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>4th Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.forthClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.forthClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>5th Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.fifthClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.fifthClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>6th Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.sixthClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.sixthClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>7th Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.seventhClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected
                              ?.seventhClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>8th Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.eighthClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.eighthClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Final Claim</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.finalClaim > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.finalClaim,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Retention</th>
                  <td>
                    <div>
                      {projectRequest.claimsDepositsProjected?.retention > 0
                        ? formatNumber(
                            projectRequest.claimsDepositsProjected?.retention,
                          )
                        : ""}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default withRouter(ProjectCostingSummary);
