import React from "react";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
    KeyboardArrowDown as ArrowDownIcon,
    Clear as ClearIcon, Event as CalendarIcon
} from "@mui/icons-material";
import DateFilter from "../../../../components/DateFilter";

export default function MobileBracketFilters({
    welderList,
    welderFilter, setWelderFilter,
    fromFilter, setFromFilter,
    toFilter, setToFilter,
    issuedList, issuedFilter, setIssuedFilter
}) {
    return (<>
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"welder-filter-mobile"}
                    options={welderList}
                    getOptionLabel={(option) => `${option.username}-${option.name}`}
                    value={welderFilter}
                    onChange={(e, newValue) => setWelderFilter(newValue)}
                    renderInput={(params) => <TextField {...params}
                        label={"Welder"} variant={"outlined"} />}
                    popupIcon={<ArrowDownIcon />} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                    id={"issued-filter-mobile"}
                    options={issuedList}
                    getOptionLabel={(option) => option.toUpperCase()}
                    value={issuedFilter}
                    onChange={(e, newValue) => setIssuedFilter(newValue)}
                    renderInput={(params) => <TextField {...params}
                        label={"Issued"} variant={"outlined"} />}
                    popupIcon={<ArrowDownIcon />} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateFilter
                label={"From"}
                value={fromFilter}
                maxDate={toFilter}
                onChange={(newValue) => setFromFilter(newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateFilter
                label={"To"}
                value={toFilter}
                minDate={fromFilter}
                onChange={(newValue) => setToFilter(newValue)}
              />
            </Grid>
        </Grid>
    </>);
};
