import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
    tagClearIcon: {
        margin: "0 0 0 2.5px !important",
        width: "16px !important",
        height: "16px !important",
        cursor: 'pointer'
    },
    tagSection: {
        margin: "0 0.2rem 0 0",
        display: "inline-flex",
        flexWrap: "wrap",
        width: "initial"
    },
    tag: {
        height: "100%",
        width: "max-content",
        display: "inline-flex",
        margin: "0 0 0 7px"
    }
}));
