import React from "react";

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case "TOGGLE_RIGHTBAR":
      return { ...state, isRightbarOpened: !state.isRightbarOpened };
    case "SET_RIGHTBAR_CONTENT":
      return { ...state, rbContent: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: window.innerWidth >= 900,
    isRightbarOpened: false,
    rbContent: {
      html: [],
      config: {
        size: 'xs',
        backButton: true,
        showBackDropInLargeScreen: false
      }
    }
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, toggleRightbar, setRighbarContent };

// ###########################################################
function toggleSidebar(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });
}

function toggleRightbar(dispatch) {
  dispatch({
    type: "TOGGLE_RIGHTBAR",
  });
}

function setRighbarContent(dispatch, html, config) {
  dispatch({
    type: "SET_RIGHTBAR_CONTENT",
    payload: { html, config }
  });
}