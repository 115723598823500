import {
    Button, TextField, DialogTitle,
    DialogContent, Dialog, DialogActions, Grid, Typography, CircularProgress
} from "@mui/material";
import { validateText } from "../../../../helper/validate-textfield";

const ProjectRequestRejectModal = (props) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;

        props.setOpen(false);
    };

    const handleRejectReason = (event) => {
        validateText(event);
        props?.setRejectReason(event.target.value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            open={props.open}
            onClose={handleClose}
            disableEscapeKeyDown={true}
            maxWidth={"sm"}>
            <DialogTitle component={"div"}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Reject</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <TextField
                    id={"reason"}
                    label={"Comment"}
                    variant={"outlined"}
                    autoFocus
                    fullWidth
                    defaultValue={props.rejectReason}
                    onChange={handleRejectReason}
                    autoComplete={"off"}
                />
            </DialogContent>
            <DialogActions className={"action"} >
                <Button
                    className={"primary"}
                    disabled={!props.rejectReason || props.isLoading}
                    startIcon={props.isLoading && <CircularProgress style={{ color: '#b1b1b1' }} size={18} />}
                    onClick={(e) => props.onSubmitReject(e)}>Proceed</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProjectRequestRejectModal;   