import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, FormControlLabel, Switch
} from "@mui/material";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";

const BracketBatchCard = (props) => {

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization 
    const { auth } = useRoleAuthorization();
    
    // handling form when adding / editing
    const { handleSubmit, register, formState: { errors: formErrors } }
        = useForm({ mode: "all" });

    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [enableSwitchValue, setEnableSwitchValue] = useState(true);

    useEffect(() => {
        document.title = "New - Bracket A/B Batch Card";

        // redirect to default page if not authorized
        if (auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.bracket_batch, PrivilegeActions.add)) {
            props.history.push('/app/dashboard');
        }
    }, []);

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    // navigate back to bracket batch list
    const navigateToList = () => {
        window.location.href = '#/app/bracket-batch/list';
    }

    // when submit the bracket batch form
    const handleSubmitForm = async (data, e) => {
        e.preventDefault();
        const batch = {
            batchNo: data.batchNo.trim().toUpperCase(),
            remark: data.remark?.trim(),
            enable: enableSwitchValue ? 'Yes' : 'No'
        };
        const result = await sendRequest('/v1/bracket-batch', "POST", batch);
        if (result?.status === 201 && result?.data?.result) {
            setMessage(`New bracket A/B batch ${batch.batchNo} has been created.`); // call show feedback message
        }
    }


    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>Bracket A/B Batch Card - New</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/bracket-batch/list')}>Cancel</Button>
                        {
                            (auth.checkModulePrivilege(PrivilegeModules.bracket_batch, PrivilegeActions.add) || auth.checkModulePrivilege(PrivilegeModules.bracket_batch, PrivilegeActions.edit))
                            &&
                            <Button className={"primary"} type={"button"} onClick={handleSubmit(handleSubmitForm)}>Save</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField id={"batch-no"} label={"Batch No."} variant={"outlined"}
                                                    {...register("batchNo", {
                                                        required: { value: true, message: "Batch No. cannot be blank." },
                                                        maxLength: { value: 50, message: "Maximum 50 characters are allowed." }
                                                    })}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.batchNo}
                                                    helperText={formErrors?.batchNo?.message}
                                                    onChange={(event) => validateText(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField id={"remark"} label={"Remark"} variant={"outlined"}
                                                    // {...register("remark", {
                                                    //     required: { value: true, message: "Remark cannot be blank." }
                                                    // })}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.remark}
                                                    helperText={formErrors?.remark?.message}
                                                    onChange={(event) => validateText(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <FormControlLabel
                                                    labelPlacement="start"
                                                    control={<Switch
                                                        {...register("enable")}
                                                        checked={enableSwitchValue}
                                                        onChange={(e) => setEnableSwitchValue(e.target.checked)} />}
                                                    label="Enable" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(BracketBatchCard);