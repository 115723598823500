import { Link } from "react-router-dom";

export const columns = [
  {
    name: "Vendor Code",
    selector: (row) => (
      <Link
        to={`/app/fnb-supplier-list/${row.vendorCode}`}
        title={row.vendorCode}
      >
        {row.vendorCode}
      </Link>
    ),
    width: "100px",
    sortable: true,
    sortField: "vendorCode",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    width: "450px",
    wrap: true,
    sortable: true,
    sortField: "name",
  },
  {
    name: "Contact Name",
    selector: (row) => row.contactName,
    width: "250px",
    wrap: true,
    sortable: true,
    sortField: "contactName",
  },
  {
    name: "Contact No.",
    selector: (row) => (
      <a href={`tel:${row.contactNumber}`}>{row.contactNumber}</a>
    ),
    width: "100px",
    wrap: true,
    sortable: true,
    sortField: "contactNumber",
  },
  {
    name: "Stores",
    selector: (row) => (row.stores || []).map((s) => <dt>{s.storeCode}</dt>),
    width: "150px",
    wrap: true,
  },
  {
    name: "Emails",
    selector: (row) =>
      row.contactEmails?.map((mail) => (
        <dt>
          <a key={mail} href={`mailto:${mail}`}>
            {mail}
          </a>
        </dt>
      )),
    minWidth: "250px",
    wrap: true,
  },
];
