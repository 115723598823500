import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import { IconButton, InputAdornment, Switch, TextField } from "@mui/material";
import TextFieldController from "../../../components/TextFieldController";
import AutocompleteController from "../../../components/AutocompleteController";
export const airconItemTableColumnConfig = ({
  onRemoveRow,
  onChangeItem,
  systems,
  name,
}) => [
  {
    name: "",
    width: "50px",
    cell: (row, index) => (
      <IconButton onClick={() => onRemoveRow(name, index)}>
        <DeleteIcon />
      </IconButton>
    ),
  },
  {
    name: "System",
    width: "200px",
    cell: (row, index) => {
      return (
        <AutocompleteController
          name={`${name}.${index}.system`}
          required
          label={"System"}
          options={systems}
          hiddenLabel
          getOptionLabel={(i) => `${i.option ?? ""}`}
        />
      );
    },
  },
  {
    name: "Combination",
    width: "300px",
    cell: (row, index) => {
      return (
        <TextFieldController
          name={`${name}.${index}.combination`}
          required
          variant={"outlined"}
          label={"Combination"}
          hiddenLabel={true}
        />
      );
    },
  },
  {
    name: "Description",
    width: "300px",
    cell: (row, index) => {
      return (
        <TextFieldController
          name={`${name}.${index}.description`}
          required
          variant={"outlined"}
          label={"Description"}
          hiddenLabel={true}
        />
      );
    },
  },
  {
    name: "Monthly (36 Months)",
    width: "150px",
    right: true,
    cell: (row, index) => {
      return (
        <TextFieldController
          name={`${name}.${index}.price.36Months`}
          required
          className={"dollar"}
          variant={"outlined"}
          label={"Monthly (36 Months)"}
          hiddenLabel={true}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type={"number"}
        />
      );
    },
  },
  {
    name: "Monthly (60 Months)",
    width: "150px",
    right: true,
    cell: (row, index) => {
      return (
        <TextFieldController
          name={`${name}.${index}.price.60Months`}
          required
          variant={"outlined"}
          className={"dollar"}
          label={"Monthly (60 Months)"}
          hiddenLabel={true}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type={"number"}
        />
      );
    },
  },
];
