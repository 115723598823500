import React, { useCallback, useMemo, useState } from "react";
import { Grid, InputAdornment, Switch, TextField } from "@mui/material";
import DataTable from "react-data-table-component";
import { Search, KeyboardArrowDown } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { useHttpRequest } from "../../../../hooks/useHttpRequest";
import { convertThisToCurrency } from "../../../../helper/convert-this-to-currency";
import { useRoleAuthorization } from "../../../../hooks/useRoleAuthorization";
import { PrivilegeActions, PrivilegeModules } from "../../../../data/privileges.enum";

export default function ItemPopup(props) {

  const handleItemEnable = (event, rowItem) => {
    const currentIndex = Object.keys(props.items).indexOf(rowItem._id);
    const newChecked = { ...props.items };

    if (currentIndex === -1) {
      rowItem.requestedQty = 1;
      newChecked[rowItem._id] = rowItem;
    } else {
      delete newChecked[rowItem._id];
    }

    props.setItems(newChecked);
  };

  // State
  const [textFilter, setTextFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [materialGroupFilter, setMaterialGroupFilter] = useState(null);
  const [listProducts, setListProducts] = useState([]);
  const [materialGroups, setMaterialGroups] = useState([]);
  const { auth } = useRoleAuthorization();
  // Get List Of Items
  const { sendRequest } = useHttpRequest();

  useMemo(async () => {
    sendRequest("/v1/material-item", "GET", {})
      .then(productListApiResponse => {
        // take only enabled items
        const list = productListApiResponse.data?.itemList.filter(i => i.enable);
        setListProducts(list || []);
        return productListApiResponse.data?.itemList;
      });
    sendRequest(`/v1/material-group/filter?filters={"enable":true}`, "GET", {})
      .then(response => {
        setMaterialGroups(response.data?.materialGroupList || []);
      });
  }, [sendRequest]);

  const filteredList = (list) => {
    return list.filter(item => {
      let filterFlag = true;
  
      if (textFilter) {
        const compoundText = item.category.toLowerCase() + item.code.toLowerCase() + item.description.toLowerCase();
        filterFlag &= compoundText.includes(textFilter.toLowerCase());
      }
  
      if (categoryFilter) {
        filterFlag &= (item.category === categoryFilter);
      }
  
      if(materialGroupFilter) {
        filterFlag &= (item?.withdrawalGroups?.find((item) => item._id === materialGroupFilter._id) != null);
      }
      return filterFlag;
    });
  }

  const data = filteredList(listProducts)

  const handleSelectAll = (e, checked, data = [],) => {
    if(checked) {
      const mapData = data.map((item) => {
        item = {
          ...item,
          requestedQty: 1
        }
        return item
      })

      const newChecked = [];

      mapData.map((item) => {
        newChecked[item._id] = item
      })
      
      props.setItems(newChecked);
    } else {
      props.setItems({});
    }
  }

  const callBackFormRender = useCallback((row) => {
    return <Switch checked={Object.keys(props.items).indexOf(row._id) !== -1}
      onChange={(e) => handleItemEnable(e, row)} />;
  }, [props.items])

  
  const allCheckboxRender = useCallback(() => {
    return <Switch checked={Object.keys(props.items).length === data.length && data.length > 0}
      onChange={(e, checked) => {
        handleSelectAll(e, checked, data)
      }}/> 
  }, [props.items, data])

  const columns = useMemo(() => {
    return [
      {
        name: allCheckboxRender(),
        width: "90px",
        sortable: false,
        selector: row => callBackFormRender(row),
      },
      {
        name: "Code",
        width: "90px",
        selector: row => row.code,
        sortable: true,
      },
      {
        name: "Description",
        minWidth: "300px",
        wrap: true,
        cell: (row, index, column, id) => {
          return row.description + ' ' + row.model
        },
        selector: row => row.description,
        sortable: true,
      },
      {
        name: "Category",
        width: "250px",
        wrap: true,
        selector: row => row.category,
        sortable: true,
      },
      {
        name: "UOM",
        width: '70px',
        selector: row => row.unit,
        sortable: true,
      },
      {
        name: "Unit Price",
        width: '100px',
        right: true,
        selector: row => row.price,
        cell: (row, index, column, id) => {
          return convertThisToCurrency(row.price.toFixed(2))
        },
        sortable: true,
      },
    ];
  }, [callBackFormRender, allCheckboxRender]);

  // Custom Variable
  const listUniqueCategory = useMemo(() => {
    return [...new Set(listProducts.map(item => item.category))].sort();
  }, [listProducts]);

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <TextField
          variant={"outlined"}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <Autocomplete
          id={"category-filter"}
          placeholder={"All Category"}
          options={listUniqueCategory}
          getOptionLabel={(option) => option}
          value={categoryFilter}
          onChange={(e, newValue) => setCategoryFilter(newValue)}
          renderInput={(params) => <TextField {...params} label={"Category"} variant={"outlined"} />}
          popupIcon={<KeyboardArrowDown />}
        />
      </Grid>

      { 
        auth.checkModulePrivilege(
          PrivilegeModules.material_request,
          PrivilegeActions.withdrawal,
        ) && 
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Autocomplete
            id={"withdrawal-group-filter"}
            placeholder={"Withdrawal Group"}
            options={materialGroups}
            getOptionLabel={(option) => option.name}
            value={materialGroupFilter}
            onChange={(e, newValue) => setMaterialGroupFilter(newValue)}
            renderInput={(params) => <TextField {...params} label={"Withdrawal Group"} variant={"outlined"} />}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
      }

      <Grid item xs={12} sm={12} md={12} lg={12} sx={{
        '& .rdt_TableCol_Sortable > span:before': {
          display: 'none!important'
        },
      }}>
        <DataTable
          fixedHeader={true}
          persistTableHead={true}
          onRowClicked={(row, e) => { handleItemEnable(e, row) }}
          columns={columns}
          data={data}
          pagination
        />
      </Grid>
    </Grid>
  )
}
