import React, { useState, useEffect } from "react";
import { Grid, Drawer, Button } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleRightbar,
} from "../../context/LayoutContext";

function Rightbar() {
    var classes = useStyles();
    var theme = useTheme();

    // global
    const { isRightbarOpened, rbContent: { html, config } } = useLayoutState();
    const layoutDispatch = useLayoutDispatch();

    // local
    const [isPermanent, setPermanent] = useState(true);

    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    // render element by key (header & body)
    function getComponent(key) {
        return html ? html.filter((comp) => {
            return comp.key === key;
        }) : <></>;
    }

    return (
        <Drawer
            ModalProps={{ onBackdropClick: () => toggleRightbar(layoutDispatch) }}
            anchor="right"
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer)}
            classes={{
                paper: classNames({
                    "open": isRightbarOpened,
                    "close": !isRightbarOpened,
                    [classes.drawerOpen]: isRightbarOpened,
                    [classes.drawerClose]: !isRightbarOpened,
                    [classes.widthLg]: isRightbarOpened && config && config.size === 'lg',
                    [classes.widthXs]: isRightbarOpened && config && config.size === 'xs',
                    [classes.widthMd]: isRightbarOpened && config && config.size === 'md'
                })
            }}
            open={isRightbarOpened}>
            <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"sub-action stick"}>
                        {(config && config.backButton) && <Button
                            onClick={() => toggleRightbar(layoutDispatch)}>
                            <ArrowBackIcon />
                        </Button>}
                        {html && getComponent('rb-header')}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        {html && getComponent('rb-body')}
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>);

    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent || config.showBackDropInLargeScreen) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

export default withRouter(Rightbar);
