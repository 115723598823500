import { convertThisToCurrency } from '../../helper';
import { IconButton, Switch } from '@mui/material';
import { Link } from 'react-router-dom';
import { DeleteOutline } from '@mui/icons-material';

export const columns = (handleInlineEditField, handleDelete) => [
  {
    name: "Setup No.",
    width: "90px",
    selector: (row) => <Link to={`/app/fnb-voucher-setup/${row.incrementId}`} title={row.incrementId}>{row.incrementId}</Link>,
    sortable: true,
    wrap: true,
    sortField: "entityId",
  },
  {
    name: "Validity Period (Day)",
    width: "120px",
    selector: (row) => row.validityPeriod,
    wrap: true,
    right: true,
    sortField: "validityPeriod",
  },
  {
    name: "Voucher Amount",
    width: "100px",
    selector: (row) => convertThisToCurrency(row.amount),
    wrap: true,
    right: true,
  },
  {
    name: "Min. Total Payable",
    width: "110px",
    selector: (row) => convertThisToCurrency(+row.minTotalCartAmount),
    wrap: true,
    right: true,
  },
  {
    name: "SKU",
    width: "70px",
    selector: (row) => row.itemNo,
    wrap: true,
  },
  {
    name: "Location Code",
    width: "100px",
    selector: (row) => row.locationCode,
    wrap: true,
  },
  {
    name: "Store",
    minWidth: "350px",
    selector: (row) => {
      if (!row.stores || row.stores.length === 0) {
        return 'All Stores';
      }
      return row.stores.reduce((acc, item) => {
        acc.push(""+item.name)
        return acc;
      }, []).join(',')
    },
    wrap: true,
    sortField: "stores",
  },
  {
    name: "Enable",
    width: '80px',
    center: true,
    selector: row => row.isActive,
    cell: (row) => {
      return (<Switch
        checked={row.isActive}
        onChange={(e, value) => handleInlineEditField(row, 'isActive', value)}
        inputProps={{ 'aria-label': 'controlled' }}
      />)
    },
    sortable: true,
    sortField:'status'
  },
  {
    name: "",
    right: true,
    width: '50px',
    cell: (row) => {
      return (<>
        {handleDelete && <IconButton onClick={(e) => handleDelete(row)}><DeleteOutline /></IconButton>}
      </>)
    }
  }
]