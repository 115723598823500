import { withRouter } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { KeyboardArrowDown, Search as SearchIcon } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { PageWrapper } from "../fnb-voucher-setup/components/PageWrapper";
import { columns } from "./supplier-listing-columns.config";
import { useSupplierListApi } from "./api/useSupplierListApi";

const SupplierList = (props) => {
  document.title = "F&B Supplier List";
  const { isLoading, getSupplierList, getPrerequisites } = useSupplierListApi();

  const [attributeFilters, setAttributeFilters] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [textFilter, setTextFilter] = useState("");
  const refreshPage = useCallback(() => {
    !refresh && setRefresh(true);
  }, [refresh]);
  const [storeList, setStoreList] = useState([]);
  const [tableData, setTableData] = useState({
    data: [],
    collectionSize: 1,
  });

  useMemo(() => {
    getPrerequisites().then((response) => {
      setStoreList(response.data.storesList);
    });
  }, []);

  const columnConfig = columns;
  const [dataTableConfig, setDataTableConfig] = useState({
    columns: columnConfig,
    sortField: "createdAt",
    sortOrder: "desc",
    page: 1,
    pageSize: 10,
  });

  const sortByColumn = useCallback(
    (columnConfig, sortDirection) => {
      setDataTableConfig({
        ...dataTableConfig,
        sortField: columnConfig.sortField,
        sortOrder: sortDirection,
      });
      refreshPage();
    },
    [dataTableConfig, refreshPage],
  );

  const handleChangeRowsPerPage = useCallback(
    (pageSize) => {
      setDataTableConfig((prev) => {
        return { ...prev, pageSize };
      });
      refreshPage();
    },
    [refreshPage],
  );

  const handleChangePage = useCallback(
    (page) => {
      setDataTableConfig((prev) => {
        return { ...prev, page };
      });
      refreshPage();
    },
    [refreshPage],
  );

  useEffect(() => {
    refresh &&
      getSupplierList(
        textFilter,
        attributeFilters,
        { page: dataTableConfig.page, pageSize: dataTableConfig.pageSize },
        {
          sortField: dataTableConfig.sortField,
          direction: dataTableConfig.sortOrder,
        },
      ).then((data) => {
        console.log(data);
        setTableData({
          data: data.searchResult,
          collectionSize: data.totalCollectionSize,
        });
        setRefresh(false);
      });
  }, [textFilter, attributeFilters, dataTableConfig, refresh]);

  const handleChangeFilter = (field, value, operation, alias = undefined) => {
    if (value === undefined) {
      setAttributeFilters((prev) => ({ ...prev, [field]: undefined }));
    } else {
      setAttributeFilters((prev) => ({
        ...prev,
        [field]: { name: alias, value, operation },
      }));
    }
    refreshPage();
  };

  return (
    <>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <PageWrapper
        title={"F&B Supplier List"}
        action={
          <Button
            className={"primary"}
            onClick={() => {
              props.history.push("/app/fnb-supplier-list/new");
            }}
            type="submit"
          >
            New
          </Button>
        }
      >
        <Grid className={"form"}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={10} sm={10} md={10} lg={2}>
              <TextField
                autoComplete={"off"}
                id={"text-searchbox"}
                variant={"outlined"}
                value={textFilter}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    refreshPage();
                  }
                }}
                onChange={(e) => setTextFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={2}>
              <Autocomplete
                id={"store-filter"}
                options={storeList}
                getOptionLabel={(option) =>
                  `${option.storeName.toUpperCase()}-${option.storeCode.toUpperCase()}`
                }
                value={attributeFilters?.stores?.storeCode}
                isOptionEqualToValue={(option, value) =>
                  option.storeCode === value.storeCode
                }
                onChange={(e, value) => {
                  handleChangeFilter(
                    "stores",
                    value === null ? undefined : value.storeCode,
                    "$eq",
                    "stores.storeCode",
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"Store"} variant={"outlined"} />
                )}
                popupIcon={<KeyboardArrowDown />}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                fixedHeader={true}
                persistTableHead={true}
                columns={dataTableConfig.columns}
                data={tableData.data}
                sortServer
                onSort={sortByColumn}
                pagination
                paginationServer
                paginationDefaultPage={dataTableConfig.page}
                paginationPerPage={dataTableConfig.pageSize}
                paginationTotalRows={tableData.collectionSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
              />
            </Grid>
          </Grid>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default withRouter(SupplierList);
