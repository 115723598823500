import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import { IconButton, Switch, TextField } from "@mui/material";
export const attributeOptionTableColumnConfig = ({
  onRemoveRow,
  onChangeItem,
  list,
  setList,
}) => [
  {
    name: "",
    width: "50px",
    cell: (row, index) => (
      <IconButton onClick={() => onRemoveRow(list, setList, index)}>
        <DeleteIcon />
      </IconButton>
    ),
  },
  {
    name: "Option",
    cell: (row, index) => {
      return (
        <TextField
          variant={"outlined"}
          type="text"
          value={row.option}
          onChange={(e) =>
            onChangeItem(list, "option", index, e.target.value, setList)
          }
          error={!row.option}
          helperText={!row.option && "Option can not be blank"}
        />
      );
    },
  },
  {
    name: "Enable",
    width: "80px",
    center: true,
    selector: (row) => row.enabled,
    cell: (row, index) => {
      return (
        <Switch
          checked={row.enabled}
          onChange={(e) =>
            onChangeItem(list, "enabled", index, !row.enabled, setList)
          }
          inputProps={{ "aria-label": "controlled" }}
        />
      );
    },
    sortable: true,
  },
];
