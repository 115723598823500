import { withRouter } from "react-router-dom";
import { Typography, Grid, TextField, InputAdornment, Box, InputLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

const InsClaimOtherDeductionPopup = (props) => {
  // handling form when adding / editing
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    setValue("wsri", props.wsri);
    setValue("vehicle", props.vehicle);
    setValue("diesel", props.diesel);
    setValue("mandaysOfGC", props.mandaysOfGC);
    setValue("salary", props.salary);
    setValue("additionalDeduction", props.additionalDeduction);
    setValue("otherDeductionRemark", props.otherDeductionRemark);
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Grid container className={"form"}>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      id={"wsri"}
                      label={"WSRI"}
                      type={"number"}
                      variant={"outlined"}
                      {...register("wsri", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: /^\d*\.?\d*$/,
                          message: "WSRI must contain only numbers (0-9).",
                        },
                      })}
                      error={!!formErrors?.wsri}
                      helperText={formErrors?.wsri?.message}
                      onChange={(event) => props.setWsri(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      id={"vehicle"}
                      label={"Vehicle"}
                      type={"number"}
                      variant={"outlined"}
                      {...register("vehicle", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: /^\d*\.?\d*$/,
                          message: "Vehicle must contain only numbers (0-9).",
                        },
                      })}
                      error={!!formErrors?.vehicle}
                      helperText={formErrors?.vehicle?.message}
                      onChange={(event) => props.setVehicle(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      id={"diesel"}
                      label={"Diesel"}
                      type={"number"}
                      variant={"outlined"}
                      {...register("diesel", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: /^\d*\.?\d*$/,
                          message: "Diesel must contain only numbers (0-9).",
                        },
                      })}
                      error={!!formErrors?.diesel}
                      helperText={formErrors?.diesel?.message}
                      onChange={(event) => props.setDiesel(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      id={"mandaysOfGC"}
                      label={"Mandays Of Gain City"}
                      type={"number"}
                      variant={"outlined"}
                      {...register("mandaysOfGC", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: /^\d*\.?\d*$/,
                          message:
                            "Mandays Of Gain City must contain only numbers (0-9).",
                        },
                      })}
                      error={!!formErrors?.mandaysOfGC}
                      helperText={formErrors?.mandaysOfGC?.message}
                      onChange={(event) =>
                        props.setMandaysOfGC(event.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      id={"salary"}
                      label={"Salary"}
                      type={"number"}
                      variant={"outlined"}
                      {...register("salary", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: /^\d*\.?\d*$/,
                          message: "Salary must contain only numbers (0-9).",
                        },
                      })}
                      error={!!formErrors?.salary}
                      helperText={formErrors?.salary?.message}
                      onChange={(event) => props.setSalary(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      id={"additionalDeduction"}
                      label={"Additional Deduction"}
                      type={"number"}
                      variant={"outlined"}
                      {...register("additionalDeduction", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: /^\d*\.?\d*$/,
                          message:
                            "Additional Deduction must contain only numbers (0-9).",
                        },
                      })}
                      error={!!formErrors?.additionalDeduction}
                      helperText={formErrors?.additionalDeduction?.message}
                      onChange={(event) =>
                        props.setAdditionalDeduction(event.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={'od-remark-box'}>
                      <InputLabel id="od-remark-label" htmlFor="otherDeductionRemark">
                        Remark
                      </InputLabel>
                      <textarea
                        id="id-remark-input"
                        value={props.otherDeductionRemark}
                        onChange={(event) =>
                          props.setOtherDeductionRemark(event.target.value)
                        }
                        rows={7}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(InsClaimOtherDeductionPopup);
