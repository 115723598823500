// import "./tools/wdyr";
import React from "react";
import ReactDOM from "react-dom";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { StoreProvider } from "./context/StoreContext";
import "./template-card.css";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <LayoutProvider>
      <UserProvider>
        <StoreProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StoreProvider>
      </UserProvider>
    </LayoutProvider>
  </StyledEngineProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
