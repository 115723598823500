import { useParams, withRouter } from "react-router-dom";
import { useSupplierOrderApi } from "./api/useSupplierOrderApi";
import React, { useCallback, useMemo, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { OrderForm } from "./OrderForm";

const FnbSupplierOrderCard = (props) => {
  const params = useParams();
  const orderId = useMemo(() => params.orderId, [params]);
  const pageTitle = useMemo(
    () =>
      !orderId
        ? "F&B Purchase Order Card - New"
        : `F&B Purchase Order Card - ${orderId}`,
    [orderId],
  );
  const windowTitle = useMemo(
    () =>
      !orderId
        ? "New - F&B Purchase Order Card"
        : `${orderId} - F&B Purchase Order Card`,
    [orderId],
  );
  // Prerequisite
  const { getPrerequisites, isLoading, getOrderByNumber } =
    useSupplierOrderApi();
  const [prerequisites, setPrerequisites] = useState(undefined);
  const [orderData, setOrderData] = useState();

  const fetchOrderData = useCallback(async () => {
    if (!orderId) {
      setOrderData({
        deliveryDate: null,
        contactName: null,
        contactNumber: null,
        supplier: {
          vendorCode: null,
        },
        deliveryTo: {
          storeCode: null,
        },
        remark: "",
        items: [],
      });
      return;
    }
    getOrderByNumber(orderId)
      .then((res) => res.data)
      .then(setOrderData);
  }, [orderId]);

  const fetchPrerequisite = useCallback(async () => {
    getPrerequisites().then((response) => {
      setPrerequisites(response.data);
      setOrderData((prev) => {
        if (!prev.contactName) prev.contactName = response?.data?.contact?.name;
        if (!prev.contactNumber)
          prev.contactNumber = response?.data?.contact?.number;
        return { ...prev };
      });
    });
  }, []);

  document.title = windowTitle;
  useMemo(() => {
    fetchOrderData().then(fetchPrerequisite);
  }, []);

  return (
    <>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      {prerequisites && (
        <OrderForm
          orderData={orderData}
          prerequisites={prerequisites}
          pageTitle={pageTitle}
        />
      )}
    </>
  );
};

export default withRouter(FnbSupplierOrderCard);
