import { DeleteOutline } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import moment from "moment";
import { BRACKET_REQUEST_ITEM_STATUS } from "../../../data/constants";

export const bracketRequestCardTableColumnConfig = (isEditAttempt, isViewOnly,
    removeBracket, onChangeReturnCheckbox) => {
    let columns = [
        {
            omit: !!isViewOnly,
            name: "",
            width: "50px",
            cell: (row) => {
                // can remove only NEW brackets
                return (row.status === BRACKET_REQUEST_ITEM_STATUS.NEW) && (<>
                    <IconButton onClick={() => removeBracket(row.serialNo)}>
                        <DeleteOutline />
                    </IconButton>
                </>)
            }
        },
        {
            name: "Serial No.",
            width: "100px",
            selector: row => row.serialNo,
            sortable: true
        },
        {
            name: "Bracket Type",
            width: "110px",
            selector: row => row.bracketType,
            left: true,
            sortable: true
        },
        {
            name: "Batch No.",
            width: "350px",
            wrap: true,
            selector: row => row.batchNos?.sort().toString(),
            sortable: true
        }
    ];
    // show only when editing
    if (isEditAttempt) {
        columns = [...columns,
        {
            name: "Issued Date",
            width: "170px",
            wrap: true,
            sortable: true,
            selector: row => row.issuedDate ? moment(row.issuedDate).format('DD/MM/YYYY HH:mm:ss') : ''
        },
        {
            omit: !!isViewOnly,
            name: "Return",
            width: "80px",
            cell: (row) => {
                // can check/uncheck returned when brackets are already ISSUED
                return (row.status === BRACKET_REQUEST_ITEM_STATUS.ISSUED ||
                    row.status === BRACKET_REQUEST_ITEM_STATUS.TO_BE_RETURNED) && (<>
                        <Checkbox
                            {...{ inputProps: { 'aria-label': 'Checkbox' } }}
                            checked={row.status === BRACKET_REQUEST_ITEM_STATUS.TO_BE_RETURNED}
                            onChange={(e) => onChangeReturnCheckbox(row._id, e.target.checked)}
                        />
                    </>)
            }
        },
        {
            name: "Returned Date",
            width: "170px",
            wrap: true,
            sortable: true,
            selector: row => row.returnedDate ? moment(row.returnedDate).format('DD/MM/YYYY HH:mm:ss') : ''
        },
        {
            name: "SRO No.",
            width: "120px",
            sortable: true,
            selector: row => row.sroNo ? row.sroNo : ''
        },
        {
            name: "Reason",
            width: "250px",
            wrap: true,
            sortable: true,
            selector: row => row.reason ? row.reason : ''
        }
        ];
    }
    return columns;
};
