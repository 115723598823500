import { Grid, Typography, TextField } from "@mui/material";
import { validateText } from "../../../../helper/validate-textfield";
import { PR_PANELS } from "../../helper/panels";
import {
  isNew,
  isRejected,
  isStep_0_5,
  isStep_1_5,
  isSubmittedForApproval,
} from "../../helper/pr-config";

const WorkflowClaimDepositProjected = (props) => {
  const isEditable = () => {
    return (
      (isRejected(props.projectRequest?.status) ||
        isNew(props.projectRequest?.status) ||
        isStep_0_5(props.step) ||
        (isStep_1_5(props.step) &&
          !isSubmittedForApproval(props.projectRequest))) &&
      props.hasEditPrivilege
    );
  };

  return (
    <>
      <Grid container className={"form"} id={PR_PANELS.CLAIM_DEPOSIT}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Typography variant={"h2"}>
                Payment Schedule (Projected)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"1st-claim"}
                    label={"1st Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("firstClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.firstClaim}
                    helperText={props.formErrors?.firstClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"2st-claim"}
                    label={"2nd Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("secondClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.secondClaim}
                    helperText={props.formErrors?.secondClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"3rd-claim"}
                    label={"3rd Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("thirdClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.thirdClaim}
                    helperText={props.formErrors?.thirdClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"4th-claim"}
                    label={"4th Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("forthClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.forthClaim}
                    helperText={props.formErrors?.forthClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"5th-claim"}
                    label={"5th Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("fifthClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.fifthClaim}
                    helperText={props.formErrors?.fifthClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"6th-claim"}
                    label={"6th Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("sixthClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.sixthClaim}
                    helperText={props.formErrors?.sixthClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"7th-claim"}
                    label={"7th Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("seventhClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.seventhClaim}
                    helperText={props.formErrors?.seventhClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"8th-claim"}
                    label={"8th Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("eighthClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.eighthClaim}
                    helperText={props.formErrors?.eighthClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"final-claim"}
                    label={"Final Claim"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("finalClaimP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.finalClaim}
                    helperText={props.formErrors?.finalClaim?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id={"retention"}
                    label={"Retention"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("retentionP")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.retention}
                    helperText={props.formErrors?.retention?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkflowClaimDepositProjected;
