import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@mui/icons-material";

export const calendarTableColumnConfig = (permission, handleToggleStatus) => [
  {
    name: "Calendar No.",
    width: "100px",
    selector: (row) => row.calendarNo,
    cell: (row, index, column, id) => {
      if (permission.allowedViewDetail) {
        return (
          <Link to={"/app/group-buy-calendar/card/" + row._id}>{row.calendarNo}</Link>
        );
      } else {
        return <>{row.calendarNo}</>;
      }
    },
    sortable: true,
    sortField: "calendarNo",
  },
  {
    name: "Calendar Name",
    width: "250px",
    wrap: true,
    selector: (row) => row.name,
    sortable: true,
    sortField: "name",
  },
  {
    name: "Min. Day",
    width: "80px",
    wrap: true,
    right: true,
    selector: (row) => row.minDayInterval,
    sortable: true,
    sortField: "minDayInterval",
  },
  {
    name: "Max. Day",
    width: "80px",
    wrap: true,
    right: true,
    selector: (row) => row.maxDay,
    sortable: true,
    sortField: "maxDay",
  },
  {
    name: "Store",
    minWidth: "300px",
    selector: (row) => row?.store?.name,
    cell: (row, index, column, id) => {
      return <>{row?.store?.name}</>;
    },
    sortable: true,
    sortField: "store.name",
  },
  {
    name: "Waiting List",
    width: "200px",
    center: true,
    selector: (row) => row.isWaitingList,
    cell: (row) => {
      return (
        <Switch
          checked={row.isWaitingList || false}
          disabled={!permission.allowedEnableDisable}
          onChange={(e, value) => handleToggleStatus(row, "isWaitingList")}
          inputProps={{ "aria-label": "controlled" }}
        />
      );
    },
    sortable: true,
    sortField: "enabled",
  },
  {
    name: "Enable",
    width: "80px",
    center: true,
    selector: (row) => row.enabled,
    cell: (row) => {
      return (
        <Switch
          checked={row.enabled}
          disabled={!permission.allowedEnableDisable}
          onChange={(e, value) => handleToggleStatus(row, "enabled")}
          inputProps={{ "aria-label": "controlled" }}
        />
      );
    },
    sortable: true,
    sortField: "enabled",
  },
];
