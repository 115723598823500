import { Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import { costMarginActualDocumentsTableConfig } from "../../data-table/cost-margin-actual-documents-table-config";

export default function CostMarginActualDocumentsDrawer({ 
    step, status, permissionToEdit, documents, setDocuments, isDisabled,
    removedDocuments, setRemovedDocuments, allowedFileTypes }) {

    const columns = costMarginActualDocumentsTableConfig(
        onChangeRowValues, onRemoveRow,
        onClickAddBtn, permissionToEdit, isDisabled, step, status);

    function onClickAddBtn() {
        document.getElementById('cost-margin-actual-document-upload').click();
    }

    function onAddDocuments(e) {
        const files = e.target.files;
        let fileList = [...documents];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const existing = documents.findIndex(d => d.fileName === file.name) > -1;
            if (!existing) {
                fileList = [...fileList, {
                    file: file,
                    fileName: file.name,
                    url: '',
                    description: '',
                    uploadedDate: null,
                    new: true
                }];
            }
        }
        setDocuments(fileList);
    }

    function onRemoveRow(index) {
        const docs = [...documents];
        const removedDocs = [...(removedDocuments ?? [])];
        const removedDoc = docs.at(index);
        if (removedDoc && !removedDoc.new) {
            const generatedName = removedDoc.url.split('/').pop();
            if (!removedDocuments.includes(generatedName)) {
                removedDocs.push(generatedName);
            }
        }
        docs.splice(index, 1);
        setDocuments([...docs]);
        setRemovedDocuments(removedDocs);
    }

    function onChangeRowValues(i, value, attribute) {
        const docList = [...documents];
        if (attribute === 'description') {
            value = value.toUpperCase();
        }
        docList.at(i)[attribute] = value;
        setDocuments(docList);
    }

    return (<>
        <Grid item xs={12} sm={12} md={12} lg={12} className={"table"}>
            <input
                hidden
                type="file"
                id="cost-margin-actual-document-upload"
                accept={allowedFileTypes ?? '*'}
                onChange={(e) => onAddDocuments(e)}
                multiple
            />
            <DataTable
                fixedHeader={true}
                persistTableHead={true}
                columns={columns}
                data={documents}
                defaultSortAsc={false}
                defaultSortFieldId={'uploadedDate'}
            />
        </Grid>
    </>);
}
