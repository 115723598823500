import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton, MenuItem, Menu, Typography,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ArrowBack as BackIcon,
  ChevronRight as BulletIcon,
  MoreVertOutlined as ExpandMoreIcon,
} from "@mui/icons-material";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut, useUserState } from "../../context/UserContext";
import { useStoreState } from "../../context/StoreContext";
import { useHttpRequest } from "../../hooks/useHttpRequest";

// import ExitToAppIcon from "@mui/icons-material/ExitToApp";

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  const { userInfo } = useUserState();
  const { store } = useStoreState();
  const { sendRequest } = useHttpRequest();

  const getUserInfo = () => {
    // if (userInfo?.roles?.includes('wh_user') || userInfo?.roles?.includes('supervisor')) {
    //   return `${store.name} : ${userInfo?.username?.toUpperCase()} - ${userInfo?.name}`
    // }
    return `${userInfo?.username?.toUpperCase()}-${userInfo?.name}`;
  }

  const closeProfileMenu = () => {
    setProfileMenu(false);
  }

  const goToChangePasswordPage = () => {
    props.history.push('/app/account/change-password');
    closeProfileMenu();
  }

  const isChangePasswordOption = () => {
    return !!userInfo
  }

  function logOut() {
    sendRequest("/v1/user/signout", "POST");
    signOut(userDispatch, props.history);
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
            classes.mobileBackButton
          )}
        >
          {layoutState.isSidebarOpened ? (
            <BackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <div className={classes.grow} />
        <Typography
          className={"profile"}
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <span>{getUserInfo()}</span> <ExpandMoreIcon />
        </Typography>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={closeProfileMenu}
          className={"profile-menu"}
          disableAutoFocusItem
        >
          {isChangePasswordOption() && <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={() => goToChangePasswordPage()}
          >
            <BulletIcon />Change Password
          </MenuItem>
          }
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={() => logOut()}
          >
            <BulletIcon />Log Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
