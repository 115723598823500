import { makeStyles } from "@mui/styles";
export default makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    width: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.down("sm")]: {
      width: 300
    }
  },
  header: {
    margin: 15,
    display: 'flex'
  },
  headerText: {
    marginLeft: 15
  },
  mobileBackButton: {
    minWidth: 40,
    minHeight: 40,
    padding: 9,
    backgroundColor: '#7066e0'
  },
  backArrowIcon: {
    fill: '#fff',
    fontSize: 25
  },
  widthLg: { width: 1100 },
  widthXs: { width: 300 },
  widthMd: { width: 700 }
}));
