import { withRouter } from "react-router-dom";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowDown,
  PlaylistPlay,
  Search as SearchIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useStyles from "./styles";
import { airconTableColumnConfig } from "./data-table/aircon-table-column-config";
import AirconFilterMobile from "./components/AirconFilterMobile";
import Swal from "sweetalert2";
import DateFilter from "../../components/DateFilter";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import moment from "moment";
import downloadCSV from "../../helper/download-csv";
import { subscriptionPeriodLabel } from "./data";
import { convertThisToCurrency } from "../../helper/convert-this-to-currency";

// **************************************************************

const AirconSubscriptionList = (props) => {
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  const theme = useTheme();
  const matchMediaQuery = useMediaQuery(theme.breakpoints.down("md"));
  const [airconSubscriptionList, setAirconSubscriptionList] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const layoutDispatch = useLayoutDispatch();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);
  const [subscriptionFromDate, setSubscriptionFromDate] = useState(null);
  const [subscriptionToDate, setSubscriptionToDate] = useState(null);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list
  const [preferredOutletFilter, setPreferredOutletFilter] = useState(null);
  const [airconSubscriptionAttribute, setAirconSubscriptionAttribute] =
    useState();

  const preferredOutletsMap =
    airconSubscriptionAttribute &&
    new Map(
      airconSubscriptionAttribute.preferredOutlets.map((item) => [
        item.id,
        item.option,
      ]),
    );

  const systemsMap =
    airconSubscriptionAttribute &&
    new Map(
      airconSubscriptionAttribute.systems.map((item) => [item.id, item.option]),
    );

  // AirconSubscription table column
  const columns = airconTableColumnConfig({
    allowedViewDetail: auth.checkModulePrivilege(
      PrivilegeModules.aircon_subscription,
      PrivilegeActions.view_detail,
    ),
    preferredOutletsMap,
    systemsMap,
  });

  const mobileFilterProps = {
    subscriptionFromDate,
    subscriptionToDate,
    setSubscriptionFromDate,
    setSubscriptionToDate,
    preferredOutletFilter,
    preferredOutletList: airconSubscriptionAttribute?.preferredOutlets || [],
    setPreferredOutletFilter,
  };

  // ************************** FUNCTION **********************
  function getFilterValues() {
    return {
      text: textFilter.trim(),
      subscriptionFromDate: subscriptionFromDate
        ? getFromDate(subscriptionFromDate)
        : undefined,
      subscriptionToDate: subscriptionToDate
        ? getToDate(subscriptionToDate)
        : undefined,
      preferredOutletId: preferredOutletFilter?.id,
    };
  }

  function filter() {
    const filters = getFilterValues();
    sendRequest(
      `/v1/aircon-subscription/filter?filters=${JSON.stringify(filters)}
      &page=${page}&pageSize=${pageSize}
      ${
        sortBy?.column && sortBy?.direction
          ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
          : ""
      }`,
      "GET",
      {},
    ).then((response) => {
      const airconSubscriptions = response.data?.subscriptions || [];
      setAirconSubscriptionList(airconSubscriptions);
      setCollectionSize(response.data?.total);
    });
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  async function fetchInitData() {
    const { data } = await sendRequest(
      `/v1/aircon-subscription-attribute/attribute`,
      "GET",
    );

    setAirconSubscriptionAttribute(data);
  }

  async function downloadReport() {
    const csvData = [];

    const filters = getFilterValues();

    try {
      const { data } = await sendRequest(
        `/v1/aircon-subscription/filter?filters=${JSON.stringify(filters)}
        &page=${page}&pageSize=0${
          sortBy?.column && sortBy?.direction
            ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
            : ""
        }`,
        "GET",
        {},
      );

      // Check data is empty
      if (data.subscriptions.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: "No data to export.",
        });
        return;
      }

      for (const subscription of data.subscriptions) {
        const transformedData = {
          "Subscription No.": subscription.airconSubscriptionNo,
          "Subscription Date": moment(subscription.createdAt).format(
            "DD/MM/YYYY HH:mm:ss",
          ),
          "Customer Name": subscription.customerName?.toUpperCase(),
          "Customer Email": subscription.customerEmail?.toLowerCase(),
          "Mobile No.": subscription.customerMobile?.toString() ?? "-",
          "Preferred Outlet":
            preferredOutletsMap
              ?.get(subscription.preferredOutletId)
              ?.toUpperCase() ?? "-",
          "Subscription Period":
            subscriptionPeriodLabel[subscription.subscriptionPeriod],
          "Monthly Subscription": convertThisToCurrency(
            subscription.monthlySubscription.toFixed(2),
          ),
          Brand: subscription.brandName,
          System: systemsMap?.get(subscription.systemId)?.toUpperCase() ?? "-",
          Description: subscription.description,
          Combination: subscription.combination,
          "Privacy Clause": subscription.isPrivacyClause ? "YES" : "NO",
        };

        csvData.push(transformedData);
      }

      // Generate filename and download CSV
      const today = moment().format("YYYYMMDD_HHmmss");
      const filename = `AIRCON-SUBSCRIPTION-REPORT-${today}.csv`;
      downloadCSV(csvData, filename);
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  }

  // ************************** HOOK **********************

  useEffect(() => {
    setRefresh(true);
  }, [subscriptionFromDate, subscriptionToDate, preferredOutletFilter]);

  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (matchMediaQuery) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <AirconFilterMobile
            key={"rb-body"}
            handleKeyDown={handleKeyDown}
            {...mobileFilterProps}
          />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [matchMediaQuery, subscriptionFromDate, subscriptionToDate]);

  useEffect(() => {
    document.title = "Aircon Subscription List";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.aircon_subscription,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }

    fetchInitData();
  }, []);

  useEffect(() => {
    if (error && responseData?.message) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message ?? "There're something error",
      });
    }
  }, [error, responseData]);

  // ************************** RENDER **********************

  const titleRender = (
    <Typography variant={"h1"}>Aircon Subscription List</Typography>
  );

  const actionRender = auth.checkModulePrivilege(
    PrivilegeModules.aircon_subscription,
    PrivilegeActions.download_report,
  ) && (
    <Button className={"primary"} onClick={() => downloadReport()}>
      Download Report
    </Button>
  );

  const filterRender = (
    <>
      <Grid item xs={10} sm={10} md={2} lg={2}>
        <TextField
          autoComplete={"off"}
          id={"text-searchbox"}
          variant={"outlined"}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {!matchMediaQuery ? (
        <>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"From"}
              value={subscriptionFromDate}
              maxDate={subscriptionToDate}
              onChange={(newValue) => setSubscriptionFromDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"To"}
              value={subscriptionToDate}
              minDate={subscriptionFromDate}
              onChange={(newValue) => setSubscriptionToDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              id={"preferred-outlet-filter"}
              options={airconSubscriptionAttribute?.preferredOutlets ?? []}
              getOptionLabel={(item) => item.option.toUpperCase()}
              value={preferredOutletFilter}
              onChange={(e, newValue) => setPreferredOutletFilter(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Preferred Outlet"}
                  variant={"outlined"}
                />
              )}
              popupIcon={<KeyboardArrowDown />}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={2}>
          <IconButton
            onClick={() => toggleRightbar(layoutDispatch)}
            className={classes.drawerToggleBtn}
          >
            {layoutState.isRightbarOpened ? (
              <ArrowBack className={classes.toggleRightBarIcon} />
            ) : (
              <PlaylistPlay className={classes.toggleRightBarIcon} />
            )}
          </IconButton>
        </Grid>
      )}
    </>
  );

  const tableRender = (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <DataTable
        fixedHeader={true}
        persistTableHead={true}
        columns={columns}
        data={airconSubscriptionList}
        sortServer
        onSort={sortByColumn}
        pagination
        paginationServer
        paginationDefaultPage={page}
        paginationPerPage={pageSize}
        paginationTotalRows={collectionSize}
        onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
        onChangePage={(page) => handlePageChanged(page)}
      />
    </Grid>
  );
  // ************************************************
  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {titleRender}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {actionRender}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {filterRender}
              {tableRender}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(AirconSubscriptionList);
