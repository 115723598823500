import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  IconButton,
  Box,
  InputAdornment,
  InputLabel, TextareaAutosize,
} from "@mui/material";
import {
  Add,
  Clear,
  KeyboardArrowDown as ArrowDownIcon,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import DataTable from "react-data-table-component";
import { fnbStoreCategoryTableColumnConfig } from "./data-table/fnb-store-category-table-column-config";
import useStyles from "./styles";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useUserState } from "../../context/UserContext";

const FnbStoreCard = (props) => {
  const classes = useStyles();
  const { userInfo } = useUserState();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const { storeId } = useParams(); // url param => storeId when editing

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
  } = useForm({ mode: "all" });

  // un-assigned sub-con list
  const [chefList, setChefList] = useState([]);
  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [currentStore, setCurrentStore] = useState(null); // when edit
  const [categoryList, setCategoryList] = useState([]);
  const [rowsInvalid, setRowsInvalid] = useState(false);
  const [lstDeletedImages, setLstDeletedImages] = useState([]);
  let selectedCategory = 0;

  const [duplicated, setDuplicated] = useState(false);
  const [enabledSwitchValue, setEnabledSwitchValue] = useState(true); // enable switch => default true
  const [isDefaultSwitchValue, setIsDefaultSwitchValue] = useState(true); // enable switch => default true
  const [errMessage, setErrMessage] = useState("");
  const [orgImageFolderName, setOrgImageFolderName] = useState(null);
  const [storeConfig, setStoreConfig] = useState("{}");
  const [kitchenChefList, setKitchenChefList] = useState("{}");
  const [receiptFooter, setReceiptFooter] = useState("");
  const [billingAddress, setBillingAddress] = useState("{}");
  const [welcomeMessage, setWelcomeMessage] = useState("");

  useEffect(() => {
    document.title = "New - F&B Store Card";
    loadKitchenChefs();

    // redirect to default page if not authorized
    if (
      storeId &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_store,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  // get current matrix in edit view
  useEffect(() => {
    if (!isLoading && !responseData && storeId) {
      sendRequest(`/v1/fnb-store/get-store-by-id/` + storeId, "GET");
    }
  }, [storeId]);

  useEffect(() => {
    formatStoreConfig();
  }, [storeConfig]);

  useEffect(() => {
    formatBillingAddress();
  }, [billingAddress]);

  // set form values when edit view
  useEffect(() => {
    if (storeId && responseData?.store) {
      const store = responseData?.store;
      document.title = `${store.storeCode} - F&B Store Card`;
      setCurrentStore(store);
      setValue("name", store.name || "");
      setValue("storeCode", store.storeCode || "");
      setValue("location", store.location || "");
      setValue("longitude", store.longitude || "");
      setValue("latitude", store.latitude || "");
      setValue("isDefault", store.isDefault);
      setValue("status", store.status);
      setStoreConfig(JSON.stringify(store.config));
      setIsDefaultSwitchValue(store.isDefault);
      setEnabledSwitchValue(store.status);
      setBillingAddress(JSON.stringify(store.billingAddress));
      setReceiptFooter(store.receiptFooter);
      setWelcomeMessage(store.welcomeMessage);

      let categories = store.categories.sort((a, b) => a.seqNo - b.seqNo);
      categories.forEach((c) => {
        if (!!c.kitchenChefs && c.kitchenChefs != "") {
          c.kitchenChefs = c.kitchenChefs.map((code) => {
            const chef = kitchenChefList.find((chef) => chef.username === code);
            return {
              name: chef ? chef.name : "",
              username: code,
            };
          });
        } else {
          c.kitchenChefs = [];
        }
      });

      setCategoryList(categories);
    }
  }, [responseData]);

  const loadKitchenChefs = () => {
    sendRequest(`/v1/fnb-store/get-kitchen-chef-list`, "GET", {}).then(
      (response) => {
        setKitchenChefList(response.data.kitchenChefs);
      },
    );
  };

  const navigateToList = () => {
    if (!storeId) window.location.href = "#/app/fnb-store/list";
  };

  const handleSubmitForm = async (data, e, duplicate = false) => {
    e.preventDefault();

    if (!currentStore) {
      let result2 = null;
      result2 = await sendRequest(
        `/v1/fnb-store/check-if-existing-store/${data.storeCode}`,
        "GET",
      );

      if (result2.data.existingStore) {
        setErrMessage(`Store code ${data.storeCode} is already existed.`);

        return;
      }
    }

    let storeConfigJson;
    try {
      storeConfigJson = JSON.parse(storeConfig);
    } catch (err) {
      setErrMessage(`CONFIGURATION - ${err}`);
      return;
    }

    let billingAddressJson;
    try {
      billingAddressJson = JSON.parse(billingAddress);
    } catch (err) {
      setErrMessage(`Billing Address - ${err}`);
      return;
    }

    if (!billingAddressJson) {
      setErrMessage(`Billing Address - Invalid Billing Address.`);
      return;
    }

    if (!billingAddressJson.companyName || billingAddressJson.companyName == '') {
      setErrMessage(`Billing Address - Company Name cannot be empty.`);
      return;
    }

    if (!billingAddressJson.gstRegistrationNo || billingAddressJson.gstRegistrationNo == '') {
      setErrMessage(`Billing Address - GST Registration No cannot be empty.`);
      return;
    }

    //duplicating/uploading/deleting Aircon images
    let result1 = null;

    if (duplicated && !currentStore) {
      let lstDuplicateImageUrls = categoryList
        .filter((e) => {
          return e.isNewImage == true && e.isDuplicated == true;
        })
        .map((item) => item.url);
      result1 = await sendRequest(
        `/v1/fnb-store/duplicate-images-in-s3/${data.storeCode}/${orgImageFolderName}`,
        "POST",
        lstDuplicateImageUrls,
      );

      if (result1?.status === 201) {
        categoryList
          .filter((mi) => {
            return mi.isNewImage == true && mi.isDuplicated == true;
          })
          .forEach((m, index) => {
            m.url = result1.data.urlArray[index].url;
          });
      } else {
        return;
      }
    }

    let lstImageUrls = [];
    let formData = new FormData();
    for (var i = 0; i < categoryList.length; i++) {
      if (
        categoryList[i].isNewImage == true &&
        categoryList[i].isDuplicated == false
      ) {
        formData.append("file", categoryList[i].url);
      } else {
        lstImageUrls.push(categoryList[i].url);
      }
    }

    let deletedImageNames =
      lstDeletedImages.toString() == ""
        ? undefined
        : lstDeletedImages.toString();
    result1 = await sendRequest(
      `/v1/fnb-store/upload-image-onto-s3/${data.storeCode}/${deletedImageNames}`,
      "POST",
      formData,
    );

    if (result1?.status === 201) {
      categoryList
        .filter((mi) => {
          return mi.isNewImage == true && mi.isDuplicated == false;
        })
        .forEach((m, index) => {
          m.url = result1.data.urlArray[index].url;
        });
    } else {
      return;
    }
    //

    const store = {
      name: data.name.toUpperCase(),
      storeCode: data.storeCode.toUpperCase(),
      location: data.location,
      longitude: data.longitude == "" ? null : Number(data.longitude),
      latitude: data.latitude == "" ? null : Number(data.latitude),
      isDefault: data.isDefault,
      status: data.status,
      config: storeConfigJson,
      categories: categoryList.map((mi) => ({
        ...mi,
        kitchenChefs: mi.kitchenChefs === "" ? [] : mi.kitchenChefs.map((kc) => kc.username),
      })),

      billingAddress: billingAddressJson,
      receiptFooter: receiptFooter,
      welcomeMessage: welcomeMessage,
    };

    let savedStore = undefined,
      message = "";
    if (currentStore && storeId) {
      // when edit
      store.storeId = currentStore.storeId;
      const result = await sendRequest(
        `/v1/fnb-store/${storeId}`,
        "PUT",
        store,
      );
      if (result?.status === 200 && result?.data?.store) {
        message = `F&B store  ${result.data.store.storeCode} has been updated.`;
        savedStore = result?.data?.store;
      }
    } else {
      const result = await sendRequest(`/v1/fnb-store`, "POST", store);
      if (result?.status === 201 && result?.data?.store) {
        message = `New F&B store ${result.data.store.storeCode} has been created.`;
        savedStore = result?.data?.store;
      }
    }

    if (duplicate && savedStore) {
      setOrgImageFolderName(`${savedStore.storeCode}`);
      duplicateStore();
    } else {
      setMessage(message);
    }
  };

  // set resource table columns
  const columns = fnbStoreCategoryTableColumnConfig(
    onRemoveRow,
    checkForDuplicatedCategory,
    onSelectImage,
    onChangeCategory,
    imagePreview,
    onRemoveImage,
    sortCategory,
    kitchenChefList,
    currentStore &&
    storeId &&
    !auth.checkModulePrivilege(
      PrivilegeModules.fnb_store,
      PrivilegeActions.edit,
    ),
  );

  function sortCategory(i, value) {
    const rList = [...categoryList];
    const name = rList[i].name;
    rList.sort((a, b) => a.seqNo - b.seqNo);

    //shift the seq numbers if it's trying to take the existing number
    if (
      rList.filter((mi) => {
        return mi.name != name && mi.seqNo == value;
      }).length > 0
    ) {
      rList
        .filter((mi) => {
          return mi.name != name && mi.seqNo >= value;
        })
        .forEach((d, index) => {
          d.seqNo = index + value + 1;
        });

      rList.sort((a, b) => a.seqNo - b.seqNo);
    }

    //rearrange the seq numbers to skip the jumped ones
    rList.forEach((d, index) => {
      d.seqNo = index + 1;
    });
    rList.sort((a, b) => a.seqNo - b.seqNo);

    setCategoryList(rList);
  }

  function getNewSeqNo() {
    const max =
      categoryList.reduce((maxValue, obj) => {
        return obj.seqNo > maxValue ? obj.seqNo : maxValue;
      }, 0) + 1;

    return max;
  }

  function onSelectImage(i) {
    selectedCategory = i;
    document.getElementById("input-file-upload").click();
  }

  function imagePreview(category) {
    window.open(
      category.isNewImage ? category.imageUrl_Local : category.imageUrl,
    );
  }

  function onRemoveImage(i) {
    const rList = [...categoryList];

    if (!rList[i].isNewImage && !rList[i].isDuplicated) {
      var deletedFileName = rList[i].url.substring(
        rList[i].url.lastIndexOf("/") + 1,
      );
      setLstDeletedImages((current) => [...current, deletedFileName]);
    } else {
      rList[i].imageUrl_Local = "";
    }

    rList[i].url = "";
    setCategoryList(rList);
  }

  // remove resource from list
  function onRemoveRow(i) {
    onRemoveImage(i);
    const rows = [...categoryList];
    rows.splice(i, 1);

    rows.forEach((d, index) => {
      d.seqNo = index + 1;
    });
    rows.sort((a, b) => a.seqNo - b.seqNo);

    setCategoryList([...rows]);
  }

  function onChangeCategory(fieldName, i, value) {
    const rList = [...categoryList];
    rList[i][fieldName] = value;
    setCategoryList(rList);
  }

  // reset prev matrix data & copy resources to new matrix
  function duplicateStore() {
    props.history.push("/app/fnb-store/card");
    setDuplicated(true);
    setCurrentStore(null);
    setValue("name", "");
    setValue("storeCode", "");
    setValue("location", "");
    setValue("longitude", null);
    setValue("latitude", null);
    setIsDefaultSwitchValue(false);
    setEnabledSwitchValue(true);

    const rList = [...categoryList];
    rList.forEach((e) => {
      delete e.entityId;
      delete e.createdAt;
      delete e.updatedAt;

      e.imageUrl_Local = e.url;
      e.isNewImage = true;
      e.isDuplicated = true;
    });
    setCategoryList(rList);
  }

  function createNewRow() {
    setCategoryList([
      ...categoryList,
      {
        name: "",
        url: "",
        seqNo: getNewSeqNo(),
        status: true,
        kitchenChefs: [],
        isExcludeFromServerQueue: false,
      },
    ]);
  }

  // check duplicated resource code entered
  const isDuplicating = (fieldName, categoryName) =>
    categoryList.filter(
      (r) => r[fieldName].toUpperCase() === categoryName.trim().toUpperCase(),
    ).length > 1;

  function checkForDuplicatedCategory(fieldName, i, categoryName) {
    let foundDuplicate = false;

    const rList = [...categoryList];

    if (
      categoryList.length > 1 &&
      !!categoryName &&
      categoryName.length > 0 &&
      isDuplicating(fieldName, categoryName)
    ) {
      setWarningMessage(
        `Duplicate category ${categoryName.toUpperCase()} found in the list.`,
      );
      rList[i][fieldName] = "";
      foundDuplicate = true;
    } else {
      rList[i][fieldName] = categoryName;
    }

    return foundDuplicate;
  }

  function setCategoryImage(e) {
    for (var j = 0; j < e.target.files.length; j++) {
      const rList = [...categoryList];
      rList[selectedCategory].url = e.target.files[j];
      rList[selectedCategory].imageUrl_Local = URL.createObjectURL(
        e.target.files[j],
      );
      rList[selectedCategory].isNewImage = true;
      rList[selectedCategory].isDuplicated = false;
      setCategoryList(rList);
    }
  }

  const formatStoreConfig = () => {
    try {
      const parsedJson = JSON.parse(storeConfig);
      const formattedJson = JSON.stringify(parsedJson, null, 2);
      setStoreConfig(formattedJson);
    } catch (error) {
      setStoreConfig(storeConfig);
    }
  };

  const formatBillingAddress = () => {
    try {
      const parsedJson = JSON.parse(billingAddress);
      const formattedJson = JSON.stringify(parsedJson, null, 2);
      setBillingAddress(formattedJson);
    } catch (error) {
      setBillingAddress(billingAddress);
    }
  };

  return (
    <>
      <div className="form-group">
        <input
          type="file"
          className="form-control"
          id="input-file-upload"
          accept="image/*"
          onChange={setCategoryImage}
        />
      </div>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                F&B Store Card
                {currentStore ? ` - ${currentStore.storeCode}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button onClick={() => props.history.push("/app/fnb-store/list")}>
                Cancel
              </Button>
              {((!currentStore &&
                !storeId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.fnb_store,
                  PrivilegeActions.add,
                )) ||
                (currentStore &&
                  storeId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.fnb_store,
                    PrivilegeActions.edit,
                  ))) && (
                  <Button
                    disabled={rowsInvalid}
                    className={"primary"}
                    type={"button"}
                    onClick={handleSubmit(handleSubmitForm)}
                  >
                    Save
                  </Button>
                )}
              {((!currentStore &&
                !storeId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.fnb_store,
                  PrivilegeActions.add,
                )) ||
                (currentStore &&
                  storeId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.fnb_store,
                    PrivilegeActions.edit,
                  ))) && (
                  <Button
                    disabled={rowsInvalid}
                    className={"primary"}
                    type={"button"}
                    onClick={handleSubmit((data, e) =>
                      handleSubmitForm(data, e, true),
                    )}
                  >
                    Save/Duplicate
                  </Button>
                )}
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"storeCode"}
                            label={"Store Code"}
                            variant={"outlined"}
                            {...register("storeCode", {
                              required: {
                                value: true,
                                message: "Store Code cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.storeCode}
                            helperText={formErrors?.storeCode?.message}
                            onChange={(event) => validateText(event)}
                            disabled={currentStore && storeId}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"name"}
                            label={"Name"}
                            variant={"outlined"}
                            {...register("name", {
                              required: {
                                value: true,
                                message: "Name cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.name}
                            helperText={formErrors?.name?.message}
                            onChange={(event) => validateText(event)}
                            disabled={
                              currentStore &&
                                storeId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_store,
                                  PrivilegeActions.edit,
                                )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"location"}
                            label={"Location"}
                            variant={"outlined"}
                            {...register("location", {
                              required: {
                                value: true,
                                message: "Location cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.location}
                            helperText={formErrors?.location?.message}
                            onChange={(event) => validateText(event)}
                            disabled={
                              currentStore &&
                                storeId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_store,
                                  PrivilegeActions.edit,
                                )
                                ? true
                                : false
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"longitude"}
                            label={"Longitude"}
                            type={"double"}
                            variant={"outlined"}
                            {...register("longitude", {
                              required: {
                                value: false,
                              },
                              pattern: {
                                value: /^\d*\.?\d/,
                                message:
                                  "Longitude may contain only numbers (0-9).",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.longitude}
                            helperText={formErrors?.longitude?.message}
                            onChange={(event) => validateText(event)}
                            disabled={
                              currentStore &&
                                storeId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_store,
                                  PrivilegeActions.edit,
                                )
                                ? true
                                : false
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"latitude"}
                            label={"Latitude"}
                            type={"double"}
                            variant={"outlined"}
                            {...register("latitude", {
                              required: {
                                value: false,
                              },
                              pattern: {
                                value: /^\d*\.?\d/,
                                message:
                                  "Latitude may contain only numbers (0-9).",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.latitude}
                            helperText={formErrors?.latitude?.message}
                            onChange={(event) => validateText(event)}
                            disabled={
                              currentStore &&
                                storeId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_store,
                                  PrivilegeActions.edit,
                                )
                                ? true
                                : false
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("isDefault")}
                                checked={isDefaultSwitchValue}
                                onChange={(e) =>
                                  setIsDefaultSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Default"
                            disabled={
                              currentStore &&
                                storeId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_store,
                                  PrivilegeActions.edit,
                                )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("status")}
                                checked={enabledSwitchValue}
                                onChange={(e) =>
                                  setEnabledSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Enable"
                            disabled={
                              currentStore &&
                                storeId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_store,
                                  PrivilegeActions.edit,
                                )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>Billing & Others</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12}>
                          <Box className={'code'}>
                            <InputLabel htmlFor="billingAddress">
                              Billing Address
                            </InputLabel>
                            <textarea
                              id="billingAddress"
                              value={billingAddress}
                              onChange={(event) => setBillingAddress(event.target.value)}
                              rows={15}
                              className="billing-address"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className={'code'}>
                            <InputLabel htmlFor="welcomeMessage">
                              Welcome Message
                            </InputLabel>
                            <textarea
                              id="welcomeMessage"
                              value={welcomeMessage}
                              onChange={(event) => setWelcomeMessage(event.target.value)}
                              rows={15}
                              className="receipt-footer"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className={'code'}>
                            <InputLabel htmlFor="receiptFooter">
                              Receipt Footer
                            </InputLabel>
                            <textarea
                              id="receiptFooter"
                              value={receiptFooter}
                              onChange={(event) => setReceiptFooter(event.target.value)}
                              rows={15}
                              className="receipt-footer"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant={"h2"}>Settings</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={'code'}>
                      <InputLabel htmlFor="config">
                        Store Configuration
                      </InputLabel>
                      <textarea
                        id="config"
                        value={storeConfig}
                        onChange={(event) => setStoreConfig(event.target.value)}
                        rows={20}
                        className="store-config"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Categories</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columns}
                      data={categoryList}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={createNewRow}
                      disabled={
                        rowsInvalid ||
                        (currentStore &&
                          storeId &&
                          !auth.checkModulePrivilege(
                            PrivilegeModules.fnb_store,
                            PrivilegeActions.edit,
                          ))
                      }
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
};

export default withRouter(FnbStoreCard);
