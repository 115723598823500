import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import QrReader from 'react-qr-scanner';
import React from 'react';
import { Drawer, Box } from '@mui/material';

export default function QrScanner(props) {
  const { isOpen, onClose, onSuccess } = props;

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        anchor={'right'}
      >
        <Box>
          <QrReader
            delay={200}
            onError={(err) => Swal.fire({
              text: err.message === 'Permission denied' ? 'Please make sure website can access your camera' : err.message,
              icon: 'warning',
              titleText: 'Error',
            })}
            onScan={(data) => {
              if (data?.text) {
                onSuccess(data?.text);
                onClose();
              }
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}

QrScanner.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
