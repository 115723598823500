import { Grid, Typography } from '@mui/material';

export const PageWrapper = ({ title, action, children }) => {
  return <Grid container spacing={{ xs: 2, md: 3 }}>
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <Typography variant={'h1'}>{title}</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={6} lg={6} className={'action'}>
      {action}
    </Grid>

    <Grid item xs={12} sm={12} md={12} lg={12}>
      {children}
    </Grid>
  </Grid>;
};